//@ts-check
import React from 'react';

/** @typedef {'start' | 'center' | 'end'} Justification */
/** @typedef {'start' | 'center' | 'end' | 'stretch'} Alignment*/
/**
 *
 * @param {import('react').PropsWithChildren<{
 *  className?: string,
 *  offset?: number,
 *  basis?: number,
 *  span?: number | 'fill',
 *  justifySelf?: Justification,
 *  alignSelf?: Alignment,
 *  paddingLeft?: number,
 *  paddingRight?: number
 * }>} props
 */
export default function GridItem({
  className = '',
  offset,
  basis,
  span = 1,
  justifySelf,
  alignSelf,
  paddingLeft,
  paddingRight,
  children,
}) {
  basis = basis ?? (typeof span === 'string' ? 1 : span);
  span = span === 'fill' ? basis - (offset % basis) : span;
  const size = `${(100 * span) / basis}%`;
  return (
    <div
      className={`layout-grid-item ${className}`}
      style={{
        paddingLeft,
        paddingRight,
        flex: `0 0 ${size}`,
        maxWidth: size,
        marginTop: `${offset >= basis ? '8px' : ''}`,

        display: 'flex',
        flexDirection: 'column',
        ...getJustificationStyle('alignItems', justifySelf),
        ...getAlignmentStyle('alignSelf', alignSelf),
      }}
    >
      {children}
    </div>
  );
}

/**
 *
 * @param {string} key
 * @param {Justification | undefined} justification
 *
 * @return {object}
 */
export function getJustificationStyle(key, justification) {
  return !justification
    ? {}
    : {
        [key]: justification === 'center' ? 'center' : `flex-${justification}`,
      };
}

/**
 *
 * @param {string} key
 * @param {Alignment | undefined} alignment
 *
 * @return {object}
 */
export function getAlignmentStyle(key, alignment) {
  return !alignment
    ? {}
    : {
        [key]:
          alignment === 'stretch' || alignment === 'center'
            ? alignment
            : `flex-${alignment}`,
      };
}
