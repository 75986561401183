import * as React from 'react';
import PropTypes from 'prop-types';

export class SchedulingInformation extends React.Component {
	render() {
		const { characterLimit, fieldValidation, handleChange, schedulingInformation, schedulingInformationLabel } = this.props;
		return (
			<div className="form-group">
				{schedulingInformationLabel &&
					<div>{schedulingInformationLabel}</div>
				}
				<textarea maxLength={characterLimit} className="form-control" id="schedulingInformation" name="schedulingInformation" rows="7" value={schedulingInformation} onChange={(e) => { handleChange(e, 'String') }} />
				{characterLimit > 0 &&
					<div><span>{schedulingInformation.length || 0}/{characterLimit}</span></div>
				}
				{fieldValidation["schedulingInformation"].valid === false &&
					<div><span style={{ "color": "red" }}>* Please enter scheduling information</span></div>
				}
			</div>
		);
	}
}

export default SchedulingInformation;

SchedulingInformation.propTypes = {
	characterLimit: PropTypes.number,
	schedulingInformation: PropTypes.string,
	schedulingInformationLabel: PropTypes.string,
	fieldValidation: PropTypes.object,
	handleChange: PropTypes.func,
}
