import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Popover, Row } from 'reactstrap';
import Calendar from 'react-calendar';
import moment from 'moment';

import { DropdownSelectWithLabel, RadioSelection2WithLabel } from '../common/standardComponents';
import DateInput from '../input/dateInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import './followUpDate.css';

const SAVED_DATE_RANGE = 'savedDateRange';
const CUSTOM_DATE_RANGE = 'customDateRange';

export class FollowUpDate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            computedDateRangeText: '',
            popoverOpenEnd: false,
            popoverOpenStart: false,
        };

        this.legacyToggleCalendarStart = this.legacyToggleCalendarStart.bind(this);
        this.onEndDatePickerChange = this.onEndDatePickerChange.bind(this);
        this.onOptionChange = this.onOptionChange.bind(this);
        this.onSavedDateRangeChange = this.onSavedDateRangeChange.bind(this);
        this.resolveDateRangeText = this.resolveDateRangeText.bind(this);
        this.toggleCalendarStart = this.toggleCalendarStart.bind(this);
    }

    resolveDateRangeText = (min, max) => {
        let dateRangeText = ''
        if (min >= 0 && max > 0) {
            let startDate = moment().add(min, 'd');
            let endDate = moment().add(max, 'd');
            dateRangeText = `${startDate.format('MMMM DD, YYYY')} - ${endDate.format('MMMM DD, YYYY')}`;            
        }
        return dateRangeText;
    }

    legacyToggleCalendarStart = () => {
        if (this.state.popoverOpenStart === true) {
            this.toggleCalendarStart();
        }
    }

    toggleCalendarStart = () => {
       this.setState({ popoverOpenStart: !this.state.popoverOpenStart})
    }

    legacyToggleCalendarEnd = () => {
        if (this.state.popoverOpenEnd === true) {
            this.toggleCalendarEnd();
        }
    }

    toggleCalendarEnd = () => {
       this.setState({ popoverOpenEnd: !this.state.popoverOpenEnd})
    }

    onSavedDateRangeChange = (event) => {
        let textResult = '';
        let dateRangeId = parseInt(event.target.value, 10);
        if (dateRangeId > 0) {
            let { dateRangeList } = this.props;
            let dateRange = dateRangeList.find(x => x.id === dateRangeId);
            if (dateRange) {                
                textResult = this.resolveDateRangeText(dateRange.minOffsetDays, dateRange.maxOffsetDays);
            }
        }
        this.setState({ computedDateRangeText: textResult });
        this.props.onDropdownChange(event, 'selectedDateRange');
    }

    onOptionChange = (event) => {
        let target = event.target;        
        this.setState({ [target.name]: target.value });

        this.props.onDateRangeOptionChange(event);
    }

    onStartDatePickerChange = (date) => {
        this.toggleCalendarStart();
        this.props.onDatePickerChange(date, 'customDateRangeStart');
    }

    onEndDatePickerChange = (date) => {
        this.toggleCalendarEnd();
        this.props.onDatePickerChange(date, 'customDateRangeEnd');
    }

    render() {
        let {
            customDateRangeEnd,
            customDateRangeEndIsValid,
            customDateRangeEndPicker,
            customDateRangeStart,
            customDateRangeStartIsValid,
            customDateRangeStartPicker,
            dateRangeList,
            onCustomDateRangeChange,
            validateCustomDateRange,
            selectedDateRange,
            selectedDateRangeOption,
            shouldValidate,
        } = this.props;

        return (
            <React.Fragment>             
                <Row>
                    <Col>
                        <RadioSelection2WithLabel
                            id="selectedDateRangeOption"
                            label="Date Range"
                            value={selectedDateRangeOption}
                            onChange={this.onOptionChange}
                            option1Label="Select Date Range"
                            option1Value={SAVED_DATE_RANGE}
                            option2Label="Input Custom Date Range"
                            option2Value={CUSTOM_DATE_RANGE} />
                    </Col>
                </Row>
                <Row className="custom-date-range-row">
                    { selectedDateRangeOption === SAVED_DATE_RANGE &&
                       <React.Fragment>
                           <Col>
                                <div className="d-flex flex-row align-items-center">
                                    <DropdownSelectWithLabel
                                        id="dateRange"
                                        isRequired
                                        label="Follow Up Date Range"
                                        onChange={(e) => this.onSavedDateRangeChange(e)} 
                                        optionList={dateRangeList}
                                        shouldValidate={shouldValidate}
                                        value={selectedDateRange} />
                                    <div className="p-2">
                                        <p className='date-range-text'>{this.state.computedDateRangeText}</p>
                                    </div>
                                </div>
                           </Col>
                        </React.Fragment>
                    }
                    { selectedDateRangeOption === CUSTOM_DATE_RANGE && 
                        <React.Fragment>
                            <Col>
                                <Row>
                                    <Col>
                                        <Label className="fw-bold">CUSTOM DATE RANGE</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-inline-flex">
                                            <div className="txtbx-date-range">
                                                <DateInput
                                                    type="text"
                                                    name="customDateRangeStart"
                                                    id="customDateRangeStart"
                                                    onChange={onCustomDateRangeChange}
                                                    validate={validateCustomDateRange}
                                                    value={customDateRangeStart}
                                                    className={"form-control " + (customDateRangeStartIsValid ? undefined : 'is-invalid')}
                                                    placeholder="Start Date"
                                                    isRequired
                                                    />
                                                <div className="invalid-feedback">
                                                    Please provide a current or future start date.
                                                </div>
                                            </div>
                                            <div className="p-2">
                                                <FontAwesomeIcon className="hand calendar-icon" id="popoverOpenStart" icon={faCalendarAlt} size="lg" onClick={this.toggleCalendarStart}/>
                                                <Popover placement="right" isOpen={this.state.popoverOpenStart} target="popoverOpenStart" toggle={this.legacyToggleCalendarStart} trigger="legacy">
                                                    <Calendar
                                                        name="customDateRangeStartPicker"
                                                        id="customDateRangeStartPicker"
                                                        calendarType="US"
                                                        formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                        className="pop-calendar"
                                                        onChange={this.onStartDatePickerChange}
                                                        value={customDateRangeStartPicker} />
                                                </Popover>
                                            </div>
                                            <div className="txt-date-range">
                                                <DateInput
                                                    type="text"
                                                    name="customDateRangeEnd"
                                                    id="customDateRangeEnd"
                                                    onChange={onCustomDateRangeChange}
                                                    validate={validateCustomDateRange}
                                                    value={customDateRangeEnd}
                                                    className={"form-control " + (customDateRangeEndIsValid ? undefined : 'is-invalid')}
                                                    placeholder="End Date"
                                                    isRequired
                                                    />
                                                <div className="invalid-feedback">
                                                    Please provide a future end date after the start date.
                                                </div>
                                            </div>
                                            <div className="p-2">
                                                <React.Fragment>
                                                    <FontAwesomeIcon className="hand calendar-icon" id="popoverOpenEnd" icon={faCalendarAlt} size="lg" onClick={this.toggleCalendarEnd}/>
                                                    <Popover placement="right" isOpen={this.state.popoverOpenEnd} target="popoverOpenEnd" toggle={this.legacyToggleCalendarEnd} trigger="legacy">
                                                        <Calendar
                                                            name="customDateRangeEndPicker"
                                                            id="customDateRangeEndPicker"
                                                            calendarType="US"
                                                            formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                            className="pop-calendar"
                                                            onChange={this.onEndDatePickerChange}
                                                            value={customDateRangeEndPicker} />
                                                    </Popover>
                                                </React.Fragment>
                                            </div>
                                        </div>                                        
                                    </Col>
                                </Row>
                            </Col>
                        </React.Fragment>
                    }
                </Row>
            </React.Fragment>
        );
    }
}

export default FollowUpDate

FollowUpDate.propTypes = {
    customDateRangeEnd: PropTypes.string,
    customDateRangeEndIsValid: PropTypes.bool,
    customDateRangeEndPicker: PropTypes.string,
    customDateRangeStart: PropTypes.string,
    customDateRangeStartIsValid: PropTypes.bool,
    customDateRangeStartPicker: PropTypes.string,
    dateRangeList: PropTypes.array,
    onCustomDateRangeChange: PropTypes.func,
    onDatePickerChange: PropTypes.func,
    onDateRangeOptionChange: PropTypes.func,
    onDropdownChange: PropTypes.func,
    selectedDateRange: PropTypes.number,
    selectedDateRangeOption: PropTypes.string,
    shouldValidate: PropTypes.func,
}