import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import dayjs from "dayjs";
import { ConfirmOrCancelModal } from "components/common/standardComponents";
import PatientAppointmentHistory from "./patientAppointmentHistory";
import PatientUpcomingAppointment from "./patientUpcomingAppointments"
import PatientCareOrderHistory from "./patientCareOrderHistory";
import { patientDetails as routeToPatientDetails } from '../../../routes';
import PatientDetailsSummary from "./patientDetailsSummary";
import PatientInfoNav from "./patientInfoNav";
import LoadingIndicator from "components/loadingIndicator";
import useLoadPatientAppointmentHistory from "../hooks/useLoadPatientAppointmentHistory";
import useLoadPatientCareOrderHistory from "../hooks/useLoadPatientCareOrderHistory";
import useLoadPatientOpenWorkflows from "../hooks/useLoadPatientOpenWorkflows";
import PatientOpenworkflows from "./patientOpenWorkflows";
import PatientCareOrderDetails from "./patientCareOrderDetails"
import { genderCodeToDisplayName } from '../../../lib/standardLibrary';
import '../css/patientInfoTabbedCollapsable.css';

export enum PatientInfoTabIds {
    PatientDetails = '1',
    AppointmentHistory = '2',
    CareOrderHistory = '3',
    DecisionSupportWorkflowHistory = '4',
    UpcomingAppointments = '5',
    PatientCareOrderDetails = '6'
}

export default function PatientInfoTabbedCollapsable(
    { isOpen, toggleIsOpen }: { isOpen: boolean, toggleIsOpen: Function }
) {
    const dispatch = useDispatch();
    
    const [showConfirmMorePatientInfoModal, setShowConfirmMorePatientInfoModal] = useState(false);

    const patient = useSelector((state: any) => state.activePatient);
    const patientDetails = patient.details;
    const patientConfig = useSelector((state: any) => state.config.patient);
    const schedulingConfig = useSelector((state: any) => state.config.scheduling);
    const careOrderFieldConfig = useSelector((state: any) => state.config.careOrderPanel);

    const appointmentHistoryRecords = useSelector((state: any) => state.activePatient.history);
    const [isPatientAppointmentHistoryLoading, setIsPatientAppointmentHistoryLoading] = useState(false);
    const shouldLoadPatientAppointmentHistory = patientConfig.isPatientHistoryEnabled
        && patientConfig.showPatientHistoryInPatientInfoPanel
        && !appointmentHistoryRecords
        && !isPatientAppointmentHistoryLoading;

    useLoadPatientAppointmentHistory(
        patient.id,
        shouldLoadPatientAppointmentHistory,
        setIsPatientAppointmentHistoryLoading
    );

    const careOrders = useSelector((state: any) => state.activePatient.careOrderHistory);
    const [isPatientCareOrderHistoryLoading, setIsPatientCareOrderHistoryLoading] = useState(false);
    const shouldLoadPatientCareOrderHistory = patientConfig.isPatientCareOrderHistoryEnabled
        && !careOrders
        && !isPatientCareOrderHistoryLoading;

    useLoadPatientCareOrderHistory(
        patient.id,
        shouldLoadPatientCareOrderHistory,
        setIsPatientCareOrderHistoryLoading
    );

    const openWorkflows = useSelector((state: any) => state.activePatient.openWorkflows);
    const isPatientOpenWorkflowsLoading = useSelector((state: any) => state.activePatient.isLoadingOpenWorkflows);
    const shouldLoadPatientOpenWorkflows = patientConfig.isDecisionSupportWorkflowHistoryEnabled
        && !openWorkflows
        && !isPatientOpenWorkflowsLoading;

    useLoadPatientOpenWorkflows(
        patient.id,
        shouldLoadPatientOpenWorkflows,
        schedulingConfig.bookOpenDecisionSupportWorkflowMaxAgeInDays,
    );

    const activeCareOrderDetails = useSelector((state: any) => state.careOrder.activeCareOrderDetails);

    const isPatientHeaderAutoExpanded = schedulingConfig.autoExpandPatientHeaderForVisibleNotes;
    const [activeTab, setActiveTab] = useState("");

    const hasCareOrder = useSelector((state: any) => !!state.careOrder.careOrderVisitIdentifier || !!state.careOrder.externalReferralOrderId);
    const enableOrderDetailsPanel = useSelector((state: any) => state.config.orderScheduling.enableOrderDetailsPanel);
    const isOrderDetailsHeaderAutoExpanded = useSelector((state: any) => state.config.orderScheduling.autoExpandOrderDetailsPanel);

    useEffect(() => {
        if (hasCareOrder && enableOrderDetailsPanel) {
            if (isOrderDetailsHeaderAutoExpanded || isPatientHeaderAutoExpanded) {
                setActiveTab(PatientInfoTabIds.PatientCareOrderDetails);
            }
            else {
                setActiveTab("");
            }
        }
        else if (isPatientHeaderAutoExpanded) {
            setActiveTab(PatientInfoTabIds.PatientDetails);
        }
        else {
            setActiveTab("");
        }
    }, [isPatientHeaderAutoExpanded, hasCareOrder, enableOrderDetailsPanel, isOrderDetailsHeaderAutoExpanded])

    return (
        <Card className={classnames({ PatientInfoCard: !activeTab, PatientInfoCardActive: activeTab })}>
            <PatientInfoHeader patientDetails={patientDetails} />
            <CardBody className="PatientInfoCardBody">
                <PatientInfoNav
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    patientConfig={patientConfig}
                    careOrderFieldConfig={careOrderFieldConfig}
                    activePatientNotes={patientDetails?.notes}
                    activeCareOrderNotes={activeCareOrderDetails?.notes}
                />
                <TabContent activeTab={activeTab}>
                    {hasCareOrder && enableOrderDetailsPanel && (
                        <TabPane tabId={PatientInfoTabIds.PatientCareOrderDetails}>
                            <PatientCareOrderDetails />
                        </TabPane>
                    )}
                    <TabPane tabId={PatientInfoTabIds.PatientDetails}>
                        <PatientDetailsSummary
                            patientDetails={patientDetails}
                            patientConfig={patientConfig}
                            setShowConfirmMorePatientInfoModal={setShowConfirmMorePatientInfoModal}
                        />
                    </TabPane>
                    {patientConfig.isPatientHistoryEnabled && patientConfig.showPatientHistoryInPatientInfoPanel &&
                        <>
                            <TabPane tabId={PatientInfoTabIds.UpcomingAppointments}>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        {isPatientAppointmentHistoryLoading &&
                                            <div>
                                                <LoadingIndicator />
                                            </div>
                                        }
                                        {!isPatientAppointmentHistoryLoading &&
                                            <PatientUpcomingAppointment
                                                showLoseProgressWarning={true}
                                                enableShowPSAandMHDfilters={false}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={PatientInfoTabIds.AppointmentHistory}>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        {isPatientAppointmentHistoryLoading &&
                                            <div>
                                                <LoadingIndicator />
                                            </div>
                                        }
                                        {!isPatientAppointmentHistoryLoading &&
                                            <PatientAppointmentHistory
                                                showLoseProgressWarning={true}
                                                enableShowPSAandMHDfilters={false}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        </>
                    }
                    {patientConfig.isPatientCareOrderHistoryEnabled &&
                        <TabPane tabId={PatientInfoTabIds.CareOrderHistory}>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    {isPatientCareOrderHistoryLoading &&
                                        <div>
                                            <LoadingIndicator />
                                        </div>
                                    }
                                    {!isPatientCareOrderHistoryLoading &&
                                        <PatientCareOrderHistory
                                            showBookButton={false}
                                            showCloseButton={false}
                                            showFilters={false}
                                            setIsPatientCareOrderHistoryLoading={setIsPatientCareOrderHistoryLoading}
                                        />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    }
                    {patientConfig.isDecisionSupportWorkflowHistoryEnabled &&
                        <TabPane tabId={PatientInfoTabIds.DecisionSupportWorkflowHistory}>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    {isPatientOpenWorkflowsLoading &&
                                        <div>
                                            <LoadingIndicator />
                                        </div>
                                    }
                                    {!isPatientOpenWorkflowsLoading &&
                                        <PatientOpenworkflows
                                            showLoseProgressWarning={true}
                                            showBookButton={schedulingConfig.enableBookOpenDecisionSupportWorkflow}
                                            toggleIsPatientSummaryPanelOpen={toggleIsOpen}
                                        />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    }
                </TabContent>
                <ConfirmOrCancelModal
                    className={"confirmMoreInfoModal"}
                    title={"Confirm"}
                    body={"If you continue you will lose your current progress. Are you sure you want to leave?"}
                    isOpen={showConfirmMorePatientInfoModal}
                    confirmLabel={"Confirm"}
                    cancelLabel={"Cancel"}
                    onConfirm={() => {
                        setShowConfirmMorePatientInfoModal(false);
                        dispatch(routeToPatientDetails(patientDetails?.referenceId));
                    }}
                    onCancel={() => setShowConfirmMorePatientInfoModal(false)}
                />
            </CardBody>
        </Card>
    );
}

const PatientInfoHeader = ({ patientDetails }: { patientDetails: any }) => (
    <CardHeader className = "PatientInfoCardHeader">
        <Row style={{alignItems: "baseline"} }>
            <Col lg="auto" style={{paddingLeft: "0"}}>
                <h4>
                    {`${patientDetails?.firstName} ${patientDetails?.lastName}`}
                </h4>
            </Col>
            <Col lg="auto" style={{ padding: "0" }}>
                <h6>
                    {patientDetails?.dateOfBirth ? dayjs(patientDetails?.dateOfBirth).format('MM/DD/YYYY') : '-'}
                </h6>
            </Col>
            <Col lg="auto" style={{ padding: "0px 3px" }}>
                <h5>&#8226;</h5>
            </Col>
            <Col lg="auto" style={{ padding: "0" }}>
                <h6>
                    {genderCodeToDisplayName(patientDetails?.gender)}
                </h6>
            </Col>
        </Row>
        
    </CardHeader>
);
