import React from 'react';
import NumberFormat from 'react-number-format';
import FieldControl from './fieldControl';
import CustomTextInput from './customTextInput';

/**
* @param {Object} props
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {number} props.decimalScale
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label
* @param {boolean} props.labelAfter - If true, label is placed after input (default before)
* @param {number} props.minLength
* @param {number} props.maxLength
* @param {number} props.minValue
* @param {number} props.maxValue
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onBlur
* @param {Function} props.onChange
* @param {Function} props.onFocus
* @param {string} props.placeholder
* @param {string} props.size - "sm", "md", "lg"
* @returns {Object}
*/
export function NumberInput(props) {
	const {
		id,
		error,
		decimalScale,
		disabled,
		isFocused,
		isVisible,
		label,
		labelAfter,
		// minLength,
		// maxLength,
		// minValue,
		// maxValue,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		placeholder,
		size,
	} = props;

	const inputType = "number";
	const hasValue = !!value;

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			id={id}
			inputType={inputType}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			<NumberFormat
				decimalScale={decimalScale || 0}
				mask="_"
				//minLength={minLength}
				//maxLength={maxLength}
				//minValue={minValue}
				//maxValue={maxValue}
				customInput={CustomTextInput}
				id={id}
				name={name}
				placeholder={placeholder}
				type={"text"}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
			/>
		</FieldControl>
	);
}
