import { Dispatch, SetStateAction } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { CareOrderFilters } from './patientCareOrderHistory';

export default function PatientCareOrderHistoryFilter(
    {
        filters,
        setFilters,
        showHybridCareOrderFilters,
        showInactiveCareOrderFilters,
    }: {
        filters: CareOrderFilters,
        setFilters: Dispatch<SetStateAction<CareOrderFilters>>,
        showHybridCareOrderFilters: boolean,
        showInactiveCareOrderFilters: boolean,
    }
) {
    return (
        <Col style={{ marginLeft: '20px', marginBottom: '10px' }}>
            <Row className="col-sm-5 col-xxl-3">
                {showInactiveCareOrderFilters &&
                    <>
                        <Col>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="includeClosed"
                                    checked={filters.includeClosed}
                                    onChange={() => setFilters({ ...filters, includeClosed: !filters.includeClosed })}
                                />{' '}
                                Show Closed Care Orders
                            </Label>
                        </Col>
                        <Col>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="includeExpired"
                                    checked={filters.includeExpired}
                                    onChange={() => setFilters({ ...filters, includeExpired: !filters.includeExpired })}
                                />{' '}
                                Show Expired Care Orders
                            </Label>
                        </Col>
                    </>
                }
                {showHybridCareOrderFilters &&
                    <>
                        <Col>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="hideMHDCareOrders"
                                    checked={filters.hideMHD}
                                    onChange={() => setFilters({ ...filters, hideMHD: !filters.hideMHD })}
                                />{' '}
                                Hide MHD Care Orders
                            </Label>
                        </Col>
                        <Col>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="hideEMRCareOrders"
                                    checked={filters.hideEMR}
                                    onChange={() => setFilters({ ...filters, hideEMR: !filters.hideEMR })}
                                />{' '}
                                Hide EMR Care Orders
                            </Label>
                        </Col>
                    </>
                }
            </Row>
        </Col>
    )
}
