import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

class TextInput extends React.Component {
	handleChange = (e) => {
		const { validate, onChange } = this.props
		const error = validate(e.target.value)
		return onChange(e, error)
	}

	render() {
		const { name, value, placeholder, type, onChange, validate, ...rest } = this.props

		return (
			<Input
				type={type}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={this.handleChange}
				{...rest}
			/>
		)
	}
}

TextInput.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.string.isRequired,
	validate: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.string])
}

TextInput.defaultProps = {
	validate: value => !(value && value.length)
}

export default TextInput
