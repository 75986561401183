import { useSelector } from "react-redux";
import {
    selectFirstDisplayableDateWithSlotForCalendarById,
    selectIsCalendarScanningById,
    selectIsCalendarSearchingById
} from "../availabilitySlice";
import LoadingIndicator from "../../../components/loadingIndicator";
import Availability from "./availability";
import Calendar from "./calendar";
import { Col, Row } from "reactstrap";
import '../css/availabilityResultRow.css';

export default function ResultRow({ calendarId, isLastRow, selectedSlot, setSelectedSlot }) {

    const isCalendarSearchingAvailability = useSelector(state => selectIsCalendarSearchingById(state, calendarId));
    const isCalendarScanningAvailability = useSelector(state => selectIsCalendarScanningById(state, calendarId));
    const firstDateWithSlot = useSelector(state => selectFirstDisplayableDateWithSlotForCalendarById(state, calendarId));
    const displayAsBusy = isCalendarSearchingAvailability || (isCalendarScanningAvailability && !firstDateWithSlot);

    return (
        <Row
            className={
                (
                    isLastRow
                        ? 'provider-divider-last-selected'
                        : 'provider-divider'
                )
                + ' '
                + (
                    displayAsBusy
                        ? 'availability-result-blur'
                        : ''
                )
            }
        >
            <Col md="3" className={`availability-result-provider ${firstDateWithSlot ? "provider-has-results" : "provider-no-results"}`}>
                <Calendar calendarId={calendarId} />
            </Col>
            <Col md="9">
                {displayAsBusy ?
                    <LoadingIndicator loadingMessage={"Searching"} />
                    : <Availability
                        calendarId={calendarId}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                    />
                }
            </Col>
        </Row>
    )
}