export function createRequest_GetPatientDetails(referenceId) {
	return {
		method: 'get',
		url: `patient/${referenceId}/details`,
	}
}

export function createRequest_LookupPatient(idValue, idType) {
	return {
		method: 'get',
		url: `patient/lookup/id/${idValue}/type/${idType}`,
	}
}

export function createRequest_GetPatientSchedulableOrders(idValue, idType) {
	return {
		method: 'get',
		url: `patient/schedulableOrders/id/${idValue}/type/${idType}`,
	}
}

export function createRequest_SearchPatient(patientSearchCriteria) {
	return {
		method: 'post',
		url: 'patient/search',
		data: {
			dateOfBirth: patientSearchCriteria.dateOfBirth,
			firstName: patientSearchCriteria.firstName,
			lastName: patientSearchCriteria.lastName,
			identityValue: patientSearchCriteria.identityValue,
			memberId: patientSearchCriteria.memberId,
			excludeWithoutMemberId: patientSearchCriteria.excludeWithoutMemberId,
			phoneNumber: patientSearchCriteria.phoneNumber,
		}
	}
}

export function createRequest_CancelAppointment(appointmentId, cancelRequest, token) {
	return {
		method: 'post',
		url: `appointment/${appointmentId}/cancel`,
		headers: {
			token: token,
		},
		data: {
			reasonForCancellation: cancelRequest.reasonForCancellation,
			sendCancellationEmailToPatient: cancelRequest.sendCancellationEmailToPatient,
			email: cancelRequest.email,
			languageCode: cancelRequest.languageCode,
		},
	}
}

export function createRequest_GetPatientHistory(referenceId) {
	return {
		method: 'get',
		url: `patient/${referenceId}/history`,
	}
}

export function createRequest_GetPatientCareOrderHistory(referenceId) {
	return {
		method: 'get',
		url: `patient/${referenceId}/careorders`,
	}
}

export function createRequest_GetPatientOpenWorkflows(referenceId, maxAgeInDays) {
	return {
		method: 'get',
		url: `patient/${referenceId}/openworkflows?maxAgeInDays=${maxAgeInDays}`,
	}
}

export function createRequest_ValidateZipCode(zipCode) {
	return {
		method: 'get',
		url: `utility/validateZipCode/${zipCode}`,
	}
}

export function createRequest_UpdatePatient(patientDetails) {
	return {
		method: 'put',
		url: `patient/${patientDetails.referenceId}/update`,
		data: {
			referenceId: patientDetails.referenceId,
			firstName: patientDetails.firstName,
			middleName: patientDetails.middleName,
			lastName: patientDetails.lastName,
			addressLine1: patientDetails.addressLine1,
			addressLine2: patientDetails.addressLine2,
			cityName: patientDetails.cityName,
			stateId: patientDetails.stateId,
			zipCode: patientDetails.zipCode,
			gender: patientDetails.gender,
			dateOfBirth: patientDetails.dateOfBirth,
			homePhone: patientDetails.homePhone,
			mobilePhone: patientDetails.mobilePhone,
			alternatePhone: patientDetails.alternatePhone,
			email: patientDetails.email,
			guardianName: patientDetails.guardianName,
			memberId: patientDetails.memberId,
			groupNumber: patientDetails.groupNumber,
			notes: patientDetails.notes,
		},
	}
}

export function createRequest_CreatePatient(patientDetails) {
	return {
		method: 'post',
		url: 'patient/create',
		data: {
			firstName: patientDetails.firstName,
			middleName: patientDetails.middleName,
			lastName: patientDetails.lastName,
			addressLine1: patientDetails.addressLine1,
			addressLine2: patientDetails.addressLine2,
			cityName: patientDetails.cityName,
			stateId: patientDetails.stateId,
			zipCode: patientDetails.zipCode,
			gender: patientDetails.gender,
			dateOfBirth: patientDetails.dateOfBirth,
			homePhone: patientDetails.homePhone,
			mobilePhone: patientDetails.mobilePhone,
			alternatePhone: patientDetails.alternatePhone,
			email: patientDetails.email,
			guardianName: patientDetails.guardianName,
			memberId: patientDetails.memberId,
			groupNumber: patientDetails.groupNumber,
			notes: patientDetails.notes,
		},
	}
}

export function createRequest_CreatePatientFromEmrSearchResult(patientDetails) {
	return {
		method: 'post',
		url: 'patient/create/fromEmrSearchResult',
		data: {
			firstName: patientDetails.firstName,
			middleName: patientDetails.middleName,
			lastName: patientDetails.lastName,
			addressLine1: patientDetails.addressLine1,
			addressLine2: patientDetails.addressLine2,
			cityName: patientDetails.cityName,
			stateId: patientDetails.stateId,
			zipCode: patientDetails.zipCode,
			gender: patientDetails.gender,
			dateOfBirth: patientDetails.dateOfBirth,
			homePhone: patientDetails.homePhone,
			mobilePhone: patientDetails.mobilePhone,
			alternatePhone: patientDetails.alternatePhone,
			email: patientDetails.email,
			guardianName: patientDetails.guardianName,
			memberId: patientDetails.memberId,
			groupNumber: patientDetails.groupNumber,
			notes: patientDetails.notes,
		},
	}
}

export function createRequest_GetPatientAddressCoordinates(coordinatesCriteria) {
	return {
		method: 'post',
		url: `utility/getCoordinates`,
		data: {
			address1: coordinatesCriteria.address1,
			address2: coordinatesCriteria.address2,
			city: coordinatesCriteria.city,
			stateCode: coordinatesCriteria.stateCode,
			zip: coordinatesCriteria.zip
		},
	}
}

export function createRequest_GetActiveCareOrderDetails(requestData) {
	return {
		method: 'post',
		url: `careordervisit/workflowDetails`,
		data: requestData,
	}
}

export function createRequest_CloseCareOrder(requestData) {
	return {
		method: 'post',
		url: `careorder/close`,
		data: requestData,
	}
}

export function createRequest_SearchAddress(addressSearchCriteria) {
	return {
		method: 'post',
		url: 'patient/search_address',
		data: {
			query: addressSearchCriteria.query,
		}
	};
}

export function createRequest_GetAddress(addressSearchCriteria) {
	return {
		method: 'post',
		url: 'patient/get_address',
		data: {
			query: addressSearchCriteria.query,
		}
	};
}