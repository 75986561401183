import { useDispatch, useSelector } from "react-redux";
import {
    setSelectedCalendarByIdThunk,
    selectSuppressCalendarTelephoneById,
    selectCalendarById,
    selectAllowRequestAppointmentWithImmediateAvailabilityById
} from "../availabilitySlice";
import {
    requestAppointment as routeToRequestAppointment,
} from '../../../routes';
import { Button, Col, Row } from "reactstrap";
import { PhoneNumber } from "../../../components/common/phoneNumber";
import '../css/availabilityResultProviderDetail.css';

export default function Calendar({ calendarId }) {
    const calendar = useSelector(state => selectCalendarById(state, calendarId));
    let suppressPhoneNumber = useSelector(state => selectSuppressCalendarTelephoneById(state, calendarId));
    let allowRequestAppointment = useSelector(state => selectAllowRequestAppointmentWithImmediateAvailabilityById(state, calendarId));

    const dispatch = useDispatch();

    return (
        <span>
            <Row className="provider-details-mobile">
                <Col className="provider-image-column">
                    <img
                        className="availability-provider-image"
                        src={calendar.imageFileName || '/resources/generic-provider-photo.jpg'}
                        alt="Provider"
                    />
                </Col>
                <Col className="provider-detail provider-detail-column">
                    <Row className="provider-name-row">
                        <a
                            href="#serviceSite"
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(setSelectedCalendarByIdThunk(calendarId))
                                // return providerClick(provider.calendarId);
                            }}
                        >
                            <span className="provider-detail-name">{calendar.displayName}</span>
                        </a>
                    </Row>
                    <Row className="provider-detail-row">
                        <span className="provider-detail-item">{calendar.specialtyName}</span>
                    </Row>
                    <Row className={`provider-detail-row`}>
                        <span className="provider-detail-item">{calendar.locationName}</span>
                    </Row>
                    {!calendar.suppressAddress && (
                        <div>
                            <Row className="provider-detail-row mobile-inline">
                                <span className="provider-detail-item">{calendar.address.addressLine1}</span>
                            </Row>
                            <Row className="provider-detail-row mobile-inline">
                                <span className="provider-detail-item">{calendar.address.addressLine2}</span>
                            </Row>
                            <Row className="provider-detail-row mobile-inline">
                                <span className="provider-detail-item">{calendar.address.cityName + ', ' + calendar.address.stateCode}</span>
                            </Row>
                            <Row className="provider-detail-row mobile-inline">
                                <span className="provider-detail-item">{calendar.address.zipCode}</span>
                            </Row>
                            <Row className={`provider-detail-row`}>
                                <span className="provider-detail-item">
                                    {
                                        calendar.distanceInMiles
                                            ? + (Math.round(calendar.distanceInMiles + 'e+1') + 'e-1') + ' miles away'
                                            : 'distance unknown'
                                    }
                                </span>
                            </Row>
                        </div>
                    )}
                    {!suppressPhoneNumber && (
                        <Row className="provider-detail-row mobile-inline">
                            <PhoneNumber className={'provider-detail-item'} value={calendar.telephone} />
                        </Row>
                    )}
                    <Row className={`provider-view-all-row`}>
                        <span>
                            <Button
                                className="viewAllButton"
                                onClick={(e) => dispatch(setSelectedCalendarByIdThunk(calendarId))}
                                color="primary"
                            >
                                View Monthly Calendar
                            </Button>
                        </span>
                    </Row>
                    {allowRequestAppointment && (
                        <Row className={`provider-view-all-row`}>
                            <span>
                                <Button
                                    className="requestAppointmentButton"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(routeToRequestAppointment(calendarId))
                                    }}
                                    color="primary"
                                >
                                    Request an Appointment
                                </Button>
                            </span>
                        </Row>
                    )}
                </Col>
            </Row>
        </span>
    );
}