import React from 'react';
import FieldControl from './fieldControl';

/**
* @param {Object} props
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label
* @param {number} props.maxLength
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onBlur
* @param {Function} props.onChange
* @param {Function} props.onFocus
* @param {string} props.placeholder
* @param {string} props.size - "sm", "md", "lg"
* @param {number} props.columns
* @param {number} props.rows
* @returns {Object}
*/
export function TextAreaInput(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isVisible,
		label,
		maxLength,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		placeholder,
		size,
		columns,
		rows,
	} = props;

	const inputType = "textarea";
	const hasValue = value && (value.length > 0);


	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			id={id}
			inputType={inputType}
			isVisible={isVisible}
			label={label}
			size={size}
		>
			<textarea
				id={id}
				placeholder={placeholder}
				disabled={disabled}
				name={name}
				maxLength={maxLength}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				cols={columns}
				rows={rows}>
			</textarea>
			{maxLength > 0 &&
				<div className="text-area-count">{value.length || 0}<abbr title="of">/</abbr>{maxLength} <span>characters left</span></div>
			}
		</FieldControl>
	);
}
