/**
 * @format
 */

import React from 'react';
import { connect } from 'react-redux';
import mhd2Permissions from '../constants/permissions';
import PatientSearchView from '../views/patient/patientSearchView';
import CreateOrderView from '../views/orderManagement/createOrderView';
import SearchOrdersView from '../views/orderManagement/searchOrdersView';
import PermissionWarning from '../components/common/permissionWarning';

export const LandingPageSelector = (props) => {
	const { permissions, activePatientReferenceId } = props;
	if (permissions.includes(mhd2Permissions.patientManagement)) {
		return <PatientSearchView />;
	} else if (permissions.includes(mhd2Permissions.orderManagement)) {
		if (activePatientReferenceId) {
			return <CreateOrderView />;
		} else {
			return <SearchOrdersView />;
		}
	} else {
		return <PermissionWarning />;
	}
};

const mapStateToProps = (state) => {
	return {
		permissions: state.auth.permissions,
		activePatientReferenceId: state.activePatient.details.referenceId,
	};
};

export default connect(mapStateToProps)(LandingPageSelector);
