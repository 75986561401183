import axios, { AxiosError, CanceledError } from 'axios';
import {
	ErrorWithMessage,
	ProblemDetails,
	UserVisibleException,
	ValidationFailureProblemDetails,
} from 'types/error.interfaces';

// Assuming we have a type for .NET Model Validation errors
interface ModelStateError {
	errors: Record<string, string[]>;
}

function isUserVisibleError(value: unknown): value is ProblemDetails {
	const x = value as ProblemDetails;
	return x?.type === 'https://www.myhealthdirect.com/errors/uve';
}

function isModelStateError(value: unknown): value is ModelStateError {
	const x = value as ModelStateError;
	return x && typeof x.errors === 'object';
}

function isValidationFailureError(value: unknown): value is ValidationFailureProblemDetails {
	const x = value as ValidationFailureProblemDetails;
	return x && Array.isArray(x.validationFailures);
}

function isCanceledError(value: unknown): value is AxiosError {
	if (axios.isCancel(value)) {
		return true;
	}
	return false;
}

function extractErrorFromAxiosError(error: unknown): unknown {
	if (error instanceof AxiosError) {
		return error.response?.data;
	}
	return error;
}

function getCancelledError(value: unknown): string | null {
	return isCanceledError(value) ? (value as CanceledError<any>)?.message : null;
}

function getUserVisibleError(value: unknown): string | null {
	return isUserVisibleError(value) ? (value as UserVisibleException)?.message : null;
}

function getValidationFailureError(value: unknown): ValidationFailureProblemDetails | null {
	return isValidationFailureError(value) ? (value as ValidationFailureProblemDetails) : null;
}

function formatModelStateErrors(errors: Record<string, string[]>): string {
	return Object.entries(errors)
		.map(([field, messages]) => `${field}: ${messages.join(', ')}`)
		.join('; ');
}

function getProblemDetailsError(value: unknown): string | null {
	const extractedError = extractErrorFromAxiosError(value);
	if (getUserVisibleError(extractedError)) {
		return getUserVisibleError(extractedError);
	}
	const x = extractedError as ProblemDetails;
	return x?.detail ?? null;
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof (error as Record<string, unknown>).message === 'string'
	);
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage | ValidationFailureProblemDetails {
	const cancelledMessage = getCancelledError(maybeError);
	if (cancelledMessage) {
		return { message: cancelledMessage };
	}

	const extractedError = extractErrorFromAxiosError(maybeError);

	const validationFailureError = getValidationFailureError(extractedError);
	if (validationFailureError) {
		return validationFailureError;
	}

	const modelStateError = isModelStateError(extractedError) ? extractedError : null;
	if (modelStateError) {
		return {
			...modelStateError,
			message: formatModelStateErrors(modelStateError.errors),
		};
	}

	const problemDetailsError = getProblemDetailsError(extractedError);
	if (problemDetailsError) {
		return { message: problemDetailsError };
	}

	const userVisibleError = getUserVisibleError(extractedError);
	if (userVisibleError) {
		return { message: userVisibleError };
	}

	if (isErrorWithMessage(extractedError)) {
		return maybeError as ErrorWithMessage;
	}
	try {
		return new Error(JSON.stringify(extractedError));
	} catch {
		// fallback in case there's an error stringifying the maybeError
		// like with circular references for example.
		return new Error(String(maybeError));
	}
}

export function getErrorMessage(error: unknown): string | Error | ValidationFailureProblemDetails {
	const result = toErrorWithMessage(error);
	if ('message' in result) {
		return result.message;
	}
	return result;
}

export default getErrorMessage;
