import React from 'react';
import { Form, Button, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';

import AppliedPathwaysEnums from '../../constants/appliedPathwaysEnums';
import AppliedPathwaysErrorList from './appliedPathwaysErrorList';
import AppliedPathwaysForm from './appliedPathwaysForm';
import AppliedPathwaysQuestion from './appliedPathwaysQuestion';
import AppliedPathwaysMessage from './appliedPathwaysMessage';
import AppliedPathwaysRecommendationMessage from './appliedPathwaysRecommendationMessage';
import LoadingIndicator from '../loadingIndicator';

import './appliedPathwaysWorkflow.css';

export default class AppliedPathwaysWorkflow extends React.Component {
	constructor(props) {
		super(props);
		this.generateComponents = this.generateComponents.bind(this);
		this.generateInitialAnswers = this.generateInitialAnswers.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleNextClick = this.handleNextClick.bind(this);
		this.handlePreviousClick = this.handlePreviousClick.bind(this);
		this.handleWorkbox = this.handleWorkbox.bind(this);
		this.processSubpoints = this.processSubpoints.bind(this)
		this.processWorkBox = this.processWorkBox.bind(this);
		this.setAnswerValue = this.setAnswerValue.bind(this);

		this.state = {
			isPageLoading: true,
			isNextProcessing: false,
			isPreviousProcessing: false,
			isValidated: false,
			navigationModel: {
				showPrevious: false,
				showNext: false
			},
			answers: {
				assetId: '',
				assetType: '',
				rootApAssetId: '',
				responses: []
			},
			components: []
		};
	}

	componentDidMount() {
		this.props.onCurrent();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.workbox !== this.props.workbox) {
			this.handleWorkbox();
		}
	}

	// We have to manually validate each component ourselves due to some limitation of the browser
	validate = (formElement) => {
		const formLength = formElement.length;

		let isValid = true;

		let componentLength = this.state.components.length;
		for (let j = 0; j < componentLength; j++) {
			var component = this.state.components[j];
			if (component.ref && component.ref.current && component.ref.current.validate) {
				isValid = component.ref.current.validate();
			}
		}

		if (isValid) {
			isValid = formElement.checkValidity();
			for (let i = 0; i < formLength; i++) {
				const element = formElement[i];
				let answerClassName = ".answer";
				let answer = null;
				if (element.closest === undefined) {
					answer = this.ie11closest(element, answerClassName);
				} else {
					answer = element.closest(answerClassName);
				}
				if (answer) {
					const errorLabel = answer.querySelector('.invalid-feedback');

					if (errorLabel && element.nodeName.toLowerCase() !== 'button') {
						if (!element.validity.valid) {
							errorLabel.textContent = element.validationMessage;
						} else {
							errorLabel.textContent = '';
						}
					}
				}
			}
		}
		return isValid;
	}

	ie11closest(element, className) {
		var el = element;

		do {
			if (el.msMatchesSelector(className)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	}

	handleChange = (event) => {
		const { name, value, unitOfMeasurementValue } = event.target;
		var answers = { ...this.state.answers };
		var response = {
			name: name,
			answer: [],
			unitOfMeasurement: unitOfMeasurementValue
		};
		response.answer = [].concat(value);
		answers.responses[name] = response;
		this.setState({ answers });
	};

	handleNextClick = (event) => {
		event.preventDefault();

		if (this.validate(event.target)) {
			this.setState({ isNextProcessing: true });
			let answerModel = {
				appliedPathwaysState: this.props.appliedPathwaysState,
				assetAnswers: this.state.answers
			};
			let data = qs.stringify(answerModel);
			this.props.onNext(data);
		}
		this.setState({ isValidated: true });
	};

	handlePreviousClick = (event) => {
		event.preventDefault();

		this.setState({ isPreviousProcessing: true });
		let answerModel = {
			appliedPathwaysState: this.state.appliedPathwaysState,
			assetAnswers: this.state.answers
		};
		let data = qs.stringify(answerModel);
		this.props.onPrevious(data);
	};

	processSubpoints(recommendedAsset) {
		if (recommendedAsset === null || recommendedAsset.ap_assets[0] === null) {
			return null;
		}
		var asset = recommendedAsset.ap_assets[0];
		if (asset.type !== AppliedPathwaysEnums.assetTypes.recommendation) {
			return null;
		}

		// Clean up applied pathways namespaces
		let subpoints = asset.ap_subpoints.map((item) => {
			let cleanSubpointName = item.name;
			let dashIndex = item.name.indexOf("-");
			if (dashIndex > -1) {
				cleanSubpointName = item.name.substring(dashIndex + 1);
			}

			return {
				key: cleanSubpointName.toLowerCase(),
				value: item.ap_literacy.base
			};
		});
		return subpoints;
	}

	handleWorkbox() {
		let completedWorkflow = false;
		let workbox = this.props.workbox;
		completedWorkflow = this.processWorkBox(workbox);
		if (!completedWorkflow) {
			this.setState({ isProcessing: false, isNextProcessing: false, isPreviousProcessing: false });

		} else {
			let subpoints = this.processSubpoints(this.props.workbox);
			this.props.onComplete(subpoints, this.props.appliedPathwaysState.workboxId);
		}
	}

	generateComponents(asset) {
		let components = [];
		let ref = React.createRef();
		switch (asset.type) {
			case AppliedPathwaysEnums.assetTypes.form:
				components.push(<AppliedPathwaysForm onChange={this.handleChange} onInitialState={this.setInitialState} asset={asset} ref={ref} key={asset.id} />);
				break;
			case AppliedPathwaysEnums.assetTypes.standard_question:
			case AppliedPathwaysEnums.assetTypes.text_question:
			case AppliedPathwaysEnums.assetTypes.value_question:
				components.push(<AppliedPathwaysQuestion onChange={this.handleChange} asset={asset} ref={ref} key={asset.id} />);
				break;
			case AppliedPathwaysEnums.assetTypes.message:
				components.push(<AppliedPathwaysMessage asset={asset} ref={ref} key={asset.id} />);
				break;
			case AppliedPathwaysEnums.assetTypes.recommendation:
				components.push(<AppliedPathwaysRecommendationMessage asset={asset} ref={ref} key={asset.id} />);
				break;
			case AppliedPathwaysEnums.assetTypes.hotspot:
				components.push('HOTSPOT ASSET NOT SUPPORTED!');
				break;
			default:
				components.push('NOT SUPPORTED ASSET: ' + asset.type);
				break;
		}
		this.setState({ components });
	}

	processWorkBox(workbox) {
		if (workbox.ap_assets === undefined) {
			return false;
		}
		let asset = workbox.ap_assets[0]; // Applied Pathways always returns a single element in an array of assets
		var initialAnswers = this.generateInitialAnswers(asset);
		let showNext = true;
		if (asset.type === AppliedPathwaysEnums.assetTypes.recommendation) {
			if (asset.ap_subpoints.length > 0) {
				return true;
			}
			showNext = false;
		}
		this.generateComponents(asset);
		this.setState({
			appliedPathwaysState: this.props.appliedPathwaysState,
			asset: asset,
			answers: {
				assetId: asset.id,
				assetType: asset.type,
				rootApAssetId: asset.id,
				...initialAnswers
			},
			navigationModel: {
				showPrevious: !asset.is_first_in_path && asset.type.toLowerCase() !== AppliedPathwaysEnums.assetTypes.recommendation,
				showNext: showNext
			},
			isPageLoading: false,
			isValidated: false,
		});
		return false;
	}

	generateInitialAnswers(asset) {
		let self = this;
		let initialAnswers = {
			responses: [],
		};
		switch (asset.type) {
			case AppliedPathwaysEnums.assetTypes.form:
				if (asset.ap_questions !== null) {
					asset.ap_questions.map(function (item) {
						self.setAnswerValue(item, initialAnswers);
						return true;
					});
				}
				break;
			case AppliedPathwaysEnums.assetTypes.standard_question:
			case AppliedPathwaysEnums.assetTypes.text_question:
			case AppliedPathwaysEnums.assetTypes.value_question:
				self.setAnswerValue(asset, initialAnswers);
				break;
			default:
				break;
		}
		return initialAnswers;
	}

	setAnswerValue(asset, answers) {
		let response = {
			name: asset.id,
			answer: [],
			unitOfMeasurement: null
		};
		switch (asset.sub_type) {
			case AppliedPathwaysEnums.assetSubTypes.yes_no:
			case AppliedPathwaysEnums.assetSubTypes.multiple_choice_single_answer:
				let value = asset.ap_answers.filter((item) => item.value);
				let result = null;
				if (value !== null && value.length > 0) {
					result = value[0].id;
				}
				response.answer.push(result);
				answers.responses[asset.id] = response;
				break;
			case AppliedPathwaysEnums.assetSubTypes.text:
				if (asset.value_user_response_measure_unit_values && asset.value_user_response_measure_unit_values !== null) {
					let year = asset.value_user_response_measure_unit_values[AppliedPathwaysEnums.dateFields.Year];
					let month = parseInt(asset.value_user_response_measure_unit_values[AppliedPathwaysEnums.dateFields.Month]) - 1;
					let day = asset.value_user_response_measure_unit_values[AppliedPathwaysEnums.dateFields.Day];
					let currentDate = moment().year(year).month(month).date(day).format("MM/DD/YYYY");
					response.answer.push(currentDate);
					response.unitOfMeasurement = asset.ap_measure_units[0].id;
				} else {
					response.answer.push(asset.value);
				}
				answers.responses[asset.id] = response;
				break;
			case AppliedPathwaysEnums.assetSubTypes.multiple_choice_multiple_answers:
				break;
			default:
				break;
		}
	}

	render() {
		let className = "";
		if (this.state.isValidated) {
			className = 'was-validated';
		}

		let appliedPathwaysPage = (this.state.isPageLoading && !this.props.error) ?
			<LoadingIndicator />
			:
			<div>
				{this.props.error &&
					<Alert color="danger">
						{this.props.error}
					</Alert>
				}
				{this.props.workbox && this.props.workbox.status === "validation_errors" &&
					<Alert color="danger">
						{this.props.workbox && this.props.workbox.ap_assets && this.props.workbox.ap_assets.map((item, key) =>
							<AppliedPathwaysErrorList key={key} list={item.ap_result_validation_errors} />
						)}
					</Alert>
				}
				{!this.props.error &&
					<Form onSubmit={this.handleNextClick} className={className} noValidate>
						{this.props.workbox &&
							this.state.components
						}
						<div>
							{this.state.navigationModel.showPrevious &&
								<Button type="button" color="secondary" onClick={this.handlePreviousClick} disabled={this.props.isLoading}>{(this.props.isLoading && this.state.isPreviousProcessing) ? <span><FontAwesomeIcon icon="spinner" spin /> Processing...</span> : <span>Previous</span>}</Button>
							}
							&nbsp;
							{this.state.navigationModel.showNext &&
								<Button type="submit" color="primary" disabled={this.props.isLoading}>{(this.props.isLoading && this.state.isNextProcessing) ? <span><FontAwesomeIcon icon="spinner" spin /> Processing...</span> : <span>Next</span>}</Button>
							}
						</div>
					</Form>
				}
			</div>
		return (
			<div className="pageBottom">
				{appliedPathwaysPage}
			</div>
		);
	}
}

AppliedPathwaysWorkflow.propTypes = {
	appliedPathwaysState: PropTypes.object.isRequired,
	error: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	onCurrent: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	onPrevious: PropTypes.func.isRequired,
	patient: PropTypes.object
};
