import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import {	
	Col,
	Collapse,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Navbar,
	NavbarToggler,
	Nav,
	Row,
	UncontrolledDropdown,
} from 'reactstrap';
import './NavHeader.scss';

export class NavHeader extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
			isUserOpen: false,
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render() {
		const showProfile = (auth) => {
			return auth.isAuthenticated ?
			<Navbar expand="md" dark>
				<NavbarToggler onClick={this.toggle} />
				<Collapse isOpen={this.state.isOpen} navbar className="justify-content-end">
					<Nav>
						<UncontrolledDropdown nav inNavbar>
							<DropdownToggle nav className="dropdowntoggle-profile">
								<span className="profile-greeting">Hi {auth.firstName}!</span>
									<FontAwesomeIcon icon={faUserCircle} size="lg" className="profile-icon" />
							</DropdownToggle>
								<DropdownMenu end>
									{!this.props.isSso &&
										<LinkContainer to="/user/profile">
											<DropdownItem>
												Profile
											</DropdownItem>
										</LinkContainer>
									}
									{!this.props.isSso &&
										<DropdownItem divider />
									}
								<LinkContainer to="/logout">
									<DropdownItem>
										Logout
									</DropdownItem>
								</LinkContainer>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
				</Collapse>
			</Navbar>
			:
			""
		};

		return (
				<Row className="g-0 header-height align-items-center">
					<Col lg="2">
						<img src={this.props.logoUrl} alt="Header Logo" className="header-logo" />
					</Col>
					<Col lg="8" className="text-center"></Col>
					<Col lg="2" className="text-end">
							{showProfile(this.props.auth)}
					</Col>
				</Row>


			// <Row noGutters className="d-flex">
			// 	<Col lg="2"></Col>
			// 	<Col lg="8" className="text-center">
			// 		<img src="/resources/header-logo3.png" alt="Header Logo" />	
			// 	</Col>
			// 	<Col lg="2" className="align-items-center">
			// 			{showProfile(this.props.auth)}
			// 	</Col>
			// </Row>
		);
	}
}

NavHeader.propTypes = {
	auth: PropTypes.object.isRequired,
	isSso: PropTypes.bool,
};

export default NavHeader;
