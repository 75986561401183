import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'reactstrap';
import { SchedulableOrderRow } from './index';

import './schedulableOrdersTable.css'

export default function SchedulableOrdersTable (props) {
    const { 
        onOrderCheck,
        ordersList,
        selectedOrdersList,
        disableAddOrders,
    } = props;    

    const rows = (ordersList && ordersList.map(order => {
        let isChecked = false;    
        if (Array.isArray(selectedOrdersList) && selectedOrdersList.length > 0) {
            isChecked = selectedOrdersList.includes(order.idExternal);
        }
        let isDisabled = !isChecked && disableAddOrders;
        return <SchedulableOrderRow order={order} onCheck={onOrderCheck} checked={isChecked} disabled={isDisabled} />
    }).reverse()) || [];
    
    const noResults = <Row><Col className="text-center">No results found!</Col></Row>;

    return (
        <React.Fragment>
            <Table striped={rows.length > 4} responsive className="orders-table">
                <thead className="order-form-table-header">
                    <tr>
                        <th>Add</th>
                        <th>Order Number</th>
                        <th>Visit Type</th>
                        <th>Referring Provider</th>
                        <th>Order Created</th>
                    </tr>
                </thead>
                <tbody>
                    { rows && rows.length > 0 ?
                        rows 
                        : noResults}
                </tbody>
            </Table>
        </React.Fragment>
    )
}  

SchedulableOrdersTable.propTypes = {
    onOrderCheck: PropTypes.func,
    ordersList: PropTypes.array,
    selectedOrdersList: PropTypes.array,
};
