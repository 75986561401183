import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
} from 'reactstrap';

import { DropdownSelectWithLabel, LabeledField, TextAreaWithLabel, } from '../common/standardComponents';
import {OrderStatusHistoryTable} from './';
import OrderVisitDetails from './orderVisitDetails';

import './orderDetails.css'

export default class OrderDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "1",
            showStatusReasonList: true,
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab});
        }
    }

    render() {
        const {
            isEditMode,
            onStatusListChange,
            onStatusNotesChange,
            onStatusReasonListChange,
            order,
            orderStatusDisplay,
            selectedOrderStatusNotes,
            selectedStatusCode,
            selectedStatusReasonId,
            careOrderHistory,
            isParentCreateOrder
        } = this.props;

        let createdByName = `${order.createdByFirstName} ${order.createdByLastName}`;
        let lastModByName = (order && order.lastModifiedByFirstName && order.lastModifiedByLastName) ? `${order.lastModifiedByFirstName} ${order.lastModifiedByLastName}` : `${order.lastUpdatedByFirstName} ${order.lastUpdatedByLastName}`;
        let isDetailsTabActive = this.state.activeTab === "1" ? "active" : undefined;
        let isHistoryTabActive = this.state.activeTab === "2" ? "active" : undefined;

        let statusList = this.props.statusOptionsList && this.props.statusOptionsList.map((s, i) => {
            return { id: s.code, name: s.name, key: i };
        });
        let statusReasonList = this.props.statusReasonOptionsList && this.props.statusReasonOptionsList
        .filter(r => r.code === selectedStatusCode)
        .map((r, i) => {
            return { id: r.id, name: r.name, key: i };
        });

        return (
            <div>
                <Col className="details-container">
                    <Row>
                        <Col lg="4">
                            { isEditMode ?
                                <Row>
                                    <Col lg="6">
                                        <DropdownSelectWithLabel optionList={statusList} id="statusId" label="Order Status" value={selectedStatusCode} isRequired shouldValidate={false} onChange={onStatusListChange} />
                                    </Col>
                                    <Col lg="6">
                                        <DropdownSelectWithLabel optionList={statusReasonList} id="statusReasonId" label="Reason" value={selectedStatusReasonId} isRequired shouldValidate={false} onChange={onStatusReasonListChange} />
                                    </Col>
                                </Row>
                            :   <Row>
                                    <Col lg="6">
                                        <LabeledField label="Order Status" value={orderStatusDisplay} />
                                    </Col>
                                    <Col lg="6">
                                        <LabeledField label="Reason" value={order.statusReason} />
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col lg="3">
                            <LabeledField label="Created By" value={createdByName} />
                        </Col>
                        <Col lg="3">
                            {isParentCreateOrder ? 
                                <LabeledField label="Order Entry Date" value={moment(order.entryDate).format("MM/DD/YYYY")} />
                                : <LabeledField label="Order Date" value={moment(order.orderDate).format("MM/DD/YYYY")} />
                            }
                        </Col>
                        <Col lg="2">
                            {isParentCreateOrder ?
                                <LabeledField label="Due Date" value={moment(order.dueDate).format("MM/DD/YYYY")} />
                                : <LabeledField label="Expiration Date" value={order.expirationDate != null ? moment(order.expirationDate).format("MM/DD/YYYY") : "-"} />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            { isEditMode ?
                                <TextAreaWithLabel onChange={onStatusNotesChange} maxLength={1000} id="statusNotes" label="Notes" value={selectedOrderStatusNotes} isRequired={false} shouldValidate={false} />
                            : <LabeledField label="Notes" value={order.notes} /> }
                        </Col>
                        <Col lg="3">
                            {isParentCreateOrder ?
                                <LabeledField label="Last Modified Date" value={moment(order.lastModifiedDate).format("MM/DD/YYYY")} />
                                : <LabeledField label="Last Updated Date" value={moment(order.updatedAt).format("MM/DD/YYYY")} />
                            }
                        </Col>
                        <Col lg="3">
                            {isParentCreateOrder ?
                                <LabeledField label="Last Modified By" value={lastModByName} />
                                : <LabeledField label="Last Updated By" value={lastModByName} />
                            }
                        </Col>
                    </Row>
                </Col>
                <Col className="order-details-tab-container">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={isDetailsTabActive} onClick={() => this.toggle("1")}>
                                Details
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={isHistoryTabActive} onClick={() => this.toggle("2")}>
                                History
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            { this.props.order.careOrderVisits.length > 0 &&
                                this.props.order.careOrderVisits.map((cov) => {
                                    return (
                                        <div key={cov.id}><OrderVisitDetails visit={cov}/></div>
                                    );
                                })
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            <OrderStatusHistoryTable orderHistory={careOrderHistory} />
                        </TabPane>
                    </TabContent>
                </Col>
            </div>
        )
    }
}

OrderDetails.propTypes = {
    cancelLabel: PropTypes.string,
    confirmLabel: PropTypes.string,
    isEditMode: PropTypes.bool,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onStatusListChange: PropTypes.func,
    onStatusNotesChange: PropTypes.func,
    onStatusReasonListChange: PropTypes.func,
    onConfirm: PropTypes.func,
    onEdit: PropTypes.func,
	order: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        statusCode: PropTypes.string,
        createdByFirstName: PropTypes.string,
        createdByLastName: PropTypes.string,
        notes: PropTypes.string,
        entryDate: PropTypes.instanceOf(Date),
        dueDate: PropTypes.instanceOf(Date),
        lastModifiedDate: PropTypes.instanceOf(Date),
        lastModifiedByFirstName: PropTypes.string,
        lastModifiedByLastName: PropTypes.string,
        careOrderVisits: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            systemDateRangeName: PropTypes.string,
            careOrderVisitAppointments: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                siteName: PropTypes.string,
                providerFirstName: PropTypes.string,
                providerLastName: PropTypes.string,
                appointmentType: PropTypes.string,
            })),
        })),
	}),
    orderStatusDisplay: PropTypes.string,
    selectedOrderStatusNotes: PropTypes.string,
    selectedStatusCode: PropTypes.string,
    selectedStatusReasonId: PropTypes.number,
    showStatusReasonList: PropTypes.bool,
    statusOptionsList: PropTypes.array,
    statusReasonOptionsList: PropTypes.array,
    title: PropTypes.string,
}
