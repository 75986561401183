/**
 * @format
 */

import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "../features/searchAsync/availabilitySlice";

export const scrollToPageTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const defaultBookingDisabledMessage = 'Booking is currently disabled for this patient.';

//this is currently used for local sitename and servicename searches on calendars, could be moved/replaced
export const stringContains = (searchIn: string, searchFor: string) => {
	return searchIn.toLowerCase().indexOf(searchFor.toLowerCase()) !== -1;
};

export const isOdd = (input: number) => input % 2 !== 0;

export const generateDateArray = (minDate: string, maxDate: string) => {
	const dateArray = [];
	for (let start = dayjs(minDate); start.diff(maxDate, 'd') <= 0; start = start.add(1, 'd')) {
		dateArray.push(start.format(DEFAULT_DATE_FORMAT))
	}
	return dateArray;
}

export const formatStartTime = (startTime: any) => {
	let timeHour = startTime.substring(0, 2).replace(/^0+/, '');
	let timePart = timeHour < 12 ? 'am' : 'pm';
	timeHour = timeHour === '' ? '12' : timeHour;
	let timeMin = startTime.substring(3, 5);
	return timeHour > 12 ? `${timeHour - 12}:${timeMin} ${timePart}` : `${timeHour}:${timeMin} ${timePart}`;
};