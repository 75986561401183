import React from 'react';
import { connect } from 'react-redux';

import {
	clearError,
	clearAllErrors,
} from '../../actions/errorActions';

import {
	Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
export class ActiveErrors extends React.Component {

	dismiss = (e, actionId) => {
		e.preventDefault();
		this.props.dispatch(clearError(actionId));
	}

	dismissAll = () => {
		this.props.dispatch(clearAllErrors());
	}

	render() {
		return (
			<div>
				{this.props.activeErrors.map((currentError, i) =>
					<Alert key={i} color="danger" isOpen={true} toggle={(event) => this.dismiss(event, currentError.actionId)}>
						{currentError.error.detail}
						{this.props.errorLink &&
							<div>
								<Link to={this.props.errorLink}>{this.props.errorLinkText}</Link>
							</div>
						}
					</Alert>
				)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeErrors: state.apiErrors.activeErrors,
	};
}

export default connect(mapStateToProps)(ActiveErrors);
