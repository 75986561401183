import React, { useRef, useMemo, useCallback, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import './ag-theme-eh.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { v4 as uuidv4 } from 'uuid';
import LoadingIndicator from '../loadingIndicator';

ModuleRegistry.registerModules([ServerSideRowModelModule]);
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-042619 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Experian )_is_granted_a_( Multiple Applications )_Developer_License_for_( 10 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 2 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 July 2024 )____[v2]_MTcyMDkxMTYwMDAwMA==3b4e8eb316b5d5cdd9138baab3d0d0cb");

export function DataGrid({
	autoHeight = false,
	columnDefs,
	customGridSearch,
	data = [],
	defaultPageSize = 10,
	editable = false,
	enableColumnFilters = false,
	gridHeading,
	gridKey,
	getRowHeight,
	height = 200,
	headerHeight,
	noRowsMessage = 'There are no results',
	pagination = true,
	resizable = true,
	rowMultiSelectWithClick = false,
	sortable = true,
	rowSelection = 'single',
	suppressRowClickSelection = true,
	setSelectedRows,
	serverSideDatasource,
	getRowStyle,
	getRowClass,
	onRowClick,
}) {
	const [pageSize, setPageSize] = useState(defaultPageSize);
	const gridRef = useRef(null);
	const heightValue = autoHeight ? 'auto' : height + 'px';
	const containerStyle = useMemo(() => ({ height: heightValue }), [heightValue]);

	const gridOptions = useMemo(() => {
		const options = {
			defaultColDef: {
				resizable: resizable,
				sortable: sortable,
				editable: editable,
				filter: enableColumnFilters,
			},
			domLayout: autoHeight ? 'autoHeight' : 'normal',
			getRowHeight: getRowHeight,
			loadingOverlayComponent: LoadingIndicator,
			noRowsOverlayComponent: NoRowsOverlay,
			noRowsOverlayComponentParams: { noRowsMessage: noRowsMessage },
			paginationPageSize: pageSize,
			...(serverSideDatasource && {
				cacheBlockSize: pageSize,
				rowModelType: 'serverSide',
				getRowId: () => uuidv4(),
				serverSideSortOnServer: true,
				serverSideDatasource: serverSideDatasource,
			}),
			getRowStyle: getRowStyle,
			getRowClass: getRowClass,
			...(serverSideDatasource === undefined && { rowData: data }),
		};
		return options;
	}, [
		resizable,
		sortable,
		editable,
		enableColumnFilters,
		autoHeight,
		getRowHeight,
		noRowsMessage,
		pageSize,
		serverSideDatasource,
		data,
		getRowStyle,
		getRowClass,
	]);

	const onFirstDataRendered = useCallback(() => {
		gridRef.current?.api.sizeColumnsToFit();
	}, [gridRef]);

	const handlePageSizeChanged = useCallback((event) => {
		const newPageSize = Number(event.target.value);
		setPageSize(newPageSize);
		gridRef.current?.api.paginationSetPageSize(newPageSize);
		gridRef.current?.api.setCacheBlockSize(newPageSize);
	}, [gridRef]);

	const cellClicked = useCallback((event) => {
		const colId = event.column.getId();
		if (!suppressRowClickSelection) {
			gridRef.current?.api.showLoadingOverlay();

			if (colId !== 'button') {
				if (onRowClick) {
					const selectedRows = gridRef.current?.api.getSelectedRows();
					onRowClick(event.event, selectedRows[0]);
				}
			}
		}
	}, [gridRef, onRowClick, suppressRowClickSelection]);

	return (
		<div className="eh-grid-container">
			{gridHeading &&
				<div className="eh-grid-header">
					{gridHeading && <h4 className="eh-grid-heading">{gridHeading}</h4>}
					{customGridSearch && <div className='eh-grid-filter'>{customGridSearch}</div>}
				</div>
			}
			<div className="ag-theme-alpine ag-theme-eh" style={containerStyle}>
				<AgGridReact
					columnDefs={columnDefs}
					gridOptions={gridOptions}
					headerHeight={headerHeight}
					key={gridKey}
					modules={[ClientSideRowModelModule, ServerSideRowModelModule]}
					onFirstDataRendered={onFirstDataRendered}
					onCellClicked={(e) => cellClicked(e)}
					pagination={pagination}
					paginationPageSize={defaultPageSize}
					ref={gridRef}
					rowSelection={rowSelection}
					rowMultiSelectWithClick={rowMultiSelectWithClick}
					suppressRowClickSelection={suppressRowClickSelection}
					rowData={gridOptions.rowData}
					suppressBrowserResizeObserver={true}
				/>
				<div className='eh-grid-dropdown'>
					<label>
						<select onChange={handlePageSizeChanged} defaultValue={pageSize} className="eh-grid-form-control eh-grid-page-size" id="grid-page-size">
							<option value="10">10 per Page</option>
							<option value="25">25 per Page</option>
							<option value="50">50 per Page</option>
							<option value="100">100 per Page</option>
						</select>
					</label>
				</div>
			</div>
		</div>
	);
};

const NoRowsOverlay = (props) => {
	return (
		<strong className="ag-overlay-no-rows-center">{props.noRowsMessage}</strong>
	)
}
