import React, { useEffect, useMemo } from 'react';
import { Table } from 'reactstrap';

/**
 * @typedef {{
 *  columns: any[];
 *  data: TData[];
 *  startSortColumn?: number;
 *  resultsPerPage?: number;
 *  loading?: boolean;
 *  loadingMessage?: string;
 *  header?: import('react').ReactNode | ((data: TData[]) => import('react').ReactNode);
 * emptyResultsMessage?: string
 * } & import('reactstrap').TableProps} DataTableProps
 *
 * @template TData
 */

/**
 *
 * @param {DataTableProps<TData>} props
 * @template TData
 * @returns
 */
export default function DataTable({
  columns,
  data,
  startSortColumn,
  loading,
  loadingMessage,
  className,
  header,
  emptyResultsMessage,
  showOverflow,
  ...tableProps
}) {

  const normalizedColumns = useMemo(() => normalizeColumns(columns), [columns]);

  const dataToRender = useMemo(() => {
      return data;
  }, [data]);

  useEffect(() => { }, [data]);

  return (
      <div className={`d-flex flex-column ${className ?? ''} ${showOverflow ? 'scrollable-x-container' : ''} w-100`}>
        <div className="d-flex align-items-center mb-2">
          {header && <div className="col p-0">{header}</div>}
        </div>
        <Table striped bordered {...tableProps} className="mb-0">
          <thead>
            <tr>
              {normalizedColumns.map(
                ({ header, sorter }, index) => (
                  <th
                    key={index}
                    style={{ cursor: sorter ? 'pointer' : '' }}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        {header}
                      </div>
                    </div>
                  </th>
                ),
              )}
            </tr>
          </thead>

          <tbody>
            {!loading ? (
              data.length ? (
                dataToRender.map((row, rowIndex) => {
                  return (
                    <tr key={rowIndex}>
                      {normalizedColumns.map(({ renderer }, columnIndex) => (
                        <td key={columnIndex}>{renderer(row, rowIndex)}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={normalizedColumns.length}
                    style={{ textAlign: 'center' }}
                  >
                    <i>
                      <b>{emptyResultsMessage || 'There are no items'}</b>
                    </i>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={normalizedColumns.length}>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
  );
}

export function normalizeColumns(columns) {
  return columns.map((column) => {
    const renderer =
      typeof column.render === 'function'
        ? column.render
        : (row) => row[column.render];

    return { ...column, renderer };
  });
}
