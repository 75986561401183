import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

import OrderDetails from '../orderManagement/orderDetails';
import PatientDetailsStrip from '../patient/patientDetailsStrip';
import { SaveCancelActionBar } from '../common/standardComponents';

import './orderManagementModals.css'

export default function OrderDetailsModal(props) {
	const {
			isEditMode,
			isOpen,
			isSaveDisabled,
			onConfirm,
			onCancel,
			onStatusNotesChange,
			onEdit,
			onStatusListChange,
			onStatusReasonListChange,
			order,
			orderStatusDisplay,
			patientConfig,
			selectedOrderStatusNotes,
			selectedStatusCode,
			selectedStatusReasonId,
			statusOptionsList,
			statusReasonOptionsList,
			isSavingEdit,
			careOrderHistory,
			isParentCreateOrder
		 } = props;
	let patient = {
		firstName: order.patientFirstName,
		lastName: order.patientLastName,
		dateOfBirth: order.patientDateOfBirth,
		homePhone: order.patientHomePhone,
		memberId: order.patientMRN? order.patientMRN : order.patientMemberId,
		mobilePhone: order.patientMobilePhone,
		alternatePhone: order.patientAlternatePhone,
	};
	return (
		<Modal className="order-details-modal-width" centered isOpen={isOpen} toggle={onCancel}>
			<ModalHeader toggle={onCancel}>
			<Row>
				<Col xs="auto" className="my-auto">
					<h4 align="center">Follow Up Order Details <FontAwesomeIcon className="order-details-edit-icon" icon="edit" onClick={onEdit} size="lg" /></h4>
				</Col>
			</Row>
			</ModalHeader>
			<ModalBody className="order-details-modal">
				<div>
					<Row>
						<Col>
							<PatientDetailsStrip patientDetails={patient} config={patientConfig} />
						</Col>
					</Row>
					<Row>
						<Col>
							<OrderDetails
								isEditMode={isEditMode}
								onStatusNotesChange={onStatusNotesChange}
								onStatusListChange={onStatusListChange}
								onStatusReasonListChange={onStatusReasonListChange}
								order={order}
								orderStatusDisplay={orderStatusDisplay}
								selectedOrderStatusNotes={selectedOrderStatusNotes}
								selectedStatusCode={selectedStatusCode}
								selectedStatusReasonId={selectedStatusReasonId}
								statusOptionsList={statusOptionsList}
								statusReasonOptionsList={statusReasonOptionsList}
								careOrderHistory={careOrderHistory}
								isParentCreateOrder={isParentCreateOrder}
								/>
						</Col>
					</Row>
				</div>
			</ModalBody>
			<ModalFooter>
				<Row className="justify-content-end">
					<Col xs="auto" className="order-details-SaveCancel-container">
						{ isEditMode &&
							<SaveCancelActionBar style={{marginTop: "0px"}} isDisabled={isSaveDisabled} isBusy={isSavingEdit} onSave={onConfirm} onCancel={onCancel} />
						}
					</Col>
				</Row>
			</ModalFooter>
		</Modal>
	);
}
