import React, { Component } from 'react';
import DropdownList from '../input/dropdownList';

class AppliedPathwaysMultipleChoiceSingleAnswer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			value: '',
			invalid: false
		}
	}

	validate = () => {
		var isRequired = this.props.asset.is_required;
		if (isRequired && this.state.value === '') {
			this.setState({ invalid: true })
			return false;
		}
		this.setState({ invalid: false })
		return true;
	}

	handleChange = (selectedOption, event) => {
		const { onChange } = this.props

		if (event === null) {
			this.setState({ value: '', selected: [], invalid: true });
			return onChange({ target: { name: selectedOption, value: '' } });
		}

		const { value, name } = event;
		this.setState({ value: value, invalid: false })
		return onChange({ target: { name, value } });
	}

	static getSelectedAnswer(asset) {
		var selectedAnswer = '';
		asset.ap_answers.map((item) => {
			if (item.value) {
				selectedAnswer = item.id;
			}
			return null;
		});
		return selectedAnswer;
	}

	componentDidMount() {
		var selectedAnswer = AppliedPathwaysMultipleChoiceSingleAnswer.getSelectedAnswer(this.props.asset);
		if (selectedAnswer === '' && this.props.asset && this.props.asset.ap_answers && !this.props.asset.is_required) {
			selectedAnswer = this.props.asset.ap_answers[0];
		}
		this.setState({ asset: this.props.asset, value: selectedAnswer })
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.asset === prevState.asset) {
			return null;
		}
		var selectedAnswer = AppliedPathwaysMultipleChoiceSingleAnswer.getSelectedAnswer(nextProps.asset);
		if (selectedAnswer === prevState.value) {
			return null;
		}
		return {
			asset: nextProps.asset,
			value: selectedAnswer
		};
	}

	render() {
		var questionControlName = this.props.asset.id;
		var isRequired = this.props.asset.is_required;
		var className = "ap-question inputfield";

		let visibleAnswers = this.props.asset.ap_answers.filter(function (item, index, arr) { return item.is_hidden === false });
		let answers = visibleAnswers.map((item, key) => {
			return { name: questionControlName, value: item.id, text: item.ap_literacy.base };
		});

		return (
			<DropdownList
				id={questionControlName}
				name={questionControlName}
				className={className}
				feedback="Please select an option"
				maxheight={250}
				placeholder="Select an option"
				required={isRequired}
				list={answers}
				listkey={"text"}
				listvalue={"value"}
				value={this.state.value}
				invalid={this.state.invalid}
				onChange={this.handleChange}
			/>
		);
	}
}

export default AppliedPathwaysMultipleChoiceSingleAnswer;
