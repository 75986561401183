import * as React from 'react';
import PropTypes from 'prop-types';
import DropdownList from '../input/dropdownList';
import { DisclaimerHtml } from '../../nighthawk/common/miscComponents';
import DateInput from '../input/dateInput';

import {
	Row,
	Col,
} from 'reactstrap';

export default function CustomFields(props) {

	const { customFields, customFieldAnswers, customFieldValidation, handleCustomFieldChange, handleCustomFieldDropdownChange, handleCustomFieldDateChange, customFieldsConfigInstructions } = props;

	return (
		<div>
			{customFieldsConfigInstructions && 
				<div>
					<Row>
						<Col lg="8">
							<h6 style={{ "marginBottom": "15px", "marginTop": "5px" }}>Custom Fields</h6>
						</Col>
					</Row>
					<Col className="container">
						<Row style={{ "marginBottom": "15px" }}>
							<Col lg="4" style={{ "marginTop": "5px" }}>
								<DisclaimerHtml className="form-instructions" isVisible={!!customFieldsConfigInstructions} text={customFieldsConfigInstructions} />
							</Col>
						</Row>
					</Col>
				</div>
			}
			{customFields ? (customFields.map((field, i) => (
				<Col className="container" key={i}>
					<Row style={{ "marginBottom": "15px" }}>
						<Col lg="4" style={{ "marginTop": "5px" }}>{field.fieldLabel}</Col>
						<Col lg="3">
							{(() => {
								switch (field.dataType) {
									case 'String':
										return (
											<div>
												<input
													className="form-control"
													type="text"
													name={customFields[i].fieldName}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(e) => { handleCustomFieldChange(e, field.dataType) }}
													maxLength={customFields[i].maxLength}
													id={field.fieldName}>
												</input>
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please enter a valid string</span>
												}
											</div>
										);
									case 'Boolean':
										return (
											<div>
												<DropdownList
													name={customFields[i].fieldName}
													id={customFields[i].fieldName}
													maxheight={250}
													placeholder="Please select an answer"
													list={[{ description: "Yes", value: "Yes" }, { description: "No", value: "No" }]}
													listkey={"description"}
													listvalue={"value"}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(name, obj) => { handleCustomFieldDropdownChange(name, obj); }} />
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please provide a valid response</span>
												}
											</div>
										);
									case 'DateTime':
										return (
											<div>
												<DateInput
													type="text"
													className="form-control"
													name={customFields[i].fieldName}
													id={customFields[i].fieldName}
													onChange={(e) => { handleCustomFieldDateChange(e, field.dataType) }}
													value={customFieldAnswers[customFields[i].fieldName]}
												/>
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please enter a valid date in format MM/DD/YYYY</span>
												}
											</div>
										);
									case 'Int32':
										return (
											<div>
												<input
													className="form-control"
													type="text"
													name={customFields[i].fieldName}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(e) => { handleCustomFieldChange(e, field.dataType) }}
													id={field.fieldName}>
												</input>
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please enter a valid number</span>
												}
											</div>

										);
									case 'Object':
										return (
											<div>
												<DropdownList
													name={customFields[i].fieldName}
													id={customFields[i].fieldName}
													maxheight={250}
													list={customFields[i].items}
													listkey={"description"}
													listvalue={"description"}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(name, obj) => { handleCustomFieldDropdownChange(name, obj); }} />
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please provide a valid response</span>
												}
											</div>
										);
									default:
										return null;
								}
							})()}
						</Col>
					</Row>
				</Col>
			))) : (null)}
		</div>
	);
}

CustomFields.propTypes = {
  customFieldAnswers: PropTypes.object,
  customFieldValidation: PropTypes.object,
  customFields: PropTypes.array,
  handleCustomFieldChange: PropTypes.func,
  handleCustomFieldDateChange: PropTypes.func,
  handleCustomFieldDropdownChange: PropTypes.func,
}
