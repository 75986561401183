//Third Party Dependencies
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions';
import qs from 'qs';

//MHD General Purpose libraries
import LoadingIndicator from '../../components/loadingIndicator';

export class SingleSignOnRedirector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alertMessage: "",
			error: "",
			showLoadingIndicator: true,
			showAlert: false
		};
		this.token = this.props.match.params.token;
		this.params = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
	}

	dismissAlert = () => {
		this.setState({ showAlert: false });
	}

	exchangeToken = () => {
		this.setState({ isExchangingToken: true }, () => {
			this.props.actions.auth.exchangeToken(this.token)
				.then((response) => {
					if (response.error) {
						this.setState({ showLoadingIndicator: false, error: "An error occurred while trying to exchange token." });
					} else {
						this.setState({ showLoadingIndicator: false });
					}
				})
				.catch((err) => {
					this.setState({ showLoadingIndicator: false, error: "An error occurred while trying to exchange token." });
				});
		});
	}

	componentDidMount() {
		this.exchangeToken();
	};

	componentDidUpdate(nextProps) {
		if (nextProps.auth.isAuthenticated) {
			let url = this.params.returnUrl || '/';
			window.location.href = url;
		}
	}

	render() {
		let { showLoadingIndicator } = this.state;

		return (
			<div>
				{showLoadingIndicator &&
					<LoadingIndicator loadingMessage="Processing Login..." />
				}
			</div>
		)
	}
};

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.auth,
		config: state.config
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			auth: bindActionCreators(authActions, dispatch),
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSignOnRedirector);
