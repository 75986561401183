import React from 'react';
import './NotFound.css';

const BaseNotFound = (props) => {
	return (
		<div className="NotFound">
			<h3>{props.message}</h3>
		</div>
	);
}

export const NotFound = () => {
	return (
		<BaseNotFound message="Sorry, page not found!" />
	);
};

export const PatientNotFound = () => {
	return (
		<BaseNotFound message="Patient not found" />
	)
}

export default NotFound;
