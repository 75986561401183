import * as actionTypes from './actionTypes';

export function getProviderByServiceSiteId(serviceSiteId) {
	return {
		type: actionTypes.PROVIDER_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `provider/${serviceSiteId}/details`
			}
		}
	};
}
