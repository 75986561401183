/**
 * @format
 */

import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Col, Container, Row } from 'reactstrap';
import { genderCodeToDisplayName } from '../../lib/standardLibrary';
import { scrollToPageTop } from '../../lib/misc';
import { calendarTypes } from '../../constants/calendarTypes';
import { PhoneNumber } from '../common/phoneNumber';
import './calendarDetails.css';

export default function CalendarDetails(props) {
	const {
		calendarDetails,
		handleGoBackToGuidedResponse,
		isLoading,
		isRequestAppointment,
		onCancel,
		providerFieldConfig,
		showNpi,
		showReturnToQuestionsButton,
	} = props;

	const providerConfig = {
		phone: providerFieldConfig.phone,
	};

	const resolveBioAsHtml = () => {
		return { __html: calendarDetails.bio && calendarDetails.bio.trim() !== '' ? calendarDetails.bio : '-' };
	};

	const resolveAdditionalServiceInformationAsHtml = () => {
		return {
			__html:
				calendarDetails.informationForPatient && calendarDetails.informationForPatient.trim() !== ''
					? calendarDetails.informationForPatient
					: '-',
		};
	};

	let calendarNameDesignation = calendarDetails.serviceName;

	let showAddress = !calendarDetails.suppressAddress;
	let showPhone = !calendarDetails.suppressPhoneNumber && providerConfig.phone && providerConfig.phone.isVisible;

	let address = `${calendarDetails.address1}${
		calendarDetails.address2 && calendarDetails.address2.trim() !== '' ? ` ${calendarDetails.address2},` : ','
	} ${calendarDetails.city}, ${calendarDetails.state} ${calendarDetails.zipCode}`;

	let phone =
		providerConfig.phone &&
		providerConfig.phone.isVisible &&
		!calendarDetails.suppressPhoneNumber &&
		calendarDetails.phone &&
		calendarDetails.phone.trim() !== '' ? (
			<PhoneNumber label={'Phone:'} value={calendarDetails.phone} />
		) : (
			''
		);

	let backButtonText = 'Return to Search Results';

	let isEquipment = calendarDetails.calendarType === calendarTypes.equipment;

	if (isLoading.anySearch || isLoading.isReserving) {
		scrollToPageTop();
	}

	return (
		<div className="content">
			<div>
				<Container className={`${isEquipment ? 'equipment' : 'provider'}-title-container`}>
					<Row>
						<Col lg="12">
							<div>
								<h4 className={`${isEquipment ? 'equipment-name' : 'provider-fullname'}-designation`}>
									{calendarNameDesignation}
								</h4>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg="8" className="my-auto">
							<span className="align-bottom">
								<h5 className="provider-address-phone">
									{showAddress && address}
									<span style={{ paddingLeft: '20px' }}>{showPhone && phone}</span>
								</h5>
							</span>
						</Col>
						<Col lg="4" className="back-button-container">
							{showReturnToQuestionsButton && (
								<Button color="secondary" className="back-button mr-2" onClick={handleGoBackToGuidedResponse}>
									Return to Questions
								</Button>
							)}
							<Button color="primary" className="back-button" onClick={onCancel}>
								{backButtonText}
							</Button>
						</Col>
					</Row>
				</Container>
				<Container className="provider-details-container">
					<Row>
						<Col lg="4">
							<img
								className="provider-image"
								src={calendarDetails.imageFile || '/resources/generic-provider-photo.jpg'}
								alt="Provider"
							/>
						</Col>
						<Col lg="2">
							{isEquipment && (
								<>
									<div>
										<p className="fw-bold form-label">Serial Number</p>
										{/* TODO HUH?? <p>{calendarDetails.specialty ? calendarDetails.serialNumber : '-'}</p>*/}
										<p>{calendarDetails.serialNumber || '-'}</p>
									</div>
									<div>
										<p className="fw-bold form-label">Asset Tag</p>
										{/* TODO HUH?? <p>{calendarDetails.specialty ? calendarDetails.assetTag : '-'}</p>*/}
										<p>{calendarDetails.assetTag || '-'}</p>
									</div>
								</>
							)}
							{!isEquipment && (
								<>
									{showNpi && (
										<div>
											<p className="fw-bold form-label">Provider NPI</p>
											<p>{calendarDetails.npi || '-'}</p>
										</div>
									)}
									<div>
										<p className="fw-bold form-label">Gender</p>
										<p>{genderCodeToDisplayName(calendarDetails.genderCode)}</p>
									</div>
									<div>
										<p className="fw-bold form-label">Specialty</p>
										<p>{calendarDetails.specialtyName || '-'}</p>
									</div>
									<div>
										<p className="fw-bold form-label">Language</p>
										{calendarDetails.languages?.length > 0 ? (
											<ul className="provider-languages">
												{calendarDetails.languages.map((lan, i) => {
													return <li key={uuidv4()}>{lan.name}</li>;
												})}
											</ul>
										) : (
											'-'
										)}
									</div>
								</>
							)}
						</Col>
						<Col lg="6">
							{isRequestAppointment && (
								<div>
									<p className="fw-bold form-label">{'Site'}</p>
									<p>{calendarDetails.siteName || '-'}</p>
								</div>
							)}
							<div>
								<p className="fw-bold form-label">{isEquipment ? 'Description' : 'Biography'}</p>
								<p className="bio-container" dangerouslySetInnerHTML={resolveBioAsHtml()} />
							</div>
							<div>
								<p className="fw-bold form-label">Additional Service Information</p>
								<p
									className="additionalServiceInfo-container"
									dangerouslySetInnerHTML={resolveAdditionalServiceInformationAsHtml()}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
