import React from 'react';

const tableBorder = {border: '1px solid black'};

export function GuidedResponseDSVResult(props) {
	const { field } = props;

	return (
		<tr>
			<td style={tableBorder}>{field.questionAndAnswerFieldName}</td>
			<td style={tableBorder}>{field.questionText}</td>
            <td style={tableBorder}>{field.answerDisplayName}</td>
			<td style={tableBorder}>{field.decisionSupportVerbiageSource}</td>
			<td style={tableBorder}>{field.decisionSupportVerbiageDestination}</td>
		</tr>
	);
}

export function GuidedResponseDSVList(props) {
	const { dsvResults } = props;
    
	return (
		<div  style={{marginTop: '1em', marginBottom: '1em'}}>
			<table>
				<thead>
					<tr>
						<th style={tableBorder}>Question Field Name</th>
						<th style={tableBorder}>Question Text</th>
						<th style={tableBorder}>Answer</th>
						<th style={tableBorder}>DSV Source</th>
                        <th style={tableBorder}>DSV Destination</th>
					</tr>
				</thead>
				<tbody>
					{dsvResults && dsvResults.length > 0 ? dsvResults.map((field, i) => (
						<GuidedResponseDSVResult key={`dsvResult-${field.questionAndAnswerFieldName}-${i}`} field={field}/>
					)): <tr style={tableBorder}><td colSpan={4} style={{textAlign: 'center'}}>No results</td></tr>}
				</tbody>
			</table>
		</div>
	);
}