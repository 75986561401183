/**
 * @format
 */

import * as actionTypes from './actionTypes';

function publishErrorAction(actionId, error) {
	return {
		type: actionTypes.PUBLISH_ERROR,
		actionId: actionId,
		error: error,
	};
}

function clearErrorAction(actionId) {
	return {
		type: actionTypes.CLEAR_ERROR,
		actionId: actionId,
	};
}

function clearAllErrorsAction() {
	return {
		type: actionTypes.CLEAR_ALL_ERRORS,
	};
}

// EXPORTS

export function publishError(actionId, error) {
	return function (dispatch) {
		dispatch(publishErrorAction(actionId, error));
	};
}

export function clearError(actionId) {
	return function (dispatch) {
		dispatch(clearErrorAction(actionId));
	};
}

export function clearAllErrors() {
	return function (dispatch) {
		dispatch(clearAllErrorsAction());
	};
}
