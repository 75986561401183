import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import PatientAppointmentHistoryFilter from "./patientAppointmentHistoryFilter";
import PatientAppointmentHistoryGrid from "./patientAppointmentHistoryGrid";
import { AppointmentHistoryFilters } from "./patientAppointmentHistory";

import '../css/patientHistory.css';

const PatientUpcomingAppointment = (
    {
        showLoseProgressWarning,
        enableShowPSAandMHDfilters,
        activeTab
    }: {
        showLoseProgressWarning: boolean,
        enableShowPSAandMHDfilters: boolean,
        activeTab?: any
    }
) => {
    const appointmentHistoryRecords = useSelector((state: any) => state.activePatient.history);
    const upcomingAppointments = appointmentHistoryRecords?.filter((upcomingAppointment: any) =>
        dayjs(upcomingAppointment.appointmentDate) >= dayjs()
    ).sort((a: any, b: any) => {
        const dateA = new Date(a.appointmentDate);
        const dateB = new Date(b.appointmentDate);
        return +dateA - +dateB;
    });

    const [filters, setFilters] = useState<AppointmentHistoryFilters>(
        {
            hideMHD: false,
            hidePSA: false,
            includeCanceled: false
        }
    )

    useEffect(() => {
        if (activeTab?.reloadTabContent) {
            setFilters({
                hideMHD: false,
                hidePSA: false,
                includeCanceled: false
            });
        }
    }, [activeTab]);

    //TODO better name
    const showPSAandMHDfilters = enableShowPSAandMHDfilters
        && upcomingAppointments?.some((x: any) => x.scheduleMethod === "PSA")
        && upcomingAppointments?.some((x: any) => x.scheduleMethod === "MHD");

    return (
        <div>
            <div>
                {upcomingAppointments?.length > 0 &&
                    <div className="col" style={{ padding: '0px' }}>
                        <PatientAppointmentHistoryFilter
                            filters={filters}
                            setFilters={setFilters}
                            showPSAandMHDfilters={showPSAandMHDfilters}
                        />
                        <PatientAppointmentHistoryGrid
                            appointmentHistoryRecords={upcomingAppointments}
                            filters={filters}
                            showLoseProgressWarning={showLoseProgressWarning}
                            activeTab={activeTab}
                        />
                    </div>
                }
                {upcomingAppointments && (upcomingAppointments.length === 0) &&
                    <PatientUpcomingAppointmentsEmptyList />
                }
            </div>
        </div>
    )
}

function PatientUpcomingAppointmentsEmptyList() {
    return (
        <div>
            <h5 className="history-result-header">No upcoming appointments found.</h5>
        </div>
    );
}

export default PatientUpcomingAppointment;
