import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LoadingIndicator from '../loadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import DateInput from '../input/dateInput';
import { CheckboxLabelLeft, DropdownSelectWithLabel, SaveCancelActionBar, TextInputWithLabel, } from '../common/standardComponents';
import Calendar from 'react-calendar';

import {
    Card,
    CardHeader,
    Col,
    Collapse,
    Label,
    Popover,
    Row,
} from 'reactstrap';

import 'react-calendar/dist/Calendar.css';
import './orderHistoryFilter.css'

export default class OrderHistoryFilter extends React.Component {

    legacyToggle = (name, isOpen) => {
        if (isOpen === true) {
			this.props.toggleCalendar(name);
		}
    }

    handleDueDateFromPickerChange = (date) => {
        this.props.handleDatePickerChange("dueDateFrom", date);
     }

     handleDueDateToPickerChange = (date) => {
        this.props.handleDatePickerChange("dueDateTo", date);
     }

     handleOrderEntryDateToPickerChange = (date) => {
        this.props.handleDatePickerChange("orderEntryDateTo", date);
     }

     handleOrderEntryDateFromPickerChange = (date) => {
        this.props.handleDatePickerChange("orderEntryDateFrom", date);
     }

     handleLastModifiedDateFromPickerChange = (date) => {
        this.props.handleDatePickerChange("lastModifiedDateFrom", date);
     }

     handleLastModifiedDateToPickerChange = (date) => {
        this.props.handleDatePickerChange("lastModifiedDateTo", date);
     }

     buildInternalStatusReasonList = () => {
        let internalList = [{id: 0, name: "All", key: -1}];
        if (this.props.statusReasonList !== null) {
            this.props.statusReasonList.forEach((s, i) => {
                internalList.push( { id: s.id, name: s.name, key: i });
            });
        }
        return internalList;
     }

     buildInternalSiteList = () => {
        let internalList = [{id: "", name: "All", key: -1}];
        if (this.props.siteList !== null) {
            this.props.siteList.forEach((s, i) => {
                internalList.push( { id: s.id, name: s.name, key: i });
            });
        }
        return internalList;
     }

    render() {
        let {
            dueDateFromIsOpen,
            dueDateToIsOpen,
            dueDateFromValue,
            dueDateFromIsValid,
            dueDateToValue,
            dueDateToIsValid,
            isFilterOpen,
            isLoaded,
            isOverdue,
            lastModifiedDateFromValue,
            lastModifiedDateFromIsValid,
            lastModifiedDateFromIsOpen,
            lastModifiedDateToValue,
            lastModifiedDateToIsValid,
            lastModifiedDateToIsOpen,
            memberId,
            memberIdLabel,
            memberIdMaxLength,
            onChange,
            onDateChange,
            onReset,
            onSearch,
            orderEntryDateFromValue,
            orderEntryDateFromIsValid,
            orderEntryDateFromIsOpen,
            orderEntryDateToValue,
            orderEntryDateToIsValid,
            orderEntryDateToIsOpen,
            selectedSiteId,
            selectedStatusReasonId,
            toggleCalendar,
            toggleFilter,
        } = this.props;

        let chevronRight = <FontAwesomeIcon style={{"marginLeft": "10px"}} icon="chevron-right" size="sm" />;
        let chevronDown = <FontAwesomeIcon style={{"marginLeft": "10px"}} icon="chevron-down" size="sm" />;
        let chevronSearchCriteria = isFilterOpen ? chevronDown : chevronRight;

        let internalStatusReasonList = this.buildInternalStatusReasonList();
        let internalSiteList = this.buildInternalSiteList();
        return (
            <div className="content">
                <Row className="g-0 sectionRow">
                    <Col lg="12">
                        { !isLoaded &&
                            <LoadingIndicator />
                        }
                        { isLoaded &&
                            <Card>
                                <CardHeader className="cardHeader" onClick={toggleFilter}><h5>Open Order Search {chevronSearchCriteria}</h5></CardHeader>
                                <Collapse className="collapse" isOpen={isFilterOpen}>
                                    <Row className="sectionRow">
                                        <Col lg="3">
                                            <TextInputWithLabel id="memberId" label={memberIdLabel} value={memberId} maxLength={memberIdMaxLength} onChange={onChange} />
                                        </Col>
                                    </Row>
                                    <Row className="sectionRow">
                                        <Col lg="4">
                                            <DropdownSelectWithLabel optionList={internalSiteList} id="selectedSiteId" label="Department" value={selectedSiteId} onChange={onChange} />
                                        </Col>
                                        <Col lg="4">
                                            <DropdownSelectWithLabel optionList={internalStatusReasonList} id="selectedStatusReasonId" label="Order Status Reason" value={selectedStatusReasonId} onChange={onChange} />
                                        </Col>
                                    </Row>
                                    <Row className="sectionRow">
                                        <Col sm="4" className="form-group">
                                            <Label className="fw-bold">EXPIRATION DATE</Label>
                                            <Row>
                                                <Col>
                                                    <DateInput
                                                        type="text"
                                                        name="dueDateFrom"
                                                        id="dueDateFrom"
                                                        placeholder="From..."
                                                        onChange={onDateChange}
                                                        value={dueDateFromValue}
                                                        className={"form-control " + (dueDateFromIsValid ? undefined : 'is-invalid')} />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid date.
                                                    </div>
                                                </Col>
                                                <Col lg="1" className="dateIconCol">
                                                    <FontAwesomeIcon className="calendarIcon" id="dueDateFrom" icon={faCalendarAlt} size="lg" onClick={(e) => toggleCalendar("dueDateFrom")} />
                                                    <Popover placement="right" isOpen={dueDateFromIsOpen} target="dueDateFrom" toggle={(e) => this.legacyToggle("dueDateFrom", dueDateFromIsOpen)} trigger="legacy">
                                                        <Calendar
                                                            calendarType="US"
                                                            formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                            className="pop-calendar"
                                                            onChange={this.handleDueDateFromPickerChange}
                                                            value={(dueDateFromIsValid && dueDateFromValue) ? new Date(dueDateFromValue) : new Date()} />
                                                    </Popover>
                                                </Col>
                                                <Col>
                                                    <DateInput
                                                        type="text"
                                                        name="dueDateTo"
                                                        id="dueDateTo"
                                                        placeholder="To..."
                                                        onChange={onDateChange}
                                                        value={dueDateToValue}
                                                        className={"form-control " + (dueDateToIsValid ? undefined : 'is-invalid')} />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid date.
                                                    </div>
                                                </Col>
                                                <Col lg="1" className="dateIconCol">
                                                    <FontAwesomeIcon className="calendarIcon" id="dueDateTo" icon={faCalendarAlt} size="lg" onClick={(e) => toggleCalendar("dueDateTo")} />
                                                        <Popover placement="right" isOpen={dueDateToIsOpen} target="dueDateTo" toggle={(e) => this.legacyToggle("dueDateTo", dueDateToIsOpen)} trigger="legacy">
                                                            <Calendar
                                                                calendarType="US"
                                                                formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                                className="pop-calendar"
                                                                onChange={this.handleDueDateToPickerChange}
                                                                value={(dueDateToIsValid && dueDateToValue) ? new Date(dueDateToValue) : new Date()} />
                                                        </Popover>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="my-auto">
                                            <CheckboxLabelLeft id="isOverdue" label="Expired" onChange={onChange} checked={isOverdue} />
                                        </Col>
                                    </Row>
                                    <Row className="sectionRow">
                                        <Col lg="4" className="form-group">
                                                <Label className="fw-bold">ORDER DATE</Label>
                                                <Row>
                                                    <Col>
                                                        <DateInput
                                                            type="text"
                                                            name="orderEntryDateFrom"
                                                            id="orderEntryDateFrom"
                                                            placeholder="From..."
                                                            onChange={onDateChange}
                                                            value={orderEntryDateFromValue}
                                                            className={"form-control " + (orderEntryDateFromIsValid ? undefined : 'is-invalid')} />
                                                        <div className="invalid-feedback">
                                                            Please provide a valid date.
                                                        </div>
                                                    </Col>
                                                    <Col lg="1" className="dateIconCol">
                                                        <div>
                                                            <FontAwesomeIcon className="calendarIcon" id="orderEntryDateFrom" icon={faCalendarAlt} size="lg" onClick={(e) => toggleCalendar("orderEntryDateFrom")} />
                                                            <Popover placement="right" isOpen={orderEntryDateFromIsOpen} target="orderEntryDateFrom" toggle={(e) => this.legacyToggle("orderEntryDateFrom", orderEntryDateFromIsOpen)} trigger="legacy">
                                                                <Calendar
                                                                    calendarType="US"
                                                                    formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                                    className="pop-calendar"
                                                                    onChange={this.handleOrderEntryDateFromPickerChange}
                                                                    value={(orderEntryDateFromIsValid && orderEntryDateFromValue) ? new Date(orderEntryDateFromValue) : new Date()} />
                                                            </Popover>
                                                        </div>
                                                    </Col>
                                                <Col>
                                                    <DateInput
                                                        type="text"
                                                        name="orderEntryDateTo"
                                                        id="orderEntryDateTo"
                                                        placeholder="To..."
                                                        onChange={onDateChange}
                                                        value={orderEntryDateToValue}
                                                        className={"form-control " + (orderEntryDateToIsValid ? undefined : 'is-invalid')} />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid date.
                                                    </div>
                                                </Col>
                                                <Col sm="1" className="dateIconCol">
                                                    <div>
                                                        <FontAwesomeIcon className="calendarIcon" id="orderEntryDateTo" icon={faCalendarAlt} size="lg" onClick={(e) => toggleCalendar("orderEntryDateTo")} />
                                                        <Popover placement="right" isOpen={orderEntryDateToIsOpen} target="orderEntryDateTo" toggle={(e) => this.legacyToggle("orderEntryDateTo", orderEntryDateToIsOpen)} trigger="legacy">
                                                            <Calendar
                                                                calendarType="US"
                                                                formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                                className="pop-calendar"
                                                                onChange={this.handleOrderEntryDateToPickerChange}
                                                                value={(orderEntryDateToIsValid && orderEntryDateToValue) ? new Date(orderEntryDateToValue) : new Date()} />
                                                        </Popover>
                                                    </div>
                                                </Col>
                                            </Row>
                                            </Col>
                                            <Col lg="4">
                                                <Label className="fw-bold">LAST UPDATED DATE</Label>
                                                <Row>
                                                    <Col>
                                                        <DateInput
                                                            type="text"
                                                            name="lastModifiedDateFrom"
                                                            id="lastModifiedDateFrom"
                                                            placeholder="From..."
                                                            onChange={onDateChange}
                                                            value={lastModifiedDateFromValue}
                                                            className={"form-control " + (lastModifiedDateFromIsValid ? undefined : 'is-invalid')} />
                                                        <div className="invalid-feedback">
                                                            Please provide a valid date.
                                                        </div>
                                                    </Col>
                                                    <Col lg="1" className="dateIconCol">
                                                        <div>
                                                            <FontAwesomeIcon className="calendarIcon" id="lastModifiedDateFrom" icon={faCalendarAlt} size="lg" onClick={(e) => toggleCalendar("lastModifiedDateFrom")} />
                                                            <Popover placement="right" isOpen={lastModifiedDateFromIsOpen} target="lastModifiedDateFrom" toggle={(e) => this.legacyToggle("lastModifiedDateFrom", lastModifiedDateFromIsOpen)} trigger="legacy">
                                                                <Calendar
                                                                    calendarType="US"
                                                                    formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                                    className="pop-calendar"
                                                                    onChange={this.handleLastModifiedDateFromPickerChange}
                                                                    value={(lastModifiedDateFromIsValid && lastModifiedDateFromValue) ? new Date(lastModifiedDateFromValue) : new Date()} />
                                                            </Popover>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <DateInput
                                                            type="text"
                                                            name="lastModifiedDateTo"
                                                            id="lastModifiedDateTo"
                                                            placeholder="To..."
                                                            onChange={onDateChange}
                                                            value={lastModifiedDateToValue}
                                                            className={"form-control " + (lastModifiedDateToIsValid ? undefined : 'is-invalid')} />
                                                        <div className="invalid-feedback">
                                                            Please provide a valid date.
                                                        </div>
                                                    </Col>
                                                    <Col lg="1" className="dateIconCol">
                                                        <div>
                                                            <FontAwesomeIcon className="calendarIcon" id="lastModifiedDateTo" icon={faCalendarAlt} size="lg" onClick={(e) => toggleCalendar("lastModifiedDateTo")} />
                                                            <Popover placement="right" isOpen={lastModifiedDateToIsOpen} target="lastModifiedDateTo" toggle={(e) => this.legacyToggle("lastModifiedDateTo", lastModifiedDateToIsOpen)} trigger="legacy">
                                                                <Calendar
                                                                    calendarType="US"
                                                                    formatMonthYear={(locale, value) => { return moment(value).format('MMMM[\r\n]YYYY'); }}
                                                                    className="pop-calendar"
                                                                    onChange={this.handleLastModifiedDateToPickerChange}
                                                                    value={(lastModifiedDateToIsValid && lastModifiedDateToValue) ? new Date(lastModifiedDateToValue) : new Date() } />
                                                            </Popover>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    <Row>
                                        <Col lg="4">
                                            <SaveCancelActionBar saveButtonText="Search" cancelButtonText="Reset" onSave={onSearch} onCancel={onReset} />
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Card>
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}

OrderHistoryFilter.propTypes = {
    dueDateFromIsOpen: PropTypes.bool,
    dueDateFromValue: PropTypes.string,
    dueDateToIsOpen: PropTypes.bool,
    dueDateToValue: PropTypes.string,
    dueDateFromIsValid: PropTypes.bool,
    dueDateToIsValid: PropTypes.bool,
    handleDatePickerChange: PropTypes.func,
    isFilterOpen: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isOverdue: PropTypes.bool,
    lastModifiedDateFromValue: PropTypes.string,
    lastModifiedDateFromIsValid: PropTypes.bool,
    lastModifiedDateFromIsOpen: PropTypes.bool,
    lastModifiedDateToValue: PropTypes.string,
    lastModifiedDateToIsValid: PropTypes.bool,
    lastModifiedDateToIsOpen: PropTypes.bool,
    memberId: PropTypes.string,
    memberIdLabel: PropTypes.string,
    memberIdMaxLength: PropTypes.number,
    onChange: PropTypes.func,
    onDateChange: PropTypes.func,
    onReset: PropTypes.func,
    onSearch: PropTypes.func,
    orderEntryDateFromValue: PropTypes.string,
    orderEntryDateFromIsValid: PropTypes.bool,
    orderEntryDateFromIsOpen: PropTypes.bool,
    orderEntryDateToValue: PropTypes.string,
    orderEntryDateToIsValid: PropTypes.bool,
    orderEntryDateToIsOpen: PropTypes.bool,
    selectedSiteId: PropTypes.number,
    selectedStatusReasonId: PropTypes.number,
    siteList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    statusReasonList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    toggleCalendar: PropTypes.func,
}
