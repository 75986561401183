import React from 'react';

import FormElement from './guidedResponseFormElement';
import Message from './guidedResponseMessage';
import Question from './guidedResponseQuestion';
import { GuidedResponseNextButton } from './guidedResponseNextButton';
import { GuidedResponsePreviousButton } from './guidedResponsePreviousButton';
import { GuidedResponseFlowDebugPathButton } from './guidedResponseFlowDebugPathButton';

const GuidedResponseQuestionForm = ({ elementSet, questions, isLoading, isFlowAtStart, chainedFromAp,
	handlers: { onDropdownChange, onChange, inputBlur, inputFocus, next, previous, getFlowDebugPathFile }, debuggerEnabled }) => {

	return (
		<>
			<div id={elementSet?.elementSetRef} style={{position: "relative"}}>
				{
					elementSet &&
					elementSet.elements.map(element => {
						const questionState = questions.find(question => question.elementRef === element.elementRef);
						return (
							<FormElement key={element.elementRef}>
								<Message message={element.messageText || element.questionText} isRequired={element.isRequired} />
								{element.elementType === 'Question' &&
									<Question
										question={element}
										onDropdownChange={onDropdownChange}
										onChange={onChange}
										answer={questionState?.answer}
										altAnswer={questionState?.altAnswer}
										isFocusedLookup={questionState?.isFocused}
										error={questionState?.error}
										onBlur={inputBlur}
										onFocus={inputFocus}
									/>}
							</FormElement>
						);
					})
				}
				{debuggerEnabled && <GuidedResponseFlowDebugPathButton isLoading={isLoading} handler={getFlowDebugPathFile} />}
				{(!isFlowAtStart || chainedFromAp) && <GuidedResponsePreviousButton isLoading={isLoading} handler={previous} />}
				<GuidedResponseNextButton isLoading={isLoading} handler={next} />
			</div>
		</>
	);
}

export default GuidedResponseQuestionForm