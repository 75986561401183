import React from 'react';

export default function ContactUs() {
	return (
		<div>
			<p>
				If you have any questions, please contact Support at (629) 777-5552
			</p>
			<br />
			<p>
				<span style={{ fontWeight: 'bold' }}>To reset your password</span>, use the "Forgot your password?" link on the main login page.
			</p>
		</div>
	)
}
