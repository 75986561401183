import {
    Badge,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboardMedical } from "@fortawesome/pro-solid-svg-icons/faClipboardMedical";
import classnames from 'classnames';
import { PatientInfoTabIds } from "./patientInfoTabbedCollapsable";
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

library.add(faClipboardMedical);

const PatientInfoNav = (
    {
        activeTab,
        setActiveTab,
        patientConfig,
        careOrderFieldConfig,
        activePatientNotes,
        activeCareOrderNotes,
    }: {
        activeTab: any,
        setActiveTab: any,
        patientConfig: any,
        careOrderFieldConfig: any,
        activePatientNotes: string|undefined|null,
        activeCareOrderNotes: string|undefined|null
    }
) => {
    const careOrders = useSelector((state: any) => state.activePatient.careOrderHistory);
    const openCareOrders = careOrders?.filter((careOrder: any) =>
        (careOrder.statusCode !== 'C')
        && (!careOrder.expirationDate || dayjs(careOrder.expirationDate) >= dayjs())
    );

    const openCareOrdersCount = openCareOrders?.length;

    const appointmentHistoryRecords = useSelector((state: any) => state.activePatient.history);
    const recentlyBookedAppointments = appointmentHistoryRecords?.filter((appointment: any) =>
        dayjs(appointment.createDateTime) >= dayjs().subtract(7, 'day')
        && appointment.cancellationDateTime === null
    );

    const recentlyBookedAppointmentsCount = recentlyBookedAppointments?.length;

    const upcomingAppointments = appointmentHistoryRecords?.filter((appointment: any) =>
        dayjs(appointment.appointmentDate) >= dayjs()
        && appointment.cancellationDateTime === null
    );

    const upcomingAppointmentsCount = upcomingAppointments?.length;

    const openWorkflows = useSelector((state: any) => state.activePatient.openWorkflows);
    const unbookedWorkflows = openWorkflows?.filter((workflow: any) => !workflow.appointmentDate)

    const unbookedWorkflowsCount = unbookedWorkflows?.length;

    const hasCareOrder = useSelector((state: any) => !!state.careOrder.careOrderVisitIdentifier || !!state.careOrder.externalReferralOrderId);
    const enableOrderDetailsPanel = useSelector((state: any) => state.config.orderScheduling.enableOrderDetailsPanel);

    return (

        <Nav tabs>
            {hasCareOrder && enableOrderDetailsPanel && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.PatientCareOrderDetails })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.PatientCareOrderDetails) {
                                setActiveTab(PatientInfoTabIds.PatientCareOrderDetails);
                            }
                            else {
                                setActiveTab("");
                            }
                        }}
                    >
                        Order Details
                        {careOrderFieldConfig.notes.isVisible && activeCareOrderNotes &&
                            <span style={{ color: "#426da9", marginLeft: "5px" }}>
                                <FontAwesomeIcon icon='clipboard-medical' />
                            </span>
                        }
                    </NavLink>
                </NavItem>
            )}
            <NavItem>
                <NavLink
                    className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.PatientDetails })}
                    onClick={() => {
                        if (activeTab !== PatientInfoTabIds.PatientDetails) {
                            setActiveTab(PatientInfoTabIds.PatientDetails);
                        }
                        else {
                            setActiveTab("");
                        }
                    }}
                >
                    Patient Details
                    {patientConfig.notes.isVisible && activePatientNotes && 
                        <span style={{ color: "#426da9", marginLeft: "5px" }}>
                            <FontAwesomeIcon icon='clipboard-medical' />
                        </span>
                    }
                </NavLink>
            </NavItem>
            {patientConfig.isPatientHistoryEnabled && patientConfig.showPatientHistoryInPatientInfoPanel && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.UpcomingAppointments })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.UpcomingAppointments) {
                                setActiveTab(PatientInfoTabIds.UpcomingAppointments);
                            }
                            else {
                                setActiveTab("");
                            }
                        }}
                    >
                        Upcoming Appointments
                        {upcomingAppointmentsCount > 0 && (
                            <>
                                {' '}
                                <Badge
                                    id="upcomingAppointmentsBadge"
                                    pill
                                >
                                    {upcomingAppointmentsCount}
                                </Badge>
                            </>
                        )}
                    </NavLink>
                </NavItem>
            )}
            {patientConfig.isPatientHistoryEnabled && patientConfig.showPatientHistoryInPatientInfoPanel && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.AppointmentHistory })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.AppointmentHistory) {
                                setActiveTab(PatientInfoTabIds.AppointmentHistory);
                            }
                            else {
                                setActiveTab("");
                            }
                        }}
                    >
                        Appointment History
                        {recentlyBookedAppointmentsCount > 0 && (
                            <>
                                {' '}
                                <Badge
                                    id="recentlyBookedAppointmentsBadge"
                                    pill
                                >
                                    {`${recentlyBookedAppointmentsCount} New`}
                                </Badge>
                            </>
                        )}
                    </NavLink>
                </NavItem>
            )}
            {patientConfig.isPatientCareOrderHistoryEnabled && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.CareOrderHistory })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.CareOrderHistory) {
                                setActiveTab(PatientInfoTabIds.CareOrderHistory);
                            }
                            else {
                                setActiveTab("");
                            }
                        }}
                    >
                        Open Care Orders
                        {openCareOrdersCount > 0 && (
                            <>
                                {' '}
                                <Badge
                                    id="openCareOrdersBadge"
                                    pill
                                >
                                    {openCareOrdersCount}
                                </Badge>
                            </>
                        )}
                    </NavLink>
                </NavItem>
            )}
            {patientConfig.isDecisionSupportWorkflowHistoryEnabled && (
                <NavItem>
                    <NavLink
                        className={classnames('patientInfoNavLink', { active: activeTab === PatientInfoTabIds.DecisionSupportWorkflowHistory })}
                        onClick={() => {
                            if (activeTab !== PatientInfoTabIds.DecisionSupportWorkflowHistory) {
                                setActiveTab(PatientInfoTabIds.DecisionSupportWorkflowHistory);
                            }
                            else {
                                setActiveTab("");
                            }
                        }}
                    >
                        Open Workflows
                        {unbookedWorkflowsCount > 0 && (
                            <>
                                {' '}
                                <Badge
                                    id="unbookedWorkflowsBadge"
                                    pill
                                >
                                    {unbookedWorkflowsCount}
                                </Badge>
                            </>
                        )}
                    </NavLink>
                </NavItem>
            )}
        </Nav>
    )
};

export default PatientInfoNav;