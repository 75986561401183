import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit';

export default function buildFontAwesomeLibrary() {
	library.add(
		faBars,
		faEdit,
		faEye,
		faEyeSlash,
		faSpinner,
		faEnvelope,
		faKey,
		faPhone,
		faUserEdit,
		faChevronRight,
		faChevronDown,
		faEllipsisH,
		faInfoCircle
	);
}