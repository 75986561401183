import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let reactPlugin = new ReactPlugin();
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
	/**
	 * Initialize the Application Insights class
	 * @param {string} instrumentationKey - Application Insights Instrumentation Key
	 * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
	 * @return {void}
	 */
	const initialize = (instrumentationKey, browserHistory, auth, session) => {
		if (!browserHistory) {
			throw new Error('Could not initialize Telemetry Service.');
		}
		if (!instrumentationKey) {
			throw new Error('Instrumentation key not provided.');
		}

		appInsights = new ApplicationInsights({
			config: {
				autoTrackPageVisitTime: true,
				instrumentationKey: instrumentationKey,
				disableFetchTracking: false,
				enableAutoRouteTracking: true,
				enableCorsCorrelation: true,
				enableRequestHeaderTracking: true,
				extensions: [reactPlugin],
				extensionConfig: {
					[reactPlugin.identifier]: {
						history: browserHistory,
					},
				},
				loggingLevelTelemetry: 2,
				maxBatchInterval: 0,
			},
		});

		appInsights.loadAppInsights();
		updateData(auth, session);
	};

	const updateData = (auth, session) => {
		if (auth && auth.isAuthenticated && appInsights) {
			const telemetryInitializer = (envelope) => {
				envelope.data.username = auth.email;
				envelope.data.correlationKey = session.correlationKey;
			};
			appInsights.addTelemetryInitializer(telemetryInitializer);
		}
	};

	return { reactPlugin, appInsights, initialize, updateData };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
