export const PATIENT_FIELD_GROUP = 'RD2.PatientFieldConfiguration';
export const PATIENT_FIELD_GENDER = `${PATIENT_FIELD_GROUP}.Gender`;

export const PATIENT_SEARCH_FIELD_GROUP = 'RD2.PatientSearchFieldConfig';
export const PATIENT_SEARCH_FIELD_FIRSTNAME = `${PATIENT_SEARCH_FIELD_GROUP}.FirstName`;
export const PATIENT_SEARCH_FIELD_LASTNAME = `${PATIENT_SEARCH_FIELD_GROUP}.LastName`;
export const PATIENT_SEARCH_FIELD_DATEOFBIRTH = `${PATIENT_SEARCH_FIELD_GROUP}.DateOfBirth`;

export const PATIENT_FIELD_MEMBERID = 'RD2.PatientFieldConfiguration.MemberId';
export const PATIENT_FIELD_GROUPNUMBER = 'RD2.PatientFieldConfiguration.GroupNumber';
export const PATIENT_FIELD_FIRSTNAME = 'RD2.PatientFieldConfiguration.FirstName';
export const PATIENT_FIELD_MIDDLENAME = 'RD2.PatientFieldConfiguration.MiddleName';
export const PATIENT_FIELD_LASTNAME = 'RD2.PatientFieldConfiguration.LastName';
export const PATIENT_FIELD_ADDRESSLINE1 = 'RD2.PatientFieldConfiguration.AddressLine1';
export const PATIENT_FIELD_ADDRESSLINE2 = 'RD2.PatientFieldConfiguration.AddressLine2';
export const PATIENT_FIELD_CITY = 'RD2.PatientFieldConfiguration.City';
export const PATIENT_FIELD_STATE = 'RD2.PatientFieldConfiguration.State';
export const PATIENT_FIELD_ZIPCODE = 'RD2.PatientFieldConfiguration.ZipCode';
export const PATIENT_FIELD_DATEOFBIRTH = 'RD2.PatientFieldConfiguration.DateOfBirth';
export const PATIENT_FIELD_EMAIL = 'RD2.PatientFieldConfiguration.Email';
export const PATIENT_FIELD_ALTERNATEPHONE = 'RD2.PatientFieldConfiguration.AlternatePhone';
export const PATIENT_FIELD_HOMEPHONE = 'RD2.PatientFieldConfiguration.HomePhone';
export const PATIENT_FIELD_MOBILEPHONE = 'RD2.PatientFieldConfiguration.MobilePhone';
export const PATIENT_FIELD_PARENT_OR_GUARDIAN_NAME = 'RD2.PatientFieldConfiguration.ParentOrGuardianName';
export const PATIENT_FIELD_NOTES = 'RD2.PatientFieldConfiguration.Notes';
