import PrivacyPolicyAndTermsOfUseLinks from "components/legal/privacyPolicyAndTermsOfUseLinks"

const LoginFooter = () => {
    return (
        <div className="loginFooter">
            <PrivacyPolicyAndTermsOfUseLinks />
            <div className="text-center loginFooterSize">
                @ {currentYear()} MyHealthDirect, Inc. All Rights Reserved.
            </div>
        </div>
    )
}

const currentYear = () => new Date().getFullYear();

export default LoginFooter;