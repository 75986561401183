import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PatientCareOrderHistoryGrid from './patientCareOrderHistoryGrid';
import PatientCareOrderHistoryFilter from './patientCareOrderHistoryFilter';

import '../css/patientCareOrderHistory.css';
import { SEARCH_HYBRID } from 'constants/careOrderSearchTypes';

export interface CareOrderFilters { includeClosed: boolean, includeExpired: boolean, hideMHD: boolean, hideEMR: boolean }

export default function PatientCareOrderHistory(
    {
        showBookButton,
        showCloseButton,
        showFilters,
        setIsPatientCareOrderHistoryLoading,
        activeTab
    }: {
        showBookButton: boolean,
        showCloseButton: boolean,
        showFilters: boolean,
        setIsPatientCareOrderHistoryLoading: Function,
        activeTab?: any
    }
): JSX.Element {

    const careOrders = useSelector((state: any) => state.activePatient.careOrderHistory);
    const includeInactiveCareOrders = useSelector((state: any) => state.config.patient.includeInactiveCareOrders);
    const searchType = useSelector((state: any) => state.config.orderScheduling.searchType);

    const hasInactiveCareOrders = careOrders?.some((careOrder: any) => careOrder.statusCode === 'C') || careOrders?.some((careOrder: any) => dayjs(careOrder.dueDate) < dayjs());
    const showInactiveCareOrderFilters = showFilters && includeInactiveCareOrders && hasInactiveCareOrders;
    const showHybridCareOrderFilters = showFilters && searchType === SEARCH_HYBRID;
    const [filters, setFilters] = useState<CareOrderFilters>({ includeClosed: false, includeExpired: false, hideMHD: false, hideEMR: false });

    useEffect(() => {
        if (activeTab?.reloadTabContent) {
            setFilters({ includeClosed: false, includeExpired: false, hideMHD: false, hideEMR: false });
        }
    }, [activeTab]);

    return (
        <div>
            {careOrders?.length > 0 &&
                <div className="col" style={{ padding: '0px' }}>
                    <PatientCareOrderHistoryFilter
                        filters={filters}
                        setFilters={setFilters}
                        showHybridCareOrderFilters={showHybridCareOrderFilters}
                        showInactiveCareOrderFilters={showInactiveCareOrderFilters}
                    />
                    <PatientCareOrderHistoryGrid
                        filters={filters}
                        careOrders={careOrders}
                        showBookButton={showBookButton}
                        showCloseButton={showCloseButton}
                        setIsPatientCareOrderHistoryLoading={setIsPatientCareOrderHistoryLoading}
                        activeTab={activeTab}
                    />
                </div>
            }
            {careOrders && (careOrders.length === 0) && <PatientCareOrderHistoryEmptyList />}
        </div>
    )
}

const PatientCareOrderHistoryEmptyList = () => {
    return (
        <div>
            <h5 className="history-result-header">No care orders found.</h5>
        </div>
    );
}
