import React from 'react';

const PermissionWarning = (props) => {
	return (
		<div>
			<h1>No Access</h1>
			<p>Current user is missing required permissions to view this content.</p>
		</div>
		);
};

export default PermissionWarning;