export const mapFieldConfigInfoToSimpleFieldConfig = (fieldConfigInfo) => {
	if (!fieldConfigInfo) {
		return null;
	}

	var simpleFieldConfig = {
		fieldLabel: fieldConfigInfo.fieldLabel,
		isEnabled: (fieldConfigInfo.fieldBehaviorFlags & 1) ? true : false,
		isVisible: (fieldConfigInfo.fieldBehaviorFlags & 2) ? true : false,
		isEditable: (fieldConfigInfo.fieldBehaviorFlags & 4) ? true : false,
		isRequired: (fieldConfigInfo.fieldBehaviorFlags & 8) ? true : false,
		maxLength: fieldConfigInfo.maxLength,	
	};
	return simpleFieldConfig;
}