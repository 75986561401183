import * as types from '../actions/actionTypes';

const defaultOption = { id: 0, name: "Select an option" };
const defaultRowOptions = {
	providers: [],
	appointmentTypes: [],
	referringProviders: [],
};

const initialState = {
	dateRangeList: [],
	bookableSites: [],
	rowOptions: [defaultRowOptions],
	careOrders: [],
	careOrderDetailsPageData: {},
	careOrderHistory: [],
	subgroups: [],
	closeCareOrderEnabled: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.RETRIEVE_DATE_RANGES_SUCCESS:
			return {
				...state,
				dateRangeList: [{...defaultOption}].concat(action.payload.data.map(dateRange => {
					return {
						...dateRange,
						name: dateRange.displayName,
					};
				})),
			};

		case types.RETRIEVE_BOOKABLE_SITES_SUCCESS:
			return {
				...state,
				bookableSites: [defaultOption,...action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1)],
			};

		case types.RETRIEVE_PROVIDERS_FOR_SITE_SUCCESS:
			const provOptions = [...state.rowOptions];
			const newProviders = action.payload.data.length ? [{ ...defaultOption }].concat(action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1)) : action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1);
			provOptions[action.meta.previousAction.appointmentIndex] = {
				...provOptions[action.meta.previousAction.appointmentIndex],
				providers: newProviders,
				appointmentTypes: [],
			};
			return {
				...state,
				rowOptions: provOptions,
			};

		case types.RETRIEVE_REFERRING_PROVIDERS_FOR_SITE_SUCCESS:
			const rProvOptions = [...state.rowOptions];
			const newReferrers = action.payload.data.length ? [{ ...defaultOption }].concat(action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1)) : action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1);
			rProvOptions[action.meta.previousAction.appointmentIndex] = {
				...rProvOptions[action.meta.previousAction.appointmentIndex],
				referringProviders: newReferrers.filter(prov => ("canMakeReferrals" in prov ? prov.canMakeReferrals === true : true)),
			};
			return {
				...state,
				rowOptions: rProvOptions,
			};

		case types.RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER_SUCCESS:
			const typeOptions = [...state.rowOptions];
			const newAppointmentTypes = action.payload.data.length ? [{ ...defaultOption }].concat(action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1)) : action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1);
			typeOptions[action.meta.previousAction.appointmentIndex] = {
				...typeOptions[action.meta.previousAction.appointmentIndex],
				appointmentTypes: newAppointmentTypes,
			};
			return {
				...state,
				rowOptions: typeOptions,
			};

		case types.RETRIEVE_SUBGROUPS_FOR_SYSTEM_SUCCESS:
			const subgroups = action.payload.data.length ? [{ ...defaultOption }].concat(action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1)) : action.payload.data.sort((a, b) => (a.name > b.name) ? 1 : -1);			
			return {
				...state,
				subgroups: subgroups,
			};

		case types.REMOVE_APPOINTMENT_OPTION_ROW:
			return {
				...state,
				rowOptions: state.rowOptions.filter((x, i) => { return i !== action.index; }),
			};

		case types.CREATE_CARE_ORDER_SUCCESS:
			return {
				...state,
				rowOptions: [{ ...defaultRowOptions }],
			};

		case types.GET_CARE_ORDERS_BY_SYSTEM_SUCCESS:
			return {
				...state,
				careOrders: action.payload.data,
			};

		case types.CARE_ORDER_DETAILS_PAGE_DATA_SUCCESS:
			return {
				...state,
				careOrderDetailsPageData: action.payload.data,
			};

		case types.GET_CARE_ORDER_HISTORY_SUCCESS:
			return {
				...state,
				careOrderHistory: action.payload.data,
			}
		case types.SEARCH_CARE_ORDERS_PAGE_DATA_SUCCESS:
			const pageData = {
				careOrderStatuses: action.payload.data && action.payload.data.careOrderStatuses.length ? action.payload.data.careOrderStatuses.sort((a, b) => (a.name > b.name) ? 1 : -1) : null,
				careOrderStatusReasons: action.payload.data && action.payload.data.careOrderStatusReasons.length ? action.payload.data.careOrderStatusReasons.sort((a, b) => (a.name > b.name) ? 1 : -1) : null,
				sites: action.payload.data && action.payload.data.sites.length ? action.payload.data.sites.sort((a, b) => (a.name > b.name) ? 1 : -1) : null,
			}
			return {
				...state,
				searchCareOrdersPageData: pageData,
			};

		case types.UPDATE_CARE_ORDER_SUCCESS:
			return {
				...state,
			}
		default:
			return state;
	}
};

export default reducer;