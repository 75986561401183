import { useLocation } from 'react-router-dom';
import qs from 'qs';

const SsoAccessDenied = () => {
    const location = useLocation();
    let queryString = location.search;
    const params = qs.parse(queryString, { ignoreQueryPrefix: true });
    const username = params?.username;

    return (
        <div>
            <h1>Access Denied</h1>
            <p>We're sorry, but {username} is not configured to use this application.</p>
        </div>
    );
};

export default SsoAccessDenied;
