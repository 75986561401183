import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button } from 'reactstrap';
import { convertStatusCode } from 'lib/careOrderUtils';
import * as routes from '../../../routes';
import { defaultBookingDisabledMessage, scrollToPageTop } from 'lib/misc';
import { closeCareOrder, setActiveCareOrderDetails } from 'actions/careOrderActions';
import { fetchPatientCareOrderHistory, generateCorrelationKey, startAvailabilitySearch, startBookingProcess } from 'actions/rd2RefactorActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmOrCancelModal } from 'components/common/standardComponents';
import { DataGrid } from 'components/dataGrid/dataGrid';
import { CareOrderFilters } from './patientCareOrderHistory';
import { fetchDecisionSupportSessionOutput } from 'actions/sessionActions';
import { processDecisionSupportSubpoints } from 'actions/decisionSupportActions';

export default function PatientCareOrderHistoryGrid(
    {
        careOrders,
        filters,
        showBookButton,
        showCloseButton,
        setIsPatientCareOrderHistoryLoading,
        activeTab
    }: {
        careOrders: any,
        filters: CareOrderFilters,
        showBookButton: boolean,
        showCloseButton: boolean,
        setIsPatientCareOrderHistoryLoading: Function,
        activeTab: any
    }
) {

    const dispatch = useDispatch();

    const productInstanceId = useSelector((state: any) => state.auth.productInstanceId)
    const careOrderConfig = useSelector((state: any) => state.config.careOrderPanel);
    const patient = useSelector((state: any) => state.activePatient);

    const [currentlyBookingCareOrderId, setCurrentlyBookingCareOrderId] = useState(null);
    const [currentlyCancellingCareOrderId, setCurrentlyCancellingCareOrderId] = useState(null);

    const [showCancelCareOrderConfirmModal, setShowCancelCareOrderConfirmModal] = useState(false);

    const handleGoToAppointmentDetails = (referenceId: any) => dispatch(routes.appointmentDetails(referenceId));

    const handleConfirmCloseCareOrder = () => {
        const careOrder = careOrders.find((careOrder: any) => careOrder.referenceId === currentlyCancellingCareOrderId);
        if (!careOrder)
            throw new Error('Cannot Find Care Order');

        setShowCancelCareOrderConfirmModal(false);
        dispatch(
            async (dispatch: any, getState: any) => {

                try {
                    await dispatch(closeCareOrder({
                        productInstanceId,
                        careOrderVisitIdentifier: careOrder.referenceId,
                        externalReferralOrderId: careOrder.externalReferralOrderId
                    }));
                } catch (e) {
                    scrollToPageTop();
                } finally {
                    setCurrentlyCancellingCareOrderId(null);
                }

                try {
                    setIsPatientCareOrderHistoryLoading(true);
                    await dispatch(fetchPatientCareOrderHistory(patient.id));
                } catch (e) {
                    scrollToPageTop();
                } finally {
                    setIsPatientCareOrderHistoryLoading(false);
                }

            }
        )
    };

    const handleCancelCloseCareOrder = () => {
        setShowCancelCareOrderConfirmModal(false);
        setCurrentlyCancellingCareOrderId(null);
    }

    const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);
    useEffect(() => {
        if (activeTab?.reloadTabContent) {
            setGridKey(`grid-key-${Math.random()}`);
        }
    }, [activeTab])

    const orderedCareOrderFields = [
        {
            config: careOrderConfig?.status, value: "statusCode", type: null,
            conversionFunc: (code: any) => convertStatusCode(code)
        },
        { config: careOrderConfig?.statusReason, value: "statusDisplayName", type: null },
        { config: careOrderConfig?.careOrderType, value: "careOrderType", type: null },
        //{ config: careOrderConfig?.createdDate, value: "createdAt", type: "date" },
        { config: careOrderConfig?.orderDate, value: "orderDate", type: "date" },
        { config: careOrderConfig?.expirationDate, value: "expirationDate", type: "date" },
        { config: careOrderConfig?.minDate, value: "minVisitDate", type: "date" },
        { config: careOrderConfig?.maxDate, value: "maxVisitDate", type: "date" },
        { config: careOrderConfig?.site, value: "siteName", type: null },
        { config: careOrderConfig?.service, value: "serviceDisplayName", type: null },
        { config: careOrderConfig?.referringSite, value: "referringSiteName", type: null },
        { config: careOrderConfig?.referringService, value: "referringServiceDisplayName", type: null },
        { config: careOrderConfig?.specialty, value: "specialtyName", type: null },
        { config: careOrderConfig?.subgroup, value: "subgroupName", type: null },
        { config: careOrderConfig?.appointmentType, value: "appointmentTypeName", type: null },
        { config: careOrderConfig?.reasonForVisit, value: "reasonForVisit", type: null },
        {
            config: careOrderConfig?.bookedAppointment, value: "appointmentReferenceId", type: null,
            conversionFunc: (refId: any, appointmentDateTime: any) =>
                FormatBookedAppointmentLink(refId, appointmentDateTime, handleGoToAppointmentDetails),
            value2: "providerAppointmentDateTime"
        },
        { config: careOrderConfig?.externalOrderNumber, value: "externalReferralOrderId", type: null },
        //{ config: careOrderConfig?.lastModifiedAt, value: "updatedAt", type: "date" },
        //{ config: careOrderConfig?.lastEmailDate, value: "lastNotificationSentAt", type: "date" },
        { config: careOrderConfig?.productInstanceConsumer, value: "productInstanceConsumerName", type: null },
        //{ config: careOrderConfig?.lockoutEndAt, value: "lockoutEndAt", type: "date" },
        { config: careOrderConfig?.customFields, value: "customFields", type: "customFields" },
        { config: careOrderConfig?.notes, value: "notes", type: "notes" },
    ];

    const columnDefs = [
        {
            headerName: "",
            hide: !showBookButton,
            cellRenderer: (r: any) => {
                if (!r.data.isInactive) {
                    return <div className='button-center'>
                        <Button
                            className="bookButton"
                            data-toggle={!patient.details.isBookingDisabled ? null : 'tooltip'}
                            data-placement="top"
                            title={patient.details.isBookingDisabled
                                ? patient?.details?.bookingDisabledMessage ?? defaultBookingDisabledMessage
                                : ''}
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setCurrentlyBookingCareOrderId(r.data.fromEMR ? r.data.externalReferralOrderId : r.data.referenceId);
                                dispatch(
                                    async (dispatch: any, getState: any) => {
                                        await dispatch(setActiveCareOrderDetails(r.data));

                                        if (r.data.decisionSupportSessionId) {
                                            await dispatch(generateCorrelationKey());
                                            await dispatch(fetchDecisionSupportSessionOutput(r.data.decisionSupportSessionId));
                                            let state = getState()
                                            await dispatch(processDecisionSupportSubpoints(state.session.decisionSupportSubpoints, true));
                                            state = getState();
                                            dispatch(startAvailabilitySearch(
                                                state.careOrder,
                                                state.decisionSupport,
                                                state.config.availabilitySearch,
                                                state.activePatient.details,
                                            ))
                                        } else {
                                            let state = getState();
                                            dispatch(startBookingProcess(
                                                state.careOrder,
                                                state.decisionSupport,
                                                state.config.availabilitySearch,
                                                state.activePatient.details,
                                                (
                                                    state.config.decisionSupport.useDecisionSupport
                                                    && state.config.scheduling.useDecisionSupportWithCareOrders
                                                )
                                            ))
                                        }
                                    }
                                )
                            }}
                            disabled={
                                isCareOrderSelected(r.data, currentlyBookingCareOrderId, currentlyCancellingCareOrderId)
                                || patient.details.isBookingDisabled
                            }
                        >
                            {isCareOrderSelected(r.data, currentlyBookingCareOrderId, currentlyCancellingCareOrderId) ? (
                                <span>
                                    <FontAwesomeIcon icon="spinner" spin /> Booking...
                                </span>
                            ) : (
                                <span>Book</span>
                            )}
                        </Button>
                    </div>
                }
                else {
                    return <span></span>
                }
            }
        },
        {
            headerName: "",
            hide: !showCloseButton,
            cellRenderer: (r: any) => {
                if (!r.data.isInactive) {
                    return <div className='button-center'>
                        <Button
                            className="cancelButton"
                            color="secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setCurrentlyCancellingCareOrderId(r.data.fromEMR ? r.data.externalReferralOrderId : r.data.referenceId);
                                setShowCancelCareOrderConfirmModal(true);
                            }
                            }
                            disabled={isCareOrderSelected(r.data, currentlyBookingCareOrderId, currentlyCancellingCareOrderId)}
                        >
                            {isCareOrderSelected(r.data, currentlyBookingCareOrderId, currentlyCancellingCareOrderId) ? (
                                <span>
                                    <FontAwesomeIcon icon="spinner" spin /> Closing...
                                </span>
                            ) : (
                                <span>Close</span>
                            )}
                        </Button>
                    </div>
                }
                else {
                    return <span></span>
                }
            }
        },
        ...orderedCareOrderFields.map((field: any) => {
            return {
                field: field.value,
                headerName: field.config?.fieldLabel,
                hide: !field.config?.isVisible,
                cellRenderer: (r: any) => <span>{ValueOrDefault(r.data[field.value], field.type, field.conversionFunc, r.data[field.value2])}</span>
            }
        })
    ]

    const filteredMappedCareOrders = careOrders
        .filter((careOrder: any) => {
            if (dayjs(careOrder.expirationDate) < dayjs() && careOrder.statusCode === 'E') {
                return filters.includeExpired;
            }

            if (careOrder.statusCode === 'C') {
                return filters.includeClosed;
            }

            if (careOrder.fromEMR) {
                return !filters.hideEMR;
            }

            if (!careOrder.fromEMR) {
                return !filters.hideMHD;
            }

            return true;
        })
        .map((careOrder: any) => {
            return {
                ...careOrder,
                isInactive: Boolean(dayjs(careOrder.expirationDate) < dayjs()) || careOrder.statusCode === 'C',
            }
        })

    return (
        <>
            <DataGrid
                autoHeight={true}
                columnDefs={columnDefs}
                data={filteredMappedCareOrders}
                getRowClass={(r: any) => r.data.isInactive ? 'inactive-row' : null}
                noRowsMessage="No care orders found."
                customGridSearch={undefined}
                gridHeading={undefined}
                gridKey={gridKey}
                getRowHeight={undefined}
                defaultPageSize={undefined}
                editable={undefined}
                enableColumnFilters={undefined}
                onRowClick={undefined}
                headerHeight={undefined}
                setSelectedRows={undefined}
                serverSideDatasource={undefined}
                getRowStyle={undefined}
            />
            {showCancelCareOrderConfirmModal && <CareOrderCancelConfirmModal onConfirm={handleConfirmCloseCareOrder} onCancel={handleCancelCloseCareOrder} />}
        </>
    )
}

const CareOrderCancelConfirmModal = ({ onConfirm, onCancel }: { onConfirm: any, onCancel: any }) => {
    return <ConfirmOrCancelModal
        className={"confirmCancelCareOrderModal"}
        title={"Confirm Cancel Care Order"}
        body={"Are you sure you want to cancel the care order?"}
        isOpen={true}
        confirmLabel={"Confirm"}
        cancelLabel={"Go Back"}
        onConfirm={onConfirm}
        onCancel={onCancel}
    />
}

function FormatBookedAppointmentLink(refId: any, appointmentDateTime: any, handler: any) {
    return <span><a href='#AppointmentDetails' onClick={(e) => { e.preventDefault(); handler(refId) }}>{appointmentDateTime ? dayjs(appointmentDateTime).format('MM/DD/YY hh:mm A') : "Booked Appointment"}</a></span>
}

function ValueOrDefault(value: any, format: any, conversionFunc: any, value2: any) {
    if (!value) {
        return "-"
    }

    if (conversionFunc) {
        return conversionFunc(value, value2);
    }

    switch (format) {
        case "date":
            return dayjs(value).format('MM/DD/YY');
        case "customFields":
            return <ul>{value.map((x: any, index: any) => <li key={index}>{`${x.fieldName}: ${x.fieldValue}`}</li>)}</ul>;
        default:
            if (typeof value === "string") {
                return value.trim();
            }
            else {
                return value;
            }
    }

}

function isCareOrderSelected(careOrder: any, currentlyBookingCareOrderId: any, currentlyCancellingCareOrderId: any) {
    let isCareOrderSelected = false;

    if (careOrder.fromEMR) {
        const careOrderMatchesSelected = (currentlyBookingCareOrderId === careOrder.externalReferralOrderId)
            || (currentlyCancellingCareOrderId === careOrder.externalReferralOrderId);

        if (careOrderMatchesSelected) {
            isCareOrderSelected = true;
        }
    } else {
        const careOrderMatchesSelected = (currentlyBookingCareOrderId === careOrder.referenceId)
            || (currentlyCancellingCareOrderId === careOrder.referenceId);

        if (careOrderMatchesSelected) {
            isCareOrderSelected = true;
        }
    }

    return isCareOrderSelected;
}