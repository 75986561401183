import authCookie from 'lib/authCookie';
import axios, { AxiosInstance, ResponseType } from 'axios';
import { logoutUser } from 'actions/authActions';
import { store } from 'store';
import { ApiState, VoidApiState } from 'types';
import { getErrorMessage } from 'utilities';

const requestQueue = new Map();

export const baseApi = (): AxiosInstance => {
	const authState = authCookie.get();
	const instance = axios.create({
		baseURL: 'api',
		headers: authState.token ? { Authorization: `Bearer ${authState.token}` } : {},
	});

	instance.interceptors.request.use(
		(config) => {
			const key = `${config.method}:${config.url}`;
			const existingRequest = requestQueue.get(key);

			if (existingRequest) {
				existingRequest.abort();
			}

			const source = new AbortController();
			config.signal = source.signal;
			requestQueue.set(key, source);

			return config;
		},
		(error) => Promise.reject(error),
	);

	instance.interceptors.response.use(
		(response) => {
			const key = `${response.config.method}:${response.config.url}`;
			requestQueue.delete(key);
			return response;
		},
		(error) => {
			const key = `${error.config.method}:${error.config.url}`;
			requestQueue.delete(key);

			if (error.code !== 'ERR_CANCELED') {
				if (error.response && error.response.status === 401) {
					authCookie.clear();
					store.dispatch(logoutUser());
				}

				if (typeof error.response === 'undefined') {
					// do something
				}
			}

			return Promise.reject(error);
		},
	);

	return instance;
};

export const cancelAllRequests = () => {
	requestQueue.forEach((source) => {
		source.abort();
	});
	requestQueue.clear();
};

export enum ApiMethod {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	DELETE = 'delete',
	PATCH = 'patch',
}

export const createApiEndpoint = <InputData>(route: string, method: ApiMethod, responseType: ResponseType = 'json') => {
	const api = baseApi();

	const callApi = async <T>(setState: (result: ApiState<T>) => void, inputData?: InputData): Promise<boolean> => {
		let result: ApiState<T> = {
			loading: true,
			data: null,
			error: null,
		};
		setState(result);

		try {
			const { data, headers } = await api.request<T>({
				method,
				url: route,
				data: inputData,
				responseType: responseType,
			});
			result = {
				loading: false,
				data: data,
				error: null,
				contentType: headers['content-type'],
				contentDisposition: headers['content-disposition'],
			};
			setState(result);
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			result = { loading: false, data: null, error: errorMessage };
			setState(result);
		}
		return result.error === null;
	};

	return callApi;
};

export const createVoidApiEndpoint = <InputData>(
	route: string,
	method: ApiMethod,
	responseType: ResponseType = 'json',
) => {
	const api = baseApi();

	const callApi = async <T>(setState: (result: VoidApiState) => void, inputData?: InputData): Promise<boolean> => {
		let result: VoidApiState = {
			processing: true,
			completed: false,
			error: null,
		};
		setState(result);

		try {
			await api.request<T>({
				method,
				url: route,
				data: inputData,
				responseType,
			});
			result = { processing: false, completed: true, error: null };
			setState(result);
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			result = { processing: false, completed: false, error: errorMessage };
			setState(result);
		}
		return result.error === null;
	};

	return callApi;
};

export default baseApi;
