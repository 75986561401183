import config from '../../../config';

const ContactUs = () => {
    return (
        <div className="text-center loginContactUs">
            Need Help? Please contact us at (629) 777-5552 |{' '}
            {config.version}
        </div>
    )
}

export default ContactUs;