import * as React from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertStatusCode } from '../../../lib/careOrderUtils';
import { LabeledField } from '../../../components/common/standardComponents';
import '../css/patientCareOrderDetails.css';

const PatientCareOrderDetails = () => {

	const details = useSelector((state: any) => state.careOrder.activeCareOrderDetails);
	const fieldConfig = useSelector((state: any) => state.config.careOrderPanel);

	let panelDetails: {[Key: string]: any} = {
		statusCode: details.statusCode,
		statusDisplayName: details.statusDisplayName,
		careOrderType: details.careOrderType,
		orderDate: details.orderDate ? moment(details.orderDate).format('LL') : null,
		expirationDate: details.expirationDate ? moment(details.expirationDate).format('LL') : null,
		minVisitDate: details.minVisitDate ? moment(details.minVisitDate).format('LL') : null,
		maxVisitDate: details.maxVisitDate ? moment(details.maxVisitDate).format('LL') : null,
		siteName: details.appointments[0].siteName,
		serviceDisplayName: details.appointments[0].serviceDisplayName,
		referringSiteName: details.appointments[0].referringSiteName,
		referringServiceName: details.appointments[0].referringServiceName,
		specialtyName: details.appointments[0].specialtyName,
		subgroupName: details.subgroupName,
		appointmentTypeName: details.appointments[0].appointmentTypeName,
		externalOrderNumber: details.appointments[0].externalReferralOrderId,
		reasonForVisit: details.appointments[0].reasonForVisit,
		productInstanceConsumerName: details.productInstanceConsumerName,
		notes: details.notes,
	};

	const orderedFields = [
		{ config: fieldConfig.status, value: 'statusCode', type: null, conversionFunc: (code:string) => convertStatusCode(code) },
		{ config: fieldConfig.statusReason, value: 'statusDisplayName', type: null },
		{ config: fieldConfig.careOrderType, value: 'careOrderType', type: null },
		{ config: fieldConfig.externalOrderNumber, value: 'externalOrderNumber', type: null },
		{ config: fieldConfig.orderDate, value: 'orderDate', type: null },
		{ config: fieldConfig.expirationDate, value: 'expirationDate', type: null },
		{ config: fieldConfig.minDate, value: 'minVisitDate', type: null },
		{ config: fieldConfig.maxDate, value: 'maxVisitDate', type: null },
		{ config: fieldConfig.site, value: 'siteName', type: null },
		{ config: fieldConfig.service, value: 'serviceDisplayName', type: null },
		{ config: fieldConfig.referringSite, value: 'referringSiteName', type: null },
		{ config: fieldConfig.referringService, value: 'referringServiceDisplayName', type: null },
		{ config: fieldConfig.specialty, value: 'specialtyName', type: null },
		{ config: fieldConfig.subgroup, value: 'subgroupName', type: null },
		{ config: fieldConfig.appointmentType, value: 'appointmentTypeName', type: null },
		{ config: fieldConfig.reasonForVisit, value: 'reasonForVisit', type: null },
		{ config: fieldConfig.productInstanceConsumer, value: 'productInstanceConsumerName', type: null },
	];

	const visibleFields = orderedFields.filter((x) => x.config.isVisible);

	return (
		<>
			<Row style={{marginTop: '10px'}}>
				{visibleFields.map((field, index) => {
					return (
						<Col key={index} lg="auto">
							<LabeledField label={field.config.fieldLabel} value={panelDetails[field.value]} />
						</Col>
					)
				})}
			</Row>
			{fieldConfig.notes.isVisible && details?.notes && (
				<Row>
					<Col className="patientCareOrderDetailsNotes">
						<div>
							<p className="fw-bold form-label">{(fieldConfig.notes.fieldLabel ?? 'Patient Notes').toUpperCase()}
								{details?.notes &&
									<span style={{ color: "#426da9", marginLeft: "5px" }}>
										<FontAwesomeIcon icon='clipboard-medical' />
									</span>
								}
							</p>
							<p>{details?.notes}</p>
						</div>
					</Col>
				</Row>
			)}
		</>
	)
};

export default PatientCareOrderDetails;