//Third Party Dependencies
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Alert } from 'reactstrap';

//MHD General Purpose libraries
import config from '../../config';
import { validateDate } from '../../lib/validation';
import ActiveErrors from '../../components/common/activeErrors';
import { SectionHeading } from '../../components/common/standardComponents';

//Specific Business and UI components
import * as orderManagementActions from '../../actions/orderManagementActions';
import { startBookingProcess, searchPatientFromCareOrders, setActivePatient } from '../../actions/rd2RefactorActions';
import OrderDetailsModal from '../../components/modals/orderDetailsModal';
import { OrderHistoryFilter } from '../../components/orderManagement';
import { OrderHistoryTable } from '../../components/orderManagement';

import { setActiveCareOrderDetails } from '../../actions/careOrderActions';
import { convertStatusCode } from '../../lib/careOrderUtils';

export class SearchOrdersView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dueDateFromIsOpen: false,
			dueDateToIsOpen: false,
			dueDateFromValue: '',
			dueDateFromIsValid: true,
			dueDateToValue: '',
			dueDateToIsValid: true,
			dueDateSetIsValid: true,
			enableEditMode: false,
			filterSelectedStatusReasonId: 0,
			isFilterLoaded: false,
			isFilterOpen: true,
			isSaveDisabled: true,
			isSavingEdit: false,
			isSearching: false,
			isOrderHistoryLoaded: false,
			isOverdue: false,
			lastModifiedDateFromValue: '',
			lastModifiedDateFromIsOpen: false,
			lastModifiedDateFromIsValid: true,
			lastModifiedDateToValue: '',
			lastModifiedDateToIsOpen: false,
			lastModifiedDateToIsValid: true,
			lastModifiedDateSetIsValid: true,
			memberId: '',
			orderDetailsModalVisible: false,
			orderEntryDateFromValue: '',
			orderEntryDateFromIsOpen: false,
			orderEntryDateFromIsValid: true,
			orderEntryDateToValue: '',
			orderEntryDateToIsOpen: false,
			orderEntryDateToIsValid: true,
			orderEntryDateSetIsValid: true,
			selectedOrder: {},
			selectedOrderStatusDisplay: '',
			selectedOrderStatusNotes: '',
			selectedSiteId: 0,
			selectedStatusCode: '',
			selectedStatusReasonId: 0,
			showEditSuccessAlert: false,
			showOrderHistory: false,
			statusNotes: '',
		};

		this.onEditClick = this.onEditClick.bind(this);
		this.onStatusListChange = this.onStatusListChange.bind(this);
		this.resolveOpenStatusReasonsForFilter = this.resolveOpenStatusReasonsForFilter.bind(this);
		this.resolveStatusReasonsForDetails = this.resolveStatusReasonsForDetails.bind(this);
	}
	componentDidMount() {
		this.props.dispatch(this.props.orderManagementActions.getSearchCareOrdersPageData())
			.then(() => this.setState({ isFilterLoaded: true }));
	}

	onDetailsClick = (e, id) => {
		e.preventDefault();
		this.props.dispatch(this.props.orderManagementActions.getCareOrderHistory(id));
		let order = this.props.orderManagement.careOrders.find(o => o.id === id);
		this.resolveStatusDisplayAndVisibility(order);

		this.setState({
			isSaveDisabled: true,
			orderDetailsModalVisible: true,
			selectedOrder: order,
			selectedOrderStatusNotes: order.notes,
		});
	}

	handleBookCareOrderVisitClick = (careOrderInfo) => {
		let PatientPhoneNumber;
		if (careOrderInfo.patientMobilePhone.trim().length > 0) {
			PatientPhoneNumber = careOrderInfo.patientMobilePhone;
		}
		else if (careOrderInfo.patientAlternatePhone.trim().length > 0) {
			PatientPhoneNumber = careOrderInfo.patientAlternatePhone;
		}
		else if (careOrderInfo.patientHomePhone.trim().length > 0) {
			PatientPhoneNumber = careOrderInfo.patientHomePhone;
		}
		else {
			PatientPhoneNumber = "";
		}
		const patientSearchCriteria = {
			firstName: careOrderInfo.patientFirstName.trim(),
			lastName: careOrderInfo.patientLastName.trim(),
			dateOfBirth: careOrderInfo.patientDateOfBirth,
			memberId: careOrderInfo.patientMemberId,
			identityValue: "",
			excludeWithoutMemberId: false,
			phoneNumber: PatientPhoneNumber,
		};

		this.props.dispatch(searchPatientFromCareOrders(patientSearchCriteria))
			.then(() => {
				this.props.dispatch(setActivePatient(this.props.patientList.items[0].referenceId))
					.then(() => {
						const useDecisionSupport = this.props.config.decisionSupport.useDecisionSupport && this.props.config.scheduling.useDecisionSupportWithCareOrders;
						this.props.dispatch(setActiveCareOrderDetails(careOrderInfo))
							.then(() => {
								this.props.dispatch(startBookingProcess(this.props.activeCareOrder, this.props.decisionSupportOutput, this.props.availabilitySearchConfig, this.props.activePatient.details, useDecisionSupport));
							});
					});
			});
	}

	onSaveDetails = () => {
		let { selectedOrder, selectedStatusCode, selectedStatusReasonId, selectedOrderStatusNotes } = { ...this.state };
		let orderUpdate = {
			id: selectedOrder.id,
			statusCode: selectedStatusCode,
			statusReason: selectedStatusReasonId,
			notes: selectedOrderStatusNotes
		};

		this.setState({ isSavingEdit: true });

		this.props.dispatch(this.props.orderManagementActions.updateCareOrder(orderUpdate))
			.then(response => {
				if (!response.error) {
					this.setState({
						showEditSuccessAlert: true,
						enableEditMode: false,
						isSaveDisabled: true,
						orderDetailsModalVisible: false,
						selectedOrderStatusNotes: '',
						selectedOrderStatusDisplay: '',
						selectedStatusCode: '',
						selectedStatusReasonId: 0,
					});
				} else {
					this.setState({ orderDetailsModalVisible: false });
				}
			})
			.finally(() => {
				this.setState({ isSavingEdit: false, isOrderHistoryLoaded: false });
				this.onFilterSearch();
			});
	}

	onCancelDetails = () => {
		this.setState({
			enableEditMode: false,
			isSaveDisabled: true,
			orderDetailsModalVisible: false,
		});
	}

	onEditClick = (e) => {
		let enableEditMode = !this.state.enableEditMode;
		let { statusCode, statusReasonId } = this.state.selectedOrder;
		this.setState({ enableEditMode, selectedStatusCode: statusCode, selectedStatusReasonId: statusReasonId.toString() });
	}

	onStatusListChange = (e) => {
		e.preventDefault();
		let selectedStatusCode = e.target.value;
		const filteredStatusReasons = this.props.pageData.careOrderStatusReasons.filter(r => r.code === selectedStatusCode);
		this.setState({
			selectedStatusCode,
			selectedStatusReasonId: filteredStatusReasons[0].id.toString()
		}, () => {
			this.resolveSaveDisabled();
		});
	}

	onStatusReasonListChange = (e) => {
		let selectedStatusReasonId = e.target.value;
		this.setState({ selectedStatusReasonId }, () => { this.resolveSaveDisabled() });
	}

	onStatusNotesChange = (e) => {
		this.setState({ selectedOrderStatusNotes: e.target.value }, () => { this.resolveSaveDisabled() });
	}

	onFilterChange = (e) => {
		if (e.target.name === 'isOverdue') {
			const isOverdue = (e.target.checked) ? true : false;
			this.setState({ isOverdue });
		} else if (e.target.name === 'selectedStatusReasonId') {
			let { value } = e.target;
			this.setState({ filterSelectedStatusReasonId: value });
		} else {
			let { name, value } = e.target;
			this.setState({ [name]: value });
		}
	}

	onFilterDateChange = (e) => {
		let { name, value } = e.target;
		let isValid = value === "" ? true : validateDate(value);
		let nameIsValid = `${name}IsValid`;
		let nameVal = `${name}Value`;
		this.setState({ [nameIsValid]: isValid, [nameVal]: value });
	}

	validateFilterForm = () => {
		let {
			dueDateFromIsValid,
			dueDateToIsValid,
			dueDateSetIsValid,
			orderEntryDateFromIsValid,
			orderEntryDateToIsValid,
			orderEntryDateSetIsValid,
			lastModifiedDateFromIsValid,
			lastModifiedDateToIsValid,
			lastModifiedDateSetIsValid,
		} = this.state;

		return dueDateFromIsValid && dueDateToIsValid && dueDateSetIsValid
			&& orderEntryDateFromIsValid && orderEntryDateToIsValid && orderEntryDateSetIsValid
			&& lastModifiedDateFromIsValid && lastModifiedDateToIsValid && lastModifiedDateSetIsValid;
	}

	onFilterSearch = (e) => {
		let isValid = this.validateFilterForm();
		if (isValid) {
			this.setState({
				showOrderHistory: true,
				isOrderHistoryLoaded: false,
				isFilterOpen: false,
			});

			let {
				dueDateFromValue,
				dueDateToValue,
				orderEntryDateFromValue,
				orderEntryDateToValue,
				isOverdue,
				lastModifiedDateFromValue,
				lastModifiedDateToValue,
				memberId,
				filterSelectedStatusReasonId,
				selectedSiteId,
			} = this.state;

			let searchCriteria = {
				dueDateFrom: dueDateFromValue,
				dueDateTo: dueDateToValue,
				entryDateFrom: orderEntryDateFromValue,
				entryDateTo: orderEntryDateToValue,
				isOverdue: isOverdue,
				lastModifiedDateFrom: lastModifiedDateFromValue,
				lastModifiedDateTo: lastModifiedDateToValue,
				memberId: memberId,
				statuCode: config.careOrderStatusCodes.open,
				statusReasonId: filterSelectedStatusReasonId === 0 ? null : filterSelectedStatusReasonId,
				siteId: selectedSiteId === 0 ? null : selectedSiteId,
			}
			this.props.dispatch(this.props.orderManagementActions.searchCareOrderHistoryAction(searchCriteria))
				.finally(() => {
					this.setState({ isOrderHistoryLoaded: true });
				});
		}
	}

	onFilterReset = () => {
		this.setState({
			memberId: '',
			filterSelectedStatusReasonId: 0,
			selectedSiteId: 0,
			dueDateFromValue: '',
			dueDateFromIsValid: true,
			dueDateToValue: '',
			dueDateToIsValid: true,
			dueDateSetIsValid: true,
			orderEntryDateFromValue: '',
			orderEntryDateFromIsValid: true,
			orderEntryDateToValue: '',
			orderEntryDateToIsValid: true,
			orderEntryDateSetIsValid: true,
			lastModifiedDateFromValue: '',
			lastModifiedDateFromIsValid: true,
			lastModifiedDateToValue: '',
			lastModifiedDateToIsValid: true,
			lastModifiedDateSetIsValid: true,
			isOverdue: false,
		});
	}

	handleDatepickerChange = (name, date) => {
		this.toggleCalendar(name);
		let isValid = date ? true : validateDate(date);
		let nameIsValid = `${name}IsValid`;
		let nameVal = `${name}Value`
		this.setState({ [nameVal]: moment(date).format("MM/DD/YYYY"), [nameIsValid]: isValid });
	};

	toggleCalendar = (name) => {
		let isOpenName = `${name}IsOpen`;
		let val = this.state[isOpenName];
		this.setState({ [isOpenName]: !val });
	}

	toggleFilter = () => {
		this.setState({ isFilterOpen: !this.state.isFilterOpen });
	}

	dismissEditSuccessAlert = () => {
		this.setState({ showEditSuccessAlert: false });
	}

	resolveSaveDisabled = () => {
		let { orderStatusNotes, selectedOrder, selectedStatusCode, selectedStatusReasonId } = { ...this.state };
		let isSaveDisabled = true;
		if (selectedStatusCode !== selectedOrder.statusCode) {
			isSaveDisabled = false;
		} else if (selectedStatusReasonId !== selectedOrder.statusReasonId) {
			isSaveDisabled = false;
		} else if (orderStatusNotes) {
			isSaveDisabled = false;
		}
		this.setState({ isSaveDisabled });
	}

	resolveStatusDisplayAndVisibility = (order) => {
		let selectedOrderStatusDisplay = '';

		switch (order.statusCode.toLowerCase()) {
			case config.careOrderStatusCodes.open.toLowerCase():
				selectedOrderStatusDisplay = convertStatusCode(order.statusCode);
				break;
			case config.careOrderStatusCodes.closed.toLowerCase():
				selectedOrderStatusDisplay = `${convertStatusCode(order.statusCode)} (${order.statusReason})`;
				break;
			default:
				selectedOrderStatusDisplay = "-";
				break;
		}
		this.setState({ selectedOrderStatusDisplay });
	}

	resolveOpenStatusReasonsForFilter = () => {
		var reasons = this.props.pageData.careOrderStatusReasons;
		let results = (reasons && reasons.filter(r => {
			return r.appliesToCareOrder === true && r.code === config.careOrderStatusCodes.open;
		}));
		return results;
	}

	resolveStatusReasonsForDetails = () => {
		var reasons = this.props.pageData.careOrderStatusReasons;
		let results = (reasons && reasons.filter(r => {
			return r.appliesToCareOrderVisit === true;
		}));
		return results;
	}

	render() {
		let openOrders = this.props.orderManagement.careOrders.filter(o => o.statusCode && o.statusCode.toLowerCase() !== config.careOrderStatusCodes.closed.toLowerCase()).sort((a, b) => b.statusCode - a.statusCode);
		let memberIdLabel = this.props.config.patient.memberId.fieldLabel;
		let {
			dueDateFromIsOpen,
			dueDateToIsOpen,
			enableEditMode,
			isFilterLoaded,
			isFilterOpen,
			isSaveDisabled,
			isSavingEdit,
			isSearching,
			isOverdue,
			memberId,
			dueDateFromValue,
			dueDateFromIsValid,
			dueDateToValue,
			dueDateToIsValid,
			dueDateSetIsValid,
			filterSelectedStatusReasonId,
			lastModifiedDateFromValue,
			lastModifiedDateFromIsOpen,
			lastModifiedDateFromIsValid,
			lastModifiedDateToValue,
			lastModifiedDateToIsOpen,
			lastModifiedDateToIsValid,
			lastModifiedDateSetIsValid,
			orderDetailsModalVisible,
			orderEntryDateFromValue,
			orderEntryDateFromIsOpen,
			orderEntryDateFromIsValid,
			orderEntryDateToValue,
			orderEntryDateToIsOpen,
			orderEntryDateToIsValid,
			orderEntryDateSetIsValid,
			selectedOrderStatusNotes,
			selectedOrder,
			selectedOrderStatusDisplay,
			selectedSiteId,
			selectedStatusCode,
			selectedStatusReasonId,
			showStatusReasonList
		} = this.state;

		let sites = this.props.pageData ? this.props.pageData.sites : [];
		let careOrderStatuses = this.props.pageData ? this.props.pageData.careOrderStatuses : [];

		let filterStatusReasons = this.props.pageData ? this.resolveOpenStatusReasonsForFilter() : [];
		let detailStatusReasons = this.props.pageData ? this.resolveStatusReasonsForDetails() : [];

		return (
			<React.Fragment>
				<ActiveErrors />
				<SectionHeading label="Search Open Follow Up Orders" />
				<Alert className="text-center" color="success" isOpen={this.state.showEditSuccessAlert} toggle={this.dismissEditSuccessAlert}>
					SUCCESS! The order edit has been saved.
				</Alert>
				<div>
					<OrderHistoryFilter
						dueDateFromIsOpen={dueDateFromIsOpen}
						dueDateToIsOpen={dueDateToIsOpen}
						dueDateFromValue={dueDateFromValue}
						dueDateFromIsValid={dueDateFromIsValid}
						dueDateToValue={dueDateToValue}
						dueDateToIsValid={dueDateToIsValid}
						dueDateSetIsValid={dueDateSetIsValid}
						handleDatePickerChange={this.handleDatepickerChange}
						isLoaded={isFilterLoaded}
						isFilterOpen={isFilterOpen}
						isSearching={isSearching}
						isOverdue={isOverdue}
						memberId={memberId}
						memberIdLabel={memberIdLabel}
						memberIdMaxLength={this.props.config.patient.memberId.maxLength}
						onChange={this.onFilterChange}
						onDateChange={this.onFilterDateChange}
						onSearch={this.onFilterSearch}
						onReset={this.onFilterReset}
						lastModifiedDateFromValue={lastModifiedDateFromValue}
						lastModifiedDateFromIsOpen={lastModifiedDateFromIsOpen}
						lastModifiedDateFromIsValid={lastModifiedDateFromIsValid}
						lastModifiedDateToValue={lastModifiedDateToValue}
						lastModifiedDateToIsOpen={lastModifiedDateToIsOpen}
						lastModifiedDateToIsValid={lastModifiedDateToIsValid}
						lastModifiedDateSetIsValid={lastModifiedDateSetIsValid}
						orderEntryDateFromValue={orderEntryDateFromValue}
						orderEntryDateFromIsOpen={orderEntryDateFromIsOpen}
						orderEntryDateFromIsValid={orderEntryDateFromIsValid}
						orderEntryDateToValue={orderEntryDateToValue}
						orderEntryDateToIsOpen={orderEntryDateToIsOpen}
						orderEntryDateToIsValid={orderEntryDateToIsValid}
						orderEntryDateSetIsValid={orderEntryDateSetIsValid}
						selectedSiteId={selectedSiteId}
						selectedStatusReasonId={filterSelectedStatusReasonId}
						siteList={sites}
						statusReasonList={filterStatusReasons}
						toggleCalendar={this.toggleCalendar}
						toggleFilter={this.toggleFilter} />
					{this.state.showOrderHistory &&
						<OrderHistoryTable
							memberIdLabel={memberIdLabel}
							isLoaded={this.state.isOrderHistoryLoaded}
							orders={openOrders}
							onDetailsClick={this.onDetailsClick}
							showPatientInfo={true}
							handleBookCareOrderVisitClick={this.handleBookCareOrderVisitClick}
							isParentCreateOrder={false} />
					}
					<OrderDetailsModal
						isEditMode={enableEditMode}
						isOpen={orderDetailsModalVisible}
						isSaveDisabled={isSaveDisabled}
						onCancel={this.onCancelDetails}
						onConfirm={this.onSaveDetails}
						onDateBlur={this.validateDate}
						onEdit={this.onEditClick}
						onStatusNotesChange={this.onStatusNotesChange}
						onStatusListChange={this.onStatusListChange}
						onStatusReasonListChange={this.onStatusReasonListChange}
						order={selectedOrder}
						orderStatusDisplay={selectedOrderStatusDisplay}
						selectedOrderStatusNotes={selectedOrderStatusNotes}
						selectedStatusCode={selectedStatusCode}
						selectedStatusReasonId={selectedStatusReasonId}
						showStatusReasonList={showStatusReasonList}
						statusOptionsList={careOrderStatuses}
						statusReasonOptionsList={detailStatusReasons}
						isSavingEdit={isSavingEdit}
						careOrderHistory={this.props.orderManagement.careOrderHistory}
						isParentCreateOrder={false} />
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		auth: state.auth,
		orderManagement: state.orderManagement,
		pageData: state.orderManagement.searchCareOrdersPageData,
		config: state.config,
		activeCareOrder: state.careOrder,
		activePatient: state.activePatient,
		decisionSupportOutput: state.decisionSupport,
		availabilitySearchConfig: state.config.availabilitySearch,
		correlationKey: state.session.correlationKey,
		patientList: state.patientSearch.patientList,
		setActiveCareOrder: state.setActiveCareOrder,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
		orderManagementActions: orderManagementActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrdersView);
