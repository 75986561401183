import * as actionTypes from './actionTypes';

export function startFlow(startFlow) {
	return {
		type: actionTypes.GUIDED_RESPONSE_START_FLOW,
		startFlow: startFlow,
		payload: {
			request: {
				method: 'post',
				url: `guidedresponse/runtime/start?flowId=${startFlow.flowId}`,
				data: startFlow,
			},
		},
	};
}

export function continueFlow(flowSessionId, userResponses) {
	return {
		type: actionTypes.GUIDED_RESPONSE_CONTINUE_FLOW,
		flowSessionId: flowSessionId,
		userResponses: userResponses,
		payload: {
			request: {
				method: 'post',
				url: `guidedresponse/runtime/continue?flowSessionId=${flowSessionId}`,
				data: userResponses,
			},
		},
	};
}

export function previous(flowSessionId) {
	return {
		type: actionTypes.GUIDED_RESPONSE_PREVIOUS,
		flowSessionId,
		payload: {
			request: {
				method: 'post',
				url: `guidedresponse/runtime/previous?flowSessionId=${flowSessionId}`,
				data: {},
			},
		},
	};
}

export function getFlowDebugPathFile(flowSessionId, token) {
	return {
		type: actionTypes.GUIDED_RESPONSE_DEBUG_PATH,
		payload: {
			request: {
				method: 'get',
				url: `guidedresponse/debugpath/${flowSessionId}`,
			},
		},
		headers: {
			token: token
		},
	};
}

export function getSessionQuestionsAndAnswers(decisionSupportSessionId) {
	return {
		type: actionTypes.GET_SESSION_QUESTIONS_AND_ANSWERS,
		payload: {
			request: {
				method: 'get',
				url: `guidedresponse/sessionquestionsandanswers/${decisionSupportSessionId}`,
			}
		}
	};
}

export function clearSessionQuestionsAndAnswers() {
	return {
		type: actionTypes.CLEAR_SESSION_QUESTIONS_AND_ANSWERS,
	};
}

export function getSession(flowSessionId) {
	return {
		type: actionTypes.GUIDED_RESPONSE_GET_FLOW_SESSION,
		payload: {
			request: {
				method: 'post',
				url: `guidedresponse/runtime/session?flowSessionId=${flowSessionId}`
			}
		}
	};
}

//TODO: The below methods will be deprecated when new Resume endpoint is implemented
function saveAnsweredQuestionsAction(questions) {
	return {
		type: actionTypes.GUIDED_RESPONSE_SAVE_FINISHED_QUESTIONS,
		data: questions,
	};
}

export function saveAnsweredQuestions(questions) {
	return function (dispatch) {
		dispatch(saveAnsweredQuestionsAction(questions));
	};
}

export function getDSVResults(flowSessionId) {
	return {
		type: actionTypes.GET_GUIDED_RESPONSE_DSV_RESULTS,
		payload: {
			request: {
				method: 'get',
				url: `guidedresponse/dsv/verbiageresults/${flowSessionId}`,
			},
		}
	};
}