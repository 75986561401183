//@ts-check
import React from 'react';

/**
 *
 * @param {import('react').PropsWithChildren<{
 *  label?: import('react').ReactNode,
 *  emptyDisplay?: import('react').ReactNode,
 *  className?: string,
 * }>} props
 * @returns {import('react').ReactElement}
 */
export default function ReadonlyField({
	label,
	className,
	emptyDisplay,
	children,
}) {
	return (
		<div className={`field ${className ?? ''}`}>
			{label && <label className="field-label">{label}</label>}
			<div className="field-value">
				{hasChildren(children) ? (
					children
				) : emptyDisplay !== undefined ? (
					<i className="text-muted">{emptyDisplay}</i>
				) : (
					'-'
				)}
			</div>
		</div>
	);
}

/**
 *
 * @param {import('react').ReactNode} children
 * @returns {boolean}
 */
function hasChildren(children) {
	return children instanceof Array
		? children.some((node) => hasChildren(node))
		: children !== false && children !== '' && isDefined(children);
}

function isDefined(value) {
	return value !== null && value !== undefined;
}
