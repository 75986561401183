import * as types from '../actions/actionTypes'

const initialState = {
	details: {},
	availabilityList: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.PROVIDER_DETAILS_SUCCESS:
			return {
				...state,
				details: action.payload.data
			}

		default:
			return state
	}
}