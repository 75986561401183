import { Button, FormGroup } from "reactstrap"

const StartForgotPassword = (props: { isLoading: boolean, handleForgotPassword: Function }) => {
    return (
        <FormGroup>
            <Button color="link" onClick={() => props.handleForgotPassword()}>
                Forgot Password?
            </Button>
        </FormGroup>
    )
}

export default StartForgotPassword;