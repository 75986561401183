//Third Party Dependencies
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

//MHD General Purpose libraries
import { useAppHistory, useDataApiState, useVoidApiState } from 'hooks';
import { useAppSelector } from 'store';

//Specific Business and UI components
import { PasswordConfigInfo, VerifyUserTokenInfo, VerifyUserTokenResult } from 'types';
import { internalService } from 'services';
import ResetPasswordForm from './comps/resetPasswordForm';
import ErrorSummary from '../../components/common/errorSummary';
import UserViewContainer from './comps/userViewContainer';
import LoginLogo from './comps/loginLogo';
import LoginFooter from './comps/loginFooter';
import ContactUs from './comps/contactUs';

export const ResetPasswordView = () => {
    const [verifyUserTokenInfo, setVerifyUserTokenInfo] = useState<VerifyUserTokenInfo>();

    const [verifyTokenResult, setVerifyToken] = useDataApiState<VerifyUserTokenResult>();
    const [resetUserPassword, setResetUserPassword] = useVoidApiState();

    const history = useAppHistory();
    const location = useLocation();
    const passwordConfig = useAppSelector((s: any) => s.config.passwordConfig) as PasswordConfigInfo;
    const logoUrl = useAppSelector((s: any) => s.config.instance.logoUrl) as string;

    useEffect(() => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as qs.ParsedQs;
        const verifyUserInfo: VerifyUserTokenInfo = {
            userId: params.userid as string,
            token: params.token as string,
        };
        setVerifyUserTokenInfo(verifyUserInfo);
        internalService.verifyToken(verifyUserInfo, setVerifyToken);
    }, [location.search, setVerifyToken]);

    const handleSubmit = (newPassword: string, confirmPassword: string) => {
        if (!verifyUserTokenInfo) {
            return;
        }
        const data = {
            newPassword,
            confirmPassword,
            token: verifyUserTokenInfo.token,
        };
        internalService.resetUserPassword(verifyUserTokenInfo.userId, data, setResetUserPassword);
    };

    const goToLogin = () => {
        history.navigate('/login');
    };

    const isPageLoading = verifyTokenResult.loading;
    const isVerified = verifyTokenResult.data?.isVerified ?? false;
    const showResetPasswordForm = !isPageLoading && isVerified;

    return (
        <UserViewContainer isLoading={isPageLoading} className="resetPasswordView">
            <div className="resetPasswordWindow">
                <LoginLogo />
                {verifyTokenResult.error && <ErrorSummary error={verifyTokenResult.error} />}
                {resetUserPassword.error && <ErrorSummary error={resetUserPassword.error} />}
                {showResetPasswordForm &&
                    <ResetPasswordForm
                        passwordConfig={passwordConfig}
                        onSubmit={handleSubmit}
                        onCancel={goToLogin}
                        email={verifyTokenResult.data?.email ?? ''}
                        logoUrl={logoUrl}
                        isSaving={resetUserPassword.processing}
                        isCompleted={resetUserPassword.completed}
                    />
                }
                <LoginFooter />
                <ContactUs />
            </div>
        </ UserViewContainer>
    );
};

export default ResetPasswordView;
