//Third Party Dependencies
import qs from 'qs';
import * as React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
import { bindActionCreators } from 'redux';

//MHD General Purpose libraries
import ErrorBoundary from './components/common/errorBoundary';

//Specific Business and UI components
import * as errorActions from './actions/errorActions';
import * as productAlertConfigActions from './actions/productAlertConfigActions';
import * as rd2RefactorActions from './actions/rd2RefactorActions';
import * as sessionActions from './actions/sessionActions';
import ProductAlertBanner from './components/common/productAlertBanner';
import TelemetryProvider from './components/common/telemetry-provider';
import Footer from './components/footer';
import Header from './components/header';
import withSupportedBrowser from './components/hoc/withSupportedBrowser';
import ModalRoot from './components/modalRoot';
import { productIds } from './constants/productAlertConfigValues';
import history from './lib/history';
import isDefined from './lib/isDefined';
import * as routes from './routes';

const AppCss = Loadable({
	loader: () => import('./AppCss'),
	loading() {
		return null
	}
});

//const NighthawkCss = Loadable({
//	//import * as css from '../nighthawkComponents/css/index';
//	// TODO: Add nighthawk css component
//	loader: () => import('./nighthawk/NighthawkCss'),
//	loading() {
//		return null
//	}
//});

function getLoadableCssComponent(pathname, isAuthenticated, useLegacyUI) {
	let conditionalCssComponent;

	useLegacyUI = true;

	if (isAuthenticated) {
		// must defer non-route based CSS loading until after authentication to prevent loading multiple conflicting stylesheets

		// For now just always use legacy UI .. July release will fix this
		conditionalCssComponent = <AppCss />;
		//conditionalCssComponent = useLegacyUI ? <AppCss /> : <NighthawkCss />;
	} else {
		conditionalCssComponent = null;
	};

	return conditionalCssComponent;
}

export class App extends React.Component {
	state = {
		instanceLoading: true,
		touchpointInitialized: false,
	};

	componentDidMount() {
		this.unlisten = history.listen((location, action) => {
			this.props.actions.error.clearAllErrors();
		});

		let queryString = (this.props.history.location.state && this.props.history.location.state.from) ? this.props.history.location.state.from.search.toLowerCase() : this.props.history.location.search.toLowerCase();
		let optionalParams = qs.parse(queryString, { ignoreQueryPrefix: true });
		if (!this.props.instance.isLoaded) {
			let instanceName = optionalParams != null ? optionalParams.instancename : null;
			this.loadInstance(this.props, instanceName);
		} else {
			this.setState({ instanceLoading: false });
		}
		this.callGetProductAlertConfig();
	}

	componentWillUnmount() {
		this.unlisten();
	}

	componentDidUpdate(prevProps) {
		if (this.props.instance.isLoaded !== prevProps.instance.isLoaded && !this.props.instance.isLoaded && !this.state.instanceLoading) {
			this.loadInstance(this.props);
		}

		// if (this.props.instance.isLoaded && !this.state.touchpointInitialized) {
		// 	window.Touchpoint.initialize({
		// 		// Please check documentation for other Touchpoint settings that can be configured
		// 		settings: {
		// 			containerStyle: {
		// 				borderRadius: '8px',
		// 				boxShadow:
		// 					'0px 3px 5px 0px rgba(0,0,0,0.2), 0px 1px 18px 0px rgba(0,0,0,0.12), 0px 6px 10px 0px rgba(0,0,0,0.14)'
		// 			}
		// 		},
		// 		visitor: {
		// 			// Pass data points from your site/application to identify the current respondent
		// 			// id: VISITOR_ID,
		// 			// email: VISITOR_EMAIL,
		// 			// role: VISITOR_ROLE,
		// 			// etc.
		// 			host: this.props.instance.host
		// 		},
		// 		user_attributes: {
		// 			// Define attribute data types to be used for targeting.
		// 			// Dates must be in ISO 8601 format
		// 			// age: 'number',
		// 			// country: 'string',
		// 			// purchase_date: 'date',
		// 			// is_active_user: 'boolean'
		// 			// etc.
		// 			host: 'string'
		// 		},
		// 		publisher: {
		// 			// Please do not make edits below
		// 			app_id: "o1ltreegQ9XaR8ee",
		// 			pod: "na2",
		// 		},
		// 	});
		// 	this.setState({ touchpointInitialized: true })
		// }
	}

	loadInstance = (props, instanceName) => {
		this.setState({ instanceLoading: true });
		props.actions.session.getProductInstanceConfig(instanceName)
			.then((response) => {
				if (response.error) {
					this.setState({ error: 'An error occurred while trying to retrieve external auth config.' });
				}
				this.setState({ instanceLoading: false });
			});
	};

	callGetProductAlertConfig = () => {
		this.props.actions.productAlertConfig.getProductAlertConfig(productIds.RD2);
	};

	shouldDisplayAlertBanner = () => {
		if (window.location.pathname === '/login' ||
			window.location.pathname === '/logout' ||
			!this.props.productAlertConfig.config?.isEnabled ||
			!this.props.productAlertConfig.config?.alertMessage?.length
		) {
			return false;
		}
		return true;
	};

	isAlertBannerExpired = () => {
		const nowDateTime = new Date();
		const hasEffectiveEndAt =
			this.props.productAlertConfig.config?.effectiveEndAt &&
			isDefined(this.props.productAlertConfig.config?.effectiveEndAt) &&
			isNaN(Date.parse(this.props.productAlertConfig.config?.effectiveEndAt)) === false;
		if (!hasEffectiveEndAt) {
			return false;
		}
		const alertEndDate = new Date(this.props.productAlertConfig.config.effectiveEndAt);

		return alertEndDate < nowDateTime;
	};

	render() {
		const { pathname } = this.props.location;
		const { isAuthenticated, useLegacyUI } = this.props.auth;

		let conditionalAppCss = getLoadableCssComponent(pathname, isAuthenticated, useLegacyUI);

		const { Rd2RouteSwitch } = routes;
		const menuItems = routes.menuItems();

		let header = this.props.instance.isLoaded && this.props.isAuthenticated && this.props.isTermsOfUseAccepted && !this.props.compelPasswordChange ?
			<Header auth={this.props.auth} isSso={this.props.externalAuth.enableSingleSignOn} menuItems={menuItems} logoUrl={this.props.instance.logoUrl} />
			:
			'';

		let footer = this.props.instance.isLoaded && this.props.isAuthenticated && this.props.isTermsOfUseAccepted && !this.props.compelPasswordChange ?
			<Footer
				instance={this.props.instance}
			/>
			:
			'';

		let contentCol = ""
		let routeSwitchContainer = "";
		if (this.props.isAuthenticated && this.props.isTermsOfUseAccepted && !this.props.compelPasswordChange) {
			routeSwitchContainer = "routeSwitchContainer";
			contentCol += "contentCol";
		}

		let routeBody = "";
		if (this.props.auth.redirectToUrl) {

			let fullUrl = `https://${this.props.auth.redirectToUrl}`;
			routeBody = (
				<Modal centered isOpen={true}>
					<ModalHeader>URL Has Changed</ModalHeader>
					<ModalBody>Our URL has changed. Please use and bookmark this new URL to login.  <a href={fullUrl}>{fullUrl}</a>.</ModalBody>
					<ModalFooter />
				</Modal >
			);
		} else {
			routeBody = <Rd2RouteSwitch instance={this.props.instance} location={this.props.location} config={this.props.config} isAuthenticated={this.props.isAuthenticated} />;
		}

		let layout =
			<TelemetryProvider instrumentationKey={this.props.applicationInsightsKey} auth={this.props.auth} session={this.props.session}>
				<ErrorBoundary>
					<Container fluid className="d-flex flex-column px-0 siteContainer2">
						{conditionalAppCss}
						<Row className="g-0 flex-shrink-0">
							<Col>
								{header}
							</Col>
						</Row>
						<Row className={!this.props.isAuthenticated ? "g-0 flex-fill d-flex justify-content-start contentContainer2" : "flex-fill d-flex justify-content-start contentContainer2"}>
							<Col className={contentCol}>
								<div className={routeSwitchContainer}>
									{this.shouldDisplayAlertBanner() &&
										this.props.productAlertConfig.isActive && (
											<div className='appAlertBanner'>
												<ProductAlertBanner config={this.props.productAlertConfig.config} />
											</div>
										)}
									{routeBody}
								</div>
							</Col>
						</Row>
						<Row className="g-0 flex-shrink-0">
							<Col >
								{footer}
								<ModalRoot />
							</Col>
						</Row>
					</Container>
				</ErrorBoundary>
			</TelemetryProvider>
		return layout;
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activePatientReferenceId: state.activePatient.details.referenceId,
		applicationInsightsKey: state.auth.applicationInsightsKey,
		auth: state.auth,
		compelPasswordChange: state.auth.compelPasswordChange,
		config: state.config,
		externalAuth: state.config.externalAuth,
		instance: state.config.instance,
		isAuthenticated: state.auth.isAuthenticated,
		isLoading: state.ajaxStatus.callsInProgressCount > 0,
		isTermsOfUseAccepted: state.auth.isTermsOfUseAccepted,
		productAlertConfig: state.productAlertConfig,
		session: state.session,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			error: bindActionCreators(errorActions, dispatch),
			rd2Refactor: bindActionCreators(rd2RefactorActions, dispatch),
			productAlertConfig: bindActionCreators(productAlertConfigActions, dispatch),
			session: bindActionCreators(sessionActions, dispatch),
		}
	}
}

export default withSupportedBrowser(withRouter(
	connect(mapStateToProps, mapDispatchToProps)(App)
));
