import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as validation from '../../lib/validation';
import * as validationUtils from '../../nighthawk/utility/validationUtils';
import * as multiValue from '../../nighthawk/utility/multiValueUtils';
import { NhLoadingIndicatorModal } from '../../nighthawk/common/loadingIndicator/nhLoadingIndicatorModal'
import * as guidedResponseActions from '../../actions/guidedResponseActions';
import { GuidedResponseWorkflow } from './guidedResponseWorkflow';
import { DebugComponent } from './guidedResponseDebugMode';
import { default as DebugErrors } from '../../components/common/activeErrors';
import '../../nighthawk/css/views/guidedResponseView.css';
import LoggerUtils from 'lib/loggerUtils';

const buildErrorGR = (element, ruleName, message) => {
	//TODO: double check meaning of isFormLevel. (NH sets to false, but that seems weird)
	//NOTE: ruleName may either go away, or just be set to "element" ... or may be something like "MinValue", "IsRequired", etc ...
	let error = { fieldId: element.elementRef, fieldGroupName: 'guidedResponse', fieldName: element.elementRef, ruleName, message, isFormLevel: true };
	return error;
};

const getErrorLabelByInputType = inputType => {
	let label = "";
	switch (inputType) {
		case "Text":
		case "MultiLineText":
		case "Bool":
			label = "answer";
			break;
		case "Selection":
		case "MultiSelection":
		case "SelectionWithConditionalText":
			label = "selection";
			break;
		case "Date":
			label = "date";
			break;
		case "Integer":
			label = "number";
			break;
		case "PhoneNumber":
			label = "phone number";
			break;
		case "Email":
			label = "email";
			break;
		case "ZipCode":
			label = "zip code";
			break;
		default:
			label = "answer";
			break;
	}
	return label;
}

const validateFieldGR = (element, answer, answerIsConditionalText = false) => {
	let error = null;
	let value = answer; // is Trim appropriate here?
	let hasValue = (value) ? true : false;
	let isAnswerSelection = element.selectOptions.some(so => so.value === value);

	if (typeof value === 'string' || value instanceof String) {
		value = value.trim();
		hasValue = value.length > 0;
	}

	if (element.inputType === 'SelectionWithConditionalText') {
		if (element.isRequired) {
			if (element.conditionalTextTriggerValue === value && element.conditionalTextInputRequired) {
				value = "";
				hasValue = false;
			}
		}

		if (!error && !answerIsConditionalText && !isAnswerSelection) {
			error = buildErrorGR(
				element,
				validationUtils.COMMON_RULE_NAMES.REQUIRED,
				validationUtils.DEFAULT_ERROR(getErrorLabelByInputType(element.inputType))
			)
		}
	}

	if (element.isRequired) {
		error = (!hasValue) ? buildErrorGR(element, validationUtils.COMMON_RULE_NAMES.REQUIRED, validationUtils.DEFAULT_ERROR(getErrorLabelByInputType(element.inputType))) : null;
	}

	if (element.inputType === "Selection") {
		if (!error && (element.isRequired && !isAnswerSelection)) {
			error = buildErrorGR(
				element,
				validationUtils.COMMON_RULE_NAMES.REQUIRED,
				validationUtils.DEFAULT_ERROR(getErrorLabelByInputType(element.inputType))
			)
		}
	}


	if (!error && !!element.minLength && element.isRequired && !isAnswerSelection && value.length < element.minLength) {
		error = buildErrorGR(element, validationUtils.COMMON_RULE_NAMES.REQUIRED, `Please enter in at least ${element.minLength} characters.`);
	}

	if (!error && !!element.maxLength && element.isRequired && !isAnswerSelection && value.length > element.maxLength) {
		error = buildErrorGR(element, validationUtils.COMMON_RULE_NAMES.REQUIRED, `Only ${element.maxLength} characters are allowed.`);
	}

	if (!error && hasValue && element.dateRestrictionType) {
		let inputRefValid = false;
		switch (element.inputType) {
			case 'Date':
				if (element.dateRestrictionType === "PastOnly") {
					inputRefValid = validation.validatePastDate(value);
				}
				if (element.dateRestrictionType === "FutureOnly") {
					inputRefValid = validation.validateFutureDate(value);
				}
				if (element.dateRestrictionType === "None") {
					inputRefValid = validation.validateAnyDate(value);
				}
				break;
			default:
				inputRefValid = true;
				break;
		}
		if (!inputRefValid) {
			error = buildErrorGR(element, validationUtils.COMMON_RULE_NAMES.REQUIRED, validationUtils.DEFAULT_ERROR(getErrorLabelByInputType(element.inputType)));
		}
	}

	if (element.inputType === "PhoneNumber") {
		if (!error && hasValue && !validation.validatePhoneNumber(value)) {
			error = buildErrorGR(
				element,
				validationUtils.COMMON_RULE_NAMES.REQUIRED,
				validationUtils.DEFAULT_ERROR(getErrorLabelByInputType(element.inputType))
			)
		}
	}

	let errorsResult = [];
	if (error) {
		errorsResult.push(error); // add back in an error for the current element being validated (if still relevant)
	}

	return errorsResult.length ? errorsResult[0] : null;
}

export const validatePageGR = (currentQuestions, allQuestions) => {
	currentQuestions.forEach(element => {
		const question = allQuestions.find(question => question.elementRef === element.elementRef);
		const questionIndex = allQuestions.indexOf(question);

		if (element.inputType !== "SelectionWithConditionalText") {
			allQuestions[questionIndex].error = validateFieldGR(element, question.answer);
		} else {
			if (question.answer === question.conditionalTextTriggerValue && question.conditionalTextInputRequired) {
				allQuestions[questionIndex].error = validateFieldGR(element, question.altAnswer, true);
			} else {
				allQuestions[questionIndex].error = validateFieldGR(element, question.answer);
			}
		}
	});

	return allQuestions;
}

export class GuidedResponseComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			questions: [],
			isLoading: false,
			isResumingFlow: (props.flowSessionResponse?.flowSessionId && props.flowSessionResponse.flowSessionId !== '00000000-0000-0000-0000-000000000000')
		}
	}

	mapQuestions = (elements) => {
		if (!elements)
			return;

		let duplicateElementFound = false;

		elements.forEach(element => {
			this.state.questions.forEach(question => {
				if (question.elementRef === element.elementRef)
					duplicateElementFound = true;
			});

			if (!duplicateElementFound) {
				if (element.elementType !== 'Question')
					return;

				const isMultiValue = element.inputType === 'MultiSelection';
				const newQuestion = {
					elementRef: element.elementRef,
					isMultiValue,
					answer: element.defaultValue ? element.defaultValue : '',
					altAnswer: '', //added to support conditional text
					error: null,
					isFocused: false,
					inputType: element.inputType,
					isRequired: element.isRequired,
					conditionalTextTriggerValue: element.conditionalTextTriggerValue,
					conditionalTextInputRequired: element.conditionalTextInputRequired
				}

				this.setState(state => ({ questions: [...state.questions, newQuestion] }));
			}
		});
	}

	componentDidMount() {

		var grFlowSessionIdPresent = (this.props.flowSessionResponse?.flowSessionId && this.props.flowSessionResponse.flowSessionId !== '00000000-0000-0000-0000-000000000000');

		if (grFlowSessionIdPresent) {
			this.setState({ isLoading: true });
			this.resumeFinishedFlow();
		}
		else {
			this.startFlow();
		}
	}

	startFlow = (debuggerFlowInputs = null, debugPathFileContents = null) => {

		let chainedFlowUniqueToken = null;
		let decisionSupportSessionId = null;

		if (this.props.chainedFlowUniqueToken !== undefined) {
			chainedFlowUniqueToken = this.props.chainedFlowUniqueToken;
		}

		if (this.props.chainedFlowDecisionSupportSessionId !== undefined) {
			decisionSupportSessionId = this.props.chainedFlowDecisionSupportSessionId;
		}

		let startFlowData = {
			correlationKey: this.props.correlationKey,
			flowId: this.props.decisionSupportConfig.flowId,
			flowUniqueToken: chainedFlowUniqueToken, //this is only present when spoofing the config for chaining an ap flow to a guided response flow
			decisionSupportSessionId: decisionSupportSessionId,
			// versionRef: versionRef, //TODO: Decide if we want to bring this feature (debugMode) into RD2
			// effectiveAt: effectiveAt,
			productInstanceId: this.props.productInstanceId,
			inputFields: this.props.contextData.inputFields,
			enableDebugging: (this.props.debugMode || this.props.enableDebuggerFromAp),
			debuggerFlowInputs,
			debugPathFileContents,
			patientReferenceId: this.props.patientReferenceId,
			careOrderVisitIdentifier: this.props.careOrderVisitIdentifier
		}
		this.props.actions.guidedResponse.startFlow(startFlowData)
			.then((response) => {

				if (response.payload.data.elementSet)
					this.mapQuestions(response.payload.data.elementSet?.elements);

				this.setState({ isLoading: false });
				window.scrollTo(0, 0);
			})
			.catch((err) => {
			});
	}

	resumeFlowFromSession = (flowSessionId) => {
		return this.props.actions.guidedResponse.getSession(flowSessionId)
			.then((response) => {
				this.mapQuestions(response.payload.data.elementSet?.elements);
				this.setState({ isLoading: false });
				window.scrollTo(0, 0);
			})
			.catch((err) => {
			});
	}

	continueFlow = (flowSessionId, userResponses) => {
		userResponses.responses.forEach(response => {
			response.responseValue = encodeURIComponent(response.responseValue);
		});
		this.props.actions.guidedResponse.continueFlow(flowSessionId, userResponses)
			.then((response) => {
				this.mapQuestions(response.payload.data.elementSet?.elements);
				window.scrollTo(0, 0);
			})
			.catch((err) => {
			});
	}

	previous = (flowSessionId) => {
		if (this.props.chainGuidedResponseFlowFromAp && this.props.flowSessionResponse.isFlowAtStart) {
			return this.props.goBackToAppliedPathways();
		}
		else {
			if (this.props.flowSessionResponse.elementSet) {
				const { elements } = this.props.flowSessionResponse.elementSet;

				const currentQuestions = elements.filter(element => element.elementType === 'Question');
				const allQuestions = this.state.questions;

				currentQuestions.forEach(currentQuestion => {
					const question = allQuestions.find(question => question.elementRef === currentQuestion.elementRef)
					if (question && question.inputType === "MultiSelection") {
						question.answer = null;
					}
				});
			}

			return this.props.actions.guidedResponse.previous(flowSessionId)
				.then((response) => {
					//Had to add this line to populate the state object for the questions
					//now that we support "fast-forwarding" flows for debugging purposes.
					this.mapQuestions(response.payload.data.elementSet?.elements);
					this.setState({ isLoading: false });
					window.scrollTo(0, 0);
				})
				.catch((err) => {
				});
		}
	}

	resumeFinishedFlow = () => {
		//Developer Beware: We MUST make a deep copy  here due to Redux immutability
		//This is to divorce ourselves from Redux state, and prevent mutation between dispatches
		const questions = this.props.questions.map(question => {
			return Object.assign({}, question);
		});

		this.setState({
			questions,
		});

		var grFlowSessionIdPresent = (this.props.flowSessionResponse?.flowSessionId && this.props.flowSessionResponse.flowSessionId !== '00000000-0000-0000-0000-000000000000');

		if (grFlowSessionIdPresent) {
			if (this.props.isFlowFinished && !this.props.isFlowAtStart) {
				this.previous(this.props.flowSessionResponse.flowSessionId).then(() => this.setState({ isResumingFlow: false }));
			} else {
				this.resumeFlowFromSession(this.props.flowSessionResponse.flowSessionId).then(() => this.setState({ isResumingFlow: false }));
				if (this.props.isFlowFinished && this.props.isFlowAtStart) {
					this.handleComplete();
				}
			}
		} else {
			this.startFlow();
			this.setState({ isLoading: false, isResumingFlow: false });
			window.scrollTo(0, 0);
		}
	}

	mapFlowSessionResponseToSubpoints = (flowSessionResponse) => {
		let results = flowSessionResponse.results.fields;
		let subpoints = [];

		results.forEach(field => {
			let subpoint = { key: field.fieldName.toLowerCase(), value: field.currentValue };
			subpoints.push(subpoint);
		});

		return subpoints;
	}

	handleStart = (debuggerFlowInputs, debugPathFileContents) => {
		this.startFlow(debuggerFlowInputs, debugPathFileContents);
	}

	handleNext = (e) => {
		e.preventDefault();
		const { elements } = this.props.flowSessionResponse.elementSet;
		const currentQuestions = elements.filter(element => element.elementType === 'Question');
		const allQuestions = this.state.questions;

		const responses = currentQuestions.map(currentQuestion => {
			const question = allQuestions.find(question => question.elementRef === currentQuestion.elementRef)

			if (!question) {
				LoggerUtils.logError('guidedResponseComponent.handleNext allQuestions', allQuestions);
			}

			if (question.inputType === "SelectionWithConditionalText") {
				if (question.answer === question.conditionalTextTriggerValue && question.conditionalTextInputRequired) {
					var response = {
						elementRef: question.elementRef,
						responseValue: question.altAnswer
					};
					return response;
				} else {
					return {
						elementRef: question.elementRef,
						responseValue: question.altAnswer !== '' ? question.altAnswer : question.answer
					}
				}

			}

			return {
				elementRef: question.elementRef,
				responseValue: question.answer
			}
		});

		let userResponses = {
			flowId: this.props.flowId,
			elementSetRef: this.props.flowSessionResponse && this.props.flowSessionResponse.elementSet?.elementSetRef,
			responses,
		};

		const validatedQuestions = validatePageGR(currentQuestions, allQuestions);
		this.setState({ questions: validatedQuestions });

		if (!validatedQuestions.find(question => !!question.error))
			this.continueFlow(this.props.flowSessionResponse.flowSessionId, userResponses);
	}

	handlePrevious = (e) => {
		e.preventDefault();

		this.previous(this.props.flowSessionResponse.flowSessionId);
	}

	handleComplete = () => {
		//if (this.props.debugMode) this.setState({ isLoading: true });

		let subpoints = this.mapFlowSessionResponseToSubpoints(this.props.flowSessionResponse);
		this.props.actions.guidedResponse.saveAnsweredQuestions(this.state.questions); //TODO: This method can go away once new Resume endpoint is implemented
		this.props.onCompleted(subpoints);
	}

	handleInputChange = (e) => {
		const questionIndex = this.state.questions.findIndex(question => question.elementRef === e.target.name);
		let value = e.target.value;
		if (!value && e.options) {
			value = e.options[e.selectedIndex].value;
		}

		const updatedQuestions = this.state.questions;

		if (!updatedQuestions[questionIndex]) {
			LoggerUtils.logError('guidedResponseComponent.handleInputChange questions', this.state.questions);
		}

		if (updatedQuestions[questionIndex].isMultiValue) {
			const answers = updatedQuestions[questionIndex].answer ? multiValue.split(updatedQuestions[questionIndex].answer) : [];
			const toggleAnswer = answers.find(answer => answer === value);
			updatedQuestions[questionIndex].answer = toggleAnswer ? multiValue.join(answers.filter(answer => answer !== value)) :
				multiValue.join([...answers, value]);
		} else if (updatedQuestions[questionIndex].inputType === "SelectionWithConditionalText") {

			//The answer is coming from the input control.
			if (e.target.className !== 'conditionalTextInput') {
				updatedQuestions[questionIndex].answer = value;
				updatedQuestions[questionIndex].altAnswer = '';
			} else {
				updatedQuestions[questionIndex].altAnswer = value;
			}
		}
		else
			updatedQuestions[questionIndex].answer = value;

		this.setState({ questions: updatedQuestions });
	}

	handleDropdownChange = (selectedOption) => {
		const questionIndex = this.state.questions.findIndex((question) => question.elementRef === selectedOption.elementRef);
		let value = selectedOption.value;

		const updatedQuestions = this.state.questions;

		if (!updatedQuestions[questionIndex]) {
			LoggerUtils.logError('guidedResponseComponent.handleDropdownChange questions', this.state.questions);
		}

		updatedQuestions[questionIndex].answer = value;

		this.setState({ questions: updatedQuestions });
	}

	handleInputFocus = (e) => {
		e.preventDefault();
		let questions = this.state.questions;

		const questionIndex = questions.findIndex(question => question.elementRef === e.target.name);

		if (!questions[questionIndex]) {
			LoggerUtils.logError('guidedResponseComponent.handleInputFocus questions', this.state.questions);
		}

		questions[questionIndex].isFocused = true;

		this.setState({ questions });
	}

	handleInputBlur = (e) => {
		e.preventDefault();
		let questions = this.state.questions;

		const questionIndex = questions.findIndex(question => question.elementRef === e.target.name);

		if (!questions[questionIndex]) {
			LoggerUtils.logError('guidedResponseComponent.handleInputBlur questions', this.state.questions);
		}

		questions[questionIndex].isFocused = false;

		this.setState({ questions });
	}

	handleGetDebugPathFile = (e) => {
		e.preventDefault();
		this.props.actions.guidedResponse
			.getFlowDebugPathFile(this.props.flowSessionResponse?.flowSessionId, this.props.token)
			.then((response) => {
				const blob = new Blob([JSON.stringify(response.payload.data)], { type: 'application/json;' });
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'FlowDebugPath.json';
				link.click();
				link.remove();
			});
	};

	render() {
		let { flowSessionResponse, debugMode, apiErrors } = this.props;
		let className = debugMode ? "view-content decision-support half" : "view-content decision-support";

		if (this.state.isLoading || (flowSessionResponse === null && apiErrors?.length === 0)) {
			return <NhLoadingIndicatorModal />
		} else {
			return <>
				{debugMode && <DebugErrors />}
				<div className='guided-response'>
					<GuidedResponseWorkflow
						className={className}
						debugMode={debugMode}
						flowSessionResponse={flowSessionResponse}
						questions={this.state.questions}
						isLoading={this.props.isLoading}
						isFlowAtStart={this.props.isFlowAtStart}
						isResumingFlow={this.state.isResumingFlow}
						chainedFromAp={this.props.chainGuidedResponseFlowFromAp}
						handlers={{
							onDropdownChange: this.handleDropdownChange,
							onChange: this.handleInputChange,
							inputBlur: this.handleInputBlur,
							inputFocus: this.handleInputFocus,
							start: this.handleStart,
							next: this.handleNext,
							previous: this.handlePrevious,
							complete: this.handleComplete,
							getFlowDebugPathFile: this.handleGetDebugPathFile
						}}
					/>
					{debugMode &&
						<DebugComponent
							debugMode={debugMode}
							flowSessionResponse={flowSessionResponse}
						/>}
				</div>
			</>
		}
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		isFlowAtStart: state.guidedResponse.flowSessionResponse ? state.guidedResponse.flowSessionResponse.isFlowAtStart : true,
		isFlowFinished: state.guidedResponse.flowSessionResponse ? state.guidedResponse.flowSessionResponse.isFlowFinished : false,
		flowSessionResponse: state.guidedResponse.flowSessionResponse,
		apiErrors: state.apiErrors.activeErrors,
		decisionSupportConfig: state.config.decisionSupport,
		debugMode: state.config.decisionSupport.enableFlowTesting || (state.config.decisionSupport.enableURLDebug && state.auth.debugParam),
		questions: state.guidedResponse.questions,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			guidedResponse: bindActionCreators(guidedResponseActions, dispatch),
		},
		dispatch
	};
}

const GuidedResponseComponentExport = withRouter(connect(mapStateToProps, mapDispatchToProps)(GuidedResponseComponent));
export default GuidedResponseComponentExport
