import React from 'react';
import PropTypes from 'prop-types';

import './loadingIndicator.css'

export default function LoadingIndicator(props) {
	return <div className="container">
		<div className="row">
			<div className="col-sm text-center">
				<div className="sk-circle">
					<div className="sk-circle1 sk-child"></div>
					<div className="sk-circle2 sk-child"></div>
					<div className="sk-circle3 sk-child"></div>
					<div className="sk-circle4 sk-child"></div>
					<div className="sk-circle5 sk-child"></div>
					<div className="sk-circle6 sk-child"></div>
					<div className="sk-circle7 sk-child"></div>
					<div className="sk-circle8 sk-child"></div>
					<div className="sk-circle9 sk-child"></div>
					<div className="sk-circle10 sk-child"></div>
					<div className="sk-circle11 sk-child"></div>
					<div className="sk-circle12 sk-child"></div>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-sm text-center">
				<label className="col-form-label">{props.loadingMessage && props.loadingMessage.trim() !== '' ?  props.loadingMessage : 'Loading. Please wait.'}</label>
			</div>
		</div>
	</div>;
}

LoadingIndicator.propTypes = {
	loadingMessage: PropTypes.string,
}
