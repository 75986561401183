export function convertStatusCode(code) {
	if (!code)
		return null

	switch (code.toLowerCase()) {
		case "o":
			return "Open";

		case "c":
			return "Closed";

		case "e":
			return "Expired";

		default:
			return "";
	}
}