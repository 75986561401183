/**
 * @format
 */

import * as React from 'react';
import { PrintButton } from '../common/printButton';
import RequestAppointmentDetails from './requestAppointmentDetails';

export class RequestAppointmentDetailsOverview extends React.Component {
	printOrder = () => {
		return <PrintButton componentRef={() => this.componentRef} />;
	};

	render() {
		const {
			bookAnotherAppointment,
			details,
			isGroupNumberActive,
			isMemberIdActive,
			memberIdFieldLabel,
			newSearch,
			providerFieldConfig,
		} = this.props;

		return (
			<RequestAppointmentDetails
				ref={(el) => (this.componentRef = el)}
				bookAnotherAppointment={bookAnotherAppointment}
				details={details}
				isGroupNumberActive={isGroupNumberActive}
				isMemberIdActive={isMemberIdActive}
				memberIdFieldLabel={memberIdFieldLabel}
				newSearch={newSearch}
				printOrder={this.printOrder}
				providerFieldConfig={providerFieldConfig}
			/>
		);
	}
}

export default RequestAppointmentDetailsOverview;
