/**
 * @format
 */

import * as React from 'react';
import moment from 'moment';
import { displayFullName, displayProviderNameWithProfessionalDesignation } from '../../lib/standardLibrary';
import { Row, Col } from 'reactstrap';
import { PhoneNumber } from '../common/phoneNumber';

export default function BookingDetails(props) {
	const { reservationDetails, config, providerFieldConfig } = props;
	const providerConfig = {
		fax: providerFieldConfig.fax,
		phone: providerFieldConfig.phone,
	};
	let showInsuranceOnBookAppointment = config.showInsuranceOnBookAppointment;
	let providerFullName = displayFullName(reservationDetails.providerFirstName, reservationDetails.providerLastName);
	let providerFullNameProfDesignation = displayProviderNameWithProfessionalDesignation(
		providerFullName,
		reservationDetails.providerProfessionalDesignation,
	);
	let showPreBookInformationForPatient =
		reservationDetails.preBookInformationForPatient && reservationDetails.preBookInformationForPatient !== '';

	let showArrivalTime = Boolean(
		reservationDetails.expectedArrivalTime &&
			reservationDetails.expectedArrivalTime !== reservationDetails.providerAppointmentDateTime,
	);

	return (
		<Col style={{ margin: '15px 15px 15px 0px' }}>
			<Row className="mb-2">
				<Col lg="4" className="mb-2">
					<div>
						<p className="fw-bold form-label">WHEN</p>
						{reservationDetails.providerAppointmentDateTime ? (
							<div> {moment(reservationDetails.providerAppointmentDateTime).format('dddd, MM/DD/YYYY')}</div>
						) : (
							<div>-</div>
						)}
						{reservationDetails.patientAppointmentDateTime ? (
							<div>
								{' '}
								{moment(reservationDetails.patientAppointmentDateTime).format('h:mm a')}
								<span style={{ marginLeft: '5px', marginRight: '5px' }}>
									{reservationDetails.patientAppointmentTimeZone}
								</span>
								(patient time)
							</div>
						) : (
							<div>-</div>
						)}
						{reservationDetails.providerAppointmentDateTime ? (
							<div>
								{' '}
								{moment(reservationDetails.providerAppointmentDateTime).format('h:mm a')}
								<span style={{ marginLeft: '5px', marginRight: '5px' }}>
									{reservationDetails.providerAppointmentTimeZone}
								</span>
								(provider time)
							</div>
						) : (
							<div>-</div>
						)}
						{showArrivalTime && (
							<div>
								{' '}
								{moment(reservationDetails.expectedArrivalTime).format('h:mm a')}
								<span style={{ marginLeft: '5px', marginRight: '5px' }}>
									{reservationDetails.providerAppointmentTimeZone}
								</span>
								(arrival time)
							</div>
						)}
					</div>
				</Col>
				<Col lg="4" className="mb-2">
					<div>
						<p className="fw-bold form-label">WHERE</p>
						<div>
							{providerFullNameProfDesignation && providerFullNameProfDesignation.trim() !== ''
								? providerFullNameProfDesignation
								: '-'}
						</div>
						<div>
							{reservationDetails.providerSpecialty && reservationDetails.providerSpecialty.trim() !== ''
								? reservationDetails.providerSpecialty
								: '-'}
						</div>
						<div style={{ marginBottom: '10px' }}>
							{reservationDetails.providerSiteName && reservationDetails.providerSiteName.trim() !== ''
								? reservationDetails.providerSiteName
								: '-'}
						</div>

						{!reservationDetails.suppressPhoneNumber && providerConfig.phone && providerConfig.phone.isVisible && (
							<PhoneNumber
								label={providerConfig.phone.fieldLabel ? providerConfig.phone.fieldLabel : '(O)'}
								value={reservationDetails.providerPhoneNumber?.trim()}
							/>
						)}
						{providerConfig.fax && providerConfig.fax.isVisible && (
							<PhoneNumber
								label={providerConfig.fax.fieldLabel ? providerConfig.fax.fieldLabel : '(F)'}
								value={reservationDetails.providerFaxNumber?.trim()}
							/>
						)}
						{!reservationDetails.suppressAddress && reservationDetails.providerAddressLine1 ? (
							<div>
								<div style={{ margin: '0px' }}>{reservationDetails.providerAddressLine1}</div>
								{reservationDetails.providerAddressLine2 && reservationDetails.providerAddressLine2.trim() !== '' ? (
									<div>{reservationDetails.providerAddressLine2}</div>
								) : (
									''
								)}
								<div>
									{reservationDetails.providerCity}, {reservationDetails.providerState} {reservationDetails.providerZip}
								</div>
							</div>
						) : (
							'-'
						)}
					</div>
				</Col>
				<Col lg="4" className="mb-2">
					{showPreBookInformationForPatient ? (
						<div>
							<p className="fw-bold form-label">ADDITIONAL BOOKING INFORMATION</p>
							<div
								dangerouslySetInnerHTML={{
									__html: reservationDetails.preBookInformationForPatient,
								}}
							/>
						</div>
					) : (
						<div></div>
					)}
				</Col>
			</Row>
			<Row className="mb-2">
				<Col lg="4" className="mb-2">
					<div>
						<p className="fw-bold form-label">TYPE</p>
						<div>
							{reservationDetails.appointmentTypeName && reservationDetails.appointmentTypeName.trim() !== ''
								? reservationDetails.appointmentTypeName
								: '-'}
						</div>
					</div>
				</Col>
				<Col lg="4" className="mb-2">
					{showInsuranceOnBookAppointment ? (
						<div>
							<p className="fw-bold form-label">INSURANCE</p>
							<div>
								{reservationDetails.payorType && reservationDetails.payorType.trim() !== ''
									? reservationDetails.payorType
									: '-'}
							</div>
							<div>
								{reservationDetails.insuranceCarrierName && reservationDetails.insuranceCarrierName.trim() !== ''
									? reservationDetails.insuranceCarrierName
									: '-'}
							</div>
						</div>
					) : (
						<div></div>
					)}
				</Col>
			</Row>
		</Col>
	);
}
