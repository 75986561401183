import { ApiResponse, UserResetPasswordDetails, VerifyUserTokenResult, VerifyUserTokenInfo, VoidApiState } from 'types';
import { cancelAllRequests, createApiEndpoint, ApiMethod, createVoidApiEndpoint } from './baseApi';

enum ApiRoute {
	PasswordUserReset = 'internal/user/{id}/resetpassword',
	VerifyToken = 'internal/user/verifyToken',
}

const resetUserPassword = async (
	userId: string,
	info: UserResetPasswordDetails,
	setState: (result: VoidApiState) => void,
): Promise<boolean> => {
	const url = `${ApiRoute.PasswordUserReset}`.replace('{id}', userId);
	const api = createVoidApiEndpoint<UserResetPasswordDetails>(url, ApiMethod.PUT);
	return await api(setState, info);
};

const verifyToken = async (
	data: VerifyUserTokenInfo,
	setState: (result: ApiResponse<VerifyUserTokenResult>) => void,
): Promise<void> => {
	const url = `${ApiRoute.VerifyToken}?token=${data.token}&userid=${data.userId}`;
	const api = createApiEndpoint<string>(url, ApiMethod.GET);
	await api(setState);
};

const cleanup = () => {
	cancelAllRequests();
};

export const internalService = {
	cleanup,
	resetUserPassword,
	verifyToken,
};

export default internalService;
