import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import '../css/availabilityWeekStrip.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeViewDateThunk,
    selectActiveDisplayedDates,
    selectAllowNextViewBaseDate,
    selectAllowPreviousViewBaseDate,
    selectIsSearchingAnything,
    selectNextViewBaseDate,
    selectPreviousViewBaseDate,
} from '../availabilitySlice';

export default function DateNav() {
    const allowNextViewBaseDate = useSelector(selectAllowNextViewBaseDate);
    const allowPreviousViewBaseDate = useSelector(selectAllowPreviousViewBaseDate);

    return (
        <Container>
            {allowPreviousViewBaseDate && <PreviousButton />}
            <DisplayDates />
            {allowNextViewBaseDate && <NextButton />}
        </Container>
    )
}

const Container = ({ children }) => {
    return (
        <Col className="sticky-container">
            <Row className="availability-week-row">
                <Col md="3" className="spacer">
                    &nbsp;
                </Col>
                <Col md="9" className="week-container">
                    <Row>
                        {children}
                    </Row>
                </Col>
            </Row>
            <Row className="spacer">
                <Col className="week-spanner-underline-col" md="3">
                    &nbsp;
                </Col>
                <Col className="week-spanner-underline-col" md="9">
                    <hr className="week-spanner-underline" />
                </Col>
            </Row>
        </Col>
    )
}

const DisplayDates = () => {
    const displayedDates = useSelector(selectActiveDisplayedDates);

    return (
        displayedDates.map((day, i) => (
            <Col className={`container day-column u-desktop-only`} key={i}>
                <Row>
                    <Col className="week-spanner-day">{dayjs(day).format('ddd')}</Col>
                </Row>
                <Row>
                    <Col className="week-spanner-date">{dayjs(day).format('M/D/YY')}</Col>
                </Row>
            </Col>
        ))
    )
}

const NextButton = () => {
    const dispatch = useDispatch();

    const nextViewBaseDate = useSelector(selectNextViewBaseDate);
    const isSearchingAnything = useSelector(selectIsSearchingAnything);

    return (
        < Col sm="auto" >
            <Row>
                <Col
                    className={
                        !isSearchingAnything
                            ? 'week-spanner-arrow shift-forward'
                            : 'week-spanner-arrow-disabled shift-forward'
                    }
                    style={{ paddingRight: '0px' }}
                    onClick={
                        !isSearchingAnything
                            ? () => dispatch(changeViewDateThunk(nextViewBaseDate))
                            : null
                    }
                >
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        size="2x"
                        className={!isSearchingAnything ? 'shift-week-caret' : 'shift-week-caret-disabled'}
                    />
                </Col>
            </Row>
        </Col >
    )
}

const PreviousButton = () => {
    const dispatch = useDispatch();

    const nextViewBaseDate = useSelector(selectPreviousViewBaseDate);
    const isSearchingAnything = useSelector(selectIsSearchingAnything);

    return (
        <Col sm="auto">
            <Row>
                <Col
                    className={
                        !isSearchingAnything
                            ? 'week-spanner-arrow shift-backward'
                            : 'week-spanner-arrow-disabled shift-backward'
                    }
                    style={{ paddingLeft: '0px' }}
                    onClick={
                        !isSearchingAnything
                            ? () => dispatch(changeViewDateThunk(nextViewBaseDate))
                            : null
                    }
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        size="2x"
                        className={!isSearchingAnything ? 'shift-week-caret' : 'shift-week-caret-disabled'}
                    />
                </Col>
            </Row >
        </Col >
    )
}

