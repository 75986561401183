import { Alert } from "reactstrap";

const LoginError = (props: { error: string, isLoading: boolean }) => {
    const showError = props.error && !props.isLoading;
    return (
        <>
            {showError && <Alert color="danger">{props.error}</Alert>}
        </>
    )
}

export default LoginError;