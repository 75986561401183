//Third Party Dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import qs from 'qs';

//MHD General Purpose libraries
import ActiveErrors from '../../components/common/activeErrors';
import { SectionHeading } from '../../components/common/standardComponents';
import LoadingIndicator from '../../components/loadingIndicator';

//Specific Business and UI components
import * as routes from '../../routes';
import { clearActivePatient, lookupPatient, getPatientSchedulableOrders } from '../../actions/rd2RefactorActions';

export class StartCreateCareOrderRedirector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alertMessage: "",
			showLoadingIndicator: true,
			showAlert: false,
		};
	}

	dismissAlert = () => {
		this.setState({ showAlert: false });
	}

	componentDidMount() {
		var params = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
		if (params.pid && params.pidtype) {
            let cleanPid = params.pid.trim();
            let cleanPidType = params.pidtype.trim();
            this.props.dispatch(clearActivePatient());
            this.loadPatientData(cleanPid, cleanPidType);
		}
		else {
            this.setState({
                showAlert: true,
                showLoadingIndicator: false,
                alertMessage: "Please provide a patient identitfier and patient identifier type."
            });
		}
    }

    loadPatientData(pid, pidType) {
		let promiseArray = [];
		promiseArray.push(this.props.dispatch(lookupPatient(pid, pidType)));

		if (this.props.config.orderManagement.usesExternalOrders) {
			promiseArray.push(this.props.dispatch(getPatientSchedulableOrders(pid, pidType)));
		}

        Promise.all(promiseArray)
        .then((response) => {
            if (response.error)
            {
                this.setState({ showLoadingIndicator: false });
            }
            else {
                this.props.dispatch(routes.createCareOrder());
            }
        });
    }

	render() {
		let { alertMessage, showLoadingIndicator, showAlert } = this.state;
		return (
			<div>
				<ActiveErrors />
				<SectionHeading label="Create Followup Visit Order" />
				<Alert className="text-center" color="warning" isOpen={showAlert} toggle={this.dismissAlert}>
					{alertMessage}
				</Alert>
				{showLoadingIndicator &&
					<LoadingIndicator loadingMessage="Fetching Patient Data..." />
				}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		activePatient: state.activePatient,
		auth: state.auth,
		config: state.config,
		token: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StartCreateCareOrderRedirector);
