import * as types from '../actions/actionTypes';
import isDefined from '../lib/isDefined';
import { CLOSE_PRODUCT_ALERT_BANNER } from '../constants/productAlertConfigValues';

const initialState = {
	config: {},
	isActive: true,
	wasAlertBannerClosed: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.GET_PRODUCT_ALERT_CONFIG_SUCCESS:
			let isActive = false;
			let wasAlertBannerClosed = false;
			if (!state.wasAlertBannerClosed) {
				const nowDateTime = new Date();
				const hasEffectiveEndAt =
					action.payload.data?.effectiveEndAt &&
					isDefined(action.payload.data?.effectiveEndAt) &&
					isNaN(Date.parse(action.payload.data?.effectiveEndAt)) === false;
				const alertEndDate = hasEffectiveEndAt ? new Date(action.payload.data?.effectiveEndAt) : nowDateTime;
				if (alertEndDate > nowDateTime) {
					isActive = true;
				};
			} else {
				if (state.config.lastModifiedAt !== action.payload.data.lastModifiedAt && action.payload.data.isEnabled) {
					isActive = true;
				} else {
					wasAlertBannerClosed = true;
				}
			}

			return {
				...state,
				config: action.payload.data,
				isActive: isActive,
				wasAlertBannerClosed: wasAlertBannerClosed
			};
		case CLOSE_PRODUCT_ALERT_BANNER:
			return {
				...state,
				isActive: action.payload.data.isActive,
				wasAlertBannerClosed: action.payload.data.wasAlertBannerClosed
			};
		default:
			return state;
	}
}
