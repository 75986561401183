import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, InputGroupText, Input, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PasswordInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'password'
		}
		this.showHide = this.showHide.bind(this);
	}

	showHide(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			type: this.state.type === 'input' ? 'password' : 'input'
		})
	}

	handleChange = ({ target: { name, value } }) => {
		const { validate, onChange } = this.props
		const error = validate(value)
		return onChange({ target: { name, value } }, error)
	}

	render() {
		const { id, name, value, placeholder, onChange, validate, ...rest } = this.props

		return (
			<FormGroup>
				<InputGroup>
					<InputGroupText
						style={{
							border: "1px solid #ced4da",
							borderRadius: ".25rem",
							borderRight: "none",
							marginRight: "-4px",
							padding: '0px',
							backgroundColor: 'transparent'
						}}
					>
						<span
							color="default"
							style={{
								cursor: "inherit",
								margin: "auto"
							}}
							className="btn btn-default btn-sm bg-transparent"
						>
							<FontAwesomeIcon icon="key" />
						</span>
					</InputGroupText>
					<Input
						type={this.state.type}
						ref={input => this.passwordInput = input}
						id={id}
						name={name}
						placeholder={placeholder}
						autoComplete="off"
						value={value}
						style={{
							...this.props.inputStyles
						}}
						className="py-2 border-left-0 border-right-0 border"
						onChange={this.handleChange}
						{...rest}
					/>
					<InputGroupText
						style={{
							border: "1px solid #ced4da",
							borderRadius: ".25rem",
							borderLeft: "none",
							marginLeft: "-4px",
							padding: '0px',
							backgroundColor: 'transparent'
						}}
					>
						<Button color="default"
							className="bg-transparent btn-sm"
							onClick={this.showHide}>
							{this.state.type === 'input' ?
								<FontAwesomeIcon icon="eye" /> :
								<FontAwesomeIcon icon="eye-slash" />
							}
						</Button>
					</InputGroupText>
				</InputGroup>
			</FormGroup>
		)
	}
}

PasswordInput.propTypes = {
	id: PropTypes.string,
	inputStyles: PropTypes.any,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	validate: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.string])
}

PasswordInput.defaultProps = {
	validate: value => !(value && value.length)
}

export default PasswordInput
