import React, { Component } from 'react';
import AppliedPathwaysErrorItem from './appliedPathwaysErrorItem';

class AppliedPathwaysErrorList extends Component {
	render() {
		return (
			<div>
				{this.props.list.map((item, key) => 
					<AppliedPathwaysErrorItem key={key} name={item.name} errorMessage={item.error_message} />
				)}
			</div>
		);
	}
}

export default AppliedPathwaysErrorList;
