
export function getConfig() {
	const config = {
		//this section will be moved elsewhere in the DB at a later time. Then, this entire file can be deleted.
		userInfo: {
			secretQuestion: {
				questionLength: 5,
				answerLength: 5,
			},
		},
	};

	return Object.freeze(config);
}