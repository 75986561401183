import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function GuidedResponseNextButton(props) {
	const { isLoading, handler } = props
	const [isClicked, setIsClicked] = useState(false);

	const clicked = (e) => {
		setIsClicked(true);
		handler(e);
	};

	return (
		<button className="btn btn-primary" type="button" disabled={isLoading} onClick={clicked}>
			{isClicked && isLoading ? <span><FontAwesomeIcon icon="spinner" spin /> Processing...</span> : <span>Next</span>}
		</button>
	);
}