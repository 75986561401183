import * as types from '../actions/actionTypes';
import { APPOINTMENT_CRITERIA } from '../constants/appointmentCriteria';
import { AVAILABILITY_SEARCH_CRITERIA } from '../constants/availabilitySearchCriteria';

const initialState = {
	activeCareOrderDetails: {},
	appointmentCriteria: {},
	availabilitySearchCriteria: {},
	careOrderVisitIdentifier: null,
	customFields: [],
	externalReferralOrderId: null,
	isCareOrderBookingContext: false,
};

//TODO - Consolidate all care order reducer logic into this reducer in future story
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.SET_ACTIVE_CAREORDERVISIT_DETAILS: {
			let appt = {};
			let asc = {};
			let customFields = [];

			const activeCareOrderDetails = action.data.careOrderWorkflowDetails;
			let hasAppointment = activeCareOrderDetails.appointments && activeCareOrderDetails.appointments.length === 1;
			let appointment = hasAppointment ? activeCareOrderDetails.appointments[0] : null;

			if (hasAppointment) {
				if (appointment.appointmentTypeId) {
					asc[AVAILABILITY_SEARCH_CRITERIA.appointmentTypeIdList] = [appointment.appointmentTypeId];
				}

				if (appointment.serviceTypeId) {
					asc[AVAILABILITY_SEARCH_CRITERIA.serviceTypeId] = appointment.serviceTypeId;
				}
				if (appointment.serviceId) {
					asc[AVAILABILITY_SEARCH_CRITERIA.serviceIdList] = [appointment.serviceId];
				}
				if (appointment.specialtyId) {
					asc[AVAILABILITY_SEARCH_CRITERIA.specialtyId] = appointment.specialtyId;
				}
				if (appointment.siteId) {
					asc[AVAILABILITY_SEARCH_CRITERIA.siteIdList] = [appointment.siteId];
				}

				if (appointment.reasonForVisit) {
					appt[APPOINTMENT_CRITERIA.reasonForVisit] = appointment.reasonForVisit;
				}

				if (appointment.careOrderCustomField) {
					customFields = appointment.careOrderCustomField;
				}
			}

			if (activeCareOrderDetails.maxVisitDate) {
				asc[AVAILABILITY_SEARCH_CRITERIA.endDate] = activeCareOrderDetails.maxVisitDate;
			}
			if (activeCareOrderDetails.minVisitDate) {
				asc[AVAILABILITY_SEARCH_CRITERIA.startDate] = activeCareOrderDetails.minVisitDate;
			}
			if (activeCareOrderDetails.patientZip) {
				asc[AVAILABILITY_SEARCH_CRITERIA.zipCode] = activeCareOrderDetails.patientZip;
			}

			return {
				...state,
				activeCareOrderDetails: action.data.careOrderWorkflowDetails,
				appointmentCriteria: appt,
				availabilitySearchCriteria: asc,
				careOrderVisitIdentifier: activeCareOrderDetails.careOrderVisitIdentifier,
				customFields: customFields,
				externalReferralOrderId: hasAppointment ? appointment.externalReferralOrderId : null,
				isCareOrderBookingContext: true,
			};
		}

		case types.CLEAR_BOOKING_CONTEXT: {
			return initialState;
		}

		default: {
			return state;
		}
	}
}
