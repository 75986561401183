//Third Party Dependencies
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row } from 'reactstrap';

//MHD General Purpose libraries
import { validateDateOfBirth, validateEmail, validatePhoneNumber, validateZipCode } from '../../lib/validation';
import { resolveGenderOptions } from '../../lib/gender';
import ErrorBoundary from '../../components/common/errorBoundary';
import ActiveErrors from '../../components/common/activeErrors';
import { SectionHeading } from '../../components/common/standardComponents';

//Specific Business and UI components
import * as routes from '../../routes';
import { loadPatientSavePageData } from '../../actions/patientActions';
import { setActivePatient, updatePatient, searchAddress, getAddress } from '../../actions/rd2RefactorActions';
import PatientEditForm from '../../components/patient/patientEditForm';

export class PatientEditView extends React.Component {
	constructor(props) {
		super(props);
		const params = this.props.match.params;
		this.state = {
			isPatientLoaded: false,
			isSaving: false,
			patientDetails: { ...props.activePatient.details },
			referenceId: params.referenceId,
			forceValidation: false,
			touchedInputs: {},
			phoneForceInvalid: false,
			phoneForceInvalidMessage: "",
			zipCodeForceInvalid: false,
			selectedAddressOption: { label: 'Select...', value: {} },
			isAddressLoading: false,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (!prevState.isPatientLoaded && nextProps.activePatient.details.firstName !== undefined) {
			return {
				isPatientLoaded: true,
				patientDetails: nextProps.activePatient.details,
			};
		}
		return null;
	}

	componentDidMount() {
		if (!this.props.stateList.length) this.props.dispatch(loadPatientSavePageData());
		// should we allow using "stale" cached patient data?
		const isPatientAlreadyActive = (this.props.activePatient.id === this.state.referenceId);
		if (isPatientAlreadyActive) {
			const isPatientAlreadyLoaded = isPatientAlreadyActive && (this.props.activePatient.details.firstName !== undefined);
			if (isPatientAlreadyLoaded) {
				this.setState({ isPatientLoaded: isPatientAlreadyLoaded });
			}
			// if already active, but not loaded, we assume our "caller" has already dispatched the action that triggers the load
			//else {
			//	this.props.dispatch(setActivePatient(patientId));
			//}
		}
		else {
			this.props.dispatch(setActivePatient(this.state.referenceId))
		}
	}

	handleCancel = (e) => {
		//TODO: dispatch an action for this?
		if (this.props.history.length > 0)
			this.props.history.goBack();
		else
			this.push(routes.patientSearchUrl);
	}

	handlePatientChange = (e) => {
		let target = e.target;
		let patientDetails = { ...this.state.patientDetails }
		patientDetails[target.name] = target.value;
		let touchedInputs = { ...this.state.touchedInputs }
		touchedInputs[target.name] = true;
		this.setState({ patientDetails, touchedInputs }, () => {
			if (target.name === 'homePhone' || target.name === 'mobilePhone') {
				this.updatePhoneForceInvalid();
			}
			if (target.name === 'zipCode') {
				this.setState({ zipCodeForceInvalid: false });
			}
		});
	}

	handleSave = (e) => {
		e.preventDefault();

		this.setState({ forceValidation: true });
		let sanitizedPatientDetails = this.beforeSave();

		this.updatePhoneForceInvalid();

		if (this.validate()) {
			this.setState({ isSaving: true });
			this.props.dispatch(updatePatient(sanitizedPatientDetails))
				.then((result) => {
					if (result.error) {
						this.setState({ isSaving: false });
					}
					else if (result.payload.data.errors && result.payload.data.errors.length > 0) {
							this.resolveSaveErrors(result.payload.data.errors);
					}
					else {
							return this.props.dispatch(setActivePatient(this.state.patientDetails.referenceId));
					}
				})
				.then((result) => {
					if (result) {
						return this.props.dispatch(routes.patientDetails(this.state.patientDetails.referenceId));
					}
				});
		}
	}

	resolveSaveErrors = (errors) => {
		let zipCodeForceInvalid = false;
		if (errors && errors.length > 0) {
			zipCodeForceInvalid = errors.includes('zipcode');
		}
		this.setState({ zipCodeForceInvalid, isSaving: false });
	}

	beforeSave = () => {
		let patientDetails = {...this.state.patientDetails};
		patientDetails.memberId = patientDetails.memberId != null ? patientDetails.memberId.trim() : null;
		patientDetails.groupNumber = patientDetails.groupNumber != null ? patientDetails.groupNumber.trim() : null;
		patientDetails.firstName = patientDetails.firstName != null ? patientDetails.firstName.trim() : null;
		patientDetails.middleName = patientDetails.middleName != null ? patientDetails.middleName.trim() : null;
		patientDetails.lastName = patientDetails.lastName != null ?  patientDetails.lastName.trim() : null;
		patientDetails.dateOfBirth = patientDetails.dateOfBirth != null ? moment.utc(patientDetails.dateOfBirth.trim()).format('MM/DD/YYYY').toString() : null;
		patientDetails.gender = patientDetails.gender != null ? patientDetails.gender.trim() : null;
		patientDetails.homePhone = patientDetails.homePhone != null ? patientDetails.homePhone.trim() : null;
		patientDetails.mobilePhone = patientDetails.mobilePhone != null ? patientDetails.mobilePhone.trim() : null;
		patientDetails.alternatePhone = patientDetails.alternatePhone != null ? patientDetails.alternatePhone.trim() : null;
		patientDetails.address1Valid = patientDetails.address1Valid != null ? patientDetails.address1Valid.trim() : null;
		patientDetails.address2Valid = patientDetails.address2Valid != null ? patientDetails.address2Valid.trim() : null;
		patientDetails.cityName = patientDetails.cityName != null ? patientDetails.cityName.trim() : null;
		patientDetails.zipCode = patientDetails.zipCode != null ? patientDetails.zipCode.trim() : null;
		patientDetails.email = patientDetails.email != null ? patientDetails.email.trim() : null;
		patientDetails.notes = patientDetails.notes != null ? patientDetails.notes.trim() : null;

		return patientDetails;
	}

	shouldValidate = (name) => {
		return (this.state.forceValidation || this.state.touchedInputs[name] === true);
	}

	updatePhoneForceInvalid = () => {
		let patientConfig = this.props.config.patient;
		let bothPhonesEmpty = (!patientConfig.homePhone.isRequired && !patientConfig.mobilePhone.isRequired && !this.state.patientDetails.homePhone && !this.state.patientDetails.mobilePhone);
		this.setState({ phoneForceInvalid: bothPhonesEmpty });
	}

	validate = () => {
		let patientConfig = this.props.config.patient;

		let memberIdValid = (patientConfig.memberId.isRequired ? (this.state.patientDetails.memberId && this.state.patientDetails.memberId !== '') : true);
		let groupNumberValid = (patientConfig.groupNumber.isRequired ? (this.state.patientDetails.groupNumber && this.state.patientDetails.groupNumber !== '') : true);
		let firstNameValid = (patientConfig.firstName.isRequired ? (this.state.patientDetails.firstName && this.state.patientDetails.firstName !== '') : true);
		let middleNameValid = (patientConfig.middleName.isRequired ? (this.state.patientDetails.middleName && this.state.patientDetails.middleName !== '') : true);
		let lastNameValid = (patientConfig.lastName.isRequired ? (this.state.patientDetails.lastName && this.state.patientDetails.lastName !== '') : true);
		let dobValid = patientConfig.dateOfBirth.isRequired ? validateDateOfBirth(this.state.patientDetails.dateOfBirth) : true;
		let genderValid = patientConfig.gender.isRequired ? this.validateGender() : true;
		let addressValid = this.validateAddress();
		let phonesValid = this.validatePhones();
		let alternatePhoneValid = this.validateAlternatePhone();
		let emailValid = patientConfig.email.isRequired ? validateEmail(this.state.patientDetails.email) : (this.state.patientDetails.email && this.state.patientDetails.email.trim() !== '') ? validateEmail(this.state.patientDetails.email) : true;
		let notesValid = (patientConfig.notes.isRequired ? (this.state.patientDetails.notes && this.state.patientDetails.notes !== '') : true);
		return (memberIdValid && groupNumberValid && firstNameValid && middleNameValid && lastNameValid && dobValid && genderValid && addressValid && phonesValid && alternatePhoneValid && emailValid && notesValid);
	}

	validateGender = () =>  {
		let result = (this.state.patientDetails.gender.toLowerCase() === 'm' || this.state.patientDetails.gender.toLowerCase() === 'f' || this.state.patientDetails.gender.toLowerCase() === 'u');
		return result
	}

	validateAddress = () => {
		let patientConfig = this.props.config.patient;
		let address1Valid = (patientConfig.addressLine1.isRequired ? this.state.patientDetails.addressLine1 !== '' : true);
		let address2Valid = (patientConfig.addressLine2.isRequired ? (this.state.patientDetails.addressLine2 !== '' && this.state.patientDetails.addressLine2 !== null) : true);
		let cityValid = (patientConfig.city.isRequired ? this.state.patientDetails.cityName !== '' : true);
		let stateValid = patientConfig.state.isRequired ? (this.state.patientDetails.stateId > 0) : true;
		let zipValid = patientConfig.zipCode.isRequired && !this.state.patientDetails.zipCode ? false : !this.state.zipCodeForceInvalid && validateZipCode(this.state.patientDetails.zipCode);

		return address1Valid && address2Valid && cityValid && stateValid && zipValid;
	}

	validateAlternatePhone = () => {
		let patientConfig = this.props.config.patient;
		let isValid = true;

		if (patientConfig.alternatePhone.isRequired || !!this.state.patientDetails.alternatePhone) {
			isValid = validatePhoneNumber(this.state.patientDetails.alternatePhone);
		}

		return isValid;
	}

	validatePhones = () => {
		let patientConfig = this.props.config.patient;
		let isValid = false;

		if (patientConfig.homePhone.isRequired) {
			isValid = validatePhoneNumber(this.state.patientDetails.homePhone);
		} else if (patientConfig.mobilePhone.isRequired) {
			isValid = validatePhoneNumber(this.state.patientDetails.mobilePhone);
		} else {
			let bothPhonesEmpty = !this.state.patientDetails.homePhone && !this.state.patientDetails.mobilePhone;
			let isHomePhoneValid = !this.state.patientDetails.homePhone || validatePhoneNumber(this.state.patientDetails.homePhone);
			let isMobilePhoneValid = !this.state.patientDetails.mobilePhone || validatePhoneNumber(this.state.patientDetails.mobilePhone);
			this.setState({phoneForceInvalidMessage : bothPhonesEmpty ? "Please provide a valid Home or Mobile Phone" : ""})

			isValid = !bothPhonesEmpty && isHomePhoneValid && isMobilePhoneValid;
		}

		return isValid;
	}

	handleAddressChange = (possibleOption) => {
		if (possibleOption.value.address) {
			this.setState({ isAddressLoading: true, selectedAddressOption: possibleOption })
			const addressSearchCriteria = {
				query: possibleOption.value.address,
			};
			this.props.dispatch(getAddress(addressSearchCriteria))
				.then(response => {
					const addressSearchResult = response.payload.data;
					const newPatientDetails = this.createPatientDetailsForAddress(addressSearchResult);
					this.setState({
						selectedAddressOption: possibleOption,
						isAddressLoading: false,
						patientDetails: newPatientDetails
					});
				});
		}
		else {
			this.setState({
				selectedAddressOption: { label: 'Select...', value: {} },
			});
		}
	}

	createPatientDetailsForAddress = (addressSearchResult) => {
		const {
			addressLine1,
			addressLine2,
			city,
			state: stateCode,
			zipCode
		} = addressSearchResult;
		const state = this.props.stateList.find((x) => x.name === stateCode);
		const newPatientDetails = {
			...this.state.patientDetails,
			addressLine1: addressLine1,
			addressLine2: addressLine2,
			cityName: city,
			stateId: state ? state.id : 0,
			zipCode: zipCode.substring(0, 5),
		};

		return newPatientDetails;
	}

	loadAddressOptions = async (inputValue, callback) => {
		this.setState({ addressInputValue: inputValue });
		if (inputValue < 5) {
			callback([]);
			return;
		}
		const result = await this.props.dispatch(searchAddress({ query: this.state.addressInputValue }));
		if (result.error) {
			console.error(result.error, this.state.addressInputValue);
			callback([]);
			return;
		}
		const addressSearchResults = result.payload.data;
		const addressOptions = addressSearchResults.map(a => ({ label: a.address, value: a }));
		callback(addressOptions);
	}

	render() {
		let genderOptions = resolveGenderOptions(this.props.config.patient.allowUnknownGender, this.props.config.patient.unknownGenderOptionText);

		return (
			<div>
				{this.state.isPatientLoaded &&
					<ErrorBoundary childName="Edit Patient">
						<ActiveErrors />
						<SectionHeading label="Edit Patient" />
						<Row noGutters>
							<div className="col-lg-12">
								<PatientEditForm
									config={this.props.config}
									genderOptions={genderOptions}
									isSaving={this.state.isSaving}
									lookupTables={this.props.lookupTables}
									onCancel={this.handleCancel}
									onPatientChange={this.handlePatientChange}
									onSave={this.handleSave}
									patientDetails={this.state.patientDetails}
									phoneForceInvalid={this.state.phoneForceInvalid}
									phoneForceInvalidMessage={this.state.phoneForceInvalidMessage}
									shouldValidate={this.shouldValidate}
									stateList={this.props.stateList}
									zipCodeForceInvalid={this.state.zipCodeForceInvalid}
									handleAddressChange={this.handleAddressChange}
									loadAddressOptions={this.loadAddressOptions}
									isAddressLoading={this.state.isAddressLoading}
									selectedAddressOption={this.state.selectedAddressOption}
								/>
							</div>
						</Row>
					</ErrorBoundary>
				}
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activePatient: state.activePatient,
		config: state.config,
		lookupTables: state.lookupTables,
		stateList: state.page.stateList
	};
}

export default connect(mapStateToProps)(PatientEditView);
