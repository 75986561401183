import * as types from '../actions/actionTypes';

const initialState = {
	flowSessionResponse: null,
	debugMode: false, //TODO: allow debugMode to be changed via Product Instance Settings Page
	questions: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.GUIDED_RESPONSE_START_FLOW:
			return {
				...state,
				flowSessionResponse: null
			}
		case types.GUIDED_RESPONSE_START_FLOW_SUCCESS:
		case types.GUIDED_RESPONSE_CONTINUE_FLOW_SUCCESS:
		case types.GUIDED_RESPONSE_PREVIOUS_SUCCESS:
			return {
				...state,
				flowSessionResponse: action.payload.data,
			};
		case types.GUIDED_RESPONSE_SAVE_FINISHED_QUESTIONS: //TODO: This action type will be deprecated once new Resume endpoint for GR is implemented
			return {
				...state,
				questions: action.data,
			};
		case types.APPOINTMENT_DETAILS_SUCCESS:
		case types.CLEAR_BOOKING_CONTEXT:
			return initialState;
		default:
			return state;
	}
}
