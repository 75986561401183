import { useDispatch, useSelector } from 'react-redux';
import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import dayjs from 'dayjs';
import PatientDetails from './comps/patientDetails'
import PatientCareOrderHistory from './comps/patientCareOrderHistory';
import PatientHistorySummary from './comps/patientHistorySummary';
import { useEffect, useState } from 'react';
import { onPatientDetailsViewMount } from 'actions/rd2RefactorActions';
import useLoadPatientDetails from './hooks/useLoadPatientDetails';
import useLoadPatientCareOrderHistory from './hooks/useLoadPatientCareOrderHistory';
import PatientAppointmentHistory from './comps/patientAppointmentHistory';
import PatientUpcomingAppointments from './comps/patientUpcomingAppointments';
import useLoadPatientAppointmentHistory from './hooks/useLoadPatientAppointmentHistory';
import ActiveErrors from 'components/common/activeErrors';
import LoadingIndicator from 'components/loadingIndicator';

import './css/patientDetailsView.css';
import useLoadPatientOpenWorkflows from './hooks/useLoadPatientOpenWorkflows';
import PatientOpenWorkflows from './comps/patientOpenWorkflows';

export default function PatientDetailsView(props: any) {
    const dispatch = useDispatch();

    const patient = useSelector((state: any) => state.activePatient);
    const patientConfig = useSelector((state: any) => state.config.patient);
    const schedulingConfig = useSelector((state: any) => state.config.scheduling)
    const closeCareOrderEnabled = useSelector((state: any) => state.config.orderManagement.closeCareOrderEnabled);
    const isPatientOpenWorkflowsLoading = useSelector((state: any) => state.activePatient.isLoadingOpenWorkflows);

    const [isPatientLoading, setIsPatientLoading] = useState(false);
    const [isPatientAppointmentHistoryLoading, setIsPatientAppointmentHistoryLoading] = useState(false);
    const [isPatientCareOrderHistoryLoading, setIsPatientCareOrderHistoryLoading] = useState(false);

    const [activeTab, setActiveTab] = useState(
        {
            "name": patientConfig.isPatientHistoryEnabled ? "upcomingAppointment" :
                (patientConfig.isPatientCareOrderHistoryEnabled ? "careOrderHistory" :
                    (patientConfig.isDecisionSupportWorkflowHistoryEnabled ? "decisionSupportWorkflowHistory" : "")),
            "reloadTabContent": false
        }
    );

    useEffect(() => {
        dispatch(onPatientDetailsViewMount());
    }, [dispatch])

    if (!props.match?.params?.referenceId) {
        throw new Error("Missing Patient Id");
    }

    const isPatientAlreadyActive = (patient?.id === props.match?.params?.referenceId);
    const isPatientAlreadyLoaded = isPatientAlreadyActive && (patient.details.firstName !== undefined);
    const shouldLoadPatient = !isPatientAlreadyLoaded;
    useLoadPatientDetails(
        props.match.params.referenceId,
        shouldLoadPatient,
        setIsPatientLoading
    );

    const shouldLoadPatientAppointmentHistory = patient.id && patientConfig.isPatientHistoryEnabled;
    useLoadPatientAppointmentHistory(
        patient.id,
        shouldLoadPatientAppointmentHistory,
        setIsPatientAppointmentHistoryLoading
    );

    const shouldLoadPatientCareOrderHistory = patient.id && patientConfig.isPatientCareOrderHistoryEnabled;
    useLoadPatientCareOrderHistory(
        patient.id,
        shouldLoadPatientCareOrderHistory,
        setIsPatientCareOrderHistoryLoading
    );

    const shouldLoadPatientOpenWorkflows = patient.id && patientConfig.isDecisionSupportWorkflowHistoryEnabled;
    useLoadPatientOpenWorkflows(
        patient.id,
        shouldLoadPatientOpenWorkflows,
        schedulingConfig.bookOpenDecisionSupportWorkflowMaxAgeInDays,
    );

    const careOrders = useSelector((state: any) => state.activePatient.careOrderHistory);
    const openCareOrders = careOrders?.filter((careOrder: any) =>
        (careOrder.statusCode !== 'C')
        && (!careOrder.expirationDate || dayjs(careOrder.expirationDate) >= dayjs())
    );

    const openCareOrdersCount = openCareOrders?.length;

    const hidePatientHistorySummary = () => {
        return !patientConfig.isPatientHistoryEnabled && !patientConfig.isPatientCareOrderHistoryEnabled && !patientConfig.isDecisionSupportWorkflowHistoryEnabled
    }

    return (
        <div>
            <ActiveErrors />
            {isPatientLoading &&
                <LoadingIndicator />
            }
            {!isPatientLoading &&
                <>
                    <PatientDetails
                        config={patientConfig}
                        patient={patient}
                    />
                    <br />
                    {(isPatientAppointmentHistoryLoading || isPatientCareOrderHistoryLoading || isPatientOpenWorkflowsLoading) &&
                        <div><LoadingIndicator /></div>
                    }
                    {(!isPatientAppointmentHistoryLoading && !isPatientCareOrderHistoryLoading && !isPatientOpenWorkflowsLoading && !hidePatientHistorySummary()) &&
                        <PatientHistorySummary
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                    }
                    {(!isPatientAppointmentHistoryLoading && !isPatientCareOrderHistoryLoading && !isPatientOpenWorkflowsLoading) &&
                        <>
                            <Nav tabs>
                                {patientConfig.isPatientHistoryEnabled && (
                                    <NavItem>
                                        <NavLink
                                            className={classnames('patientInfoNavLink', { active: activeTab.name === "upcomingAppointment" })}
                                            onClick={() => {
                                                if (activeTab.name !== "upcomingAppointment") {
                                                    setActiveTab({ name: "upcomingAppointment", reloadTabContent: false });
                                                }
                                            }}
                                        >
                                            Upcoming Appointments
                                        </NavLink>
                                    </NavItem>
                                )}
                                {patientConfig.isPatientHistoryEnabled && (
                                    <NavItem>
                                        <NavLink
                                            className={classnames('patientInfoNavLink', { active: activeTab.name === "appointmentHistory" })}
                                            onClick={() => {
                                                if (activeTab.name !== "appointmentHistory") {
                                                    setActiveTab({ name: "appointmentHistory", reloadTabContent: false });
                                                }
                                            }}
                                        >
                                            Appointment History
                                        </NavLink>
                                    </NavItem>
                                )}
                                {patientConfig.isPatientCareOrderHistoryEnabled && (
                                    <NavItem>
                                        <NavLink
                                            className={classnames('patientInfoNavLink', { active: activeTab.name === "careOrderHistory" })}
                                            onClick={() => {
                                                if (activeTab.name !== "careOrderHistory") {
                                                    setActiveTab({ name: "careOrderHistory", reloadTabContent: false });
                                                }
                                            }}
                                        >
                                            Open Care Orders
                                            {openCareOrdersCount > 0 && (
                                                <>
                                                    {' '}
                                                    <Badge
                                                        id="openCareOrdersBadge"
                                                        pill
                                                    >
                                                        {openCareOrdersCount}
                                                    </Badge>
                                                </>
                                            )}
                                        </NavLink>
                                    </NavItem>
                                )}
                                {patientConfig.isDecisionSupportWorkflowHistoryEnabled && (
                                    <NavItem>
                                        <NavLink
                                            className={classnames('patientInfoNavLink', { active: activeTab.name === "decisionSupportWorkflowHistory" })}
                                            onClick={() => {
                                                if (activeTab.name !== "decisionSupportWorkflowHistory") {
                                                    setActiveTab({ name: "decisionSupportWorkflowHistory", reloadTabContent: false });
                                                }
                                            }}
                                        >
                                            Open Workflows
                                        </NavLink>
                                    </NavItem>
                                )}
                            </Nav>
                            <TabContent activeTab={activeTab.name}>
                                <TabPane tabId="upcomingAppointment" className="patientDetailsPageTabPane">
                                    {patientConfig.isPatientHistoryEnabled &&
                                        <PatientUpcomingAppointments
                                            showLoseProgressWarning={false}
                                            enableShowPSAandMHDfilters={true}
                                            activeTab={activeTab}
                                        />
                                    }
                                </TabPane>
                                <TabPane tabId="appointmentHistory" className="patientDetailsPageTabPane">
                                    {patientConfig.isPatientHistoryEnabled &&
                                        <PatientAppointmentHistory
                                            showLoseProgressWarning={false}
                                            enableShowPSAandMHDfilters={true}
                                            activeTab={activeTab}
                                        />
                                    }
                                </TabPane>
                                <TabPane tabId="careOrderHistory" className="patientDetailsPageTabPane">
                                    {patientConfig.isPatientCareOrderHistoryEnabled &&
                                        <PatientCareOrderHistory
                                            showBookButton={true}
                                            showCloseButton={closeCareOrderEnabled}
                                            showFilters={true}
                                            setIsPatientCareOrderHistoryLoading={setIsPatientCareOrderHistoryLoading}
                                            activeTab={activeTab}
                                        />
                                    }
                                </TabPane>
                                <TabPane tabId="decisionSupportWorkflowHistory" className="patientDetailsPageTabPane">
                                    {patientConfig.isDecisionSupportWorkflowHistoryEnabled &&
                                        <PatientOpenWorkflows
                                            showLoseProgressWarning={false}
                                            showBookButton={schedulingConfig.enableBookOpenDecisionSupportWorkflow}
                                            activeTab={activeTab}
                                            toggleIsPatientSummaryPanelOpen={() => null}
                                        />
                                    }
                                </TabPane>
                            </TabContent>
                        </>
                    }
                </>
            }
        </div>
    )
}
