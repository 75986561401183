import React, { useEffect, useState, useCallback } from "react";
import IdlePopup from './idlePopup';
import { TIMEOUTS, domEvents } from './idleConstants';

// Adapted from code in this example https://javascript.plainenglish.io/advanced-react-how-to-build-user-idle-component-using-hooks-81f8ef6fdefc

export default function IdlePopupContainer() {
    const [open, setOpen] = useState(false);
    const [reset, setReset] = useState(Date.now());
    const [timedOut, setTimedOut] = useState(false);
    const resetFun = useCallback(() => setReset(Date.now()), []);

    useEffect(() => {
        const id = setTimeout(() => {
            setOpen(true);
        }, TIMEOUTS.IDLE_POPUP_TIME);

        return clearTimeout.bind(null, id);
    }, [reset]);

    useEffect(() => {
        const id = setTimeout(() => {
            setTimedOut(true);
        }, TIMEOUTS.IDLE_TIMEOUT);

        return clearTimeout.bind(null, id);
    }, [reset]);

    useEffect(() => {
        domEvents.forEach((event) =>
            document.addEventListener(event, resetFun));
        return () => {
            domEvents.forEach((event) =>
                document.removeEventListener(event, resetFun)
            );
        };
    }, [resetFun]);

    return (
        <>
            <IdlePopup
                timedOut={timedOut}
                open={open}
                setOpen={setOpen}
                setReset={setReset}
                setTimedOut={setTimedOut}
                onClose={() => {
                    setOpen(false);
                    setReset(Date.now());
                }}
            />
        </>
    )
}
