export const nullIfZero = (value) => {
	if (value === 0)
		return null;
	else
		return value;
}

export const deepCopy = (value) => JSON.parse(JSON.stringify(value));

export const filterArrayDuplicatesByPropName = (array, propName) => {
	const uniqueResults = [];
	const map = new Map();
	for (const item of array) {
		if (!map.has(item[propName])) {
			map.set(item[propName], true);    // set any value to Map
			uniqueResults.push({
				...item
			});
		}
	}
	return uniqueResults;
}

export function resolveGenderList(allowUnknownGender, unknownGenderOptionText) {
	let genderOptions = [{ id: "M", value: "M", label: "Male", name: "Male" }, { id: "F", value: "F", label: "Female", name: "Female" }];
	if (allowUnknownGender) {
		genderOptions.push({ id: "U", value: "U", label: `${unknownGenderOptionText}`, name: "Unknown" });
	};
	return genderOptions;
}

export const scrollToPageTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
}