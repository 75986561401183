import { Dispatch, SetStateAction } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { OpenWorkflowFilters } from "./patientOpenWorkflows";

const PatientOpenWorkflowsFilter = (
    {
        filters,
        setFilters,
    }: {
        filters: OpenWorkflowFilters,
        setFilters: Dispatch<SetStateAction<OpenWorkflowFilters>>,
    }
) => {
    return (
        <Col style={{ marginLeft: '20px', marginBottom: '10px' }}>
            <Row>
                <Col>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="includeCanceled"
                            checked={filters.includeBookedWorkflows}
                            onChange={() => setFilters(filters => { return { ...filters, includeBookedWorkflows: !filters.includeBookedWorkflows } })}
                        />{' '}
                        Include Booked Workflows
                    </Label>
                </Col>
            </Row>
        </Col>
    );
}

export default PatientOpenWorkflowsFilter;