/**
 * @format
 */

export function resolveGenderOptions(allowUnknownGender, unknownGenderOptionText) {
	let genderOptions = [
		{ id: 'M', name: 'Male' },
		{ id: 'F', name: 'Female' },
	];
	if (allowUnknownGender) {
		genderOptions.push({ id: 'U', name: `${unknownGenderOptionText}` });
	}
	return genderOptions;
}
