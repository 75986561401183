import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { PasswordInput } from '../../../components/input';
import { PasswordConfigInfo } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppHistory } from 'hooks';
import ResetPasswordInstructions from './resetPasswordInstructions';

interface ResetPasswordProps {
	email: string;
	passwordConfig: PasswordConfigInfo;
	onSubmit: (newPassword: string, confirmPassword: string) => void;
	onCancel: () => void;
	logoUrl: string;
	isSaving: boolean;
	isCompleted: boolean;
}

interface ResetPasswordState {
	newPassword: string;
	confirmPassword: string;
	isNotLongEnough: boolean;
	isNotConfirmed: boolean;
}

export const ResetPasswordForm: React.FC<ResetPasswordProps> = (props) => {
	const { email, passwordConfig, onSubmit, onCancel, isSaving, isCompleted } = props;

	const [state, setState] = useState<ResetPasswordState>({
		newPassword: '',
		confirmPassword: '',
		isNotLongEnough: false,
		isNotConfirmed: false,
	});

	const history = useAppHistory();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (validatePasswords()) {
			onSubmit(state.newPassword, state.confirmPassword);
		}
	};

	const validatePasswords = () => {
		const isNewValid = validateField('newPassword', state.newPassword);
		const isConfirmedValid = validateField('confirmPassword', state.confirmPassword);
		return isNewValid && isConfirmedValid;
	};

	const validateField = (field: string, value: string) => {
		switch (field) {
			case 'newPassword':
				const isNotLongEnough = !value || value.length < passwordConfig.requiredLength;
				setState((prevState) => ({ ...prevState, isNotLongEnough }));
				return !isNotLongEnough;

			case 'confirmPassword':
				const isNotConfirmed = state.newPassword !== state.confirmPassword;
				setState((prevState) => ({ ...prevState, isNotConfirmed }));
				return !isNotConfirmed;

			default:
				return false;
		}
	};

	const cancel = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		onCancel();
	};

	return (
		<Form onSubmit={handleSubmit} className='resetPasswordForm'>
			<div>
				<div style={{ fontWeight: 'bold' }} className="font-weight-bold form-label">User Email:</div>
				<div className="form-label">{email}</div>
			</div>
			{!isCompleted &&
				<>
					<FormGroup>
						<PasswordInput
							name="newPassword"
							id="newPassword"
							label="New Password"
							placeholder="New Password"
							onChange={handleChange}
							value={state.newPassword}
						/>
						{state.isNotLongEnough && (
							<div className="validation-error">
								Your new password must be at least {passwordConfig.requiredLength} characters.
							</div>
						)}
					</FormGroup>
					<FormGroup>
						<PasswordInput
							name="confirmPassword"
							id="confirmPassword"
							label="Confirm Password"
							placeholder="Confirm Password"
							onChange={handleChange}
							value={state.confirmPassword}
						/>
						{state.isNotConfirmed && <div className="validation-error">You must confirm your new password.</div>}
					</FormGroup>
					<FormGroup>
						<Button color="success" type="submit" className="experian-btn" block>
							{isSaving ? (
								<span>
									<FontAwesomeIcon icon="spinner" spin /> Saving Password...
								</span>
							) : (
								<span>Save Password</span>
							)}
						</Button>
						<Button color="link" onClick={cancel} block>
							Cancel
						</Button>
					</FormGroup>
					<ResetPasswordInstructions />
				</>
			}
			{isCompleted &&
				<div>
					<Row>
						<Col>
							<Alert color="success" style={{
								width: '100%',
							}}>Your password has been reset.</Alert>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button color="success" style={{ marginBottom: '10px' }} className="experian-btn" block onClick={() => history.navigate('/login')}>Back to Login</Button>
						</Col>
					</Row>
				</div>
			}
		</Form >
	);
};

export default ResetPasswordForm;
