import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from 'reactstrap';
import {
    changeViewDateThunk,
    createCalendarDateAvailabilitySelector,
    selectActiveDisplayedDates,
    selectAllowRequestAppointmentById,
    selectAllowRequestAppointmentWithAvailabilityById,
    selectFirstAvailabilityDateByCalendarId,
    selectFirstDisplayableDateWithSlotForCalendarById,
    selectIsCalendarScanningById,
    selectIsCalendarSearchingById,
    selectNextAvailabilityDateByCalendarId,
    selectScanDayCount,
    selectShouldShowPreReserveModal
} from "../availabilitySlice";
import { reserveAppointment } from "../../../actions/appointmentActions";
import { requestAppointment as routeToRequestAppointment, routeToBooking } from "../../../routes";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons';
import { formatStartTime } from "../../../lib/misc";
import '../css/availabilityResultTimeslots.css';
import { nullifySuppressedDates } from "lib/date";

export default function Availability({ calendarId, selectedSlot, setSelectedSlot }) {
    const isCalendarSearchingAvailability = useSelector(state => selectIsCalendarSearchingById(state, calendarId));
    const isCalendarScanningAvailability = useSelector(state => selectIsCalendarScanningById(state, calendarId));
    const isScanningOrSearching = isCalendarSearchingAvailability || isCalendarScanningAvailability;
    const firstDisplayableDateWithSlot = useSelector(state => selectFirstDisplayableDateWithSlotForCalendarById(state, calendarId));

    return (
        <AvailabilityContainer>
            {!firstDisplayableDateWithSlot && !isScanningOrSearching &&
                <NoAvailability
                    calendarId={calendarId}
                />
            }
            {firstDisplayableDateWithSlot &&
                <AvailabilityDates
                    calendarId={calendarId}
                    selectedSlot={selectedSlot}
                    setSelectedSlot={setSelectedSlot}
                />
            }
        </AvailabilityContainer>
    )
}

const AvailabilityContainer = ({ children }) => {
    return (
        <Row className="timeslot-container">
            <Col sm="auto" style={{ "paddingRight": "0px" }}>
                <Row>
                    <Col>
                        <div className="timeslot-spacer">&nbsp;</div>
                    </Col>
                </Row>
            </Col>
            {children}
            <Col sm="auto" className="timeslot-gutter">
                <Row>
                    <Col>
                        <div className="timeslot-spacer">&nbsp;</div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const NoAvailability = ({ calendarId }) => {

    const dispatch = useDispatch();

    const isCalendarSearchingAvailability = useSelector(state => selectIsCalendarSearchingById(state, calendarId));
    const isCalendarScanningAvailability = useSelector(state => selectIsCalendarScanningById(state, calendarId));
    const isScanningOrSearching = isCalendarSearchingAvailability || isCalendarScanningAvailability;

    /* eslint-disable */
    const firstAvailabilityDate = useSelector(state => selectFirstAvailabilityDateByCalendarId(state, calendarId));
    /* eslint-enable */
    const nextAvailabilityDate = useSelector(state => selectNextAvailabilityDateByCalendarId(state, calendarId));

    const allowRequestAppointment = useSelector(state => selectAllowRequestAppointmentById(state, calendarId));
    const allowRequestAppointmentWithAvailability = useSelector(state => selectAllowRequestAppointmentWithAvailabilityById(state, calendarId));
    const scanDayCount = useSelector(selectScanDayCount);

    const effectiveNextAvailabilityDate = firstAvailabilityDate || nextAvailabilityDate;

    return (
        <Col sm="12">
            <Row>
                <Col className="d-flex justify-content-center" >
                    <div className="no-availability-pane">
                        {!effectiveNextAvailabilityDate &&
                            (allowRequestAppointment ?
                                <>
                                    This provider matched your search but has no appointments available in the next {scanDayCount} days. Please <Button color="link" className="first-available-button" onClick={(e) => dispatch(routeToRequestAppointment(e, calendarId))}>Request an appointment.</Button>
                                </>
                                :
                                <>
                                    This provider matched your search but has no appointments available in the next {scanDayCount} days.
                                </>
                            )
                        }
                        {effectiveNextAvailabilityDate && !isScanningOrSearching &&
                            (allowRequestAppointmentWithAvailability ?
                                <>
                                    This provider has no appointments available this week. Their first available appointment is <Button color="link" className="first-available-button" onClick={() => dispatch(changeViewDateThunk(effectiveNextAvailabilityDate))}>{dayjs(effectiveNextAvailabilityDate).format("MMM D YYYY")}</Button> or <Button color="link" className="first-available-button" onClick={(e) => dispatch(routeToRequestAppointment(e, calendarId))}>Request an appointment.</Button>
                                </>
                                :
                                <>
                                    This provider has no appointments available this week. Their first available appointment is <Button color="link" className="first-available-button" onClick={() => dispatch(changeViewDateThunk(effectiveNextAvailabilityDate))}>{dayjs(effectiveNextAvailabilityDate).format("MMM D YYYY")}</Button>
                                </>
                            )
                        }
                    </div>
                </Col>
            </Row>
        </Col>
    );
}

const AvailabilityDates = ({ calendarId, selectedSlot, setSelectedSlot }) => {
    const displayedDates = useSelector(selectActiveDisplayedDates);

    const suppressSaturday = useSelector(state => state.config.availabilitySearch.suppressSaturday);
    const suppressSunday = useSelector(state => state.config.availabilitySearch.suppressSunday);

    return displayedDates.map(date => {
        if (!nullifySuppressedDates(date, suppressSaturday, suppressSunday)) {
            return <AvailabilityDateDisabled key={date} date={date} />;
        }

        return <AvailabilityDate
            key={date}
            calendarId={calendarId}
            date={date}
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
        />
    })
}

const AvailabilityDate = ({ calendarId, date, selectedSlot, setSelectedSlot }) => {
    const INITIAL_SLOTS_PER_DAY = 5;
    const selectCalendarAvailabilityByDate = useMemo(createCalendarDateAvailabilitySelector, []);

    //This creates a unique selector instance to cache by calendarId/date
    //https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
    const slots = useSelector((state) => selectCalendarAvailabilityByDate(state, calendarId, date));
    const isReserving = useSelector(state => state.appointment.isLoading.reserveAppointment);
    const enableAppointmentTypeTooltips = useSelector(state => state.config.availabilitySearch.enableAppointmentTypeTooltips);
    const shouldShowPreReserveModal = useSelector(state => selectShouldShowPreReserveModal(state, calendarId));
    const token = useSelector(state => state.auth.token);
    const correlationKey = useSelector(state => state.session.correlationKey);
    const reservationDurationInMinutes = useSelector(state => state.config.scheduling.reservationDurationInMinutes);
    const activePatientReferenceId = useSelector(state => state.activePatient?.details?.referenceId);

    const [isExpandedToShowAllSlots, setIsExpandedToShowAllSlots] = useState(false);

    const dispatch = useDispatch();

    let canExpand = slots?.length > INITIAL_SLOTS_PER_DAY;
    let shownSlots = (canExpand && !isExpandedToShowAllSlots) ?
        slots.slice(0, INITIAL_SLOTS_PER_DAY)
        : slots;

    const availabilitySearchSupportData = useSelector((state) => state.config.availabilitySearchSupportData);
    const appointmentTypeList = availabilitySearchSupportData.appointmentTypeList;

    return (
        <DateContainer date={date}>
            {shownSlots.map((slot, index) => {
                let isSelected = Boolean(selectedSlot?.timeSlotId === slot.availabilityId);
                const modality = appointmentTypeList.find(apptType => apptType.idPgmAppointmentType === slot.appointmentTypeId)?.pgmAppointmentModalityName ?? '';

                return (
                    <div className="container" key={index}>
                        <Row className="timeslot-button-container">
                            <Button
                                className="timeslot-button"
                                // dispatch(reserveAppointmentRequest(selectedSlot))
                                onClick={() => {
                                    let mappedSlot = mapSlotToAppointmentRequest(slot);
                                    setSelectedSlot(mappedSlot);
                                    if (!shouldShowPreReserveModal) {
                                        dispatch(reserveAppointment(
                                            token,
                                            mappedSlot,
                                            reservationDurationInMinutes,
                                            correlationKey
                                        )).then((response) => {
                                            if (response.payload?.data?.reservationId) {
                                                dispatch(routeToBooking(activePatientReferenceId, response.payload?.data?.flexCalendarEntryId))
                                            }
                                        });
                                    }
                                }}
                                data-toggle="tooltip"
                                data-placement="top"
                                disabled={isReserving}
                                title={enableAppointmentTypeTooltips ? slot.appointmentTypeName : null}
                            >
                                {(modality === "Telephonic") && !isReserving && !isSelected &&
                                    <span><FontAwesomeIcon style={{ marginRight: '5px' }} icon={faMobileScreenButton} size='lg' /></span>
                                }
                                {isReserving && isSelected ? <span><FontAwesomeIcon icon="spinner" spin />  Reserving...</span> : formatStartTime(slot.startTime)}
                            </Button>
                        </Row>
                    </div>
                );
            })}
            {canExpand &&
                <ShowMoreLessButton
                    isExpandedToShowAllSlots={isExpandedToShowAllSlots}
                    setIsExpandedToShowAllSlots={setIsExpandedToShowAllSlots}
                />
            }
        </ DateContainer>
    )
}

const AvailabilityDateDisabled = ({ date }) => <DateContainer date={date} isDisabled={true} />

const DateContainer = ({ children, date, isDisabled }) => {
    const className = isDisabled ? "timeslot-date-disabled" : "timeslot-date";

    return (
        <Col key={date} className={className}>
            <Row>
                <Col className="timeslot-col">
                    <div>
                        {children}
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

const ShowMoreLessButton = ({ isExpandedToShowAllSlots, setIsExpandedToShowAllSlots }) => {
    return (
        <div className="container">
            <Row>
                <Button className="timeslot-button-blue" onClick={() => setIsExpandedToShowAllSlots(x => !x)}>{isExpandedToShowAllSlots ? "Less" : "More"}</Button>
            </Row>
        </div>
    )
}

export const mapSlotToAppointmentRequest = (slot) => {
    return {
        timeSlotId: slot.availabilityId,
        calendarId: slot.calendarId,
        appointmentTypeId: slot.appointmentTypeId,
        date: slot.date,
        startTime: slot.startTime,
        endTime: slot.endTime,
    };
}


