import * as actionTypes from './actionTypes';

export function setInitialCustomFieldAnswers(careOrderCustomFields, decisionSupportCustomFields) {
	return function (dispatch) {
		dispatch(setInitialCustomFieldAnswersAction(careOrderCustomFields, decisionSupportCustomFields));
		return Promise.resolve();
	}
}

function setInitialCustomFieldAnswersAction(careOrderCustomFields, decisionSupportCustomFields) {
	return {
		type: actionTypes.SET_INITIAL_CUSTOM_FIELD_ANSWERS,
		careOrderCustomFields: careOrderCustomFields,
		decisionSupportCustomFields: decisionSupportCustomFields,
	}
}
