/**
 * @format
 */

import moment from 'moment';

export function displayFullName(firstName, lastName) {
	let fullName = `${firstName && firstName.trim() !== '' ? firstName : ''} ${
		lastName && lastName.trim() !== '' ? lastName : ''
	}`;
	return fullName;
}

export function displayProviderNameWithProfessionalDesignation(providerName, professionalDesignation) {
	let fullName = `${providerName}`;
	if (professionalDesignation && professionalDesignation.trim() !== '') {
		fullName += `, ${professionalDesignation}`;
	}
	return fullName;
}

export function genderCodeToDisplayName(genderCode) {
	let genderDisplay;
	if (genderCode && genderCode.toUpperCase() === 'M') {
		genderDisplay = 'Male';
	} else if (genderCode && genderCode.toUpperCase() === 'F') {
		genderDisplay = 'Female';
	} else if (genderCode && genderCode.toUpperCase() === 'U') {
		genderDisplay = 'Unknown';
	} else {
		genderDisplay = '-';
	}
	return genderDisplay;
}

export function formatCityStateZip(city, stateCode, zip) {
	return `${city}, ${stateCode} ${zip}`;
}

export function formatPhone(phone, prependLabel) {
	let result = '';
	if (phone && phone.trim() !== '') {
		const cleanPhone = phone.replace(/\D/g, '');
		let match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (prependLabel) {
			result = `${prependLabel} `;
		}
		if (match) {
			result += '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
	}
	return result;
}

export function formatPhoneNoLabel(phone) {
	let result = '';
	if (phone && phone.trim() !== '') {
		const cleanPhone = phone.replace(/\D/g, '');
		let match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			result = '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
	}
	return result;
}

export function formatServiceName(firstName, lastName, assetTag) {
	let fname = firstName ? firstName.trim() : '';
	let lname = lastName ? lastName.trim() : '';
	let fullName = '';
	if (assetTag && assetTag.trim() !== '') {
		fullName = assetTag;
	} else {
		fullName = `${fname} ${lname}`;
	}
	return fullName;
}

export function verifyFuture(date) {
	return moment.utc(date).isAfter();
}

export function replaceLineFeedsWithBreakRegex(string) {
	const lineFeedsRegex = new RegExp(/(\r\n?|\n)/g);
	return string.replace(lineFeedsRegex, '<br />');
}

export const valueDisplay = {
	margin: '0px',
};

export const valueDisplayBottomMargin = {
	margin: '0px 0px 10px',
};
