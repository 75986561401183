import { useSelector } from "react-redux";
import {
    selectPreferredDisplayedCalendarIds
} from "../availabilitySlice";
import DateNav from "./dateNav";
import ResultRow from "./resultRow";

export default function PreferredResults({ selectedSlot, setSelectedSlot }) {

    const preferredDisplayedCalendarIds = useSelector(selectPreferredDisplayedCalendarIds);

    return (
        <>
            {preferredDisplayedCalendarIds?.length > 0 &&
                <>
                    <h5 className="availability-result-subheader-divider">Selected Provider</h5>
                    <DateNav />
                    {preferredDisplayedCalendarIds.map((calendarId, index, array) =>
                        <ResultRow
                            key={calendarId}
                            calendarId={calendarId}
                            isLastRow={array.length - 1 === index}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                        />
                    )}
                </>
            }
        </>
    );
}


