import React from 'react';
import FieldControl from './fieldControl';

/**
* @param {Object} props
* @param {string} props.id
* @param {Object} props.error
* @param {string} props.error.message
* @param {boolean} props.disabled
* @param {boolean} props.isFocused
* @param {boolean} props.isVisible - If False Component Returns null
* @param {boolean} props.label
* @param {boolean} props.labelAfter - If true, label is placed after input (default before)
* @param {string} props.name
* @param {Object} props.value
* @param {Function} props.onBlur
* @param {Function} props.onChange
* @param {Function} props.onFocus
* @param {Array} props.optionList
* @param {string} props.size - "sm", "md", "lg"
* @returns {Object}
*/
export function RadioSelections(props) {
	const {
		id,
		error,
		disabled,
		isFocused,
		isVisible,
		label,
		labelAfter,
		name,
		value,
		onBlur,
		onChange,
		onFocus,
		optionList,
		size,
	} = props;

	// TODO: Change radio button labels to style similarly to filled text inputs
	const hasValue = value;
	const inputType = "radio";

	return (
		<FieldControl
			disabled={disabled}
			error={error}
			hasValue={hasValue}
			isFocused={isFocused}
			id={id}
			inputType={inputType}
			isVisible={isVisible}
			label={label}
			labelAfter={labelAfter}
			size={size}
		>
			<div className="radio-options-container">
				{optionList && optionList.map(option =>
					<RadioOption
						key={option.id}
						id={id + option.id}
						name={name}
						optionValue={option.id}
						label={option.name}
						value={value}
						onChange={onChange}
						onFocus={onFocus}
						onBlur={onBlur}
						disabled={disabled}
					/>
				)}
			</div>
		</FieldControl>
	);
}

function RadioOption(props) {
	const { id, name, optionValue, label, value, onChange, /*onFocus, onBlur*/ } = props;
	let isSelected = (optionValue === value);

	return (
		<div className="radio-option">
			<input type="radio" className="input-radio" id={id} name={name} checked={isSelected} value={optionValue} onChange={onChange} /*onFocus={onFocus}*//* onBlur={onBlur}*/ />
			<label className="radio-label" htmlFor={id}>{label}</label>
		</div>
	);
}