/**
 * @format
 */

import React from 'react';
import { Col, Button } from 'reactstrap';

export function RescheduleAppointmentPanel(props) {
	const {
		allowReschedulePSAWithSameProvider,
		backButtonText,
		goBack,
		hasMappedAppointmentType,
		isPsaAppointment,
		rescheduleText,
		startRescheduleWithDifferentProvider,
		startRescheduleWithSameProvider,
	} = props;

	return (
		<Col lg="6">
			<p>{rescheduleText}</p>
			{!isPsaAppointment ? (
				<div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
					<Button
						color="primary"
						style={{ marginRight: '8px' }}
						onClick={() => {
							startRescheduleWithSameProvider();
						}}
					>
						Same Provider
					</Button>
					<Button
						color="primary"
						style={{ marginRight: '8px' }}
						onClick={() => {
							startRescheduleWithDifferentProvider();
						}}
					>
						Different Provider
					</Button>
					<Button color="secondary" style={{ marginRight: '8px' }} onClick={goBack}>
						{backButtonText}
					</Button>
				</div>
			) : isPsaAppointment && hasMappedAppointmentType && allowReschedulePSAWithSameProvider ? (
				<div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
					<Button
						color="primary"
						style={{ marginRight: '8px' }}
						onClick={() => {
							startRescheduleWithSameProvider();
						}}
					>
						Same Provider
					</Button>
					<Button
						color="primary"
						style={{ marginRight: '8px' }}
						onClick={() => {
							startRescheduleWithDifferentProvider();
						}}
					>
						Different Provider
					</Button>
					<Button color="secondary" style={{ marginRight: '8px' }} onClick={goBack}>
						{backButtonText}
					</Button>
				</div>
			) : (
				<div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
					<Button
						color="primary"
						style={{ marginRight: '8px' }}
						onClick={() => {
							startRescheduleWithDifferentProvider();
						}}
					>
						Confirm
					</Button>
					<Button color="secondary" style={{ marginRight: '8px' }} onClick={goBack}>
						{backButtonText}
					</Button>
				</div>
			)}
		</Col>
	);
}
