//@ts-check

import { useCallback, useRef, useState } from 'react';

/**
 * A hook that saves state for a controlled component, and sets up and controls internal state for an uncontrolled component
 * @param {{ controlledValue?: Value, defaultValue?: Value }} props
 * @returns {[Value, (value: Value) => void]}
 * @template Value
 */
export default function useControlled({ controlledValue, defaultValue }) {
    const isControlled = useRef(controlledValue !== undefined);
    const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

    //
    const onlySetValueIfUncontrolledComponent = useCallback((value) => {
        if (!isControlled.current) {
            setUncontrolledValue(value);
        }
    }, []);

    const value = isControlled.current ? controlledValue : uncontrolledValue;

    return [value, onlySetValueIfUncontrolledComponent];
}
