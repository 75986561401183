import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { closeProductAlertBanner, getProductAlertConfig } from '../../actions/productAlertConfigActions';
import { productIds } from '../../constants/productAlertConfigValues';
import {
	Grid,
	GridItem
} from '../guidedResponse/layouts';
import ReadonlyHtml from './readonlyHtml';

library.add(faInfoCircle, faTimes);

const INTERVAL_IN_MS = 300000;

const ProductAlertBanner = ({config}) => {
	const dispatch = useDispatch();
	const { isActive, wasAlertBannerClosed } = useSelector(s => s.productAlertConfig);
	let intervalId = useRef();

	const getAlertTitle = () => {
		if (config?.alertTitle) {
			return config.alertTitle;
		}
		return `Alert Title`;
	}

	useEffect(() => {
		if (isActive) {
			intervalId = window.setInterval(() => {
				dispatch((getProductAlertConfig(productIds.RD2)));
			}, INTERVAL_IN_MS);
		} else {
			window.clearInterval(intervalId);
		}
	}, [isActive, wasAlertBannerClosed]);

	useEffect(() => {
		return () => {
			window.clearInterval(intervalId);
		}
	}, []);

	const handleCloseAlertBannerClick = () => {
		dispatch(closeProductAlertBanner());
	};

	return(
		<Grid numberOfColumns={16}>
			<GridItem span={1}>
				<FontAwesomeIcon icon={faInfoCircle} size="lg" />
			</GridItem>
			<GridItem span={14}>
				<ReadonlyHtml
					label={getAlertTitle()}
					value={config?.alertMessage ? config.alertMessage : ''}
				/>
			</GridItem>
			<GridItem span={1} justifySelf='center' >
				<Button className='alertCloseButton' >
					<FontAwesomeIcon icon={faTimes} size="lg" onClick={handleCloseAlertBannerClick} />
				</Button>
			</GridItem>
		</Grid>
	);
}

export default ProductAlertBanner;