/**
 * @format
 */

import React from 'react';
import { Col, Button } from 'reactstrap';
import { TextInputWithLabel, DropdownSelect } from '../common/standardComponents';
import { CancellationEmailNotification } from './cancellationEmailNotification';

export function CancelAppointmentPanel(props) {
	const {
		cancelInstructions,
		cancellationEmailConsent,
		cancellationEmailInstructions,
		cancellationEmailOther,
		collapse,
		confirmCancellation,
		emailCancellationEnabled,
		enableCancellationEmailOptIn,
		enableCancellationEmailOther,
		fieldValidation,
		goBack,
		handleChange,
		handleCheckboxChange,
		onCancelReasonChange,
		onSelectCancelReason,
		patientCancellationEmailDisclaimer,
		patientEmail,
		reasonForCancellation,
		showDropdown,
		showReasonForCancel,
		systemCancelReasons,
		toggle,
	} = props;

	return (
		<Col lg="6">
			{showReasonForCancel && (
				<>
					<p>{cancelInstructions}</p>
					{showDropdown ? (
						<DropdownSelect
							name="cancelReason"
							isRequired
							label="Reason For Cancellation"
							onChange={onSelectCancelReason}
							optionList={systemCancelReasons}
						/>
					) : (
						<TextInputWithLabel
							onChange={onCancelReasonChange}
							maxLength={200}
							id="cancelReason"
							value={reasonForCancellation}
							isRequired
							label="Reason For Cancellation"
						/>
					)}
				</>
			)}
			{emailCancellationEnabled && (
				<CancellationEmailNotification
					cancellationEmailConsent={cancellationEmailConsent}
					cancellationEmailInstructions={cancellationEmailInstructions}
					cancellationEmailOther={cancellationEmailOther}
					collapse={collapse}
					enableCancellationEmailOptIn={enableCancellationEmailOptIn}
					enableCancellationEmailOther={enableCancellationEmailOther}
					fieldValidation={fieldValidation}
					handleChange={handleChange}
					handleCheckboxChange={handleCheckboxChange}
					patientCancellationEmailDisclaimer={patientCancellationEmailDisclaimer}
					patientEmail={patientEmail}
					title="Configure Email Notification"
					toggle={toggle}
				/>
			)}
			<div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left', marginTop: '8px' }}>
				<Button
					color="primary"
					style={{ marginRight: '8px' }}
					disabled={showReasonForCancel && reasonForCancellation === ''}
					onClick={() => {
						confirmCancellation();
					}}
				>
					Confirm Cancellation
				</Button>
				<Button color="secondary" style={{ marginRight: '8px' }} onClick={goBack}>
					Go Back
				</Button>
			</div>
		</Col>
	);
}
