/**
 * @format
 */

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import Calendar from 'react-calendar';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Form,
    FormGroup,
    Input,
    Label,
    Popover,
    Row,
} from 'reactstrap';
import dayjs from 'dayjs';
import DateInput from '../../../components/input/dateInput';
import DropdownList from '../../../components/input/dropdownList';

import { ZipInputWithoutLabel } from '../../../components/common/standardComponents';
import { ASC } from '../../../constants/availabilitySearchFields';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { validateZipCode } from '../../../lib/validation';
import '../css/availabilitySearchForm.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    DEFAULT_DATE_FORMAT,
    applyFiltersThunk,
    selectAppliedFilters,
    selectAppointmentTypeFilterOptions,
    selectDaysOfWeekFilterOptions,
    selectGenderFilterOptions,
    selectInitialFilters,
    selectInsuranceCarrierFilterOptions,
    selectInsuranceStateFilterOptions,
    selectIsSearchingAnything,
    selectLanguageFilterOptions,
    selectPayorTypeFilterOptions,
    selectRadiusFilterOptions,
    selectServiceTypeFilterOptions,
    selectSortOrderFilterOptions,
    selectSpecialtyFilterOptions
} from '../availabilitySlice';
import { scrollToPageTop } from '../../../lib/misc';

const mapNewFiltersToLegacy = (newFilters) => {

    const timeOfDay = [];
    if (newFilters.minStartTime === "12:00:00" && !newFilters.maxStartTime)
        timeOfDay.push("PM");

    if (newFilters.maxStartTime === "12:00:00" && !newFilters.minStartTime)
        timeOfDay.push("AM");

    return {
        [ASC.appointmentType]: newFilters.appointmentTypeIds?.length > 0 ? newFilters.appointmentTypeIds[0] : "",
        [ASC.appointmentModality]: newFilters.appointmentTypeModalityIds,
        [ASC.appointmentStartDate]: moment.utc(newFilters.viewBaseDate).startOf('day').format(DATE_FORMATS.standard),
        [ASC.providerGender]: newFilters.gender,
        [ASC.insuranceCarrier]: newFilters.insuranceCarrierId,
        [ASC.insuranceState]: newFilters.insuranceStateId,
        [ASC.languagePreference]: newFilters.language,
        [ASC.payorType]: newFilters.payorType,
        [ASC.searchRadius]: newFilters.radius,
        [ASC.serviceName]: newFilters.serviceName,
        [ASC.serviceCategory]: newFilters.serviceCategoryId,
        [ASC.siteName]: newFilters.siteName,
        [ASC.sortOrder]: newFilters.sortOrder,
        [ASC.specialty]: newFilters.specialtyId,
        [ASC.timeOfDay]: timeOfDay,
        [ASC.daysOfWeek]: newFilters.daysOfWeek || [],
        [ASC.zipCode]: newFilters.zipCode,
    };
}

const mapLegacyFiltersToNew = (legacyFilters) => {
    return {
        appointmentTypeIds: legacyFilters[ASC.appointmentType] ? [legacyFilters[ASC.appointmentType]] : [],
        appointmentTypeModalityIds: legacyFilters[ASC.appointmentModality],
        viewBaseDate: dayjs(legacyFilters[ASC.appointmentStartDate]).format(DEFAULT_DATE_FORMAT),
        gender: legacyFilters[ASC.providerGender],
        insuranceCarrierId: legacyFilters[ASC.insuranceCarrier],
        insuranceStateId: legacyFilters[ASC.insuranceState],
        language: legacyFilters[ASC.languagePreference],
        payorType: legacyFilters[ASC.payorType],
        radius: legacyFilters[ASC.searchRadius],
        serviceCategoryId: legacyFilters[ASC.serviceCategory],
        sortOrder: legacyFilters[ASC.sortOrder],
        specialtyId: legacyFilters[ASC.specialty],
        //TODO
        minStartTime: legacyFilters[ASC.timeOfDay].includes("PM") && !legacyFilters[ASC.timeOfDay].includes("AM")
            ? "12:00:00"
            : null,
        maxStartTime: legacyFilters[ASC.timeOfDay].includes("AM") && !legacyFilters[ASC.timeOfDay].includes("PM")
            ? "12:00:00"
            : null,
        daysOfWeek: legacyFilters[ASC.daysOfWeek],
        zipCode: legacyFilters[ASC.zipCode],
        siteName: legacyFilters[ASC.siteName],
        serviceName: legacyFilters[ASC.serviceName],
    }
}

export default function SearchForm() {
    const initialFilters = useSelector(selectInitialFilters);
    const appliedFilters = useSelector(selectAppliedFilters);
    const availabilitySearchSupportData = useSelector(state => state.config.availabilitySearchSupportData);
    const useDecisionSupport = useSelector(state => state.config.decisionSupport.useDecisionSupport);

    const searchFields = useSelector(state => state.config.availabilitySearch.searchFields);
    const doFirstSearchAutomatically = useSelector(state =>
        state.careOrder?.activeCareOrderDetails?.appointments
        || state.config.decisionSupport.useDecisionSupport
    )

    const serviceTypeFilterOptions = useSelector(selectServiceTypeFilterOptions);
    const languageFilterOptions = useSelector(selectLanguageFilterOptions);
    const appointmentTypeFilterOptions = useSelector(selectAppointmentTypeFilterOptions);
    const insuranceStateFilterOptions = useSelector(selectInsuranceStateFilterOptions);
    const insuranceCarrierFilterOptions = useSelector(selectInsuranceCarrierFilterOptions);
    const specialtyFilterOptions = useSelector(selectSpecialtyFilterOptions);
    const sortOrderFilterOptions = useSelector(selectSortOrderFilterOptions);
    const payorTypeFilterOptions = useSelector(selectPayorTypeFilterOptions);
    const radiusFilterOptions = useSelector(selectRadiusFilterOptions);
    const genderFilterOptions = useSelector(selectGenderFilterOptions);
    const daysOfWeekFilterOptions = useSelector(selectDaysOfWeekFilterOptions);

    const isSearchingAnything = useSelector(selectIsSearchingAnything);

    const initialInputCriteria = useMemo(() => {
        const translatedFilters = mapNewFiltersToLegacy(initialFilters);
        return translatedFilters;
    }, [initialFilters]);

    const [formFieldValues, setFormFieldValues] = useState(initialInputCriteria);

    const [formErrors, setFormErrors] = useState([]);

    const [isShowing, setIsShowing] = useState(!doFirstSearchAutomatically);

    const dispatch = useDispatch();

    useEffect(
        () => {
            setFormFieldValues(mapNewFiltersToLegacy(appliedFilters));
        },
        [appliedFilters]
    )

    const {
        appointmentStartDate,
        appointmentType,
        days,
        insuranceCarrier,
        insuranceState,
        language,
        payorType,
        providerGender,
        searchRadius,
        serviceCategory,
        serviceName,
        siteName,
        sortOrder,
        specialty,
        timeOfDay,
        zipCode,
    } = searchFields;

    const validateFormFields = (onSubmission) => {
        let errors = [];

        if (!validateFutureDate(formFieldValues[ASC.appointmentStartDate])) {
            errors.push(ASC.appointmentStartDate);
        }

        if (formFieldValues[ASC.zipCode] && !validateZipCode(formFieldValues[ASC.zipCode])) {
            errors.push(ASC.zipCode);
        }

        if (formFieldValues[ASC.zipCode] && formFieldValues[ASC.searchRadius] === null) {
            errors.push(ASC.searchRadius);
        }

        //checks only when form is submitted
        if (onSubmission) {
            if (
                searchFields.payorType.isVisible &&
                !validateField(searchFields.payorType, formFieldValues[ASC.payorType], 'payorType')
            ) {
                errors.push(ASC.payorType);
            }

            if (
                searchFields.insuranceState.isVisible &&
                !validateField(searchFields.insuranceState, formFieldValues[ASC.insuranceState], 'insuranceState')
            ) {
                errors.push(ASC.insuranceState);
            }

            if (
                searchFields.insuranceCarrier.isVisible &&
                !validateField(searchFields.insuranceCarrier, formFieldValues[ASC.insuranceCarrier], 'insuranceCarrier')
            ) {
                errors.push(ASC.insuranceCarrier);
            }

            if (
                searchFields.serviceCategory.isVasdf &&
                !validateField(searchFields.serviceCategory, formFieldValues[ASC.serviceCategory], 'serviceCategory')
            ) {
                errors.push(ASC.serviceCategory);
            }

            if (
                searchFields.specialty.isVisible &&
                !validateField(searchFields.specialty, formFieldValues[ASC.specialty], 'specialty')
            ) {
                errors.push(ASC.specialty);
            }

            if (
                searchFields.appointmentType.isVisible &&
                !validateField(searchFields.appointmentType, formFieldValues[ASC.appointmentType], 'appointmentType')
            ) {
                errors.push(ASC.appointmentType);
            }

            if (
                searchFields.providerGender.isVisible &&
                !validateField(searchFields.providerGender, formFieldValues[ASC.providerGender], 'providerGender')
            ) {
                errors.push(ASC.providerGender);
            }

            if (
                searchFields.language.isVisible &&
                !validateField(searchFields.language, formFieldValues[ASC.languagePreference], 'languagePreference')
            ) {
                errors.push(ASC.languagePreference);
            }
        }

        setFormErrors(errors);

        return errors;
    };

    const validateField = (searchField, value, type) => {
        let isValid = false;
        let payorObj =
            formFieldValues[ASC.payorType] > 0
                ? availabilitySearchSupportData.payorTypeList.find(function (x) {
                    return x.idPgmPayorType === formFieldValues[ASC.payorType];
                })
                : null;

        switch (type) {
            case 'payorType':
            case 'serviceCategory':
            case 'specialty':
            case 'appointmentType':
                isValid = value > 0 || !searchField.isRequired;
                return isValid;

            case 'insuranceCarrier':
            case 'insuranceState':
                isValid = !payorObj || (payorObj && !payorObj.usesInsurance) || value > 0 || !searchField.isRequired;
                return isValid;

            case 'providerGender':
                isValid = (!searchField.isRequired && !value) || value === 'F' || value === 'M' || value === 'U';
                return isValid;

            case 'languagePreference':
                isValid = !value || availabilitySearchSupportData.languageList.some((x) => x.idPgmLanguage === value);
                return isValid;

            default:
                return true;
        }
    };

    const startDate = formFieldValues[ASC.appointmentStartDate];
    const zipVal = formFieldValues[ASC.zipCode];
    const radius = formFieldValues[ASC.searchRadius];

    /* eslint-disable */
    const validateFormFieldsTrigger = useEffect(() => {
        validateFormFields(false);
    }, [startDate, zipVal, radius]);
    /* eslint-enable */

    const handleFieldChange = (name, value, type) => {
        switch (type) {
            case 'array': {
                let set = [...formFieldValues[name]];
                let index = set.findIndex((x) => {
                    return x === value;
                });
                if (index > -1) {
                    set.splice(index, 1);
                } else {
                    set = [...set, value];
                }
                setFormFieldValues((x) => {
                    return {
                        ...x,
                        [name]: set,
                    };
                });
                break;
            }
            default:
                setFormFieldValues((x) => {
                    return {
                        ...x,
                        [name]: value,
                    };
                });
                break;
        }
    };

    const submitForm = () => {
        if (validateFormFields(true).length < 1) {
            const intendedFilters = {
                ...appliedFilters,
                ...mapLegacyFiltersToNew(formFieldValues)
            };
            dispatch(applyFiltersThunk(intendedFilters));
            setIsShowing(false);
            scrollToPageTop();
        }
    };

    const resetForm = () => {
        setFormFieldValues(initialInputCriteria)
    };

    const effectiveSpecialtyList = formFieldValues[ASC.serviceCategory]
        ? specialtyFilterOptions.filter((x) => x.idPgmServiceType === formFieldValues[ASC.serviceCategory])
        : specialtyFilterOptions;

    const effectiveInsuranceList = formFieldValues[ASC.insuranceState]
        ? insuranceCarrierFilterOptions.filter((x) => x.stateId === formFieldValues[ASC.insuranceState])
        : insuranceCarrierFilterOptions;

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const legacyToggle = () => {
        if (isDatePickerOpen) {
            setIsDatePickerOpen(false);
        }
    };

    const handleDatepickerChange = (date) => {
        legacyToggle();
        let mDate = moment(date);

        setFormFieldValues((x) => {
            return {
                ...x,
                [ASC.appointmentStartDate]: mDate.format(DATE_FORMATS.standard),
            };
        });
    };

    return (
        <div className="form-container">
            <div className="row">
                <div className="col">
                    <Card>
                        <CardHeader>
                            <h4 onClick={() => setIsShowing(x => !x)} className="hand" style={{ marginBottom: '0px' }}>
                                {useDecisionSupport ? 'Appointment Search Filters' : 'Appointment Search'}
                                <FontAwesomeIcon
                                    style={{ marginLeft: '10px' }}
                                    icon={!isShowing ? faChevronRight : faChevronDown}
                                    size="sm"
                                />
                            </h4>
                        </CardHeader>
                        <Collapse isOpen={isShowing}>
                            <CardBody>
                                <div className="form-frame">
                                    <div className="row">
                                        <div className="col-sm">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    submitForm();
                                                }}
                                            >
                                                <FormGroup row>
                                                    <Label className="fw-bold form-label" for="availabilityStartAt" sm="4">
                                                        {appointmentStartDate
                                                            ? appointmentStartDate.fieldLabel.toUpperCase()
                                                            : 'APPOINTMENT START DATE'}
                                                    </Label>
                                                    <Col sm="3" style={{ paddingRight: '0px' }}>
                                                        <DateInput
                                                            type="text"
                                                            name={ASC.appointmentStartDate}
                                                            id="availabilityStartAt"
                                                            disabled={!appointmentStartDate.isEditable}
                                                            placeholder="Appointment Start Date"
                                                            onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                                                            value={formFieldValues[ASC.appointmentStartDate]}
                                                            className={
                                                                'form-control ' +
                                                                (!formErrors.some((x) => x === ASC.appointmentStartDate) ? undefined : 'is-invalid')
                                                            }
                                                        />
                                                        <div className="invalid-feedback">Please provide a valid start date.</div>
                                                    </Col>
                                                    <Col sm="2" style={{ paddingLeft: '8px', paddingTop: '6px' }}>
                                                        {appointmentStartDate && appointmentStartDate.isVisible && (
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    className="hand"
                                                                    style={{ color: '#717171' }}
                                                                    id="popCalendar"
                                                                    icon={faCalendarAlt}
                                                                    size="lg"
                                                                    onClick={() => setIsDatePickerOpen((x) => !x)}
                                                                />
                                                                <div>
                                                                    <Popover
                                                                        placement="right"
                                                                        isOpen={isDatePickerOpen}
                                                                        target="popCalendar"
                                                                        toggle={legacyToggle}
                                                                        trigger="legacy"
                                                                    >
                                                                        <Calendar
                                                                            calendarType="US"
                                                                            formatMonthYear={(locale, value) => {
                                                                                return moment(value).format('MMMM[\r\n]YYYY');
                                                                            }}
                                                                            className="pop-calendar"
                                                                            onChange={handleDatepickerChange}
                                                                            value={
                                                                                formFieldValues[ASC.appointmentStartDate] === '__/__/____' ||
                                                                                    formFieldValues[ASC.appointmentStartDate] === '' ||
                                                                                    moment(formFieldValues[ASC.appointmentStartDate], DEFAULT_DATE_FORMAT).isValid() === false
                                                                                    ? new Date()
                                                                                    : new Date(formFieldValues[ASC.appointmentStartDate]) /*controls date picker*/
                                                                            }
                                                                        />
                                                                    </Popover>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col sm="3">
                                                        <div style={{ float: 'right' }}>
                                                            <span style={{ verticalAlign: 'sub' }}>*</span> - required
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                {payorType && payorType.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="idPgmPayorType" sm="4">
                                                            {payorType ? payorType.fieldLabel.toUpperCase() : 'PAYOR TYPE'}
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.payorType}
                                                                id="idPgmPayorType"
                                                                feedback="Please select a payor type."
                                                                isDisabled={!payorType.isEditable}
                                                                maxheight={250}
                                                                placeholder="Select Payor Type"
                                                                list={payorTypeFilterOptions}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                value={formFieldValues[ASC.payorType]}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                className={
                                                                    !formErrors.some((x) => x === ASC.payorType && !formFieldValues[ASC.payorType])
                                                                        ? undefined
                                                                        : 'is-invalid'
                                                                }
                                                            />
                                                        </Col>
                                                        {payorType.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {insuranceState && insuranceState.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="idInsuranceState" sm="4">
                                                            {insuranceState ? insuranceState.fieldLabel.toUpperCase() : 'INSURANCE STATE'}
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.insuranceState}
                                                                feedback="Select a valid state for your insurance."
                                                                isDisabled={!insuranceState.isEditable}
                                                                id="idInsuranceState"
                                                                maxheight={250}
                                                                placeholder="Select Insurance State"
                                                                list={insuranceStateFilterOptions}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                value={formFieldValues[ASC.insuranceState]}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                className={
                                                                    !formErrors.some(
                                                                        (x) => x === ASC.insuranceState && !formFieldValues[ASC.insuranceState],
                                                                    )
                                                                        ? undefined
                                                                        : 'is-invalid'
                                                                }
                                                            />
                                                        </Col>
                                                        {insuranceState.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {insuranceCarrier && insuranceCarrier.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="idPgmInsuranceProvider" sm="4">
                                                            {insuranceCarrier ? insuranceCarrier.fieldLabel.toUpperCase() : 'INSURANCE CARRIER'}
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.insuranceCarrier}
                                                                feedback="Select a valid insurance for this payor type."
                                                                isDisabled={!formFieldValues[ASC.insuranceState] || !insuranceCarrier.isEditable}
                                                                id="idPgmInsuranceProvider"
                                                                maxheight={250}
                                                                placeholder="Select Insurance Carrier"
                                                                list={effectiveInsuranceList}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                value={formFieldValues[ASC.insuranceCarrier]}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                className={
                                                                    !formErrors.some(
                                                                        (x) => x === ASC.insuranceCarrier && !formFieldValues[ASC.insuranceCarrier],
                                                                    )
                                                                        ? undefined
                                                                        : 'is-invalid'
                                                                }
                                                            />
                                                        </Col>
                                                        {insuranceCarrier.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {serviceCategory && serviceCategory.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="idPgmServiceType" sm="4">
                                                            {serviceCategory ? serviceCategory.fieldLabel.toUpperCase() : 'SERVICE CATEGORY'}
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.serviceCategory}
                                                                id="idPgmServiceType"
                                                                feedback="Please select a service type."
                                                                isDisabled={!serviceCategory.isEditable}
                                                                maxheight={250}
                                                                placeholder="Select Service Category"
                                                                list={serviceTypeFilterOptions}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                value={formFieldValues[ASC.serviceCategory]}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                className={
                                                                    !formErrors.some(
                                                                        (x) => x === ASC.serviceCategory && !formFieldValues[ASC.serviceCategory],
                                                                    )
                                                                        ? undefined
                                                                        : 'is-invalid'
                                                                }
                                                            />
                                                        </Col>
                                                        {serviceCategory.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {specialty && specialty.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="idPgmSpecialty" sm="4">
                                                            {specialty ? specialty.fieldLabel.toUpperCase() : 'SPECIALTIES'}
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.specialty}
                                                                id="idPgmSpecialty"
                                                                feedback="Please select a specialty."
                                                                isDisabled={!formFieldValues[ASC.serviceCategory] || !specialty.isEditable}
                                                                maxheight={250}
                                                                placeholder="Select one or all specialties"
                                                                list={effectiveSpecialtyList}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                value={formFieldValues[ASC.specialty]}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                className={
                                                                    !formErrors.some((x) => x === ASC.specialty && !formFieldValues[ASC.specialty])
                                                                        ? undefined
                                                                        : 'is-invalid'
                                                                }
                                                            />
                                                        </Col>
                                                        {specialty.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {appointmentType && appointmentType.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="idPgmAppointmentType" sm="4">
                                                            {appointmentType ? appointmentType.fieldLabel.toUpperCase() : 'APPOINTMENT TYPE'}
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.appointmentType}
                                                                id="idPgmAppointmentType"
                                                                feedback="Please select an appointment type."
                                                                isDisabled={!appointmentType.isEditable}
                                                                /*ismulti={true}*/
                                                                maxheight={250}
                                                                placeholder="Select Appointment Type"
                                                                list={appointmentTypeFilterOptions}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                value={formFieldValues[ASC.appointmentType]}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                className={
                                                                    !formErrors.some(
                                                                        (x) => x === ASC.appointmentType && !formFieldValues[ASC.appointmentType],
                                                                    )
                                                                        ? undefined
                                                                        : 'is-invalid'
                                                                }
                                                            />
                                                        </Col>
                                                        {appointmentType.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {zipCode && zipCode.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="zipCode" sm="4">
                                                            {zipCode ? zipCode.fieldLabel.toUpperCase() : 'ZIP CODE'}
                                                        </Label>
                                                        <Col sm="3">
                                                            <div>
                                                                <ZipInputWithoutLabel
                                                                    id={ASC.zipCode}
                                                                    label="Zip Code"
                                                                    isRequired={true}
                                                                    forceInvalid={formErrors.some((x) => x === ASC.zipCode)}
                                                                    onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                                                                    value={formFieldValues[ASC.zipCode]}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm="2" className="req-asterisk">
                                                            *
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                                {searchRadius && searchRadius.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="searchRadius" sm="4">
                                                            {searchRadius ? searchRadius.fieldLabel.toUpperCase() : 'SEARCH RADIUS'}
                                                        </Label>
                                                        <Col sm="5" md="5" lg="3">
                                                            <DropdownList
                                                                name={ASC.searchRadius}
                                                                id="searchRadius"
                                                                invalid={formErrors.some((x) => x === ASC.searchRadius)}
                                                                feedback="Please select a search radius."
                                                                isDisabled={
                                                                    !formFieldValues[ASC.zipCode] ||
                                                                    formErrors.some((x) => x === ASC.zipCode) ||
                                                                    !searchRadius.isEditable
                                                                }
                                                                maxheight={250}
                                                                placeholder="Select a search radius"
                                                                list={radiusFilterOptions}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                value={formFieldValues[ASC.searchRadius]}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                className={
                                                                    !formErrors.some((x) => x === ASC.searchRadius && !formFieldValues[ASC.searchRadius])
                                                                        ? undefined
                                                                        : 'is-invalid'
                                                                }
                                                            />
                                                        </Col>
                                                        {!formErrors.some((x) => x === ASC.zipCode) && formFieldValues[ASC.zipCode] >= 5 && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {siteName && siteName.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="siteName" sm="4">
                                                            {siteName ? siteName.fieldLabel.toUpperCase() : 'SITE NAME'}
                                                        </Label>
                                                        <Col sm="3">
                                                            <div>
                                                                <Input
                                                                    disabled={!siteName.isEditable}
                                                                    type="text"
                                                                    name={ASC.siteName}
                                                                    id="siteName"
                                                                    maxLength={120}
                                                                    placeholder="Site Name"
                                                                    onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                                                                    value={formFieldValues[ASC.siteName]}
                                                                />
                                                            </div>
                                                        </Col>
                                                        {siteName.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {serviceName &&
                                                    serviceName.isVisible && ( //visibilityBehaviors.showServiceName &&
                                                        <FormGroup row>
                                                            <Label className="fw-bold form-label" for="serviceName" sm="4">
                                                                {serviceName ? serviceName.fieldLabel.toUpperCase() : 'SERVICE NAME'}
                                                            </Label>
                                                            <Col sm="3">
                                                                <div>
                                                                    <Input
                                                                        disabled={!serviceName.isEditable}
                                                                        type="text"
                                                                        name={ASC.serviceName}
                                                                        id="serviceName"
                                                                        maxLength={120}
                                                                        placeholder="Service Name"
                                                                        onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                                                                        value={formFieldValues[ASC.serviceName]}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            {serviceName.isRequired && (
                                                                <Col sm="2" className="req-asterisk">
                                                                    *
                                                                </Col>
                                                            )}
                                                        </FormGroup>
                                                    )}
                                                {providerGender && providerGender.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="gender" sm="4">
                                                            PROVIDER GENDER
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.providerGender}
                                                                id="gender"
                                                                feedback="Please select a provider gender."
                                                                isDisabled={!providerGender.isEditable}
                                                                maxheight={250}
                                                                placeholder="No preference"
                                                                list={genderFilterOptions}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                onChange={(name, e) => {
                                                                    handleFieldChange(name, e?.value);
                                                                }}
                                                                value={formFieldValues[ASC.providerGender]}
                                                            />
                                                        </Col>
                                                        {providerGender.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {language && language.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="language" sm="4">
                                                            {language ? language.fieldLabel.toUpperCase() : 'LANGUAGE PREFERENCE'}
                                                        </Label>
                                                        <Col sm="5">
                                                            <DropdownList
                                                                name={ASC.languagePreference}
                                                                id="language"
                                                                feedback="Please select a preferred language."
                                                                isDisabled={!language.isEditable}
                                                                maxheight={250}
                                                                placeholder="Show all"
                                                                list={languageFilterOptions}
                                                                listkey={'label'}
                                                                listvalue={'id'}
                                                                onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                value={formFieldValues[ASC.languagePreference]}
                                                            />
                                                        </Col>
                                                        {language.isRequired && (
                                                            <Col sm="2" className="req-asterisk">
                                                                *
                                                            </Col>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                {days && days.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="days" sm="4">
                                                            {days ? days.fieldLabel.toUpperCase() : 'DAYS'}
                                                        </Label>
                                                        <Col sm="6">
                                                            <Row>
                                                                <Col>
                                                                    {daysOfWeekFilterOptions.map((day, i) => (
                                                                        <Button
                                                                            disabled={!days.isEditable}
                                                                            className={
                                                                                formFieldValues[ASC.daysOfWeek].findIndex((x) => {
                                                                                    return x === day.id;
                                                                                }) > -1
                                                                                    ? 'filter-button'
                                                                                    : 'filter-button-inactive'
                                                                            }
                                                                            onClick={(e) => {
                                                                                handleFieldChange(ASC.daysOfWeek, day.id, 'array');
                                                                            }}
                                                                            key={i}
                                                                            outline={false}
                                                                        >
                                                                            {day.abbr}
                                                                        </Button>
                                                                    ))}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                                {timeOfDay && timeOfDay.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="timeOfDay" sm="4">
                                                            {timeOfDay ? timeOfDay.fieldLabel.toUpperCase() : 'AM/PM'}
                                                        </Label>
                                                        <Col sm="6">
                                                            <Row>
                                                                <Col>
                                                                    <Button
                                                                        disabled={!timeOfDay.isEditable}
                                                                        className={
                                                                            formFieldValues[ASC.timeOfDay].findIndex((x) => {
                                                                                return x === 'AM';
                                                                            }) > -1
                                                                                ? 'filter-button'
                                                                                : 'filter-button-inactive'
                                                                        }
                                                                        onClick={(e) => {
                                                                            handleFieldChange(ASC.timeOfDay, 'AM', 'array');
                                                                        }}
                                                                        outline={false}
                                                                    >
                                                                        AM
                                                                    </Button>
                                                                    <Button
                                                                        disabled={!timeOfDay.isEditable}
                                                                        className={
                                                                            formFieldValues[ASC.timeOfDay].findIndex((x) => {
                                                                                return x === 'PM';
                                                                            }) > -1
                                                                                ? 'filter-button'
                                                                                : 'filter-button-inactive'
                                                                        }
                                                                        onClick={(e) => {
                                                                            handleFieldChange(ASC.timeOfDay, 'PM', 'array');
                                                                        }}
                                                                        outline={false}
                                                                    >
                                                                        PM
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                                {sortOrder && sortOrder.isVisible && (
                                                    <FormGroup row>
                                                        <Label className="fw-bold form-label" for="sortOrder" sm="4">
                                                            {sortOrder ? sortOrder.fieldLabel.toUpperCase() : 'SORT ORDER'}
                                                        </Label>
                                                        <Col sm="6">
                                                            <Row>
                                                                <Col>
                                                                    <DropdownList
                                                                        name={ASC.sortOrder}
                                                                        id="sortOrder"
                                                                        feedback="Please select a sort order."
                                                                        isDisabled={!sortOrder.isEditable}
                                                                        isClearable={false}
                                                                        maxheight={250}
                                                                        placeholder="Select a sort order"
                                                                        list={sortOrderFilterOptions}
                                                                        listkey={'label'}
                                                                        listvalue={'id'}
                                                                        onChange={(name, e) => handleFieldChange(name, e?.value)}
                                                                        value={formFieldValues[ASC.sortOrder]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                                <div style={{ marginTop: '15px' }}>
                                                    <Button type="submit" color="primary" disabled={isSearchingAnything}>
                                                        {isSearchingAnything ? (
                                                            <span>
                                                                <FontAwesomeIcon icon="spinner" spin /> Searching...
                                                            </span>
                                                        ) : (
                                                            <span>Search Appointments</span>
                                                        )}
                                                    </Button>
                                                    <Button
                                                        style={{ marginLeft: '8px' }}
                                                        color="secondary"
                                                        disabled={isSearchingAnything}
                                                        onClick={(e) => {
                                                            resetForm();
                                                        }}
                                                    >
                                                        Clear Filters
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Collapse>
                    </Card>
                </div>
            </div>
        </div>
    );
}

const validateFutureDate = (value) => {
    const now = dayjs();
    const date = dayjs(value);
    return date.isValid() && date.diff(now, 'day') >= 0;
};
