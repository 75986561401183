import { createCareOrderFromDecisionSupportOuputs } from "actions/orderManagementActions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { CareOrderDetails, CareOrderTypeEnum } from "../types/CareOrderDetails";
import dayjs from "dayjs";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SaveSearchToCareOrder = () => {

    const [isSavingSearch, setIsSavingSearch] = useState<boolean>(false);

    const dispatch = useDispatch();

    const payload = useSelector(selectSearchToCareOrderDetails);
    const hasBeenSaved = useSelector((state: any) => state.session.isDecisionSupportSessionSavedToCareOrder);

    const handleSaveSearchToCareOrder = async () => {
        setIsSavingSearch(true);

        const response = await dispatch(createCareOrderFromDecisionSupportOuputs(payload));
        console.log('save DS to care order response: ', response);
        setIsSavingSearch(false);
    }

    return (
        <Button
            color="secondary"
            className="mt-2"
            onClick={handleSaveSearchToCareOrder}
            disabled={isSavingSearch || hasBeenSaved}
        >
            {hasBeenSaved
                ? "Saved"
                : isSavingSearch
                    ? <><FontAwesomeIcon icon="spinner" spin /> Saving ...</>
                    : "Save Search"
            }
        </Button>
    )
}

export default SaveSearchToCareOrder;

const selectSearchToCareOrderDetails = (state: any): CareOrderDetails => {

    return {
        id: 0,
        systemId: state.auth.referralSystemId,
        patientReferenceId: state.activePatient.id,
        userId: "00000000-0000-0000-0000-000000000000", // this is handled in api from token
        careOrderStatusReasonId: 5, // created
        idMhdD2cClientInstance: null,
        idProductInstance: state.auth.productInstanceId,
        careOrderType: CareOrderTypeEnum.PatientRequestedDecisionSupportOutput,
        decisionSupportSessionId: state.session.decisionSupportSessionId,
        expirationDate: dayjs().add(state.config.orderManagement.patientRequestedOrderExpirationLeadDays, 'days').toISOString(),
        currentFailedAuthCount: 0,
        lastFailedAuthAt: null,
        lockoutEndAt: null,
        visits: [
            {
                minVisitDate: dayjs().startOf('day').toISOString(), // these aren't nullable in the db
                maxVisitDate: dayjs('9999-12-31T23:59:59.999Z').toISOString(), // these aren't nullable in the db
                systemDateRangeId: null,
                referenceId: null,
                appointments: [
                    {
                        orderIndex: 1,
                        siteId: null,
                        serviceId: null,
                        appointmentTypeId: null,
                        specialtyId: null,
                        referringSiteId: null,
                        referringServiceId: null,
                        unmanagedReferringSiteName: "",
                        unmanagedReferringServiceName: "",
                        reasonForVisit: "",
                        notes: "",
                        externalReferralOrderId: "",
                        subgroupId: null,
                        customFields: []
                    }
                ]
            }
        ],
        customFields: [],
        consumerIdProductInstance: state.config.orderManagement.idProductInstanceConsumerCareOrder,
        externalReferralOrderId: "",
        sendNotificationsAfterCreate: false, // TODO **NEW CONFIG**
    };
}