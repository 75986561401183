import { useState } from 'react';
import { ApiState, VoidApiState } from 'types';

export function useDataApiState<T>(
    defaultLoading = true,
): [ApiState<T>, React.Dispatch<React.SetStateAction<ApiState<T>>>] {
    const defaultState: ApiState<T> = {
        loading: defaultLoading,
        data: null,
        error: null,
    };
    const [data, setData] = useState(defaultState);
    return [data, setData];
}

export function useVoidApiState(): [VoidApiState, React.Dispatch<React.SetStateAction<VoidApiState>>] {
    const defaultState: VoidApiState = {
        processing: false,
        completed: false,
        error: null,
    };
    const [data, setData] = useState(defaultState);
    return [data, setData];
}
