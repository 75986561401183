import * as React from 'react';
import PropTypes from 'prop-types';

export class ReasonForVisit extends React.Component {
	render() {
		const { characterLimit, fieldValidation, handleChange, reasonForVisit, reasonForVisitLabel } = this.props;
		return (
			<div className="form-group">
				{reasonForVisitLabel &&
					<div>{reasonForVisitLabel}</div>
				}
				<textarea maxLength={characterLimit} className="form-control" id="reasonForVisit" name="reasonForVisit" rows="7" value={reasonForVisit} onChange={(e) => { handleChange(e, 'String') }} />
				{characterLimit > 0 &&
					<div><span>{reasonForVisit.length || 0}/{characterLimit}</span></div>
				}
				{fieldValidation["reasonForVisit"].valid === false &&
					<div><span style={{ "color": "red" }}>* Please enter a reason for your visit</span></div>
				}
			</div>
		);
	}
}

export default ReasonForVisit;

ReasonForVisit.propTypes = {
	characterLimit: PropTypes.number,
	reasonForVisit: PropTypes.string,
	reasonForVisitLabel: PropTypes.string,
	fieldValidation: PropTypes.object,
	handleChange: PropTypes.func,
}
