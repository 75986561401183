/**
 * @format
 */

import React from 'react';
import './phoneNumber.css';

export const PhoneNumber = (props) => {
	const { className, label, value } = props;

	if (value === null) {
		return null;
	}

	const sanitizePhoneNumber = (phoneNumber) => {
		if (!phoneNumber) return phoneNumber;
		const cleanPhone = phoneNumber.replace(/\D/g, '');
		let match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return match[1] + '-' + match[2] + '-' + match[3];
		} else {
			return cleanPhone;
		}
	};

	const sanitizedValue = sanitizePhoneNumber(value);

	return (
		<span className="phone-number-display">
			{label ? <span className="phone-label-left label">{label}</span> : null}
			<a href={`tel:${sanitizedValue}`} className={`selectable-phone-number ${className}`}>
				{<span className="phone-number">{sanitizedValue}</span>}
			</a>
		</span>
	);
};
