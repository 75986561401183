import React from 'react';
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './modalComponent.css'

export default class ModalComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: true
		};

		this.toggle = this.toggle.bind(this);
	}

	listenKeyboard = (event) => {
		if (event.key === 'Escape' || event.keyCode === 27) {
			this.props.onClose();
		}
	};

	componentDidMount() {
		if (this.props.onClose) {
			window.addEventListener('keydown', this.listenKeyboard, true);
		}
	}

	componentWillUnmount() {
		if (this.props.onClose) {
			window.removeEventListener('keydown', this.listenKeyboard, true);
		}
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	get title() {
		const { title } = this.props;

		return title ? (
			<ModalHeader toggle={this.toggle}>{title}</ModalHeader>
		) : null;
	}

	render() {
		return (
			<div>
				<Modal isOpen={this.state.modal}
					toggle={this.toggle}
					modalClassName={this.props.modalClassName}
					contentClassName={this.props.contentClassName}
					backdrop={this.props.backdrop}
					size={this.props.size}
				>
					{this.title}
					<ModalBody className={this.props.bodyClassName}>
						{this.props.children}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.toggle}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

ModalComponent.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	modalClassName: PropTypes.string,
	contentClassName: PropTypes.string,
	bodyClassName: PropTypes.string
};

ModalComponent.defaultProps = {
	backdrop: 'static'
};
