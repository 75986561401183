import AvailabilitySearchCriteria from "./AvailabilitySearchCriteria";
import CalendarSearchCriteria from "./CalendarSearchCriteria";
import { DayOfWeek } from "./DayOfWeek";
import { IdNamePair } from "./IdNamePair";
import { PayorTypeEnum } from "./PayorTypeEnum";

export enum TimeOfDay { AM, PM }
export type Gender = string
export enum SortOrder { FirstAvailability = 1, Distance = 2, Alphabetical = 3, }
export enum SearchRequestType { Search = "search", Scan = "scan" }
export enum BatchType { Single, ByCalendarSearchGrouping }
export enum CalendarSearchGrouping { SelectedRecommended }

export type ByCalendarThenDate<type> = ByCalendar<ByDate<type>>;

export type ByCalendar<type> = {
    [calendarId: number]: type
}

export type ByDate<type> = {
    [date: string]: type;
}

export type Calendars = ByCalendar<CalendarDetails>;

export type CalendarDetails = {
    id: number,
    isPreferred: boolean,
    displayName: string,
    specialtyName: string,
    locationDisplayName: string,
    distanceInMiles: number | null,
    address: Address,
    calendarImage: string,
    gender: Gender,
    serviceProviderTypeId: number,
    serviceProviderTypeName: string,
    languages: IdNamePair[];
}

export type Address = {
    addressLine1: string;
    addressLine2: string;
    cityName: string;
    stateCode: string;
    zipCode: number;
}

export type AvailabilityResult = {
    slots: Slot[];
    searchedAt: string;
}

export type Slot = {
    id: number;
    date: string;
    startTime: string;
    endTime: string;
    appointmentTypeAbbreviation: string;
}

type ScanResult = boolean;
type InFlightSearch = boolean;
type InFlightScan = boolean;

export type AvailabilityResults = ByCalendarThenDate<AvailabilityResult>;
export type ScanResults = ByCalendarThenDate<ScanResult>;
export type InFlightSearches = ByCalendarThenDate<InFlightSearch>;
export type InFlightScans = ByCalendarThenDate<InFlightScan>

export interface AvailabilityConfig {
    displayDayCount: number;
    calendarsPerPage: number;
    scanDayCount: number;
    shouldFilterPreferredCalendars: boolean;
    shouldPagePreferredCalendars: boolean;
    shouldBatchPreferredProvidersSeperatelyForFirstScan: boolean;
    shouldSkipToFirstAvailable: boolean;
}

export interface SearchContext {
    availabilitySearchCriteria: AvailabilitySearchCriteria;
    calendarSearchCriteria: CalendarSearchCriteria;
}

export interface AvailabilityDataNeeds {
    searchDates: ByCalendar<string[]>;
    scanDates: ByCalendar<string[]>;
}

type AvailabilityScanCriteria = AvailabilitySearchCriteria;
export interface SearchRequest {
    type: SearchRequestType;
    payload: AvailabilityScanCriteria | AvailabilitySearchCriteria | CalendarSearchCriteria;
}

export interface InitializeSearchContext {
    config: AvailabilityConfig,
    searchContext: SearchContext
}

export type Filters = {
    viewBaseDate: string;

    //calendar flush
    zipcode: string | null,
    radius: string | null,
    gender: Gender | null,
    serviceCategoryId: number | null,
    payorType: PayorTypeEnum | null,
    specialtyName: string | null,

    sortOrder: SortOrder;
    services: string[],
    sites: string[],
    insuranceCarrierId: number | null,
    insuranceStateId: number | null,
    language: string | null,

    //availability flush
    dayOfWeek: DayOfWeek[] | null,
    // timeOfDay: TimeOfDay | null,
    minStartTime: string | null,
    maxStartTime: string | null,
    appointmentTypeIds: number[] | null,
    appointmentTypeModalityIds: number[] | null,
}

export type ConsequenceActionGatherer<Type> = {
    [Property in keyof Type]: (intendedUpdate: Type, currentCache: Type, key: Property) => Function[];
}

export type AxiosRequestMhd<Type> = {
    method: string,
    url: String,
    data: Type
}

export type AxiosResponseMhd<Type> = {
    data: Type
}
