import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export const PrintButton = props => {
    const {
        isDisabled,
        componentRef,
        onBeforePrintGetContent,
        onAfterPrint,
    } = props;
    
    const buttonText = props.buttonText || 'Print';
    const style = props.style || { marginLeft: '5px' };
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: onAfterPrint,
        onBeforeGetContent: onBeforePrintGetContent
    });


    return (
        <Button style={style} disabled={isDisabled} onClick={() => handlePrint()}><span>{buttonText}</span></Button>
    )
}

export default PrintButton;

PrintButton.propTypes = {
    buttonText: PropTypes.string,
    componentRef: PropTypes.object,
    isDisabled: PropTypes.bool,
    onAfterPrint: PropTypes.func,
    onBeforePrintGetContent: PropTypes.func,
}
