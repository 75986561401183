import * as types from '../actions/actionTypes';

const initialState = {
	appliedPathwaysState: {},
	workbox: {},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.APPLIEDPATHWAYS_FETCH_WORKBOX_SUCCESS:
			return {
				...state,
				appliedPathwaysState: action.payload.data.state,
				workbox: action.payload.data.workbox
			};
		case types.APPLIEDPATHWAYS_WORKFLOW_NEXT_SUCCESS:
			return {
				...state,
				workbox: action.payload.data.workbox
			};
		case types.APPLIEDPATHWAYS_WORKFLOW_PREVIOUS_SUCCESS:
			return {
				...state,
				workbox: action.payload.data.workbox
			};
		case types.APPOINTMENT_DETAILS_SUCCESS:
		case types.CLEAR_BOOKING_CONTEXT: {
				return initialState;
			}
		default:
			return state;
	}
}
