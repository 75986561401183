import * as actionTypes from './actionTypes';
import { CLOSE_PRODUCT_ALERT_BANNER } from '../constants/productAlertConfigValues';

export function getProductAlertConfig(productId) {
	return {
		type: actionTypes.GET_PRODUCT_ALERT_CONFIG,
		payload: {
			request: {
				method: 'get',
				url: `ops/productalertconfigs/${productId}`
			}
		}
	};
};

export function closeProductAlertBanner() {
	const data = {
		data: {
			isActive: false,
			wasAlertBannerClosed: true
		}
	};
	return {
		type: CLOSE_PRODUCT_ALERT_BANNER,
		payload: data,
	};
}
