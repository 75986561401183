import React from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

import CustomFieldsModal from '../modals/customFieldsModal';

export function PostBookCustomFieldsModal(props) {
	const {
		customFieldAnswers,
		customFieldValidation,
		handlePostBookCustomFieldChange,
		handlePostBookCustomFieldDateChange,
		handlePostBookCustomFieldDropdownChange,
		isOpen,
		isPostBookCustomFieldsValid,
		onAccept,
		onReject,
		postBookCustomFields,
	} = props;

	return (
		<Modal centered isOpen={isOpen} size="lg" toggle={onReject} className="postBookCustomFieldsModal">
			<ModalHeader toggle={onReject}>
			</ModalHeader>
			<ModalBody>
				{!isPostBookCustomFieldsValid &&
					<div><span style={{ "color": "red" }}>* Please review validation messages.</span></div>
				}
				<CustomFieldsModal
					customFields={postBookCustomFields}
					customFieldAnswers={customFieldAnswers}
					handleCustomFieldChange={handlePostBookCustomFieldChange}
					handleCustomFieldDropdownChange={handlePostBookCustomFieldDropdownChange}
					handleCustomFieldDateChange={handlePostBookCustomFieldDateChange}
					customFieldValidation={customFieldValidation}
				/>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={onReject}>Cancel</Button>
				<Button color="primary" disabled={!isPostBookCustomFieldsValid} onClick={onAccept}>Submit</Button>
			</ModalFooter>
		</Modal>
	);
}
