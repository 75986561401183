/**
 * @format
 */

import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import DropdownList from '../input/dropdownList';
import { TextInputWithLabel } from '../common/standardComponents';
import './referringProvider.css';

export default function ReferringProvider(props) {
	const {
		config,
		externalReferringServiceName,
		fieldValidation,
		handleChange,
		handleDropdownChange,
		internalReferringServiceId,
		isComponentValid,
		isServerTypeAheadLoading,
		handleServerTypeAheadSearch,
		internalProviderList,
		organizationProviderList,
		referringOrganizationProviderId,
		handleOrgProviderServerTypeAheadSearch,
	} = props;

	const externalReferringServiceNameMaxLength =
		fieldValidation.externalReferringServiceName.max !== null ? fieldValidation.externalReferringServiceName.max : 200;
	const internalReferringProviderLabel = config.managedReferringProviderFieldConfig.fieldLabel ?? 'Referring Provider';
	const externalReferringProviderLabel =
		config.unmanagedReferringProviderFieldConfig.fieldLabel ?? 'External Referring Provider';
	const orgReferringProviderLabel =
		config.organizationReferringProviderFieldConfig.fieldLabel ?? 'Organization Referring Provider';

	return (
		<div>
			{config.allowInternalReferringProviders && !config.enableInternalReferringProviderServerTypeAhead && (
				<Row className="provider-row">
					<Col lg="4">
						<Label className="fw-bold" for={'internalReferringServiceId'}>
							{internalReferringProviderLabel.toUpperCase()}
						</Label>
						<DropdownList
							isDisabled={false}
							name="internalReferringServiceId"
							id="internalReferringServiceId"
							maxheight={250}
							placeholder="Select Referring Physician"
							list={internalProviderList}
							listkey="displayName"
							listvalue="serviceId"
							value={internalReferringServiceId}
							onChange={(name, obj) => {
								handleDropdownChange(name, 'Int32', obj);
							}}
						/>
						{fieldValidation.internalReferringServiceId.required && (
							<small className="form-text text-muted">Required</small>
						)}
						{!fieldValidation.internalReferringServiceId.valid && (
							<div>
								<span style={{ color: 'red' }}>* Please select a referring provider</span>
							</div>
						)}
					</Col>
				</Row>
			)}
			{config.allowInternalReferringProviders && config.enableInternalReferringProviderServerTypeAhead && (
				<Row className="provider-row">
					<Col lg="4">
						<Label className="fw-bold" for={'internalReferringServiceId'}>
							{internalReferringProviderLabel.toUpperCase()}
						</Label>
						<AsyncTypeahead
							filterBy={() => true}
							id="internalReferringServiceId"
							name="internalReferringServiceId"
							isLoading={isServerTypeAheadLoading}
							labelKey="displayName"
							minLength={config.internalReferringProviderServerTypeAheadMinimumCharacters}
							onChange={(selected) => {
								var selectedObj = { value: 0 };
								if (selected && selected.length > 0) {
									selectedObj.value = selected[0].serviceId;
								}
								handleDropdownChange('internalReferringServiceId', 'Int32', selectedObj);
							}}
							onSearch={handleServerTypeAheadSearch}
							options={internalProviderList}
							placeholder="Select Referring Physician"
						/>
						{fieldValidation.internalReferringServiceId.required && (
							<small className="form-text text-muted">Required</small>
						)}
						{!fieldValidation.internalReferringServiceId.valid && (
							<div>
								<span style={{ color: 'red' }}>* Please select a referring provider</span>
							</div>
						)}
					</Col>
				</Row>
			)}
			{config.allowOrganizationReferringProviders && !config.enableInternalReferringProviderServerTypeAhead && (
				<Row className="provider-row">
					<Col lg="4">
						<Label className="fw-bold" for={'referringOrganizationProviderId'}>
							{orgReferringProviderLabel.toUpperCase()}
						</Label>
						<DropdownList
							isDisabled={false}
							name="referringOrganizationProviderId"
							id="referringOrganizationProviderId"
							maxheight={250}
							placeholder="Select Organization Referring Physician"
							list={organizationProviderList}
							listkey="displayName"
							listvalue="organizationProviderId"
							value={referringOrganizationProviderId}
							onChange={(name, obj) => {
								handleDropdownChange(name, 'Int32', obj);
							}}
						/>
						{fieldValidation.referringOrganizationProviderId.required && (
							<small className="form-text text-muted">Required</small>
						)}
						{!fieldValidation.referringOrganizationProviderId.valid && (
							<div>
								<span style={{ color: 'red' }}>* Please select a referring provider</span>
							</div>
						)}
					</Col>
				</Row>
			)}
			{config.allowOrganizationReferringProviders && config.enableInternalReferringProviderServerTypeAhead && (
				<Row className="provider-row">
					<Col lg="4">
						<Label className="fw-bold" for={'referringOrganizationProviderId'}>
							{orgReferringProviderLabel.toUpperCase()}
						</Label>
						<AsyncTypeahead
							filterBy={() => true}
							id="referringOrganizationProviderId"
							name="referringOrganizationProviderId"
							isLoading={isServerTypeAheadLoading}
							labelKey="displayName"
							minLength={config.internalReferringProviderServerTypeAheadMinimumCharacters}
							onChange={(selected) => {
								var selectedObj = { value: 0 };
								if (selected && selected.length > 0) {
									selectedObj.value = selected[0].organizationProviderId;
								}
								handleDropdownChange('referringOrganizationProviderId', 'Int32', selectedObj);
							}}
							onSearch={handleOrgProviderServerTypeAheadSearch}
							options={organizationProviderList}
							placeholder="Select Referring Physician"
						/>
						{fieldValidation.referringOrganizationProviderId.required && (
							<small className="form-text text-muted">Required</small>
						)}
						{!fieldValidation.referringOrganizationProviderId.valid && (
							<div>
								<span style={{ color: 'red' }}>* Please select a referring provider</span>
							</div>
						)}
					</Col>
				</Row>
			)}
			{config.allowExternalReferringProviders && (
				<Row className="provider-row">
					<Col lg="4">
						<TextInputWithLabel
							id="externalReferringServiceName"
							label={externalReferringProviderLabel}
							value={externalReferringServiceName}
							maxLength={externalReferringServiceNameMaxLength}
							isRequired={fieldValidation.externalReferringServiceName.required}
							onChange={(e) => {
								handleChange(e, 'String');
							}}
						/>
						{!fieldValidation.externalReferringServiceName.valid && (
							<div>
								<span style={{ color: 'red' }}>* Please enter a referring provider</span>
							</div>
						)}
					</Col>
				</Row>
			)}
			{!isComponentValid && (
				<div>
					<span style={{ color: 'red' }}>* Please select or enter a referring provider</span>
				</div>
			)}
		</div>
	);
}
