/**
 * @format
 */

import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input'

const emailRegexBuilder = () => {
	const localPartFirstCharacter = "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]";
	const localPartCharacters = "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]";
	const domain = '[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?';
	const additionalDomain = '(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)';

	return new RegExp(`^${localPartFirstCharacter}{1}${localPartCharacters}*@${domain}${additionalDomain}+$`);
};

const emailRegex = emailRegexBuilder();
const yearCodeRegex = /^\d{4}$/;
const zipCodeRegex = /^\d{5}$/;
// const phoneNumberRegex = /(\d{3})-(\d{3})-(\d{4})/;
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const validateYear = (value) => yearCodeRegex.test(value);
export const validateZipCode = (value) => zipCodeRegex.test(value);
export const validatePhoneNumber = (value) => isValidPhoneNumber(value, 'US')
export const validateEmail = (value) => emailRegex.test(value);

export const validateDate = (dateString) => {
	if (dateString && dateString.trim().length < 10) {
		return false;
	}

	if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

	var parts = dateString.split('/');
	var day = parseInt(parts[1], 10);
	var month = parseInt(parts[0], 10);
	var year = parseInt(parts[2], 10);

	if (year < 1900 || year > 3000 || month === 0 || month > 12) return false;

	var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

	return day > 0 && day <= monthLength[month - 1];
};

export function validateDateOfBirth(value) {
	let isValid = validateDate(value);
	if (!isValid) {
		return isValid;
	}
	let dateUtc = moment.utc(value);
	if (moment.utc() < dateUtc) {
		isValid = false;
	}
	return isValid;
}

export const validatePastDate = (value) => {
	const now = moment.utc();
	const dateUtc = moment.utc(value);
	return dateUtc.isValid() && dateUtc.isSameOrBefore(now, 'day');
};

export const validateFutureDate = (value) => {
	const now = moment.utc();
	const dateUtc = moment.utc(value);
	return dateUtc.isValid() && dateUtc.isSameOrAfter(now, 'day');
};

export const validateAnyDate = (value) => {
	const dateUtc = moment.utc(value);
	return dateUtc.isValid();
};

export const validateGuid = (value) => {
	let result = guidRegex.test(value);
	return result;
};

export const isInt32Valid = (value, minimum, maximum) => {
	let val = parseInt(value, 10);
	let min = parseInt(minimum, 10) || Number.MIN_VALUE;
	let max = parseInt(maximum, 10) || Number.MAX_VALUE;
	var result = false;
	if (!isNaN(value)) {
		if (val >= min && val <= max) {
			result = true;
		}
	}
	return result;
};

export const validateField = (
	name,
	type,
	value,
	minimum = null,
	maximum = null,
	required = true,
	isCustomField = false,
) => {
	let isFieldValid = true;
	switch (type.toLowerCase()) {
		case 'string':
			if (required && ((value !== null && value.trim() === '') || value === null)) {
				isFieldValid = false;
			} else {
				isFieldValid = true;
			}
			break;
		case 'email':
			let emailExp = new RegExp(
				/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
			);
			let isEmailValid = value.trim() !== '' && emailExp.test(value.trim()) ? true : false;
			isFieldValid = isEmailValid;
			break;
		case 'phone':
			let phoneExp = new RegExp(/((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/);
			let isPhoneValid = value.trim() !== '' && phoneExp.test(value.trim()) ? true : false;
			isFieldValid = isPhoneValid;
			break;
		case 'boolean':
			if (required && ((value !== null && value.trim() === '') || value === null)) {
				isFieldValid = false;
			} else {
				isFieldValid = true;
			}
			break;
		case 'dateTime':
			let dobExp = new RegExp(
				/^((((0[13578])|([13578])|(1[02]))[/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[/](([1-9])|([0-2][0-9])|(30)))|((2|02)[/](([1-9])|([0-2][0-9]))))[/]\d{4}$|^\d{4}$/,
			);
			let isDateValid = value.trim() !== '' && dobExp.test(value.trim()) ? true : false;
			isFieldValid = isDateValid;
			break;
		case 'int32':
			isFieldValid = isInt32Valid(value, minimum, maximum);
			break;
		case 'object':
			if (required && ((value !== null && value.trim() === '') || value === null)) {
				isFieldValid = false;
			} else {
				isFieldValid = true;
			}
			break;
		default:
			isFieldValid = true;
	}

	if (!required) {
		isFieldValid = true;
	}

	return isFieldValid;
};
