import React from "react";
import PropTypes from 'prop-types';

import './instanceUnavailable.css';

export default function InstanceUnavailable (props) {
    const { message } = props;

    return (
        <div className="message">
            <h4>{ message && message.trim() !== '' ? message : 'Instance Unavailable' }</h4>
        </div>
    )
}  

InstanceUnavailable.propTypes = {
    message: PropTypes.string
}