import { setActivePatient } from 'actions/rd2RefactorActions';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

const useLoadPatientDetails = (
    referenceId: string,
    shouldLoad: boolean,
    setIsPatientLoading: Function,
) => {

    const dispatch = useDispatch();
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        const isMounted = () => mountedRef.current;

        if (!shouldLoad) {
            return;
        }

        const loadPatient = async () => {
            try {
                setIsPatientLoading(true);
                await dispatch(setActivePatient(referenceId));
            } finally {
                if (isMounted()) {
                    setIsPatientLoading(false);
                }
            }
        };

        loadPatient();

    }, [dispatch, referenceId, shouldLoad, setIsPatientLoading]);
};

export default useLoadPatientDetails;