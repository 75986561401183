import { createSelector } from '@reduxjs/toolkit';
import {
    SortOrder,
} from '../types/localTypes';
import {
    greaterOfTwoDates,
    lesserOfTwoDates
} from '../../../lib/dateUtils';
import {
    generateDateArray
} from '../../../lib/misc';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from './availabilitySlice';
import mhd2Permissions from 'constants/permissions';


//#region simple selectors
export const selectInitialFilters = (state) => state.availability.initialFilters;
export const selectAppliedFilters = (state) => state.availability.appliedFilters;

export const selectSearchContext = (state) => state.availability.searchContext;

export const selectSortOrder = (state) => state.availability.appliedFilters.sortOrder;

//returns the second parameter passed in to the selector created by createSelector
//which will only be a calendarId throughout this file
export const selectCalendarIdHelper = (state, calendarId) => calendarId;

//returns the third parameter passed in to the selector created by createSelector
//which will only be a date string throughout this file
export const selectDateHelper = (state, id, date) => date;

export const selectViewBaseDate = (state) => state.availability.appliedFilters.viewBaseDate;

export const selectSortOrderBaseDate = (state) => state.availability.sortOrderBaseDate;

export const selectHasSkipAheadToDateBehaviorSettled = (state) => state.availability.hasSkipAheadToDateBehaviorSettled;

export const selectAvailability = (state) => state.availability.availability;

export const selectSupportData = (state) => state.config.availabilitySearchSupportData;

export const selectDisplayDayCount = (state) => {
    const selectedCalendarId = selectSelectedCalendarId(state);
    if (selectedCalendarId) {
        return dayjs(selectSelectedCalendarViewBaseDate(state)).endOf('month').diff(selectSelectedCalendarViewBaseDate(state), 'day') + 1;
    } else {
        return 7
    }
};

export const selectScanDayCount = (state) => state.config.availabilitySearch.availabilitySearchWindow;

export const selectShowScanNextRangeButton = (state) => state.config.availabilitySearch.showScanNextRangeButton;

export const selectPageNumber = (state) => state.availability.pageNumber;

export const selectCorrelationKey = state => state.session.correlationKey;

export const selectProductInstanceId = state => state.auth.productInstanceId;

export const selectSelectedCalendarViewBaseDate = state => state.availability.selectedCalendarViewBaseDate;

export const selectSelectedCalendarId = (state) => state.availability.selectedCalendarId;

export const selectActiveDisplayedDates = createSelector(
    [selectSelectedCalendarId, selectSelectedCalendarViewBaseDate, selectViewBaseDate, selectDisplayDayCount],
    (selectedCalendarId, selectedCalendarViewBaseDate, viewBaseDate, displayDayCount) => {
        const displayedDates = [];

        let baseDate = selectedCalendarId ? selectedCalendarViewBaseDate : viewBaseDate;

        let date = dayjs(baseDate);
        const endDate = date.add(displayDayCount - 1, 'days');

        while (date <= endDate) {
            displayedDates.push(date.format(DEFAULT_DATE_FORMAT));
            date = date.add(1, 'days');
        }
        return displayedDates;
    }
)

export const selectTargetSearchDates = createSelector(
    [selectSelectedCalendarId, selectSelectedCalendarViewBaseDate, selectViewBaseDate, selectDisplayDayCount, selectSearchContext],
    (selectedCalendarId, selectedCalendarViewBaseDate, viewBaseDate, displayDayCount, searchContext) => {
        const targetSearchDates = [];

        let baseDate = selectedCalendarId ? selectedCalendarViewBaseDate : viewBaseDate;

        let date = dayjs(baseDate);
        const endDate = lesserOfTwoDates(date.add(displayDayCount - 1, 'days'), searchContext.availabilitySearchCriteria.maxStartDate);

        while (date <= endDate) {
            targetSearchDates.push(date.format(DEFAULT_DATE_FORMAT));
            date = date.add(1, 'days');
        }
        return targetSearchDates;
    }
)

export const selectUnboundedPreviousViewBaseDate = createSelector(
    [selectViewBaseDate, selectDisplayDayCount, selectSelectedCalendarId, selectSelectedCalendarViewBaseDate],
    (viewBaseDate, displayDayCount, selectedCalendarId, selectedCalendarViewBaseDate) => {
        if (selectedCalendarId) {
            return dayjs(selectedCalendarViewBaseDate).add(-displayDayCount, 'd').format(DEFAULT_DATE_FORMAT);
        } else {
            return dayjs(viewBaseDate).add(-displayDayCount, 'd').format(DEFAULT_DATE_FORMAT);
        }
    }
)

export const selectPreviousViewBaseDate = (state) => {
    return greaterOfTwoDates(
        selectUnboundedPreviousViewBaseDate(state),
        selectContextAvailabilitySearchCriteria(state).minStartDate
    );
}

export const selectNextViewBaseDate = createSelector(
    [selectViewBaseDate, selectDisplayDayCount, selectSelectedCalendarId, selectSelectedCalendarViewBaseDate],
    (viewBaseDate, displayDayCount, selectedCalendarId, selectedCalendarViewBaseDate) => {
        if (selectedCalendarId) {
            return dayjs(selectedCalendarViewBaseDate).add(displayDayCount, 'd').format(DEFAULT_DATE_FORMAT);
        } else {
            return dayjs(viewBaseDate).add(displayDayCount, 'd').format(DEFAULT_DATE_FORMAT);
        }
    }
)

export const selectContextAvailabilitySearchCriteria = (state) => state.availability.searchContext.availabilitySearchCriteria;

export const selectAllowNextViewBaseDate = createSelector(
    [selectNextViewBaseDate, selectContextAvailabilitySearchCriteria],
    (nextViewBaseDate, contextAvailabilitySearchCriteria) => {
        if (!contextAvailabilitySearchCriteria.maxStartDate)
            return true;

        return dayjs(nextViewBaseDate).diff(contextAvailabilitySearchCriteria.maxStartDate, 'd') <= 0;
    }
)

export const selectAllowPreviousViewBaseDate = createSelector(
    [selectViewBaseDate, selectPreviousViewBaseDate, selectSelectedCalendarId, selectSelectedCalendarViewBaseDate],
    (viewBaseDate, previousViewBaseDate, selectedCalendarId, selectedCalendarViewBaseDate) => {
        return dayjs(previousViewBaseDate).diff(selectedCalendarId ? selectedCalendarViewBaseDate : viewBaseDate, 'd') < 0;
    }
);

export const selectInFlightScanLog = (state) => state.availability.inFlightScanLog;

export const selectInFlightSearchLog = (state) => state.availability.inFlightSearchLog;

export const selectIsSearchingCalendars = (state) => state.availability.isSearchingCalendars;

export const selectIsScanningAvailability = createSelector(
    [selectInFlightScanLog],
    (inFlightScanLog) => Object.keys(inFlightScanLog).length > 0
);

export const selectHasPreferredFirstScanCompleted = (state) =>
    selectHasCalendarFirstAvailabilityForSortOrderBeenScanned(state, selectFilteredPreferredCalendarIds(state));

export const selectHasNotPreferredFirstScanCompleted = (state) =>
    selectHasCalendarFirstAvailabilityForSortOrderBeenScanned(state, selectFilteredNotPreferredCalendarIds(state));

export const selectIsScanningFirstAvailabilityPreferred = createSelector(
    [selectIsScanningAvailability, selectHasPreferredFirstScanCompleted],
    (isScanning, hasFirstScanCompleted) => isScanning && !hasFirstScanCompleted
);

export const selectIsScanningFirstAvailabilityNotPreferred = createSelector(
    [selectIsScanningAvailability, selectHasNotPreferredFirstScanCompleted],
    (isScanning, hasFirstScanCompleted) => isScanning && !hasFirstScanCompleted
);

export const selectIsSearchingAvailability = createSelector(
    [selectInFlightSearchLog],
    (inFlightSearchLog) => Object.keys(inFlightSearchLog).length > 0
);

export const selectIsSearchingAnything = (state) =>
(
    selectIsSearchingCalendars(state)
    || selectIsScanningAvailability(state)
    || selectIsSearchingAvailability(state)
)

export const selectIsCalendarSearchingById = (state, id) => !!selectInFlightSearchLog(state)[id]

export const selectIsCalendarScanningById = (state, id) => !!selectInFlightScanLog(state)[id]

export const selectHasDoneFirstCalendarSearch = (state) => state.availability.hasDoneFirstCalendarSearch;

export const selectContextCalendarSearchCriteria = (state) => state.availability.searchContext.calendarSearchCriteria;

//#endregion

//#region config selectors
export const selectShouldSearchPreferredProvidersFirst = (state) => state.config.availabilitySearch.searchPreferredProvidersFirst;

export const selectShouldSkipToFirstAvailableEnabled = (state) => state.config.availabilitySearch.skipToFirstAvailability;

export const selectCalendarsPerPage = (state) => state.config.availabilitySearch.defaultPageSize;

export const selectShouldFilterPreferredCalendars = (state) => state.config.availabilitySearch.enableFilterSelectedProvider;

export const selectShouldPagePreferredCalendars = (state) => true;

export const selectShowReturnToQuestionsButton = (state) => {
    return state.config.availabilitySearch.allowReturnToDecisionSupport &&
        state.guidedResponse.flowSessionResponse?.flowSessionId?.length > 0 &&
        state.decisionSupport.endedInGuidedResponse;
}

export const selectShowSaveSearchToCareOrderButton = (state) => {
    return state.config.orderManagement.patientRequestedOrderEnabled &&
        state.session.decisionSupportSessionId &&
        state.auth.permissions?.includes(mhd2Permissions.orderManagement);
}

export const selectAvailabilitySearchFieldConfig = (state) => state.config.availabilitySearch.searchFields;

export const selectReferrerNotesById = (state, id) => selectCalendarById(state, id)?.referrefNotes;

export const selectShouldShowReferrerNotesModalById = (state, id) => {
    return selectReferrerNotesById(state, id) &&
        state.config.scheduling.enableProviderReferrerInformationPopUp;
}

export const selectAgentAvailabilityInstructions = (state) =>
    state.appointment.agentInstructions.agentAvailabilityInstructions;

export const selectShouldShowAgentInstructionsModal = (state) =>
    selectAgentAvailabilityInstructions(state);

export const selectPreReservePatientNotes = (state) =>
    state.activePatient.details.notes;

export const selectShouldShowPatientNotesModal = (state) =>
    state.config.patient.notes.isVisible &&
    selectPreReservePatientNotes(state) &&
    state.config.scheduling.enablePatientNotesPopUp;

export const selectShouldShowPreReserveModal = (state, id) =>
    selectShouldShowReferrerNotesModalById(state, id) ||
    selectShouldShowAgentInstructionsModal(state) ||
    selectShouldShowPatientNotesModal(state);

//#endregion

//#region calendar selectors
export const selectCalendars = (state) => state.availability.calendars;

export const selectCalendarList = createSelector([selectCalendars],
    (calendars) => Object.values(calendars)
)

export const selectCalendarById = createSelector(
    [selectCalendars, selectCalendarIdHelper],
    (calendars, calendarId) => calendars[calendarId]
)

export const selectPreferredCalendars = createSelector(
    [selectCalendarList],
    (calendars) => calendars.filter(calendar => calendar.isPreferred)
);
export const selectNotPreferredCalendars = createSelector(
    [selectCalendarList],
    (calendars) => calendars.filter(calendar => !calendar.isPreferred)
);

export const selectFilteredCalendars = createSelector(
    [selectCalendarList, selectAppliedFilters, selectShouldFilterPreferredCalendars, selectSupportData],
    (calendars, appliedFilters, shouldFilterPreferredCalendars, supportData) => {

        const filteredCalendars = calendars.filter(calendar => {
            return (
                (
                    !shouldFilterPreferredCalendars
                    && calendar.isPreferred
                )

                ||

                (
                    filterCalendarByRadius(calendar, appliedFilters.radius)
                    && filterCalendarByGender(calendar, appliedFilters.gender)
                    && filterCalendarByServiceCategory(calendar, appliedFilters.serviceCategoryId)
                    && filterCalendarBySpecialty(calendar, appliedFilters.specialtyId, supportData)
                    && filterCalendarByServices(calendar, appliedFilters.services)
                    && filterCalendarBySites(calendar, appliedFilters.sites)
                    && filterCalendarByServiceName(calendar, appliedFilters.serviceName)
                    && filterCalendarBySiteName(calendar, appliedFilters.siteName)
                    && filterCalendarByLanguage(calendar, appliedFilters.language)
                )
            )
        });

        return filteredCalendars;

    }
)

export const selectFilteredCalendarCount = createSelector(
    [selectFilteredCalendars],
    (calendars) => calendars.length
)

export const selectFilteredPreferredCalendars = createSelector(
    [selectFilteredCalendars],
    (calendars) => calendars.filter(calendar => calendar.isPreferred)
);
export const selectFilteredPreferredCalendarCount = createSelector(
    [selectFilteredPreferredCalendars],
    (calendars) => calendars.length
);
export const selectFilteredNotPreferredCalendars = createSelector(
    [selectFilteredCalendars],
    (calendars) => calendars.filter(calendar => !calendar.isPreferred)
);

export const calendarListToIdList = (calendars) => calendars.map(calendar => calendar.id);

export const selectScanResults = (state) => state.availability.scanResults;

export const targetScanDatesSelectorCallback = (baseDate, scanDayCount, searchContext) => {
    const endDate = lesserOfTwoDates(dayjs(baseDate).add((scanDayCount - 1), 'day').format(DEFAULT_DATE_FORMAT), searchContext.availabilitySearchCriteria.maxStartDate);
    return generateDateArray(baseDate, endDate);
}

export const selectTargetScanNextAvailabilityDates = createSelector(
    [selectViewBaseDate, selectScanDayCount, selectSearchContext],
    targetScanDatesSelectorCallback
)

export const selectTargetScanSortOrderDates = createSelector(
    [selectSortOrderBaseDate, selectScanDayCount, selectSearchContext],
    targetScanDatesSelectorCallback
)

export const getFirstAvailabilityForCalendar = (
    targetScanDates,
    scanResults,
    calendarId
) => {
    let firstAvailabilityDate = undefined;
    targetScanDates.forEach(date => {
        if (!firstAvailabilityDate && scanResults[calendarId]?.[date]) {
            firstAvailabilityDate = scanResults[calendarId]?.[date];
        }
    })
    return firstAvailabilityDate;
}

export const firstAvailabilityDateByCalendarSelectorCallback = (calendarList, targetScanDates, scanResults) => {
    let firstAvailabilityDates = {};
    calendarList.forEach(calendar => {
        firstAvailabilityDates[calendar.id] =
            getFirstAvailabilityForCalendar(targetScanDates, scanResults, calendar.id)?.date;
    })
    return firstAvailabilityDates;

}

export const firstAvailabilityStartAtUtcByCalendarSelectorCallback = (calendarList, targetScanDates, scanResults) => {
    let firstAvailabilityStartAts = {};
    calendarList.forEach(calendar => {
        firstAvailabilityStartAts[calendar.id] =
            getFirstAvailabilityForCalendar(targetScanDates, scanResults, calendar.id)?.startAtUtc;
    })
    return firstAvailabilityStartAts;
}

export const selectNextAvailabilityDateByCalendar = createSelector(
    [selectFilteredCalendars, selectTargetScanNextAvailabilityDates, selectScanResults],
    firstAvailabilityDateByCalendarSelectorCallback
)

export const selectSortOrderFirstAvailabilityDateByCalendar = createSelector(
    [selectFilteredCalendars, selectTargetScanSortOrderDates, selectScanResults],
    firstAvailabilityDateByCalendarSelectorCallback
)

export const selectSortOrderFirstAvailabilityStartAtUtcByCalendar = createSelector(
    [selectFilteredCalendars, selectTargetScanSortOrderDates, selectScanResults],
    firstAvailabilityStartAtUtcByCalendarSelectorCallback
)

export const sortCalendarsByFirstAvailability = (calendars, firstAvailabilityStartAtUtcByCalendar) => {
    const sortedCalendars = calendars.sort(sortCalendarsFirstAvailability(firstAvailabilityStartAtUtcByCalendar));

    return sortedCalendars;
}

export const selectHasCalendarFirstAvailabilityForSortOrderBeenScanned = (state, calendarIds) => {
    if (!calendarIds?.length)
        return false;

    let hasBeenScanned = true;

    calendarIds.forEach(calendarId => {
        const scanTargetSortOrderDatesWithAvailabilityCount = selectScanTargetSortOrderDatesWithAvailabilityById(state, calendarId)?.length;
        const unscannedTargetSortOrderDatesCount = selectUnscannedTargetSortOrderDatesForCalendarById(state, calendarId)?.length;

        if (
            //...if we haven't found availability
            !scanTargetSortOrderDatesWithAvailabilityCount > 0
            //and the sort order scan window hasn't been fully searched
            && unscannedTargetSortOrderDatesCount > 0
        ) {
            //we haven't finisehd first scan
            hasBeenScanned = false;
        }
    })

    return hasBeenScanned;
}

export const sortedFilteredCalendarsSelectorCallback =
    (
        calendars,
        sortOrder,
        sortOrderFirstAvailabilityStartAtUtcByCalendar,
        hasFirstScanCompleted
    ) => {

        let sortedCalendars = [];

        switch (sortOrder) {
            case SortOrder.Alphabetical:
                sortedCalendars = calendars.sort(sortCalendarsAlphabetical)
                break;
            case SortOrder.Distance:
                sortedCalendars = calendars.sort(sortCalendarsDistance(sortOrderFirstAvailabilityStartAtUtcByCalendar))
                break;
            case SortOrder.FirstAvailability:
            default:
                sortedCalendars = hasFirstScanCompleted ?
                    calendars.sort(sortCalendarsFirstAvailability(sortOrderFirstAvailabilityStartAtUtcByCalendar))
                    : []
                break;
        }

        return sortedCalendars;
    }

export const sortCalendarsFirstAvailability = (firstAvailabilityStartAtUtcByCalendar) => {
    return (a, b) => {
        if (firstAvailabilityStartAtUtcByCalendar[a.id] === firstAvailabilityStartAtUtcByCalendar[b.id]) {
            // prob should do this, but current RD2 does not so matching behavior
            // return sortCalendarsAlphabetical(a, b);
            return 0

        }

        if (!firstAvailabilityStartAtUtcByCalendar[a.id]) {
            return 1
        }
        if (!firstAvailabilityStartAtUtcByCalendar[b.id]) {
            return -1
        }

        let minDiff = dayjs(firstAvailabilityStartAtUtcByCalendar[a.id]).diff(firstAvailabilityStartAtUtcByCalendar[b.id], 'm');
        if (minDiff > 0) {
            return 1
        }
        if (minDiff < 0) {
            return -1
        }

        // return sortCalendarsAlphabetical(a, b);
        return 0;
    }
}

export const sortCalendarsAlphabetical = (a, b) => {
    return a.providerLastName.localeCompare(b.providerLastName) || a.providerFirstName?.localeCompare(b.providerFirstName);
}

export const sortCalendarsDistance = (firstAvailabilityStartAtUtcByCalendar) => {
    return (a, b) => {
        if (Number(a.distanceInMiles) < Number(b.distanceInMiles)) {
            return -1;
        }
        if (Number(a.distanceInMiles) > Number(b.distanceInMiles)) {
            return 1;
        }
        return sortCalendarsFirstAvailability(firstAvailabilityStartAtUtcByCalendar)(a, b);
    }
}

export const selectFilteredSortedPreferredCalendars = createSelector(
    [
        selectFilteredPreferredCalendars,
        selectSortOrder,
        selectSortOrderFirstAvailabilityStartAtUtcByCalendar,
        selectHasPreferredFirstScanCompleted
    ],
    sortedFilteredCalendarsSelectorCallback
)

export const selectFilteredSortedNotPreferredCalendars = createSelector(
    [
        selectFilteredNotPreferredCalendars,
        selectSortOrder,
        selectSortOrderFirstAvailabilityStartAtUtcByCalendar,
        selectHasNotPreferredFirstScanCompleted
    ],
    sortedFilteredCalendarsSelectorCallback
)

export const firstScanFirstAvailabilityDateSelectorCallback = (
    calendars,
    sortOrderFirstAvailabilityDateByCalendar,
    sortOrderFirstAvailabilityStartAtUtcByCalendar,
) => {
    const firstCalendar = sortCalendarsByFirstAvailability(calendars, sortOrderFirstAvailabilityStartAtUtcByCalendar)?.[0];
    return sortOrderFirstAvailabilityDateByCalendar[firstCalendar?.id];
}

export const selectPreferredFirstScanFirstAvailabilityDate = createSelector(
    [
        selectPreferredCalendars,
        selectSortOrderFirstAvailabilityDateByCalendar,
        selectSortOrderFirstAvailabilityStartAtUtcByCalendar,
    ],
    firstScanFirstAvailabilityDateSelectorCallback
)

export const selectNotPreferredFirstScanFirstAvailabilityDate = createSelector(
    [
        selectNotPreferredCalendars,
        selectSortOrderFirstAvailabilityDateByCalendar,
        selectSortOrderFirstAvailabilityStartAtUtcByCalendar,
    ],
    firstScanFirstAvailabilityDateSelectorCallback
)

export const selectFilteredCalendarIds = (state) => calendarListToIdList(selectFilteredCalendars(state));
export const selectFilteredPreferredCalendarIds = (state) => calendarListToIdList(selectFilteredPreferredCalendars(state));
export const selectFilteredNotPreferredCalendarIds = (state) => calendarListToIdList(selectFilteredNotPreferredCalendars(state));

export const selectDisplayedCalendars = createSelector(
    [
        selectSelectedCalendarId,
        selectFilteredSortedPreferredCalendars,
        selectFilteredSortedNotPreferredCalendars,
        selectShouldPagePreferredCalendars,
        selectPageNumber,
        selectCalendarsPerPage
    ],
    (selectedCalendarId, preferredCalendars, notPreferredCalendars, shouldPagePreferredCalendars, pageNumber, calendarsPerPage) => {
        if (selectedCalendarId) {
            return [selectedCalendarId];
        }

        let pageStart = (pageNumber - 1) * calendarsPerPage;
        let pageEnd = pageStart + calendarsPerPage;

        if (shouldPagePreferredCalendars) {
            return [...preferredCalendars, ...notPreferredCalendars].slice(pageStart, pageEnd)
        } else {
            return [...preferredCalendars, ...notPreferredCalendars.slice(pageStart, pageEnd)]
        }
    }
)

export const selectFirstShownResultNum = createSelector(
    [
        selectPageNumber,
        selectCalendarsPerPage,
    ],
    (pageNumber, calendarsPerPage) => {
        if (pageNumber === 1)
            return 1;
        else
            return ((pageNumber - 1) * calendarsPerPage + 1);
    }
)

export const selectLastShownResultNum = createSelector(
    [
        selectFilteredCalendarCount,
        selectPageNumber,
        selectCalendarsPerPage,
        selectFirstShownResultNum,
    ],
    (
        filteredCalendarCount,
        pageNumber,
        calendarsPerPage,
        firstShownResultNum,
    ) => {
        if (pageNumber === 1)
            return Math.min(calendarsPerPage, filteredCalendarCount);
        else
            return Math.min(firstShownResultNum + calendarsPerPage - 1, filteredCalendarCount);
    }
)

export const selectPreferredDisplayedCalendars = createSelector(
    [selectDisplayedCalendars],
    (displayedCalendars) => {
        return displayedCalendars.filter(calendar => calendar.isPreferred);
    }
)

export const selectNotPreferredDisplayedCalendars = createSelector(
    [selectDisplayedCalendars],
    (displayedCalendars) => {
        return displayedCalendars.filter(calendar => !calendar.isPreferred);
    }
)

export const selectDisplayedCalendarIds = (state) => calendarListToIdList(selectDisplayedCalendars(state));
export const selectPreferredDisplayedCalendarIds = (state) => calendarListToIdList(selectPreferredDisplayedCalendars(state));
export const selectNotPreferredDisplayedCalendarIds = (state) => calendarListToIdList(selectNotPreferredDisplayedCalendars(state));

export const selectPagableCalendarCount = createSelector(
    [selectFilteredCalendars, selectFilteredNotPreferredCalendars, selectShouldPagePreferredCalendars],
    (filteredCalendars, filteredNotPreferredCalendars, shouldPagePreferredCalendars) => {
        if (shouldPagePreferredCalendars) {
            return filteredCalendars.length;
        }
        else {
            return filteredNotPreferredCalendars.length;
        }
    }
)

export const selectNumberOfPages = createSelector(
    [selectPagableCalendarCount, selectCalendarsPerPage],
    (calendarCountForPaging, calendarsPerPage) => {
        return Math.ceil(
            ((calendarCountForPaging || 1) / calendarsPerPage)
        )
    }
)

export const selectPageablePreferredCalendarCount = createSelector(
    [
        selectFilteredPreferredCalendars,
        selectShouldPagePreferredCalendars,
        selectCalendarsPerPage

    ],
    (
        filteredPreferredCalendars,
        shouldPagePreferredCalendars,
        calendarsPerPage,
    ) => {

        if (shouldPagePreferredCalendars) {
            return Math.min(
                calendarsPerPage,
                filteredPreferredCalendars.length
            )
        } else {
            return filteredPreferredCalendars.length;
        }

    }
)

export const selectPageableNotPreferredCalendarCount = createSelector(
    [selectFilteredPreferredCalendars, selectFilteredNotPreferredCalendars, selectShouldPagePreferredCalendars, selectCalendarsPerPage],
    (filteredPreferredCalendars, filteredNotPreferredCalendars, shouldPagePreferredCalendars, calendarsPerPage) => {

        let upperLimit = shouldPagePreferredCalendars ?
            Math.max(calendarsPerPage - filteredPreferredCalendars.length, 0)
            : calendarsPerPage;

        return Math.min(
            upperLimit,
            filteredNotPreferredCalendars.length
        )
    }
)

export const selectSuppressCalendarTelephoneById = (state, id) => !state.config.provider?.phone?.isVisible || selectCalendarById(state, id)?.suppressTelephone;

export const selectAllowRequestAppointmentById = (state, id) =>
    state.config.scheduling.enableRequestAppointment
    && selectCalendarById(state, id)?.isAppointmentRequestEnabled;

export const selectAllowRequestAppointmentWithAvailabilityById = (state, id) =>
    selectAllowRequestAppointmentById(state, id)
    && selectCalendarById(state, id)?.isAppointmentRequestWithAvailabilityEnabled;

export const selectAllowRequestAppointmentWithImmediateAvailabilityById = (state, id) =>
    selectAllowRequestAppointmentWithAvailabilityById(state, id)
    && selectCalendarById(state, id)?.isAppointmentRequestWithImmediateAvailabilityEnabled;
//#endregion

//#region availability selectors
export const scanTargetDatesWithAvailabilityByIdSelectorCallback = (targetDates, scanResults, calendarId) => {
    return targetDates.filter(date => !!scanResults[calendarId]?.[date]);
}

export const selectScanTargetNextAvailabilityDatesWithAvailabilityById = createSelector(
    [selectTargetScanNextAvailabilityDates, selectScanResults, selectCalendarIdHelper],
    scanTargetDatesWithAvailabilityByIdSelectorCallback
)

export const selectScanTargetSortOrderDatesWithAvailabilityById = createSelector(
    [selectTargetScanSortOrderDates, selectScanResults, selectCalendarIdHelper],
    scanTargetDatesWithAvailabilityByIdSelectorCallback
)

export const notScanningOrSearchingTargetDatesForCalendarByIdSelectorCallback = (dates, inFlightLog, calendarId) => {
    return dates.filter(date => {
        return inFlightLog[calendarId]?.[date] === undefined
    })
}

export const selectNotScanningTargetNextAvailabilityDatesForCalendarById = createSelector(
    [
        selectTargetScanNextAvailabilityDates,
        selectInFlightScanLog,
        selectCalendarIdHelper
    ],
    notScanningOrSearchingTargetDatesForCalendarByIdSelectorCallback
)

export const selectNotScanningTargetSortOrderDatesForCalendarById = createSelector(
    [
        selectTargetScanSortOrderDates,
        selectInFlightScanLog,
        selectCalendarIdHelper
    ],
    notScanningOrSearchingTargetDatesForCalendarByIdSelectorCallback
)

export const selectNotSearchingDatesForCalendarById = createSelector(
    [
        selectTargetSearchDates,
        selectInFlightSearchLog,
        selectCalendarIdHelper
    ],
    notScanningOrSearchingTargetDatesForCalendarByIdSelectorCallback
)

export const unscannedOrUnsearchedTargetDatesForCalendarByIdSelectorCallback = (dates, results, calendarId) => {
    return dates.filter(date => {
        return results[calendarId]?.[date] === undefined
    })
}

export const selectUnscannedTargetNextAvailabilityDatesForCalendarById = createSelector(
    [
        selectTargetScanNextAvailabilityDates,
        selectScanResults,
        selectCalendarIdHelper
    ],
    unscannedOrUnsearchedTargetDatesForCalendarByIdSelectorCallback
)

export const selectUnscannedTargetSortOrderDatesForCalendarById = createSelector(
    [
        selectTargetScanSortOrderDates,
        selectScanResults,
        selectCalendarIdHelper
    ],
    unscannedOrUnsearchedTargetDatesForCalendarByIdSelectorCallback
)

export const selectUnsearchedDatesForCalendarById = createSelector(
    [
        selectTargetSearchDates,
        selectAvailability,
        selectCalendarIdHelper
    ],
    unscannedOrUnsearchedTargetDatesForCalendarByIdSelectorCallback
)

export const datesNeedingScannedForCalendarByIdSelectorCallback = (unscannedDates, notScanningDates, datesWithAvailability) => {
    return unscannedDates.filter(date => {
        return notScanningDates.includes(date)
            && datesWithAvailability?.[0] ? dayjs(date).diff(datesWithAvailability[0], 'd') < 0 : true;
    })
}

export const selectNextAvailabilityDatesNeedingScannedForCalendarById = createSelector(
    [
        selectUnscannedTargetNextAvailabilityDatesForCalendarById,
        selectNotScanningTargetNextAvailabilityDatesForCalendarById,
        selectScanTargetNextAvailabilityDatesWithAvailabilityById
    ],
    datesNeedingScannedForCalendarByIdSelectorCallback
)

export const selectSortOrderDatesNeedingScannedForCalendarById = createSelector(
    [
        selectUnscannedTargetSortOrderDatesForCalendarById,
        selectNotScanningTargetSortOrderDatesForCalendarById,
        selectScanTargetSortOrderDatesWithAvailabilityById
    ],
    datesNeedingScannedForCalendarByIdSelectorCallback
)

//first from sortOrderBaseDate
export const selectFirstAvailabilityDateByCalendarId = createSelector(
    [selectSortOrderFirstAvailabilityDateByCalendar, selectCalendarIdHelper],
    (scanResultsAllCalendars, calendarId) => {
        return scanResultsAllCalendars[calendarId]
    }
)

//first from viewBaseDate
export const selectNextAvailabilityDateByCalendarId = createSelector(
    [selectNextAvailabilityDateByCalendar, selectCalendarIdHelper],
    (scanResultsAllCalendars, calendarId) => {
        return scanResultsAllCalendars[calendarId]
    }
)

export const selectCalendarAvailabilityById = (state, id) => selectAvailability(state)[id];

export const selectSelectedCalendarAvailability = (state, id) => {
    let slots = [];

    const calendarAvailabilityByDate = selectCalendarAvailabilityById(state, id);

    selectActiveDisplayedDates(state).forEach((date) => {
        if (calendarAvailabilityByDate?.[date]?.length) {
            slots = slots.concat(calendarAvailabilityByDate[date]);
        }
    })

    return slots;
}

//This creates a unique selector instance to cache by calendarId/date
//https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
export const createCalendarDateAvailabilitySelector = () => {
    const selectCalendarDateAvailability = createSelector(
        [selectCalendarAvailabilityById, selectDateHelper],
        (calendarAvailability, date) => {
            const calendarAvailabilityDay = calendarAvailability?.[date] ?? [];
            return calendarAvailabilityDay;
        }
    )

    return selectCalendarDateAvailability;
}

export const selectFirstDisplayableDateWithSlotForCalendarById = (state, id) => {
    let firstDisplayableSlotDateForCalendar = '';
    selectActiveDisplayedDates(state).forEach(date => {
        if (!firstDisplayableSlotDateForCalendar && selectCalendarAvailabilityById(state, id)?.[date]?.length > 0) {
            firstDisplayableSlotDateForCalendar = date;
        }
    })
    return firstDisplayableSlotDateForCalendar;
}

//#endregion

//#region search criteria selectors
export const selectEffectiveCalendarSearchCriteria = createSelector(
    [selectContextCalendarSearchCriteria, selectAppliedFilters, selectCorrelationKey, selectProductInstanceId],
    (contextCalendarSearchCriteria, appliedFilters, correlationKey, productInstanceId) => {
        return {
            ...contextCalendarSearchCriteria,
            correlationKey,
            productInstanceId,
            distanceFilter: {
                ...contextCalendarSearchCriteria.distanceFilter,
                zipCode: appliedFilters.zipCode ?? contextCalendarSearchCriteria.distanceFilter.zipCode,
                maxDistanceInMiles: appliedFilters.radius ?? contextCalendarSearchCriteria.distanceFilter.maxDistanceInMiles,
            },
            identityCriteria: {
                ...contextCalendarSearchCriteria.identityCriteria,
                // serviceIdentities: appliedFilters?.services?.length ?
                //   appliedFilters.services.map(
                //     serviceId => new IdType({ id: String(serviceId), type: 'serviceId' })
                //   ) : [],
                // siteIdentities: appliedFilters?.sites?.length ?
                //   appliedFilters.sites.map(
                //     siteId => new IdType({ id: String(siteId), type: 'siteId' })
                //   ) : [],
            },
            patientCriteria: {
                ...contextCalendarSearchCriteria.patientCriteria,
                payorType: appliedFilters.payorType ?? contextCalendarSearchCriteria.patientCriteria.payorType,
                insuranceProviderId: appliedFilters.insuranceCarrierId ?? contextCalendarSearchCriteria.patientCriteria.insuranceProviderId,

            },
            providerCriteria: {
                ...contextCalendarSearchCriteria.providerCriteria,
                languageName: appliedFilters.language?.length ? appliedFilters.language : contextCalendarSearchCriteria.providerCriteria.languageName,
                genderCode: appliedFilters.gender ?? contextCalendarSearchCriteria.providerCriteria.genderCode,
                serviceProviderTypeId: appliedFilters.serviceCategoryId ?? contextCalendarSearchCriteria.providerCriteria.serviceProviderTypeId,
                specialtyId: appliedFilters.specialtyId ?? contextCalendarSearchCriteria.providerCriteria.specialtyId,
            }
        }
    }
)

export const selectEffectiveAvailabilitySearchCriteria = createSelector(
    [selectContextAvailabilitySearchCriteria, selectAppliedFilters, selectCorrelationKey, selectProductInstanceId],
    (contextAvailabilitySearchCriteria, appliedFilters, correlationKey, productInstanceId) => {
        return {
            ...contextAvailabilitySearchCriteria,
            correlationKey,
            productInstanceId,
            returnAllCalendars: false,
            returnAllAppointmentTypes: !appliedFilters.appointmentTypeIds?.length && !contextAvailabilitySearchCriteria.appointmentTypeIds.length,
            filterContextRefId: appliedFilters.availabilityValidRefId,
            appointmentTypeIds: appliedFilters.appointmentTypeIds?.length
                ? appliedFilters.appointmentTypeIds
                : contextAvailabilitySearchCriteria.appointmentTypeIds,
            appointmentModalityIds: appliedFilters.appointmentTypeModalityIds?.length
                ? appliedFilters.appointmentTypeModalityIds
                : contextAvailabilitySearchCriteria.appointmentModalityIds,
            daysOfWeek: appliedFilters.daysOfWeek?.length ? appliedFilters.daysOfWeek : contextAvailabilitySearchCriteria.daysOfWeek ?? [],
            returnAllDays: !appliedFilters.daysOfWeek?.length,
            insuranceProviderId: appliedFilters.insuranceCarrierId ? appliedFilters.insuranceCarrierId : contextAvailabilitySearchCriteria.insuranceProviderId,

            minStartTime: appliedFilters.minStartTime ?? contextAvailabilitySearchCriteria.minStartTime,
            maxStartTime: appliedFilters.maxStartTime ?? contextAvailabilitySearchCriteria.maxStartTime,
        }
    }
)
//#endregion

//#region option lists
export const selectServiceFilterOptions = createSelector(
    [selectCalendarList],
    (calendars) => {
        return [...new Set(calendars.map(x => x.displayName))].map(x => ({ id: x, label: x }));
    }
)

export const selectSiteFilterOptions = createSelector(
    [selectCalendarList],
    (calendars) => {
        return [...new Set(calendars.map(x => x.locationName))]
            .sort((a, b) => a.localeCompare(b))
            .map(x => ({ id: x, label: x }));
    }
)

export const selectServiceTypeFilterOptions = createSelector(
    [selectSupportData],
    (supportData) => {
        const serviceTypes = supportData.serviceTypeList.map((x) => {
            return {
                id: x.idPgmServiceType,
                label: x.pgmServiceTypeName,
            };
        });

        return serviceTypes;
    }
)

export const selectLanguageFilterOptions = createSelector(
    [selectCalendarList, selectSupportData],
    (calendars, supportData) => {
        let calendarLanguages = [];
        calendars.forEach((calendar) => {
            calendarLanguages = calendarLanguages.concat(calendar.languages);
        });
        const uniqueCalendarLanguages = [...new Set(calendarLanguages)];

        let languageList = supportData.languageList.map(x => ({ id: x.idPgmLanguage, label: x.language }));

        if (uniqueCalendarLanguages?.length > 0) {
            languageList = languageList.filter((x) => uniqueCalendarLanguages.some((y) => y.id === x.id));
        }

        return languageList;
    }

)

export const selectAppointmentTypeFilterOptions = createSelector(
    [selectContextAvailabilitySearchCriteria, selectSupportData],
    (contextAvailabilitySearchCriteria, supportData) => {
        let appointmentTypes = contextAvailabilitySearchCriteria.appointmentTypeIds.length
            ? supportData.appointmentTypeList
                .filter((x) => contextAvailabilitySearchCriteria.appointmentTypeIds.includes(x.idPgmAppointmentType))
                .sort((a, b) => a.pgmAppointmentTypeName.localeCompare(b.pgmAppointmentTypeName))
                .map((x) => {
                    return {
                        id: x.idPgmAppointmentType,
                        label: x.pgmAppointmentTypeName,
                        pgmAppointmentTypeAbbr: x.pgmAppointmentTypeAbbr,
                        idPgmAppointmentModality: x.idPgmAppointmentModality,
                        pgmAppointmentModalityName: x.pgmAppointmentModalityName,
                    };
                })
            : supportData.appointmentTypeList.map((x) => {
                return {
                    id: x.idPgmAppointmentType,
                    label: x.pgmAppointmentTypeName,
                    pgmAppointmentTypeAbbr: x.pgmAppointmentTypeAbbr,
                    idPgmAppointmentModality: x.idPgmAppointmentModality,
                    pgmAppointmentModalityName: x.pgmAppointmentModalityName,
                };
            });

        // contextAvailabilitySearchCriteria.appointmentTypeIds.forEach(appointmentTypeId => {
        //   if (appointmentTypes.findIndex(x => x.id === appointmentTypeId) < 0) {
        //     const appointmentType = supportData.appointmentTypeList.find(appointmentTypeData => appointmentTypeData.idPgmAppointmentType === appointmentTypeId)
        //     if (appointmentType !== undefined)
        //       appointmentTypes.push({ id: appointmentType?.idPgmAppointmentType, label: appointmentType?.pgmAppointmentTypeName });
        //   }
        // })

        return appointmentTypes;
    }
)

export const selectInsuranceStateFilterOptions = createSelector(
    [selectSupportData],
    (supportData) => {
        let states = [];

        states = supportData.stateList.map(state => { return { id: state.idPgmState, label: state.state } })

        return states;
    }
)

export const selectInsuranceCarrierFilterOptions = createSelector(
    [selectSupportData],
    (supportData) => {
        let insuranceCarriers = [];

        insuranceCarriers = supportData.insuranceTypeList.map(
            x => {
                return { id: x.idPgmInsuranceProvider, label: x.pgmInsuranceProviderName, stateId: x.idPgmState }
            }
        )

        return insuranceCarriers;
    }
)

export const selectAppointmentTypeModalityFilterOptions = createSelector(
    [selectAppointmentTypeFilterOptions],
    (appointmentTypeFilterOptions) => {
        const appointmentTypeModalities = [];

        appointmentTypeFilterOptions.forEach(appointmentType => {
            if (appointmentTypeModalities.findIndex(x => x.id === appointmentType.idPgmAppointmentModality) < 0) {
                appointmentTypeModalities.push({ id: appointmentType?.idPgmAppointmentModality, label: appointmentType?.pgmAppointmentModalityName });
            }
        })

        return appointmentTypeModalities;
    }
)

export const selectSpecialtyFilterOptions = createSelector(
    [selectSupportData],
    (supportData) => {
        return supportData.specialtyList.map((x) => {
            return {
                id: x.idPgmSpecialty,
                label: x.pgmSpecialtyName,
                idPgmServiceType: x.idPgmServiceType,
            };
        });
    }
)

export const selectSortOrderFilterOptions = createSelector(
    [selectSupportData],
    (supportData) => {
        return supportData.sortOrderList.map(x => ({ id: x.availabilitySortOrderId, label: x.displayName }))
    }
)

export const selectPayorTypeFilterOptions = createSelector(
    [selectSupportData],
    (supportData) => {
        return supportData.payorTypeList.map(x => ({ id: x.idPgmPayorType, label: x.pgmPayorTypeName, usesInsurance: x.usesInsurance }))
    }
)

export const selectRadiusFilterOptions = createSelector(
    [selectSupportData],
    (supportData) => {
        return supportData.radiusMileList.map(x => ({ id: Number(x.radiusMiles), label: x.radiusMiles }))
    }
)

export const selectGenderFilterOptions = (state) => [
    { id: 'F', label: 'Female' },
    { id: 'M', label: 'Male' }
]

export const selectDaysOfWeekFilterOptions = (state) => {
    return [
        { id: 0, name: "Sunday", abbr: "Sun" },
        { id: 1, name: "Monday", abbr: "Mon" },
        { id: 2, name: "Tuesday", abbr: "Tue" },
        { id: 3, name: "Wednesday", abbr: "Wed" },
        { id: 4, name: "Thursday", abbr: "Thurs" },
        { id: 5, name: "Friday", abbr: "Fri" },
        { id: 6, name: "Saturday", abbr: "Sat" },
    ]
}
//#endregion

//#region calendar filters
const filterCalendarByRadius = (calendar, radius) =>
    !radius || !calendar.distanceInMiles || (Number(calendar.distanceInMiles) <= Number(radius));

const filterCalendarByGender = (calendar, gender) =>
    !gender || calendar.genderCode === gender;

const filterCalendarByServiceCategory = (calendar, serviceCategoryId) =>
    !serviceCategoryId || calendar.serviceProviderTypeId === serviceCategoryId;

const filterCalendarBySpecialty = (calendar, specialtyId, supportData) =>
    !specialtyId || calendar.specialtyName === supportData.specialtyList.find(x => x.idPgmSpecialty === specialtyId)?.pgmSpecialtyName;

const filterCalendarByServices = (calendar, services) =>
    services.length === 0 || services.indexOf(calendar.displayName) >= 0;

const filterCalendarBySites = (calendar, sites) =>
    sites.length === 0 || sites.indexOf(calendar.locationName) >= 0;

const filterCalendarByServiceName = (calendar, serviceName) =>
    !serviceName || calendar.displayName.toLowerCase().indexOf(serviceName.toLowerCase()) !== -1;

const filterCalendarBySiteName = (calendar, siteName) =>
    !siteName || calendar.locationName.toLowerCase().indexOf(siteName.toLowerCase()) !== -1;

const filterCalendarByLanguage = (calendar, languageId) => {
    return !languageId || calendar.languages.findIndex(lan => lan.id === languageId) >= 0;
}
//#endregion