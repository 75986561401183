import React, { useState, useRef, useEffect } from "react";

const CountDownTimer = (props) => {
	const Ref = useRef(null);

	const [timer, setTimer] = useState("00:00");

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		return {
			total,
			minutes,
			seconds,
		};
	};

	const startTimer = (e) => {
		let { total, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {
			// update the timer
			// check if less than 10 then we need to
			// add '0' at the beginning of the variable
			setTimer(
				(minutes > 9 ?
					minutes
					: "0" + minutes) +
				":" +
				(seconds > 9 ? seconds : "0" + seconds)
			);
		}
	};

	const clearTimer = (e) => {
		if (Ref.current) clearInterval(Ref.current);
		const id = setInterval(() => {
			let { total } = getTimeRemaining(e);
			if (total >= 0) {
				startTimer(e);
			}
			else {
				clearInterval(Ref.current);
				props.countDownExpired();
			}
		}, 1000);
		Ref.current = id;
	};

	const getCountDownTime = () => {
		let deadline = new Date(props.countDownExpirationTime);
		return deadline;
	};

	useEffect(() => {
		clearTimer(getCountDownTime());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>{timer}</span>
		</div>
	);
};

export default CountDownTimer;
