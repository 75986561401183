/**
 * @format
 */

import * as actionTypes from './actionTypes';

export function processDecisionSupportSubpoints(subpoints, endedInGuidedResponse) {
	return function (dispatch) {
		dispatch(processDecisionSupportSubpointsAction(subpoints, endedInGuidedResponse));
		return Promise.resolve();
	};
}

function processDecisionSupportSubpointsAction(subpoints, endedInGuidedResponse) {
	return {
		type: actionTypes.PROCESS_DECISION_SUPPORT_SUBPOINTS,
		subpoints: subpoints,
		endedInGuidedResponse: endedInGuidedResponse
	};
}
