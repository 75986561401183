/**
 * @format
 */

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'reactstrap';
import Divider from '@mui/material/Divider';
import { TextInputWithLabel, DateInputWithLabel, PhoneInputWithLabel } from '../common/standardComponents';
import { validateDateOfBirth } from '../../lib/validation';
import './patientSearchForm.css';

export default function PatientSearchForm(props) {
	const {
		firstName,
		lastName,
		dateOfBirth,
		memberId,
		identityValue,
		onChange,
		onSubmit,
		shouldValidate,
		fieldConfig,
		phoneForceInvalid,
	} = props;

	const firstNameRequired = fieldConfig.firstName.isRequired;
	const lastNameRequired = fieldConfig.lastName.isRequired;
	const dateOfBirthRequired = fieldConfig.dateOfBirth.isRequired;
	const usingPatientDetails = firstName !== '' || lastName !== '' || dateOfBirth !== '';
	const usingMemberId = memberId !== '';
	const usingIdentityValue = identityValue !== '';
	const phoneIsRequired =
		!props.enablePatientSearchByDemographics &&
		!props.enablePatientSearchByIdentity &&
		!props.enablePatientSearchByMemberId;

	return (
		<div>
			<div className="col-lg-12 patient-criteria-content">
				<Form onSubmit={onSubmit}>
					<div className="row">
						{props.enablePatientSearchByDemographics && (
							<div className="col-sm">
								<div className="col-6">
									<TextInputWithLabel
										shouldValidate={shouldValidate}
										isRequired={usingPatientDetails && firstNameRequired}
										disabled={usingMemberId || usingIdentityValue}
										id="firstName"
										label="First Name"
										value={props.firstName}
										maxLength={50}
										onChange={onChange}
									/>
									<TextInputWithLabel
										shouldValidate={shouldValidate}
										isRequired={usingPatientDetails && lastNameRequired}
										disabled={usingMemberId || usingIdentityValue}
										id="lastName"
										label="Last Name"
										value={props.lastName}
										maxLength={50}
										onChange={onChange}
									/>
									<DateInputWithLabel
										validator={validateDateOfBirth}
										shouldValidate={shouldValidate}
										isRequired={usingPatientDetails && dateOfBirthRequired}
										disabled={usingMemberId || usingIdentityValue}
										id="dateOfBirth"
										label="Date of Birth"
										value={props.dateOfBirth}
										onChange={onChange}
									/>
									{props.enablePatientSearchByPhone && (
										<PhoneInputWithLabel
											shouldValidate={shouldValidate}
											forcedInvalidMessage="Please provide a valid phone number"
											isRequired={phoneIsRequired}
											disabled={usingMemberId || usingIdentityValue}
											id="phoneNumber"
											label="Phone Number"
											value={props.phoneNumber}
											forceInvalid={phoneForceInvalid}
											onChange={onChange}
										/>
									)}
								</div>
							</div>
						)}
						{!props.enablePatientSearchByDemographics && props.enablePatientSearchByPhone && (
							<div className="col-sm">
								<div className="col-6">
									<PhoneInputWithLabel
										shouldValidate={shouldValidate}
										isRequired={phoneIsRequired}
										disabled={usingMemberId || usingIdentityValue}
										id="phoneNumber"
										label="Phone Number"
										value={props.phoneNumber}
										forceInvalid={phoneForceInvalid}
										onChange={onChange}
									/>
								</div>
							</div>
						)}
						{props.enablePatientSearchByMemberId && props.enablePatientSearchByDemographics && (
							<div className="col-1">
								<Divider orientation="vertical" className="verticalDivider" style={{ borderLeft: "2px" }}>Or</Divider>
							</div>
						)}
						{props.enablePatientSearchByMemberId && (
							<div className="col-sm">
								<div className="col-6">
									<TextInputWithLabel
										shouldValidate={shouldValidate}
										isRequired={usingMemberId}
										disabled={usingPatientDetails || usingIdentityValue}
										id="memberId"
										label={props.memberIdFieldLabel || 'Member ID'}
										value={props.memberId}
										maxLength={props.memberIdMaxLength}
										onChange={onChange}
									/>
								</div>
							</div>
						)}
						{props.enablePatientSearchByIdentity &&
							(props.enablePatientSearchByMemberId || props.enablePatientSearchByDemographics) && (
								<div className="col-1">
									<Divider orientation="vertical" style={{ borderLeft: "2px" }}>Or</Divider>
								</div>

							)}
						{props.enablePatientSearchByIdentity && (
							<div className="col-sm">
								<div className="col-6">
									<TextInputWithLabel
										shouldValidate={shouldValidate}
										isRequired={usingIdentityValue}
										disabled={usingPatientDetails || usingMemberId}
										id="identityValue"
										label={props.patientIdentityFieldLabelOverride || 'Other Patient ID'}
										value={props.identityValue}
										onChange={onChange}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="col-sm patient-search-form-footer">
						<Button color="primary" type="submit" disabled={props.isSearching}>
							{props.isSearching ? (
								<span>
									<FontAwesomeIcon icon="spinner" spin /> Searching...
								</span>
							) : (
								<span>Search Patient</span>
							)}
						</Button>
						<Button
							color="secondary"
							style={{ marginLeft: '8px' }}
							disabled={props.isSearching}
							onClick={props.clearSearchCriteria}
						>
							Reset
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
}
