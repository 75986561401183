import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthWrapper from '../components/common/authWrapper';
import { NavLink as RRNavLink } from 'react-router-dom'
import {
	Navbar,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import './NavMenu.css';

export class NavMenu extends Component {
	render() {
		const { menuItems } = this.props;

		let renderMenuItems = menuItems.map((item, key) => {
			let menuProps = {};
			if (item.requiresActivePatient) {
				menuProps.requiresActivePatient = item.requiresActivePatient;
			}

			return (
				<AuthWrapper key={key} requiredPermission={item.requiredPermission} isMenuItem={true} {...menuProps}>
					<NavItem>
						<NavLink activeClassName='active' tag={RRNavLink} to={item.action}>
							<h5 className="navItemText">{item.name}</h5>
						</NavLink>
					</NavItem>
				</AuthWrapper>
			);
		});
		return (
			<Navbar expand="md" className="navbar-dark nav-menu row">
				<Nav navbar>
					{renderMenuItems}
				</Nav>
			</Navbar>
		);
	}
}

NavMenu.propTypes = {
	auth: PropTypes.object.isRequired,
};

export default NavMenu;
