import { useSelector } from "react-redux";
import {
    selectFilteredCalendarCount,
    selectHasDoneFirstCalendarSearch
} from "../availabilitySlice"

export default function ResultsContainer(props) {

    const calendarCount = useSelector(selectFilteredCalendarCount);
    const hasSearchedCalendars = useSelector(selectHasDoneFirstCalendarSearch);

    return (
        <div className="content-container">
            {hasSearchedCalendars && !calendarCount && (
                <h4 className="availability-result-header">No providers found!</h4>
            )}
            {!!calendarCount && (
                <div>
                    <div className="content-container">
                        {props.children}
                    </div>
                </div>
            )}
        </div>
    )
}