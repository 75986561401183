export const CARE_ORDER_FIELD_GROUP = 'RD2.CareOrderFieldConfiguration';
export const CARE_ORDER_FIELD_STATUS = `${CARE_ORDER_FIELD_GROUP}.Status`;
export const CARE_ORDER_FIELD_STATUS_REASON = `${CARE_ORDER_FIELD_GROUP}.StatusReason`;
export const CARE_ORDER_FIELD_CARE_ORDER_TYPE = `${CARE_ORDER_FIELD_GROUP}.CareOrderType`;
//export const CARE_ORDER_FIELD_CREATED_DATE = `${CARE_ORDER_FIELD_GROUP}.CreatedDate`;
export const CARE_ORDER_FIELD_ORDER_DATE = `${CARE_ORDER_FIELD_GROUP}.OrderDate`;
export const CARE_ORDER_FIELD_EXPIRATION_DATE = `${CARE_ORDER_FIELD_GROUP}.ExpirationDate`;
export const CARE_ORDER_FIELD_MIN_DATE = `${CARE_ORDER_FIELD_GROUP}.MinDate`;
export const CARE_ORDER_FIELD_MAX_DATE = `${CARE_ORDER_FIELD_GROUP}.MaxDate`;
export const CARE_ORDER_FIELD_SITE = `${CARE_ORDER_FIELD_GROUP}.Site`;
export const CARE_ORDER_FIELD_SERVICE = `${CARE_ORDER_FIELD_GROUP}.Service`;
export const CARE_ORDER_FIELD_REFERRING_SITE = `${CARE_ORDER_FIELD_GROUP}.ReferringSite`;
export const CARE_ORDER_FIELD_REFERRING_SERVICE = `${CARE_ORDER_FIELD_GROUP}.ReferringService`;
export const CARE_ORDER_FIELD_SPECIALTY = `${CARE_ORDER_FIELD_GROUP}.Specialty`;
export const CARE_ORDER_FIELD_SUBGROUP = `${CARE_ORDER_FIELD_GROUP}.Subgroup`;
export const CARE_ORDER_FIELD_APPOINTMENT_TYPE = `${CARE_ORDER_FIELD_GROUP}.AppointmentType`;
export const CARE_ORDER_FIELD_REASON_FOR_VISIT = `${CARE_ORDER_FIELD_GROUP}.ReasonForVisit`;
export const CARE_ORDER_FIELD_BOOKED_APPOINTMENT = `${CARE_ORDER_FIELD_GROUP}.BookedAppointment`;
export const CARE_ORDER_FIELD_EXTERNAL_ORDER_NUMBER = `${CARE_ORDER_FIELD_GROUP}.ExternalOrderNumber`;
//export const CARE_ORDER_FIELD_LAST_MODIFIED_AT = `${CARE_ORDER_FIELD_GROUP}.LastModifiedAt`;
//export const CARE_ORDER_FIELD_LAST_EMAIL_DATE = `${CARE_ORDER_FIELD_GROUP}.LastEmailDate`;
export const CARE_ORDER_FIELD_PRODUCT_INSTANCE_CONSUMER = `${CARE_ORDER_FIELD_GROUP}.ProductInstanceConsumer`;
//export const CARE_ORDER_FIELD_LOCKOUT_END_AT = `${CARE_ORDER_FIELD_GROUP}.LockoutEndAt`;
export const CARE_ORDER_FIELD_CUSTOM_FIELDS = `${CARE_ORDER_FIELD_GROUP}.CustomFields`;
export const CARE_ORDER_FIELD_NOTES = `${CARE_ORDER_FIELD_GROUP}.Notes`;
