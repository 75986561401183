import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';
import moment from 'moment';
import './schedulableOrderRow.css'

export default function SchedulableOrderRow (props) {
    const {
        checked,
        disabled,
        onCheck,
        order,
    } = props;

    var visitTypesDisplay = `${order.appointmentTypeName} [${order.appointmentTypeVisitCode}]`;
    return (
        <tr className="order-history-row-responsive">
            <td><Input type="checkbox" className="custom-checkbox-left" id={order.idExternal} name={order.idExternal} onChange={onCheck} checked={checked} disabled={disabled ? 'disabled' : false} /></td>
            <td>{order.idExternal}</td>
            <td>{visitTypesDisplay}</td>
            <td>{order.referringProviderName}</td>
            <td className="text-nowrap">{moment(order.orderingDateExternal).format('MM/DD/YYYY')}</td>
        </tr>
    )
}

SchedulableOrderRow.propTypes = {
	order: PropTypes.object,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
