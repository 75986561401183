import authCookie from '../lib/authCookie';
import * as actionTypes from './actionTypes'

export function authenticateUser(email, password, productInstanceId) {
	return {
		type: actionTypes.AUTHENTICATE_USER,
		payload: {
			request: {
				method: 'post',
				url: "login",
				data: {
					email,
					password
				}
			}
		}
	};
}

export function verifyToken() {
	return {
		type: actionTypes.VERIFY_TOKEN,
		payload: {
			request: {
				method: 'get',
				url: "token/verify",
			}
		}
	};
}

export function logoutUser() {
	return {
		type: actionTypes.LOGOUT_USER,
		payload: {
			request: {
				method: 'get',
				url: "logout",
			}
		}
	};
}

export function exchangeToken(token) {
	return {
		type: actionTypes.EXCHANGE_TOKEN,
		payload: {
			request: {
				method: 'post',
				url: `sso/callback/${token}`
			}
		}
	}
}

export function saveFirstLoginChanges(secretQuestionInfo, changePasswordInfo) {
	return {
		type: actionTypes.FIRST_LOGIN_SAVE,
		payload: {
			request: {
				method: 'post',
				url: 'user/savefirstloginchanges',
				data: {
					userProfileDetails: {
						secretQuestion1: secretQuestionInfo.secretQuestion1,
						secretQuestion2: secretQuestionInfo.secretQuestion2,
						secretQuestion3: secretQuestionInfo.secretQuestion3,
						secretAnswer1: secretQuestionInfo.secretAnswer1,
						secretAnswer2: secretQuestionInfo.secretAnswer2,
						secretAnswer3: secretQuestionInfo.secretAnswer3,
					},
					UserChangePasswordDetails: {
						currentPassword: changePasswordInfo.currentPassword,
						newPassword: changePasswordInfo.newPassword,
						confirmPassword: changePasswordInfo.confirmPassword,
					},
				}
			}
		}
	}
}

export const setAuthToken = (auth) => {
	authCookie.set(auth);
	return {
		type: actionTypes.SET_AUTH_TOKEN,
		payload: auth
	}
}

export const clearAuthToken = () => {
	authCookie.clear();
	return { 
		type: actionTypes.CLEAR_AUTH_TOKEN,
		payload: authCookie.get()
	}
}

export function setGRDebugContext(contextDetails) {
	const contextCriteria = {
		debug: contextDetails.debug
	};

	return function (dispatch) {
		dispatch(setGRDebugContextAction(contextCriteria));
		return Promise.resolve();
	};
}

function setGRDebugContextAction(contextCriteria) {
	return {
		type: actionTypes.SET_GUIDED_RESPONSE_DEBUG_CONTEXT,
		contextCriteria: contextCriteria,
	};
}

export const keepSessionAlive = () => {
	return {
		type: actionTypes.KEEP_SESSION_ALIVE,
		payload: {
			request: {
				method: 'get',
				url: 'token/keepSessionAlive',
			}
		}
	}
}
