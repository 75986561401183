import * as React from 'react';
import PropTypes from 'prop-types';
import DropdownList from '../input/dropdownList';
import DateInput from '../input/dateInput';

import {
	Row,
	Col,
} from 'reactstrap';

// This CustomFieldsModal component is originally intended for "Post-Book" Custom Fields that can appear
// on special modal currently in use for RD2 on the bookAppointment view. This component differs from the standard
// CustomFields component entirely in layout at the request of  Account Management.

export default function CustomFieldsModal(props) {

	const { customFields, customFieldAnswers, customFieldValidation, handleCustomFieldChange, handleCustomFieldDropdownChange, handleCustomFieldDateChange } = props;

	return (
		<div>
			{customFields ? (customFields.map((field, i) => (
				<Row className="container" key={i}>
					<Col lg="8" style={{ "marginBottom": "15px" }}>
						<Row style={{ "margin": "auto" }}>{field.fieldLabel}</Row>
						<Row className="mt-2">
							<Col lg="8">
							{(() => {
								switch (field.dataType) {
									case 'String':
										return (
											<div>
												<input
													className="form-control"
													type="text"
													name={customFields[i].fieldName}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(e) => { handleCustomFieldChange(e, field.dataType) }}
													maxLength={customFields[i].maxLength}
													id={field.fieldName}>
												</input>
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please enter a valid string</span>
												}
											</div>
										);
									case 'Boolean':
										return (
											<div>
												<DropdownList
													name={customFields[i].fieldName}
													id={customFields[i].fieldName}
													maxheight={250}
													placeholder="Please select an answer"
													list={[{ description: "Yes", value: "Yes" }, { description: "No", value: "No" }]}
													listkey={"description"}
													listvalue={"value"}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(name, obj) => { handleCustomFieldDropdownChange(name, obj); }} />
											</div>
										);
									case 'DateTime':
										return (
											<div>
												<DateInput
													type="text"
													className="form-control"
													name={customFields[i].fieldName}
													id={customFields[i].fieldName}
													onChange={(e) => { handleCustomFieldDateChange(e, field.dataType) }}
													value={customFieldAnswers[customFields[i].fieldName]}
												/>
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please enter a valid date in format MM/DD/YYYY</span>
												}
											</div>
										);
									case 'Int32':
										return (
											<div>
												<input
													className="form-control"
													type="text"
													name={customFields[i].fieldName}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(e) => { handleCustomFieldChange(e, field.dataType) }}
													id={field.fieldName}>
												</input>
												{!customFieldValidation[customFields[i].fieldName].valid &&
													<span style={{ "color": "red" }}>*Please enter a valid number</span>
												}
											</div>

										);
									case 'Object':
										return (
											<div>
												<DropdownList
													name={customFields[i].fieldName}
													id={customFields[i].fieldName}
													maxheight={250}
													list={customFields[i].items}
													listkey={"description"}
													listvalue={"description"}
													value={customFieldAnswers[customFields[i].fieldName]}
													onChange={(name, obj) => { handleCustomFieldDropdownChange(name, obj); }} />
											</div>
										);
									default:
										return null;
								}
							})()}
							</Col>
						</Row>
					</Col>
				</Row>
			))) : (null)}
		</div>
	);
}

CustomFieldsModal.propTypes = {
  customFieldAnswers: PropTypes.object,
  customFieldValidation: PropTypes.object,
  customFields: PropTypes.array,
  handleCustomFieldChange: PropTypes.func,
  handleCustomFieldDateChange: PropTypes.func,
  handleCustomFieldDropdownChange: PropTypes.func,
}
