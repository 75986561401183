export const categories = [
	{
		label: 'Warning',
		value: 1,
	},
	{
		label: 'Critical',
		value: 2,
	},
	{
		label: 'Info',
		value: 3,
	},
];

export const productIds = {
	RD2: 2,
	CD2: 4,
	ADMIN2: 6
};

// Action types
export const CLOSE_PRODUCT_ALERT_BANNER = 'CLOSE_PRODUCT_ALERT_BANNER';
