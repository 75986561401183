import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormElement from './guidedResponseFormElement';
import Message from './guidedResponseMessage';
import Question from './guidedResponseQuestion';
import * as validationUtils from '../../nighthawk/utility/validationUtils';
import * as validation from '../../lib/validation';
import {
    TextInput
} from '../input';


const GuidedResponseInputForm = ({ flowInputs, isLoading, handler }) => {
    const [inputFields, setInputFields] = useState([]);
    const [debugPathFileContents, setDebugPathFileContents] = useState("");

    useEffect(() => {
        setInputFields(
            flowInputs.map((input) => ({
                answer: input.providedValue ?? input.defaultValue,
                providedValue: input.providedValue,
                defaultValue: input.defaultValue,
                elementRef: input.fieldName,
                error: null,
                falseLabel: input.valueType === 'Bool' ? 'false' : null,
                fieldName: input.fieldName,
                inputType: input.valueType,
                isFocused: false,
                isRequired: input.isRequired,
                questionText: input.fieldName,
                trueLabel: input.valueType === 'Bool' ? 'true' : null,
            })),
        );
    }, [flowInputs]);

    const buildErrorGR = (element, ruleName, message) => {
        let error = {
            fieldId: element.elementRef,
            fieldGroupName: 'guidedResponse',
            fieldName: element.elementRef,
            ruleName,
            message,
            isFormLevel: true,
        };
        return error;
    };

    const handleInputChange = (e) => {
        updateInputField(e.target.name, 'answer', e.target.value);
    };

    const handleInputFocus = (e) => {
        updateInputField(e.target.name, 'isFocused', true);
    };

    const handleInputBlur = (e) => {
        updateInputField(e.target.name, 'isFocused', false);
    };

    const updateInputField = (fieldName, property, value) => {
        const updatedInputFields = inputFields.map((inputField) => {
            if (inputField.elementRef === fieldName) {
                inputField[property] = value;
            }

            return inputField;
        });
        setInputFields(updatedInputFields);
    };

    const getValidatedInputFields = (inputFields) => {
        return inputFields.map((inputField) => {
            let value = inputField.answer;
            let hasValue = value ? true : false;

            if (typeof value === 'string' || value instanceof String) {
                value = value.trim();
                hasValue = value.length > 0;
            }

            inputField.error =
                inputField.isRequired && !hasValue
                    ? buildErrorGR(
                        inputField,
                        validationUtils.COMMON_RULE_NAMES.REQUIRED,
                        validationUtils.DEFAULT_ERROR(inputField.fieldName),
                    )
                    : null;

            if (
                inputField.inputType === 'Date' &&
                hasValue &&
                inputField.error === null &&
                !validation.validateAnyDate(value)
            ) {
                inputField.error = buildErrorGR(
                    inputField,
                    validationUtils.COMMON_RULE_NAMES.REQUIRED,
                    validationUtils.DEFAULT_ERROR(inputField.fieldName),
                );
            }

            return inputField;
        });
    };

    const handleDebugPathChange = (event) => {
        setDebugPathFileContents(event.target.value);
    }

    const handleStart = () => {
        const validatedInputFields = getValidatedInputFields(inputFields);
        setInputFields(validatedInputFields);

        if (
            !validatedInputFields.find(
                (validatedInputField) => !!validatedInputField.error,
            )
        ) {
            const updatedFlowInputs = flowInputs.map((flowInput) => {
                const currentInputField = validatedInputFields.find(
                    (validatedInputField) =>
                        validatedInputField.elementRef === flowInput.fieldName,
                );
                return { ...flowInput, defaultValue: currentInputField.answer };
            });

            handler(updatedFlowInputs, debugPathFileContents);
        }
    };

    const ValueSourceTag = ({sourceData}) => {
		const {answer, providedValue, defaultValue} = sourceData;
		let sourceText = '';
		if(answer != null) {
			if(answer === defaultValue) sourceText = 'Default Value';
			else if(answer === providedValue) sourceText = 'Provided Value';
		}
		return <div style={{fontStyle: 'italic', color: 'darkgray', position: 'relative', height: '1em'}}>{sourceText}</div>
	}

    return (
        <>
            <div>
                <Message
                    message={'Please provide values for the following flow inputs for debugging purposes.'}
                />
                <br />
                {inputFields.map((inputField) => {
                    const {
                        elementRef,
                        questionText,
                        isRequired,
                        answer,
                        isFocused,
                        error,
                        providedValue,
                        defaultValue
                    } = inputField;

                    return (
                        <FormElement key={elementRef}>
                            <Message message={questionText} isRequired={isRequired} />
                            <Question
                                question={inputField}
                                onChange={handleInputChange}
                                answer={answer}
                                isFocusedLookup={isFocused}
                                error={error}
                                onBlur={handleInputBlur}
                                onFocus={handleInputFocus}
                            />
                            <ValueSourceTag sourceData={{answer, providedValue, defaultValue}}/>
                        </FormElement>
                    );
                })}
                <FormElement key="DebugPathFileContentsKey">
                    <Message message="Debug Path File Contents" />
                    <TextInput name="DebugPathFileContents" PlaceHolder="DebugPath File Contents" isVisible={true} onChange={handleDebugPathChange}></TextInput>
                </FormElement>
                <br/>
                <button
                    className="btn btn-primary"
                    type="button"
                    disabled={isLoading}
                    onClick={handleStart}
                >
                    {isLoading ? (
                        <span>
                            <FontAwesomeIcon icon="spinner" spin /> Processing...
                        </span>
                    ) : (
                        <span>Start</span>
                    )}
                </button>
            </div>
        </>
    );
};

export default GuidedResponseInputForm