import * as actionTypes from './actionTypes';

export function fetchWorkbox(contextCriteria, correlationKey, config) {
	return {
		type: actionTypes.APPLIEDPATHWAYS_FETCH_WORKBOX,
		payload: {
			request: {
				method: 'post',
				url: `appliedPathways/workflow`,
				data: {
					workspaceId: config.apWorkspaceId,
					algorithmId: config.apAlgorithmId,
					serverUrl: config.apServerUrl,
					useJwt: config.apUseJwt,
					correlationKey,
					contextCriteria,
				}
			}
		}
	};
}

export function fetchWorkboxWithRule(contextCriteria, correlationKey, config, ruleToken, subpoints) {
	return {
		type: actionTypes.APPLIEDPATHWAYS_FETCH_WORKBOX,
		payload: {
			request: {
				method: 'post',
				url: `appliedPathways/workflowrule`,
				data: {
					ruleToken,
					subpoints,
					serverUrl: config.apServerUrl,
					useJwt: config.apUseJwt,
					correlationKey,
					contextCriteria,
				}
			}
		}
	};
}

export function fetchWorkboxNext(data) {
	return {
		type: actionTypes.APPLIEDPATHWAYS_WORKFLOW_NEXT,
		payload: {
			request: {
				method: 'post',
				url: `appliedPathways/workflow/next`,
				data: data,
			}
		}
	};
}

export function fetchWorkboxPrevious(data) {
	return {
		type: actionTypes.APPLIEDPATHWAYS_WORKFLOW_PREVIOUS,
		payload: {
			request: {
				method: 'post',
				url: `appliedPathways/workflow/previous`,
				data: data,
			}
		}
	};
}
