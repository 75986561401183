import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'redux-axios-middleware';

export const TOKEN_LINK_PATIENT_REFID_LOOKUP = 'TOKEN_LINK_PATIENT_LOOKUP';
export const TOKEN_LINK_APPOINTMENT_REFID_LOOKUP = 'TOKEN_LINK_APPOINTMENT_LOOKUP';

export const SET_ACTIVE_PATIENT = 'SET_ACTIVE_PATIENT';
export const CLEAR_ACTIVE_PATIENT = 'CLEAR_ACTIVE_PATIENT';
export const PUBLISH_ERROR = 'PUBLISH_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS';

export const ENSURE_PATIENT = 'ENSURE_PATIENT';
export const ENSURE_PATIENT_SUCCESS = `${ENSURE_PATIENT}${SUCCESS_SUFFIX}`;
export const ENSURE_PATIENT_ERROR = `${ENSURE_PATIENT}${ERROR_SUFFIX}`;

export const SET_ACTIVE_PATIENT_DETAILS = 'SET_ACTIVE_PATIENT_DETAILS';
export const SET_ACTIVE_PATIENT_DETAILS_SUCCESS = `${SET_ACTIVE_PATIENT_DETAILS}${SUCCESS_SUFFIX}`;
export const SET_ACTIVE_PATIENT_DETAILS_ERROR = `${SET_ACTIVE_PATIENT_DETAILS}${ERROR_SUFFIX}`;

export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = `${AUTHENTICATE_USER}${SUCCESS_SUFFIX}`;
export const AUTHENTICATE_USER_ERROR = `${AUTHENTICATE_USER}${ERROR_SUFFIX}`;

export const EXCHANGE_TOKEN = 'EXCHANGE_TOKEN';
export const EXCHANGE_TOKEN_SUCCESS = `${EXCHANGE_TOKEN}${SUCCESS_SUFFIX}`;
export const EXCHANGE_TOKEN_ERROR = `${EXCHANGE_TOKEN}${ERROR_SUFFIX}`;

export const LOGOUT_USER = 'LOGOUT_USER';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_TOKEN_SUCCESS = `${VERIFY_TOKEN}${SUCCESS_SUFFIX}`;
export const VERIFY_TOKEN_ERROR = `${VERIFY_TOKEN}${ERROR_SUFFIX}`

export const FIRST_LOGIN_SAVE = 'FIRST_LOGIN_SAVE';
export const FIRST_LOGIN_SAVE_SUCCESS = `${FIRST_LOGIN_SAVE}${SUCCESS_SUFFIX}`;
export const FIRST_LOGIN_SAVE_ERROR = `${FIRST_LOGIN_SAVE}${ERROR_SUFFIX}`;

export const KEEP_SESSION_ALIVE = 'KEEP_SESSION_ALIVE';
export const KEEP_SESSION_ALIVE_SUCCESS = `${KEEP_SESSION_ALIVE}${SUCCESS_SUFFIX}`;
export const KEEP_SESSION_ALIVE_ERROR = `${KEEP_SESSION_ALIVE}${ERROR_SUFFIX}`;

export const LOAD_LOGIN_PAGE_DATA = 'LOAD_LOGIN_PAGE_DATA';
export const LOAD_LOGIN_PAGE_DATA_SUCCESS = `${LOAD_LOGIN_PAGE_DATA}${SUCCESS_SUFFIX}`;
export const LOAD_LOGIN_PAGE_DATA_ERROR = `${LOAD_LOGIN_PAGE_DATA}${ERROR_SUFFIX}`;

export const PROCESS_PRODUCT_INSTANCE = 'PROCESS_PRODUCT_INSTANCE';
export const PROCESS_PRODUCT_INSTANCE_SUCCESS = `${PROCESS_PRODUCT_INSTANCE}${SUCCESS_SUFFIX}`;
export const PROCESS_PRODUCT_INSTANCE_ERROR = `${PROCESS_PRODUCT_INSTANCE}${ERROR_SUFFIX}`;

export const SEARCH_PATIENT = 'SEARCH_PATIENT';
export const SEARCH_PATIENT_SUCCESS = `${SEARCH_PATIENT}${SUCCESS_SUFFIX}`;
export const SEARCH_PATIENT_ERROR = `${SEARCH_PATIENT}${ERROR_SUFFIX}`;

export const START_BOOKING = 'START_BOOKING';
export const WORKFLOW_CORRELATION_KEY_UPDATED = 'WORKFLOW_CORRELATION_KEY_UPDATED';

export const SEARCH_CALENDARS = 'SEARCH_CALENDARS';
export const SEARCH_CALENDARS_SUCCESS = `${SEARCH_CALENDARS}${SUCCESS_SUFFIX}`;
export const SEARCH_CALENDARS_ERROR = `${SEARCH_CALENDARS}${ERROR_SUFFIX}`;

export const SCAN_FIRST_AVAILABILITY = 'SCAN_FIRST_AVAILABILITY';
export const SCAN_FIRST_AVAILABILITY_SUCCESS = `${SCAN_FIRST_AVAILABILITY}${SUCCESS_SUFFIX}`;
export const SCAN_FIRST_AVAILABILITY_ERROR = `${SCAN_FIRST_AVAILABILITY}${ERROR_SUFFIX}`;

export const SCAN_NEXT_AVAILABILITY = 'SCAN_NEXT_AVAILABILITY';
export const SCAN_NEXT_AVAILABILITY_SUCCESS = `${SCAN_NEXT_AVAILABILITY}${SUCCESS_SUFFIX}`;
export const SCAN_NEXT_AVAILABILITY_ERROR = `${SCAN_NEXT_AVAILABILITY}${ERROR_SUFFIX}`;

export const SEARCH_AVAILABILITY = 'SEARCH_AVAILABILITY';
export const SEARCH_AVAILABILITY_SUCCESS = `${SEARCH_AVAILABILITY}${SUCCESS_SUFFIX}`;
export const SEARCH_AVAILABILITY_ERROR = `${SEARCH_AVAILABILITY}${ERROR_SUFFIX}`;

export const SET_SCAN_NEXT_AVAILABILITY_RESULTS = "SET_SCAN_NEXT_AVAILABILITY_RESULTS";

export const SET_EFFECTIVE_SEARCH_RESULTS = 'SET_EFFECTIVE_SEARCH_RESULTS';

export const AVAILABILITY_SEARCH_CONFIG = 'AVAILABILITY_SEARCH_CONFIG';
export const AVAILABILITY_SEARCH_CONFIG_SUCCESS = `${AVAILABILITY_SEARCH_CONFIG}${SUCCESS_SUFFIX}`;
export const AVAILABILITY_SEARCH_CONFIG_ERROR = `${AVAILABILITY_SEARCH_CONFIG}${ERROR_SUFFIX}`;

export const SET_RESCHEDULE_SEARCH_STATE = 'SET_RESCHEDULE_SEARCH_STATE';
export const RESET_AVAILABILITY_STATE = 'RESET_AVAILABILITY_STATE';

export const ON_PATIENT_DETAILS_VIEW_MOUNT = 'ON_PATIENT_DETAILS_VIEW_MOUNT';

export const PATIENT_DETAILS = 'PATIENT_DETAILS';
export const PATIENT_DETAILS_SUCCESS = `${PATIENT_DETAILS}${SUCCESS_SUFFIX}`;
export const PATIENT_DETAILS_ERROR = `${PATIENT_DETAILS}${ERROR_SUFFIX}`;

export const GET_PATIENT_ID_BY_IDENTITY = 'GET_PATIENT_ID_BY_IDENTITY';
export const GET_PATIENT_ID_BY_IDENTITY_SUCCESS = `${GET_PATIENT_ID_BY_IDENTITY}${SUCCESS_SUFFIX}`;
export const GET_PATIENT_ID_BY_IDENTITY_ERROR = `${GET_PATIENT_ID_BY_IDENTITY}${ERROR_SUFFIX}`;

export const GET_PATIENT_SCHEDULABLE_ORDERS = 'GET_PATIENT_SCHEDULABLE_ORDERS';
export const GET_PATIENT_SCHEDULABLE_ORDERS_SUCCESS = `${GET_PATIENT_SCHEDULABLE_ORDERS}${SUCCESS_SUFFIX}`;
export const GET_PATIENT_SCHEDULABLE_ORDERS_ERROR = `${GET_PATIENT_SCHEDULABLE_ORDERS}${ERROR_SUFFIX}`;

export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT';

export const SET_PREVIOUS_APPOINTMENT = 'SET_PREVIOUS_APPOINTMENT';
export const CLEAR_PREVIOUS_APPOINTMENT = 'CLEAR_PREVIOUS_APPOINTMENT';
export const SET_CANCEL_RESCHEDULE_ACTION_REFERRER = 'SET_CANCEL_RESCHEDULE_ACTION_REFERRER';

export const PATIENT_SAVE = 'PATIENT_SAVE';
export const PATIENT_SAVE_SUCCESS = `${PATIENT_SAVE}${SUCCESS_SUFFIX}`;
export const PATIENT_SAVE_ERROR = `${PATIENT_SAVE}${ERROR_SUFFIX}`;

export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const PATIENT_UPDATE_SUCCESS = `${PATIENT_UPDATE}${SUCCESS_SUFFIX}`;
export const PATIENT_UPDATE_ERROR = `${PATIENT_UPDATE}${ERROR_SUFFIX}`;

export const LOAD_PATIENT_SAVE_PAGE_DATA = 'LOAD_PATIENT_SAVE_PAGE_DATA';
export const LOAD_PATIENT_SAVE_PAGE_DATA_SUCCESS = `${LOAD_PATIENT_SAVE_PAGE_DATA}${SUCCESS_SUFFIX}`;
export const LOAD_PATIENT_SAVE_PAGE_DATA_ERROR = `${LOAD_PATIENT_SAVE_PAGE_DATA}${ERROR_SUFFIX}`;

export const PATIENT_HISTORY = 'PATIENT_HISTORY';
export const PATIENT_HISTORY_SUCCESS = `${PATIENT_HISTORY}${SUCCESS_SUFFIX}`;
export const PATIENT_HISTORY_ERROR = `${PATIENT_HISTORY}${ERROR_SUFFIX}`;

export const PATIENT_CARE_ORDER_HISTORY = 'PATIENT_CARE_ORDER_HISTORY';
export const PATIENT_CARE_ORDER_HISTORY_SUCCESS = `${PATIENT_CARE_ORDER_HISTORY}${SUCCESS_SUFFIX}`;
export const PATIENT_CARE_ORDER_HISTORY_ERROR = `${PATIENT_CARE_ORDER_HISTORY}${ERROR_SUFFIX}`;

export const PATIENT_OPEN_WORKFLOWS = 'PATIENT_OPEN_WORKFLOWS';
export const PATIENT_OPEN_WORKFLOWS_SUCCESS = `${PATIENT_OPEN_WORKFLOWS}${SUCCESS_SUFFIX}`;
export const PATIENT_OPEN_WORKFLOWS_ERROR = `${PATIENT_OPEN_WORKFLOWS}${ERROR_SUFFIX}`;

export const PATIENT_SEARCH_ADDRESS = 'PATIENT_SEARCH_ADDRESS';
export const PATIENT_SEARCH_ADDRESS_SUCCESS = `${PATIENT_SEARCH_ADDRESS}${SUCCESS_SUFFIX}`;
export const PATIENT_SEARCH_ADDRESS_ERROR = `${PATIENT_SEARCH_ADDRESS}${ERROR_SUFFIX}`;

export const PATIENT_GET_ADDRESS = 'PATIENT_GET_ADDRESS';
export const PATIENT_GET_ADDRESS_SUCCESS = `${PATIENT_GET_ADDRESS}${SUCCESS_SUFFIX}`;
export const PATIENT_GET_ADDRESS_ERROR = `${PATIENT_GET_ADDRESS}${ERROR_SUFFIX}`;

export const PROVIDER_DETAILS = 'PROVIDER_DETAILS';
export const PROVIDER_DETAILS_SUCCESS = `${PROVIDER_DETAILS}${SUCCESS_SUFFIX}`;
export const PROVIDER_DETAILS_ERROR = `${PROVIDER_DETAILS}${ERROR_SUFFIX}`;

export const APPOINTMENT_DETAILS = 'APPOINTMENT_DETAILS';
export const APPOINTMENT_DETAILS_SUCCESS = `${APPOINTMENT_DETAILS}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_DETAILS_ERROR = `${APPOINTMENT_DETAILS}${ERROR_SUFFIX}`;

export const PREVIOUS_APPOINTMENT_DETAILS = 'PREVIOUS_APPOINTMENT_DETAILS';
export const PREVIOUS_APPOINTMENT_DETAILS_SUCCESS = `${PREVIOUS_APPOINTMENT_DETAILS}${SUCCESS_SUFFIX}`;
export const PREVIOUS_APPOINTMENT_DETAILS_ERROR = `${PREVIOUS_APPOINTMENT_DETAILS}${ERROR_SUFFIX}`;

export const APPOINTMENT_BOOK = 'APPOINTMENT_BOOK';
export const APPOINTMENT_BOOK_SUCCESS = `${APPOINTMENT_BOOK}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_BOOK_ERROR = `${APPOINTMENT_BOOK}${ERROR_SUFFIX}`;

export const APPOINTMENT_CANCEL = 'APPOINTMENT_CANCEL';
export const APPOINTMENT_CANCEL_SUCCESS = `${APPOINTMENT_CANCEL}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_CANCEL_ERROR = `${APPOINTMENT_CANCEL}${ERROR_SUFFIX}`;

export const APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE';
export const APPOINTMENT_UPDATE_SUCCESS = `${APPOINTMENT_UPDATE}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_UPDATE_ERROR = `${APPOINTMENT_UPDATE}${ERROR_SUFFIX}`;

export const APPOINTMENT_RESERVE = 'APPOINTMENT_RESERVE';
export const APPOINTMENT_RESERVE_SUCCESS = `${APPOINTMENT_RESERVE}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_RESERVE_ERROR = `${APPOINTMENT_RESERVE}${ERROR_SUFFIX}`;

export const APPOINTMENT_RESERVE_DETAILS = `APPOINTMENT_RESERVE_DETAILS`;
export const APPOINTMENT_RESERVE_DETAILS_SUCCESS = `${APPOINTMENT_RESERVE_DETAILS}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_RESERVE_DETAILS_ERROR = `${APPOINTMENT_RESERVE_DETAILS}${ERROR_SUFFIX}`;

export const APPOINTMENT_RESERVATION_CANCEL = `APPOINTMENT_RESERVATION_CANCEL`;
export const APPOINTMENT_RESERVATION_CANCEL_SUCCESS = `${APPOINTMENT_RESERVATION_CANCEL}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_RESERVATION_CANCEL_ERROR = `${APPOINTMENT_RESERVATION_CANCEL}${ERROR_SUFFIX}`;

export const APPOINTMENT_REQUEST = 'APPOINTMENT_REQUEST';
export const APPOINTMENT_REQUEST_SUCCESS = `${APPOINTMENT_REQUEST}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_REQUEST_ERROR = `${APPOINTMENT_REQUEST}${ERROR_SUFFIX}`;

export const APPOINTMENT_REQUEST_DETAILS = 'APPOINTMENT_REQUEST_DETAILS';
export const APPOINTMENT_REQUEST_DETAILS_SUCCESS = `${APPOINTMENT_REQUEST_DETAILS}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_REQUEST_DETAILS_ERROR = `${APPOINTMENT_REQUEST_DETAILS}${ERROR_SUFFIX}`;

export const APPOINTMENT_RESCHEDULE = 'APPOINTMENT_RESCHEDULE';
export const APPOINTMENT_RESCHEDULE_SUCCESS = `${APPOINTMENT_RESCHEDULE}${SUCCESS_SUFFIX}`;
export const APPOINTMENT_RESCHEDULE_ERROR = `${APPOINTMENT_RESCHEDULE}${ERROR_SUFFIX}`;

export const CUSTOM_FIELDS = 'CUSTOM_FIELDS';
export const CUSTOM_FIELDS_SUCCESS = `${CUSTOM_FIELDS}${SUCCESS_SUFFIX}`;
export const CUSTOM_FIELDS_ERROR = `${CUSTOM_FIELDS}${ERROR_SUFFIX}`;

export const USER_PROFILE_DETAILS = 'USER_PROFILE_DETAILS';
export const USER_PROFILE_DETAILS_SUCCESS = `${USER_PROFILE_DETAILS}${SUCCESS_SUFFIX}`;
export const USER_PROFILE_DETAILS_ERROR = `${USER_PROFILE_DETAILS}${ERROR_SUFFIX}`;

export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';
export const USER_PROFILE_UPDATE_SUCCESS = `${USER_PROFILE_UPDATE}${SUCCESS_SUFFIX}`;
export const USER_PROFILE_UPDATE_ERROR = `${USER_PROFILE_UPDATE}${ERROR_SUFFIX}`;

export const USER_PASSWORD_UPDATE = 'USER_PASSWORD_UPDATE';
export const USER_PASSWORD_UPDATE_SUCCESS = `${USER_PASSWORD_UPDATE}${SUCCESS_SUFFIX}`;
export const USER_PASSWORD_UPDATE_ERROR = `${USER_PASSWORD_UPDATE}${ERROR_SUFFIX}`;

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_FORGOT_PASSWORD_SUCCESS = `${USER_FORGOT_PASSWORD}${SUCCESS_SUFFIX}`;
export const USER_FORGOT_PASSWORD_ERROR = `${USER_FORGOT_PASSWORD}${ERROR_SUFFIX}`;

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_SUCCESS = `${USER_RESET_PASSWORD}${SUCCESS_SUFFIX}`;
export const USER_RESET_PASSWORD_ERROR = `${USER_RESET_PASSWORD}${ERROR_SUFFIX}`;

export const USER_LOAD_SECRET_QUESTION = 'USER_LOAD_SECRET_QUESTION';
export const USER_LOAD_SECRET_QUESTION_SUCCESS = `${USER_LOAD_SECRET_QUESTION}${SUCCESS_SUFFIX}`;
export const USER_LOAD_SECRET_QUESTION_ERROR = `${USER_LOAD_SECRET_QUESTION}${ERROR_SUFFIX}`;

export const USER_VERIFY_SECRET_ANSWER = 'USER_VERIFY_SECRET_ANSWER';
export const USER_VERIFY_SECRET_ANSWER_SUCCESS = `${USER_VERIFY_SECRET_ANSWER}${SUCCESS_SUFFIX}`;
export const USER_VERIFY_SECRET_ANSWER_ERROR = `${USER_VERIFY_SECRET_ANSWER}${ERROR_SUFFIX}`;

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const CLEAR_AGENT_INSTRUCTIONS = 'CLEAR_AGENT_INSTRUCTIONS';
export const CLEAR_BOOKING_CONTEXT = 'CLEAR_BOOKING_CONTEXT';

export const RETRIEVE_DATE_RANGES = 'RETRIEVE_DATE_RANGES';
export const RETRIEVE_DATE_RANGES_SUCCESS = `${RETRIEVE_DATE_RANGES}${SUCCESS_SUFFIX}`;
export const RETRIEVE_DATE_RANGES_ERROR = `${RETRIEVE_DATE_RANGES}${ERROR_SUFFIX}`;

export const RETRIEVE_BOOKABLE_SITES = 'RETRIEVE_BOOKABLE_SITES';
export const RETRIEVE_BOOKABLE_SITES_SUCCESS = `${RETRIEVE_BOOKABLE_SITES}${SUCCESS_SUFFIX}`;
export const RETRIEVE_BOOKABLE_SITES_ERROR = `${RETRIEVE_BOOKABLE_SITES}${ERROR_SUFFIX}`;

export const RETRIEVE_PROVIDERS_FOR_SITE = 'RETRIEVE_PROVIDERS_FOR_SITE';
export const RETRIEVE_PROVIDERS_FOR_SITE_SUCCESS = `${RETRIEVE_PROVIDERS_FOR_SITE}${SUCCESS_SUFFIX}`;
export const RETRIEVE_PROVIDERS_FOR_SITE_ERROR = `${RETRIEVE_PROVIDERS_FOR_SITE}${ERROR_SUFFIX}`;

export const RETRIEVE_REFERRING_PROVIDERS_FOR_SITE = 'RETRIEVE_REFERRING_PROVIDERS_FOR_SITE';
export const RETRIEVE_REFERRING_PROVIDERS_FOR_SITE_SUCCESS = `${RETRIEVE_REFERRING_PROVIDERS_FOR_SITE}${SUCCESS_SUFFIX}`;
export const RETRIEVE_REFERRING_PROVIDERS_FOR_SITE_ERROR = `${RETRIEVE_REFERRING_PROVIDERS_FOR_SITE}${ERROR_SUFFIX}`;

export const RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER = 'RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER';
export const RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER_SUCCESS = `${RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER}${SUCCESS_SUFFIX}`;
export const RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER_ERROR = `${RETRIEVE_APPOINTMENT_TYPES_FOR_PROVIDER}${ERROR_SUFFIX}`;

export const CREATE_CARE_ORDER = 'CREATE_CARE_ORDER';
export const CREATE_CARE_ORDER_SUCCESS = `${CREATE_CARE_ORDER}${SUCCESS_SUFFIX}`;
export const CREATE_CARE_ORDER_ERROR = `${CREATE_CARE_ORDER}${ERROR_SUFFIX}`;

export const CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS = 'CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS';
export const CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS_SUCCESS = `${CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS}${SUCCESS_SUFFIX}`;
export const CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS_ERROR = `${CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS}${ERROR_SUFFIX}`;

export const UPDATE_CARE_ORDER = 'UPDATE_CARE_ORDER';
export const UPDATE_CARE_ORDER_SUCCESS = `${UPDATE_CARE_ORDER}${SUCCESS_SUFFIX}`;
export const UPDATE_CARE_ORDER_ERROR = `${UPDATE_CARE_ORDER}${ERROR_SUFFIX}`;

export const GET_CARE_ORDERS_BY_SYSTEM = 'GET_CARE_ORDERS_BY_SYSTEM';
export const GET_CARE_ORDERS_BY_SYSTEM_SUCCESS = `${GET_CARE_ORDERS_BY_SYSTEM}${SUCCESS_SUFFIX}`;
export const GET_CARE_ORDERS_BY_SYSTEM_ERROR = `${GET_CARE_ORDERS_BY_SYSTEM}${ERROR_SUFFIX}`;

export const CARE_ORDER_DETAILS_PAGE_DATA = 'CARE_ORDER_DETAILS_PAGE_DATA';
export const CARE_ORDER_DETAILS_PAGE_DATA_SUCCESS = `${CARE_ORDER_DETAILS_PAGE_DATA}${SUCCESS_SUFFIX}`;
export const CARE_ORDER_DETAILS_PAGE_DATA_ERROR = `${CARE_ORDER_DETAILS_PAGE_DATA}${ERROR_SUFFIX}`;

export const GET_CARE_ORDER_HISTORY = 'GET_CARE_ORDER_HISTORY';
export const GET_CARE_ORDER_HISTORY_SUCCESS = `${GET_CARE_ORDER_HISTORY}${SUCCESS_SUFFIX}`;
export const GET_CARE_ORDER_HISTORY_ERROR = `${GET_CARE_ORDER_HISTORY}${ERROR_SUFFIX}`;

export const SEARCH_CARE_ORDERS_PAGE_DATA = 'SEARCH_CARE_ORDERS_PAGE_DATA';
export const SEARCH_CARE_ORDERS_PAGE_DATA_SUCCESS = `${SEARCH_CARE_ORDERS_PAGE_DATA}${SUCCESS_SUFFIX}`;
export const SEARCH_CARE_ORDERS_PAGE_DATA_ERROR = `${SEARCH_CARE_ORDERS_PAGE_DATA}${ERROR_SUFFIX}`;

export const REMOVE_APPOINTMENT_OPTIONS = 'REMOVE_APPOINTMENT_OPTIONS';
export const REMOVE_APPOINTMENT_OPTION_ROW = 'REMOVE_APPOINTMENT_OPTION_ROW';

export const VALIDATE_ZIPCODE = 'VALIDATE_ZIPCODE';

export const GET_REFERRING_PROVIDERS = 'GET_REFERRING_PROVIDERS';
export const GET_REFERRING_PROVIDERS_SUCCESS = `${GET_REFERRING_PROVIDERS}${SUCCESS_SUFFIX}`;
export const GET_REFERRING_PROVIDERS_ERROR = `${GET_REFERRING_PROVIDERS}${ERROR_SUFFIX}`;

export const GET_ORGANIZATION_REFERRING_PROVIDERS = 'GET_ORGANIZATION_REFERRING_PROVIDERS';
export const GET_ORGANIZATION_REFERRING_PROVIDERS_SUCCESS = `${GET_ORGANIZATION_REFERRING_PROVIDERS}${SUCCESS_SUFFIX}`;
export const GET_ORGANIZATION_REFERRING_PROVIDERS_ERROR = `${GET_ORGANIZATION_REFERRING_PROVIDERS}${ERROR_SUFFIX}`;

export const RETRIEVE_SUBGROUPS_FOR_SYSTEM = 'RETRIEVE_SUBGROUPS_FOR_SYSTEM';
export const RETRIEVE_SUBGROUPS_FOR_SYSTEM_SUCCESS = `${RETRIEVE_SUBGROUPS_FOR_SYSTEM}${SUCCESS_SUFFIX}`;
export const RETRIEVE_SUBGROUPS_FOR_SYSTEM_ERROR = `${RETRIEVE_SUBGROUPS_FOR_SYSTEM}${ERROR_SUFFIX}`;

// Applied Pathways
export const APPLIEDPATHWAYS_FETCH_WORKBOX = 'APPLIEDPATHWAYS_FETCH_WORKBOX';
export const APPLIEDPATHWAYS_FETCH_WORKBOX_SUCCESS = `${APPLIEDPATHWAYS_FETCH_WORKBOX}${SUCCESS_SUFFIX}`;
export const APPLIEDPATHWAYS_FETCH_WORKBOX_ERROR = `${APPLIEDPATHWAYS_FETCH_WORKBOX}${ERROR_SUFFIX}`;

export const APPLIEDPATHWAYS_WORKFLOW_NEXT = 'APPLIEDPATHWAYS_WORKFLOW_NEXT';
export const APPLIEDPATHWAYS_WORKFLOW_NEXT_SUCCESS = `${APPLIEDPATHWAYS_WORKFLOW_NEXT}${SUCCESS_SUFFIX}`;
export const APPLIEDPATHWAYS_WORKFLOW_NEXT_ERROR = `${APPLIEDPATHWAYS_WORKFLOW_NEXT}${ERROR_SUFFIX}`;

export const APPLIEDPATHWAYS_WORKFLOW_PREVIOUS = 'APPLIEDPATHWAYS_WORKFLOW_PREVIOUS';
export const APPLIEDPATHWAYS_WORKFLOW_PREVIOUS_SUCCESS = `${APPLIEDPATHWAYS_WORKFLOW_PREVIOUS}${SUCCESS_SUFFIX}`;
export const APPLIEDPATHWAYS_WORKFLOW_PREVIOUS_ERROR = `${APPLIEDPATHWAYS_WORKFLOW_PREVIOUS}${ERROR_SUFFIX}`;

// Auth Token
export const SET_AUTH_TOKEN = "[AUTH] SET TOKEN";
export const CLEAR_AUTH_TOKEN = "[AUTH] CLEAR TOKEN";

// Care Order
export const SET_ACTIVE_CAREORDERVISIT_DETAILS = 'SET_ACTIVE_CAREORDERVISIT_DETAILS';

export const CLOSE_CARE_ORDER = 'CLOSE_CARE_ORDER';
export const CLOSE_CARE_ORDER_SUCCESS = `${CLOSE_CARE_ORDER}${SUCCESS_SUFFIX}`;
export const CLOSE_CARE_ORDER_ERROR = `${CLOSE_CARE_ORDER}${ERROR_SUFFIX}`;

// Decision Support
export const PROCESS_DECISION_SUPPORT_SUBPOINTS = 'PROCESS_DECISION_SUPPORT_SUBPOINTS';
export const GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD = 'GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD'
export const GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD_SUCCESS = `${GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD}${SUCCESS_SUFFIX}`
export const GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD_ERROR = `${GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD}${ERROR_SUFFIX}`

// Guided Response
export const GUIDED_RESPONSE_START_FLOW = 'GUIDED_RESPONSE_START_FLOW';
export const GUIDED_RESPONSE_START_FLOW_SUCCESS = `${GUIDED_RESPONSE_START_FLOW}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_START_FLOW_ERROR = `${GUIDED_RESPONSE_START_FLOW}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_CONTINUE_FLOW = 'GUIDED_RESPONSE_CONTINUE_FLOW';
export const GUIDED_RESPONSE_CONTINUE_FLOW_SUCCESS = `${GUIDED_RESPONSE_CONTINUE_FLOW}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_CONTINUE_FLOW_ERROR = `${GUIDED_RESPONSE_CONTINUE_FLOW}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_PREVIOUS = 'GUIDED_RESPONSE_PREVIOUS';
export const GUIDED_RESPONSE_PREVIOUS_SUCCESS = `${GUIDED_RESPONSE_PREVIOUS}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_PREVIOUS_ERROR = `${GUIDED_RESPONSE_PREVIOUS}${ERROR_SUFFIX}`;

export const GUIDED_RESPONSE_SET_INITIALDATA = 'GUIDED_RESPONSE_SET_INITIALDATA';

export const GUIDED_RESPONSE_SAVE_FINISHED_QUESTIONS = 'GUIDED_RESPONSE_SAVE_FINISHED_QUESTIONS';

export const GUIDED_RESPONSE_DEBUG_PATH = 'GUIDED_RESPONSE_DEBUG_PATH';

export const SET_GUIDED_RESPONSE_DEBUG_CONTEXT = 'SET_GUIDED_RESPONSE_DEBUG_CONTEXT';

export const GET_GUIDED_RESPONSE_DSV_RESULTS = 'GET_GUIDED_RESPONSE_DSV_RESULTS';

export const GET_SESSION_QUESTIONS_AND_ANSWERS = 'GET_SESSION_QUESTIONS_AND_ANSWERS';
export const GET_SESSION_QUESTIONS_AND_ANSWERS_SUCCESS = `${GET_SESSION_QUESTIONS_AND_ANSWERS}${SUCCESS_SUFFIX}`;
export const GET_SESSION_QUESTIONS_AND_ANSWERS_ERROR = `${GET_SESSION_QUESTIONS_AND_ANSWERS}${ERROR_SUFFIX}`;
export const CLEAR_SESSION_QUESTIONS_AND_ANSWERS = 'CLEAR_SESSION_QUESTIONS_AND_ANSWERS';

export const GUIDED_RESPONSE_GET_FLOW_SESSION = 'GUIDED_RESPONSE_GET_FLOW_SESSION';
export const GUIDED_RESPONSE_GET_FLOW_SESSION_SUCCESS = `${GUIDED_RESPONSE_GET_FLOW_SESSION}${SUCCESS_SUFFIX}`;
export const GUIDED_RESPONSE_GET_FLOW_SESSION_ERROR = `${GUIDED_RESPONSE_GET_FLOW_SESSION}${ERROR_SUFFIX}`;

// TODO: name and group these:
export const SET_APPOINTMENT_DETAILS_FROM_DECISION_SUPPORT_OUTPUT = 'SET_APPOINTMENT_DETAILS_FROM_DECISION_SUPPORT_OUTPUT';
export const SET_INITIAL_AVAILABILITY_SEARCH_STATE = 'SET_INITIAL_AVAILABILITY_SEARCH_STATE';
export const SET_RESCHEDULE_AVAILABILITY_SEARCH_STATE = 'SET_RESCHEDULE_AVAILABILITY_SEARCH_STATE';
export const SET_INITIAL_CUSTOM_FIELD_ANSWERS = 'SET_INITIAL_CUSTOM_FIELD_ANSWERS';

// Ops Product Alert Banner
export const GET_PRODUCT_ALERT_CONFIG = 'GET_PRODUCT_ALERT_CONFIG';
export const GET_PRODUCT_ALERT_CONFIG_SUCCESS = `${GET_PRODUCT_ALERT_CONFIG}${SUCCESS_SUFFIX}`;
export const GET_PRODUCT_ALERT_CONFIG_ERROR = `${GET_PRODUCT_ALERT_CONFIG}${ERROR_SUFFIX}`;
