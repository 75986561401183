import { clearAuthToken, logoutUser } from "actions/authActions";
import { getProductAlertConfig } from "actions/productAlertConfigActions";
import { productIds } from "constants/productAlertConfigValues";
import { useEffect } from "react";
import { useDispatch } from "react-redux"
import UserViewContainer from "./comps/userViewContainer";
import ProductAlert from "./comps/productAlert";
import { Link } from "react-router-dom";
import LoginLogo from "./comps/loginLogo";

const LogoutView = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductAlertConfig(productIds.RD2));
        dispatch(logoutUser());
        dispatch(clearAuthToken());
    }, [dispatch])

    return (
        <UserViewContainer isLoading={false} className="logoutView">
            <div className="logoutWindow">
                <ProductAlert />
                <LoginLogo />
                <p>You have successfully logged out.</p>
                <p>Please click <Link to="/">here</Link> to log back in to continue managing your patients.</p>
            </div>
        </UserViewContainer>
    )
}

export default LogoutView;