import {
    Button,
    Col,
    Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { AddressDisplay, LabeledField } from "components/common/standardComponents";
import { faClipboardMedical } from "@fortawesome/pro-solid-svg-icons/faClipboardMedical";
import { PhoneNumber } from "components/common/phoneNumber";
import '../css/patientDetailsSummary.css';

const PatientDetailsSummary = (
    { patientDetails, patientConfig, setShowConfirmMorePatientInfoModal }:
        { patientDetails: any, patientConfig: any, setShowConfirmMorePatientInfoModal: any }
) => {

    library.add(faClipboardMedical);

    return (
    <>
        <Row style={{ marginTop: '10px' }}>
            {patientConfig.memberId.isVisible &&
                <Col lg="auto">
                    <LabeledField label={patientConfig.memberId?.fieldLabel ?? 'Member Id'} value={patientDetails?.memberId} />
                </Col>
            }
            <Col lg="auto">
                <LabeledField label={patientConfig.groupNumber?.fieldLabel ?? 'Group Number'} value={patientDetails?.groupNumber} />
            </Col>
            <Col lg="auto">
                <LabeledField label="HOME PHONE" value={<PhoneNumber value={patientDetails?.homePhone} />} />
            </Col>
            <Col lg="auto">
                <LabeledField label="MOBILE PHONE" value={<PhoneNumber value={patientDetails?.mobilePhone} />} />
            </Col>
            <Col lg="auto">
                <LabeledField label="EMAIL" value={patientDetails?.email} />
            </Col>
            <Col lg="auto">
                <AddressDisplay label="ADDRESS" address={patientDetails} />
            </Col>
        </Row>
        {patientConfig.notes.isVisible && patientDetails?.notes &&
            <Row>
                <Col className="patientDetailsSummaryNotes">
                    <div>
                        <p className="fw-bold form-label">{(patientConfig.notes?.fieldLabel ?? 'Patient Notes').toUpperCase()}
                            {patientDetails?.notes &&
                                <span style={{ color: "#426da9", marginLeft: "5px" }}>
                                    <FontAwesomeIcon icon='clipboard-medical' />
                                </span>
                            }
                        </p>
                        <p>{patientDetails?.notes}</p>
                    </div>
                </Col>
            </Row>
        }
        <Row>
            <Col>
                <Button color="primary" onClick={() => setShowConfirmMorePatientInfoModal(true)}>
                    More Info
                </Button>
            </Col>
        </Row>
        </>
    )
};

export default PatientDetailsSummary;