/**
 * @format
 */

import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../actions/authActions';
import FirstLoginView from '../features/user/firstLoginView';
import AuthWrapper from '../components/common/authWrapper';
import ErrorBoundary from '../components/common/errorBoundary';

export class PrivateRoute extends React.Component {
	state = {
		loaded: false,
		isAuthValid: false,
	};

	componentDidMount() {
		this.props.actions.verifyToken().then((response) => {
			if (response.error && response.error.status === 401) {
				this.setState({ loaded: true });
			} else {
				this.setState({ loaded: true });
			}
		});
	}

	render() {
		const {
			component: Component,
			isAuthenticated,
			isTermsOfUseAccepted,
			compelPasswordChange,
			requiredPermission,
			alternateDisplay,
			...rest
		} = this.props;
		const { loaded } = this.state;
		if (!loaded) return null;
		return (
			<ErrorBoundary>
				<Route
					{...rest}
					render={(props) => {
						const { ...rest } = props;
						return isAuthenticated ? (
							isTermsOfUseAccepted && !compelPasswordChange ? (
								<AuthWrapper requiredPermission={requiredPermission} alternateDisplay={alternateDisplay}>
									<Component {...rest} />
								</AuthWrapper>
							) : (
								<FirstLoginView isTermsOfUseAccepted={isTermsOfUseAccepted} showChangePassword={compelPasswordChange} />
							)
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										from: props.location,
									},
								}}
							/>
						);
					}}
				/>
			</ErrorBoundary>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		token: state.auth.token,
		tokenExpiresAt: state.auth.expiresAt,
		isTermsOfUseAccepted: state.auth.isTermsOfUseAccepted,
		compelPasswordChange: state.auth.compelPasswordChange,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			...bindActionCreators(authActions, dispatch),
		},
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
