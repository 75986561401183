import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import activePatientReducer from './activePatientReducer'
import ajaxStatusReducer from './ajaxStatusReducer'
import apiErrorReducer from './apiErrorReducer'
import appliedPathwaysReducer from './appliedPathwaysReducer'
import appointmentReducer from './appointmentReducer'
import appReducer from './appReducer'
import authReducer from './authReducer'
import careOrderReducer from './careOrderReducer'
import configReducer from './configReducer'
import customFieldsReducer from './customFieldsReducer'
import decisionSupportReducer from './decisionSupportReducer'
import guidedResponseReducer from './guidedResponseReducer'
import lookupTablesReducer from './lookupTablesReducer'
import modalReducer from './modalReducer'
import orderManagementReducer from './orderManagementReducer'
import pageReducer from './pageReducer'
import patientSearchReducer from './patientSearchReducer'
import providerReducer from './providerReducer'
import referringProvidersReducer from './referringProvidersReducer'
import sessionReducer from './sessionReducer'
import userReducer from './userReducer'
import productAlertConfigReducer from './productAlertConfigReducer'

import availabilityReducer from '../features/searchAsync/availabilitySlice';

import * as types from '../actions/actionTypes'
const actionTypes = types;

const sessionReducers = (history) => combineReducers({
	// original reducers
	ajaxStatus: ajaxStatusReducer,
	app: appReducer,
	appliedPathways: appliedPathwaysReducer,
	appointment: appointmentReducer,
	auth: authReducer,
	guidedResponse: guidedResponseReducer,
	modal: modalReducer,
	page: pageReducer,
	patientSearch: patientSearchReducer,
	provider: providerReducer,
	router: connectRouter(history),
	session: sessionReducer,
	user: userReducer,
	// refactor reducers - I separated these so we know which reducers are part of the new and improved state management - KM
	activePatient: activePatientReducer,
	apiErrors: apiErrorReducer,
	careOrder: careOrderReducer,
	config: configReducer,

	customFields: customFieldsReducer,
	decisionSupport: decisionSupportReducer,
	lookupTables: lookupTablesReducer,
	orderManagement: orderManagementReducer,
	referringProviders: referringProvidersReducer,

	availability: availabilityReducer,
	productAlertConfig: productAlertConfigReducer,
})

const rootReducer = (history) => (state, action) => {
	if (action.type === actionTypes.LOGOUT_USER || action.type === actionTypes.VERIFY_TOKEN_ERROR) {
		localStorage.clear();
		state = undefined;
	}
	return sessionReducers(history)(state, action)
}

export default rootReducer;
