import { useAppSelector } from "store";
import { PasswordConfigInfo } from "types";

const ResetPasswordInstructions = () => {
    const passwordConfig = useAppSelector((s: any) => s.config.passwordConfig) as PasswordConfigInfo;

    return (
        <div className="resetPasswordInstructions">
            As a HITRUST Certified company, we are committed to HIPAA compliance and the privacy and integrity of all
            patients and their data. All passwords must contain at least {passwordConfig.requiredLength} characters,
            {passwordConfig.requireUppercase && ' 1 capital letter,'}
            {passwordConfig.requireLowercase && ' 1 lower case letter,'}
            {passwordConfig.requireDigit && ' 1 number,'}
            {passwordConfig.requireNonAlphanumeric && ' 1 special character,'}
            &nbsp;and new passwords cannot be one of your last {passwordConfig.passwordHistoryCount} passwords.
        </div>
    )
}

export default ResetPasswordInstructions;