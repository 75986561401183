/**
 * @format
 */

import * as React from 'react';
import { PrintButton } from '../common/printButton';
import { AppointmentDetails } from './appointmentDetails';
import { useState, useRef } from 'react';

export const AppointmentDetailsOverview = props => {
	const componentRef = useRef(null);
	const [isDecisionSupportResponsesCollapsed, setDecisionSupportResponsesCollapsed] = useState(true);

	const printOrder = () => {
		return <PrintButton componentRef={componentRef} />;
	};

	const decisionSupportResponsesToggle = () => {
		setDecisionSupportResponsesCollapsed(!isDecisionSupportResponsesCollapsed);
	};

	const {
		activeCareOrder,
		afterPrint,
		agentInstructions,
		appointmentManagementConfig,
		beforePrint,
		className,
		details,
		enableDecisionSupportHistory,
		handleCancelClick,
		handleEditClick,
		handleNewSearchClick,
		handlePreviousDetailsClick,
		handleRescheduleClick,
		isGroupNumberActive,
		isGroupNumberRequired,
		isMemberIdActive,
		isMemberIdRequired,
		isRescheduled,
		memberIdFieldLabel,
		pastModal,
		previousAppointmentModal,
		providerDetails,
		providerFieldConfig,
		rescheduledAppointmentDetails,
		rescheduleMethod,
		schedulingConfig,
		selectedAppointmentReferenceId,
		sessionQuestionsAndAnswersInfo,
		showInsuranceOnAppointmentDetails,
		showProviderResponseApptDetails,
		showProviderResponsePatientHistory,
		showReasonForVisitApptDetails,
		togglePastModal,
		togglePreviousAppointmentDetailsModal,
		verifyFuture,
		isAppointmentCancelled,
	} = props;

	return (
		<AppointmentDetails
			ref={componentRef}
			activeCareOrder={activeCareOrder}
			agentInstructions={agentInstructions}
			appointmentManagementConfig={appointmentManagementConfig}
			className={className}
			decisionSupportResponsesToggle={decisionSupportResponsesToggle}
			details={details}
			enableDecisionSupportHistory={enableDecisionSupportHistory}
			handleCancelClick={handleCancelClick}
			handleEditClick={handleEditClick}
			handleNewSearchClick={handleNewSearchClick}
			handleRescheduleClick={handleRescheduleClick}
			history={props.history}
			isDecisionSupportResponsesCollapsed={isDecisionSupportResponsesCollapsed}
			isGroupNumberActive={isGroupNumberActive}
			isGroupNumberRequired={isGroupNumberRequired}
			isMemberIdActive={isMemberIdActive}
			isMemberIdRequired={isMemberIdRequired}
			isRescheduled={isRescheduled}
			memberIdFieldLabel={memberIdFieldLabel}
			onAfterPrint={afterPrint}
			onBeforePrintGetContent={beforePrint}
			onViewPreviousClick={handlePreviousDetailsClick}
			pastModal={pastModal}
			previousAppointmentModal={previousAppointmentModal}
			printOrder={printOrder}
			providerDetails={providerDetails}
			providerFieldConfig={providerFieldConfig}
			rescheduledAppointmentDetails={rescheduledAppointmentDetails}
			rescheduleMethod={rescheduleMethod}
			schedulingConfig={schedulingConfig}
			selectedAppointmentReferenceId={selectedAppointmentReferenceId}
			sessionQuestionsAndAnswersInfo={sessionQuestionsAndAnswersInfo}
			showInsuranceOnAppointmentDetails={showInsuranceOnAppointmentDetails}
			showProviderResponseApptDetails={showProviderResponseApptDetails}
			showProviderResponsePatientHistory={showProviderResponsePatientHistory}
			showReasonForVisitApptDetails={showReasonForVisitApptDetails}
			togglePastModal={togglePastModal}
			togglePreviousAppointmentDetailsModal={togglePreviousAppointmentDetailsModal}
			verifyFuture={verifyFuture}
			isAppointmentCancelled={isAppointmentCancelled}
		/>
	);
}

export default AppointmentDetailsOverview;
