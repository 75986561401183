import * as actionTypes from './actionTypes'

export function getProductInstanceConfig(instanceName) {
	let url = "productinstance";
	if (instanceName) {
		url += `?instanceName=${instanceName}`
	}
	return {
		type: actionTypes.PROCESS_PRODUCT_INSTANCE,
		payload: {
			request: {
				method: 'get',
				url: url,
			}
		}
	};
}

export function fetchDecisionSupportSessionOutput(decisionSupportSessionId) {
	return {
		type: actionTypes.GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD,
		payload: {
			request: {
				method: 'get',
				url: `decisionsupportsession/${decisionSupportSessionId}`
			}
		}
	};
}
