import * as React from 'react';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';

export default class ManageSecretQuestions extends React.Component {
    constructor(props) {
        super(props);
        let userDetails = Object.assign({}, props.details);
        this.state = {
            user: userDetails,
            isEditMode: props.editMode,
            isOpen: false,
            isQuestion1Valid: true,
            isQuestion2Valid: true,
            isQuestion3Valid: true,
            isAnswer1Valid: true,
            isAnswer2Valid: true,
            isAnswer3Valid: true
        };
    }

    isQuestionValid = (question) => {
        var result = false;
        if (question && question.trim() !== '') {
            if (question.length >= this.props.questionLength) {
                result = true;
            }
        }
        return result;
    };

    isAnswerValid = (answer) => {
        var result = false;
        if (answer && answer.trim() !== '') {
            if (answer.length >= this.props.answerLength) {
                result = true;
            }
        }
        return result;
    }

    validate = () => {
        var validationSet = {
            isQuestion1Valid: this.validateField("secretQuestion1", this.state.user.secretQuestion1),
            isQuestion2Valid: this.validateField("secretQuestion2", this.state.user.secretQuestion2),
            isQuestion3Valid: this.validateField("secretQuestion3", this.state.user.secretQuestion3),
            isAnswer1Valid: this.validateField("secretAnswer1", this.state.user.secretAnswer1),
            isAnswer2Valid: this.validateField("secretAnswer2", this.state.user.secretAnswer2),
            isAnswer3Valid: this.validateField("secretAnswer3", this.state.user.secretAnswer3)
        }

        return Object.keys(validationSet).every(function (x) {
            return (validationSet[x] === true);
        });
    }

    validateField = (field, value) => {

        let isValid = false;

        switch (field) {

            case "secretQuestion1":
                isValid = this.isQuestionValid(value);
                this.setState({ isQuestion1Valid: isValid });
                return isValid;

            case "secretQuestion2":
                isValid = this.isQuestionValid(value);
                this.setState({ isQuestion2Valid: isValid });
                return isValid;

            case "secretQuestion3":
                isValid = this.isQuestionValid(value);
                this.setState({ isQuestion3Valid: isValid });
                return isValid;

            case "secretAnswer1":
                isValid = this.isAnswerValid(value);
                this.setState({ isAnswer1Valid: isValid });
                return isValid;

            case "secretAnswer2":
                isValid = this.isAnswerValid(value);
                this.setState({ isAnswer2Valid: isValid });
                return isValid;

            case "secretAnswer3":
                isValid = this.isAnswerValid(value);
                this.setState({ isAnswer3Valid: isValid });
                return isValid;

            default:
                return true;
        }
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.validate(event)) {
            this.props.onSubmit(this.state.user, event);
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let user = {
            ...this.state.user,
            [name]: value
        };
        this.setState({ user });
    };

    handleEdit = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            isEditMode: !this.state.isEditMode
        });
    };

    handleCancel = (event) => {
        event.preventDefault();
        var revertedUser = {
            ...this.state.user,
            secretQuestion1: this.props.details.secretQuestion1,
            secretAnswer1: this.props.details.secretAnswer1,
            secretQuestion2: this.props.details.secretQuestion2,
            secretAnswer2: this.props.details.secretAnswer2,
            secretQuestion3: this.props.details.secretQuestion3,
            secretAnswer3: this.props.details.secretAnswer3
        };

        this.setState({
            ...this.state,
            isEditMode: !this.state.isEditMode,
            user: revertedUser,
            isQuestion1Valid: true,
            isQuestion2Valid: true,
            isQuestion3Valid: true,
            isAnswer1Valid: true,
            isAnswer2Valid: true,
            isAnswer3Valid: true
        });

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    render() {
        return (
            <Form onSubmit={this.onSubmit} className="secretQuestionsForm" >
                {this.state.isEditMode &&
                    <Row>
                        <Col lg={"12"}>
                            <p className="form-text">Please answer three secret questions and answers only you can answer. In the event that you forget your password, you will be asked to answer these questions to verify your identity before you can receive a new password.</p>
                            <p className="form-text">Secret Questions and Answers must have a minimum of 5 characters.</p>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col lg={this.props.isFirstLogin ? "6" : "3"}>
                        <FormGroup>
                            <Label for="secretQuestion1" className="fw-bold">SECRET QUESTION 1</Label>
                            <p hidden={this.state.isEditMode}>{!this.props.details.secretQuestion1 ? <span>{'-'}</span> : this.props.details.secretQuestion1}</p>
                            <div hidden={!this.state.isEditMode}>
                                <Input type="text" name="secretQuestion1" id="secretQuestion1" placeholder="Question 1" invalid={(!this.state.isQuestion1Valid) ? true : undefined}
                                    onChange={this.handleChange} value={!this.state.user.secretQuestion1 ? '' : this.state.user.secretQuestion1} />
                                <small id="secretQuestion1Required" className="form-text text-muted">Required</small>
                                <div className="invalid-feedback">
                                    {this.state.user.secretQuestion1 && this.state.user.secretQuestion1.trim() !== '' ?
                                        <span>Question length must be at least {this.props.questionLength} characters</span>
                                        : <span>Please provide a valid question</span>}
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg={this.props.isFirstLogin ? "6" : "3"}>
                        <FormGroup>
                            <Label for="secretAnswer1" className="fw-bold">SECRET ANSWER 1</Label>
                            <p hidden={this.state.isEditMode}>{!this.props.details.secretAnswer1 ? <span>{'-'}</span> : this.props.details.secretAnswer1}</p>
                            <div hidden={!this.state.isEditMode}>
                                <Input type="text" name="secretAnswer1" id="secretAnswer1" placeholder="Answer 1" invalid={(!this.state.isAnswer1Valid) ? true : undefined}
                                    onChange={this.handleChange} value={!this.state.user.secretAnswer1 ? '' : this.state.user.secretAnswer1} />
                                <small id="secretAnswer1Required" className="form-text text-muted">Required</small>
                                <div className="invalid-feedback">
                                    {this.state.user.secretAnswer1 && this.state.user.secretAnswer1.trim() !== '' ?
                                        <span>Answer length must be at least {this.props.questionLength} characters</span>
                                        : <span>Please provide a valid answer</span>}
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={this.props.isFirstLogin ? "6" : "3"}>
                        <FormGroup>
                            <Label for="secretQuestion2" className="fw-bold">SECRET QUESTION 2</Label>
                            <p hidden={this.state.isEditMode}>{!this.props.details.secretQuestion2 ? <span>{'-'}</span> : this.props.details.secretQuestion2}</p>
                            <div hidden={!this.state.isEditMode}>
                                <Input type="text" name="secretQuestion2" id="secretQuestion2" placeholder="Question 2" invalid={(!this.state.isQuestion2Valid) ? true : undefined}
                                    onChange={this.handleChange} value={!this.state.user.secretQuestion2 ? '' : this.state.user.secretQuestion2} />
                                <small id="secretQuestion2Required" className="form-text text-muted">Required</small>
                                <div className="invalid-feedback">
                                    {this.state.user.secretQuestion2 && this.state.user.secretQuestion2.trim() !== '' ?
                                        <span>Question length must be at least {this.props.questionLength} characters</span>
                                        : <span>Please provide a valid question</span>}
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg={this.props.isFirstLogin ? "6" : "3"}>
                        <FormGroup>
                            <Label for="secretAnswer2" className="fw-bold">SECRET ANSWER 2</Label>
                            <p hidden={this.state.isEditMode}>{!this.props.details.secretAnswer2 ? <span>{'-'}</span> : this.props.details.secretAnswer2}</p>
                            <div hidden={!this.state.isEditMode}>
                                <Input type="text" name="secretAnswer2" id="secretAnswer2" placeholder="Answer 2" invalid={(!this.state.isAnswer2Valid) ? true : undefined}
                                    onChange={this.handleChange} value={!this.state.user.secretAnswer2 ? '' : this.state.user.secretAnswer2} />
                                <small id="secretAnswer2Required" className="form-text text-muted">Required</small>
                                <div className="invalid-feedback">
                                    {this.state.user.secretAnswer2 && this.state.user.secretAnswer2.trim() !== '' ?
                                        <span>Answer length must be at least {this.props.answerLength} characters</span>
                                        : <span>Please provide a valid answer</span>}
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={this.props.isFirstLogin ? "6" : "3"}>
                        <FormGroup>
                            <Label for="secretQuestion3" className="fw-bold">SECRET QUESTION 3</Label>
                            <p hidden={this.state.isEditMode}>{!this.props.details.secretQuestion3 ? <span>{'-'}</span> : this.props.details.secretQuestion3}</p>
                            <div hidden={!this.state.isEditMode}>
                                <Input type="text" name="secretQuestion3" id="secretQuestion3" placeholder="Question 3" invalid={(!this.state.isQuestion3Valid) ? true : undefined}
                                    onChange={this.handleChange} value={!this.state.user.secretQuestion3 ? '' : this.state.user.secretQuestion3} />
                                <small id="secretQuestion3Required" className="form-text text-muted">Required</small>
                                <div className="invalid-feedback">
                                    {this.state.user.secretQuestion3 && this.state.user.secretQuestion3.trim() !== '' ?
                                        <span>Question length must be at least {this.props.answerLength} characters</span>
                                        : <span>Please provide a valid question</span>}
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg={this.props.isFirstLogin ? "6" : "3"}>
                        <FormGroup>
                            <Label for="secretAnswer3" className="fw-bold">SECRET ANSWER 3</Label>
                            <p hidden={this.state.isEditMode}>{!this.props.details.secretAnswer3 ? <span>{'-'}</span> : this.props.details.secretAnswer3}</p>
                            <div hidden={!this.state.isEditMode}>
                                <Input type="text" name="secretAnswer3" id="secretAnswer3" placeholder="Answer 3" invalid={(!this.state.isAnswer3Valid) ? true : undefined}
                                    onChange={this.handleChange} value={!this.state.user.secretAnswer3 ? '' : this.state.user.secretAnswer3} />
                                <small id="secretAnswer3Required" className="form-text text-muted">Required</small>
                                <div className="invalid-feedback">
                                    {this.state.user.secretAnswer3 && this.state.user.secretAnswer3.trim() !== '' ?
                                        <span>Answer length must be at least {this.props.answerLength} characters</span>
                                        : <span>Please provide a valid answer</span>}
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={this.props.isFirstLogin ? "6" : "3"}>
                        {this.state.isEditMode === true ?
                            <span>
                                <Button type="submit" color="primary" style={{ marginRight: '10px' }}>Save</Button>
                                <Button onClick={this.handleCancel}>Cancel</Button>
                            </span>
                            : <span><Button color="primary" onClick={this.handleEdit} style={{ marginBotton: '20px' }}>Edit</Button></span>}
                    </Col>
                </Row>
            </Form>
        );
    }
}