export const AVAILABILITY_SEARCH_CRITERIA = {
	startDate: "startDate",
	endDate: "endDate",
	siteIdList: "siteIdList",
	additionalCalendarList: "additionalCalendarList",
	serviceTypeId: "serviceTypeId",
	appointmentTypeIdList: "appointmentTypeIdList",
	zipCode: "zipCode",
	serviceNpiList: "serviceNpiList",
	selectedProviderNpiList: "selectedProviderNpiList",
	selectedProviderCalendarList: "selectedProviderCalendarList",
	selectedSiteIdList: "selectedSiteIdList",
	serviceIdList: "serviceIdList",
	specialtyId: "specialtyId",
	assetTags: "assetTags",
	minStartTime: "minStartTime",
	maxStartTime: "maxStartTime",
	patientAge: "patientAge",
}
