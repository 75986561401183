import ErrorBoundary from "../../../components/common/errorBoundary";

export default function Container(props) {
    return (
        <div style={{ marginBottom: '30px' }}>
            <ErrorBoundary childName="Availability Search">
                {props.children}
            </ErrorBoundary>
        </div>
    );
}