import React, { useEffect, useState } from 'react';

import { GuidedResponseResultList } from "./guidedResponseResultList";
import { GuidedResponsePreviousButton } from "./guidedResponsePreviousButton";
import { GuidedResponseNextButton } from "./guidedResponseNextButton";
import { GuidedResponseFlowDebugPathButton } from "./guidedResponseFlowDebugPathButton";
import { NhLoadingIndicatorModal } from "../../nighthawk/common/loadingIndicator/nhLoadingIndicatorModal";
import { GuidedResponseDSVList } from './guidedResponseDSVList';
import { getDSVResults } from '../../actions/guidedResponseActions';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

export function GuidedResponseResults({ debugMode, isLoading, results, nextHandler, previousHandler, getFlowDebugPathFile, isResumingFlow, flowSessionId }) {
	const [dsvResults, setDsvResults] = useState(null)
	useEffect(() => {
		if (!isLoading && !debugMode && !isResumingFlow) nextHandler();
	}, [isLoading, nextHandler, debugMode, isResumingFlow]);

	const dispatch = useDispatch();

	useEffect(() => {
		let isMounted = true;
		if(debugMode) dispatch(getDSVResults(flowSessionId)).then((res) => {if(isMounted) setDsvResults(res.payload.data)}) 
		return () => isMounted = false;
	},[debugMode, flowSessionId, dispatch])

	if (debugMode)
		return (
			<div>
				<GuidedResponseResultList results={results}/>
				{dsvResults != null ? <GuidedResponseDSVList dsvResults={dsvResults}/>: <div><Spinner/> Loading DSV Results...</div>}
				{debugMode && <GuidedResponseFlowDebugPathButton isLoading={isLoading} handler={getFlowDebugPathFile} />}
				<GuidedResponsePreviousButton isLoading={isLoading} handler={previousHandler} />
				<GuidedResponseNextButton isLoading={isLoading} handler={nextHandler} />				
			</div>
		);
		else {
			return <NhLoadingIndicatorModal />;
		}
	}

