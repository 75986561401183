/**
 * @format
 */

import React from 'react';
import { Col, Row, Collapse, Label } from 'reactstrap';

export function CancellationEmailNotification(props) {
	const {
		cancellationEmailConsent,
		cancellationEmailInstructions,
		cancellationEmailOther,
		collapse,
		enableCancellationEmailOptIn,
		enableCancellationEmailOther,
		fieldValidation,
		handleChange,
		handleCheckboxChange,
		patientCancellationEmailDisclaimer,
		patientEmail,
		title,
		toggle,
	} = props;

	return (
		<>
			<Label className="fw-bold mt-2 ml-0">{title.toUpperCase()}</Label>
			<Col>
				{enableCancellationEmailOptIn && (
					<div>
						<label style={{ marginBottom: '.625em' }} className="form-check-label" htmlFor="apptConfirmEmailCheck">
							<input
								onClick={() => {
									toggle('emailConsent');
								}}
								name="cancellationEmailConsent"
								className="form-check-input"
								onChange={(e) => {
									handleCheckboxChange(e, patientEmail ? [] : ['cancellationEmailOther']);
								}}
								type="checkbox"
								value={cancellationEmailConsent}
								id="apptConfirmEmailCheck"
							/>
							Patient Consent: EMAIL Cancellation Confirmation
						</label>
					</div>
				)}
				<Collapse isOpen={collapse.emailConsent}>
					<div>
						{patientCancellationEmailDisclaimer && patientCancellationEmailDisclaimer !== '' && (
							<Row style={{ marginBottom: '.42em' }}>
								<Col lg="8">
									<p dangerouslySetInnerHTML={{ __html: patientCancellationEmailDisclaimer }}></p>
								</Col>
							</Row>
						)}
						{cancellationEmailInstructions && (
							<Row style={{ marginBottom: '.42em' }}>
								<Col lg="8">
									<span className="generalInformation">{cancellationEmailInstructions}</span>
								</Col>
							</Row>
						)}
						{enableCancellationEmailOther && (
							<div>
								<Row>
									<Col lg="6">
										<input
											name="cancellationEmailOther"
											className="form-control"
											type="text"
											onChange={(e) => {
												handleChange(e, 'Email');
											}}
											value={cancellationEmailOther}
											placeholder="Other Email Address"
											id="cancelConfirmEmailCheck"
										></input>
									</Col>
								</Row>
								{fieldValidation['cancellationEmailOther'].valid === false && (
									<div>
										<span className="validationError">* Please enter a valid email address</span>
									</div>
								)}
							</div>
						)}
					</div>
				</Collapse>
			</Col>
		</>
	);
}
