import { validateDateOfBirth, validateDate, validateEmail, validatePhoneNumber, validateZipCode, validateYear } from '../../lib/validation';

export const INPUT_TYPES = {
	BUTTON_OPTIONS: 'buttonOptions',
	CHECKBOX: 'checkbox',
	DATE_OF_BIRTH: 'dateOfBirth',
	DROPDOWN: 'dropdown',
	EMAIL: 'email',
	NUMBER: 'number',
	PHONE_NUMBER: 'phoneNumber',
	TEXT: 'text',
	ZIP_CODE: 'zipCode',
	DATE: 'date',
	RADIO_OPTIONS: 'radioOptions',
	YEAR: 'year',
}

export const DEFAULT_ERROR = (label) => `Please provide a valid ${label}`;
export const TYPE_VALIDATORS = {
	[INPUT_TYPES.DATE_OF_BIRTH]: validateDateOfBirth,
	[INPUT_TYPES.EMAIL]: validateEmail,
	[INPUT_TYPES.PHONE_NUMBER]: validatePhoneNumber,
	[INPUT_TYPES.ZIP_CODE]: validateZipCode,
	[INPUT_TYPES.DATE]: validateDate,
	[INPUT_TYPES.YEAR]: validateYear,
}

export const COMMON_RULE_NAMES = {
	REQUIRED: "required",
	INPUT_TYPE: "inputType",
}
