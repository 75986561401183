import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, FormGroup } from "reactstrap"

const LoginButton = (props: {
    isLoading: boolean,
    disabled: boolean,
    text: string,
    loadingText: string
}) => {
    return (
        <FormGroup className="loginButton">
            <Button type="submit" className="experian-btn" block disabled={props.isLoading}>
                {props.isLoading ? (
                    <span>
                        <FontAwesomeIcon icon="spinner" spin /> {props.loadingText}
                    </span>
                ) : (
                    <span>{props.text}</span>
                )}
            </Button>
        </FormGroup>
    )
}

export default LoginButton;