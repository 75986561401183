import React from 'react';

export function GuidedResponseResult({ fieldName, fieldValue }) {
	return (
		<tr>
			<td>{fieldName}</td>
			<td>{fieldValue}</td>
		</tr>
	);
}

export function GuidedResponseResultList({ results }) {
	return (
		<div>
			<table className="results" >
				<thead><tr><th>Field Name</th><th>Field Value</th></tr></thead>
				<tbody>
					{results.fields.map(field => (
						<GuidedResponseResult key={field.fieldName} fieldName={field.fieldName} fieldValue={field.currentValue} />
					))}
				</tbody>
			</table>
		</div>
	);
}