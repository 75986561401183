import * as React from 'react';
import moment from 'moment';
import DropdownList from '../input/dropdownList';
import { Row, Col, Collapse } from 'reactstrap';
import { PhoneNumberInput } from '../input/index';
import './patientNotifications.css';

export default function PatientNotifications(props) {
	const {
		collapse,
		config,
		confirmationEmailConsent,
		confirmationEmailInstructions,
		confirmationEmailOther,
		disableTextReminderNumber,
		disableTextReminderOtherNumber,
		disableVoiceReminderNumber,
		disableVoiceReminderOtherNumber,
		emailReminderConsent,
		emailReminderLeadTime,
		enableConfirmationEmailInstructions,
		enableConfirmationEmailOptIn,
		enableConfirmationEmailOther,
		enableEmailReminderInstructions,
		enableSmsReminderOptions,
		enableVoiceReminderOptions,
		fieldValidation,
		formValidation,
		handleChange,
		handleCheckboxChange,
		handleDropdownChange,
		isRequestAppointment,
		maxReminderLeadHours,
		otherPhoneNumber,
		patientConfirmationEmailDisclaimer,
		patientDetails,
		patientReminderEmailDisclaimer,
		patientReminderSmsDisclaimer,
		patientReminderVoiceDisclaimer,
		phones,
		reminderEmailOther,
		reservation,
		reservationDetails,
		selectedLanguage,
		shouldSendRequestConfirmationEmail,
		showEmailConfirmation,
		showEmailReminder,
		showTextReminder,
		showTopHorizontalDivider,
		showVoiceReminder,
		textReminderConsent,
		textReminderLeadTime,
		textReminderNumber,
		textReminderOtherNumber,
		title,
		toggle,
		voiceReminderConsent,
		voiceReminderLeadTime,
		voiceReminderNumber,
		voiceReminderOtherNumber,
	} = props;

	let defaultTitle = 'Patient Notifications';
	let showContext = showEmailReminder || showVoiceReminder || showTextReminder || showEmailConfirmation;
	let reservationHasMultipleLanguages = Boolean(reservationDetails?.notificationLanguages?.length > 1);

	const voiceReminderPhones = phones ? [...phones] : [];
	config.voiceReminderOtherPhoneAllowed && phones && voiceReminderPhones.push({ type: 'Other Phone Number', number: otherPhoneNumber });

	const textReminderPhones = phones ? [...phones] : [];
	config.textReminderOtherPhoneAllowed && phones && textReminderPhones.push({ type: 'Other Phone Number', number: otherPhoneNumber });

	return (
		<div>
			{showEmailReminder | showVoiceReminder | showTextReminder | showEmailConfirmation ? (
				<div>
					<Row>
						<Col lg="12">
							{showTopHorizontalDivider && <hr />}
							<h6 className="title">{title ? title : defaultTitle}</h6>
						</Col>
					</Row>
					<Row>
						<Col lg="12">
							<form>
								<div style={{ marginLeft: '30px' }} className="form-group">
									{showEmailConfirmation ? (
										<div>
											{isRequestAppointment && (
												<div>
													<label
														style={{ marginBottom: '.625em' }}
														className="form-check-label"
														htmlFor="apptConfirmEmailCheck"
													>
														<input
															onClick={() => {
																toggle('emailRequestConsent');
															}}
															name="confirmationEmailConsent"
															className="form-check-input"
															onChange={(e) => {
																handleCheckboxChange(e, patientDetails.email ? [] : ['confirmationEmailOther']);
															}}
															type="checkbox"
															value={shouldSendRequestConfirmationEmail}
															id="apptRequestConfirmEmailCheck"
														/>
														Patient Consent: EMAIL Appointment Request Confirmation
													</label>
												</div>
											)}
											{isRequestAppointment && (
												<Collapse isOpen={collapse.emailRequestConsent}>
													<div>
														{patientConfirmationEmailDisclaimer && patientConfirmationEmailDisclaimer !== '' && (
															<Row style={{ marginBottom: '.42em' }}>
																<Col lg="8">
																	<p dangerouslySetInnerHTML={{ __html: patientConfirmationEmailDisclaimer }}></p>
																</Col>
															</Row>
														)}
														{enableConfirmationEmailInstructions && (
															<Row style={{ marginBottom: '.42em' }}>
																<Col lg="8">
																	<span className="generalInformation">{confirmationEmailInstructions}</span>
																</Col>
															</Row>
														)}
														{enableConfirmationEmailOther && (
															<div>
																<Row>
																	<Col lg="6">
																		<input
																			name="confirmationEmailOther"
																			className="form-control"
																			type="text"
																			onChange={(e) => {
																				handleChange(e, 'Email');
																			}}
																			value={confirmationEmailOther}
																			placeholder="Other Email Address"
																			id="apptConfirmEmailCheck"
																		></input>
																	</Col>
																</Row>
																{fieldValidation['confirmationEmailOther'].valid === false && (
																	<div>
																		<span className="validationError">* Please enter a valid email address</span>
																	</div>
																)}
															</div>
														)}
													</div>
												</Collapse>
											)}
											{enableConfirmationEmailOptIn && (
												<div>
													<label
														style={{ marginBottom: '.625em' }}
														className="form-check-label"
														htmlFor="apptConfirmEmailCheck"
													>
														<input
															onClick={() => {
																toggle('emailConsent');
															}}
															name="confirmationEmailConsent"
															className="form-check-input"
															onChange={(e) => {
																handleCheckboxChange(e, patientDetails.email ? [] : ['confirmationEmailOther']);
															}}
															type="checkbox"
															value={confirmationEmailConsent}
															id="apptConfirmEmailCheck"
														/>
														Patient Consent: EMAIL Appointment Confirmation{' '}
														{isRequestAppointment && <span>(when appointment is scheduled)</span>}
													</label>
												</div>
											)}
											<Collapse isOpen={collapse.emailConsent}>
												<div>
													{patientConfirmationEmailDisclaimer && patientConfirmationEmailDisclaimer !== '' && (
														<Row style={{ marginBottom: '.42em' }}>
															<Col lg="8">
																<p dangerouslySetInnerHTML={{ __html: patientConfirmationEmailDisclaimer }}></p>
															</Col>
														</Row>
													)}
													{enableConfirmationEmailInstructions && (
														<Row style={{ marginBottom: '.42em' }}>
															<Col lg="8">
																<span className="generalInformation">{confirmationEmailInstructions}</span>
															</Col>
														</Row>
													)}
													{enableConfirmationEmailOther && (
														<div>
															<Row>
																<Col lg="6">
																	<input
																		name="confirmationEmailOther"
																		className="form-control"
																		type="text"
																		onChange={(e) => {
																			handleChange(e, 'Email');
																		}}
																		value={confirmationEmailOther}
																		placeholder="Other Email Address"
																		id="apptConfirmEmailCheck"
																	></input>
																</Col>
															</Row>
															{fieldValidation['confirmationEmailOther'].valid === false && (
																<div>
																	<span className="validationError">* Please enter a valid email address</span>
																</div>
															)}
														</div>
													)}
												</div>
											</Collapse>
										</div>
									) : null}
									{showEmailReminder ? (
										<div>
											<div style={{ marginTop: '.42em' }}>
												<label
													style={{ marginBottom: '.625em' }}
													className="form-check-label"
													htmlFor="apptEmailReminderCheck"
												>
													<input
														onClick={() => {
															toggle('email');
														}}
														name="emailReminderConsent"
														className="form-check-input"
														type="checkbox"
														onChange={(e) => {
															handleCheckboxChange(
																e,
																patientDetails.email
																	? ['emailReminderLeadTime']
																	: ['reminderEmailOther', 'emailReminderLeadTime'],
															);
														}}
														value={emailReminderConsent}
														id="apptEmailReminderCheck"
													/>
													Patient Consent: EMAIL Appointment Reminder{' '}
													{isRequestAppointment && <span>(when appointment is scheduled)</span>}
												</label>
											</div>
											<Collapse isOpen={collapse.email}>
												<div>
													{patientReminderEmailDisclaimer && patientReminderEmailDisclaimer !== '' && (
														<Row style={{ marginBottom: '.42em' }}>
															<Col lg="6">
																<p dangerouslySetInnerHTML={{ __html: patientReminderEmailDisclaimer }}></p>
															</Col>
														</Row>
													)}
													{enableEmailReminderInstructions && (
														<Row style={{ marginBottom: '.42em' }}>
															<Col lg="12">
																<span className="generalInformation" style={{ marginBottom: '.42em' }}>
																	{patientDetails.email !== ''
																		? 'Reminder will be sent to ' +
																		  patientDetails.email +
																		  '.  Optionally, you may override that email below.'
																		: 'No email address in patient profile'}
																</span>
															</Col>
														</Row>
													)}
													<Row>
														<Col lg="6">
															<input
																name="reminderEmailOther"
																className="form-control"
																type="text"
																onChange={(e) => {
																	handleChange(e, 'Email');
																}}
																value={reminderEmailOther}
																placeholder={'Enter Reminder Email Address'}
																id={'reminderEmailOther'}
															></input>
														</Col>
													</Row>
													{fieldValidation['reminderEmailOther'].valid === false && (
														<div>
															<span className="validationError">* Please enter a valid email address</span>
														</div>
													)}
												</div>
												{enableEmailReminderInstructions && reservation && (
													<div>
														<div style={{ marginTop: '.42em' }}>
															How many hours prior to appointment time would you like the EMAIL reminder to be sent?
														</div>
														<Row style={{ marginTop: '.42em' }}>
															<Col lg="1">
																<input
																	name="emailReminderLeadTime"
																	min={config.minEmailReminderLeadTime}
																	max={maxReminderLeadHours}
																	className="form-control"
																	type="number"
																	onKeyPress={(event) => {
																		if (!/[0-9]/.test(event.key)) {
																			event.preventDefault();
																		}
																	}}
																	onChange={(e) => {
																		handleChange(e, 'Int32');
																	}}
																	value={emailReminderLeadTime}
																	placeholder="Enter Hours"
																	id="apptReminderEmailLeadTime"
																></input>
															</Col>
															<Col style={{ marginTop: '.42em' }}>
																{emailReminderLeadTime > 0 && reservation.patientAppointmentDateTime && (
																	<span className="generalInformation">
																		{' '}
																		EMAIL reminder will be sent at{' '}
																		{moment(reservation.patientAppointmentDateTime)
																			.subtract(emailReminderLeadTime, 'h')
																			.format('h:mm a on MM/DD/YYYY')}
																	</span>
																)}
															</Col>
														</Row>
														{fieldValidation['emailReminderLeadTime'].valid === false &&
															emailReminderLeadTime > maxReminderLeadHours && (
																<div>
																	<span className="validationError">* Lead time cannot be earlier than today.</span>
																</div>
															)}
														{fieldValidation['emailReminderLeadTime'].valid === false &&
															emailReminderLeadTime < config.minEmailReminderLeadTime && (
																<div>
																	<span className="validationError">
																		* Please enter a value greater than {config.minEmailReminderLeadTime}
																	</span>
																</div>
															)}
													</div>
												)}
											</Collapse>
										</div>
									) : null}
									{showVoiceReminder ? (
										<div>
											<div style={{ marginTop: '.42em' }}>
												<label
													style={{ marginBottom: '.625em' }}
													className="form-check-label"
													htmlFor="apptVoiceReminderCheck"
												>
													<input
														onClick={() => {
															toggle('voice');
														}}
														name="voiceReminderConsent"
														className="form-check-input"
														onChange={(e) => {
															handleCheckboxChange(e, [
																'voiceReminderLeadTime',
																'voiceReminderNumber',
																'voiceReminderOtherNumber',
															]);
														}}
														type="checkbox"
														value={voiceReminderConsent}
														id="apptVoiceReminderCheck"
													/>
													Patient Consent: VOICE Appointment Reminder{' '}
													{isRequestAppointment && <span>(when appointment is scheduled)</span>}
												</label>
											</div>
											<Collapse isOpen={collapse['voice']}>
												<div>
													{patientReminderVoiceDisclaimer && patientReminderVoiceDisclaimer !== '' && (
														<Row style={{ marginBottom: '.42em' }}>
															<Col lg="6">
																<p dangerouslySetInnerHTML={{ __html: patientReminderVoiceDisclaimer }}></p>
															</Col>
														</Row>
													)}
													<Row style={{ marginBottom: '8px' }}>
														<Col lg="6">
															{enableVoiceReminderOptions && (
																<DropdownList
																	className="reminderOptions"
																	isDisabled={disableVoiceReminderNumber}
																	name={'voiceReminderNumber'}
																	id={'voiceReminderNumber'}
																	maxheight={250}
																	placeholder={'Select voice reminder phone'}
																	list={voiceReminderPhones}
																	listkey={'type'}
																	listvalue={'number'}
																	value={voiceReminderNumber}
																	onChange={(name, obj) => {
																		handleDropdownChange(name, 'phone', obj);
																	}}
																/>
															)}
															{!disableVoiceReminderOtherNumber && (
																<PhoneNumberInput
																	disabled={disableVoiceReminderOtherNumber ? 'disabled' : false}
																	style={{ width: '250px' }}
																	name="voiceReminderOtherNumber"
																	className="form-control"
																	onChange={(e) => {
																		handleChange(e, 'Phone');
																	}}
																	value={voiceReminderOtherNumber}
																	placeholder={'Enter Other Phone Number'}
																	id="voiceReminderOtherNumber"
																	shouldvalidate="true"
																/>
															)}
															{(enableVoiceReminderOptions &&
																(fieldValidation['voiceReminderNumber'].valid === false ||
																	fieldValidation['voiceReminderOtherNumber'].valid === false) && (
																<div>
																	<span className="validationError">
																		{!disableVoiceReminderOtherNumber &&
																			voiceReminderNumber === otherPhoneNumber
																			? `* Please enter a valid phone number`
																			: `* Please select a phone number`}
																	</span>
																</div>
															)
															)}
														</Col>
													</Row>
												</div>
												{enableVoiceReminderOptions && reservation && (
													<div>
														<div style={{ marginTop: '.42em' }}>
															How many hours prior to appointment time would you like the VOICE reminder to be sent?
														</div>
														<Row style={{ marginTop: '.42em' }}>
															<Col lg="1">
																<input
																	name="voiceReminderLeadTime"
																	min={config.minVoiceReminderLeadTime}
																	max={maxReminderLeadHours}
																	className="form-control"
																	onKeyPress={(event) => {
																		if (!/[0-9]/.test(event.key)) {
																			event.preventDefault();
																		}
																	}}
																	type="number"
																	onChange={(e) => {
																		handleChange(e, 'Int32');
																	}}
																	value={voiceReminderLeadTime}
																	placeholder="Enter Hours"
																	id="apptReminderVoiceLeadTime"
																></input>
															</Col>
															<Col style={{ marginTop: '.42em' }}>
																{voiceReminderLeadTime > 0 && reservation.patientAppointmentDateTime && (
																	<span className="generalInformation">
																		{' '}
																		VOICE reminder will be sent at{' '}
																		{moment(reservation.patientAppointmentDateTime)
																			.subtract(voiceReminderLeadTime, 'h')
																			.format('h:mm a on MM/DD/YYYY')}
																	</span>
																)}
															</Col>
														</Row>
														{fieldValidation['voiceReminderLeadTime'].valid === false &&
															voiceReminderLeadTime > maxReminderLeadHours && (
																<div>
																	<span className="validationError">* Lead time cannot be earlier than today.</span>
																</div>
															)}
														{fieldValidation['voiceReminderLeadTime'].valid === false &&
															voiceReminderLeadTime < config.minVoiceReminderLeadTime && (
																<div>
																	<span className="validationError">
																		* Please enter a value greater than {config.minVoiceReminderLeadTime}
																	</span>
																</div>
															)}
														{formValidation['voiceReminderLeadTime'].valid === false && (
															<div>
																<span className="validationError">
																	* Based on TCPA guidelines, Voice Reminders can ONLY be sent between 8am - 9pm. Please
																	adjust your hours accordingly.
																</span>
															</div>
														)}
													</div>
												)}
											</Collapse>
										</div>
									) : null}
									{showTextReminder ? (
										<div>
											<div style={{ marginTop: '.42em' }}>
												<label
													style={{ marginBottom: '.625em' }}
													className="form-check-label"
													htmlFor="apptTextReminderCheck"
												>
													<input
														onClick={() => {
															toggle('text');
														}}
														name="textReminderConsent"
														className="form-check-input"
														type="checkbox"
														onChange={(e) => {
															handleCheckboxChange(e, [
																'textReminderLeadTime',
																'textReminderNumber',
																'textReminderOtherNumber',
															]);
														}}
														value={textReminderConsent}
														id="apptTextReminderCheck"
													/>
													Patient Consent: TEXT Appointment Reminder{' '}
													{isRequestAppointment && <span>(when appointment is scheduled)</span>}
												</label>
											</div>
											<Collapse isOpen={collapse['text']}>
												<div>
													{patientReminderSmsDisclaimer && patientReminderSmsDisclaimer !== '' && (
														<Row style={{ marginBottom: '.42em' }}>
															<Col lg="6">
																<p dangerouslySetInnerHTML={{ __html: patientReminderSmsDisclaimer }}></p>
															</Col>
														</Row>
													)}
													<Row>
														<Col lg="6">
															{enableSmsReminderOptions && (
																<DropdownList
																	className="reminderOptions"
																	isDisabled={disableTextReminderNumber}
																	name={'textReminderNumber'}
																	id={'textReminderNumber'}
																	maxheight={250}
																	placeholder={'Select text reminder phone'}
																	list={textReminderPhones}
																	listkey={'type'}
																	listvalue={'number'}
																	value={textReminderNumber}
																	onChange={(name, obj) => {
																		handleDropdownChange(name, 'phone', obj);
																	}}
																/>
															)}
															{!disableTextReminderOtherNumber && (
																<PhoneNumberInput
																	disabled={disableTextReminderOtherNumber ? 'disabled' : false}
																	style={{ width: '250px' }}
																	name="textReminderOtherNumber"
																	className="form-control"
																	onChange={(e) => {
																		handleChange(e, 'Phone');
																	}}
																	value={textReminderOtherNumber}
																	placeholder={'Enter Other Phone Number'}
																	id="textReminderOtherNumber"
																	shouldvalidate="true"
																/>
															)}
															{(enableSmsReminderOptions &&
																(fieldValidation['textReminderNumber'].valid === false ||
																	fieldValidation['textReminderOtherNumber'].valid === false) && (
																	<div>
																		<span className="validationError">
																		{!disableTextReminderOtherNumber &&
																			textReminderNumber === otherPhoneNumber
																			? `* Please enter a valid phone number`
																			: `* Please select a phone number`}
																	</span>
																</div>
															)
															)}
														</Col>
													</Row>
												</div>
												{enableSmsReminderOptions && reservation && (
													<div>
														<div style={{ marginTop: '.42em' }}>
															How many hours prior to appointment time would you like the TEXT reminder to be sent?
														</div>
														<Row style={{ marginTop: '.42em' }}>
															<Col lg="1">
																<input
																	name="textReminderLeadTime"
																	min={config.minTextReminderLeadTime}
																	max={maxReminderLeadHours}
																	className="form-control"
																	type="number"
																	onKeyPress={(event) => {
																		if (!/[0-9]/.test(event.key)) {
																			event.preventDefault();
																		}
																	}}
																	onChange={(e) => {
																		handleChange(e, 'Int32');
																	}}
																	value={textReminderLeadTime}
																	placeholder="Enter Hours"
																	id="apptReminderTextLeadTime"
																></input>
															</Col>
															<Col style={{ marginTop: '.42em' }}>
																{textReminderLeadTime > 0 && reservation.patientAppointmentDateTime && (
																	<span className="generalInformation">
																		{' '}
																		TEXT reminder will be sent at{' '}
																		{moment(reservation.patientAppointmentDateTime)
																			.subtract(textReminderLeadTime, 'h')
																			.format('h:mm a on MM/DD/YYYY')}
																	</span>
																)}
															</Col>
														</Row>
														{fieldValidation['textReminderLeadTime'].valid === false &&
															textReminderLeadTime > maxReminderLeadHours && (
																<div>
																	<span className="validationError">* Lead time cannot be earlier than today.</span>
																</div>
															)}
														{fieldValidation['textReminderLeadTime'].valid === false &&
															textReminderLeadTime < config.minTextReminderLeadTime && (
																<div>
																	<span className="validationError">
																		* Please enter a value greater than {config.minTextReminderLeadTime}
																	</span>
																</div>
															)}
														{formValidation['textReminderLeadTime'].valid === false && (
															<div>
																<span className="validationError">
																	* Based on TCPA guidelines, Text Reminders can ONLY be sent between 8am - 9pm. Please
																	adjust your hours accordingly.
																</span>
															</div>
														)}
													</div>
												)}
											</Collapse>
										</div>
									) : null}
									{showContext || reservationHasMultipleLanguages || !reservation ? (
										<Row>
											<Col lg="2">
												<div style={{ marginTop: '.42em', marginBottom: '.42em' }}>
													Select a notification language:{' '}
												</div>
												<DropdownList
													name={'selectedLanguage'}
													id={'selectedLanguage'}
													maxheight={250}
													placeholder={'Select notification language'}
													list={
														reservationDetails?.notificationLanguages?.length > 0
															? reservationDetails.notificationLanguages
															: [
																	{ languageName: 'English', idLanguage: 1 },
																	{ languageName: 'Spanish', idLanguage: 2 },
															  ]
													}
													listkey={'languageName'}
													listvalue={'idLanguage'}
													value={selectedLanguage}
													onChange={(name, obj) => {
														handleDropdownChange(name, 'int32', obj);
													}}
												/>
											</Col>
										</Row>
									) : null}
								</div>
							</form>
						</Col>
					</Row>
				</div>
			) : null}
		</div>
	);
}
