import cookie from 'js-cookie';

export const authCookie = {
	/**
	 * @return {{ token?: string, productInstanceId?: number, referralSiteId?: number }}
	 */
	get() {
		try {
			const authCookie = cookie.get('auth');
			const auth = authCookie && JSON.parse(authCookie);
			return auth || {};
		} catch (ex) {
			console.warn(`Unable to read auth from cookie: ${ex}`);
			return {};
		}
	},

	/**
	 * @param {{ token?: string, productInstanceId?: number, referralSiteId?: number }} auth
	 */
	set(auth) {
		cookie.set('auth', JSON.stringify(auth));
	},

	clear() {
		cookie.remove('auth');
	},
};

export default authCookie;
