export interface CareOrderDetails {
    id: number;
    systemId: number;
    patientReferenceId: string;
    userId: string;
    careOrderStatusReasonId: number;
    idMhdD2cClientInstance: number | null;
    idProductInstance: number | null;
    careOrderType: CareOrderTypeEnum;
    expirationDate: string | null;
    currentFailedAuthCount: number;
    lastFailedAuthAt: string | null;
    lockoutEndAt: string | null;
    visits: CareOrderVisitDetails[];
    customFields: CareOrderCustomFieldInfo[];
    consumerIdProductInstance: number | null;
    externalReferralOrderId: string;
    sendNotificationsAfterCreate: boolean;
    decisionSupportSessionId: string;
}

export enum CareOrderTypeEnum {
    ReferralOrder = 1,
    FollowUpOrder = 2,
    AppointmentOrder = 3,
    CareGap = 4,
    PatientRequestedDecisionSupportOutput = 5,
}

export interface CareOrderVisitDetails {
    systemDateRangeId: number | null;
    minVisitDate: string;
    maxVisitDate: string;
    referenceId: string | null;
    createdAt?: string | undefined;
    createdBy?: string | undefined;
    updatedAt?: string | undefined;
    updatedBy?: string | undefined;
    appointments: CareOrderVisitAppointmentDetails[];
}

export interface CareOrderVisitAppointmentDetails {
    orderIndex: number;
    siteId: number | null;
    serviceId: number | null;
    appointmentTypeId: number | null;
    specialtyId: number | null;
    referringSiteId: number | null;
    referringServiceId: number | null;
    unmanagedReferringSiteName: string;
    unmanagedReferringServiceName: string;
    reasonForVisit: string;
    notes: string;
    createdAt?: string | undefined;
    createdBy?: string | undefined;
    updatedAt?: string | undefined;
    updatedBy?: string | undefined;
    externalReferralOrderId: string;
    subgroupId: number | null;
    customFields: CareOrderCustomFieldInfo[];
}

export interface CareOrderCustomFieldInfo {
    fieldName: string;
    fieldValue: string;
}