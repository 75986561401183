/**
 * @format
 */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as authActions from '../../actions/authActions';
import qs from 'qs';
import './css/login.scss';
import LoginForm from "./comps/loginForm"
import UserViewContainer from "./comps/userViewContainer"
import ProductAlert from "./comps/productAlert"
import { useAppSelector } from 'store';
import LoginLogo from './comps/loginLogo';
import LoginFooter from './comps/loginFooter';
import ContactUs from './comps/contactUs';

const LoginView = () => {

    const dispatch = useDispatch();

    const externalAuth: any = useAppSelector((state: any) => state.config.externalAuth);
    const instance: any = useAppSelector((state: any) => state.config.instance);
    const instanceId: any = useAppSelector((state: any) => state.auth.productInstanceId);
    const isAuthenticated: any = useAppSelector((state: any) => state.auth.isAuthenticated);

    const location = useLocation();
    const [error, setError] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);
    const { from } = location.state as any || { pathname: '/' };

    const handleSso = useCallback(() => {
        const returnUrl = from ? `${from.pathname || '/'}${from.search || ''}` : '/';
        const url = externalAuth.singleSignOnUrl || '/';
        window.location.href = `${url}?returnUrl=${encodeURIComponent(returnUrl)}`;
    }, [from, externalAuth.singleSignOnUrl]);

    useEffect(() => {
        if (!isAuthenticated && externalAuth.isAutoLoginEnabled) {
            handleSso();
        } else if (instance.isLoaded) {
            setShowLoadingIndicator(false);
        }
    }, [isAuthenticated, externalAuth, instance, handleSso]);

    useEffect(() => {
        let queryString = location && location.search ? location.search : null;
        let rawParams = queryString ? qs.parse(queryString, { ignoreQueryPrefix: true }) : {};
        const optionalParams: { [key: string]: any } = {};
        Object.keys(rawParams).forEach((key) => {
            optionalParams[key.toLowerCase()] = rawParams[key];
        });

        dispatch(authActions.setGRDebugContext({ debug: optionalParams.debug === 'on' }))

    }, [dispatch, location]);

    const handleAuthenticateUser = async (email: any, password: any) => {
        setLoggingIn(true);
        try {
            const response: any = await dispatch(authActions.authenticateUser(email, password, instanceId))
            if (response.error) {
                if (response.error.response.data && typeof response.error.response.data === 'string') {
                    setError(response.error.response.data);
                } else {
                    const request = response.error.request;
                    const errorMessage = request.responseText.replace(/['"]+/g, '');
                    setError(`An error occurred while trying to log in. ${errorMessage}`);
                }
            } else {
                setRedirectToReferrer(true);
            }
        } catch {
            setError('An error occurred while trying to log in.');
        } finally {
            setShowLoadingIndicator(false);
            setLoggingIn(false);
        }
    };

    if (redirectToReferrer && !loggingIn) {
        return <Redirect to={from} />;
    }

    return (
        <UserViewContainer isLoading={showLoadingIndicator} className='loginView'>
            <div className="loginWindow">
                <ProductAlert />
                <LoginLogo />
                <LoginForm
                    handleSubmit={handleAuthenticateUser}
                    handleSsoLogin={handleSso}
                    isLoading={loggingIn}
                    error={error}
                />
                <LoginFooter />
                <ContactUs />
            </div>
        </UserViewContainer>
    )
}

export default LoginView;