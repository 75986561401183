import React from 'react';
import PropTypes from 'prop-types'

export default class AppliedPathwaysQuestion_YesNo extends React.Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.asset === prevState.asset) {
			return null;
		}
		var selectedAnswer = AppliedPathwaysQuestion_YesNo.getSelectedAnswer(nextProps.asset);
		if (selectedAnswer === prevState.value) {
			return null;
		}
		return {
			asset: nextProps.asset,
			value: selectedAnswer
		};
	}

	static getSelectedAnswer(asset) {
		var selectedAnswer = '';
		asset.ap_answers.map((item) => {
			if (item.value) {
				selectedAnswer = item.id;
			}
			return null;
		});
		return selectedAnswer;
	}

	constructor(props) {
		super(props);
		this.state = {
			asset: {},
			value: ''
		};
	}

	componentDidMount() {
		var selectedAnswer = AppliedPathwaysQuestion_YesNo.getSelectedAnswer(this.props.asset);
		this.setState({ asset: this.props.asset, value: selectedAnswer });
	}

	handleChange = ({ target: { name, value } }) => {
		const { onChange } = this.props;
		this.setState({ value });
		return onChange({ target: { name, value } });
	}

	render() {
		let answersLength = this.props.asset.ap_answers.length;
		let answers = this.props.asset.ap_answers.map((item, key) =>
			<div key={key} className="form-check form-check-inline">
				<input className='ap-question form-check-input' value={item.id} type='radio' id={this.props.asset.id + key} required={this.props.asset.is_required} name={this.props.asset.id} checked={item.id === this.state.value} onChange={this.handleChange} />
				<label className='form-check-label' htmlFor={this.props.asset.id + key}>
					{item.ap_literacy.base}
				</label>
				{
					(answersLength === key + 1) &&
					<div className="invalid-feedback" style={{ paddingLeft: '10px' }} />
				}
			</div>
		);
		return (
			<div>
				{answers}
			</div>
		);
	}
}

AppliedPathwaysQuestion_YesNo.propTypes = {
	asset: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

