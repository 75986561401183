import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from '../../actions/modalActions';
import Modal from '../modalComponent';

class Notification extends React.Component {
	constructor(props) {
		super(props);

		this.onClose = this.onClose.bind(this);
	}

	onClose() {
		this.props.hideModal();

		if (this.props.afterClose) {
			this.props.afterClose();
		}
	}

	render() {
		return (
			<Modal onClose={this.onClose} {...this.props}>
				{this.props.children}
			</Modal>
		);
	}
}

export default connect(null, { hideModal })(Notification);
