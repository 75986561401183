import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

export default function AgentInstructions(props) {
	const { instructions } = props;

		return (
				<div>
					<Row className='pt-2 mb-2'>
						<Col lg="8">
							<h6 style={{ "marginBottom": "15px", "marginTop": "5px" }}>Agent Instructions</h6>
							<p style={{ "marginBottom": "10px" }}>{instructions}</p>
						</Col>
					</Row>
				</div>
		)
}

AgentInstructions.propTypes = {
		instructions: PropTypes.string,
}
