import * as React from 'react';
import PropTypes from 'prop-types';
import DropdownList from '../input/dropdownList';
import { Label } from 'reactstrap';

export default function ConsumerProductInstance(props) {
    const { consumerProductInstances, fieldValidation, handleDropdownChange, selectedConsumerProductInstanceId } = props;
    return (
        <div>
            <Label className="fw-bold" for={"selectedConsumerProductInstanceId"}>SELECT SELF SCHEDULING PRODUCT INSTANCE</Label>
            <DropdownList
                name={'selectedConsumerProductInstanceId'}
                id={'selectedConsumerProductInstanceId'}
                maxheight={250}
                list={consumerProductInstances}
                listkey={"name"}
                listvalue={"id"}
                value={selectedConsumerProductInstanceId}
                onChange={(name, obj) => { handleDropdownChange(name, 'Int32', obj); }} />

                { fieldValidation.selectedConsumerProductInstanceId.required &&
                    <small id={props.id + "Required"} className="form-text text-muted">Required</small>
                }
                { !fieldValidation.selectedConsumerProductInstanceId.valid &&
                    <div><span style={{ "color": "red" }}>* Please select a self-schedule product instance</span></div>
                }
        </div>
	);
}

ConsumerProductInstance.propTypes = {
  fieldValidation: PropTypes.array,
  handleDropdownChange: PropTypes.func,
  consumerProductInstances: PropTypes.array,
  selectedConsumerProductInstanceId: PropTypes.number,
}
