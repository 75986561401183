/**
 * @format
 */

import moment from 'moment';
import * as rd2ApiManager from '../lib/rd2ApiManager';
import * as routes from '../routes';
import * as actionTypes from './actionTypes';
import * as appointmentActions from './appointmentActions';
import * as availabilitySearchActions from './availabilitySearchActions';
import * as customFieldActions from './customFieldActions';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

function cancelAppointmentAction(appointmentId, cancelRequest, token) {
	return {
		type: actionTypes.APPOINTMENT_CANCEL,
		payload: {
			request: rd2ApiManager.createRequest_CancelAppointment(appointmentId, cancelRequest, token),
		},
	};
}

function clearActivePatientAction() {
	return {
		type: actionTypes.CLEAR_ACTIVE_PATIENT,
	};
}

function getPatientHistoryAction(referenceId) {
	return {
		type: actionTypes.PATIENT_HISTORY,
		payload: {
			request: rd2ApiManager.createRequest_GetPatientHistory(referenceId),
		},
		patientRef: referenceId,
	};
}

function getPatientCareOrderHistoryAction(referenceId) {
	return {
		type: actionTypes.PATIENT_CARE_ORDER_HISTORY,
		payload: {
			request: rd2ApiManager.createRequest_GetPatientCareOrderHistory(referenceId),
		},
		patientRef: referenceId,
	};
}

function getPatientOpenWorkflowsAction(referenceId, maxAgeInDays = 15) {
	return {
		type: actionTypes.PATIENT_OPEN_WORKFLOWS,
		payload: {
			request: rd2ApiManager.createRequest_GetPatientOpenWorkflows(referenceId, maxAgeInDays),
		},
		patientRef: referenceId,
	};

}

function validateZipCodeAction(zipCode) {
	return {
		type: actionTypes.VALIDATE_ZIPCODE,
		payload: {
			request: rd2ApiManager.createRequest_ValidateZipCode(zipCode),
		},
	};
}

function getPatientDetailsAction(referenceId) {
	return {
		type: actionTypes.SET_ACTIVE_PATIENT_DETAILS,
		payload: {
			request: rd2ApiManager.createRequest_GetPatientDetails(referenceId),
		},
	};
}

function lookupPatientAction(idValue, idType) {
	return {
		type: actionTypes.GET_PATIENT_ID_BY_IDENTITY,
		payload: {
			request: rd2ApiManager.createRequest_LookupPatient(idValue, idType),
		},
	};
}

function getPatientSchedulableOrdersAction(idValue, idType) {
	return {
		type: actionTypes.GET_PATIENT_SCHEDULABLE_ORDERS,
		payload: {
			request: rd2ApiManager.createRequest_GetPatientSchedulableOrders(idValue, idType),
		},
	};
}

function searchPatientAction(patientSearchCriteria) {
	return {
		type: actionTypes.SEARCH_PATIENT,
		payload: {
			request: rd2ApiManager.createRequest_SearchPatient(patientSearchCriteria),
		},
	};
}

function createPatientAction(patientDetails) {
	return {
		type: actionTypes.PATIENT_SAVE,
		payload: {
			request: rd2ApiManager.createRequest_CreatePatient(patientDetails),
		},
	};
}

function updatePatientAction(patientDetails) {
	return {
		type: actionTypes.PATIENT_UPDATE,
		payload: {
			request: rd2ApiManager.createRequest_UpdatePatient(patientDetails),
		},
	};
}

function ensurePatientAction(patient) {
	return {
		type: actionTypes.ENSURE_PATIENT,
		payload: {
			request: rd2ApiManager.createRequest_CreatePatientFromEmrSearchResult(patient),
		},
	};
}

function setActivePatientAction(referenceId) {
	return {
		type: actionTypes.SET_ACTIVE_PATIENT,
		referenceId: referenceId,
	};
}

function setPreviousAppointmentReferenceId(referenceId, rescheduleMethod) {
	return {
		type: actionTypes.SET_PREVIOUS_APPOINTMENT,
		referenceId: referenceId,
		rescheduleMethod: rescheduleMethod,
	};
}

function clearPreviousAppointmentReferenceId() {
	return {
		type: actionTypes.CLEAR_PREVIOUS_APPOINTMENT,
	};
}

function clearAgentInstructionsState() {
	return {
		type: actionTypes.CLEAR_AGENT_INSTRUCTIONS,
	};
}

function resetInitialAvailabilitySearchState() {
	return {
		type: actionTypes.RESET_AVAILABILITY_STATE,
	};
}

function setCancelRescheduleActionReferrerState(actionReferrer) {
	return {
		type: actionTypes.SET_CANCEL_RESCHEDULE_ACTION_REFERRER,
		actionReferrer: actionReferrer,
	};
}

function setRescheduleSearchState(criteria) {
	return {
		type: actionTypes.SET_RESCHEDULE_SEARCH_STATE,
		criteria: criteria,
	};
}

function clearBookingContextAction() {
	return {
		type: actionTypes.CLEAR_BOOKING_CONTEXT,
	};
}

function generateCorrelationKeyAction() {
	let key = crypto.randomUUID();
	return {
		type: actionTypes.WORKFLOW_CORRELATION_KEY_UPDATED,
		correlationKey: key,
	};
}

// EXPORTS

export function clearActivePatient() {
	return function (dispatch) {
		dispatch(clearActivePatientAction());
	};
}

export function ensurePatient(patient) {
	return function (dispatch) {
		return dispatch(ensurePatientAction(patient));
	};
}

export function setActivePatient(referenceId) {
	return function (dispatch) {
		dispatch(setActivePatientAction(referenceId));
		return dispatch(getPatientDetailsAction(referenceId));
	};
}

export function createNewPatient(firstName, lastName, dob, memberId) {
	return function (dispatch) {
		dispatch(routes.patientAdd(firstName, lastName, dob, memberId));
	};
}

export function searchPatient(patientSearchCriteria) {
	return function (dispatch) {
		dispatch(searchPatientAction(patientSearchCriteria));
	};
}

export function searchPatientFromCareOrders(patientSearchCriteria) {
	return function (dispatch) {
		return dispatch(searchPatientAction(patientSearchCriteria));
	};
}

export function lookupPatient(idValue, idType) {
	return function (dispatch) {
		return dispatch(lookupPatientAction(idValue, idType));
	};
}

export function getPatientSchedulableOrders(idValue, idType) {
	return function (dispatch) {
		return dispatch(getPatientSchedulableOrdersAction(idValue, idType));
	};
}

//Find a better name, as this is too close to createNewPatient, which does navigation.
export function createPatient(patientDetails) {
	return function (dispatch) {
		return dispatch(createPatientAction(patientDetails));
	};
}

export function updatePatient(patientDetails) {
	return function (dispatch) {
		return dispatch(updatePatientAction(patientDetails));
	};
}

export function cancelAppointment(appointmentId, cancelRequest, token) {
	return function (dispatch) {
		return dispatch(cancelAppointmentAction(appointmentId, cancelRequest, token));
	};
}

export function fetchPatientHistory(referenceId) {
	return function (dispatch) {
		return dispatch(getPatientHistoryAction(referenceId));
	};
}

export function fetchPatientCareOrderHistory(referenceId) {
	return function (dispatch) {
		return dispatch(getPatientCareOrderHistoryAction(referenceId));
	};
}

export function fetchPatientOpenWorkflows(referenceId, bookOpenDecisionSupportWorkflowMaxAgeInDays) {
	return function (dispatch) {
		return dispatch(getPatientOpenWorkflowsAction(referenceId, bookOpenDecisionSupportWorkflowMaxAgeInDays));
	}
}

export function validateZipCode(zipCode) {
	return function (dispatch) {
		return dispatch(validateZipCodeAction(zipCode));
	};
}

export function onPatientDetailsViewMount() {
	return {
		type: actionTypes.ON_PATIENT_DETAILS_VIEW_MOUNT,
	};
}

export function resetAvailabilitySearchState() {
	return function (dispatch) {
		dispatch(resetInitialAvailabilitySearchState());
	};
}

export function setCancelRescheduleActionReferrer(actionReferrer) {
	return function (dispatch) {
		dispatch(setCancelRescheduleActionReferrerState(actionReferrer));
	};
}

export function setRescheduleAvailabilitySearchState(criteria) {
	return function (dispatch) {
		dispatch(setRescheduleSearchState(criteria));
	};
}

export function setPreviousAppointment(referenceId, rescheduleMethod) {
	return function (dispatch) {
		dispatch(setPreviousAppointmentReferenceId(referenceId, rescheduleMethod));
	};
}

export function clearPreviousAppointment() {
	return function (dispatch) {
		dispatch(clearPreviousAppointmentReferenceId());
	};
}

export function clearAgentInstructions() {
	return function (dispatch) {
		dispatch(clearAgentInstructionsState());
	};
}

export function startPatientSearchProcess() {
	return function (dispatch) {
		dispatch(routes.patientSearch());
	};
}

export function startBookingProcess(
	activeCareOrder,
	decisionSupportOutput,
	availabilitySearchConfig,
	patientDetails,
	useDecisionSupport,
) {
	return function (dispatch) {
		dispatch(generateCorrelationKey()).then(() => {
			if (useDecisionSupport) {
				dispatch(routes.decisionSupport());
			} else {
				dispatch(
					startAvailabilitySearch(activeCareOrder, decisionSupportOutput, availabilitySearchConfig, patientDetails),
				);
			}
		});
	};
}

export function generateCorrelationKey() {
	return (dispatch) => {
		dispatch(generateCorrelationKeyAction());
		return Promise.resolve();
	};
}

export function clearBookingContext() {
	return function (dispatch) {
		dispatch(clearBookingContextAction());
	};
}

export function startAvailabilitySearch(
	activeCareOrder,
	decisionSupportOutput,
	availabilitySearchConfig,
	patientDetails,
) {
	return function (dispatch) {
		let initialAvailabilitySearchState = dispatch(
			availabilitySearchActions.setInitialAvailabilitySearchState(
				activeCareOrder.availabilitySearchCriteria,
				decisionSupportOutput.availabilitySearchCriteria,
				availabilitySearchConfig,
				patientDetails,
			),
		);
		let appointmentDetails = dispatch(
			appointmentActions.setAppointmentDetailsFromDecisionSupportOutput(
				activeCareOrder.appointmentCriteria,
				decisionSupportOutput.appointmentCriteria,
			),
		);
		let customFieldAnswers = dispatch(
			customFieldActions.setInitialCustomFieldAnswers(activeCareOrder.customFields, decisionSupportOutput.customFields),
		);

		Promise.all([initialAvailabilitySearchState, appointmentDetails, customFieldAnswers]).then(() => {
			dispatch(routes.availabilitySearch());
		});
	};
}

export function searchAddress(addressSearchCriteria) {
	return function (dispatch) {
		let result = null;
		try {
			result = dispatch(searchAddressAction(addressSearchCriteria));

		} catch (e) {
			console.error(e);
		}
		return result;
	};
}

function searchAddressAction(addressSearchCriteria) {
	return {
		type: actionTypes.PATIENT_SEARCH_ADDRESS,
		payload: {
			request: rd2ApiManager.createRequest_SearchAddress(addressSearchCriteria),
		},
	};
}

export function getAddress(addressSearchCriteria) {
	return function (dispatch) {
		return dispatch(getAddressAction(addressSearchCriteria))
	};

}

function getAddressAction(addressSearchCriteria) {
	return {
		type: actionTypes.PATIENT_GET_ADDRESS,
		payload: {
			request: rd2ApiManager.createRequest_GetAddress(addressSearchCriteria),
		},
	};
}