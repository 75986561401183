import React from 'react';
import classNames from 'classnames';
import { getFieldSizeByLabel } from '../../utility/fieldConfigUtils';

export default function FieldControl(props) {
	const { className, disabled, error, hasValue, isFocused, id, inputType, isVisible, label, labelAfter, size, } = props;
	let comp = null;
	let sizeClassName = size;
	if (!size && !!label) {
		sizeClassName = getFieldSizeByLabel(label);
	}

	const fieldControlClassName = classNames(
		"field-control",
		className,
		inputType,
		sizeClassName,
		{ error: error },
		{ filled: hasValue },
		{ focused: isFocused },
		{ disabled: disabled },
	);

	if (isVisible)
		comp =
			<div className={fieldControlClassName}>
				{label && !labelAfter &&
					<label className="field-label" htmlFor={id}>
						{label}
					</label>
				}
				{props.children}
				{label && labelAfter &&
					<label className="field-label" htmlFor={id}>
						{label}
					</label>
				}
				{error &&
					<span className="field-error">
						{error.message}
					</span>
				}
			</div>
	return comp;
}
