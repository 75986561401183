import React from 'react';
import * as Icon from '../common/icons/icon';


export function TextLiteral(props) {
	const { text, isVisible, className } = props;
	let returnObj = null;
	if (isVisible)
		returnObj = <span className={className}>{text}</span>;

	return returnObj
}

export function DisclaimerHtml(props) {
	const { className, text, isVisible } = props;
	let returnObj = null;
	if (isVisible)
		returnObj = <div className={"disclaimer " + className} dangerouslySetInnerHTML={{ __html: text }}></div>

	return returnObj;
}

export function SectionHeader(props) {
	const { label } = props;
	return (
		<h1>{label}</h1>
	)
}

export function CollapsablePanelWithSectionHeader(props) {
	const { enableSectionValidationIcons, label, isCollapsed, isCollapsable, children, onToggleCollapse, name, hasErrors, showHeader, shouldShowSectionValidation } = props;
	const isPanelCollapsed = isCollapsable && isCollapsed;

	let validationIcon = hasErrors ?
		<span className="validation-icon-times"><Icon.IconClose /></span>
		: <span className="validation-icon-check"><Icon.IconCheck /></span>;

	return (
		<section id={name} className="content-section">
			{showHeader &&
				<div className="section-header">
					<div className="header-text-validation-icon">
						<SectionHeader label={label} hasErrors={hasErrors} shouldShowSectionValidation={shouldShowSectionValidation} />
						{enableSectionValidationIcons && shouldShowSectionValidation && validationIcon}
					</div>
					{isCollapsable &&
						<CollapsablePanelToggle isCollapsed={isCollapsed} onToggleCollapse={onToggleCollapse} name={name} />
					}
				</div>
			}
			<CollapsablePanelContent isCollapsed={isPanelCollapsed}>
				{children}
			</CollapsablePanelContent>
		</section>
	)
}

export function CollapsablePanelToggle(props) {
	const { isCollapsed, onToggleCollapse, name } = props;
	return (
		<React.Fragment>
			{isCollapsed &&
				<span className="angle-collapse-handle-open" onClick={() => onToggleCollapse(name)}><Icon.IconAngleDown /></span>
			}
			{!isCollapsed &&
				<span className="angle-collapse-handle-close" onClick={() => onToggleCollapse(name)}><Icon.IconAngleUp /></span>
			}
		</React.Fragment>
	)
}

export function CollapsablePanelContent(props) {
	const { isCollapsed, children } = props;
	return (
		<React.Fragment>
			{!isCollapsed &&
				children
			}
		</React.Fragment>
	)
}

export function NighhawkInputIdiom(props) {
	const { isCollapsed, children } = props;
	return (
		<React.Fragment>
			{isCollapsed &&
				children
			}
		</React.Fragment>
	)
}

export function NoResultsDisplay(props) {
	return (
		<div className="no-results-container">
			<span>No Providers Found</span>
		</div>
	)
}
