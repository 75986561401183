import { useCallback } from 'react';
import { useHistory as useReactRouterHistory } from 'react-router-dom';

export const useAppHistory = () => {
	const reactRouterHistory = useReactRouterHistory();

	const navigate = useCallback(
		(path: string) => {
			setTimeout(() => {
				reactRouterHistory.push(path);
			}, 100);
		},
		[reactRouterHistory],
	);

	const goBack = useCallback(() => {
		reactRouterHistory.goBack();
	}, [reactRouterHistory]);

	return {
		...reactRouterHistory,
		navigate,
		goBack,
	};
};

export default useAppHistory;
