/**
 * @format
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/common/errorBoundary';
import LoadingIndicator from '../../components/loadingIndicator';
import * as appointmentActions from '../../actions/appointmentActions';
import * as rd2RefactorActions from '../../actions/rd2RefactorActions';
import RequestAppointmentDetailsOverview from '../../components/appointment/requestAppointmentDetailsOverview';

export class RequestAppointmentDetailsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
			isDetailsLoading: false,
		};
	}

	requestDetails = (requestId) => {
		this.setState({ isDetailsLoading: true }, () => {
			this.props.actions.appointment
				.getAppointmentRequest(requestId)
				.then((response) => {
					if (response.error) {
						this.setState({ error: 'An error occurred while trying to get the appointment details.' });
					}
					this.setState({ isDetailsLoading: false });
				})
				.catch((err) => {
					this.setState({ error: 'Error' });
				});
		});
	};

	newSearch() {
		this.props.actions.rd2Refactor.startPatientSearchProcess();
	}

	bookAnotherAppointment() {
		this.props.actions.rd2Refactor.startBookingProcess(
			this.props.activeCareOrder,
			this.props.decisionSupportOutput,
			this.props.availabilitySearchConfig,
			this.props.activePatient.details,
			this.props.useDecisionSupport,
		);
	}

	handleNewSearchClick = (e) => {
		e.preventDefault();
		this.newSearch();
	};

	handleBookAnotherAppointmentClick = (e) => {
		e.preventDefault();
		this.bookAnotherAppointment();
	};

	componentDidMount() {
		const params = this.props.match.params;
		this.requestDetails(params.requestId);
	}

	render() {
		return (
			<ErrorBoundary childName="Appointment Details">
				{this.state.isDetailsLoading && <LoadingIndicator />}
				{!this.state.isDetailsLoading && (
					<RequestAppointmentDetailsOverview
						bookAnotherAppointment={this.handleBookAnotherAppointmentClick}
						details={this.props.details}
						isGroupNumberActive={this.props.isGroupNumberActive}
						isMemberIdActive={this.props.isMemberIdActive}
						memberIdFieldLabel={this.props.memberIdFieldLabel}
						newSearch={this.handleNewSearchClick}
						providerFieldConfig={this.props.providerFieldConfig}
					/>
				)}
			</ErrorBoundary>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.activePatient,
		availabilitySearchConfig: state.config.availabilitySearch,
		decisionSupportOutput: state.decisionSupport,
		details: state.appointment.requestDetails,
		isGroupNumberActive: state.config.system.isGroupNumberActive,
		isMemberIdActive: state.config.system.isMemberIdActive,
		memberIdFieldLabel: state.config.system.memberIdFieldLabel,
		providerFieldConfig: state.config.provider,
		referralSiteId: state.auth.referralSiteId,
		referralSystemId: state.auth.referralSystemId,
		token: state.auth.token,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			appointment: bindActionCreators(appointmentActions, dispatch),
			rd2Refactor: bindActionCreators(rd2RefactorActions, dispatch),
		},
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAppointmentDetailsView);
