import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PermissionWarning from './permissionWarning';

const AuthWrapper = (props) => {
	const { requiredPermission, currentPermissions, alternateDisplay, children, requiresActivePatient, activePatientReferenceId } = props;
	const isMenuItem = props.isMenuItem || false;
	const AlternateDisplay = alternateDisplay || PermissionWarning;

	if (!requiredPermission || ((currentPermissions && currentPermissions.includes(requiredPermission)) && (!requiresActivePatient || activePatientReferenceId))) {
		return children;
	} else {
		return !isMenuItem ? <AlternateDisplay /> : null;
	}
};

const mapStateToProps = (state, ownProps) => {
	return {
		currentPermissions: state.auth.permissions,
		activePatientReferenceId: state.activePatient.details.referenceId,
	};
};

export default connect(mapStateToProps)(AuthWrapper);

AuthWrapper.propTypes = {
	requiredPermission: PropTypes.string,
	alternateDisplay: PropTypes.element
};