import { getAppInsights } from 'components/common/telemetryService';

interface LogProperties {
    [key: string]: any;
}

class LoggerUtils {
    static logTrace(message: string, properties: LogProperties = {}) {
        LoggerUtils.logWithTrace({ message, severityLevel: 0, properties: LoggerUtils.copyProperties(properties) });
    }

    static logInfo(message: string, properties: LogProperties = {}) {
        LoggerUtils.logWithTrace({ message, severityLevel: 1, properties: LoggerUtils.copyProperties(properties) });
    }

    static logWarning(message: string, properties: LogProperties = {}) {
        LoggerUtils.logWithTrace({ message, severityLevel: 2, properties: LoggerUtils.copyProperties(properties) });
    }

    static logError(error: string, properties: LogProperties = {}) {
        const appInsights = getAppInsights();
        if (appInsights && appInsights.trackException) {
            try {
                appInsights.trackException({ exception: new Error(error), properties: LoggerUtils.copyProperties(properties) });
            } catch (err) {
                console.error('Failed to log error to Application Insights:', err);
            }
        }
    }

    static logEvent(name: string, properties: LogProperties = {}) {
        const appInsights = getAppInsights();
        if (appInsights && appInsights.trackEvent) {
            try {
                appInsights.trackEvent({ name, properties: LoggerUtils.copyProperties(properties) });
            } catch (err) {
                console.error('Failed to log event to Application Insights:', err);
            }
        }
    }

    private static logWithTrace({ message, severityLevel, properties }: { message: string; severityLevel: number; properties: LogProperties }) {
        const appInsights = getAppInsights();
        if (appInsights && appInsights.trackTrace) {
            try {
                appInsights.trackTrace({ message, severityLevel, properties });
            } catch (err) {
                console.error('Failed to log trace to Application Insights:', err);
            }
        }
    }

    private static copyProperties(properties: LogProperties): LogProperties {
        try {
            return JSON.parse(JSON.stringify(properties));
        } catch (err) {
            console.error('Failed to copy properties for logging:', err);
            return {}; // Return an empty object in case of serialization failure
        }
    }
}

export default LoggerUtils;