/**
 * @format
 */

import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { RescheduleAppointmentPanel } from './rescheduleAppointmentPanel';
import { AppointmentDetailsPanel } from './appointmentDetailsPanel';
import {
	displayFullName,
	displayProviderNameWithProfessionalDesignation,
	valueDisplay,
	valueDisplayBottomMargin,
} from '../../lib/standardLibrary';

const PATIENT_DETAILS = 'patientDetails';
const PRACTICE_SCHEDULED = 'Practice Scheduled';

export class RescheduleAppointmentDetailsOverview extends React.Component {
	render() {
		const {
			rescheduleInfo,
			details,
			goBack,
			previousAppointmentDetails,
			providerFieldConfig,
			schedulingConfig,
			showInsuranceOnBookAppointment,
			startRescheduleWithDifferentProvider,
			startRescheduleWithSameProvider,
		} = this.props;

		const providerConfig = {
			fax: providerFieldConfig.fax,
			phone: providerFieldConfig.phone,
		};

		let providerFullName = displayFullName(details.providerFirstName, details.providerLastName);
		let providerFullNameProfessionalDesignation = displayProviderNameWithProfessionalDesignation(
			providerFullName,
			details.providerProfessionalDesignation,
		);
		let isPsaAppointment = Boolean(details.appointmentTypeName === PRACTICE_SCHEDULED);
		let backButtonText =
			rescheduleInfo.actionReferrer === PATIENT_DETAILS ? 'Back to Patient Details' : 'Back to Appointment Details';
		let rescheduleText = isPsaAppointment
			? 'Are you sure you want to reschedule this appointment?'
			: 'How would you like to reschedule this appointment?';
		let isReferringProvider = Boolean(
			details.managedReferringServiceDisplayName && details.managedReferringServiceDisplayName.trim() !== '',
		);
		let hasMappedAppointmentType = Boolean(details.mappedAppointmentTypeName);
		let showMappedAppointmentType =
			hasMappedAppointmentType && details.mappedAppointmentTypeName !== details.appointmentTypeName;
		let allowReschedulePSAWithSameProvider = Boolean(schedulingConfig.allowReschedulePSAWithSameProvider);

		let showArrivalTime = Boolean(
			details.expectedArrivalTime && details.expectedArrivalTime !== details.providerAppointmentDateTime,
		);

		return (
			<React.Fragment>
				<Col>
					<Row>
						<h4>Reschedule Appointment</h4>
					</Row>
				</Col>
				<Row>
					<RescheduleAppointmentPanel
						allowReschedulePSAWithSameProvider={allowReschedulePSAWithSameProvider}
						backButtonText={backButtonText}
						goBack={goBack}
						hasMappedAppointmentType={hasMappedAppointmentType}
						isPsaAppointment={isPsaAppointment}
						rescheduleText={rescheduleText}
						startRescheduleWithDifferentProvider={startRescheduleWithDifferentProvider}
						startRescheduleWithSameProvider={startRescheduleWithSameProvider}
					/>
					<AppointmentDetailsPanel
						details={details}
						isReferringProvider={isReferringProvider}
						previousAppointmentDetails={previousAppointmentDetails}
						providerConfig={providerConfig}
						providerFullNameProfessionalDesignation={providerFullNameProfessionalDesignation}
						showArrivalTime={showArrivalTime}
						showInsuranceOnBookAppointment={showInsuranceOnBookAppointment}
						showMappedAppointmentType={showMappedAppointmentType}
						showProviderResponseApptDetails={schedulingConfig.showProviderResponseApptDetails}
						showReasonForVisitApptDetails={schedulingConfig.showReasonForVisitApptDetails}
						valueDisplay={valueDisplay}
						valueDisplayBottomMargin={valueDisplayBottomMargin}
					/>
				</Row>
			</React.Fragment>
		);
	}
}

export default RescheduleAppointmentDetailsOverview;
