import { SUCCESS_SUFFIX, ERROR_SUFFIX } from 'redux-axios-middleware';
import * as types from '../actions/actionTypes';

const initialState = {
	callsInProgressCount: 0,
	isLoading: [],
}

function isAxiosRequest(action) {
	return action.payload && (action.payload.request || (action.meta && action.meta.previousAction));
}

function isBeginCall(action) {
	return isAxiosRequest(action) && (!action.type.endsWith(SUCCESS_SUFFIX) && !action.type.endsWith(ERROR_SUFFIX));
}

function isEndCall(action) {
	return action.type.endsWith(SUCCESS_SUFFIX) || action.type.endsWith(ERROR_SUFFIX);
}

function stripActionTypeSuffix(action) {
	if (action.type.endsWith(SUCCESS_SUFFIX)) {
		return action.type.slice(0, -SUCCESS_SUFFIX.length);
	} else if (action.type.endsWith(ERROR_SUFFIX)) {
		return action.type.slice(0, -ERROR_SUFFIX.length);
	}
	return action.type;
}

export default function ajaxStatusReducer(state = initialState, action) {
	// NOTE: I Don't think this needs its own callout...?
	if (action.type === types.AUTHENTICATE_USER_SUCCESS) {
		return {
			...state,
			callsInProgressCount: 0,
			isLoading: [],
		}
	}

	if (isBeginCall(action)) {
		return {
			...state,
			callsInProgressCount: state.callsInProgressCount + 1,
			isLoading: [...state.isLoading, action.type],
		}
	}

	if (isEndCall(action)) {
		return {
			...state,
			callsInProgressCount: state.callsInProgressCount - 1,
			isLoading: [...state.isLoading.filter((type) => type !== stripActionTypeSuffix(action))],
		}
	}

	return state;
}
