import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEventHandler } from "react"
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap"

const UserEmailInput = (props: { value: string, handleChange: ChangeEventHandler<HTMLInputElement> }) => {
    return (
        <FormGroup>
            <InputGroup>
                <InputGroupText
                    style={{
                        border: '1px solid #ced4da',
                        borderRadius: '.25rem',
                        borderRight: 'none',
                        marginRight: '-4px',
                        padding: '0px',
                        backgroundColor: 'transparent'
                    }}
                >
                    <span
                        color="default"
                        style={{
                            cursor: 'inherit',
                            margin: 'auto',
                        }}
                        className="btn btn-default btn-sm bg-transparent"
                    >
                        <FontAwesomeIcon icon="envelope" />
                    </span>
                </InputGroupText>
                <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={props.value}
                    onChange={props.handleChange}
                    className="py-2 border-left-0 border"
                />
            </InputGroup>
        </FormGroup>
    )
}

export default UserEmailInput;