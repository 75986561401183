/**
 * @format
 */

import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { CancelAppointmentPanel } from './cancelAppointmentPanel';
import { AppointmentDetailsPanel } from './appointmentDetailsPanel';
import { displayFullName, displayProviderNameWithProfessionalDesignation } from '../../lib/standardLibrary';

export default function CancelAppointmentDetailsOverview(props) {
	const {
		cancellationEmailConsent,
		cancellationEmailInstructions,
		cancellationEmailOther,
		collapse,
		confirmCancellation,
		details,
		emailCancellationEnabled,
		enableCancellationEmailOptIn,
		enableCancellationEmailOther,
		fieldValidation,
		goBack,
		handleChange,
		handleCheckboxChange,
		onCancelReasonChange,
		onSelectCancelReason,
		patientCancellationEmailDisclaimer,
		patientEmail,
		previousAppointmentDetails,
		providerFieldConfig,
		reasonForCancellation,
		schedulingConfig,
		showInsuranceOnBookAppointment,
		systemCancelReasons,
		toggle,
	} = props;

	const providerConfig = {
		fax: providerFieldConfig.fax,
		phone: providerFieldConfig.phone,
	};

	let providerFullName = displayFullName(details.providerFirstName, details.providerLastName);
	let providerFullNameProfessionalDesignation = displayProviderNameWithProfessionalDesignation(
		providerFullName,
		details.providerProfessionalDesignation,
	);

	let isReferringProvider = Boolean(
		details.managedReferringServiceDisplayName && details.managedReferringServiceDisplayName.trim() !== '',
	);

	let hasMappedAppointmentType = Boolean(details.mappedAppointmentTypeName);
	let showMappedAppointmentType =
		hasMappedAppointmentType && details.mappedAppointmentTypeName !== details.appointmentTypeName;

	let showReasonForCancel = schedulingConfig.showReasonForCancellation;
	let showDropdown =
		schedulingConfig.enableCustomCancelReasons && systemCancelReasons && systemCancelReasons.length > 0 ? true : false;
	let cancelInstructions = showReasonForCancel && showDropdown ? 'Select a Reason For Cancellation (required)' : '';
	let showArrivalTime = Boolean(
		details.expectedArrivalTime && details.expectedArrivalTime !== details.providerAppointmentDateTime,
	);

	return (
		<React.Fragment>
			<Col>
				<Row>
					<h4>Cancel Appointment</h4>
				</Row>
			</Col>
			<Row>
				<CancelAppointmentPanel
					cancelInstructions={cancelInstructions}
					cancellationEmailConsent={cancellationEmailConsent}
					cancellationEmailInstructions={cancellationEmailInstructions}
					cancellationEmailOther={cancellationEmailOther}
					collapse={collapse}
					confirmCancellation={confirmCancellation}
					emailCancellationEnabled={emailCancellationEnabled}
					enableCancellationEmailOptIn={enableCancellationEmailOptIn}
					enableCancellationEmailOther={enableCancellationEmailOther}
					fieldValidation={fieldValidation}
					goBack={goBack}
					handleChange={handleChange}
					handleCheckboxChange={handleCheckboxChange}
					onCancelReasonChange={onCancelReasonChange}
					onSelectCancelReason={onSelectCancelReason}
					patientCancellationEmailDisclaimer={patientCancellationEmailDisclaimer}
					patientEmail={patientEmail}
					reasonForCancellation={reasonForCancellation}
					showDropdown={showDropdown}
					showReasonForCancel={showReasonForCancel}
					systemCancelReasons={systemCancelReasons}
					toggle={toggle}
				/>
				<AppointmentDetailsPanel
					details={details}
					isReferringProvider={isReferringProvider}
					previousAppointmentDetails={previousAppointmentDetails}
					providerConfig={providerConfig}
					providerFullNameProfessionalDesignation={providerFullNameProfessionalDesignation}
					showArrivalTime={showArrivalTime}
					showInsuranceOnBookAppointment={showInsuranceOnBookAppointment}
					showMappedAppointmentType={showMappedAppointmentType}
					showProviderResponseApptDetails={schedulingConfig.showProviderResponseApptDetails}
					showReasonForVisitApptDetails={schedulingConfig.showReasonForVisitApptDetails}
				/>
			</Row>
		</React.Fragment>
	);
}
