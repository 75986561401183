import React from 'react';
import { connect } from 'react-redux';

import { hideModal } from '../../actions/modalActions';
import Modal from '../modalComponent';

const Confirmation = ({ title, onConfirm, hideModal, children }) => {
	const handleConfirm = (isConfirmed) => () => {
		hideModal();
		onConfirm(isConfirmed);
	};

	return (
		<Modal title={title}>
			{children}
			<button onClick={handleConfirm(true)}>
				Yes
			</button>
			<button onClick={handleConfirm(false)}>
				No
			</button>
		</Modal>
	);
};


export default connect(null, { hideModal })(Confirmation);
