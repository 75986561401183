import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

export default function OrderStatusHistoryRow (props) {
    const { historyEntry, timezone } = props;

    return (
        <tr>
            <td>
                {moment.utc(historyEntry.date).tz(timezone).format('MM/DD/YYYY, h:mm:ss A z')}
            </td>
            <td>{historyEntry.userName}</td>
            <td>{`${historyEntry.status} (${historyEntry.statusReason})`}</td>
            <td>{historyEntry.notes}</td>
        </tr>
    );
}
