import * as React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Col,
	Button,
} from 'reactstrap';

export class Attachments extends React.Component {

	render() {
		const { config, onDropRejected, onDrop, onCancel, fileError, files, removeFile } = this.props;

		const dropzoneRef = React.createRef();

		return (
			<Col lg="5" className="attachments">
				<Col lg="12">
					<div className="dropzone">
						<Dropzone
							style={{ "border": "dotted 2px #c0c0c0", "height": "8.7em", "padding": "10px" }}
							ref={dropzoneRef}
							accept=".txt, .doc, .docx, .pdf"
							maxSize={config.maxAppointmentAttachmentSize}
							onDropRejected={onDropRejected.bind(this)}
							onDrop={onDrop.bind(this)}
						//onFileDialogCancel={onCancel.bind(this)}
						//hidden = "true"
						>
							<p>Optionally, drag and drop files here to upload.<br /><br />Accepted file types: .txt .doc .docx .pdf </p>
						</Dropzone>
						<Button style={{ "marginTop": "5px", "marginRight": "5px" }} color="primary" type="button" onClick={() => { dropzoneRef.current.open() }}>Choose File</Button>
						<Button style={{ "marginTop": "5px" }} onClick={() => { onCancel() }}>Clear</Button>
					</div>
				</Col>
				<Col lg="4">
					{fileError !== null &&
						<div><span style={{ "color": "red" }}>* {fileError}</span></div>
					}
					<ul>
						{
							files.map(f =>
								<div key={f.name}>
									<li><span>{f.name} - {f.size} bytes </span><FontAwesomeIcon className="hand" style={{ "color": "#717171", "fontSize": "18px", "marginLeft": "4px" }} id="trashFile" icon={faTrashAlt} size="lg" onClick={() => { removeFile(f) }} /></li>
								</div>
							)}
					</ul>
				</Col>
			</Col>
		);
	}
}

export default Attachments;

Attachments.propTypes = {
	config: PropTypes.object,
	onDropRejected: PropTypes.func,
	onDrop: PropTypes.func,
	onCancel: PropTypes.func,
	files: PropTypes.array,
	removeFile: PropTypes.func,
}
