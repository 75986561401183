import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { scrollToPageTop } from '../../lib/misc';

import * as appliedPathwaysActions from '../../actions/appliedPathwaysActions';

import AppliedPathwaysWorkflow from './appliedPathwaysWorkflow';

export class AppliedPathwaysComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: ''
		}
	}

	handleComplete = (subpoints) => {
		scrollToPageTop();
		if (subpoints === null) {
			return;
		}
		let ruleToken = "";
		let chainToGuidedResponseFlow = false;

		subpoints.map((subpoint) => {
			switch (subpoint.key) {
				case "redirecttoalgo":
					ruleToken = subpoint.value;
					break;
				case "chainedflowuniquetoken":
					chainToGuidedResponseFlow = true;
					break;
				default:
					break;
			}
			return "";
		});

		if (chainToGuidedResponseFlow) {
			//Redirect to Guided Response Workflow View
			this.props.onChainFlow(subpoints);
		} else {
			if (ruleToken !== "") {
				this.props.actions.appliedPathways.fetchWorkboxWithRule(this.props.contextData, this.props.correlationKey, this.props.decisionSupportConfig, ruleToken, subpoints)
			} else {
				this.props.onCompleted(subpoints); // raise callback to parent?
			}
		}
	}

	handleCurrent = () => {
		this.fetchWorkbox();
	};

	fetchWorkbox = () => {
		this.props.actions.appliedPathways.fetchWorkbox(this.props.contextData, this.props.correlationKey, this.props.decisionSupportConfig)
			.then((response) => {
				if (response.error) {
					this.setState({
						error: 'An error occurred while trying to retrieve workbox.'
					});
				}
			})
			.catch((err) => {
				this.setState({
					error: 'An error occurred while trying to retrieve workbox.'
				});
			})
	}

	handleNext = (data) => {
		scrollToPageTop();
		this.props.actions.appliedPathways.fetchWorkboxNext(data);
	};

	handlePrevious = (data) => {
		scrollToPageTop();
		this.props.actions.appliedPathways.fetchWorkboxPrevious(data);
	};

	render() {
		return (
			<AppliedPathwaysWorkflow
				appliedPathwaysState={this.props.appliedPathways.appliedPathwaysState}
				error={this.state.error}
				isLoading={this.props.isLoading}
				onComplete={this.handleComplete}
				onCurrent={this.handleCurrent}
				onNext={this.handleNext}
				onPrevious={this.handlePrevious}
				patient={this.props.patient}
				workbox={this.props.appliedPathways.workbox}
			/>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		appliedPathways: state.appliedPathways,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			appliedPathways: bindActionCreators(appliedPathwaysActions, dispatch),
		},
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AppliedPathwaysComponent);
