import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function AppInsightsLogger(props) {
    const initialBatchLog = useSelector(state => state.availability.initialBatchLog);
    const applicationInsightsKey = useSelector(state => state.auth.applicationInsightsKey);

    useEffect(() => {
        if (initialBatchLog.hasCompleted) {

            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: applicationInsightsKey
                }
            })
            appInsights.loadAppInsights();

            const { preferred, notPreferred } = initialBatchLog;

            let endTimeStamp = null;


            if (initialBatchLog.hasPreferredBatch) {
                if (preferred.searchRequired) {
                    appInsights.trackTrace({ message: `Preferred Availability Search was run for ${preferred.searchCalendarIds?.length} calendars from ${preferred.searchMinStartDate}-${preferred.searchMaxStartDate}.` })
                } else {
                    appInsights.trackTrace({ message: `No availability was found from the preferred initial scan - availability search was not run.` })
                }

                if (preferred.nextScanRequired) {
                    appInsights.trackTrace({ message: `Preferred next scan was run for ${preferred.nextScanCalendarIds?.length} calendars from ${preferred.nextScanMinStartDate}-${preferred.nextScanMaxStartDate}` });

                } else {
                    appInsights.trackTrace({ message: "Preferred next scan was not needed." });
                }


                if (!preferred.searchRequired && !preferred.nextScanRequired) {
                    endTimeStamp = preferred.firstScanCompletedAt;
                }

                if (preferred.searchRequired && !preferred.nextScanRequired) {
                    endTimeStamp = preferred.searchCompletedAt;
                }

                if (preferred.searchRequired && preferred.nextScanRequired) {
                    if (dayjs(preferred.searchCompletedAt).diff(preferred.nextScanCompletedAt) > 0) {
                        endTimeStamp = preferred.nextScanCompletedAt;
                    } else {
                        endTimeStamp = preferred.searchCompletedAt;
                    }
                }

                appInsights.trackTrace({ message: `All preferred searches and scans took ${dayjs(initialBatchLog.calendarSearchStartedAt).diff(endTimeStamp)} ms` });
            }

            if (notPreferred.searchRequired) {
                appInsights.trackTrace({ message: `Availability Search was run for ${notPreferred.searchCalendarIds?.length} calendars from ${notPreferred.searchMinStartDate}-${notPreferred.searchMaxStartDate}.` })
            } else {
                appInsights.trackTrace({ message: `No availability was found from the initial scan - availability search was not run.` })
            }

            if (notPreferred.nextScanRequired) {
                appInsights.trackTrace({ message: `Next scan was run for ${notPreferred.nextScanCalendarIds?.length} calendars from ${notPreferred.nextScanMinStartDate}-${notPreferred.nextScanMaxStartDate}` });

            } else {
                appInsights.trackTrace({ message: "Next scan was not needed." });
            }

            if (!notPreferred.searchRequired && !notPreferred.nextScanRequired) {
                endTimeStamp = notPreferred.firstScanCompletedAt;
            }

            if (notPreferred.searchRequired && !notPreferred.nextScanRequired) {
                endTimeStamp = notPreferred.searchCompletedAt;
            }

            if (notPreferred.searchRequired && notPreferred.nextScanRequired) {
                if (dayjs(notPreferred.searchCompletedAt).diff(notPreferred.nextScanCompletedAt) > 0) {
                    endTimeStamp = notPreferred.nextScanCompletedAt;
                } else {
                    endTimeStamp = notPreferred.searchCompletedAt;
                }
            }

            appInsights.trackTrace({ message: `All searches and scans took ${dayjs(initialBatchLog.calendarSearchStartedAt).diff(endTimeStamp)} ms` });
        }

    }, [initialBatchLog, applicationInsightsKey])

    return null;
}