/**
 * @format
 */

import { createRef } from 'react';
import moment from 'moment';
import { Col, Container, Row, Button } from 'reactstrap';
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../css/calendarSearchResult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons';

import { mapSlotToAppointmentRequest } from './availability';
import {
    changeViewDateThunk,
    selectAllowNextViewBaseDate,
    selectAllowPreviousViewBaseDate,
    selectIsSearchingAnything,
    selectNextViewBaseDate,
    selectPreviousViewBaseDate,
    selectSelectedCalendarAvailability,
    selectSelectedCalendarId,
    selectSelectedCalendarViewBaseDate,
    selectShouldShowPreReserveModal
} from '../availabilitySlice';
import { formatStartTime } from '../../../lib/misc';
import { useDispatch, useSelector } from 'react-redux';
import { reserveAppointment } from '../../../actions/appointmentActions';
import { routeToBooking } from '../../../routes';
import { nullifySuppressedDates } from 'lib/date';

const EVENT_BACKGROUND_COLOR = '#f0ad4e';
export default function SelectedCalendarResult({ setSelectedSlot }) {

    const dispatch = useDispatch();

    const calendarId = useSelector(selectSelectedCalendarId);
    const selectedCalendarAvailability = useSelector(state => selectSelectedCalendarAvailability(state, calendarId));
    const allowNextViewBaseDate = useSelector(selectAllowNextViewBaseDate);
    const allowPreviousViewBaseDate = useSelector(selectAllowPreviousViewBaseDate);
    const nextViewBaseDate = useSelector(selectNextViewBaseDate);
    const previousViewBaseDate = useSelector(selectPreviousViewBaseDate);
    const isSearchingAnything = useSelector(selectIsSearchingAnything);
    const isReserving = useSelector(state => state.appointment.isLoading.reserveAppointment);
    const selectedCalendarViewBaseDate = useSelector(selectSelectedCalendarViewBaseDate);
    const isRescheduleFlow = useSelector(state => Boolean(state.appointment.cancelRescheduleInfo?.previousAppointmentReferenceId));
    const shouldShowPreReserveModal = useSelector(state => selectShouldShowPreReserveModal(state, calendarId));
    const token = useSelector(state => state.auth.token);
    const correlationKey = useSelector(state => state.session.correlationKey);
    const reservationDurationInMinutes = useSelector(state => state.config.scheduling.reservationDurationInMinutes);
    const activePatientReferenceId = useSelector(state => state.activePatient?.details?.referenceId);
    const suppressSaturday = useSelector(state => state.config.availabilitySearch.suppressSaturday);
    const suppressSunday = useSelector(state => state.config.availabilitySearch.suppressSunday);

    const availabilitySearchSupportData = useSelector((state) => state.config.availabilitySearchSupportData);
    const appointmentTypeList = availabilitySearchSupportData.appointmentTypeList;

    const localizer = momentLocalizer(moment);

    const exitPopup = createRef();

    const eventStyleGetter = ({ event, start, end, isSelected }) => {
        return {
            style: {
                backgroundColor: EVENT_BACKGROUND_COLOR,
                fontWeight: 'bold',
            },
        };
    };

    const handleCalendarClick = (event) => {
        if (event.target.className === 'rbc-overlay-header') {
            exitPopup.current.click();
        }
    };

    let providerCalendarClass = isSearchingAnything || isReserving ? 'provider-calendar-blur' : undefined;
    let titleText = !isRescheduleFlow ? "Please select an open slot to reschedule your patient's appointment)" : '';
    let events = selectedCalendarAvailability?.length
        ? selectedCalendarAvailability
            .filter(a => !!nullifySuppressedDates(a.date, suppressSaturday, suppressSunday))
            .map((x) => {

                return {
                    ...x,
                    key: x.availabilityId,
                    start: x.localStartAt,
                    end: x.localEndAt,
                    title: moment(x.localStartAt).format('h:mm a'),
                    allDay: false,
                    resource: null,
                    availabilityId: x.availabilityId,
                    //TODO: Determine if we still need above repeat code
                    timeSlotId: x.availabilityId,
                    date: x.date,
                    startTime: x.startTime,
                    endTime: x.endTime,
                    formattedStartTime: formatStartTime(x.startTime)
                };
            })
        : [];

    const customToolbar = (toolbar) => {
        const goToPrevious = () => {
            toolbar.onNavigate(Navigate.PREVIOUS);
        };

        const goToNext = () => {
            toolbar.onNavigate(Navigate.NEXT);
        };

        const label = () => {
            const date = moment(toolbar.date);
            return (
                <span>
                    {date.format('MMMM')} {date.format('YYYY')}
                </span>
            );
        };

        return (
            <div className="rbc-toolbar">
                {allowPreviousViewBaseDate && <Button onClick={goToPrevious}>Previous</Button>}
                <div className="rbc-toolbar-label">{label()}</div>
                {allowNextViewBaseDate && <Button onClick={goToNext}>Next</Button>}
            </div>
        );
    };

    const customEvent = (event) => {
        const modality = appointmentTypeList.find(apptType => apptType.idPgmAppointmentType === event.event.appointmentTypeId)?.pgmAppointmentModalityName ?? '';

        return (
            <>
                {(modality === "Telephonic") ? (
                    <span><FontAwesomeIcon style={{ marginRight: '5px' }} icon={faMobileScreenButton} size='lg' />{event.event.formattedStartTime}</span>
                ) : (
                    <span>{event.event.formattedStartTime}</span>
                )}

            </>
        );
    }

    return (
        <div>
            {isRescheduleFlow && <h4>{titleText}</h4>}
            <div className={providerCalendarClass}>
                <Container className="provider-calendar-container">
                    <Row>
                        <Col lg="12" onClick={handleCalendarClick}>
                            <div>
                                {/*
									There is no mechanism in react-big-calendar to either show a "Close"/X button or an event to hook into. A user must
									either hit Esc or click elsewhere on the screen. Using CSS, I added an "X" and am simulating a click event using exitPopup() and handleCalendarClick().
									*/}
                                <div ref={exitPopup} />
                                <Calendar
                                    components={{
                                        month: {
                                            toolbar: customToolbar,
                                            event: customEvent,
                                        },
                                    }}
                                    date={moment(selectedCalendarViewBaseDate).toDate()}
                                    defaultDate={new Date()}
                                    defaultView="month"
                                    eventPropGetter={eventStyleGetter}
                                    events={events}
                                    localizer={localizer}
                                    onNavigate={(date, view, action) => {
                                        switch (action) {
                                            case Navigate.PREVIOUS:
                                                if (allowPreviousViewBaseDate) {
                                                    dispatch(changeViewDateThunk(previousViewBaseDate));
                                                }
                                                break;
                                            case Navigate.NEXT:
                                                if (allowNextViewBaseDate) {
                                                    dispatch(changeViewDateThunk(nextViewBaseDate));
                                                }
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                    onSelectEvent={(slot) => {
                                        let mappedSlot = mapSlotToAppointmentRequest(slot);
                                        setSelectedSlot(mappedSlot);
                                        if (!shouldShowPreReserveModal) {
                                            dispatch(reserveAppointment(
                                                token,
                                                mappedSlot,
                                                reservationDurationInMinutes,
                                                correlationKey
                                            )).then((response) => {
                                                if (response.payload?.data?.reservationId) {
                                                    dispatch(routeToBooking(activePatientReferenceId, response.payload?.data?.flexCalendarEntryId))
                                                }
                                            });
                                        }
                                    }}
                                    onClick={handleCalendarClick}
                                    popup={true}
                                    style={{ height: '100vh' }}
                                    tooltipAccessor={(event) => {
                                        return event.appointmentTypeName;
                                    }}
                                    views={['month']}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

