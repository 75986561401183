import React from 'react';
import './termsOfUse.css'

export default function TermsOfUse() {
	return (
		<div id="termsOfUse">
			<div>
				<p>
					THESE TERMS OF USE (this “<b>Agreement</b>”) are made by and between My Health Direct, Inc., a Delaware corporation (“<b>MHD</b>”), and you (“User”) 
					effective as of the date User agrees to be bound hereto (“Effective Date”). 
				</p>
				<p>
					MHD has designed and developed MyHealthDirect, a web-based solution that functions as a portal (the “<b>Portal</b>”) for the purposes of scheduling
				 	health care appointments (the “<b>Scheduling Service</b>”). 
				</p>
				<p>
					PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING OR USING THE SCHEDULING SERVICES. THIS AGREEMENT HAS THE SAME FORCE 
					AND EFFECT AS IF IT WERE EXECUTED IN A WRITTEN DOCUMENT BY MHD AND USER. USER ACKNOWLEDGES AND AGREES THAT THIS AGREEMENT SUPERSEDES AND 
					REPLACES ANY PRIOR AGREEMENT, TERMS, OR CONDITIONS USER PREVIOUSLY AGREED TO OR ENTERED INTO WITH MHD, AND SUCH PRIOR AGREEMENT, TERMS, OR 
					CONDITIONS SHALL NO LONGER BE IN EFFECT.					
				</p>
				<p>
					BY REGISTERING FOR ACCESS, YOU, THE INDIVIDUAL CLICKING THE “SUBMIT” BUTTON, AGREES THAT USER HAS READ, UNDERSTOOD, AND AGREED TO BE BOUND BY THIS AGREEMENT. 
				</p>
				<p>
					NOW THEREFORE the parties mutually promise and agree as follows: 
				</p>
				<ol>
					<li>
						<p>
							<u>Eligibility</u>. User acknowledges and agrees that this Agreement applies to any individual, groups of individuals, or entity attempting to use the 
							Scheduling Service through a registered user account. In order to use the Scheduling Service, User must be 13 years of age or older or an 
							emancipated minor. Parents of un-emancipated minors may access and use the Scheduling Services for themselves on behalf of such minors, as may 
							other personal representatives under applicable law. The Scheduling Service is for use in connection with scheduling services with healthcare 
							providers operating in the United States only. The Scheduling Service is not marketed to or intended for use by providers in foreign jurisdictions. All references to “User” in this Agreement include you, any person using your login credentials, and any other person for whom you are authorized in writing or under applicable law to act on behalf of, if any, in order to access and use the Scheduling Services. 
						</p>
						<p>
							In order to access and use the Scheduling Service, User first will create a user account that will allow User to login to the Portal for the 
							purposes of accessing and using the Scheduling Service. User shall provide to MHD certain information regarding User, including, without limitation, 
							User’s name, mailing address, phone number, and e-mail address, in order to access and use the Scheduling Service through the Portal. MHD may use 
							User’s e-mail address or phone number to, among other things, provide User with access credentials and instructions related thereto and correspond 
							with User regarding User’s use of the Scheduling Service via e-mail, text message, or SMS message, and User hereby consents to such use. 
						</p>
						<p>
							The Portal is intended as a secure online means for User to access the Scheduling Service, and it is User’s responsibility to select a 
							confidential password, to authorize, monitor, and control access to and use of User’s account, ID, and password, and to promptly inform MHD 
							technical support if User needs to inactivate User’s account, ID, or password for any reason. Please note that if User shares User’s Portal 
							user name and password with another person, this will allow that person to see User’s personal information. MHD has no responsibility concerning
							any breach of User’s personal information due to User sharing or losing a user name and password. MHD will not be liable for any loss that User
							may incur as a result of someone else using User’s password or account, either with or without User’s knowledge. However, User could be held 
							liable for losses incurred by MHD or another party due to someone else using User’s account or password. User may not use anyone else’s Portal 
								account at any time, without the authorization of the account holder or in accordance with User’s rights as a personal representative under 
							applicable law. 
						</p>
					</li>
					<li>
						<p>
							<u>Grant</u>. During the term of this Agreement subject to the use restrictions set forth in Section 3 below, MHD hereby grants User a non-exclusive, 
							non-transferable, non-sublicensable right to access and use the Scheduling Service through the Portal. User shall only be entitled to use the 
							Scheduling Service to either (i) post health care services offered by User that are available for scheduling by patients or potential patients 
							and receive appointments for such health care services, or (ii) schedule an appointment for health care services either directly for User or on 
							behalf of another person for which User has been authorized to schedule services. All rights in and to the Scheduling Service not expressly granted
							herein are reserved by MHD. 
						</p>
					</li>
					<li>
						<p>
							<u>Updates</u>. MHD may modify or change this Agreement at any time or add or delete functions to the Scheduling Service. Such modifications 
							or changes become effective immediately when they are posted to the Portal. User agrees to review this Agreement frequently so that User will 
							be familiar with them. User further agree that each time User uses the Portal to access and use the Scheduling Service, User is, by such use, 
							consenting to this Agreement applicable at that time. 
						</p>
					</li>
					<li>
						<p>
							<u>Use of the Scheduling Service; Restrictions</u>. User acknowledges that the Scheduling Service contains trade secrets and confidential information of 
							MHD. User will not disclose any information relating to the Scheduling Service to any third party except as may be incidental to the normal use of 
							the Scheduling Service. User shall not be entitled to receive a source code or object code version of the Scheduling Service. Except as this 
							Agreement expressly permits, User shall not, directly or indirectly, alone or with another party, and shall not permit another party to: (i) copy, 
							modify, or create derivative works or improvements of the Scheduling Service; (ii) rent, lease, lend, sell, sublicense, assign, distribute, 
							publish, transfer, or otherwise make available the Scheduling Service to another party, including on or in connection with the Internet or any 
							time-sharing, service bureau, software as a service, cloud, or other technology or services; (iii) reverse engineer, disassemble, decompile, 
							decode, adapt, or otherwise attempt to derive or gain access to the source code of the Scheduling Service, in whole or in part; (iv) bypass or 
							breach any security device or protection used by the Scheduling Service, or access or use the Scheduling Service through the use of his or her own 
							then-valid access credentials; (v) input, upload, transmit, or otherwise provide to or through the Scheduling Service, any information or materials
								that are unlawful or injurious, or contain, transmit, or activate any virus, bug, Trojan horse, worm, backdoor, or other harmful or malicious 
								computer code or any time bomb or drop dead device; (vi) damage, destroy, disrupt, disable, impair, interfere with, or otherwise impede or harm 
								in any manner the Scheduling Service or MHD’s provision of services to any third party, in whole or in part; (vii) remove, delete, alter, or 
								obscure any documentation, warranties, or disclaimers, or any copyright, trademark, patent, or other intellectual property or proprietary rights 
								notices from the Scheduling Service, including any copy thereof; (viii) access or use the Scheduling Service in any manner or for any purpose that
								infringes, misappropriates, or otherwise violates any intellectual property rights or other rights of any third party; (ix) use the Scheduling 
								Service in connection with any illegal or unlawful activity or in a manner that causes, results in, encourages, solicits, or publicizes a crime 
								or illegal or unlawful activity; (x) access or use the Scheduling Service for purposes of competitive analysis of the Scheduling Service, the 
								development, provision, or use of a competing software service or product or any other purpose that is to MHD’s detriment or commercial disadvantage;
								  and (xi) otherwise access or use the Scheduling Services beyond the scope of the authorization granted in <u>Section 2</u>.
						</p>
					</li>
					<li>
						<p>
							<u>Termination; Suspension</u>. The Scheduling Service is being provided to User as a convenience. MHD has the right to terminate, limit, suspend, or 
							revoke User’s access at any time for any reason, with or without notice. User may stop using the Scheduling Service at any time.  
						</p>
					</li>
					<li>
						<p>
							<u>Fees</u>. The Scheduling Service is presently provided free of charge to User, but MHD reserves the right to charge for access to and use of 
							the Scheduling Service in the future. Should such a charge ever be introduced, anyone with a user account would have the option to discontinue 
							access to and use of the Scheduling Services.
						</p> 
					</li>
					<li>
						<u>Warranties; Disclaimer. </u>
						<ol>
							<li>
								<p>
									User represents, warrants and covenants to MHD that (i) User holds and will maintain in good standing all required licenses, registrations, 
									filings, consents, approvals, and permits required for User to use the Scheduling Service, if any; (ii) User will exercise due care at all times 
									in its use of the Scheduling Service; and (iii) if User is a provider of health care services, User will service and fulfill health care 
									appointments made through the Scheduling Service in the same manner as those made with User through other means. If User is a provider of health 
									care services, User will be responsible for all information uploaded or posted regarding User, including, but not limited to, User’s profile, 
									background, products, services and prices.
								</p>
							</li>
							<li>
								<p>
									SUBJECT TO THE FOREGOING, THE PORTAL AND SCHEDULING SERVICE ARE FURNISHED TO USER “AS IS,” AND MHD HEREBY 
									DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE SCHEDULING SERVICE, THE PORTAL, OR THE 
									AVAILABILITY, FUNCTIONALITY, PERFORMANCE, OR RESULTS OF USE THEREOF, AS WELL AS ANY OTHER WARRANTIES, EXPRESS, 
									IMPLIED AND STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A 
									PARTICULAR PURPOSE, AND NON-INFRINGEMENT, ALL WARRANTIES ARISING BY USAGE OF TRADE, COURSE OF DEALING, OR COURSE 
									OF PERFORMANCE, AND ANY WARRANTIES THAT THE SCHEDULING SERVICE OR PORTAL WILL BE ACCURATE, ERROR-FREE, OR 
									UNINTERRUPTED, WILL MEET USER’S OR ANY OTHER PERSON’S REQUIREMENTS, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE 
									OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, OR FREE OF HARMFUL CODE. 
								</p>
								<p>
									SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF 
									A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO USER. IF USER IS A RESIDENT OF A JURISDICTION THAT REQUIRES 
									A SPECIFIC STATEMENT REGARDING RELEASE, THEN THE FOLLOWING APPLIES. FOR EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THIS AGREEMENT, 
									WAIVE THE APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR 
									DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY 
									AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.” USER HEREBY WAIVED THIS SECTION OF THE CALIFORNIA CIVIL CODE. USER HEREBY WAIVES ANY SIMILAR 
									PROVISION IN LAW, REGULATION, OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED RELEASE. 
								</p>
							</li>
							<li>
								<p>
									The general information provided on the Portal is not a substitute for the advice of a personal physician or 
									other qualified health care professional. Always seek the advice of a physician or other qualified health 
									care professional with any questions User may have regarding medical symptoms or a medical condition. Never 
									disregard professional medical advice or delay in seeking it because of something User has read on the Portal. 
									Neither the Portal nor the Scheduling Service are meant to be used in any manner in the case of urgent or 
									emergency medical issues. If User experiences an emergency, User should immediately seek appropriate emergency care. 
								</p>
							</li>
						</ol>
					</li>
					<li>
						<p>
							<u>Compliance with Laws</u>. User agrees to use the Scheduling Service in accordance with applicable data privacy and other laws, including, 
							without limitation, the Health Insurance Portability and Accountability Act of 1996, as amended (“<b>HIPAA</b>”). 
						</p>
					</li>
					<li>
						<p>
							<u>Indemnification</u>. User agrees to indemnify, defend, and hold MHD, and its officers, directors, affiliates, agents, licensors, suppliers, 
							successors and advisors harmless from and against any and all losses, damages, judgments, assessments, penalties, amounts paid in settlement, 
							costs and expenses (including reasonable attorneys’ and expert witness fees) (collectively, “Claims”) arising out of or related in any manner 
							to (i) use of the Scheduling Service or Portal by User or any other third party accessing the Scheduling Service or Portal with User’s access 
							credentials, except and solely to the extent directly caused by MHD’s gross negligence or willful misconduct, (ii) inaccurate or incomplete 
							information regarding User, (iii) if User is the provider of healthcare services, the medical malpractice of User, its agents, or its 
							employees, (iv) the failure of User or its employees to hold a required permit or license or to comply with any applicable law including, but 
							not limited, to HIPAA, and (v) the failure by User to comply with any term or condition of this Agreement.
						</p>
					</li>
					<li>
						<p>
							<u>Limitation on Liability</u>. IN NO EVENT SHALL MHD, ITS SUPPLIERS, AFFILIATES, OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR 
							AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER. THE FOREGOING 
							LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. IF ANY EXCLUSION, DISCLAIMER OR 
							OTHER PROVISION CONTAINED IN THIS AGREEMENT IS HELD TO BE INVALID FOR ANY REASON BY A COURT OF COMPETENT JURISDICTION, AND MHD, OR ONE OF ITS 
							AFFILIATES, OFFICERS, DIRECTORS, AGENTS OR EMPLOYEES BECOMES LIABLE FOR LOSS OR DAMAGE THAT COULD OTHERWISE BE LIMITED, SUCH LIABILITY 
							WHETHER IN CONTRACT, TORT OR OTHERWISE WILL NOT EXCEED IN THE AGGREGATE $100.00. IF USER IS A RESIDENT OF THE STATE OF NEW JERSEY, TO THE 
							EXTENT NEW JERSEY LAW PROHIBITS THE LIMITATIONS AND/OR EXCLUSIONS OF LIABILITY SET FORTH IN THIS AGREEMENT, SUCH LIMITATIONS AND/OR 
							EXCLUSIONS SHALL NOT APPLY TO USER. Any claim or cause of action arising out of or related to User’s use of the Scheduling Services or this 
							Agreement must be filed within one (1) year after such claim or cause of action arose or it shall forever be barred, notwithstanding any 
							statute of limitations or other law to the contrary. 
						</p>
					</li>
					<li>
						<p>
							<u>Injunctive Relief</u>. User acknowledges that MHD shall be entitled to temporary and permanent injunctive relief without the necessity of 
							having to post a bond or other security, specific performance or other equitable relief in the event of a breach by User of any of the 
							provisions set forth in this Agreement. Such remedy shall be cumulative and nonexclusive and shall be in addition to any other remedy to which 
							MHD may be entitled.
						</p>
					</li>
					<li>
						<p>
							<u>Notice</u>. All notices to MHD shall be in writing and shall be sent by facsimile transmission, or by prepaid 
							courier service, or by certified mail, postage prepaid. Notices to MHD shall be sent to My Health Direct, Inc., 102 
							Woodmont Boulevard, Suite 400, Nashville, TN 37205, facsimile (615) 866-0645, attention: CEO, or such other address 
							or fax number as MHD may designate by notice duly given. Notices to User shall be sent to the address or fax number 
							indicated on the preface to this Agreement, or to such other address or fax number as User may designate by notice 
							duly given. 
						</p>
					</li>
					<li>
						<p>
							<u> User Data</u>.
						</p>
						<ol>
							<li>
								<p>
									“<b>User Data</b>” means any of User’s information, documents, or electronic files that are provided by or 
									otherwise received from User by or through the Scheduling Service hereunder. For the avoidance of doubt, 
									User Data does not include Resultant Data or any other information reflecting the access or use of the 
									Scheduling Service by or on behalf of User. 
								</p>
							</li>
							<li>
								<p>
									“<b>Resultant Data</b>” means (i) data and information related to User’s use of the Scheduling Service that is used by MHD in an aggregate 
									and anonymized manner, including to compile statistical and performance information related to the provision 
									and operation of the Scheduling Service and the Portal, and (ii) de-identified User Data. 
								</p>
							</li>
							<li>
								<p>
										As between User and MHD, User owns all rights, title, and interest in the User Data. Subject to Section 13.4 below, User hereby irrevocably 
										grants all such rights and permissions in or relating to the User Data as are necessary or useful to MHD to provide the Scheduling Services and 
										its other obligations under this Agreement. User acknowledges and agrees that (a) MHD may, from time to time, communicate with User by e-mail, 
										online newsletters (in the form of e- mail), text message or SMS message, pop-up windows and confirmation letters, (b) MHD may notify User if 
										there are any new or deleted features of the Scheduling Service, (c) MHD strives to use commercially acceptable means to protect the personal 
										information of User, but MHD does not guarantee the confidentiality of any communication or material transmitted to or from the Scheduling 
										Service, transmitted by the internet or other global computer networks or transmitted by e- mail or facsimile transmission between User and 
										MHD, (d) any e-mail or facsimile transmission between User and MHD may be shared with a representative, contractor, employee, agent or advisor 
										of MHD that is most able to address the inquiry, (e) MHD may conduct voluntary surveys from time to time for the purpose of improving the 
										Scheduling Service, which MHD may share with third parties to illustrate, among other things, samplings of users and general user preferences; 
										however, participation in any such survey is voluntary, the results of such surveys will be displayed as aggregate results and User’s individual 
										identity will not be released in survey results to any third party without User’s prior consent, and (f) MHD may share with MHD’s partners 
										aggregate statistical information regarding, among other things, site usage of User. Any information available through the Scheduling Service 
										that is not identified as having been prepared by MHD (including, without limitation, any information accessed through a third party website 
										referenced or linked from the Scheduling Service) is not endorsed by MHD and is provided for the convenience of User only and MHD has not 
										evaluated and does not guarantee the accuracy or completeness of any such information. Notwithstanding anything to the contrary in this 
										Agreement, MHD may use and disclose personally identifiable health information as otherwise permitted by law and pursuant to the terms of this 
										Agreement, such as for treatment, payment, and health care operation purposes, or in connection with a potential business transaction, 
										including a merger, reorganization, consolidation, or sale of all or substantially all of MHD’s assets.  
								</p>
							</li>
							<li>
								<p>
									MHD also may use or disclose User Data to MHD’s third-party partners as necessary to provide the Scheduling Service to User, or if MHD is 
									required to do so by law or MHD in good faith believes that such action is necessary to (a) comply with the law or with legal process; (b) 
									protect and defend MHD’s rights and property; (c) protect against misuse or unauthorized use of the Scheduling Service; or (d) protect the 
									personal safety or property of MHD’s users or the public. Please note MHD may not provide User with notice prior to disclosure in such cases. 
								</p>
							</li>
							<li>
								<p>
									MHD may de-identify User Data for data benchmarking, sharing, warehousing, resource utilization, similar data analysis services, and the 
									sharing of any such de-identified data by MHD. Any and all such de-identified data shall be deemed Resultant Data and shall be owned by 
									MHD. In furtherance of the foregoing, User hereby unconditionally and irrevocably assigns all right, title, and interest in and to the 
									Resultant Data, including all intellectual property rights therein. Notwithstanding anything to the contrary in this Agreement, User 
									acknowledges and agrees that MHD has the right to use the Resultant Data for any purpose according to MHD’s sole discretion. 
								</p>
							</li>
						</ol>
					</li>
						
					<li>
						<p>
							<i>Disputes</i>. This Agreement shall be governed by the internal substantive laws of the State of Tennessee, without 
							respect to its conflict of laws principles. Any claim or dispute between User and MHD that arises in whole or in part from 
							the Scheduling Services, the Portal, or this Agreement shall be decided exclusively by a court of competent jurisdiction 
							located in the State of Tennessee; provided, that User hereby agrees that any dispute arising out of or relating in any way 
							to this Agreement or User’s use of the Scheduling Services, Portal, and/or any information, materials or services User 
							obtains from MHD requires that such claim be resolved exclusively by confidential binding arbitration, except that, to the 
							extent User has in any manner violated or threatened to violate MHD’s intellectual property rights or confidential 
							information, MHD may seek injunctive or other appropriate relief in a court of competent jurisdiction in the State of 
							Tennessee. The arbitration shall be conducted before three neutral arbitrators in Nashville, Tennessee, U.S.A., in 
							accordance with the rules of the American Arbitration Association (“AAA”), as then in effect. No claims of any other parties 
							may be joined or otherwise combined in the arbitration proceeding. Unless otherwise expressly required by applicable law, 
							each party shall bear its own attorneys’ fees without regard to which party is deemed the prevailing party in the arbitration 
							proceeding. Except for punitive and consequential damages (which may not be awarded), and subject to this Agreement, the 
							arbitrators shall be authorized to award either party any provisional or equitable remedy permitted by applicable law. 
							BECAUSE THE USE OF THE SCHEDULING SERVICES AND PORTAL REQUIRES THE ARBITRATION OF ANY CLAIMS OR DISPUTES EXISTING BETWEEN THE 
							PARTIES, NEITHER PARTY WILL HAVE THE RIGHT TO PURSUE THAT CLAIM IN COURT OR BEFORE A JUDGE OR JURY OR TO PARTICIPATE IN A CLASS 
							ACTION OR ANY OTHER COLLECTIVE OR REPRESENTATIVE PROCEEDING. THE ARBITRATORS’ DECISION WILL BE FINAL AND BINDING. OTHER RIGHTS 
							THAT EITHER PARTY WOULD HAVE IF SUCH PARTY WENT TO COURT, INCLUDING WITHOUT LIMITATION THE RIGHT TO CONDUCT DISCOVERY OR TO 
							APPEAL, MAY BE LIMITED OR UNAVAILABLE IN ARBITRATION. The award of the arbitrators may be enforced in any court having 
							jurisdiction thereof. Each party hereby consents (i) to the non-exclusive jurisdiction of the state or federal courts located 
							in Nashville, Tennessee for any action (a) to compel arbitration, (b) to enforce any award of the arbitrators, (c) at any time 
							prior to the qualification and appointment of the arbitrators, for temporary, interim or provisional equitable remedies, or (d) 
							to enforce MHD’s intellectual property rights or rights in confidential information, and (ii) to service of process in any such 
							action by registered mail or any other means provided by law. Should this section be deemed invalid or otherwise unenforceable 
							for any reason, it shall be severed and the parties agree that sole and exclusive jurisdiction and venue for any claims will be 
							in the state or federal courts in Nashville, Tennessee.
						</p>
					</li>
					<li>
						<p>
							<u>Miscellaneous</u>. User shall not, without the prior written consent of MHD, transfer or assign, in whole or in part, its rights or 
							obligations under this Agreement. Any assignment or transfer in contravention of the foregoing sentences shall be deemed null and void. 
							This Agreement shall be binding upon and shall inure to the benefit of the parties hereto and their respective successors, legal 
							representatives and permitted assigns. The waiver by either party of a breach of any provision of this Agreement shall not be deemed a waiver 
							of any subsequent breach. If any provision of this Agreement shall be held to be invalid or unenforceable, the remainder of this Agreement 
							shall not be affected thereby and each provision of this Agreement shall be valid and enforceable to the fullest extent permitted by law. 
							The terms of this Agreement that by their nature survive the termination of this Agreement, including, without limitation, Sections 2, 4, 7.2, 
							7.3, 8-10, and 13-17 of this Agreement, shall so survive. 
						</p>
					</li>
					<li>
						<p>
							<u>Entire Agreement</u>. This Agreement contain the entire understanding between the parties with respect to the subject matter hereof and 
							thereof, and all prior discussions and agreements are merged herein and therein and superseded hereby and thereby. 
						</p>
					</li>
				</ol>
				<p>
					25525127.v8
				</p>
			</div>
		</div>
	)
}
