/**
 * @format
 */

import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import {
	displayFullName,
	displayProviderNameWithProfessionalDesignation,
	valueDisplay,
	valueDisplayBottomMargin,
} from '../../lib/standardLibrary';
import { DisplayCustomValueResponses } from '../common/standardComponents';
import { PhoneNumber } from '../common/phoneNumber';

export class RequestAppointmentDetails extends React.Component {
	render() {
		const {
			bookAnotherAppointment,
			details,
			isGroupNumberActive,
			isMemberIdActive,
			memberIdFieldLabel,
			newSearch,
			providerFieldConfig,
		} = this.props;

		const providerConfig = {
			fax: providerFieldConfig.fax,
			phone: providerFieldConfig.phone,
		};

		const customValueComponents =
			details.customValues && details.customValues.length > 0
				? details.customValues.map((customField) => (
						<DisplayCustomValueResponses label={customField.label} value={customField.value} />
				  ))
				: '';

		let isHomePreferredContact = Boolean(
			details.preferredContactPhoneType && details.preferredContactPhoneType.trim() === 'Home',
		);
		let isMobilePreferredContact = Boolean(
			details.preferredContactPhoneType && details.preferredContactPhoneType.trim() === 'Cell',
		);

		let providerFullName = displayFullName(details.providerFirstName, details.providerLastName);
		let providerFullNameProfessionalDesignation = displayProviderNameWithProfessionalDesignation(
			providerFullName,
			details.providerProfessionalDesignation,
		);

		let headerText = 'Request Appointment Details';
		let subheaderText = 'Request Information';

		let showCustomValues = Boolean(details.customValues && details.customValues.length > 0);

		return (
			<div>
				<h4 style={{ marginBottom: '10px' }}>{headerText}</h4>
				{!details ? (
					<div>
						<Row>
							<Col>
								<p className="fw-bold form-label">No appointment request found!</p>
							</Col>
						</Row>
					</div>
				) : (
					<div className="col-lg-12 col-md-12 col-sm-12" style={{ paddingLeft: '0px', paddingTop: '20px' }}>
						<Row style={{ marginBottom: '20px' }}>
							<Col lg="3">
								<div
									style={{
										background: '#FAFAFA',
										border: 'solid 1px #E0E0E0',
										padding: '10px',
										height: '100%',
										borderRadius: '5px',
									}}
								>
									<h5 className="fw-bold" style={{ marginBottom: '10px' }}>
										Patient Information
									</h5>
									<div>
										<p className="fw-bold form-label">NAME</p>
										<p style={valueDisplay}>
											{details.patientFirstName ? `${details.patientFirstName} ${details.patientLastName}` : '-'}
										</p>
									</div>
									{details.patientMemberId && isMemberIdActive === true ? (
										<div>
											<p className="fw-bold form-label">
												{!memberIdFieldLabel ? '' : memberIdFieldLabel.toUpperCase()}
											</p>
											<p>{details.patientMemberId}</p>
										</div>
									) : (
										<div></div>
									)}
									{details.patientGroupNumber && isGroupNumberActive === true ? (
										<div>
											<p className="fw-bold form-label">GROUP NUMBER</p>
											<p>{details.patientGroupNumber}</p>
										</div>
									) : (
										<div></div>
									)}
									<div>
										<p className="fw-bold form-label">DATE OF BIRTH</p>
										<p>{details.patientDateOfBirth ? moment(details.patientDateOfBirth).format('MM/DD/YYYY') : '-'}</p>
									</div>
									<div>
										{isHomePreferredContact ? (
											<p className="fw-bold form-label">HOME (Preferred)</p>
										) : (
											<p className="fw-bold form-label">HOME</p>
										)}
										<PhoneNumber value={details.patientHomePhone?.trim()} />
									</div>
									<div>
										{isMobilePreferredContact ? (
											<p className="fw-bold form-label">MOBILE (Preferred) </p>
										) : (
											<p className="fw-bold form-label">MOBILE</p>
										)}
										<PhoneNumber value={details.patientMobilePhone?.trim()} />
									</div>
									<div>
										<p className="fw-bold form-label">ADDRESS</p>
										{details.patientAddressLine1 ? (
											<div>
												<p style={{ margin: '0px' }}>{details.patientAddressLine1}</p>
												{details.patientAddressLine2 ? (
													<p style={{ margin: '0px' }}>{details.patientAddressLine2}</p>
												) : (
													''
												)}
												<p>
													{details.patientCity}
													{', '}
													{details.patientState} {details.patientZip}
												</p>
											</div>
										) : (
											'-'
										)}
									</div>
								</div>
							</Col>
							<Col lg="9">
								<div
									style={{
										background: '#FAFAFA',
										border: 'solid 1px #E0E0E0',
										padding: '10px',
										height: '100%',
										borderRadius: '5px',
									}}
								>
									<h5 className="fw-bold" style={{ marginBottom: '10px' }}>
										{subheaderText}
									</h5>
									<Row>
										<Col lg="4">
											<div>
												<p className="fw-bold form-label">WHERE</p>
												<p style={valueDisplay}>
													{details.providerFirstName || details.providerLastName
														? providerFullNameProfessionalDesignation
														: '-'}
												</p>
												<p style={valueDisplay}>
													{details.providerSiteName ? details.providerSiteName : '-'}
													{details.providerSiteName && details.providerSystemName ? ', ' : ''}
													{details.providerSystemName ? details.providerSystemName : ''}
												</p>
												{!details.suppressPhoneNumber && providerConfig.phone && providerConfig.phone.isVisible && (
													<PhoneNumber
														label={providerConfig.phone.fieldLabel ? providerConfig.phone.fieldLabel : '(O)'}
														value={details.providerPhoneNumber?.trim()}
													/>
												)}
												{providerConfig.fax && providerConfig.fax.isVisible && (
													<PhoneNumber
														label={providerConfig.fax.fieldLabel ? providerConfig.fax.fieldLabel : '(F)'}
														value={details.providerFaxNumber?.trim()}
													/>
												)}
												{!details.suppressAddress && details.providerAddressLine1 ? (
													<div>
														<p style={valueDisplay}>{details.providerAddressLine1}</p>
														{details.providerAddressLine2 ? (
															<p style={valueDisplay}>{details.providerAddressLine2}</p>
														) : (
															''
														)}
														<p>
															{details.providerCity}
															{', '}
															{details.providerState} {details.providerZip}
														</p>
													</div>
												) : (
													<div></div>
												)}
											</div>
										</Col>
										<Col lg="8">
											<div>
												<p className="fw-bold form-label">SPECIALTY</p>
												<p style={valueDisplayBottomMargin}>{details.specialty ? details.specialty : '-'}</p>
											</div>
											<div>
												<p className="fw-bold form-label">INSURANCE</p>
												{details.payorType ? (
													<div>
														<p style={valueDisplay}>{details.payorType}</p>
													</div>
												) : (
													<div></div>
												)}
												{details.insuranceCarrierName ? (
													<div>
														<p style={valueDisplay}>{details.insuranceCarrierName}</p>
													</div>
												) : (
													<div></div>
												)}
											</div>
										</Col>
									</Row>
									<Row>
										<Col lg="8">
											<div>
												<p className="fw-bold form-label">REASON FOR VISIT</p>
												<p style={valueDisplayBottomMargin}>{details.reasonForVisit ? details.reasonForVisit : '-'}</p>
											</div>
											<div>
												<p className="fw-bold form-label">SCHEDULING INFORMATION</p>
												<p style={valueDisplayBottomMargin}>
													{details.schedulingInformation ? details.schedulingInformation : '-'}
												</p>
											</div>
										</Col>
									</Row>
									<Row className="no-print"></Row>
								</div>
							</Col>
						</Row>
						{details.infoForConsumerImageFile ||
						details.informationForConsumer ||
						details.informationForPatient ||
						showCustomValues ? (
							<Row style={{ marginBottom: '20px' }}>
								<Col lg="12">
									<div style={{ background: '#FAFAFA', border: 'solid 1px #E0E0E0', padding: '10px' }}>
										<h5 className="fw-bold" style={{ marginBottom: '10px' }}>
											Additional Information
										</h5>
										{showCustomValues && (
											<div>
												<p className="fw-bold form-label">REQUEST INFORMATION</p>
												{customValueComponents}
											</div>
										)}
										{details.infoForConsumerImageFile ? (
											<div>
												<p>
													<img src={details.infoForConsumerImageFile} alt="providerImage" />
												</p>
											</div>
										) : (
											<div></div>
										)}
										{details.informationForConsumer ? (
											<div>
												<p className="fw-bold form-label">INFORMATION FROM PROVIDER</p>
												<p dangerouslySetInnerHTML={{ __html: details.informationForConsumer }}></p>
											</div>
										) : (
											<div></div>
										)}
										{details.informationForPatient ? (
											<div>
												<p className="fw-bold form-label">PRE-VISIT INFORMATION FOR PATIENT</p>
												<p dangerouslySetInnerHTML={{ __html: details.informationForPatient }}></p>
											</div>
										) : (
											<div></div>
										)}
									</div>
								</Col>
							</Row>
						) : (
							<div></div>
						)}
						{details.emailAttachments && details.emailAttachments.length > 0 ? (
							<Row style={{ marginBottom: '20px' }}>
								<Col lg="12">
									<div style={{ background: '#FAFAFA', border: 'solid 1px #E0E0E0', padding: '10px' }}>
										<h5 className="fw-bold" style={{ marginBottom: '10px' }}>
											Attachments
										</h5>
										<ul className="provider-languages">
											{details.emailAttachments.map((attachment, i) => {
												let fileName = attachment.substring(attachment.indexOf('/') + 1);
												return <li key={uuidv4()}>{fileName}</li>;
											})}
										</ul>
									</div>
								</Col>
							</Row>
						) : (
							<div></div>
						)}
						<div className="no-print" style={{ marginBottom: '20px', marginTop: '10px' }}>
							<Row>
								<Col lg="12">
									<div style={{ padding: '10px' }}>
										<h5 className="fw-bold" style={{ marginBottom: '10px' }}>
											Requesting PCP Information
										</h5>
										<Row>
											<Col lg="3">
												<div>
													<p className="fw-bold form-label">REQUESTING PCP SYSTEM NAME</p>
													<p style={valueDisplay}>{details.referringSystemName ? details.referringSystemName : '-'}</p>
												</div>
											</Col>
											<Col lg="4">
												<div>
													<p className="fw-bold form-label">REQUESTING PCP SITE NAME</p>
													<p style={valueDisplay}>{details.referringSiteName ? details.referringSiteName : '-'}</p>
												</div>
											</Col>
										</Row>
										<Row style={{ marginBottom: '20px', marginTop: '10px' }}>
											<Col lg="3">
												<div>
													<p className="fw-bold form-label">REQUESTED BY</p>
													<p style={valueDisplay}>{details.requestedByName ? details.requestedByName : '-'}</p>
												</div>
											</Col>
											<Col lg="4">
												<div>
													<p className="fw-bold form-label">PHONE</p>
													<PhoneNumber value={details.referringSitePhone?.trim()} />
												</div>
											</Col>
										</Row>
										<Row style={{ marginBottom: '20px', marginTop: '10px' }}>
											<Col lg="3">
												<div>
													<p className="fw-bold form-label">TIMESTAMP</p>
													<p>
														{details.requestedDateTime
															? moment(details.requestedDateTime).format('MM/DD/YYYY hh:mm A ') +
															  details.providerTimeZone
															: '-'}
													</p>
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg="12">
									<div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
										<Button color="primary" onClick={newSearch} style={{ marginRight: '8px' }}>
											Start a new search
										</Button>
										<Button color="primary" onClick={bookAnotherAppointment} style={{ marginRight: '8px' }}>
											Book another appointment
										</Button>
										{this.props.printOrder()}
									</div>
								</Col>
							</Row>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default RequestAppointmentDetails;
