import { useDispatch, useSelector } from "react-redux";
import { clearAllErrors } from "../../../actions/errorActions";
import { Alert } from "reactstrap";

export default function ErrorAlert(props) {
    const dispatch = useDispatch();
    const errorMessage = useSelector(state => {
        if (state?.apiErrors?.activeErrors?.length) {
            let activeError = state.apiErrors.activeErrors[0];
            return activeError?.error?.userMessage
                ? activeError?.error?.userMessage
                : activeError?.actionId === 'APPOINTMENT_RESERVE'
                    ? 'An error occurred while attempting to reserve timeslot.'
                    : 'An error occurred while attempting to search.';
        } else {
            return null;
        }
    });

    if (errorMessage)
        return (
            <div>
                <Alert
                    color="danger"
                    toggle={
                        () =>
                            dispatch(clearAllErrors())
                    }
                >
                    {errorMessage}
                </Alert>
            </div>
        );

    return null;
}