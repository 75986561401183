import { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { keepSessionAlive } from '../../actions/authActions';
import { TIMEOUTS } from "./idleConstants";

//Adapted from code in this example https://javascript.plainenglish.io/advanced-react-how-to-build-user-idle-component-using-hooks-81f8ef6fdefc

export default function IdlePopup({ open, onClose, timedOut, setTimedOut, setOpen, setReset }) {
    const [time, setTime] = useState(TIMEOUTS.POPUP_COUNTDOWN_TIME / TIMEOUTS.ONE_MIN);
    const [login, setLogin] = useState(false);
    const mounted = useRef(0);
    const dispatch = useDispatch();

    const handleTimedOut = () => {
        setOpen(false);
        setReset(Date.now());
        setTimedOut(false);
    };

    const handleContinue = () => {
        setOpen(false);
        setReset(Date.now());
        dispatch(keepSessionAlive()); // check if authActions.exchangeToken() is the same
    };

    const handleLogin = () => {
        setOpen(false);
        setReset(Date.now());
        setLogin(true);
    };

    useEffect(() => {
        if (login) { 
            window.open(`${window.location.origin}/login`, '_blank');
        }
    }, [login])

    //Countdown
    useEffect(() => {
        if (open) {
            if (time <= 0) {
                mounted.current && window.clearTimeout(mounted.current);
                return;
            }
            mounted.current = window.setTimeout(
                () => setTime(time - 1),
                TIMEOUTS.ONE_MIN
            );
        } else {
            setTime(TIMEOUTS.POPUP_COUNTDOWN_TIME / TIMEOUTS.ONE_MIN);
        }
        return () => {
            mounted.current && window.clearTimeout(mounted.current);
        };
    }, [open, time, onClose]);

    return (
        <Modal
            isOpen={open}
            toggle={!timedOut ? (
                () => handleContinue()
            ) : (
                () => handleTimedOut()
            )}
            className="danger">
            <ModalHeader>
                This page has been idle for too long.
            </ModalHeader>
            <ModalBody>
                {!timedOut ? (
                    <>
                        {`You may be logged out in ${time} `}
                        {(time > 1) ? 'minutes. ' : 'minute. '}
                        {'Do you want to continue working on this page?'}
                    </>
                ) : (
                    <>
                        {`If your session has expired, you will be directed to log in to continue working.`}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                {!timedOut ? (
                    <Button onClick={() => handleContinue()}>
                        Stay Signed In
                    </Button>
                ) : (
                    <>
                        <Button onClick={() => handleLogin()}>
                            Login
                        </Button>
                        <Button onClick={() => handleTimedOut()}>
                            Close
                        </Button>                    
                    </>
                )}
            </ModalFooter>
        </Modal>
    )
}