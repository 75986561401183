import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import { getPatientReferenceId } from '../../actions/patientActions';
import { patientDetails as routeToPatientDetails } from '../../routes';
import { PatientNotFound } from '../../components/NotFound';
import LoadingIndicator from '../../components/loadingIndicator';

export default function PatientRedirector(props) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const config = useSelector((state) => state.config);

    const patientConfig = config.patient;
    const queryStringParams = qs.parse(props.history.location.search, { ignoreQueryPrefix: true });
    const routeParams = props.match.params;

    useEffect(() => {
        if (patientConfig.allowExternalPatientDetailsUrl) {
            if (queryStringParams.refType && routeParams.refValue) {
                dispatch(getPatientReferenceId(queryStringParams.refType, routeParams.refValue)).then((response) => {
                    setLoading(false);
                    if (response.payload.data !== '') {
                        dispatch(routeToPatientDetails(response.payload.data));
                    }
                });
            } else {
                setLoading(false);
                if (routeParams.refValue) {
                    dispatch(routeToPatientDetails(routeParams.refValue.toLowerCase()));
                }
            }
        } else {
            setLoading(false);
        }
    }, [dispatch, patientConfig.allowExternalPatientDetailsUrl, queryStringParams.refType, routeParams.refValue])

    return isLoading ? <LoadingIndicator /> : <PatientNotFound />;
}
