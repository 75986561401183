import React from 'react';
import PropTypes from 'prop-types';
import OrderFormRow from './orderFormRow';
import AddSchedulableOrdersModal from '../modals/addSchedulableOrdersModal';
import { DropdownSelectWithLabel } from '../common/standardComponents';
import { Button, Col, Row, Table } from 'reactstrap';
import LoadingIndicator from '../loadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FollowUpDate from './followUpDate';

import './createOrderForm.css';
export default function OrderForm (props) {
	const {
		appointments,
		customDateRangeEnd,
		customDateRangeEndIsValid,
		customDateRangeEndPicker,
		customDateRangeStart,
		customDateRangeStartIsValid,
		customDateRangeStartPicker,
		dateRangeList,
		departmentList,
		disableAdditionalOrders,
		enableProductInstances,
		enableSave,
		isSavingCreate,
		isSchedulableOrdersModalOpen,
		maxRows,
		onAddRadiologyOrderClick,
		onCreateOrderClick,
		onCustomDateBlur,
		onCustomDateRangeChange,
		onDatePickerChange,
		onDateRangeOptionChange,
		onDropdownChange,
		onRemoveRowClick,
		onRowDropdownChange,
		onSchedulableOrderChange,
		onSchedulableOrdersClose,
		onSchedulableOrdersSave,
		optionLists,
		patientDetails,
		productInstances,
		schedulableOrdersList,
		selectedConsumerProductInstance,
		selectedDateRange,
		selectedDateRangeOption,
		selectedSchedulableOrdersList,
		shouldValidate,
		subgroups,
		validateCustomDateRange,
	} = props;

	let disableAddAppointments = appointments.length && appointments.length >= maxRows;
	let showSubgroup = appointments.some(x => x.externalReferralOrderId !== "");

	const orderFormRows = appointments.map((orderItem, index) => {
		return (
			<OrderFormRow
				index={index}
				orderItem={orderItem}
				departmentList={departmentList}
				onRowDropdownChange={onRowDropdownChange}
				options={optionLists[index] || []}
				onRemoveRowClick={onRemoveRowClick}
				shouldValidate={shouldValidate}
				key={index}
				showSubgroup={showSubgroup}
				subgroups={subgroups}
			/>
		);
	});

	let isLoaded = (departmentList && departmentList.length > 0) && (dateRangeList && dateRangeList.length > 0);
	let isSchedulableOrdersSaveDisabled = (Array.isArray(selectedSchedulableOrdersList) && selectedSchedulableOrdersList.length > 0 ? false : true);
	return (
		<React.Fragment>
			{ !isLoaded &&
				<LoadingIndicator />
			}
			{ isLoaded &&
				<div className="col-lg-12 order-form-content">
					<Row>
						<Col lg="10">
							{ enableProductInstances &&
								<DropdownSelectWithLabel
									isRequired
									shouldValidate={shouldValidate}
									optionList={productInstances}
									id="productInstance"
									label="Select a Self-Schedule Product Instance"
									value={selectedConsumerProductInstance}
									onChange={(e) => { onDropdownChange(e, 'selectedConsumerProductInstance') }} />
							}
							<FollowUpDate
								customDateRangeEnd={customDateRangeEnd}
								customDateRangeEndIsValid={customDateRangeEndIsValid}
								customDateRangeEndPicker={customDateRangeEndPicker}
								customDateRangeStart={customDateRangeStart}
								customDateRangeStartIsValid={customDateRangeStartIsValid}
								customDateRangeStartPicker={customDateRangeStartPicker}
								dateRangeList={dateRangeList}
								onCustomDateRangeChange={onCustomDateRangeChange}
								onDatePickerChange={onDatePickerChange}
								onDateRangeOptionChange={onDateRangeOptionChange}
								onDropdownChange={onDropdownChange}
								selectedDateRange={selectedDateRange}
								selectedDateRangeOption={selectedDateRangeOption}
								shouldValidate={shouldValidate}
								onCustomDateBlur={onCustomDateBlur}
								validateCustomDateRange={validateCustomDateRange} />
							{ Array.isArray(schedulableOrdersList) && schedulableOrdersList.length > 0 &&
								<Button color="link" disabled={disableAddAppointments} onClick={onAddRadiologyOrderClick}>Add Radiology Order(s)</Button>
							}
							<AddSchedulableOrdersModal
								isOpen={isSchedulableOrdersModalOpen}
								isSaveDisabled={isSchedulableOrdersSaveDisabled}
								onSave={onSchedulableOrdersSave}
								onCancel={onSchedulableOrdersClose}
								ordersList={schedulableOrdersList}
								selectedOrdersList={selectedSchedulableOrdersList}
								onOrderCheck={onSchedulableOrderChange}
								patient={patientDetails}
								disableAddOrders={disableAdditionalOrders}
							/>
						</Col>
					</Row>
					<Table responsive>
						<thead className="order-form-table-header">
							<tr>
								<th>Department</th>
								<th>Provider or Resource</th>
								{ showSubgroup && <th>Subgroup</th> }
								<th>Visit Type</th>
								<th>Referring Department</th>
								<th>Referring Provider</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{orderFormRows}
						</tbody>
					</Table>
					<Button onClick={props.onAddAppointmentClick} color="link" disabled={disableAddAppointments}>Add Follow Up Appointment</Button>
					<div style={{ float: "right" }}>
						<Button disabled={!enableSave || isSavingCreate} onClick={onCreateOrderClick} color="primary">{isSavingCreate? <FontAwesomeIcon icon="spinner" spin /> : null}{isSavingCreate ? 'Saving, please wait...' : 'Create Follow Up Order'}</Button>
					</div>
				</div>
			}
		</React.Fragment>
	);
}

OrderForm.propTypes = {
	appointments: PropTypes.array,
	dateRangeList: PropTypes.array,
	departmentList: PropTypes.array,
	isAddToRadiologyOrdersModalOpen: PropTypes.bool,
	isSavingCreate: PropTypes.bool,
	maxRows: PropTypes.number,
	onAddToRadiologyOrdersCancel: PropTypes.func,
	onAddToRadiologyOrdersClick: PropTypes.func,
	onAddToRadiologyOrdersSave: PropTypes.func,
	onCreateOrderClick: PropTypes.func,
	onDateRangeChange: PropTypes.func,
	onRemoveRowClick: PropTypes.func,
	onRowDropdownChange: PropTypes.func,
	onSchedulableOrderChange: PropTypes.func,
	optionLists: PropTypes.array,
	patientDetails: PropTypes.object,
	productInstances: PropTypes.array,
	schedulableOrdersList: PropTypes.array,
	selectedConsumerProductInstance: PropTypes.number,
	selectedDateRange: PropTypes.number,
	selectedSchedulableOrders: PropTypes.array,
	selectedSchedulableOrdersList: PropTypes.array,
	shouldValidate: PropTypes.bool,
	subgroups: PropTypes.array,
}
