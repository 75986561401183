import { useDispatch, useSelector } from "react-redux";
import {
    selectPreferredDisplayedCalendarIds,
    selectNotPreferredDisplayedCalendarIds,
    selectViewBaseDate,
    changeViewDateThunk,
    selectNotPreferredFirstScanFirstAvailabilityDate
} from "../availabilitySlice";
import DateNav from "./dateNav";
import ResultRow from "./resultRow";
import { Button, Col, Row } from "reactstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

export default function Results({ selectedSlot, setSelectedSlot }) {
    const dispatch = useDispatch();

    const preferredDisplayedCalendarIds = useSelector(selectPreferredDisplayedCalendarIds);
    const notPreferredDisplayedCalendarIds = useSelector(selectNotPreferredDisplayedCalendarIds);

    const viewBaseDate = useSelector(selectViewBaseDate);
    const notPreferredFirstScanFirstAvailabilityDate = useSelector(selectNotPreferredFirstScanFirstAvailabilityDate);

    const hasEarlierThanDisplayedAvailability = notPreferredFirstScanFirstAvailabilityDate
        && dayjs(notPreferredFirstScanFirstAvailabilityDate).diff(viewBaseDate, 'd') < 0;

    return (
        <>
            {notPreferredDisplayedCalendarIds?.length > 0 &&
                <>
                    {preferredDisplayedCalendarIds?.length > 0 &&
                        <Row>
                            <Col>
                                <h4 className="availability-result-subheader-divider">
                                    Additional Recommended Providers
                                    {hasEarlierThanDisplayedAvailability &&
                                        <span
                                            style={{
                                                marginLeft: "24px",
                                                fontSize: "20px",
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCalendarCheck}
                                                // bounce
                                                size="lg"
                                                style={{
                                                    color: "#0ba275",
                                                    marginRight: "6px"
                                                }}
                                            />
                                            Earlier Appointments Available on
                                            <Button
                                                style={{
                                                    marginLeft: "6px",
                                                    fontWeight: "bold",
                                                    textDecoration: "underline",
                                                    fontSize: "20px",
                                                }}
                                                color="link"
                                                className="first-available-button"
                                                onClick={() => dispatch(changeViewDateThunk(notPreferredFirstScanFirstAvailabilityDate, true))}
                                            >
                                                {dayjs(notPreferredFirstScanFirstAvailabilityDate).format("MMM D YYYY")}
                                            </Button>
                                        </span>
                                    }
                                </h4>
                            </Col>
                        </Row>
                    }
                    <DateNav />
                    {notPreferredDisplayedCalendarIds.map((calendarId, index, array) =>
                        <ResultRow
                            key={calendarId}
                            calendarId={calendarId}
                            isLastRow={array.length - 1 === index}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                        />
                    )}
                </>
            }
        </>
    );
}


