import { MouseEventHandler, useEffect, useState } from "react";
import { getProductAlertConfig } from "actions/productAlertConfigActions";
import { useDispatch } from "react-redux";
import { Form } from "reactstrap";
import LoginError from "./loginError";
import UserEmailInput from "./userEmailInput";
import { PasswordInput } from "components/input";
import StartForgotPassword from "./startForgotPassword";
import * as routes from "../../../routes";
import SsoLoginButton from "./ssoLoginButton";
import LoginButton from "./loginButton";
import '../css/loginForm.css';

const LoginForm = (
    props: {
        handleSubmit: Function,
        handleSsoLogin: MouseEventHandler<HTMLButtonElement>,
        isLoading: boolean,
        error: string,
    }
) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductAlertConfig());
    }, [dispatch])

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //const isValid = () => {
    //    return email && email.trim() && password && password.trim();
    //}

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // if (isValid()) TODO: keeping current behavior for now
        props.handleSubmit(email, password);
    }

    return (
        <Form onSubmit={handleSubmit} className="loginForm">
            <LoginError isLoading={props.isLoading} error={props.error} />
            <UserEmailInput handleChange={(e) => setEmail(e.target.value)} value={email} />
            <PasswordInput
                onChange={(e: any) => setPassword(e.target.value)}
                label="Password"
                name="password"
                placeholder="Password"
            />
            <StartForgotPassword isLoading={props.isLoading} handleForgotPassword={() => dispatch(routes.forgotPassword())} />
            <LoginButton
                isLoading={props.isLoading}
                disabled={props.isLoading}
                text={"Login"}
                loadingText="Logging in..."
            />
            <SsoLoginButton isLoading={props.isLoading} handleSSOLogin={props.handleSsoLogin} />
        </Form>
    )

}

export default LoginForm;