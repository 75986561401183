import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Col,
    Row,
    Table,
} from 'reactstrap';

import './orderVisitDetails.css'

export default function OrderVisitDetails(props) {
    const { visit } = props;
    return (
        <div className="order-visit-container">
            <Row>
                <Col xs="auto">
                    <h6 align="center">Follow Up Date Range: <span>{visit.systemDateRangeName}</span></h6>
                </Col>
            </Row>
            <Table className="order-visit-table">
                <thead>
                    <tr key={visit.id} className="info-table">
                        <th>Department</th>
                        <th>Provider or Resource</th>
                        <th>Visit Type</th>
                        <th>Referring Provider</th>
                        <th>Referring Department</th>
                    </tr>
                </thead>
                <tbody>
                    {visit.careOrderVisitAppointments.length > 0 &&
                        visit.careOrderVisitAppointments.map((app) => {
                            let providerName = app.providerFirstName && app.providerLastName ? `${app.providerFirstName} ${app.providerLastName}` : '-';
                            let referringProviderName = app.referringProviderFirstName && app.referringProviderLastName ? `${app.referringProviderFirstName} ${app.referringProviderLastName}` : '-';
                            let referringSiteName = app.referringSiteName ? app.referringSiteName : '-';
                            return (
                                <tr key={app.id}>
                                    <td>{app.siteName}</td>
                                    <td>{providerName}</td>
                                    <td>{app.appointmentType}</td>
                                    <td>{referringProviderName}</td>
                                    <td>{referringSiteName}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>                                                        
            </Table>
        </div> 
    )
}

OrderVisitDetails.propTypes = {
    visit: PropTypes.shape({
        id: PropTypes.number,
        systemDateRangeName: PropTypes.string,
        careOrderVisitAppointments: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            siteName: PropTypes.string,
            providerFirstName: PropTypes.string,
            providerLastName: PropTypes.string,
            appointmentType: PropTypes.string,
        })),
    }),
}
