import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// for attachment upload on book appointment page in IE11
if (!FileReader.prototype.readAsBinaryString) {

	FileReader.prototype.readAsBinaryString = function (fileData) {

		this.content = "";
		const reader = new FileReader();

		reader.onload = (e) => {

			const byteArray = new Uint8Array(reader.result);

			for (let i = 0; i < byteArray.byteLength; i++) {
				this.content += String.fromCharCode(byteArray[i]);
			}

			this.onload();
		}

		reader.readAsArrayBuffer(fileData);
	}
}