import React from 'react';
import MhdErrorDisplay from './mhdErrorDisplay';
import { connect } from 'react-redux';
import * as routes from '../../routes';
import { getAppInsights } from './telemetryService';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null
		};
	}
	
	componentDidCatch(error, info) {
		let appInsights = getAppInsights();
		if (error.message === "not_specified") {
			error = new Error("An error occurred but no specific message was provided.");
		}
		appInsights.trackException(error);
		this.setState({ hasError: true, error: error, errorInfo: info });
	}

	patientSearch = () => {
		this.setState({ hasError: false });
		this.props.dispatch(routes.patientSearch());
	}

	render() {
		if (this.state.hasError) {
			return <MhdErrorDisplay
				error={this.state.error}
				errorInfo={this.state.errorInfo}
				childName={this.props.childName}
				patientSearch={this.patientSearch} />;
		} else {
			return this.props.children;
		}
	}
}

export default connect()(ErrorBoundary);