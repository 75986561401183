import React from 'react';

const lineFeedsRegex = new RegExp(/(\r\n?|\n)/g);
const replaceLineFeedsWithBreakRegex = (string) => {
	return string.replace(lineFeedsRegex, "<br />");
};

export default class AppliedPathwaysMessage extends React.Component {
	render() {
		return (
			<div dangerouslySetInnerHTML={{
				__html: replaceLineFeedsWithBreakRegex(this.props.asset.body)
			}} />
		);
	}
}
