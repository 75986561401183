import React from 'react';
import { DropdownSelect } from '../common/standardComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function OrderFormRow (props) {
	const {
		onRowDropdownChange,
		orderItem,
		index,
		departmentList,
		options,
		onRemoveRowClick,
		shouldValidate,
		showSubgroup,
		subgroups,
	} = props;

	const selectedProvider = options.providers ? options.providers.find(p => p.id === orderItem.provider) : null;
	const showReferringDepartmentAndProvider = selectedProvider ? selectedProvider.requiresReferralToBook : false;
	return (
		<tr>
			<td className="align-top">
				{orderItem.externalReferralOrderId !== "" ?
					<span>-</span>
				:
					<DropdownSelect isRequired shouldValidate={shouldValidate} optionList={departmentList} name="department" id={"departmentSelect" + index} label="Department" value={orderItem.department} onChange={e => onRowDropdownChange(e, index)} />
				}
			</td>
			<td className="align-top">
				{orderItem.externalReferralOrderId !== "" ?
					<span>-</span>
				: <DropdownSelect isRequired disabled={!(options.providers && options.providers.length)} shouldValidate={shouldValidate} optionList={options.providers || []} name="provider" id={"providerResourceSelect" + index} label="Provider/Resource" value={orderItem.provider} onChange={e => onRowDropdownChange(e, index)} />
				}
			</td>

			{ showSubgroup &&
				<td className="align-top">
					{orderItem.externalReferralOrderId !== "" ?
						<DropdownSelect isRequired disabled={!(subgroups && subgroups.length)} shouldValidate={shouldValidate} optionList={subgroups || []} name="subgroup" id={"subgroupSelect" + index} label="Subgroup" value={orderItem.subgroup} onChange={e => onRowDropdownChange(e, index)} />
					: <span>-</span>
					}
				</td>
			}

			<td className="align-top">
				{orderItem.externalReferralOrderId !== "" ?
					<span>{orderItem.visitTypeName}</span>
				: <DropdownSelect isRequired disabled={!(options.appointmentTypes && options.appointmentTypes.length)} shouldValidate={shouldValidate} optionList={options.appointmentTypes || []} name="visitType" id={"visitTypeSelect" + index} label="Visit Type" value={orderItem.visitType} onChange={e => onRowDropdownChange(e, index)} />
			}
			</td>
			<td className="align-top">
				{ orderItem.externalReferralOrderId !== "" &&
					<span>-</span>
				}
				{ showReferringDepartmentAndProvider &&
					<DropdownSelect shouldValidate={shouldValidate} optionList={departmentList} name="referringDepartment" id={"referringDepartmentSelect" + index} label="Referring Department" value={orderItem.referringDepartment} onChange={e => onRowDropdownChange(e, index)} />
				}
			</td>
			<td className="align-top">
				{ orderItem.externalReferralOrderId !== "" &&
						<span>{orderItem.referringProviderName}</span>
				}
				{ showReferringDepartmentAndProvider &&
					<DropdownSelect isRequired={!!orderItem.referringDepartment} disabled={!(options.referringProviders && options.referringProviders.length)} shouldValidate={shouldValidate} optionList={options.referringProviders || []} name="referringProvider" id={"referringProviderSelect" + index} label="Referring Provider" value={orderItem.referringProvider} onChange={e => onRowDropdownChange(e, index)} />
				}
			</td>
			<td className="align-top"><span style={{ color: "grey", cursor: "pointer", fontSize: "1.5em" }} onClick={() => onRemoveRowClick(index)}><FontAwesomeIcon icon={faTrash} /></span></td>
		</tr>
	);
}
