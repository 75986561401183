import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { Table } from 'reactstrap';
import { OrderStatusHistoryRow } from './index';

export default function OrderStatusHistoryTable (props) {

		const timezone = moment.tz.guess();

		const rows = props.orderHistory.map(historyEntry => {
			return (
				<OrderStatusHistoryRow historyEntry={historyEntry} timezone={timezone} />
			);
		});


		return (
		<Table striped={rows.length > 4} responsive>
			<thead className="order-form-table-header">
				<tr>
					<th>Date and Time</th>
					<th>Modified By</th>
					<th>Order Status</th>
					<th>Notes</th>
				</tr>
			</thead>
			<tbody>
				{rows}
			</tbody>
		</Table>
    );
}
