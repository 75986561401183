import ProductAlertBanner from "components/common/productAlertBanner";
import { useAppSelector } from "store"

const ProductAlert = () => {
    const productAlertConfig: any = useAppSelector((state: any) => state.productAlertConfig);
    const showBanner = productAlertConfig.config?.isEnabled &&
        productAlertConfig.config?.alertMessage &&
        productAlertConfig.isActive;

    return showBanner
        ? <div className='loginAlertBanner'>
            <ProductAlertBanner config={productAlertConfig.config} />
        </div>
        : null;

}

export default ProductAlert;