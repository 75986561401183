/**
 * @format
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Col, Row } from 'reactstrap';

import { LabeledFieldHorizontal } from '../common/standardComponents';

import { PhoneNumber } from '../common/phoneNumber';

import './patientDetailsStrip.css';

export default function PatientDetailsStrip(props) {
	const { config, patientDetails } = props;

	let memberIdLabel = config && config.memberId && config.memberId.fieldLabel ? config.memberId.fieldLabel : 'Patient MRN';

	let phone = '';
	if (patientDetails.homePhone) {
		phone = patientDetails.homePhone;
	} else if (patientDetails.mobilePhone) {
		phone = patientDetails.mobilePhone;
	} else if (patientDetails.alternatePhone) {
		phone = patientDetails.alternatePhone;
	}

	return (
		<div className="col-sm-12 strip-container">
			<Row>
				<Col className="text-center strip-col">
					<LabeledFieldHorizontal
						label="Patient Name"
						value={`${patientDetails.firstName} ${patientDetails.lastName}`}
					/>
				</Col>
				<Col className="text-center strip-col">
					<LabeledFieldHorizontal label={memberIdLabel} value={patientDetails.memberId} />
				</Col>
				<Col className="text-center strip-col">
					<LabeledFieldHorizontal
						label="Date Of Birth"
						value={moment(patientDetails.dateOfBirth).format('MM/DD/YYYY')}
					/>
				</Col>
				<Col className="text-center strip-col-end">
					<PhoneNumber label={'Phone'} value={phone} />
				</Col>
			</Row>
		</div>
	);
}

PatientDetailsStrip.propTypes = {
	patientDetails: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		dateOfBirth: PropTypes.string,
		homePhone: PropTypes.string,
		memberId: PropTypes.string,
		mobilePhone: PropTypes.string,
		alternatePhone: PropTypes.string,
	}),
	config: PropTypes.object,
};
