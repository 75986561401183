/**
 * @format
 */

import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { valueDisplay, valueDisplayBottomMargin } from '../../lib/standardLibrary';
import { PhoneNumber } from '../common/phoneNumber';

export class AppointmentDetailsPanel extends React.Component {
	render() {
		const {
			details,
			isReferringProvider,
			previousAppointmentDetails,
			providerConfig,
			providerFullNameProfessionalDesignation,
			showArrivalTime,
			showInsuranceOnBookAppointment,
			showMappedAppointmentType,
			showProviderResponseApptDetails,
			showReasonForVisitApptDetails,
		} = this.props;

		return (
			<Col lg="6">
				<div
					style={{
						background: '#FAFAFA',
						border: 'solid 1px #E0E0E0',
						padding: '10px',
						height: '100%',
						borderRadius: '5px',
					}}
				>
					<h5 className="fw-bold" style={{ marginBottom: '10px' }}>
						Appointment Information
					</h5>
					<Row>
						<Col lg="5">
							<div>
								<p className="fw-bold form-label">WHEN</p>
								<p style={valueDisplay}>
									{details.providerAppointmentDateTime
										? moment(details.providerAppointmentDateTime).format('dddd, MM/DD/YYYY')
										: '-'}
								</p>
								<p style={valueDisplay}>
									{details.patientAppointmentDateTime
										? moment(details.patientAppointmentDateTime).format('hh:mm A') +
										  ' ' +
										  (details.patientAppointmentTimeZone ? details.patientAppointmentTimeZone : '')
										: '-'}
									{' (patient time)'}
								</p>
								<p style={valueDisplay}>
									{details.providerAppointmentDateTime
										? moment(details.providerAppointmentDateTime).format('hh:mm A') +
										  ' ' +
										  (details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '')
										: '-'}
									{' (provider time)'}
								</p>
								{showArrivalTime && (
									<p style={valueDisplayBottomMargin}>
										{details.expectedArrivalTime
											? moment(details.expectedArrivalTime).format('hh:mm A') +
											  ' ' +
											  (details.providerAppointmentTimeZone ? details.providerAppointmentTimeZone : '')
											: '-'}
										{' (arrival time)'}
									</p>
								)}
							</div>
						</Col>
						<Col lg="7">
							<div>
								<p className="fw-bold form-label">WHERE</p>
								<p style={valueDisplay}>
									{details.providerFirstName || details.providerLastName
										? providerFullNameProfessionalDesignation
										: '-'}
								</p>
								<p style={valueDisplay}>{details.providerSpecialty ? details.providerSpecialty : '-'}</p>
								<p style={valueDisplay}>
									{details.providerSiteName ? details.providerSiteName : '-'}
									{details.providerSiteName && details.providerSystemName ? ', ' : ''}
									{details.providerSystemName ? details.providerSystemName : ''}
								</p>
								{!details.suppressPhoneNumber && providerConfig.phone && providerConfig.phone.isVisible && (
									<PhoneNumber
										label={providerConfig.phone.fieldLabel ? providerConfig.phone.fieldLabel : '(O)'}
										value={details.providerPhoneNumber?.trim()}
									/>
								)}
								{providerConfig.fax && providerConfig.fax.isVisible && (
									<PhoneNumber
										label={providerConfig.fax.fieldLabel ? providerConfig.fax.fieldLabel : '(F)'}
										value={details.providerFaxNumber?.trim()}
									/>
								)}
								{!details.suppressAddress && details.providerAddressLine1 ? (
									<div>
										<p style={valueDisplay}>{details.providerAddressLine1}</p>
										{details.providerAddressLine2 ? <p style={valueDisplay}>{details.providerAddressLine2}</p> : ''}
										<p>
											{details.providerCity}
											{', '}
											{details.providerState} {details.providerZip}
										</p>
									</div>
								) : (
									<div></div>
								)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg="5">
							<div>
								<p className="fw-bold form-label">TYPE</p>
								<p style={valueDisplayBottomMargin}>
									{details.appointmentTypeName ? details.appointmentTypeName : '-'}
								</p>
							</div>
							{showMappedAppointmentType && (
								<div className="mt-4">
									<p className="fw-bold form-label">MAPPED APPOINTMENT TYPE</p>
									<p style={valueDisplayBottomMargin}>{details.mappedAppointmentTypeName}</p>
								</div>
							)}
						</Col>
						<Col lg="7">
							{showInsuranceOnBookAppointment && details.payorType ? (
								<div>
									<p className="fw-bold form-label">INSURANCE</p>
									{details.payorType ? (
										<div>
											<p style={valueDisplay}>{details.payorType}</p>
										</div>
									) : (
										<div></div>
									)}
									{details.insuranceCarrierName ? (
										<div>
											<p style={valueDisplay}>{details.insuranceCarrierName}</p>
										</div>
									) : (
										<div></div>
									)}
								</div>
							) : (
								<div>
									<p>&nbsp;</p>
									<p style={valueDisplayBottomMargin}>&nbsp;</p>
								</div>
							)}
							{isReferringProvider && (
								<Row className="mb-2">
									<Col lg="4" className="mb-2">
										<div>
											<p className="fw-bold form-label">REFERRING PROVIDER</p>
											<div>{previousAppointmentDetails.managedReferringServiceDisplayName}</div>
										</div>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
					<Row>
						<Col lg="5">
							{showReasonForVisitApptDetails ? (
								<div>
									<p className="fw-bold form-label">REASON FOR VISIT</p>
									<p style={valueDisplayBottomMargin}>{details.reasonForVisit ? details.reasonForVisit : '-'}</p>
								</div>
							) : (
								<div>
									<p>&nbsp;</p>
									<p style={valueDisplayBottomMargin}>&nbsp;</p>
								</div>
							)}
						</Col>
						<Col lg="7">
							{showProviderResponseApptDetails ? (
								<div>
									<p className="fw-bold form-label">PROVIDER RESPONSE</p>
									<p style={valueDisplayBottomMargin}>{details.providerResponse ? details.providerResponse : '-'}</p>
								</div>
							) : (
								<div>
									<p>&nbsp;</p>
									<p style={valueDisplayBottomMargin}>&nbsp;</p>
								</div>
							)}
						</Col>
					</Row>
				</div>
			</Col>
		);
	}
}
