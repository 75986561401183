import React from 'react';

import {
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

import { SchedulableOrdersTable } from '../orderManagement';
import PatientDetailsStrip from '../patient/patientDetailsStrip';
import { SaveCancelActionBar } from '../common/standardComponents';

import './orderManagementModals.css'

export default function AddSchedulableOrdersModal(props) {
	const {
		isOpen,
		onSave,
		onCancel,
		ordersList,
		selectedOrdersList,
		patient,
		onOrderCheck,
		disableAddOrders,
	} = props;

	const isSaveDisabled = Array.isArray(selectedOrdersList) && selectedOrdersList.length === 0;
	return (
		<Modal className="add-order-modal-width" centered isOpen={isOpen} toggle={onCancel}>
			<ModalHeader toggle={onCancel}>
				<Row>
					<Col>
						<h4 align="center">Radiology Orders</h4>
					</Col>
				</Row>
			</ModalHeader>
			<ModalBody className="add-order-modal">
				<div>
					<Row>
						<Col>
							<PatientDetailsStrip patientDetails={patient} />
						</Col>
					</Row>
					<Row>
						<Col>
							<SchedulableOrdersTable ordersList={ordersList} selectedOrdersList={selectedOrdersList} onOrderCheck={onOrderCheck} disableAddOrders={disableAddOrders} />
						</Col>
					</Row>
				</div>
			</ModalBody>
			<ModalFooter>
				<Row className="justify-content-end">
					<Col xs="auto" className="add-order-SaveCancel-container">
						<SaveCancelActionBar style={{marginTop: "0px"}} isDisabled={isSaveDisabled} onSave={onSave} onCancel={onCancel} saveButtonText="Add Order(s)" />
					</Col>
				</Row>
			</ModalFooter>
		</Modal>
	)
}
