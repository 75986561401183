/**
 * @format
 */

import { v4 as uuidv4 } from 'uuid';
import { Button, Col, Container, Row } from 'reactstrap';
import { genderCodeToDisplayName } from '../../../lib/standardLibrary';
import { scrollToPageTop } from '../../../lib/misc';
import { calendarTypes } from '../../../constants/calendarTypes';
import { PhoneNumber } from '../../../components/common/phoneNumber';
import '../css/calendarDetails.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCalendarById,
    selectIsSearchingAnything,
    selectSelectedCalendarId,
    selectShowReturnToQuestionsButton,
    selectSuppressCalendarTelephoneById,
    setSelectedCalendarByIdThunk
} from '../availabilitySlice';
import { decisionSupport as routeToDecisionSupport } from '../../../routes';

export default function SelectedCalendarDetails() {
    const calendarId = useSelector(selectSelectedCalendarId);
    const calendar = useSelector(state => selectCalendarById(state, calendarId));
    const suppressPhoneNumber = useSelector(state => selectSuppressCalendarTelephoneById(state, calendarId));
    const isSearchingAnything = useSelector(selectIsSearchingAnything);
    const isReserving = useSelector(state => state.appointment.isLoading.reserveAppointment);

    const showReturnToQuestionsButton = useSelector(selectShowReturnToQuestionsButton);
    const showNpi = useSelector(state => state.config.scheduling.showNpiOnProviderDetails);

    const dispatch = useDispatch();

    const handleGoBackToGuidedResponse = () => {
        dispatch(setSelectedCalendarByIdThunk(null));
        dispatch(routeToDecisionSupport(true));
    };

    const resolveBioAsHtml = () => {
        return { __html: calendar?.bio && calendar?.bio.trim() !== '' ? calendar?.bio : '-' };
    };

    const resolveAdditionalServiceInformationAsHtml = () => {
        return {
            __html:
                calendar?.informationForPatient && calendar?.informationForPatient.trim() !== ''
                    ? calendar?.informationForPatient
                    : '-',
        };
    };

    const formatPhone = () => {
        const cleanPhone = calendar?.telephone.replace(/\D/g, '');
        let match = cleanPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    };

    let showAddress = !calendar?.suppressAddress;
    let showPhone = !suppressPhoneNumber;

    let displayAddress = `${calendar?.address.addressLine1}${calendar?.address.addressLine2 && calendar?.address.addressLine2.trim() !== '' ? ` ${calendar?.address.addressLine2},` : ','
        } ${calendar?.address.cityName}, ${calendar?.address.stateCode} ${calendar?.address.zipCode}`;

    let phone =
        calendar?.telephone && calendar?.telephone.trim() !== '' ?
            <PhoneNumber label={'Phone:'} value={formatPhone()} />
            : ''

    let backButtonText = 'Return to Search Results';

    let isEquipment = calendar?.serviceProviderTypeName === calendarTypes.equipment;

    if (isSearchingAnything || isReserving) {
        scrollToPageTop();
    }

    return (
        <div className="content">
            <div>
                <Container className={`${isEquipment ? 'equipment' : 'provider'}-title-container`}>
                    <Row>
                        <Col lg="12">
                            <div>
                                <h4 className={`${isEquipment ? 'equipment-name' : 'provider-fullname'}-designation`}>
                                    {calendar?.displayName}
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="8" className="my-auto">
                            <span className="align-bottom">
                                <h5 className="provider-address-phone">
                                    {showAddress && displayAddress}
                                    <span style={{ paddingLeft: '20px' }}>{showPhone && phone}</span>
                                </h5>
                            </span>
                        </Col>
                        <Col lg="4" className="back-button-container">
                            {showReturnToQuestionsButton && (
                                <Button color="secondary" className="back-button mr-2" onClick={handleGoBackToGuidedResponse}>
                                    Return to Questions
                                </Button>
                            )}
                            <Button color="primary" className="back-button" onClick={() => dispatch(setSelectedCalendarByIdThunk(null))}>
                                {backButtonText}
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <Container className="provider-details-container">
                    <Row>
                        <Col lg="4">
                            <img
                                className="provider-image"
                                src={calendar?.imageFileName || '/resources/generic-provider-photo.jpg'}
                                alt="Provider"
                            />
                        </Col>
                        <Col lg="2">
                            {isEquipment && (
                                <>
                                    <div>
                                        <p className="fw-bold form-label">Serial Number</p>
                                        <p>{calendar?.serialNumber || '-'}</p>
                                    </div>
                                    <div>
                                        <p className="fw-bold form-label">Asset Tag</p>
                                        <p>{calendar?.assetTag || '-'}</p>
                                    </div>
                                </>
                            )}
                            {!isEquipment && (
                                <>
                                    {showNpi && (
                                        <div>
                                            <p className="fw-bold form-label">Provider NPI</p>
                                            <p>{calendar?.npi || '-'}</p>
                                        </div>
                                    )}
                                    <div>
                                        <p className="fw-bold form-label">Gender</p>
                                        <p>{genderCodeToDisplayName(calendar?.genderCode)}</p>
                                    </div>
                                    <div>
                                        <p className="fw-bold form-label">Specialty</p>
                                        <p>{calendar?.specialtyName || '-'}</p>
                                    </div>
                                    <div>
                                        <p className="fw-bold form-label">Language</p>
                                        {calendar?.languages?.length > 0 ? (
                                            <ul className="provider-languages">
                                                {calendar?.languages.map((lan, i) => {
                                                    return <li key={uuidv4()}>{lan.name}</li>;
                                                })}
                                            </ul>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </>
                            )}
                        </Col>
                        <Col lg="6">
                            {/* this was isRequestAppointment */}
                            {false && (
                                <div>
                                    <p className="fw-bold form-label">{'Site'}</p>
                                    <p>{calendar?.locationName || '-'}</p>
                                </div>
                            )}
                            <div>
                                <p className="fw-bold form-label">{isEquipment ? 'Description' : 'Biography'}</p>
                                <p className="bio-container" dangerouslySetInnerHTML={resolveBioAsHtml()} />
                            </div>
                            <div>
                                <p className="fw-bold form-label">Additional Service Information</p>
                                <p
                                    className="additionalServiceInfo-container"
                                    dangerouslySetInnerHTML={resolveAdditionalServiceInformationAsHtml()}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
