import { Col, Row, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import '../css/availabilityResultPagination.css';
import {
    changePageThunk,
    selectNumberOfPages,
    selectPageNumber
} from '../availabilitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { scrollToPageTop } from '../../../lib/misc';

export default function PaginationComp() {
    const pageNumber = useSelector(selectPageNumber);
    const numberOfPages = useSelector(selectNumberOfPages);
    const dispatch = useDispatch();

    const MAX_NUM_PAGING_BUTTONS_SHOWN = 7; // TODO
    const numPagesToShowEllipse = Math.ceil(MAX_NUM_PAGING_BUTTONS_SHOWN / 2); // TODO naming

    const pageButtons = [];

    const showAnyEllipse = numberOfPages > MAX_NUM_PAGING_BUTTONS_SHOWN;

    if (!showAnyEllipse) {
        for (let i = 1; i <= numberOfPages; i++) {
            pageButtons.push(
                <PageLink
                    key={i}
                    isSelected={i === pageNumber}
                    handler={() => { dispatch(changePageThunk(i)); scrollToPageTop(); }}
                    pageNumber={i}
                />
            );
        }
    } else {
        const showBeginningEllipse = showAnyEllipse && pageNumber > numPagesToShowEllipse; // TODO
        const showEndingEllipse = showAnyEllipse && pageNumber <= numberOfPages - numPagesToShowEllipse;

        let numMidPages = MAX_NUM_PAGING_BUTTONS_SHOWN - 2;

        if (showBeginningEllipse) {
            numMidPages -= 1;
        }

        if (showEndingEllipse) {
            numMidPages -= 1;
        }

        let startMidPageNumber = 2;

        if (!showEndingEllipse) {
            startMidPageNumber = numberOfPages - numMidPages;
        }

        if (showBeginningEllipse && showEndingEllipse) {
            startMidPageNumber = pageNumber - Math.floor(numMidPages / 2);
        }

        const innerNumberLinks = [];

        for (let i = startMidPageNumber; i < startMidPageNumber + numMidPages; i++) {
            innerNumberLinks.push(
                <PageLink
                    key={i}
                    isSelected={i === pageNumber}
                    handler={() => { dispatch(changePageThunk(i)); scrollToPageTop(); }}
                    pageNumber={i}
                />
            );
        };

        if (numberOfPages > 0)
            pageButtons.push(
                <PageLink
                    key={1}
                    isSelected={pageNumber === 1}
                    handler={() => { dispatch(changePageThunk(1)); scrollToPageTop() }}
                    pageNumber={1}
                />
            );

        if (showBeginningEllipse)
            pageButtons.push(<Ellipse key={"beginningEllipse"} />);

        pageButtons.push(...innerNumberLinks);

        if (showEndingEllipse) {
            pageButtons.push(<Ellipse key={"endingEllipse"} />);
        }

        if (numberOfPages > 1) {
            pageButtons.push(
                <PageLink
                    key={numberOfPages}
                    isSelected={pageNumber === numberOfPages}
                    handler={() => { dispatch(changePageThunk(numberOfPages)); scrollToPageTop(); }}
                    pageNumber={numberOfPages}
                />
            );
        }
    }

    return (

        <Row className="d-flex justify-content-center pagination-row">
            <Col sm="8" className="d-flex justify-content-center">
                <Pagination size="lg" aria-label="Page navigation example">
                    {numberOfPages > 0 &&
                        <>
                            <BackArrow
                                disabled={pageNumber === 1}
                                handler={() => { dispatch(changePageThunk(pageNumber - 1)); scrollToPageTop(); }}
                            />
                            {pageButtons}
                            <ForwardArrow
                                disabled={pageNumber === numberOfPages}
                                handler={() => { dispatch(changePageThunk(pageNumber + 1)); scrollToPageTop(); }}
                            />
                        </>
                    }
                </Pagination>
            </Col>
        </Row>
    )
}


const BackArrow = (props) => {
    return (
        <PaginationItem disabled={props.disabled}>
            <PaginationLink onClick={props.handler}>
                <span className="pagination-arrow">
                    Prev
                </span>
            </PaginationLink>
        </PaginationItem>
    )
}

const ForwardArrow = (props) => {
    return (
        <PaginationItem disabled={props.disabled}>
            <PaginationLink onClick={props.handler}>
                <span className="pagination-arrow">
                    Next
                </span>
            </PaginationLink>
        </PaginationItem>
    )
}

const PageLink = (props) => {
    const { isSelected, handler, pageNumber } = props;
    return (
        <PaginationItem disabled={isSelected}>
            <PaginationLink className={isSelected ? "pagination-link-active" : ""} onClick={handler}>
                <span className="pagination-number">{pageNumber}</span>
            </PaginationLink>
        </PaginationItem>
    )
}

const Ellipse = (props) => {
    return (
        <PaginationItem disabled>
            <PaginationLink>
                <span className="pagination-arrow">
                    <FontAwesomeIcon icon={faEllipsisH} size="sm" style={{ marginTop: "6px" }} />
                </span>
            </PaginationLink>
        </PaginationItem>
    )
}
