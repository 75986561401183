import * as types from '../actions/actionTypes'

const initialState = {
	isSearching: false,
	patientList: {
		summaryInfo: null,
		items: [],
	},
	isCreatingPatient: false,
	selectedPatient: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {

		case types.SEARCH_PATIENT:
			return {
				...state,
				isSearching: true,
				selectedPatient: null,
			};

		case types.SEARCH_PATIENT_SUCCESS:
			return {
				...state,
				isSearching: false,
				patientList: {
					summaryInfo: action.payload.data.summaryInfo,
					items: action.payload.data.patients
				}
			};

		case types.SEARCH_PATIENT_ERROR:
			return {
				...state,
				isSearching: false
			};

		case types.ENSURE_PATIENT:
			return {
				...state,
				isCreatingPatient: true,
				selectedPatient: null,
			};
			
		case types.ENSURE_PATIENT_SUCCESS:
			return {
				...state,
				isCreatingPatient: false,
				selectedPatient: action.payload.data.patientDetails,
			};

		case types.ENSURE_PATIENT_ERROR:
			return {
				...state,
				isCreatingPatient: false,
			};

		default:
			return state;
	}
}
