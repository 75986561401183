/**
 * @format
 */

import React, { useState } from 'react';
import moment from 'moment';
import { ButtonToolbar, Col, Form, Row } from 'reactstrap';
import {
	CancelButton,
	DateInputWithLabel,
	DropdownSelectWithLabel,
	EmailInputWithLabel,
	PhoneInputWithLabel,
	SaveButton,
	TextInputWithLabel,
	TextAreaWithLabel,
	ZipInputWithLabel,
	AddressSelectWithLabel,
} from '../common/standardComponents';
import { validateDateOfBirth } from '../../lib/validation';
import './patientEditForm.css';

const emptyAddressOption = {
	label: "Select...", value: {}
};

export default function PatientEditForm(props) {
	const [isManualAddressEntry, setIsManualAddressEntry] = useState(!props.config.patient.showAddressSearch); 
	const {
		config,
		genderOptions,
		shouldValidate,
		patientDetails,
		onPatientChange,
		phoneForceInvalid,
		zipCodeForceInvalid,
		stateList,
		handleAddressChange,
		loadAddressOptions,
		selectedAddressOption,
		isAddressLoading,
	} = props;

	let isMinor = moment().diff(patientDetails.dateOfBirth, 'years') < config.patient.guardian.adultAge ? true : false;

	let patientConfig = config.patient;
	let genderLabel =
		patientConfig.gender && patientConfig.gender.fieldLabel ? patientConfig.gender.fieldLabel : 'Gender';
	let memberIdLabel =
		patientConfig.memberId && patientConfig.memberId.fieldLabel ? patientConfig.memberId.fieldLabel : 'Member Id';
	let firstNameLabel =
		patientConfig.firstName && patientConfig.firstName.fieldLabel ? patientConfig.firstName.fieldLabel : 'First Name';
	let middleNameLabel =
		patientConfig.middleName && patientConfig.middleName.fieldLabel
			? patientConfig.middleName.fieldLabel
			: 'Middle Name';
	let lastNameLabel =
		patientConfig.lastName && patientConfig.lastName.fieldLabel ? patientConfig.lastName.fieldLabel : 'Last Name';
	let groupNumberLabel =
		patientConfig.groupNumber && patientConfig.groupNumber.fieldLabel
			? patientConfig.groupNumber.fieldLabel
			: 'Group Number';
	let addressLine1Label =
		patientConfig.addressLine1 && patientConfig.addressLine1.fieldLabel
			? patientConfig.addressLine1.fieldLabel
			: 'Address (Line 1)';
	let addressLine2Label =
		patientConfig.addressLine2 && patientConfig.addressLine2.fieldLabel
			? patientConfig.addressLine2.fieldLabel
			: 'Address (Line 2)';
	let cityLabel = patientConfig.city && patientConfig.city.fieldLabel ? patientConfig.city.fieldLabel : 'City';
	let stateLabel = patientConfig.state && patientConfig.state.fieldLabel ? patientConfig.state.fieldLabel : 'State';
	let zipCodeLabel =
		patientConfig.zipCode && patientConfig.zipCode.fieldLabel ? patientConfig.zipCode.fieldLabel : 'Zip Code';
	let dateOfBirthLabel =
		patientConfig.dateOfBirth && patientConfig.dateOfBirth.fieldLabel
			? patientConfig.dateOfBirth.fieldLabel
			: 'Date of Birth';
	let homePhoneLabel =
		patientConfig.homePhone && patientConfig.homePhone.fieldLabel ? patientConfig.homePhone.fieldLabel : 'Home Phone';
	let mobilePhoneLabel =
		patientConfig.mobilePhone && patientConfig.mobilePhone.fieldLabel
			? patientConfig.mobilePhone.fieldLabel
			: 'Mobile Phone';
	let alternatePhoneLabel =
		patientConfig.alternatePhone && patientConfig.alternatePhone.fieldLabel
			? patientConfig.alternatePhone.fieldLabel
			: 'Alternate Phone';
	let emailLabel =
		patientConfig.email && patientConfig.email.fieldLabel ? patientConfig.email.fieldLabel : 'Email Address';
	let parentOrGuardianNameLabel =
		patientConfig.parentOrGuardianName && patientConfig.parentOrGuardianName.fieldLabel
			? patientConfig.parentOrGuardianName.fieldLabel
			: 'Guardian Name';
	let patientNotesLabel =
		patientConfig.notes && patientConfig.notes.fieldLabel ? patientConfig.notes.fieldLabel : 'Patient Notes';

	let gendersList = [{ id: '', name: 'Select', key: -1 }];

	genderOptions.forEach((s, i) => {
		gendersList.push({ id: s.id, name: s.name, key: i });
	});

	const handleIsManualAddressEntryChange = (e) => {
		handleAddressChange(emptyAddressOption);
		setIsManualAddressEntry(e);
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Delete') {
			e.preventDefault();
			handleAddressChange(emptyAddressOption);
		}
	}

	return (
		<Form>
			<div className="form-area">
				{((patientConfig.memberId && patientConfig.memberId.isVisible) ||
					(patientConfig.groupNumber && patientConfig.groupNumber.isVisible)) && (
					<Row>
						<Col lg="4">
							{patientConfig.memberId && patientConfig.memberId.isVisible && (
								<TextInputWithLabel
									id="memberId"
									label={memberIdLabel}
									value={patientDetails.memberId}
									maxLength={patientConfig.memberId.maxLength}
									isRequired={patientConfig.memberId.isRequired}
									disabled={!patientConfig.memberId.isEditable}
									shouldValidate={shouldValidate}
									onChange={onPatientChange}
								/>
							)}
						</Col>
						<Col lg="4">
							{patientConfig.groupNumber && patientConfig.groupNumber.isVisible && (
								<TextInputWithLabel
									id="groupNumber"
									label={groupNumberLabel}
									value={patientDetails.groupNumber}
									maxLength={patientConfig.groupNumber.maxLength}
									isRequired={patientConfig.groupNumber.isRequired}
									disabled={!patientConfig.groupNumber.isEditable}
									shouldValidate={shouldValidate}
									onChange={onPatientChange}
								/>
							)}
						</Col>
						<Col lg="4">
							<div>&nbsp;</div>
						</Col>
					</Row>
				)}
				<Row>
					{patientConfig.firstName && patientConfig.firstName.isVisible && (
						<Col lg="4">
							<TextInputWithLabel
								id="firstName"
								label={firstNameLabel}
								value={patientDetails.firstName}
								maxLength={50}
								isRequired={patientConfig.firstName.isRequired}
								disabled={!patientConfig.firstName.isEditable}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					{patientConfig.middleName && patientConfig.middleName.isVisible && (
						<Col lg="4">
							<TextInputWithLabel
								id="middleName"
								label={middleNameLabel}
								value={patientDetails.middleName}
								maxLength={50}
								isRequired={patientConfig.middleName.isRequired}
								disabled={!patientConfig.middleName.isEditable}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					{patientConfig.lastName && patientConfig.lastName.isVisible && (
						<Col lg="4">
							<TextInputWithLabel
								id="lastName"
								label={lastNameLabel}
								value={patientDetails.lastName}
								maxLength={50}
								isRequired={patientConfig.lastName.isRequired}
								disabled={!patientConfig.lastName.isEditable}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
				</Row>
				<Row>
					{patientConfig.notes && patientConfig.notes.isVisible && (
						<Col lg="12">
							<TextAreaWithLabel
								id="notes"
								label={patientNotesLabel}
								value={patientDetails.notes}
								isRequired={patientConfig.notes.isRequired}
								disabled={!patientConfig.notes.isEditable}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
				</Row>
				<Row>
					{patientConfig.dateOfBirth && patientConfig.dateOfBirth.isVisible && (
						<Col lg="4">
							<DateInputWithLabel
								id="dateOfBirth"
								label={dateOfBirthLabel}
								value={patientDetails.dateOfBirth}
								isRequired={patientConfig.dateOfBirth.isRequired}
								validator={validateDateOfBirth}
								disabled={!patientConfig.dateOfBirth.isEditable}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					<Col lg="4">
						{patientConfig.parentOrGuardianName &&
							patientConfig.parentOrGuardianName.isVisible &&
							isMinor && ( //{showGuardian &&
								<TextInputWithLabel
									id="guardianName"
									label={parentOrGuardianNameLabel}
									value={patientDetails.guardianName}
									maxLength={50}
									isRequired={patientConfig.parentOrGuardianName.isRequired}
									disabled={!patientConfig.parentOrGuardianName.isEditable}
									shouldValidate={shouldValidate}
									onChange={onPatientChange}
								/>
							)}
					</Col>
					<Col lg="4">
						<div>&nbsp;</div>
					</Col>
				</Row>
				<Row>
					{patientConfig.gender && patientConfig.gender.isVisible && (
						<Col lg="4">
							<DropdownSelectWithLabel
								id="gender"
								label={genderLabel}
								value={patientDetails.gender}
								isRequired={patientConfig.gender.isRequired}
								disabled={!patientConfig.gender.isEditable}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
								optionList={gendersList}
							/>
						</Col>
					)}
				</Row>
				<Row>
					{patientConfig.homePhone && patientConfig.homePhone.isVisible && (
						<Col lg="4">
							<PhoneInputWithLabel
								id="homePhone"
								label={homePhoneLabel}
								forcedInvalidMessage="Please provide a valid Home or Mobile Phone"
								subText={patientConfig.homePhone.isRequired ? 'Home or Mobile Phone required' : ''}
								value={patientDetails.homePhone}
								isRequired={patientConfig.homePhone.isRequired}
								disabled={!patientConfig.homePhone.isEditable}
								shouldValidate={shouldValidate}
								forceInvalid={phoneForceInvalid}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					{patientConfig.mobilePhone && patientConfig.mobilePhone.isVisible && (
						<Col lg="4">
							<PhoneInputWithLabel
								id="mobilePhone"
								label={mobilePhoneLabel}
								forcedInvalidMessage=" "
								value={patientDetails.mobilePhone}
								shouldValidate={shouldValidate}
								isRequired={patientConfig.mobilePhone.isRequired}
								forceInvalid={phoneForceInvalid}
								disabled={!patientConfig.mobilePhone.isEditable}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					{patientConfig.alternatePhone && patientConfig.alternatePhone.isVisible && (
						<Col lg="4">
							<PhoneInputWithLabel
								id="alternatePhone"
								label={alternatePhoneLabel}
								forcedInvalidMessage=" "
								value={patientDetails.alternatePhone}
								shouldValidate={shouldValidate}
								isRequired={patientConfig.alternatePhone.isRequired}
								disabled={!patientConfig.alternatePhone.isEditable}
								onChange={onPatientChange}
							/>
						</Col>
					)}
				</Row>
				<Row>
					{patientConfig.showAddressSearch &&
						<>
							<AddressSelectWithLabel
								id="address-select"
								className="address-search-select"
								label={isAddressLoading ? "Loading..." : "Address Search"}
								cacheOptions
								defaultOptions={[emptyAddressOption]}
								loadOptions={loadAddressOptions}
								onChange={handleAddressChange}
								value={selectedAddressOption}
								isDisabled={isManualAddressEntry || isAddressLoading}
								isAddressLoading={isAddressLoading}
								onKeyDown={handleKeyDown}
							/>
							<AddressCheckbox
								isManualEntry={isManualAddressEntry}
								onChange={handleIsManualAddressEntryChange}
								disabled={isAddressLoading}
							/>
						</>
					}
				</Row>
				<Row>
					{patientConfig.addressLine1 && patientConfig.addressLine1.isVisible && (
						<Col lg="4">
							<TextInputWithLabel
								id="addressLine1"
								label={addressLine1Label}
								value={patientDetails.addressLine1}
								isRequired={patientConfig.addressLine1.isRequired}
								disabled={!patientConfig.addressLine1.isEditable || !isManualAddressEntry}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					{patientConfig.addressLine2 && patientConfig.addressLine2.isVisible && (
						<Col lg="4">
							<TextInputWithLabel
								id="addressLine2"
								label={addressLine2Label}
								value={patientDetails.addressLine2 ? patientDetails.addressLine2 : ''}
								isRequired={patientConfig.addressLine2.isRequired}
								disabled={!patientConfig.addressLine2.isEditable || !isManualAddressEntry}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
				</Row>
				<Row>
					{patientConfig.city && patientConfig.city.isVisible && (
						<Col lg="4">
							<TextInputWithLabel
								id="cityName"
								label={cityLabel}
								value={patientDetails.cityName}
								isRequired={patientConfig.city.isRequired}
								disabled={!patientConfig.city.isEditable || !isManualAddressEntry}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					{patientConfig.state && patientConfig.state.isVisible && (
						<Col lg="4">
							<DropdownSelectWithLabel
								optionList={stateList}
								id="stateId"
								label={stateLabel}
								value={patientDetails.stateId}
								isRequired={patientConfig.state.isRequired}
								disabled={!patientConfig.state.isEditable || !isManualAddressEntry}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
					{patientConfig.zipCode && patientConfig.zipCode.isVisible && (
						<Col lg="4">
							<ZipInputWithLabel
								id="zipCode"
								label={zipCodeLabel}
								value={patientDetails.zipCode}
								isRequired={patientConfig.zipCode.isRequired}
								forceInvalid={zipCodeForceInvalid}
								disabled={!patientConfig.zipCode.isEditable || !isManualAddressEntry}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
				</Row>
				<Row>
					{patientConfig.email && patientConfig.email.isVisible && (
						<Col lg="4">
							<EmailInputWithLabel
								id="email"
								label={emailLabel}
								value={patientDetails.email}
								isRequired={patientConfig.email.isRequired}
								disabled={!patientConfig.email.isEditable}
								shouldValidate={shouldValidate}
								onChange={onPatientChange}
							/>
						</Col>
					)}
				</Row>
			</div>
			<Row className="action-buttons">
				<ButtonToolbar>
					<SaveButton onClick={props.onSave} isBusy={props.isSaving} />
					<CancelButton onClick={props.onCancel} />
				</ButtonToolbar>
			</Row>
		</Form>
	);
}

const AddressCheckbox = ({ onChange, isManualEntry, disabled }) => {
	return (
		<div className="address-search-checkbox">
			<label>Enter Address Manually?</label>
			<input
				id="address-checkbox"
				type="checkbox"
				onChange={e => onChange(e.target.checked)}
				value={isManualEntry}
				disabled={disabled}
			/>
		</div>
	)
}
