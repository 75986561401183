import React from 'react';
import { Alert } from 'reactstrap';
import { Grid, GridItem } from '../guidedResponse/layouts'; // TODO: Replace with a common grid
import { ValidationFailureProblemDetails, ValidationFailureInfo, ErrorAlias } from 'types';

interface ErrorSummaryProps {
    error: ErrorAlias;
}

export const ErrorSummary: React.FC<ErrorSummaryProps> = ({ error }) => {
    let validationFailures: ValidationFailureInfo[] = [];

    if (typeof error === 'string') {
        const errorMessage = error as string;
        const validationFailure: ValidationFailureInfo = {
            validationMessage: errorMessage,
        };
        validationFailures.push(validationFailure);
    } else {
        const problemDetails = error as ValidationFailureProblemDetails;
        validationFailures = problemDetails.validationFailures || [];
    }

    const hasErrors = validationFailures.length > 0;

    return (
        <Alert hidden={!hasErrors} color={hasErrors ? 'danger' : ''}>
            {hasErrors &&
                validationFailures.map((validationFailure: ValidationFailureInfo) => (
                    <Grid key={validationFailure.propertyName}>
                        <GridItem span="fill">
                            <div>
                                <div>{validationFailure.validationMessage}</div>
                            </div>
                        </GridItem>
                    </Grid>
                ))}
        </Alert>
    );
};

export default ErrorSummary;
