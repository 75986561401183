import React from 'react';
import { connect } from 'react-redux';
import * as routes from '../../routes';
import ActiveErrors from '../../components/common/activeErrors';
import ErrorBoundary from '../../components/common/errorBoundary';
import { validateDateOfBirth, validateGuid, validatePhoneNumber } from '../../lib/validation';
import {
	createNewPatient,
	ensurePatient,
	searchPatient,
	setActivePatient,
	startBookingProcess,
	clearAgentInstructions,
	clearBookingContext,
} from '../../actions/rd2RefactorActions';
import PatientSearchForm from '../../components/patient/patientSearchForm';
import PatientSearchResultList from '../../components/patient/patientSearchResultList';
import './patientSearchView.css';

export class PatientSearchView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			isLoadingPatientDetails: false,
			touchedInputs: {},
			firstName: '',
			lastName: '',
			dateOfBirth: '',
			phoneNumber: '',
			memberId: '',
			identityValue: '',
			disableRow: false,
			forceValidation: false,
			phoneForceInvalid: false,
			isHandleBookClickDeferred: false,
			isHandleRowClickDeferred: false,
		};
	}

	onChange = (e) => {
		const touchedInputs = { ...this.state.touchedInputs };
		touchedInputs[e.target.name] = true;
		this.setState({
			touchedInputs,
			[e.target.name]: e.target.value,
		});
		if (e.target.name === 'phoneNumber') {
			this.validatePhoneInput(e.target.value);
		}
	};

	componentDidMount() {
		this.clearCache();
	}

	clearCache = () => {
		this.props.dispatch(clearBookingContext());
		this.props.dispatch(clearAgentInstructions());
	};

	handleBookClick = (patient) => {
		this.setState({ disableRow: true }, () => {
			if (!validateGuid(patient.referenceId)) {
				this.setState({
					isHandleBookClickDeferred: true,
				});
				this.props.dispatch(ensurePatient(patient)).then((response) => {
					if (response.error) {
						this.setState({ disableRow: false });
					}
				});
			} else {
				this.setState({
					isHandleBookClickDeferred: false,
				});
				this.props
					.dispatch(setActivePatient(patient.referenceId))
					.then(() => {
						this.props.dispatch(
							startBookingProcess(
								this.props.activeCareOrder,
								this.props.decisionSupportOutput,
								this.props.availabilitySearchConfig,
								this.props.activePatient.details,
								this.props.decisionSupport.useDecisionSupport,
							),
						);
					})
					.catch(() => {
						this.setState({ disableRow: false });
					});
			}
		});
	};

	handleCreateClick = (e) => {
		e.preventDefault();
		this.props.dispatch(
			createNewPatient(
				this.state.firstName,
				this.state.lastName,
				this.state.dateOfBirth,
				this.state.memberId && !this.props.patientList.items.length ? this.state.memberId : '',
			),
		);
	};

	handleRowClick = (patient) => {
		this.setState({ disableRow: true, isLoadingPatientDetails: true }, () => {
			if (!validateGuid(patient.referenceId)) {
				this.setState({
					isHandleRowClickDeferred: true,
				});
				this.props.dispatch(ensurePatient(patient)).then((response) => {
					if (response.error) {
						this.setState({ disableRow: false, isLoadingPatientDetails: false });
					}
				});
			} else {
				this.setState({
					isHandleRowClickDeferred: false,
				});
				this.props
					.dispatch(setActivePatient(patient.referenceId))
					.then(() => {
						this.props.dispatch(routes.patientDetails(patient.referenceId));
					})
					.catch(() => {
						this.setState({ disableRow: false, isLoadingPatientDetails: false });
					});
			}
		});
	};

	clearSearchCriteria = () => {
		this.setState({
			firstName: '',
			lastName: '',
			dateOfBirth: '',
			memberId: '',
			identityValue: '',
			forceValidation: false,
			touchedInputs: {},
			phoneNumber: '',
			phoneForceInvalid: false,
		});
	};

	patientSearch = (e) => {
		e.preventDefault();

		this.setState({ forceValidation: true });

		const patientSearchCriteria = {
			firstName: this.state.firstName.trim(),
			lastName: this.state.lastName.trim(),
			dateOfBirth: this.state.dateOfBirth,
			memberId: this.state.memberId.trim(),
			identityValue: this.state.identityValue.trim(),
			excludeWithoutMemberId: this.props.patientConfig.excludeWithoutMemberId,
			phoneNumber: this.state.phoneNumber,
		};

		if (this.validateSearchCriteria(patientSearchCriteria)) {
			this.props.dispatch(searchPatient(patientSearchCriteria));
		}

		// TODO: handle error response
	};

	shouldValidate = (name) => {
		return this.state.forceValidation || this.state.touchedInputs[name];
	};

	validatePhoneInput = (value) => {
		let isValid = !value || validatePhoneNumber(value);
		this.setState({ phoneForceInvalid: !isValid });
	};

	validateSearchCriteria = (searchCriteria) => {
		// if any of the demographics fields are set, we are "using" that panel
		const isUsingDemographics = searchCriteria.firstName || searchCriteria.lastName || searchCriteria.dateOfBirth;
		const isUsingPhone = searchCriteria.phoneNumber;
		let isPhoneValid = !isUsingPhone || (isUsingPhone && validatePhoneNumber(searchCriteria.phoneNumber));

		if (!isPhoneValid) {
			this.setState({ phoneForceInvalid: !isPhoneValid });
		}

		// if using the demographics panel, verify that field requirements are met
		let isDemographicsValid = true;
		if (isUsingDemographics) {
			if (this.props.patientSearchFieldConfig.firstName.isRequired && !searchCriteria.firstName) {
				isDemographicsValid = false;
			}
			if (this.props.patientSearchFieldConfig.lastName.isRequired && !searchCriteria.lastName) {
				isDemographicsValid = false;
			}
			if (
				this.props.patientSearchFieldConfig.dateOfBirth.isRequired &&
				(!searchCriteria.dateOfBirth || !validateDateOfBirth(searchCriteria.dateOfBirth))
			) {
				isDemographicsValid = false;
			}
		}
		if (!isPhoneValid) {
			isDemographicsValid = false;
		}

		// must be using 1 of the 3 panels, and not have a demographics requiment failure (if applicable)
		return (
			(searchCriteria.memberId || searchCriteria.identityValue || isUsingDemographics || searchCriteria.phoneNumber) &&
			isDemographicsValid
		);
	};
	render() {
		//TODO: instead of checking during render we should refactor this to use a promise more correctly in (intial) call to handleBookClick and handleRowClick
		if (this.state.isHandleBookClickDeferred && this.props.selectedPatient) {
			this.handleBookClick(this.props.selectedPatient);
		} else if (this.state.isHandleRowClickDeferred && this.props.selectedPatient) {
			this.handleRowClick(this.props.selectedPatient);
		}

		return (
			<div>
				<ActiveErrors />
				<ErrorBoundary childName="Patient Search">
					<PatientSearchForm
						clearSearchCriteria={this.clearSearchCriteria}
						dateOfBirth={this.state.dateOfBirth}
						enablePatientSearchByDemographics={this.props.patientConfig.enablePatientSearchByDemographics}
						enablePatientSearchByIdentity={this.props.patientConfig.enablePatientSearchByIdentity}
						enablePatientSearchByMemberId={this.props.patientConfig.enablePatientSearchByMemberId}
						enablePatientSearchByPhone={this.props.patientConfig.enablePatientSearchByPhone}
						error={this.state.error}
						fieldConfig={this.props.patientSearchFieldConfig}
						firstName={this.state.firstName}
						identityValue={this.state.identityValue}
						isSearching={this.props.isSearching}
						lastName={this.state.lastName}
						memberId={this.state.memberId}
						memberIdFieldLabel={this.props.patientConfig.memberId.fieldLabel}
						memberIdMaxLength={this.props.patientConfig.memberId.maxLength}
						onChange={this.onChange}
						onSubmit={this.patientSearch}
						patientIdentityFieldLabelOverride={this.props.patientConfig.patientIdentityFieldLabelOverride}
						phoneForceInvalid={this.state.phoneForceInvalid}
						phoneNumber={this.state.phoneNumber}
						shouldValidate={this.shouldValidate}
					/>
				</ErrorBoundary>
				{this.props.patientList && (
					<div className="patientSearchResultList">
						<ErrorBoundary childName="Patient Search Results">
							<PatientSearchResultList
								dateOfBirth={this.state.dateOfBirth}
								disableRow={this.state.disableRow}
								enablePatientAdd={this.props.patientConfig.isAddEnabled}
								firstName={this.state.firstName}
								genderFieldLabel={this.props.genderFieldLabel}
								lastName={this.state.lastName}
								memberId={this.state.memberId}
								memberIdFieldLabel={this.props.patientConfig.memberId.fieldLabel}
								onBookClick={this.handleBookClick}
								onCreateClick={this.handleCreateClick}
								onRowClick={this.handleRowClick}
								patients={this.props.patientList}
								config={this.props.patientConfig}
							/>
						</ErrorBoundary>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.activePatient,
		agentInstructions: state.appointment.agentInstructions,
		availabilitySearchConfig: state.config.availabilitySearch,
		decisionSupport: state.config.decisionSupport,
		decisionSupportOutput: state.decisionSupport,
		patientConfig: state.config.patient,
		idSystem: state.auth.referralSystemId,
		isMemberIdActive: state.config.system.isMemberIdActive,
		isMemberIdRequired: state.config.system.isMemberIdRequired,
		isSearching: state.patientSearch.isSearching,
		memberIdFieldLabel: state.config.system.memberIdFieldLabel,
		memberIdMaxLength: state.config.system.memberIdMaxLength,
		patientList: state.patientSearch.patientList,
		patientSearchFieldConfig: state.config.patientSearch,
		previousAppointmentReferenceId: state.appointment.cancelRescheduleInfo.previousAppointmentReferenceId,
		selectedPatient: state.patientSearch.selectedPatient,
	};
}

export default connect(mapStateToProps)(PatientSearchView);
