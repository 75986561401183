/**
 * @format
 */

import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Alert } from 'reactstrap';
import config from '../../config';
import ActiveErrors from '../../components/common/activeErrors';
import ErrorBoundary from '../../components/common/errorBoundary';
import { YesNoModal } from '../../components/common/standardComponents';
import { validateField } from '../../lib/validation';
import * as appointmentActions from '../../actions/appointmentActions';
import * as orderManagementActions from '../../actions/orderManagementActions';
import * as providerActions from '../../actions/providerActions';
import * as patientActions from '../../actions/patientActions';
import * as rd2RefactorActions from '../../actions/rd2RefactorActions';
import { availabilitySearch as routeToAvailabilitySearch } from '../../routes';
import { PostBookCustomFieldsModal } from '../../components/modals/postBookCustomFields';
import AgentInstructions from '../../components/appointment/agentInstructions';
import Attachments from '../../components/appointment/attachments';
import BookingDetails from '../../components/appointment/bookingDetails';
import RescheduleBookingDetails from '../../components/appointment/rescheduleBookingDetails';
import ConsumerProductInstance from '../../components/appointment/consumerProductInstance';
import CustomFields from '../../components/appointment/customFields';
import PatientNotifications from '../../components/appointment/patientNotifications';
import EarlierAppointmentRequestSection from '../../components/appointment/earlierAppointmentRequestSection';
import ReasonForVisit from '../../components/appointment/reasonForVisit';
import LoadingIndicator from '../../components/loadingIndicator';
// import PatientDetailsHeader from '../../components/patient/patientDetailsHeader';
import PatientInfoTabbedCollapsable from 'features/patient/comps/patientInfoTabbedCollapsable';
import ReferringProvider from '../../components/appointment/referringProvider';
import { DEFAULT_BACK_BUTTON_WARNING_BOOK_APPOINTMENT_VIEW } from '../../constants/miscStrings';
import './bookAppointmentView.css';
import LoggerUtils from 'lib/loggerUtils';
import CountDownTimer from '../../components/common/countDownTimer'
import { fetchDecisionSupportSessionOutput } from 'actions/sessionActions';
import { processDecisionSupportSubpoints } from 'actions/decisionSupportActions';
import { setInitialCustomFieldAnswers } from 'actions/customFieldActions';

const NO_VOICE_REMINDER_MINUTES_START = 21 * 60;
const NO_VOICE_REMINDER_MINUTES_END = 8 * 60;
const NO_SMS_REMINDER_MINUTES_START = 21 * 60;
const NO_SMS_REMINDER_MINUTES_END = 8 * 60;
const OTHER_PHONE_NUMBER = 'other';
const SAME_PROVIDER = 'sameProvider';

export class BookAppointmentView extends React.Component {
	constructor(props) {
		super(props);

		// Gets either home phone or mobile phone as the default phone number if only one exists. This prevents unneccessary other number validation.
		const hasOnePhoneNumber = !((this.props.patientDetails.homePhone && this.props.patientDetails.mobilePhone) || (!this.props.patientDetails.homePhone && !this.props.patientDetails.mobilePhone));
		const phoneReminderNumber = hasOnePhoneNumber ? this.props.patientDetails.homePhone ?? this.props.patientDetails.mobilePhone : '';

		this.state = {
			collapse: {
				emailConsent: false,
				email: false,
				text: false,
				phone: false,
			},
			error: '',
			popoverOpen: [],
			isBackButtonModalOpen: false,
			isReservationLoading: true,
			isPreviousDetailsLoading: true,
			isCustomFieldsLoading: false,
			isRescheduleFlow: false,
			previousAppointmentDetails: {},
			postBookCustomFieldsModalVisible: false,
			isBooking: false,
			isChangingAppointment: false,
			isReferringProvidersLoading: false,
			isOrgProvidersLoading: false,
			fieldValidation: {
				reasonForVisit: {
					dataType: 'String',
					valid: true,
					max: this.props.schedulingConfig.reasonForVisitCharacterLimit,
					required: this.props.schedulingConfig.reasonForVisit.isRequired,
				},
				confirmationEmailOther: { dataType: 'Email', valid: true, required: false },
				reminderEmailOther: { dataType: 'Email', valid: true, required: false },
				emailReminderLeadTime: {
					dataType: 'Int32',
					valid: true,
					required: false,
					min: this.props.notificationConfig.minEmailReminderLeadTime,
				},
				voiceReminderNumber: { dataType: 'String', valid: true, required: false },
				voiceReminderOtherNumber: { dataType: 'Phone', valid: true, required: false },
				voiceReminderLeadTime: {
					dataType: 'Int32',
					valid: true,
					required: false,
					min: this.props.notificationConfig.minVoiceReminderLeadTime,
				},
				textReminderNumber: { dataType: 'String', valid: true, required: false },
				textReminderOtherNumber: { dataType: 'Phone', valid: true, required: false },
				textReminderLeadTime: {
					dataType: 'Int32',
					valid: true,
					required: false,
					min: this.props.notificationConfig.minTextReminderLeadTime,
				},
				selectedLanguage: { dataType: 'Int32', valid: true, required: false },
				externalReferringServiceName: { dataType: 'String', valid: true, max: 200, required: false },
				internalReferringServiceId: { dataType: 'Int32', valid: true, min: 1, required: false },
				referringOrganizationProviderId: { dataType: 'Int32', valid: true, min: 1, required: false },
				selectedConsumerProductInstanceId: { dataType: 'Int32', valid: true, min: 1, required: false }, //required: this.props.consumerProductInstances.length > 0 ? true : false
			},
			formValidation: {
				voiceReminderLeadTime: { valid: true, required: false },
				voiceReminderNumber: { valid: true, required: false },
				voiceReminderOtherNumber: { valid: true, required: false },
				textReminderLeadTime: { valid: true, required: false },
				textReminderNumber: { valid: true, required: false },
				textReminderOtherNumber: { valid: true, required: false },
				externalReferringServiceName: { valid: true, required: false },
				internalReferringServiceId: { valid: true, required: false },
				referringOrganizationProviderId: { valid: true, required: false },
			},
			isPageValid: true,
			isPostBookCustomFieldsValid: true,
			reasonForVisit: this.props.symptomDetails.reasonForVisit ? this.props.symptomDetails.reasonForVisit : '',
			selectedLanguage: this.props.notificationConfig.languageId,
			fileError: null,
			files: [],
			customFieldAnswers: null,
			customFieldValidation: null,

			externalReferringServiceName: '',
			internalReferringServiceId: null,
			referringOrganizationProviderId: null,

			confirmationEmailConsent: false,
			confirmationEmail: '',
			confirmationEmailOther: '',

			reminderEmail: '',
			reminderEmailOther: '',
			emailReminderConsent: false,
			emailReminderLeadTime: this.props.notificationConfig.minEmailReminderLeadTime,

			voiceReminderConsent: false,
			voiceReminderNumber: phoneReminderNumber,
			voiceReminderOtherNumber: '',
			voiceReminderLeadTime: this.props.notificationConfig.minVoiceReminderLeadTime,
			disableVoiceReminderNumber: false,
			disableVoiceReminderOtherNumber: true,

			textReminderConsent: false,
			textReminderNumber: phoneReminderNumber,
			textReminderOtherNumber: '',
			textReminderLeadTime: this.props.notificationConfig.minTextReminderLeadTime,
			disableTextReminderNumber: false,
			disableTextReminderOtherNumber: true,

			timeSlot: this.props.match.params.availabilityId,
			userId: this.props.match.params.userId,
			serviceSiteId: this.props.reservation?.timeSlotRequestInfo?.serviceSiteId,
			insuranceId: this.props.availability.searchContext.availabilitySearchCriteria.insuranceProviderId,
			payorTypeId: this.props.availability.searchContext.availabilitySearchCriteria.payorTypeName,
			patientReferenceId: this.props.patientReferenceId,
			patient: null,
			token: this.props.token,
			isPatientHeaderCollapsed: true,
			isServerTypeAheadLoading: false,
			isReservationExpired: false,
			isEarlierAppointmentRequested: false,
			selectedConsumerProductInstanceId:
				this.props.schedulingConfig.idProductInstanceConsumerCancellation
					? this.props.schedulingConfig.idProductInstanceConsumerCancellation
					: this.props.consumerProductInstances.length === 1
						? this.props.consumerProductInstances[0].id
						: this.props.consumerProductInstances.length < 2
							? 0
							: this.props.consumerProductInstances[1].id,
		};
	}

	resolveReferringProviderValidation = (requiresReferralToBook) => {
		if (this.props.referringProviderConfig.useReferringProviders && requiresReferralToBook) {
			let fieldValidation = { ...this.state.fieldValidation };

			fieldValidation.externalReferringServiceName.required =
				this.props.referringProviderConfig.allowExternalReferringProviders &&
				!this.props.referringProviderConfig.allowInternalReferringProviders &&
				!this.props.referringProviderConfig.allowOrganizationReferringProviders;

			fieldValidation.internalReferringServiceId.required =
				this.props.referringProviderConfig.allowInternalReferringProviders &&
				!this.props.referringProviderConfig.allowExternalReferringProviders &&
				!this.props.referringProviderConfig.allowExternalReferringProviders;

			fieldValidation.referringOrganizationProviderId.required =
				this.props.referringProviderConfig.allowOrganizationReferringProviders &&
				!this.props.referringProviderConfig.allowExternalReferringProviders &&
				!this.props.referringProviderConfig.allowInternalReferringProviders;

			let formValidation = { ...this.state.formValidation };

			let isFormRequired =
				this.props.referringProviderConfig.allowInternalReferringProviders ||
				this.props.referringProviderConfig.allowExternalReferringProviders ||
				this.props.referringProviderConfig.allowOrganizationReferringProviders;

			formValidation.externalReferringServiceName.required = isFormRequired;
			formValidation.internalReferringServiceId.required = isFormRequired;
			formValidation.referringOrganizationProviderId.required = isFormRequired;

			this.setState({ fieldValidation, formValidation });
		}
	};

	setBrowserHistoryState = () => {
		window.history.pushState(null, null, window.location.pathname);
	};

	createBackButtonModalEventListener = () => {
		window.addEventListener('popstate', this.onBrowserBackButtonClick);
	};

	componentDidMount() {

		this.setBrowserHistoryState();
		this.createBackButtonModalEventListener();

		let serviceSiteId = this.props.reservation?.timeSlotRequestInfo?.serviceSiteId;

		let referralSystemId = this.props.referralSystemId;

		let insuranceId = this.props.availability.searchContext.availabilitySearchCriteria.insuranceProviderId;
		let payorTypeId = this.props.availability.searchContext.availabilitySearchCriteria.payorTypeName;
		let patientReferenceId = this.props.patientReferenceId;
		let defaultLanguage = this.props.notificationConfig.languageId;

		let loadInternalReferringProviders =
			this.props.referringProviderConfig.useReferringProviders &&
			this.props.referringProviderConfig.allowInternalReferringProviders;

		let loadOrgReferringProviders =
			this.props.referringProviderConfig.useReferringProviders &&
			this.props.referringProviderConfig.allowOrganizationReferringProviders;

		let useInternalReferringProvidersServerTypeAhead =
			loadInternalReferringProviders &&
			this.props.referringProviderConfig.enableInternalReferringProviderServerTypeAhead;

		let isRescheduleFlow = Boolean(this.props.previousAppointmentReferenceId);

		if (isRescheduleFlow) {

			LoggerUtils.logTrace(`BookAppoinmentView.componentDidMount rescheduleMethod: ${this.props.rescheduleMethod}`);

			let promises = [];

			promises.push(this.props.actions.getPreviousAppointmentByReferenceId(this.props.previousAppointmentReferenceId));

			if (serviceSiteId && this.props.rescheduleMethod === SAME_PROVIDER) {
				this.setState({ isCustomFieldsLoading: true });
				promises.push(this.props.actions.getCustomFields(referralSystemId));
			}

			LoggerUtils.logTrace(`BookAppoinmentView.componentDidMount rescheduleFlowPromises length: ${promises.length}`);

			Promise.all(promises)
				.then((responses) => {
					if (responses[0].error) {
						LoggerUtils.logError(`BookAppoinmentView.componentDidMount getPreviousAppointmentByReferenceId error: ${responses[0].error}`, responses[0].error);
						this.setState({
							error: 'An error occurred while trying to get previous appointment details.',
							isCustomFieldsLoading: false,
							isPreviousDetailsLoading: false,
						});
					}

					if (responses[1] && responses[1].error) {
						LoggerUtils.logError(`BookAppoinmentView.componentDidMount getCustomFields error: ${responses[1].error}`, responses[1].error);
						this.setState({
							error: 'An error occurred while trying to get custom fields.',
							isCustomFieldsLoading: false,
							isPreviousDetailsLoading: false,
						});
					}

					if (!responses[0].error && (!responses[1] || !responses[1].error)) {
						let previousAppointmentDetails = responses[0].payload.data;

						LoggerUtils.logTrace(`BookAppoinmentView.componentDidMount loaded previousAppointmentDetails`, previousAppointmentDetails);

						if (this.props.rescheduleMethod === SAME_PROVIDER) {
							LoggerUtils.logTrace(`BookAppoinmentView.componentDidMount loaded customField answers unmapped SAME_PROVIDER`, previousAppointmentDetails?.customFields);
							let cutomFieldsAns = this.mapCustomFieldAnswer(
								this.props.customFields,
								previousAppointmentDetails.customFields,
							);
							LoggerUtils.logTrace(`BookAppoinmentView.componentDidMount loaded customField answers mapped SAME_PROVIDER`, cutomFieldsAns?.customFieldAnswers);
							this.setState(
								{
									isCustomFieldsLoading: false,
									customFieldAnswers: cutomFieldsAns.customFieldAnswers,
									customFieldValidation: cutomFieldsAns.validations,
								},
								() => {
									this.initializePatientNotifications();
								},
							);
						}
						this.setState({
							previousAppointmentDetails,
							isRescheduleFlow,
							isPreviousDetailsLoading: false,
							reasonForVisit: previousAppointmentDetails.reasonForVisit,
						});
					}
				})
				.catch((err) => {
					LoggerUtils.logError(`BookAppoinmentView.componentDidMount prevAppointment/customFields promises rejected: ${err}`, err);
					this.setState({
						error: 'An error occurred while trying to get previous appointment details.',
						isCustomFieldsLoading: false,
						isPreviousDetailsLoading: false,
					});
				});
		} else {
			this.setState({
				isPreviousDetailsLoading: false,
			});
		}

		//If the user books appointment, then clicks back...
		if (!serviceSiteId && this.props.appointmentId) {
			LoggerUtils.logTrace(`BookAppoinmentView.componentDidMount already has appointmentId, redirecting to AppointmentDetailsView`);
			this.props.history.push(`/appointment/${this.props.appointmentId}/details`);
		}

		if (serviceSiteId) {
			this.props.actions
				.getReservationDetails(
					this.props.reservation.flexCalendarEntryId,
					patientReferenceId,
					serviceSiteId,
					insuranceId,
					payorTypeId,
				)
				.then((detailResponse) => {
					if (detailResponse.error) {
						this.setState({
							error: 'An error occurred while trying to get appointment details.',
							isReservationLoading: false,
						});
					} else {
						let setLanguage = defaultLanguage;
						if (
							detailResponse.payload.data.notificationLanguages &&
							detailResponse.payload.data.notificationLanguages.length === 1
						) {
							setLanguage = detailResponse.payload.data.notificationLanguages[0].idLanguage;
						}
						this.resolveReferringProviderValidation(detailResponse.payload.data.requiresReferralToBook);
						this.setState({
							isReservationLoading: false,
							selectedLanguage: setLanguage,
						});
					}
				})
				.catch((err) => {
					this.setState({
						error: 'An error occurred while trying to get appointment details.',
						isReservationLoading: false,
					});
				});

			if (!isRescheduleFlow || this.props.rescheduleMethod !== SAME_PROVIDER) {
				LoggerUtils.logTrace(`BookAppoinmentView.ComponentDidMount attempting to get non-reschedule or different provider reschedule custom fields`);
				this.setState({ isCustomFieldsLoading: true });
				this.props.actions
					.getCustomFields(referralSystemId)
					.then((response) => {
						if (response.error) {
							LoggerUtils.logError(`BookAppoinmentView.ComponentDidMount getCustomFields non-reschedule or different provider error: ${response.error}`, response.error);
							this.setState({
								error: 'An error occurred while trying to get custom fields.',
								isCustomFieldsLoading: false,
							});
						} else {
							this.props.dispatch(
								async (dispatch, getState) => {
									let state = getState();
									
									if(!!state.session.decisionSupportSessionId) {
										await dispatch(fetchDecisionSupportSessionOutput(state.session.decisionSupportSessionId));
										state = getState()
									}
									
									if(!!state.session.decisionSupportSubpoints) {
										dispatch(processDecisionSupportSubpoints(state.session.decisionSupportSubpoints, true));
										state = getState();	
									}

									dispatch(setInitialCustomFieldAnswers(state.careOrder.customFields, state.decisionSupport.customFields));
									state = getState();

									const customFields = state.customFields;

									LoggerUtils.logTrace(`BookAppoinmentView.ComponentDidMount non-reschedule or different provider reschedule custom fields answers unmapped`, customFields?.customFieldAnswers);
									let customFieldsAns = this.mapCustomFieldAnswer(customFields, customFields.customFieldAnswers);
									LoggerUtils.logTrace(`BookAppoinmentView.ComponentDidMount customFieldsMapped non-reschedule or different provider custom field answers mapped`, customFieldsAns?.customFieldAnswers);
									this.setState(
										{
											isCustomFieldsLoading: false,
											customFieldAnswers: customFieldsAns.customFieldAnswers,
											customFieldValidation: customFieldsAns.validations,
										},
										() => {
											this.initializePatientNotifications();
										},
									);
								}

							);
						}
					})
					.catch((err) => {
						LoggerUtils.logError(`BookAppoinmentView.ComponentDidMount non-reschedule or different provider customFields promise rejected: ${err}`, err);
						this.setState({
							error: 'An error occurred while trying to get custom fields.',
							isPageLoading: false,
						});
					});
			}

			if (loadInternalReferringProviders && !useInternalReferringProvidersServerTypeAhead) {
				this.setState({ isReferringProvidersLoading: true });
				this.props.actions
					.getReferringProviders(this.props.referralSystemId)
					.then((response) => {
						if (response.error) {
							this.setState({
								error: 'An error occurred while trying to get the referring provider list.',
								isReferringProvidersLoading: false,
							});
						} else {
							this.setState({ isReferringProvidersLoading: false });
						}
					})
					.catch((err) => {
						this.setState({
							error: 'An error occurred while trying to get the referring provider list.',
							isPageLoading: false,
							isReferringProvidersLoading: false,
						});
					});
			} else {
				this.setState({ isReferringProvidersLoading: false });
			}
			if (loadOrgReferringProviders && !useInternalReferringProvidersServerTypeAhead) {
				this.setState({ isOrgProvidersLoading: true });
				this.props.actions
					.getOrganizationReferringProviders(null)
					.then((response) => {
						if (response.error) {
							this.setState({
								error: 'An error occurred while trying to get the organization referring provider list.',
								isOrgProvidersLoading: false,
							});
						} else {
							this.setState({ isOrgProvidersLoading: false });
						}
					})
					.catch((err) => {
						this.setState({
							error: 'An error occurred while trying to get the organization referring provider list.',
							isPageLoading: false,
							isOrgProvidersLoading: false,
						});
					});
			} else {
				this.setState({ isOrgProvidersLoading: false });
			}
		}
		if (
			this.props.patientConfig.notes.isVisible &&
			this.props.schedulingConfig.autoExpandPatientHeaderForVisibleNotes
		) {
			this.setState({ isPatientHeaderCollapsed: false });
		}
	}

	mapCustomFieldAnswer = (customFields, customFieldAns) => {
		let answers = {};
		let validation = {};
		let results = {};
		customFields.customFields.forEach((field) => {
			// Look for matching DS subpoints to prepopulate values
			if (customFieldAns.length) {
				let answerMatch = customFieldAns.find((answer) => {
					let answerKeyLabel = answer.key ? answer.key : answer.label;
					return answerKeyLabel.localeCompare(field.fieldLabel, undefined, { sensitivity: 'accent' }) === 0;
				});
				answers[field.fieldName] = answerMatch ? answerMatch.value : '';
			} else {
				answers[field.fieldName] = '';
			}
			validation[field.fieldName] = {
				valid: true,
				required: field.isRequired,
				active: field.isActive,
				dataType: field.dataType,
				public: field.printIt,
				isPostBookAppointment: field.isPostBookAppointment,
			};
		});
		results.customFieldAnswers = answers;
		results.validations = validation;

		return results;
	};

	removeBackButtonModalEventListener = () => {
		window.removeEventListener('popstate', this.onBrowserBackButtonClick);
	};

	componentWillUnmount() {
		this.removeBackButtonModalEventListener();
	}

	initializePatientNotifications = () => {
		if (this.props.emailConfirmationEnabled && !this.props.enableConfirmationEmailOptIn) {
			this.togglePatientNotifications('emailConsent');
			let event = {
				target: {
					name: 'confirmationEmailConsent',
					checked: true,
				},
			};

			this.handleCheckboxChange(
				event,
				this.props.patientDetails.email && this.props.patientDetails.email.trim() !== ''
					? []
					: ['confirmationEmailOther'],
			);
			let fieldValidation = { ...this.state.fieldValidation };
			fieldValidation.confirmationEmailOther.valid =
				this.props.patientDetails.email && this.props.patientDetails.email.trim() !== '' ? true : false;
			fieldValidation.confirmationEmailOther.required =
				this.props.patientDetails.email && this.props.patientDetails.email.trim() !== '' ? false : true;
			this.setState({
				enableConfirmationEmailInstructions: false,
				fieldValidation,
			});
		}
	};

	togglePatientNotifications = (area) => {
		let col = { ...this.state.collapse };
		col[area] = !col[area];
		this.setState({ collapse: col });
	};

	handleChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let valObj = { ...this.state.fieldValidation };
		if (valObj[name]) {
			let isRequired = valObj[name].required;
			if (value && value.trim() !== '') {
				isRequired = true;
			}
			this.setState({ [name]: value, fieldValidation: valObj }, () => {
				valObj[name].valid = this.validateFieldAndForm(name, type, value, min, max, isRequired);
			});
		}
	};

	handleCheckboxChange = (event, requiredFields) => {
		const { name, checked } = event.target;
		this.setState({ [name]: checked });
		let valObj = { ...this.state.fieldValidation };
		let formVal = { ...this.state.formValidation };
		requiredFields.forEach((field) => {
			//fields only required when the checkbox is checked
			if (formVal[field]) {
				formVal[field].required = checked;
				this.setState({ formValidation: formVal });
			}
			if (valObj[field]) {
				valObj[field].required = checked;
				let { dataType, min, max } = { ...valObj[field] };
				this.validateFieldAndForm(field, dataType, this.state[field], min, max, valObj[field].required);
				this.setState({ fieldValidation: valObj });
			}
		});
	};

	handleCustomFieldChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let newObj = { ...this.state.customFieldAnswers };
		this.validateFieldAndForm(name, type, value, min, max, this.state.customFieldValidation[name].required, true);
		newObj[name] = value;
		this.setState({ customFieldAnswers: newObj });
	};

	handleCustomFieldDropdownChange = (name, obj) => {
		let newObj = { ...this.state.customFieldAnswers };
		let value = obj ? obj.value : null;
		this.validateFieldAndForm(
			name,
			this.state.customFieldValidation[name].dataType,
			value,
			null,
			null,
			this.state.customFieldValidation[name].required,
			true,
		);
		newObj[name] = value;
		this.setState({ customFieldAnswers: newObj });
	};

	handleCustomFieldDateChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let newObj = { ...this.state.customFieldAnswers };
		if (value === '__/__/____' || value === '') return;
		newObj[name] = value;
		this.validateFieldAndForm(name, type, value, min, max, this.state.customFieldValidation[name].required, true);
		this.setState({ customFieldAnswers: newObj });
	};

	handlePostBookCustomFieldChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let newObj = { ...this.state.customFieldAnswers };
		this.validatePostBookCustomFieldsForm(
			name,
			type,
			value,
			min,
			max,
			this.state.customFieldValidation[name].required,
			true,
		);
		newObj[name] = value;
		this.setState({ customFieldAnswers: newObj });
	};

	handlePostBookCustomFieldDropdownChange = (name, obj) => {
		let newObj = { ...this.state.customFieldAnswers };
		let value = obj ? obj.value : null;
		this.validatePostBookCustomFieldsForm(
			name,
			this.state.customFieldValidation[name].dataType,
			value,
			null,
			null,
			this.state.customFieldValidation[name].required,
			true,
		);
		newObj[name] = value;
		this.setState({ customFieldAnswers: newObj });
	};

	handlePostBookCustomFieldDateChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let newObj = { ...this.state.customFieldAnswers };
		if (value === '__/__/____' || value === '') return;
		newObj[name] = value;
		this.validatePostBookCustomFieldsForm(
			name,
			type,
			value,
			min,
			max,
			this.state.customFieldValidation[name].required,
			true,
		);
		this.setState({ customFieldAnswers: newObj });
	};

	handleServerTypeAheadSearch = (query) => {
		this.setState({ isServerTypeAheadLoading: true });
		this.props.actions.searchReferringProviders(this.props.referralSystemId, query).then(() => {
			this.setState({ isServerTypeAheadLoading: false });
		});
	};

	handleOrgProviderServerTypeAheadSearch = (query) => {
		this.setState({ isServerTypeAheadLoading: true });
		this.props.actions.searchOrganizationProviders(query).then(() => {
			this.setState({ isServerTypeAheadLoading: false });
		});
	};

	handleRequestEarlierAppointmentChange = () => {
		let isEarlierAppointmentRequested = this.state.isEarlierAppointmentRequested;
		this.setState({ isEarlierAppointmentRequested: !isEarlierAppointmentRequested });
	};

	handleDropdownChange = (name, type, obj) => {
		let valObj = { ...this.state.fieldValidation };
		if (obj) {
			this.setState({ [name]: obj.value }, () => {
				this.validateFieldAndForm(name, type, obj.value, valObj[name].min, valObj[name].max);
			});
		} else {
			this.setState({ [name]: '' }, () => {
				this.validateFieldAndForm(name, type, '');
			});
		}
	};

	validate = () => {
		let validation = this.state.customFieldValidation;

		const preBook = {};
		const postBook = {};

		for (let key in validation) {
			if (validation[key].isPostBookAppointment === false) preBook[key] = validation[key];
			else postBook[key] = validation[key];
		}

		//re-run validations
		let customFieldValidity = this.props.schedulingConfig.enableCustomFields
			? Object.keys(preBook).every((field) => {
				let valField = this.state.customFieldValidation[field];
				return this.validateFieldAndForm(
					field,
					valField.dataType,
					this.state.customFieldAnswers[field],
					null,
					null,
					valField.required,
					true,
				);
			})
			: true;

		let fieldValidityArray = [];
		Object.keys(this.state.fieldValidation).forEach((field) => {
			let valField = this.state.fieldValidation[field];
			fieldValidityArray.push(
				this.validateFieldAndForm(
					field,
					valField.dataType,
					this.state[field],
					(valField.min = null),
					(valField.max = null),
					valField.required,
				),
			);
		});
		let fieldValidity = fieldValidityArray.every((x) => x === true);

		let formValidityArray = [];
		Object.keys(this.state.formValidation).forEach((field) => {
			formValidityArray.push(this.validateForm(field));
		});
		let formValidity = formValidityArray.every((x) => x === true);

		this.setState({ isPageValid: fieldValidity && customFieldValidity && formValidity });
		return fieldValidity && customFieldValidity && formValidity;
	};

	validatePostBookCustomFields = () => {
		let validation = this.state.customFieldValidation;

		const preBook = {};
		const postBook = {};

		for (let key in validation) {
			if (validation[key].isPostBookAppointment === false) preBook[key] = validation[key];
			else postBook[key] = validation[key];
		}

		let customFieldValidity = Object.keys(postBook).every((field) => {
			let valField = this.state.customFieldValidation[field];
			return this.validatePostBookCustomFieldsForm(
				field,
				valField.dataType,
				this.state.customFieldAnswers[field],
				null,
				null,
				valField.required,
				true,
			);
		});

		this.setState({ isPostBookCustomFieldsValid: customFieldValidity });
		return customFieldValidity;
	};

	validateForm = (name) => {
		let isValid = true;
		//TODO: Find a way to combine some of these similar validation functions
		switch (name) {
			case 'voiceReminderLeadTime':
			case 'textReminderLeadTime':
				isValid = this.isReminderLeadTimeValid(name);
				break;
			case 'voiceReminderOtherNumber':
			case 'voiceReminderNumber':
				isValid = this.isVoiceReminderNumberValid(name);
				break;
			case 'textReminderOtherNumber':
			case 'textReminderNumber':
				isValid = this.isTextReminderNumberValid(name);
				break;
			case 'externalReferringServiceName':
			case 'internalReferringServiceId':
			case 'referringOrganizationProviderId':
				isValid = this.isReferringProviderValid(name);
				break;
			default:
				break;
		}
		return isValid;
	};

	isReferringProviderValid = (name) => {
		let isValid = false;
		let formValidation = { ...this.state.formValidation };
		let fieldValidation = { ...this.state.fieldValidation };
		let { internalReferringServiceId, externalReferringServiceName, referringOrganizationProviderId } = {
			...this.state,
		};

		if (formValidation[name].required) {
			//defaults
			formValidation.internalReferringServiceId.required = true;
			formValidation.internalReferringServiceId.valid = false;
			formValidation.externalReferringServiceName.required = true;
			formValidation.externalReferringServiceName.valid = false;
			formValidation.referringOrganizationProviderId.required = true;
			formValidation.referringOrganizationProviderId.valid = false;

			fieldValidation.internalReferringServiceId.valid = true;
			fieldValidation.externalReferringServiceName.valid = true;
			fieldValidation.referringOrganizationProviderId.valid = true;

			if (internalReferringServiceId > 0) {
				fieldValidation.externalReferringServiceName.valid = true;
				fieldValidation.externalReferringServiceName.required = false;
				fieldValidation.referringOrganizationProviderId.valid = true;
				fieldValidation.referringOrganizationProviderId.required = false;
				formValidation.externalReferringServiceName.valid = true;
				formValidation.externalReferringServiceName.required = false;
				formValidation.referringOrganizationProviderId.valid = true;
				formValidation.referringOrganizationProviderId.required = false;
				formValidation.internalReferringServiceId.valid = fieldValidation.internalReferringServiceId.valid;
				isValid = formValidation.internalReferringServiceId.valid;
			} else if (referringOrganizationProviderId > 0) {
				fieldValidation.internalReferringServiceId.valid = true;
				fieldValidation.internalReferringServiceId.required = false;
				fieldValidation.externalReferringServiceName.valid = true;
				fieldValidation.externalReferringServiceName.required = false;
				formValidation.internalReferringServiceId.valid = true;
				formValidation.internalReferringServiceId.required = false;
				formValidation.externalReferringServiceName.valid = true;
				formValidation.externalReferringServiceName.required = false;
				formValidation.referringOrganizationProviderId.valid = fieldValidation.referringOrganizationProviderId.valid;
				isValid = formValidation.referringOrganizationProviderId.valid;
			} else if (externalReferringServiceName !== null && externalReferringServiceName.trim() !== '') {
				fieldValidation.internalReferringServiceId.valid = true;
				fieldValidation.internalReferringServiceId.required = false;
				fieldValidation.referringOrganizationProviderId.valid = true;
				fieldValidation.referringOrganizationProviderId.required = false;
				formValidation.internalReferringServiceId.valid = true;
				formValidation.internalReferringServiceId.required = false;
				formValidation.referringOrganizationProviderId.valid = true;
				formValidation.referringOrganizationProviderId.required = false;
				formValidation.externalReferringServiceName.valid = fieldValidation.externalReferringServiceName.valid;
				isValid = formValidation.externalReferringServiceName.valid;
			}
			formValidation[name].valid = isValid;
		} else {
			formValidation[name].valid = true;
			isValid = true;
		}
		this.setState({ formValidation, fieldValidation });
		return isValid;
	};

	isReminderLeadTimeValid = (name) => {
		let isValid = false;

		let isVoice = name === 'voiceReminderLeadTime' ? true : false;
		let reminderLeadTime = isVoice ? this.state.voiceReminderLeadTime : this.state.textReminderLeadTime;
		let noReminderMinutesStart = isVoice ? NO_VOICE_REMINDER_MINUTES_START : NO_SMS_REMINDER_MINUTES_START;
		let noReminderMinutesEnd = isVoice ? NO_VOICE_REMINDER_MINUTES_END : NO_SMS_REMINDER_MINUTES_END;

		let formValidation = { ...this.state.formValidation };
		if (formValidation[name].required) {
			let adjustedTime = moment(this.props.reservationDetails.patientAppointmentDateTime).subtract(
				reminderLeadTime,
				'h',
			);
			let scheduledReminder = adjustedTime.hours() * 60;
			scheduledReminder += adjustedTime.minutes();
			isValid = scheduledReminder < noReminderMinutesStart && scheduledReminder >= noReminderMinutesEnd;
			formValidation[name].valid = isValid;
		} else {
			formValidation[name].valid = true;
			isValid = true;
		}
		this.setState({ formValidation });
		return isValid;
	};

	isVoiceReminderNumberValid = (name) => {
		let isValid = false;
		let formValidation = { ...this.state.formValidation };
		let fieldValidation = { ...this.state.fieldValidation };
		let { voiceReminderOtherNumber, disableVoiceReminderOtherNumber, disableVoiceReminderNumber } = { ...this.state };

		if (formValidation[name].required) {
			//default values for first load
			formValidation.voiceReminderNumber.required = true;
			formValidation.voiceReminderNumber.valid = false;
			fieldValidation.voiceReminderOtherNumber.required = true;
			formValidation.voiceReminderOtherNumber.valid = false;

			disableVoiceReminderNumber = false;
			disableVoiceReminderOtherNumber = true;

			if (this.state.voiceReminderNumber && this.state.voiceReminderNumber !== OTHER_PHONE_NUMBER) {
				fieldValidation.voiceReminderOtherNumber.required = false;
				fieldValidation.voiceReminderOtherNumber.valid = true;
				formValidation.voiceReminderOtherNumber.valid = true;
				formValidation.voiceReminderNumber.valid = fieldValidation.voiceReminderNumber.valid;
				isValid = formValidation.voiceReminderNumber.valid;

				voiceReminderOtherNumber = '';
				disableVoiceReminderOtherNumber = true;
			} else if (this.state.voiceReminderNumber === OTHER_PHONE_NUMBER) {
				if (!fieldValidation.voiceReminderNumber.valid) {
					fieldValidation.voiceReminderOtherNumber.valid = false;
				}

				fieldValidation.voiceReminderNumber.valid = true;
				formValidation.voiceReminderNumber.required = true;
				formValidation.voiceReminderNumber.valid = true;
				disableVoiceReminderOtherNumber = false;
				formValidation.voiceReminderOtherNumber.valid = fieldValidation.voiceReminderOtherNumber.valid;
				isValid = fieldValidation.voiceReminderOtherNumber.valid;
			} else {
				voiceReminderOtherNumber = '';
			}

			formValidation[name].valid = isValid;
		} else {
			formValidation[name].valid = true;
			isValid = true;
		}
		this.setState({
			formValidation,
			fieldValidation,
			voiceReminderOtherNumber,
			disableVoiceReminderOtherNumber,
			disableVoiceReminderNumber,
		});
		return isValid;
	};

	isTextReminderNumberValid = (name) => {
		let isValid = false;
		let formValidation = { ...this.state.formValidation };
		let fieldValidation = { ...this.state.fieldValidation };
		let { textReminderOtherNumber, disableTextReminderOtherNumber, disableTextReminderNumber } = { ...this.state };

		if (formValidation[name].required) {
			//default values for first load
			formValidation.textReminderNumber.required = true;
			formValidation.textReminderNumber.valid = false;
			fieldValidation.textReminderOtherNumber.required = true;
			formValidation.textReminderOtherNumber.valid = false;

			disableTextReminderNumber = false;
			disableTextReminderOtherNumber = true;

			if (this.state.textReminderNumber && this.state.textReminderNumber !== OTHER_PHONE_NUMBER) {
				fieldValidation.textReminderOtherNumber.required = false;
				fieldValidation.textReminderOtherNumber.valid = true;
				formValidation.textReminderOtherNumber.valid = true;
				formValidation.textReminderNumber.valid = fieldValidation.textReminderNumber.valid;
				isValid = formValidation.textReminderNumber.valid;

				textReminderOtherNumber = '';
				disableTextReminderOtherNumber = true;
			} else if (this.state.textReminderNumber === OTHER_PHONE_NUMBER) {
				if (!fieldValidation.textReminderNumber.valid) {
					fieldValidation.textReminderOtherNumber.valid = false;
				}

				fieldValidation.textReminderNumber.valid = true;
				formValidation.textReminderNumber.required = true;
				formValidation.textReminderNumber.valid = true;
				disableTextReminderOtherNumber = false;
				formValidation.textReminderOtherNumber.valid = fieldValidation.textReminderOtherNumber.valid;
				isValid = fieldValidation.textReminderOtherNumber.valid;
			} else {
				textReminderOtherNumber = '';
			}

			formValidation[name].valid = isValid;
		} else {
			formValidation[name].valid = true;
			isValid = true;
		}
		this.setState({
			formValidation,
			fieldValidation,
			textReminderOtherNumber,
			disableTextReminderOtherNumber,
			disableTextReminderNumber,
		});
		return isValid;
	};

	determineCustomFieldValidity = (isPreBook) => {
		let validation = this.state.customFieldValidation;
		const preBookCustomFields = {};
		const postBookCustomFields = {};
		for (let key in validation) {
			if (validation[key].isPostBookAppointment === false) preBookCustomFields[key] = validation[key];
			else postBookCustomFields[key] = validation[key];
		}

		let customFieldValidity;

		if (isPreBook === true) {
			customFieldValidity = Object.keys(preBookCustomFields).every((field) => {
				return preBookCustomFields[field].valid === true;
			});
		} else {
			customFieldValidity = Object.keys(postBookCustomFields).every((field) => {
				return postBookCustomFields[field].valid === true;
			});
		}

		return customFieldValidity;
	};

	validateFieldAndForm = (
		name,
		type,
		value,
		minimum = null,
		maximum = null,
		required = true,
		isCustomField = false,
	) => {
		let isFieldValid = validateField(name, type, value, minimum, maximum, required);

		if (isCustomField) {
			let valObj = { ...this.state.customFieldValidation };
			valObj[name].valid = isFieldValid;
			this.setState({ customFieldValidation: valObj });
		} else {
			let valObj = { ...this.state.fieldValidation };
			if (valObj[name]) {
				valObj[name].valid = isFieldValid;
				this.setState({ fieldValidation: valObj });
			}
		}

		this.validateForm(name);

		let formValidity = Object.keys(this.state.formValidation).every((field) => {
			return this.state.formValidation[field].valid === true;
		});

		let validity = Object.keys(this.state.fieldValidation).every((field) => {
			return this.state.fieldValidation[field].valid === true;
		});

		let customFieldValidity = this.determineCustomFieldValidity(true);

		this.setState({ isPageValid: validity & customFieldValidity & formValidity ? true : false });

		return isFieldValid;
	};

	validatePostBookCustomFieldsForm = (
		name,
		type,
		value,
		minimum = null,
		maximum = null,
		required = true,
		isCustomField = false,
	) => {
		let isFieldValid = validateField(name, type, value, minimum, maximum, required);

		if (isCustomField) {
			let valObj = { ...this.state.customFieldValidation };
			valObj[name].valid = isFieldValid;
			this.setState({ customFieldValidation: valObj });
		} else {
			let valObj = { ...this.state.fieldValidation };
			if (valObj[name]) {
				valObj[name].valid = isFieldValid;
				this.setState({ fieldValidation: valObj });
			}
		}

		let customFieldValidity = this.determineCustomFieldValidity(false);

		this.setState({ isPostBookCustomFieldsValid: customFieldValidity });
		return customFieldValidity;
	};

	onDrop = (acceptedFiles, rejectedFiles) => {
		if (this.state.files.length + acceptedFiles.length > this.props.systemConfig.appointmentMaxAttachments) {
			this.setState({
				fileError: 'Only ' + this.props.systemConfig.appointmentMaxAttachments + ' attachments may be added.',
			});
			return;
		}
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				let fileAsBinaryString = '';

				if (!e) {
					fileAsBinaryString = reader.content;
				} else {
					fileAsBinaryString = reader.result;
				}

				let fileArray = this.state.files.slice();
				fileArray.push({ name: file.name, size: file.size, data: fileAsBinaryString });
				this.setState({ files: fileArray, fileError: null });
			};
			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');

			reader.readAsBinaryString(file);
		});
	};

	onDropRejected = () => {
		this.setState({
			fileError: `Files exceeds ${config.maxAppointmentAttachmentSize / 1048576
				}MB limit or file extensions are not valid. Please attach again.`,
		});
	};

	onCancel = () => {
		this.setState({
			files: [],
			fileError: null,
		});
	};

	removeFile = (f) => {
		let fileArray = this.state.files.slice();
		fileArray.splice(fileArray.indexOf(f), 1);
		this.setState({
			files: fileArray,
			fileError: null,
		});
	};

	openPostBookModal() {
		if (this.validate()) {
			this.setState({
				postBookCustomFieldsModalVisible: true,
			});
		}
	}

	onAccept = () => {
		if (this.validatePostBookCustomFields()) {
			this.bookAppointment();
			this.setState({
				postBookCustomFieldsModalVisible: false,
				isPostBookCustomFieldsValid: true,
			});
		} else {
			this.setState({
				postBookCustomFieldsModalVisible: true,
				isPostBookCustomFieldsValid: false,
			});
		}
	};

	onReject = () => {
		this.setState({
			postBookCustomFieldsModalVisible: false,
		});
	};

	bookAppointment() {
		if (this.validate()) {
			this.setState({ isBooking: true });

			let cfa = this.props.customFields.customFields.map((field) => {
				return { Key: field.fieldName, Value: this.state.customFieldAnswers[field.fieldName] };
			});

			let attachments = this.state.files.map((file) => {
				return { fileName: file.name, base64EncodedFileContent: window.btoa(file.data) };
			});

			let booking = {
				correlationKey: this.props.session.correlationKey,
				idSlot: this.state.timeSlot,
				patientReferenceId: this.props.patientReferenceId,
				idPayorType: this.props.reservationDetails.idPayorType,
				idLanguage: this.state.selectedLanguage,

				homePhoneCallReminder: this.state.voiceReminderConsent,
				homePhoneCallReminderNumber: this.state.voiceReminderOtherNumber
					? this.state.voiceReminderOtherNumber
					: this.props.patientDetails.homePhone,
				homePhoneRemindersHoursBefore: this.state.voiceReminderLeadTime,

				mobilePhoneCallReminder: false, // right now we don't want to do separate reminders for home/mobile
				mobilePhoneCallReminderNumber: this.state.voiceReminderOtherNumber
					? this.state.voiceReminderOtherNumber
					: this.props.patientDetails.mobilePhone,
				mobilePhoneCallRemindersHoursBefore: this.state.voiceReminderLeadTime,

				mobilePhoneTextReminder: this.state.textReminderConsent,
				mobilePhoneTextReminderNumber: this.state.textReminderOtherNumber
					? this.state.textReminderOtherNumber
					: this.state.textReminderNumber,
				mobilePhoneTextRemindersHoursBefore: this.state.textReminderLeadTime,

				reminderEmail: this.state.reminderEmailOther ? this.state.reminderEmailOther : this.props.patientDetails.email,
				emailReminder: this.state.emailReminderConsent,

				emailConfirmation: this.state.confirmationEmailConsent,
				confirmationEmail: this.state.confirmationEmailOther
					? this.state.confirmationEmailOther
					: this.props.patientDetails.email,
				emailRemindersHoursBefore: this.state.emailReminderLeadTime,

				reasonForAppointment: this.state.reasonForVisit,
				idInsuranceState: this.props.reservationDetails.idInsuranceState,
				idInsurance: this.props.reservationDetails.idInsurance,
				clientIdentifier: '',
				reservationId: this.props.reservation.reservationId,
				customFieldValues: cfa,
				appointmentAttachments: attachments,
				appointmentTypeId: this.props.reservationDetails.idAppointmentType,
				idServiceSite: this.state.serviceSiteId,
				slotStartDateTime: moment.utc(this.props.reservationDetails.appointmentStartAt).toISOString(),
				slotEndDateTime: moment.utc(this.props.reservationDetails.appointmentEndAt).toISOString(),
				managedReferringServiceId: this.state.internalReferringServiceId,
				unmanagedReferringServiceName:
					this.state.externalReferringServiceName !== null && this.state.externalReferringServiceName.trim() !== ''
						? this.state.externalReferringServiceName.trim()
						: null,
				referringOrganizationProviderId: this.state.referringOrganizationProviderId,
				workboxId: this.props.session.workboxId,
				decisionSupportSessionId: this.props.session.decisionSupportSessionId,

				productInstanceConsumerId:
					this.state.selectedConsumerProductInstanceId !== 0
						? parseInt(this.state.selectedConsumerProductInstanceId, 10)
						: null,

				careOrderVisitIdentifier: this.props.activeCareOrder.careOrderVisitIdentifier,
				externalReferralOrderId: this.props.activeCareOrder.externalReferralOrderId,
				hasRequestedWaitlist: this.state.isEarlierAppointmentRequested,
			};

			if (this.state.isRescheduleFlow) {
				this.props.actions
					.rescheduleAppointment(
						this.props.token,
						booking,
						this.props.previousAppointmentReferenceId,
						this.props.rescheduleMethod,
					)
					.then((response) => {
						if (response.error) {
							this.setState({
								error: 'An error occurred while trying to book.',
								isBooking: false,
							});
						} else {
							this.props.history.push(`/appointment/${response.payload.data.referenceId}/details`);
							this.setState({
								isBooking: false,
							});
						}
					})
					.catch((err) => {
						this.setState({
							error: 'An error occurred while trying to book.',
							isBooking: false,
						});
					});
			} else {
				if (
					this.props.activeCareOrder.isCareOrderBookingContext &&
					!this.props.activeCareOrder.careOrderVisitIdentifier
				) {
					let acod = this.props.activeCareOrder.activeCareOrderDetails;
					let appt = acod.appointments[0];

					let createCareOrderDetails = {
						id: 0,
						systemId: this.props.auth.referralSystemId,
						patientReferenceId: this.props.patientDetails.referenceId,
						userId: this.props.auth.userId,
						careOrderStatusReasonId: 5, // created
						idProductInstance: this.props.auth.idProductInstance,
						careOrderType: 1, // referral Order
						expirationDate: acod.expirationDate,
						currentFailedAuthCount: 0,
						visits: [
							{
								systemDateRangeId: null,
								minVisitDate: acod.minVisitDate,
								maxVisitDate: acod.maxVisitDate,
								referenceId: null,
								createdAt: moment.utc().format('YYYY-MM-DD'),
								createdBy: this.props.auth.userId,
								updatedAt: moment.utc().format('YYYY-MM-DD'),
								updatedBy: this.props.auth.userId,
								appointments: [
									{
										orderIndex: 0,
										siteId: appt.siteId,
										serviceId: appt.serviceId,
										appointmentTypeId: appt.appointmentTypeId,
										specialtyId: appt.specialtyId,
										referringSiteId: appt.referringSiteId,
										referringServiceId: appt.referringServiceId,
										unmanagedReferringSiteName: null,
										unmanagedReferringServiceName: null,
										reasonForVisit: appt.reasonForVisit,
										createdAt: moment.utc().format('YYYY-MM-DD'),
										createdBy: this.props.auth.userId,
										updatedAt: moment.utc().format('YYYY-MM-DD'),
										updatedBy: this.props.auth.userId,
										externalReferralOrderId: appt.externalReferralOrderId,
										subgroupId: null,
									},
								],
							},
						],
						consumerIdProductInstance: null,
						externalReferralOrderId: appt.externalReferralOrderId,
						sendNotificationsAfterCreate: false,
					};

					this.props.orderManagementActions
						.createCareOrder(createCareOrderDetails)
						.then((careOrderCreateResponse) => {
							if (careOrderCreateResponse.error) {
								this.setState({
									error: 'An error occurred while trying to book.',
									isBooking: false,
								});
							} else {
								booking.careOrderVisitIdentifier = careOrderCreateResponse.payload.data;
								this.props.actions
									.bookAppointment(this.props.token, booking)
									.then((response) => {
										if (response.error) {
											this.setState({
												error: 'An error occurred while trying to book.',
												isBooking: false,
											});
										} else {
											this.setState({
												isBooking: false,
											});
											this.props.history.push(`/appointment/${response.payload.data.referenceId}/details`);
										}
									})
									.catch((err) => {
										this.setState({
											error: 'An error occurred while trying to book.',
											isBooking: false,
										});
									});
							}
						})
						.catch((err) => {
							this.setState({
								error: 'An error occurred while trying to book.',
								isBooking: false,
							});
						});
				} else {
					this.props.actions
						.bookAppointment(this.props.token, booking)
						.then((response) => {
							if (response.error) {
								this.setState({
									error: 'An error occurred while trying to book.',
									isBooking: false,
								});
							} else {
								this.props.history.push(`/appointment/${response.payload.data.referenceId}/details`);
								this.setState({
									isBooking: false,
								});
							}
						})
						.catch((err) => {
							this.setState({
								error: 'An error occurred while trying to book.',
								isBooking: false,
							});
						});
				}
			}
		}
	}

	rescheduleAppointment = () => {
		this.setState({ isBooking: true });
		const prevAppointment = this.state.previousAppointmentDetails;

		let customFieldAppointment = this.props.customFields.customFields.map((field) => {
			return { Key: field.fieldName, Value: this.state.customFieldAnswers[field.fieldName] };
		});
		let attachments = this.state.files.map((file) => {
			return { fileName: file.name, base64EncodedFileContent: window.btoa(file.data) };
		});

		let booking = {
			correlationKey: this.props.session.correlationKey,
			idSlot: this.state.timeSlot,
			patientReferenceId: this.props.patientReferenceId,
			idPayorType: this.props.reservationDetails.idPayorType,
			idLanguage: this.state.selectedLanguage,

			homePhoneCallReminder: this.state.voiceReminderConsent,
			homePhoneCallReminderNumber: this.state.voiceReminderOtherNumber
				? this.state.voiceReminderOtherNumber
				: this.props.patientDetails.homePhone,
			homePhoneRemindersHoursBefore: this.state.voiceReminderLeadTime,

			mobilePhoneCallReminder: false, // right now we don't want to do separate reminders for home/mobile
			mobilePhoneCallReminderNumber: this.state.voiceReminderOtherNumber
				? this.state.voiceReminderOtherNumber
				: this.props.patientDetails.mobilePhone,
			mobilePhoneCallRemindersHoursBefore: this.state.voiceReminderLeadTime,

			mobilePhoneTextReminder: this.state.textReminderConsent,
			mobilePhoneTextReminderNumber: this.state.textReminderOtherNumber
				? this.state.textReminderOtherNumber
				: this.state.textReminderNumber,
			mobilePhoneTextRemindersHoursBefore: this.state.textReminderLeadTime,

			reminderEmail: this.state.reminderEmailOther ? this.state.reminderEmailOther : this.props.patientDetails.email,
			emailReminder: this.state.emailReminderConsent,

			emailConfirmation: this.state.confirmationEmailConsent,
			confirmationEmail: this.state.confirmationEmailOther
				? this.state.confirmationEmailOther
				: this.props.patientDetails.email,
			emailRemindersHoursBefore: this.state.emailReminderLeadTime,

			reasonForAppointment: prevAppointment.reasonForVisit,
			idInsuranceState: this.props.reservationDetails.idInsuranceState,
			idInsurance: this.props.reservationDetails.idInsurance,
			clientIdentifier: '',
			reservationId: this.props.reservation.reservationId,
			customFieldValues: customFieldAppointment,
			appointmentAttachments: attachments,
			appointmentTypeId: this.props.reservationDetails.idAppointmentType,
			idServiceSite: this.state.serviceSiteId,
			slotStartDateTime: new Date(this.props.reservationDetails.appointmentStartAt),
			slotEndDateTime: new Date(this.props.reservationDetails.appointmentEndAt),
			managedReferringServiceId: prevAppointment.managedReferringServiceId,
			unmanagedReferringServiceName:
				prevAppointment.unmanagedReferringServiceName !== null &&
					prevAppointment.unmanagedReferringServiceName.trim() !== ''
					? prevAppointment.unmanagedReferringServiceName.trim()
					: null,
			workboxId: this.props.session.workboxId,

			productInstanceConsumerId:
				this.state.selectedConsumerProductInstanceId !== 0
					? parseInt(this.state.selectedConsumerProductInstanceId, 10)
					: null,

			careOrderVisitIdentifier: this.props.activeCareOrder.careOrderVisitIdentifier,
			externalReferralOrderId: this.props.activeCareOrder.externalReferralOrderId,
		};

		this.props.actions
			.rescheduleAppointment(
				this.props.token,
				booking,
				this.props.previousAppointmentReferenceId,
				this.props.rescheduleMethod,
			)
			.then((response) => {
				if (response.error) {
					this.setState({
						error: 'An error occurred while trying to book.',
						isBooking: false,
					});
				} else {
					this.props.history.push(`/appointment/${response.payload.data.referenceId}/details`);
					this.setState({
						isBooking: false,
					});
				}
			})
			.catch((err) => {
				this.setState({
					error: 'An error occurred while trying to book.',
					isBooking: false,
				});
			});
	};

	patientHeaderToggle = () => {
		this.setState({ isPatientHeaderCollapsed: !this.state.isPatientHeaderCollapsed });
	};

	changeAppointmentClick = () => {
		this.setState({ isChangingAppointment: true });
		this.props.actions
			.cancelReservation(this.props.token, this.props.reservation.reservationId)
			.then((response) => {
				if (response.error) {
					this.setState({
						error: 'An error occurred while trying to change appointments',
						isChangingAppointment: false,
					});
				} else {
					this.setState({
						isChangingAppointment: false,
					});
					this.props.routes.routeToAvailabilitySearch();
				}
			})
			.catch((err) => {
				this.setState({
					error: 'An error occurred while trying to change appointments.',
					isChangingAppointment: false,
				});
			});
	};

	resolveConfirmationEmailInstructions = () => {
		let result = 'No email address in patient profile.';

		let { emailConfirmationEnabled, enableConfirmationEmailOptIn, patientDetails } = this.props;
		let isAutomatic = emailConfirmationEnabled && !enableConfirmationEmailOptIn;
		let automaticallyText = isAutomatic ? 'automatically ' : '';

		if (patientDetails.email && patientDetails.email.trim() !== '') {
			result = `Email Appointment Confirmation will ${automaticallyText}be sent to ${patientDetails.email}. Optionally, you may override that email below.`;
		}
		return result;
	};

	onBrowserBackButtonClick = (e) => {
		e.preventDefault();
		this.setState({ isBackButtonModalOpen: true });
	};

	handleReservationExpired = () => {
		this.setState({ isReservationExpired: true });
	}

	render() {
		let reservation = { ...this.props.reservation, ...this.props.reservationDetails };
		let phones = [];
		if (!this.state.isPatientLoading) {
			this.props.patientDetails.homePhone && phones.push({ type: 'Home', number: this.props.patientDetails.homePhone });
			this.props.patientDetails.mobilePhone &&
				phones.push({ type: 'Mobile', number: this.props.patientDetails.mobilePhone });
			this.props.patientDetails.alternatePhone &&
				phones.push({ type: 'Alternate', number: this.props.patientDetails.alternatePhone });
		}

		let isRescheduleFlow = Boolean(this.props.previousAppointmentReferenceId);
		let rescheduleWithSameProvider = Boolean(this.props.rescheduleMethod === SAME_PROVIDER);

		let showEmailReminder =
			this.props.emailReminderEnabled &&
			moment.utc(reservation.appointmentStartAt) >
			moment.utc().add(this.props.notificationConfig.minEmailReminderLeadTime, 'h');
		let showVoiceReminder =
			this.props.voiceReminderEnabled &&
			moment.utc(reservation.appointmentStartAt) >
			moment.utc().add(this.props.notificationConfig.minVoiceReminderLeadTime, 'h');
		let showTextReminder =
			this.props.textReminderEnabled &&
			moment.utc(reservation.appointmentStartAt) >
			moment.utc().add(this.props.notificationConfig.minTextReminderLeadTime, 'h');
		let maxReminderLeadHours = moment(reservation.patientAppointmentDateTime).diff(moment(), 'h');

		let shownCustomFields = this.props.customFields.customFields.filter(
			(field) => !field.isPostBookAppointment, // field.printIt was removed for rd2 as call center should see all fields
		);
		let postBookCustomFields = this.props.customFields.customFields.filter(
			(field) => field.isPostBookAppointment && field.printIt,
		);

		let disableBookButton =
			this.state.isCustomFieldsLoading ||
			this.state.isReservationLoading ||
			this.state.isPreviousDetailsLoading ||
			this.state.isReservationExpired ||
			!this.state.isPageValid;
		let step1Label = 'Step 1';

		let reasonForVisitLabel = this.props.schedulingConfig.reasonForVisit.isRequired
			? `${this.props.schedulingConfig.reasonForVisit.fieldLabel} (required)`
			: this.props.schedulingConfig.reasonForVisit.fieldLabel;

		let attachmentsLabel = this.props.schedulingConfig.enableAddAttachments ? 'Add Attachments' : '';

		let reasonForVisitAndAttachmentsLabel = (
			<h6 style={{ marginBottom: '15px', marginTop: '5px' }}>
				{this.props.schedulingConfig.reasonForVisit.isEnabled && this.props.schedulingConfig.enableAddAttachments
					? `${step1Label}: ${reasonForVisitLabel} & ${attachmentsLabel}`
					: this.props.schedulingConfig.reasonForVisit.isEnabled && !this.props.schedulingConfig.enableAddAttachments
						? `${step1Label}: ${reasonForVisitLabel}`
						: !this.props.schedulingConfig.reasonForVisit.isEnabled && this.props.schedulingConfig.enableAddAttachments
							? `${attachmentsLabel}`
							: ''}
			</h6>
		);

		let patientNotificationsLabel = `${this.props.schedulingConfig.reasonForVisit.isEnabled && !rescheduleWithSameProvider ? 'Step 2: ' : ''
			}${'Configure Patient Notifications'}`;
		let confirmationEmailInstructions = this.resolveConfirmationEmailInstructions();
		let showReferringProviders =
			this.props.referringProviderConfig.useReferringProviders &&
			this.props.reservationDetails &&
			this.props.reservationDetails.requiresReferralToBook;

		let showAgentInstructions =
			this.props.agentInstructions && this.props.agentInstructions.agentReservationInstructions !== '';

		let titleText = !isRescheduleFlow ? 'Schedule An Appointment' : 'Reschedule Appointment';
		let previousAppointmentDetails =
			this.state.previousAppointmentDetails !== null ? this.state.previousAppointmentDetails : {};

		let instances = this.props.consumerProductInstances.map((productInstance) => {
			return {
				id: productInstance.id,
				name: productInstance.name,
			};
		});

		let showReferringProviderSection = !this.state.isReferringProvidersLoading && showReferringProviders;

		return (
			<ErrorBoundary childName="Book Appointment">
				<div className="container-fluid">
					<div>{this.state.error && <Alert color="danger">{this.state.error}</Alert>}</div>
					<ActiveErrors errorLink="/availability/search" errorLinkText="Please select a different slot." />
					<div>
						<YesNoModal
							isOpen={this.state.isBackButtonModalOpen}
							body={DEFAULT_BACK_BUTTON_WARNING_BOOK_APPOINTMENT_VIEW}
							noLabel="No"
							yesLabel="Yes"
							title="Are you sure you want to go back?"
							onNo={() => {
								this.setBrowserHistoryState();
								this.setState({ isBackButtonModalOpen: false });
							}}
							onYes={() => {
								this.setState({ isBackButtonModalOpen: false, isLoading: true });
								this.changeAppointmentClick();
							}}
						/>
					</div>
					<div>
						<PostBookCustomFieldsModal
							customFieldAnswers={this.state.customFieldAnswers}
							customFieldValidation={this.state.customFieldValidation}
							handlePostBookCustomFieldChange={this.handlePostBookCustomFieldChange}
							handlePostBookCustomFieldDateChange={this.handlePostBookCustomFieldDateChange}
							handlePostBookCustomFieldDropdownChange={this.handlePostBookCustomFieldDropdownChange}
							isOpen={this.state.postBookCustomFieldsModalVisible}
							isPostBookCustomFieldsValid={this.state.isPostBookCustomFieldsValid}
							onAccept={this.onAccept}
							onReject={this.onReject}
							postBookCustomFields={postBookCustomFields}
						/>
					</div>
					<Col>
						<Row>
							<Col>
								<h4>{titleText}</h4>
							</Col>
							<Col style={{ textAlign: "right" }}>
								{!this.state.isReservationExpired ? (
									<div className="reservationCountDown">
										Reservation will expire in <span><CountDownTimer countDownExpirationTime={moment.utc(this.props.reservation.reservationExpirationTimeString).local().format()} countDownExpired={this.handleReservationExpired} /></span>
									</div>
								) : (
									<div className="reservationCountDown">
										Reservation expired, Please
										{!this.state.isLoading ? (
											<span className="link" onClick={() => { this.setState({ isLoading: true }); this.changeAppointmentClick(); }}> pick a slot again.</span>
										) : (
											<span> wait <FontAwesomeIcon icon="spinner" spin /></span>
										)}

									</div>
								)}
							</Col>
						</Row>
					</Col>
					<div className="schedule-appointment-content">
						{this.props.patientReferenceId && this.props.patientReferenceId !== '' ? (
							<ErrorBoundary childName="Patient Details">
								<PatientInfoTabbedCollapsable
									isOpen={!this.state.isPatientHeaderCollapsed}
									toggleIsOpen={this.patientHeaderToggle}
								/>
							</ErrorBoundary>
						) : (
							''
						)}
						{this.state.isReservationLoading || this.state.isPreviousDetailsLoading ? (
							<LoadingIndicator loadingMessage="Loading reservation details..." />
						) : !this.state.isRescheduleFlow ? (
							<BookingDetails
								config={this.props.schedulingConfig}
								providerFieldConfig={this.props.providerFieldConfig}
								reservationDetails={this.props.reservationDetails}
							/>
						) : (
							<RescheduleBookingDetails
								config={this.props.schedulingConfig}
								isRescheduleFlow={isRescheduleFlow}
								previousAppointmentDetails={previousAppointmentDetails}
								providerFieldConfig={this.props.providerFieldConfig}
								reservationDetails={this.props.reservationDetails}
							/>
						)}
					</div>
					<hr />
					{showAgentInstructions && (
						<AgentInstructions instructions={this.props.agentInstructions.agentReservationInstructions} />
					)}
					<React.Fragment>
						{this.props.hasMultipleProductInstances && (
							<Row>
								<Col lg="3">
									<ConsumerProductInstance
										consumerProductInstances={instances}
										fieldValidation={this.state.fieldValidation}
										handleDropdownChange={this.handleDropdownChange}
										selectedConsumerProductInstanceId={this.state.selectedConsumerProductInstanceId}
									/>
								</Col>
							</Row>
						)}
						<Col lg="12" className="reasonForVisitAttachments">
							{reasonForVisitAndAttachmentsLabel}
							<Row>
								{this.props.schedulingConfig.reasonForVisit.isEnabled &&
									this.props.schedulingConfig.reasonForVisit.isVisible && (
										<Col lg="4">
											<ReasonForVisit
												config={this.props.schedulingConfig.scheduling}
												reasonForVisit={this.state.reasonForVisit}
												fieldValidation={this.state.fieldValidation}
												handleChange={this.handleChange}
												characterLimit={this.props.schedulingConfig.reasonForVisitCharacterLimit}
											/>
										</Col>
									)}
								{this.props.schedulingConfig.enableAddAttachments && (
									<Attachments
										config={this.props.schedulingConfig}
										onDropRejected={this.onDropRejected}
										onDrop={this.onDrop}
										onCancel={this.onCancel}
										fileError={this.state.fileError}
										files={this.state.files}
										removeFile={this.removeFile}
									/>
								)}
							</Row>
						</Col>
						{showReferringProviderSection && (
							<ReferringProvider
								config={this.props.referringProviderConfig}
								externalReferringServiceName={this.state.externalReferringServiceName}
								fieldValidation={this.state.fieldValidation}
								handleChange={this.handleChange}
								handleDropdownChange={this.handleDropdownChange}
								handleOrgProviderServerTypeAheadSearch={this.handleOrgProviderServerTypeAheadSearch}
								handleServerTypeAheadSearch={this.handleServerTypeAheadSearch}
								internalReferringServiceId={this.state.internalReferringServiceId}
								referringOrganizationProviderId={this.state.referringOrganizationProviderId}
								isComponentValid={
									this.state.formValidation.internalReferringServiceId.valid &&
									this.state.formValidation.externalReferringServiceName.valid
								}
								isServerTypeAheadLoading={this.state.isServerTypeAheadLoading}
								internalProviderList={this.props.referringProviders.referringProviders}
								organizationProviderList={this.props.referringProviders.orgReferringProviders}
							/>
						)}
						{!this.state.isCustomFieldsLoading && this.props.schedulingConfig.enableCustomFields && (
							<CustomFields
								customFields={shownCustomFields}
								customFieldAnswers={this.state.customFieldAnswers}
								handleCustomFieldChange={this.handleCustomFieldChange}
								handleCustomFieldDropdownChange={this.handleCustomFieldDropdownChange}
								handleCustomFieldDateChange={this.handleCustomFieldDateChange}
								customFieldValidation={this.state.customFieldValidation}
								customFieldsConfigInstructions={this.props.schedulingConfig.customFieldsConfigInstructions}
							/>
						)}
					</React.Fragment>
					<PatientNotifications
						collapse={this.state.collapse}
						config={this.props.notificationConfig}
						confirmationEmailConsent={this.state.confirmationEmailConsent}
						confirmationEmailInstructions={confirmationEmailInstructions}
						confirmationEmailOther={this.state.confirmationEmailOther}
						disableTextReminderNumber={this.state.disableTextReminderNumber}
						disableTextReminderOtherNumber={this.state.disableTextReminderOtherNumber}
						disableVoiceReminderNumber={this.state.disableVoiceReminderNumber}
						disableVoiceReminderOtherNumber={this.state.disableVoiceReminderOtherNumber}
						emailReminderConsent={this.state.emailReminderConsent}
						emailReminderLeadTime={this.state.emailReminderLeadTime}
						enableConfirmationEmailInstructions={true}
						enableConfirmationEmailOptIn={this.props.enableConfirmationEmailOptIn}
						enableConfirmationEmailOther={true}
						enableEmailReminderInstructions={true}
						enableSmsReminderOptions={true}
						enableVoiceReminderOptions={true}
						fieldValidation={this.state.fieldValidation}
						formValidation={this.state.formValidation}
						handleChange={this.handleChange}
						handleCheckboxChange={this.handleCheckboxChange}
						handleDropdownChange={this.handleDropdownChange}
						isRescheduleWithSameProvider={rescheduleWithSameProvider}
						maxReminderLeadHours={maxReminderLeadHours}
						otherPhoneNumber={OTHER_PHONE_NUMBER}
						patientConfirmationEmailDisclaimer={this.props.patientConfirmationEmailDisclaimer}
						patientDetails={this.props.patientDetails}
						patientReminderEmailDisclaimer={this.props.patientReminderEmailDisclaimer}
						patientReminderSmsDisclaimer={this.props.patientReminderSmsDisclaimer}
						patientReminderVoiceDisclaimer={this.props.patientReminderVoiceDisclaimer}
						phones={phones}
						reminderEmailOther={this.state.reminderEmailOther}
						reservation={reservation}
						reservationDetails={this.props.reservationDetails}
						selectedLanguage={this.state.selectedLanguage}
						showEmailConfirmation={this.props.emailConfirmationEnabled}
						showEmailReminder={showEmailReminder}
						showTextReminder={showTextReminder}
						showTopHorizontalDivider={true}
						showVoiceReminder={showVoiceReminder}
						textReminderConsent={this.state.textReminderConsent}
						textReminderLeadTime={this.state.textReminderLeadTime}
						textReminderNumber={this.state.textReminderNumber}
						textReminderOtherNumber={this.state.textReminderOtherNumber}
						title={patientNotificationsLabel}
						toggle={this.togglePatientNotifications}
						voiceReminderConsent={this.state.voiceReminderConsent}
						voiceReminderLeadTime={this.state.voiceReminderLeadTime}
						voiceReminderNumber={this.state.voiceReminderNumber}
						voiceReminderOtherNumber={this.state.voiceReminderOtherNumber}
					/>
					{this.props.isWaitlistEnabled && (
						<>
							<hr />
							<Row>
								<Col lg="12">
									<EarlierAppointmentRequestSection
										name="earlierAppointmentRequestSection"
										value={this.state.isEarlierAppointmentRequested}
										requestEarlierAppointmentFieldConfig={this.props.requestEarlierAppointmentFieldConfig}
										onChange={this.handleRequestEarlierAppointmentChange}
									/>
								</Col>
							</Row>
						</>
					)}
					<Row>
						<Col lg="12">
							<div style={{ marginTop: '15px', marginLeft: '15px' }}>
								{!this.state.isPageValid && (
									<div>
										<span style={{ color: 'red' }}>* Form is invalid, please review validation messages.</span>
									</div>
								)}
								{!isRescheduleFlow ? (
									<Button
										onClick={() => {
											postBookCustomFields.length > 0 ? this.openPostBookModal() : this.bookAppointment();
										}}
										disabled={disableBookButton || this.state.isBooking}
										style={{ marginRight: '5px' }}
										type="submit"
										color="primary"
									>
										{this.state.isBooking ? (
											<span>
												<FontAwesomeIcon icon="spinner" spin /> Scheduling...
											</span>
										) : (
											<span>Schedule Appointment</span>
										)}
									</Button>
								) : (
									<Button
										onClick={() => {
											rescheduleWithSameProvider
												? this.rescheduleAppointment()
												: postBookCustomFields.length > 0
													? this.openPostBookModal()
													: this.bookAppointment();
										}}
										disabled={disableBookButton || this.state.isBooking}
										style={{ marginRight: '5px' }}
										type="submit"
										color="primary"
									>
										{this.state.isBooking ? (
											<span>
												<FontAwesomeIcon icon="spinner" spin /> Scheduling...
											</span>
										) : (
											<span>Confirm Reschedule</span>
										)}
									</Button>
								)}
								<Button
									onClick={() => {
										this.changeAppointmentClick();
									}}
									disabled={this.state.isChangingAppointment}
								>
									{this.state.isChangingAppointment ? (
										<span>
											<FontAwesomeIcon icon="spinner" spin /> Changing...
										</span>
									) : (
										<span>Change Appointment</span>
									)}
								</Button>
								{!this.state.isReservationExpired ? (
									<div className="reservationCountDown" style={{ marginLeft: "5px" }}>
										Reservation will expire in <span><CountDownTimer countDownExpirationTime={moment.utc(this.props.reservation.reservationExpirationTimeString).local().format()} countDownExpired={this.handleReservationExpired} /></span>
									</div>
								) : (
									<div className="reservationCountDown" style={{ marginLeft: "5px" }}>
										<span><FontAwesomeIcon icon={faChevronLeft} /> Reservation expired, Please pick a slot again.</span>
									</div>
								)}
							</div>
						</Col>
					</Row>
				</div>
			</ErrorBoundary>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrder: state.careOrder,
		agentInstructions: state.appointment.agentInstructions,
		appointmentId: state.appointment.details?.referenceId,
		availability: state.availability,
		auth: state.auth,
		careOrderConfig: state.config.careOrder,
		careOrderPanelConfig: state.config.careOrderPanel,
		consumerProductInstances: state.config.consumerProductInstances,
		customFields: state.customFields,
		emailConfirmationEnabled: state.config.notification.emailConfirmationEnabled,
		emailReminderEnabled: state.config.notification.emailReminderEnabled,
		enableConfirmationEmailOptIn: state.config.notification.showPatientConfirmationEmailOptIn,
		hasMultipleProductInstances: false, //state.config.consumerProductInstances.filter(pi => pi.id > 0).length > 1,
		isGroupNumberActive: state.config.system.isGroupNumberActive,
		isLoading: state.ajaxStatus.callsInProgressCount > 0,
		isMemberIdActive: state.config.system.isMemberIdActive,
		memberIdFieldLabel: state.config.system.memberIdFieldLabel,
		notificationConfig: state.config.notification,
		orderManagementConfig: state.config.orderManagement,
		orderScheduling: state.config.orderScheduling,
		patientConfirmationEmailDisclaimer: state.config.notification.patientConfirmationEmailDisclaimer,
		patientConfig: state.config.patient,
		patientDetails: state.activePatient.details,
		patientReferenceId: state.activePatient.details.referenceId,
		patientReminderEmailDisclaimer: state.config.notification.patientReminderEmailDisclaimer,
		patientReminderSmsDisclaimer: state.config.notification.patientReminderSmsDisclaimer,
		patientReminderVoiceDisclaimer: state.config.notification.patientReminderVoiceDisclaimer,
		previousAppointmentReferenceId: state.appointment.cancelRescheduleInfo.previousAppointmentReferenceId,
		providerFieldConfig: state.config.provider,
		referralSystemId: state.auth.referralSystemId,
		referringProviderConfig: state.config.scheduling.referringProvider,
		referringProviders: state.referringProviders,
		rescheduleMethod: state.appointment.cancelRescheduleInfo.rescheduleMethod,
		reservation: state.appointment.reservation,
		reservationDetails: state.appointment.reservationDetails,
		schedulingConfig: state.config.scheduling,
		session: state.session,
		symptomDetails: state.appointment.symptomDetails,
		systemConfig: state.config.system,
		textReminderEnabled: state.config.notification.textReminderEnabled,
		token: state.auth.token,
		voiceReminderEnabled: state.config.notification.voiceReminderEnabled,
		isWaitlistEnabled: state.config.scheduling.enableWaitlist,
		requestEarlierAppointmentFieldConfig: state.config.scheduling.requestEarlierAppointmentFieldConfig,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(appointmentActions, dispatch),
		orderManagementActions: bindActionCreators(orderManagementActions, dispatch),
		patientActions: bindActionCreators(patientActions, dispatch),
		providerActions: bindActionCreators(providerActions, dispatch),
		rd2RefactorActions: bindActionCreators(rd2RefactorActions, dispatch),
		routes: bindActionCreators({ routeToAvailabilitySearch }, dispatch),
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BookAppointmentView);
