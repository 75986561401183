//Third Party Dependencies
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';

//MHD General Purpose libraries
import { SectionHeading } from '../../components/common/standardComponents';
import LoadingIndicator from '../../components/loadingIndicator';
import ActiveErrors from '../../components/common/activeErrors';
import ErrorBoundary from '../../components/common/errorBoundary';

//Specific Business and UI components
import * as routes from '../../routes';
import * as appointmentActions from '../../actions/appointmentActions';
import * as rd2RefactorActions from '../../actions/rd2RefactorActions';
import AppointmentEditForm from '../../components/appointment/appointmentEditForm';
import BookingDetails from '../../components/appointment/bookingDetails';

export class AppointmentEditView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			details: { ...props.details },
			error: '',
			isDetailsLoaded: false,
			isSaving: false,
			referenceId: '',
			reasonForVisit: '',
			providerResponse: '',
		};
	}

	appointmentDetails = (referenceId) => {
		this.setState({ isDetailsLoaded: false }, () => {
			this.props.actions.appointment.getAppointmentByReferenceId(referenceId)
				.then((response) => {
					if (response.error) {
						this.setState({ error: 'An error occurred while trying to get the appointment details.' });
					}
					this.setState({
						isDetailsLoaded: true,
						details: { ...this.props.details },
					});
				})
				.catch((err) => {
					this.setState({ error: "Error" });
				});
		});
	}

	componentDidMount() {
		const params = this.props.match.params;
		const isDetailsAlreadyActive = (this.props.details.referenceId === params.referenceId);
		if (isDetailsAlreadyActive) {
			const isDetailsAlreadyLoaded = isDetailsAlreadyActive && (this.props.details.referenceId !== undefined);
			if (isDetailsAlreadyLoaded) {
				this.setState({
					isDetailsLoaded: isDetailsAlreadyLoaded,
					referenceId: this.props.details.referenceId ? this.props.details.referenceId : '',
					reasonForVisit: this.props.details.reasonForVisit ? this.props.details.reasonForVisit : '',
					providerResponse: this.props.details.providerResponse ? this.props.details.providerResponse : '',
				});
			}
		} else {
			this.appointmentDetails(params.referenceId);
		}
	}

	handleCancel = (e) => {
		e.preventDefault();
		if (this.props.history.length > 0) {
			this.props.history.goBack();
		}
		else {
			this.push(routes.appointmentDetailsUrl(this.props.appointmentReferenceId));
		};
	}

	handleChange = (e) => {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	handleSave = (e) => {
		e.preventDefault();
		this.setState({ isSaving: true });

		let editRequest = {
			referenceId: this.props.details.referenceId,
			reasonForVisit: this.state.reasonForVisit,
			providerResponse: this.state.providerResponse,
			doUpdateReasonForVisit: this.state.reasonForVisit !== this.props.details.reasonForVisit ? true : false,
			doUpdateProviderResponse: this.state.providerResponse !== this.props.details.providerResponse ? true : false,
		};

		this.props.actions.appointment.updateAppointment(editRequest)
			.then((response) => {
				if (response.error) {
					this.setState({
						error: "An error occurred while trying to save appointment notes.",
						isSaving: false,
					});
				} else {
					this.props.dispatch(routes.appointmentDetails(this.props.details.referenceId));
				}
			})
			.catch((err) => {
				this.setState({
					error: "An error occurred while trying to save appointment notes.",
					isSaving: false,
				});
			});
	}


	render() {
		return (
			<div>
				{this.state.isLoading || this.state.isSaving ?
					<LoadingIndicator loadingMessage={this.state.isLoading ? "Loading appointment. Please wait." : this.state.isSaving ? "Saving changes. Please wait." : "Please Wait"} />
					:
					<ErrorBoundary childName="Edit Appointment">
						<ActiveErrors />
						<SectionHeading label="Edit Appointment" />
						<div className="form-area">
							<BookingDetails
								config={this.props.schedulingConfig}
								providerFieldConfig={this.props.providerFieldConfig}
								reservationDetails={this.props.details}
							/>
						</div>
						<Row className="g-0">
							<div className="col-lg-12">
								<AppointmentEditForm
									config={this.props.schedulingConfig}
									details={this.state.details}
									reasonForVisit={this.state.reasonForVisit}
									providerResponse={this.state.providerResponse}
									isSaving={this.state.isSaving}
									onChange={this.handleChange}
									onCancel={this.handleCancel}
									onSave={this.handleSave}
									enableEditProviderResponse={this.props.appointmentManagementConfig.enableEditProviderResponse}
									enableEditReasonForVisit={this.props.appointmentManagementConfig.enableEditReasonForVisit}

								/>
							</div>
						</Row>
					</ErrorBoundary>
				}
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activePatient: state.activePatient,
		appointmentManagementConfig: state.config.appointmentManagement,
		details: state.appointment.details,
		providerFieldConfig: state.config.provider,
		schedulingConfig: state.config.scheduling,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			appointment: bindActionCreators(appointmentActions, dispatch),
			rd2Refactor: bindActionCreators(rd2RefactorActions, dispatch),
		},
		dispatch
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentEditView);
