import * as types from '../actions/actionTypes';
import { AVAILABILITY_SEARCH_CRITERIA } from '../constants/availabilitySearchCriteria';
import { APPOINTMENT_CRITERIA } from '../constants/appointmentCriteria';
import { getAppInsights } from '../components/common/telemetryService';
import DecisionSupportSubpoints from 'constants/decisionSupportSubpoints';

const initialState = {
	appointmentCriteria: {},
	availabilitySearchCriteria: {},
	customFields: [],
	sessionQuestionsAndAnswersInfo: []
};

// TODO - Consolidate all care order reducer logic into this reducer in future story
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.PROCESS_DECISION_SUPPORT_SUBPOINTS: {
			let asc = {};
			let appt = {};
			let customFields = [];

			action.subpoints.map((subpoint) => {
				switch (subpoint.key) {
					case DecisionSupportSubpoints.npi:
						if (subpoint.value) {
							let npiList = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.serviceNpiList] = npiList.map((npi) => {
								return npi.trim();
							});
						}
						break;
					case DecisionSupportSubpoints.siteid:
						if (subpoint.value) {
							let siteList = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.siteIdList] = siteList.map((site) => {
								return (site * 1);
							});
						}
						break;
					case DecisionSupportSubpoints.servicetypeid:
						if (subpoint.value) {
							asc[AVAILABILITY_SEARCH_CRITERIA.serviceTypeId] = (subpoint.value * 1);
						}
						break;
					case DecisionSupportSubpoints.asssettags:
						if (subpoint.value) {
							let assettags = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.assetTags] = assettags.map((tag) => {
								return tag.trim();
							});
						}
						break;
					case DecisionSupportSubpoints.additionalcalendarids:
						if (subpoint.value) {
							let calendarList = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.additionalCalendarList] = calendarList.map((calendar) => {
								return (calendar * 1);
							});
						}
						break;
					case DecisionSupportSubpoints.appointmenttypeid:
						if (subpoint.value) {
							let appointmentTypeList = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.appointmentTypeIdList] = appointmentTypeList.map((appointmentType) => {
								return (appointmentType * 1);
							});
						}
						break;
					case DecisionSupportSubpoints.zipcode:
						asc[AVAILABILITY_SEARCH_CRITERIA.zipCode] = subpoint.value;
						break;
					case DecisionSupportSubpoints.startdate:
						asc[AVAILABILITY_SEARCH_CRITERIA.startDate] = subpoint.value;
						break;
					case DecisionSupportSubpoints.enddate:
						asc[AVAILABILITY_SEARCH_CRITERIA.endDate] = subpoint.value;
						break;
					case DecisionSupportSubpoints.minstarttime:
						asc[AVAILABILITY_SEARCH_CRITERIA.minStartTime] = subpoint.value;
						break;
					case DecisionSupportSubpoints.maxstarttime:
						asc[AVAILABILITY_SEARCH_CRITERIA.maxStartTime] = subpoint.value;
						break;
					case DecisionSupportSubpoints.selectedprovidernpi:
						if (subpoint.value) {
							let npiList = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.selectedProviderNpiList] = npiList.map((npi) => {
								return npi.trim();
							})
						}
						break;
					case DecisionSupportSubpoints.selectedprovidercalendarid:
						if (subpoint.value) {
							let calList = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.selectedProviderCalendarList] = calList.map((id) => {
								return id.trim();
							})
						}
						break;
					case DecisionSupportSubpoints.selectedsiteid:
						if (subpoint.value) {
							let siteIdList = subpoint.value.split(",");
							asc[AVAILABILITY_SEARCH_CRITERIA.selectedSiteIdList] = siteIdList.map((id) => {
								return id.trim();
							})
						}
						break;
					case DecisionSupportSubpoints.reasonforvisit:
						let appInsights = getAppInsights();
						let loadedFrom = action.endedInGuidedResponse ? "GuidedResponse" : "AppliedPathways";
						appInsights.trackTrace({ message: `decisionSupportReducer: reasonforvisit value of '${subpoint.value}' was loaded from ${loadedFrom}.` });
						appt[APPOINTMENT_CRITERIA.reasonForVisit] = subpoint.value;
						break;
					case DecisionSupportSubpoints.agentavailabilityinstructions:
						appt[APPOINTMENT_CRITERIA.agentAvailabilityInstructions] = subpoint.value;
						break;
					case DecisionSupportSubpoints.agentreservationinstructions:
						appt[APPOINTMENT_CRITERIA.agentReservationInstructions] = subpoint.value;
						break;
					case DecisionSupportSubpoints.agentpostbookinginstructions:
						appt[APPOINTMENT_CRITERIA.agentPostBookingInstructions] = subpoint.value;
						break;

					// TODO: Do we need PatientType?
					default:
						customFields.push(subpoint);
						break;
				}
				return "";
			});

			return {
				...state,
				appointmentCriteria: appt,
				availabilitySearchCriteria: asc,
				customFields: customFields,
				endedInGuidedResponse: action.endedInGuidedResponse
			};
		}
		case types.APPOINTMENT_DETAILS_SUCCESS: {
			return {
				...state,
				appointmentCriteria: {},
				availabilitySearchCriteria: {},
				customFields: [],
			};
		}
		case types.CLEAR_BOOKING_CONTEXT: {
			return initialState;
		}
		case types.GET_SESSION_QUESTIONS_AND_ANSWERS_SUCCESS: {
			return {
				...state,
				sessionQuestionsAndAnswersInfo: action.payload.data
			};
		}
		case types.CLEAR_SESSION_QUESTIONS_AND_ANSWERS:
		case types.GET_SESSION_QUESTIONS_AND_ANSWERS:
		case types.GET_SESSION_QUESTIONS_AND_ANSWERS_ERROR: {
			return {
				...state,
				sessionQuestionsAndAnswersInfo: initialState.sessionQuestionsAndAnswersInfo
			};
		}
		default: {
			return state;
		}
	}
}
