import * as types from '../actions/actionTypes';

const initialState = {
	customFieldAnswers: [],
	customFields: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.CUSTOM_FIELDS_SUCCESS:
			return {
				...state,
				customFields: action.payload.data,
			};

		case types.SET_INITIAL_CUSTOM_FIELD_ANSWERS:
			let customFieldAnswers = mergeCustomFieldAnswers(action.careOrderCustomFields, action.decisionSupportCustomFields)

			return {
				...state,
				customFieldAnswers: customFieldAnswers,
			};

		case types.APPOINTMENT_RESERVATION_CANCEL_SUCCESS:
			return {
				...state,
				customFields: [],
			};

		case types.APPOINTMENT_BOOK_SUCCESS:
		case types.APPOINTMENT_DETAILS_SUCCESS:
		case types.APPOINTMENT_RESCHEDULE_SUCCESS:
		case types.CLEAR_BOOKING_CONTEXT:
			return { ...initialState };

		default:
			return state;
	}
}

function mergeCustomFieldAnswers(careOrderCustomFields, decisionSupportCustomFields) {

	let customFieldAnswers = careOrderCustomFields.map(x => {
		return { key: x.fieldName, value: x.fieldValue };
	})

	decisionSupportCustomFields.forEach(x => {
		let currentCustomField = customFieldAnswers.find(y => y.key.toLowerCase().trim() === x.key.toLowerCase().trim());
		if (currentCustomField) {
			currentCustomField.value = x.value;
		} else {
			customFieldAnswers.push(x);
		}
	})

	return customFieldAnswers;
}
