/**
 * @format
 */

import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { showModal } from '../actions/modalActions';
import { MODAL_TYPE_NOTIFICATION } from '../constants/modalTypes';

import PrivacyPolicy from './legal/privacyPolicy';
import TermsOfUse from './legal/termsOfUse';
import ContactUs from './legal/contactUs';
import { PhoneNumber } from './common/phoneNumber';

import config from '../config';
import './footer.css';

import { Col, Row } from 'reactstrap';

export class Footer extends React.Component {
	constructor() {
		super();

		var today = new Date();
		var year = today.getFullYear();

		this.state = {
			year: year,
		};
	}

	showTermsOfUse = (event) => {
		event.preventDefault();
		this.props.showModal(MODAL_TYPE_NOTIFICATION, {
			title: 'Terms of Use',
			children: <TermsOfUse />,
			contentClassName: 'scrollable',
			size: 'lg',
		});
	};

	showContactUs = (event) => {
		event.preventDefault();
		this.props.showModal(MODAL_TYPE_NOTIFICATION, {
			title: 'Contact Us',
			children: <ContactUs />,
		});
	};

	render() {
		let phone = this.props.instance.supportPhoneOverride ? (
			<PhoneNumber value={this.props.instance.supportPhoneOverride} />
		) : (
			<PhoneNumber value={'(629) 777-5552'} />
		);

		return (
			<Row className="footer2">
				<Col>
					<span>
						© {this.state.year} <a href="http://www.myhealthdirect.com">MyHealthDirect</a>
					</span>
					<span className="footerDivider2"> | </span>
					<PrivacyPolicy />
					<span className="footerDivider2"> | </span>
					<Link to="/terms-of-use" onClick={this.showTermsOfUse}>
						Terms of Use
					</Link>
				</Col>
				<Col className="text-end">
					{(this.props.instance.showSupportEmail && this.props.instance.supportEmailOverride) && (
						<span>
							<a href={`mailto:${this.props.instance.supportEmailOverride}`}>Email Support</a>
							<span className="footerDivider2"> | </span>
						</span>
					)}
					{this.props.instance.showSupportPhone && (
						<span>
							<span>
								<span style={{ marginRight: '5px' }}>
									<FontAwesomeIcon icon="phone" flip="horizontal" />
								</span>
								{phone}
							</span>
							<span className="footerDivider2"> | </span>
						</span>
					)}
					<span>{config.version}</span>
				</Col>
			</Row>

			// <div className="navbar-dark navbar footer row">
			// 	<div className="col">
			// 		<span>© {this.state.year} <a href="http://www.myhealthdirect.com">MyHealthDirect</a></span>
			// 		<span className="footerDivider"> | </span>
			// 		<Link to="/privacy-policy" onClick={this.showPrivacyPolicy}>Privacy Policy</Link>
			// 		<span className="footerDivider"> | </span>
			// 		<Link to="/terms-of-use" onClick={this.showTermsOfUse}>Terms of Use</Link>
			// 	</div>
			// 	<div className="col text-right">
			// 		<a href="mailto:support@myhealthdirect.com">Email MHD's Support</a>
			// 		<span className="footerDivider"> | </span>
			// 		<span><span style={{marginRight:"5px"}}><FontAwesomeIcon icon="phone" flip="horizontal" /></span>(629) 777-5552</span>
			// 		<span className="footerDivider"> | </span>
			// 		<span>{config.version}</span>
			// 	</div>
			// </div>
		);
	}
}

export default connect(null, { showModal })(Footer);
