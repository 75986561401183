import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button, Tooltip } from 'reactstrap';
import dayjs from 'dayjs';

import '../css/patientHistorySummary.css';

const PatientHistorySummary = (
    {
        setActiveTab,
        activeTab
    }: {
        setActiveTab: any,
        activeTab?: any
    }
) => {
    const patientConfig = useSelector((state: any) => state.config.patient);
    const appointmentHistoryRecords = useSelector((state: any) => state.activePatient.history);
    const careOrders = useSelector((state: any) => state.activePatient.careOrderHistory);
    const openWorkflows = useSelector((state: any) => state.activePatient.openWorkflows);

    const [upcomingAppointmentsCount, setUpcomingAppointmentsCount] = useState(0);
    const [recentlyBookedCount, setRecentlyBookedCount] = useState(0);
    const [openCareOrdersCount, setOpenCareOrdersCount] = useState(0);
    const [openWorkflowsCount, setOpenWorkflowsCount] = useState(0);

    const openCareOrders = careOrders?.filter((careOrder: any) =>
        (careOrder.statusCode !== 'C')
        && (!careOrder.expirationDate || dayjs(careOrder.expirationDate) >= dayjs())
    );

    const recentlyBookedAppointments = appointmentHistoryRecords?.filter((appointment: any) =>
        dayjs(appointment.createDateTime) >= dayjs().subtract(7, 'day')
        && appointment.cancellationDateTime === null
    );

    const upcomingAppointments = appointmentHistoryRecords?.filter((appointment: any) =>
        dayjs(appointment.appointmentDate) >= dayjs()
        && appointment.cancellationDateTime === null
    );

    const unbookedWorkflows = openWorkflows?.filter((workflow: any) => !workflow.appointmentDate)

    useEffect(() => {
        setOpenCareOrdersCount(openCareOrders?.length);
        setUpcomingAppointmentsCount(upcomingAppointments?.length);
        setOpenWorkflowsCount(unbookedWorkflows?.length);
        setRecentlyBookedCount(recentlyBookedAppointments?.length);
    }, [openCareOrders, upcomingAppointments, unbookedWorkflows, recentlyBookedAppointments]);

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div>
            <div className="col-lg-12">
                <Row className="patientHistorySummaryRow">
                    {patientConfig.isPatientHistoryEnabled &&
                        <Col className="patientHistorySummaryCol">
                            <p className="summary-label">UPCOMING APPOINTMENTS</p>
                            <Row>
                                <Col className="countCol" lg="2" xxl="1"><h3>{upcomingAppointmentsCount}</h3></Col>
                                <Col className="viewBtnCol">
                                    <Button
                                        className="btnView"
                                        onClick={() => setActiveTab({ name: "upcomingAppointment", reloadTabContent: true })}
                                    >
                                        View
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    }
                    {patientConfig.isPatientHistoryEnabled &&
                        <>
                            <Tooltip
                                placement="top-start"
                                isOpen={tooltipOpen}
                                target="recentlyBookedSummaryCol"
                                toggle={toggle}
                            >
                                Booked in last 7 days
                            </Tooltip>
                            <Col className="patientHistorySummaryCol" id="recentlyBookedSummaryCol">
                                <p className="summary-label">RECENTLY BOOKED</p>
                                <Row>
                                    <Col className="countCol" lg="2" xxl="1"><h3>{recentlyBookedCount}</h3></Col>
                                    <Col className="viewBtnCol">
                                        <Button
                                            className="btnView"
                                            onClick={() => setActiveTab({ name: "appointmentHistory", reloadTabContent: true })}
                                        >
                                            View
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    }
                    {patientConfig.isPatientCareOrderHistoryEnabled &&
                        <Col className="patientHistorySummaryCol">
                            <p className="summary-label">OPEN CARE ORDERS</p>
                            <Row>
                                <Col className="countCol" xl="2" xxl="1"><h3>{openCareOrdersCount}</h3></Col>
                                <Col className="viewBtnCol">
                                    <Button
                                        className="btnView"
                                        onClick={() => setActiveTab({ name: "careOrderHistory", reloadTabContent: true })}
                                    >
                                        View
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    }
                    {patientConfig.isDecisionSupportWorkflowHistoryEnabled &&
                        <Col className="patientHistorySummaryCol">
                            <p className="summary-label">OPEN WORKFLOWS</p>
                            <Row>
                                <Col className="countCol" lg="2" xxl="1"><h3>{openWorkflowsCount}</h3></Col>
                                <Col className="viewBtnCol">
                                    <Button
                                        className="btnView"
                                        onClick={() => setActiveTab({ name: "decisionSupportWorkflowHistory", reloadTabContent: true })}
                                    >
                                        View
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
                <br />
            </div>
        </div>
    )
}

export default PatientHistorySummary;