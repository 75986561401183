import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "components/dataGrid/dataGrid";
import dayjs from "dayjs";
import { generateCorrelationKey, startAvailabilitySearch } from "actions/rd2RefactorActions";
import { fetchDecisionSupportSessionOutput } from "actions/sessionActions";
import { processDecisionSupportSubpoints } from "actions/decisionSupportActions";
import { useLocation } from "react-router";
import { flushAvailabilityAndScanResults, searchCalendarsThunk } from "features/searchAsync/availabilitySlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { InternalConfirmOrCancelModal } from "./patientAppointmentHistoryGrid";
import DecisionSupportSubpoints from "constants/decisionSupportSubpoints";
import * as routes from '../../../routes';
import ReadonlyHtml from "components/common/readonlyHtml";

const DATE_FORMAT = 'MM/DD/YY hh:mm A';

const INCLUDED_SUBPOINTS = [
    DecisionSupportSubpoints.appointmenttypeid,
    DecisionSupportSubpoints.selectedprovidernpi,
    DecisionSupportSubpoints.servicetypeid,
];

const MAX_INCLUDED_SUBPOITNS = 2;

const PatientOpenWorkflowsGrid = (
    {
        openWorkflows,
        showLoseProgressWarning,
        showBookButton,
        activeTab,
        toggleIsPatientSummaryPanelOpen
    }: {
        openWorkflows: any,
        showLoseProgressWarning: boolean,
        showBookButton: boolean,
        activeTab: any,
        toggleIsPatientSummaryPanelOpen: Function,
    }
) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const openWorkflowPanelConfig = useSelector((state: any) => state.config.openWorkflowPanel);

    const [workflowRecordPendingAction, setWorkflowRecordPendingAction] = useState<any | null>(null);
    const [showConfirmBookModal, setShowConfirmBookModal] = useState(false);
    const [isLoadingBookOpenWorkflow, setIsLoadingBookOpenWorkflow] = useState(false);
    const [showQuestionAndAnswersModal, setShowQuestionAndAnswersModal] = useState(false);
    const [questionsAndAnswers, setQuestionsAndAnswers] = useState<any | null>(null);

    const handleConfirmBookWorkflow = (openWorkflow: any) => {
        setIsLoadingBookOpenWorkflow(true);

        dispatch(
            async (dispatch: any, getState: any) => {
                await dispatch(generateCorrelationKey());
                await dispatch(fetchDecisionSupportSessionOutput(openWorkflow.decisionSupportSessionId));
                let state = getState()
                await dispatch(processDecisionSupportSubpoints(state.session.decisionSupportSubpoints, true));
                state = getState();
                setIsLoadingBookOpenWorkflow(false);
                setWorkflowRecordPendingAction(null);
                dispatch(startAvailabilitySearch(
                    state.careOrder,
                    state.decisionSupport,
                    state.config.availabilitySearch,
                    state.activePatient.details,
                ))

                if (location.pathname === "/availability/search") {
                    dispatch(flushAvailabilityAndScanResults())
                    dispatch(searchCalendarsThunk())
                    toggleIsPatientSummaryPanelOpen(false);
                }
            }
        )
    }

    const handleGoToAppointmentDetails = (referenceId: any) => dispatch(routes.appointmentDetails(referenceId));

    const handleShowQuestionsAndAnswersModal = (questionAndAnswers: any) => {
        setQuestionsAndAnswers(questionAndAnswers);
        setShowQuestionAndAnswersModal(true);
    }
    const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);
    useEffect(() => {
        if (activeTab?.reloadTabContent) {
            setGridKey(`grid-key-${Math.random()}`);
        }
    }, [activeTab])

    const formatSubpointOutputValue = (outputValue: any) => {
        const splitValues = outputValue?.split(',') || [];
        let formattedOutputValue;

        if (splitValues.length > 5) {
            const firstFiveValues = splitValues.slice(0, 5);
            formattedOutputValue = [...firstFiveValues, '...'];
        } else {
            formattedOutputValue = splitValues;
        }

        const outputString = formattedOutputValue.join(', ');

        return outputString;
    }

    const columnDefs = [
        {
            field: "bookButton",
            headerName: "",
            hide: !showBookButton,
            sortable: false,
            cellRenderer: (r: any) => {
                return <div className='button-center'>
                    <Button
                        className="bookButton"
                        data-toggle={null}
                        data-placement="top"
                        title={''}
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setWorkflowRecordPendingAction(r.data);

                            if (showLoseProgressWarning) {
                                setShowConfirmBookModal(true);
                            } else {
                                handleConfirmBookWorkflow(r.data);
                            }
                        }
                        }
                        disabled={!!workflowRecordPendingAction || !!isLoadingBookOpenWorkflow}
                    >
                        {r.data.decisionSupportSessionId === workflowRecordPendingAction?.decisionSupportSessionId && isLoadingBookOpenWorkflow ? (
                            <span>
                                <FontAwesomeIcon icon="spinner" spin /> Booking...
                            </span>
                        ) : (
                            <span>Book</span>
                        )}
                    </Button>
                </div>
            }
        },
        {
            field: 'flowEndTime',
            headerName: openWorkflowPanelConfig?.completionDate.fieldLabel,
            hide: !openWorkflowPanelConfig?.completionDate.isVisible,
            cellRenderer: (r: any) => <span>{r.data.flowEndTime ? dayjs(r.data.flowEndTime).format(DATE_FORMAT) : '-'}</span>
        },
        {
            field: 'createdByUserDisplayName',
            headerName: openWorkflowPanelConfig?.createdBy.fieldLabel,
            hide: !openWorkflowPanelConfig?.createdBy.isVisible,
        },
        {
            field: 'productInstanceName',
            headerName: openWorkflowPanelConfig?.productInstance.fieldLabel,
            hide: !openWorkflowPanelConfig?.productInstance.isVisible,
        },
        {
            field: 'appointmentDate',
            headerName: openWorkflowPanelConfig?.appointmentDate.fieldLabel,
            hide: !openWorkflowPanelConfig?.appointmentDate.isVisible,
            cellRenderer: (r: any) => <span>
                {r.data.appointmentDate ?
                    < a href='#AppointmentDetails' onClick={(e) => { e.preventDefault(); handleGoToAppointmentDetails(r.data.appointmentReferenceId) }}>
                        {r.data.appointmentDate ? dayjs(r.data.appointmentDate).format(DATE_FORMAT) + ` ${r.data.appointmentDateTimeZoneString}` : '-'}
                    </a >
                    : '-'
                }
            </span >,
        },
        {
            field: 'questionsAndAnswers',
            headerName: openWorkflowPanelConfig?.questionsAndAnswers.fieldLabel,
            hide: !openWorkflowPanelConfig?.questionsAndAnswers.isVisible,
            cellRenderer: (r: any) => <span>
                < a href='#AppointmentDetails' onClick={(e) => { e.preventDefault(); handleShowQuestionsAndAnswersModal(r.data.questionAndAnswers) }}>
                    Questions and Answers
                </a >
            </span >,
        },
        {
            field: "outputs",
            // per Keith do not show this
            hide: true,
            sortable: false,
            autoHeight: true,
            headerName: "Subpoints",
            cellRenderer: (r: any) => {
                return (
                    <ul
                        style={{
                            listStyleType: 'none',
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        {
                            r.data.outputs
                                .filter((subpoint: any) => INCLUDED_SUBPOINTS.includes(subpoint.fieldName?.toLowerCase()))
                                .sort((a: any, b: any) => {
                                    return (INCLUDED_SUBPOINTS.indexOf(a?.fieldName) - INCLUDED_SUBPOINTS.indexOf(b?.fieldName))
                                })
                                .slice(0, MAX_INCLUDED_SUBPOITNS)
                                .map((subpoint: any, index: any, array: any) => {
                                    const hasANext = index < array.length - 1;

                                    return (
                                        <li
                                            key={index}
                                            style={{
                                                lineHeight: "normal",
                                                marginTop: 5,
                                                marginBottom: hasANext ? 0 : 5,
                                            }}
                                        >
                                            <span>
                                                {`${subpoint.fieldName}: ${formatSubpointOutputValue(subpoint.outputValue)}`}
                                            </span>
                                        </li>
                                    )
                                }
                                )
                        }
                    </ul>
                )
            }

        }
    ];

    const sortedOpenWorkflows = useMemo(() => {
        return [...openWorkflows].sort((a: any, b: any) => {
            const dateA = new Date(a.flowEndTime);
            const dateB = new Date(b.flowEndTime);
            return +dateB - +dateA;
        });
    }, [openWorkflows]);

    return (
        <>
            <div>
                <DataGrid
                    autoHeight={true}
                    columnDefs={columnDefs}
                    rowSelection='single'
                    data={sortedOpenWorkflows}
                    getRowClass={(r: any) => r.data.isCancelledAppointment ? 'cancelled-row' : null}
                    suppressRowClickSelection={true}
                    onRowClick={(e: any) => null}
                    noRowsMessage="No open workflows found."
                    customGridSearch={undefined}
                    gridHeading={undefined}
                    gridKey={gridKey}
                    getRowHeight={undefined}
                    defaultPageSize={undefined}
                    editable={undefined}
                    enableColumnFilters={undefined}
                    headerHeight={undefined}
                    setSelectedRows={undefined}
                    serverSideDatasource={undefined}
                    getRowStyle={undefined}
                />
            </div>
            {showConfirmBookModal &&
                <InternalConfirmOrCancelModal
                    onConfirm={() => {
                        handleConfirmBookWorkflow(workflowRecordPendingAction);
                        setShowConfirmBookModal(false);
                    }}
                    onCancel={() => {
                        setWorkflowRecordPendingAction(null);
                        setShowConfirmBookModal(false);
                    }}
                />
            }
            {showQuestionAndAnswersModal &&
                <QuestionsAndAnswersModal
                    isOpen={showQuestionAndAnswersModal}
                    toggle={() => setShowQuestionAndAnswersModal(!showQuestionAndAnswersModal)}
                    questionsAndAnswers={questionsAndAnswers}
                />
            }
        </>
    )
}

export default PatientOpenWorkflowsGrid;

const QuestionsAndAnswersModal = ({ isOpen, toggle, questionsAndAnswers }: any) => {
    return (
        <Modal centered isOpen={isOpen} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>Decision Support Questions And Answers</ModalHeader>
            <ModalBody>{questionsAndAnswers?.length && questionsAndAnswers.map((qna: any, index: number) => {
                return (
                    <Row key={index}>
                        <Col lg='12'>
                            <div>
                                <p className='fw-bold form-label'>
                                    <ReadonlyHtml
                                        label={''}
                                        value={qna.questionText}
                                    />
                                </p>
                                <p>{qna.answerDisplayName}</p>
                            </div>
                        </Col>
                    </Row>
                );
            })}</ModalBody>
        </Modal>
    )
}

