import React from 'react';
import { NhLoadingIndicator } from './nhLoadingIndicator';
import { NighthawkModal } from '../modal/nighthawkModal';
import '../../css/loadingIndicator.css';
import '../../css/modal.css';

export function NhLoadingIndicatorModal(props) {
    const { message } = props;
    return (
        <NighthawkModal
            containerId="loadingIndicatorModalContainer"
            modalId="loadingIndicatorModal"
        >
            <NhLoadingIndicator
                message={message}
            />
        </NighthawkModal>
    );
};
