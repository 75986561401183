import { useDispatch, useSelector } from "react-redux";
import { YesNoModal, LabeledField } from "../../../components/common/standardComponents";
import {
    selectAgentAvailabilityInstructions,
    selectPreReservePatientNotes,
    selectReferrerNotesById,
    selectShouldShowAgentInstructionsModal,
    selectShouldShowPatientNotesModal,
    selectShouldShowPreReserveModal,
    selectShouldShowReferrerNotesModalById
} from "../availabilitySlice";
import { useEffect, useState } from "react";
import { reserveAppointment } from "../../../actions/appointmentActions";
import { routeToBooking } from "../../../routes";

export default function YesNoModalReserveation({ selectedSlot, setSelectedSlot }) {

    const dispatch = useDispatch();

    const shouldShowPreReserveModal =
        useSelector(state => selectShouldShowPreReserveModal(state, selectedSlot?.calendarId));

    const token = useSelector(state => state.auth.token);
    const correlationKey = useSelector(state => state.session.correlationKey);
    const reservationDurationInMinutes = useSelector(state => state.config.scheduling.reservationDurationInMinutes);
    const activePatientReferenceId = useSelector(state => state.activePatient?.details?.referenceId);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (selectedSlot && shouldShowPreReserveModal) {
            setIsOpen(true);
        }
    }, [selectedSlot, shouldShowPreReserveModal])

    return (
        <YesNoModal
            body={selectedSlot ? <PreReserveModalBody selectedSlot={selectedSlot} /> : null}
            isOpen={isOpen}
            noLabel="Cancel"
            onNo={() => {
                setSelectedSlot(null);
                setIsOpen(false);
            }}
            onYes={() => {
                dispatch(reserveAppointment(token, selectedSlot, reservationDurationInMinutes, correlationKey))
                    .then((response) => {
                        if (response.payload?.data?.reservationId) {
                            dispatch(routeToBooking(activePatientReferenceId, response.payload?.data?.flexCalendarEntryId))
                        }
                    });
                setIsOpen(false);
            }}
            title="Additional Referrer Information"
            yesLabel="OK"
        />
    );
}

const PreReserveModalBody = ({ selectedSlot }) => {

    const shouldShowReferrerNotesModal = useSelector(state => selectShouldShowReferrerNotesModalById(state, selectedSlot.calendarId));
    const referrerNotes = useSelector(state => selectReferrerNotesById(state, selectedSlot.calendarId));
    const shouldShowAgentInstructionsModal = useSelector(selectShouldShowAgentInstructionsModal);
    const agentInstructions = useSelector(selectAgentAvailabilityInstructions);
    const shouldShowPatientNotesModal = useSelector(selectShouldShowPatientNotesModal);
    const patientNotes = useSelector(selectPreReservePatientNotes);
    const patientNotesLabel = useSelector(state => state.config.patient.notes.fieldLabel);

    return (
        <>
            {shouldShowReferrerNotesModal && (
                <div
                    className="message-text"
                    dangerouslySetInnerHTML={{
                        __html: referrerNotes,
                    }}
                />
            )}
            {shouldShowAgentInstructionsModal && (
                <div
                    className="message-text"
                    dangerouslySetInnerHTML={{
                        __html: agentInstructions,
                    }}
                />
            )}
            {shouldShowPatientNotesModal && (
                <LabeledField
                    label={patientNotesLabel}
                    value={patientNotes}
                />
            )}
        </>
    );
};
