import React from 'react';
import { connect } from 'react-redux';

import {
	clearError,
	clearAllErrors,
} from '../../actions/errorActions';

import { DEFAULT_ERROR_MESSAGE } from '../constants/errorMessage';
import { DisclaimerHtml } from './miscComponents';

export class ActiveErrors extends React.Component {

	dismiss = (e, actionId) => {
		e.preventDefault();
		this.props.dispatch(clearError(actionId));
	}

	dismissAll = () => {
		this.props.dispatch(clearAllErrors());
	}

	render() {
		let className = this.props.errorMessageOverride ? "page-error" : "page-error default"
		let error = (this.props.activeErrors && this.props.activeErrors.length > 0) ?
			<DisclaimerHtml
				className={className}
				text={this.props.errorMessageOverride || DEFAULT_ERROR_MESSAGE}
				isVisible={true}
			/>
			: null;
		return error;
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeErrors: state.apiErrors.activeErrors,
		errorMessageOverride: state.config.instance.errorMessageOverride,
	};
}

export default connect(mapStateToProps)(ActiveErrors);
