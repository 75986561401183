enum DecisionSupportSubpoints {
    npi = "npi",
    siteid = "siteid",
    servicetypeid = "servicetypeid",
    asssettags = "assettags",
    additionalcalendarids = "additionalcalendarids",
    appointmenttypeid = "appointmenttypeid",
    zipcode = "zipcode",
    startdate = "startdate",
    enddate = "enddate",
    minstarttime = "minstarttime",
    maxstarttime = "maxstarttime",
    selectedprovidernpi = "selectedprovidernpi",
    selectedprovidercalendarid = "selectedprovidercalendarid",
    selectedsiteid = "selectedsiteid",
    reasonforvisit = "reasonforvisit",
    agentavailabilityinstructions = "agentavailabilityinstructions",
    agentreservationinstructions = "agentreservationinstructions",
    agentpostbookinginstructions = "agentpostbookinginstructions",
}

export default DecisionSupportSubpoints;
