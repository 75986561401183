import { useSelector } from "react-redux";
import PatientOpenWorkflowsGrid from "./patientOpenWorkflowsGrid";
import { useMemo, useState } from "react";
import PatientOpenWorkflowsFilter from "./patientOpenWorkflowsFilter";

export interface OpenWorkflowFilters {
    includeBookedWorkflows: boolean;
}

const PatientOpenworkflows = (
    {
        showLoseProgressWarning,
        showBookButton,
        activeTab,
        toggleIsPatientSummaryPanelOpen
    }: {
        showLoseProgressWarning: boolean,
        showBookButton: boolean,
        activeTab?: any,
        toggleIsPatientSummaryPanelOpen: Function
    }
) => {
    const openWorkflows = useSelector((state: any) => state.activePatient.openWorkflows);

    const [filters, setFilters] = useState<OpenWorkflowFilters>(
        {
            includeBookedWorkflows: false
        }
    )

    const filteredOpenWorkflows = useMemo(() => {
        return openWorkflows?.filter((workflow: any) => {
            if (!filters.includeBookedWorkflows && !!workflow.appointmentDate) {
                return false;
            }
            return true;
        });
    }, [openWorkflows, filters])

    return (
        <div>
            <div>
                {openWorkflows?.length > 0 &&
                    <div className="col" style={{ padding: '0px' }}>
                        <PatientOpenWorkflowsFilter
                            filters={filters}
                            setFilters={setFilters}
                        />
                        <PatientOpenWorkflowsGrid
                            openWorkflows={filteredOpenWorkflows}
                            showLoseProgressWarning={showLoseProgressWarning}
                            showBookButton={showBookButton}
                            activeTab={activeTab}
                            toggleIsPatientSummaryPanelOpen={toggleIsPatientSummaryPanelOpen}
                        />
                    </div>
                }
                {openWorkflows && (openWorkflows.length === 0) &&
                    <PatientOpenWorkflowsEmptyList />
                }
            </div>
        </div>
    )
}

function PatientOpenWorkflowsEmptyList() {
    return (
        <div>
            <h5 className="history-result-header">No history records found.</h5>
        </div>
    );
}

export default PatientOpenworkflows;