import { Dispatch, SetStateAction } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { AppointmentHistoryFilters } from "./patientAppointmentHistory";

const PatientAppointmentHistoryFilter = (
    {
        filters,
        setFilters,
        showPSAandMHDfilters
    }: {
        filters: AppointmentHistoryFilters,
        setFilters: Dispatch<SetStateAction<AppointmentHistoryFilters>>,
        showPSAandMHDfilters: boolean,
    }
) => {
    return (
        <Col style={{ marginLeft: '20px', marginBottom: '10px' }}>
            {showPSAandMHDfilters &&
                <Row className="col-sm-5 col-xxl-3">
                    <Col>
                        <Label check>
                            <Input
                                type="checkbox"
                                name="hidePSA"
                                checked={filters.hidePSA}
                                disabled={filters.hideMHD}
                                onChange={() => setFilters(filters => { return { ...filters, hidePSA: !filters.hidePSA } })}
                            />{' '}
                            Hide PSA Appointments
                        </Label>
                    </Col>
                    <Col>
                        <Label check>
                            <Input
                                type="checkbox"
                                name="hideMHD"
                                checked={filters.hideMHD}
                                disabled={filters.hidePSA}
                                onChange={() => setFilters(filters => { return { ...filters, hideMHD: !filters.hideMHD } })}
                            />{' '}
                            Hide MHD Appointments
                        </Label>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <Label check>
                        <Input
                            type="checkbox"
                            name="includeCanceled"
                            checked={filters.includeCanceled}
                            onChange={() => setFilters(filters => { return { ...filters, includeCanceled: !filters.includeCanceled } })}
                        />{' '}
                        Include Canceled Appointments
                    </Label>
                </Col>
            </Row>
        </Col>
    );
}

export default PatientAppointmentHistoryFilter;