import { useEffect, useState } from "react"
import UserViewContainer from "./comps/userViewContainer";
import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { logoutUser, saveFirstLoginChanges } from "actions/authActions";
import { logoutUser as logoutUserRoute } from "routes";
import { getUserProfile } from "actions/userActions";
import AcceptTermsOfUse from "./comps/acceptTermsOfUse";
import ManageSecretQuestions from "./comps/manageSecretQuestions";
import ChangePassword from "./comps/changePassword";
import './css/firstLoginView.css';
import classnames from 'classnames';
import LoginLogo from "./comps/loginLogo";
import LoginFooter from "./comps/loginFooter";
import ContactUs from "./comps/contactUs";

const FirstLoginView = () => {
    const dispatch = useDispatch();

    const { isTermsOfUseAccepted, compelPasswordChange }: any = useAppSelector((state: any) => state.auth);
    const enableSingleSignOn: any = useAppSelector((state: any) => state.config.externalAuth.enableSingleSignOn);
    const userDetails: any = useAppSelector((state: any) => state.user.details);
    const passwordConfig: any = useAppSelector((state: any) => state.config.passwordConfig);
    const userInfoConfig: any = useAppSelector((state: any) => state.app.config.userInfo);

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [localIsTermsOfUseAccepted, setLocalIsTermsOfUseAccepted] = useState(isTermsOfUseAccepted);
    const [showChangePassword, setShowChangePassword] = useState(compelPasswordChange);
    const [showSecretQuestions, setShowSecretQuestions] = useState(false);
    const [secretQuestionInfo, setSecretQuestionInfo]: any = useState({});

    const [changePasswordResponse, setChangePasswordResponse]: any = useState({
        succeeded: false,
        errors: [],
    })

    const saveAll = async (currentPassword: string, newPassword: string, confirmPassword: string) => {
        setIsPageLoading(true);

        const passwordInfo = {
            currentPassword,
            newPassword,
            confirmPassword
        }

        const localSecretQuestionInfo = {
            secretQuestion1: secretQuestionInfo.secretQuestion1 ? secretQuestionInfo.secretQuestion1 : userDetails.secretQuestion1,
            secretAnswer1: secretQuestionInfo.secretAnswer1 ? secretQuestionInfo.secretAnswer1 : userDetails.secretAnswer1,
            secretQuestion2: secretQuestionInfo.secretQuestion2 ? secretQuestionInfo.secretQuestion2 : userDetails.secretQuestion2,
            secretAnswer2: secretQuestionInfo.secretAnswer2 ? secretQuestionInfo.secretAnswer2 : userDetails.secretAnswer2,
            secretQuestion3: secretQuestionInfo.secretQuestion3 ? secretQuestionInfo.secretQuestion3 : userDetails.secretQuestion3,
            secretAnswer3: secretQuestionInfo.secretAnswer3 ? secretQuestionInfo.secretAnswer3 : userDetails.secretAnswer3,
        }

        try {
            const response: any = await dispatch(saveFirstLoginChanges(localSecretQuestionInfo, passwordInfo))

            if (!response.payload.data.succeeded) {
                setChangePasswordResponse({
                    succeeded: response.payload.data.succeeded,
                    errors: response.payload.data.errors,
                })

                setIsPageLoading(false);
            }

            // TODO: verify not routing here (unmounted state change warning)
        } catch {
            setIsPageLoading(false);
        }
    };

    const logout = async () => {
        const response: any = await dispatch(logoutUser());
        if (!response.error) {
            dispatch(logoutUserRoute())
        }
    }

    const handleTermsOfUseSubmit = (isAccepted: boolean) => {
        if (isAccepted) {
            setLocalIsTermsOfUseAccepted(true);
            setShowSecretQuestions(true);
            setShowChangePassword(false);
        } else {
            logout();
        }
    }

    const handleSecretQuestionSubmit = (secretQuestionInfo: any) => {
        setSecretQuestionInfo(secretQuestionInfo);
        setShowSecretQuestions(false);
        setShowChangePassword(true);
    }

    useEffect(() => {
        const loadUserProfile = async () => {
            setIsPageLoading(true);

            try {
                await dispatch(getUserProfile());
            } finally {
                setIsPageLoading(false);
            }
        }

        loadUserProfile();
    }, [dispatch])

    const containerClassName = classnames(
        'firstLoginWindow',
        { termsOfUse: !localIsTermsOfUseAccepted },
        { secretQuestions: localIsTermsOfUseAccepted && showSecretQuestions },
        { changePassword: localIsTermsOfUseAccepted && showChangePassword }
    );

    return (
        <UserViewContainer isLoading={isPageLoading} className="firstLoginView">
            <div className={containerClassName}>
                {!localIsTermsOfUseAccepted &&
                    <AcceptTermsOfUse
                        onSubmit={enableSingleSignOn ? saveAll : handleTermsOfUseSubmit}
                        onCancel={logout}
                    />
                }
                {localIsTermsOfUseAccepted && !enableSingleSignOn &&
                    <>
                        <LoginLogo />
                        {showSecretQuestions &&
                            <ManageSecretQuestions details={userDetails}
                                onSubmit={handleSecretQuestionSubmit}
                                onCancel={logout}
                                questionLength={userInfoConfig.secretQuestion.questionLength}
                                answerLength={userInfoConfig.secretQuestion.answerLength}
                                editMode={true}
                                isFirstLogin={true}
                            />
                        }
                        {showChangePassword &&
                            <ChangePassword
                                onSubmit={saveAll}
                                onCancel={logout}
                                showCancel={true}
                                response={changePasswordResponse}
                                passwordConfig={passwordConfig}
                                isFirstLogin={true}
                            />
                        }
                        <LoginFooter />
                        <ContactUs />
                    </>
                }
            </div>
        </UserViewContainer>
    )
}

export default FirstLoginView;