//Third Party Dependencies
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Alert } from 'reactstrap';

//MHD General Purpose libraries
import config from '../../config';
import ActiveErrors from '../../components/common/activeErrors';
import ErrorBoundary from '../../components/common/errorBoundary';
import { SectionHeading } from '../../components/common/standardComponents';
import LoadingIndicator from '../../components/loadingIndicator';

//Specific Business and UI components
import * as orderManagementActions from '../../actions/orderManagementActions';
import OrderDetailsModal from '../../components/modals/orderDetailsModal';
import OrderForm from '../../components/orderManagement/createOrderForm';
import OrderHistoryTable from '../../components/orderManagement/orderHistoryTable';
import PatientDetailsStrip from '../../components/patient/patientDetailsStrip';

//Constants
const CREATE_ORDER_SUCCESS_MESSAGE = 'SUCCESS! The followup order has been created for your patient.';
const EDIT_ORDER_SUCCESS_MESSAGE = 'SUCCESS! The order edit has been saved.';
const ADD_APPOINTMENT_MESSAGE = 'Please add at least one appointment to the followup order.';
const NO_CONSUMER_PRODUCT_INSTANCE_MESSAGE = 'No consumer product instances configured. Please contact support.';
const SAVED_DATE_RANGE = 'savedDateRange';
const CUSTOM_DATE_RANGE = 'customDateRange';

export class CreateOrderView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			appointments: [
				{
					externalReferralOrderId: "",
					department: 0,
					provider: 0,
					requiresReferralToBook: false,
					visitType: 0,
					visitTypeName: "",
					referringDepartment: 0,
					referringProvider: 0,
					referringProviderName: "",
				}
			],
			alertColor: 'warning',
			alertMessage: '',
			enableEditMode: false,
			isBookableSitesLoaded: false,
			isCareOrderDateRangesLoaded: false,
			isCareOrderDetailsPageDataLoaded: false,
			isOrderHistoryLoaded: false,
			isSaveDisabled: true,
			isSavingCreate: false,
			isSavingEdit: false,
			orderDetailsModalVisible: false,
			selectedDateRange: 0,
			selectedOrder: {},
			selectedOrderStatusDisplay: '',
			selectedOrderStatusNotes: '',
			selectedConsumerProductInstance: this.props.hasMultipleProductInstances || (this.props.consumerProductInstances.length < 1) ? 0 : this.props.consumerProductInstances[0].id,
			selectedStatusCode: '',
			selectedStatusReasonId: '',
			//showAddAppointmentAlert: false,
			showAlert: false,
			//showCreateSuccessAlert: false,
			//showEditSuccessAlert: false,
			showValidationErrors: false,

			customDateRangeEnd: null,
			customDateRangeEndIsValid: false,
			customDateRangeEndPicker: null,
			customDateRangeStart: null,
			customDateRangeStartIsValid: false,
			customDateRangeStartPicker: null,
			selectedDateRangeOption: SAVED_DATE_RANGE,

			selectedSchedulableOrders: [],
			isSchedulableOrdersModalOpen: false,
			disableAddAdditionalOrders: false,
		};

		this.onCustomDateRangeChange = this.onCustomDateRangeChange.bind(this);
		this.onDatePickerChange = this.onDatePickerChange.bind(this);
		this.onEditClick = this.onEditClick.bind(this);
		this.onSchedulableOrderChange = this.onSchedulableOrderChange.bind(this);
		this.onSchedulableOrdersSave = this.onSchedulableOrdersSave.bind(this);
		this.onStatusListChange = this.onStatusListChange.bind(this);
		this.validateCustomDate = this.validateCustomDate.bind(this);
		this.validateEntireOrder = this.validateEntireOrder.bind(this);
	}

	validateEntireOrder = (order) => {
		const deepFlatten = (obj) => {
			return Object.values(obj).reduce((agg, curr) => {
				if (typeof curr !== "object") {
					return agg.concat(curr);
				} else {
					return agg.concat(deepFlatten(curr));
				}
			}, []);
		};

		let localState = { ...this.state };
		let cleanOrder = order;
		if (cleanOrder && cleanOrder.visits.length > 0) {
			cleanOrder.visits.forEach((o) => {
				if (localState.selectedDateRangeOption === SAVED_DATE_RANGE) {
					delete o.minVisitDate;
					delete o.maxVisitDate;
				} else if (localState.selectedDateRangeOption === CUSTOM_DATE_RANGE) {
					delete o.systemDateRangeId;
				}
				o.appointments.forEach((a) => {
					if (!a.requiresReferralToBook) {
						delete a.referringSiteId;
						delete a.referringServiceId;
					}
					delete a.requiresReferralToBook;
					if (a.externalReferralOrderId !== "") {
						delete a.siteId;
						delete a.serviceId;
					} else {
						delete a.unmanagedReferringServiceName;
						delete a.externalReferralOrderId;
						delete a.subgroupId;
					}
				})
			})
		}

		let result = deepFlatten(cleanOrder).every(x => x);
		return result;
	}

	onCreateOrderClick = () => {
		let consumerIdProductInstance = parseInt(this.state.selectedConsumerProductInstance, 10);
		const order = {
			systemId: this.props.auth.referralSystemId,
			patientReferenceId: this.props.activePatient.details.referenceId,
			idProductInstance: this.props.auth.productInstanceId,
			consumerIdProductInstance: consumerIdProductInstance,
			visits: [
				{
					systemDateRangeId: this.state.selectedDateRangeOption === SAVED_DATE_RANGE ? this.state.selectedDateRange : null,
					minVisitDate: this.state.selectedDateRangeOption === CUSTOM_DATE_RANGE ? this.state.customDateRangeStart : null,
					maxVisitDate: this.state.selectedDateRangeOption === CUSTOM_DATE_RANGE ? this.state.customDateRangeEnd : null,
					appointments: this.state.appointments.map(x => {
						return {
							siteId: x.department === 0 ? "" : x.department,
							serviceId: x.provider === 0 ? "" : x.provider,
							requiresReferralToBook: x.requiresReferralToBook,
							appointmentTypeId: x.visitType === 0 ? "" : x.visitType,
							referringSiteId: x.referringDepartment === 0 ? "" : x.referringDepartment,
							referringServiceId: x.referringProvider === 0 ? "" : x.referringProvider,
							unmanagedReferringServiceName: x.referringProviderName,
							externalReferralOrderId: x.externalReferralOrderId,
							subgroupId: x === 0 ? "" : x.subgroup,
						};
					}),
				},
			],
		};

		if (this.props.consumerProductInstances.filter(pi => pi.id > 0).length === 0) {
			this.setState({ showAlert: true, alertColor: 'danger', alertMessage: NO_CONSUMER_PRODUCT_INSTANCE_MESSAGE });
		}
		else if (!order.visits[0].appointments.length) {
			this.setState({ showAlert: true, alertMessage: ADD_APPOINTMENT_MESSAGE });
		}
		else if (this.validateEntireOrder(order)) {
			let hasMultipleProductInstances = this.props.hasMultipleProductInstances;
			let firstProductInstanceId = this.props.consumerProductInstances[0].id;

			this.setState({ isSavingCreate: true });
			this.props.dispatch(this.props.orderManagementActions.createCareOrder(order))
				.then(response => {
					if (!response.error) {
						this.setState({
							appointments: [
								{
									externalReferralOrderId: "",
									department: "",
									provider: "",
									requiresReferralToBook: false,
									visitType: "",
									referringDepartment: "",
									referringProvider: "",
								}
							],
							alertColor: 'success',
							alertMessage: CREATE_ORDER_SUCCESS_MESSAGE,
							selectedDateRange: 0,
							selectedConsumerProductInstance: hasMultipleProductInstances ? 0 : firstProductInstanceId,
							showAlert: true,
							showValidationErrors: false,
							customDateRangeEnd: null,
							customDateRangeEndIsValid: false,
							customDateRangeEndPicker: null,
							customDateRangeStart: null,
							customDateRangeStartIsValid: false,
							customDateRangeStartPicker: null,
							selectedDateRangeOption: SAVED_DATE_RANGE,
						});
					}
				})
				.finally(() => {
					this.setState({ isSavingCreate: false, isOrderHistoryLoaded: false });
					let searchCriteria = {
						patientReferenceId: this.props.activePatient.details.referenceId,
					};
					this.props.dispatch(this.props.orderManagementActions.searchCareOrders(searchCriteria))
						.then(() => {
							this.setState({ isOrderHistoryLoaded: true });
						});
				});
		}
		else {
			this.setState({ showValidationErrors: true });
		}
	}

	onAddAppointmentClick = () => {
		const appointments = [...this.state.appointments].concat({
			externalReferralOrderId: "",
			department: "",
			provider: "",
			visitType: "",
			referringDepartment: "",
			referringProvider: "",
		});
		this.setState({ appointments, showAlert: false, alertMessage: '' });
	}

	onChange = (e, name) => {
		let localName = name && name !== "" ? name : e.target.name;
		this.setState({ [localName]: e.target.value });
	}

	onCustomDateRangeChange = (event, error) => {
		const { name, value } = event.target;
		let pickerValue = (value === "__/__/____" || value === "" || !moment(value).isValid()) ? new Date() : new Date(value);
		let datePickerName = `${name}Picker`;
		let isValidName = `${name}IsValid`;

		this.setState({ [name]: value, [isValidName]: !error, [datePickerName]: pickerValue }, () => {
			let isEndDateValid = this.state.customDateRangeEndIsValid && moment(this.state.customDateRangeEnd).isAfter(moment(this.state.customDateRangeStart));
			this.setState({ customDateRangeEndIsValid: isEndDateValid });
		});
	};

	validateCustomDate = (value) => {
		let error = false;
		let mDate = moment(value).startOf('day');
		if (mDate.isBefore(moment().startOf('day'))) {
			error = true;
		}
		return error;
	}


	onDatePickerChange = (date, name) => {
		let dateTextName = `${name}Picker`;
		let mDate = moment(date);

		this.setState({ [dateTextName]: date}, () => {
			let event = {
				target: {
					name: name,
					value: mDate.format("MM/DD/YYYY"),
				}
			};
			let error = this.validateCustomDate(date);
			this.onCustomDateRangeChange(event, error);
		});
	}

	updateAppointmentOption = (index, dropdown, value) => {
		const appointments = [...this.state.appointments];
		const optionToChange = { ...appointments[index] };

		let intValue = parseInt(value, 10);
		optionToChange[dropdown] = intValue;

		switch (dropdown) {
			case "department":
				this.props.dispatch(this.props.orderManagementActions.retrieveServicesForSite(intValue, index));
				optionToChange.provider = 0;
				optionToChange.visitType = 0;
				break;
			case "provider":
				let selectedProvider = this.props.orderManagement.rowOptions[index].providers.find(p => p.id === intValue);
				this.props.dispatch(this.props.orderManagementActions.retrieveAppointmentTypesForCalendar(this.state.appointments[index].department, intValue, index));
				optionToChange.visitType = 0;
				optionToChange.requiresReferralToBook = selectedProvider.requiresReferralToBook;
				break;
			case "referringDepartment":
				this.props.dispatch(this.props.orderManagementActions.retrieveReferringProvidersForSite(intValue, index));
				optionToChange.referringProvider = 0;
				break;
			default:
				break;
		}

		appointments[index] = optionToChange;
		this.setState({ appointments });
	}

	onRowDropdownChange = (event, index) => {
		this.updateAppointmentOption(index, event.target.name, event.target.value);
	}

	onRemoveRowClick = (index) => {
		this.props.dispatch(this.props.orderManagementActions.removeAppointmentOptionRow(index));

		let appointments = this.state.appointments;
		let apptToRemove = appointments.splice(index, 1);
		const selectedSchedulableOrders = this.state.selectedSchedulableOrders.filter(s => s !== apptToRemove[0].externalReferralOrderId);

		let disableAddAdditionalOrders = appointments.length === this.props.config.orderManagement.maxAppointmentsPerVisit;
		this.setState({ appointments, selectedSchedulableOrders, disableAddAdditionalOrders });
	}

	onDetailsClick = (e, id) => {
		e.preventDefault();
		this.props.dispatch(this.props.orderManagementActions.getCareOrderHistory(id));
		let order = this.props.orderManagement.careOrders.find(o => o.id === id);
		this.resolveStatusDisplayAndVisibility(order);

		this.setState({
			isSaveDisabled: true,
			orderDetailsModalVisible: true,
			selectedOrder: order,
			selectedOrderStatusNotes: order.notes,
		});
	}

	onSaveDetails = () => {
		let { selectedOrder, selectedStatusCode, selectedStatusReasonId, selectedOrderStatusNotes } = { ...this.state };
		let orderUpdate = {
			id: selectedOrder.id,
			statusCode: selectedStatusCode,
			statusReason: selectedStatusReasonId,
			notes: selectedOrderStatusNotes
		};

		this.setState({ isSavingEdit: true });

		this.props.dispatch(this.props.orderManagementActions.updateCareOrder(orderUpdate))
			.then(response => {
				if (!response.error) {
					this.setState({
						showAlert: true,
						alertColor: 'success',
						alertMessage: EDIT_ORDER_SUCCESS_MESSAGE,
						enableEditMode: false,
						isSaveDisabled: true,
						orderDetailsModalVisible: false,
						selectedOrderStatusDisplay: '',
						selectedOrderStatusNotes: '',
						selectedStatusCode: '',
						selectedStatusReasonId: '',
					});
				} else {
					this.setState({ orderDetailsModalVisible: false });
				}
			})
			.finally(() => {
				let searchCriteria = {
					patientReferenceId: this.props.activePatient.details.referenceId,
				};
				this.setState({ isSavingEdit: false, isOrderHistoryLoaded: false });
				this.props.dispatch(this.props.orderManagementActions.searchCareOrders(searchCriteria))
					.then(() => {
						this.setState({ isOrderHistoryLoaded: true });
					});
			});
	}

	onCancelDetails = () => {
		this.setState({
			enableEditMode: false,
			isSaveDisabled: true,
			orderDetailsModalVisible: false,
		});
	}

	onEditClick = (e) => {
		e.preventDefault();
		let enableEditMode = !this.state.enableEditMode;
		let { statusCode, statusReasonId } = this.state.selectedOrder;
		this.setState({ enableEditMode, selectedStatusCode: statusCode, selectedStatusReasonId: statusReasonId.toString() });
	}

	onStatusListChange = (e) => {
		e.preventDefault();
		let selectedStatusCode = e.target.value;
		const filteredStatusReasons = this.props.pageData.careOrderStatusReasons.filter(r => r.code === selectedStatusCode);
		this.setState({
			selectedStatusCode,
			selectedStatusReasonId: filteredStatusReasons[0].id.toString()
		}, () => {
			this.resolveSaveDisabled();
		});
	}

	onStatusReasonListChange = (e) => {
		let selectedStatusReasonId = e.target.value;
		this.setState({ selectedStatusReasonId }, () => { this.resolveSaveDisabled() });
	}

	onStatusNotesChange = (e) => {
		this.setState({ selectedOrderStatusNotes: e.target.value }, () => { this.resolveSaveDisabled() });
	}

	onAddRadiologyOrdersClick = (e) => {
		this.setState({ isSchedulableOrdersModalOpen: true });
	}

	onSchedulableOrdersSave = (e) => {
		let { appointments, selectedSchedulableOrders } = { ...this.state};
		let selectedOrders = this.props.activePatient.schedulableOrders.filter(o => selectedSchedulableOrders.includes(o.idExternal));
		let newSelectedOrders = selectedOrders.filter(s => appointments.every(a => a.externalReferralOrderId !== s.idExternal));

		let newAppointments = newSelectedOrders.map((o) => {
				return {
					department: 0,
					provider: 0,
					visitType: o.appointmentTypeId,
					visitTypeName: o.appointmentTypeName,
					referringDepartment: 0,
					referringProvider: 0,
					referringProviderName: o.referringProviderName,
					requiresReferralToBook: false,
					externalReferralOrderId: o.idExternal,
					subgroup: 0,
				};
		});

		let finalAppointments = appointments.filter(a => a.externalReferralOrderId === "" || (a.externalReferralOrderId !== "" && selectedOrders.some(s =>s.idExternal === a.externalReferralOrderId))).concat(newAppointments);
		this.setState({ appointments: finalAppointments, isSchedulableOrdersModalOpen: false });
	}

	onSchedulableOrdersClose = (e) => {
		this.setState({ isSchedulableOrdersModalOpen: false });
	}

	onSchedulableOrderChange = (e) => {
		let { appointments, selectedSchedulableOrders, disableAddAdditionalOrders } = { ...this.state };
		if (selectedSchedulableOrders.length > 0 && selectedSchedulableOrders.includes(e.target.id)) {
			selectedSchedulableOrders.splice(selectedSchedulableOrders.indexOf(e.target.id), 1);
		}
		else {
			selectedSchedulableOrders.push(e.target.id);
		}

		let total = selectedSchedulableOrders.length + appointments.filter(a => !selectedSchedulableOrders.some(s => s === a.externalReferralOrderId)).length;
		if (total > 0) {
			let maxRows = this.props.config.orderManagement.maxAppointmentsPerVisit;
			disableAddAdditionalOrders = total >= maxRows;
		}
		this.setState({ selectedSchedulableOrders, disableAddAdditionalOrders });
	}

	dismissAlert = () => {
		this.setState ({ showAlert: false, alertMessage: '' });
	}

	shouldValidate = () => {
		return this.state.showValidationErrors;
	}

	resolveSaveDisabled = () => {
		let { selectedOrderStatusNotes, selectedOrder, selectedStatusCode, selectedStatusReasonId } = { ...this.state };
		let isSaveDisabled = true;
		if (selectedStatusCode !== selectedOrder.statusCode) {
			isSaveDisabled = false;
		} else if (selectedStatusReasonId !== selectedOrder.statusReasonId) {
			isSaveDisabled = false;
		} else if (selectedOrderStatusNotes) {
			isSaveDisabled = false;
		}
		this.setState({ isSaveDisabled });
	}

	resolveStatusDisplayAndVisibility = (order) => {
		let selectedOrderStatusDisplay = '';

		switch(order.statusCode.toLowerCase()) {
			case config.careOrderStatusCodes.open.toLowerCase() :
				selectedOrderStatusDisplay = order.status;
				break;
			case config.careOrderStatusCodes.closed.toLowerCase() :
				selectedOrderStatusDisplay = `${order.status} (${order.statusReason})`;
				break;
			default:
				selectedOrderStatusDisplay = "-";
				break;
		}
		this.setState({ selectedOrderStatusDisplay });
	}

	getCareOrderHistory = () => {
		let searchCriteria = {
			patientReferenceId: this.props.activePatient.details.referenceId,
		};
		this.props.dispatch(this.props.orderManagementActions.searchCareOrders(searchCriteria))
			.then(() => {
				this.setState({	isOrderHistoryLoaded: true });
			});
	}

	componentDidMount() {
		this.props.dispatch(this.props.orderManagementActions.retrieveCareOrderDateRanges(this.props.auth.referralSystemId))
			.then(() => this.setState({ isCareOrderDateRangesLoaded: true }));
		this.props.dispatch(this.props.orderManagementActions.retrieveBookableSites(this.props.auth.referralSiteId))
			.then(() => this.setState({ isBookableSitesLoaded: true }));
		this.props.dispatch(this.props.orderManagementActions.getCareOrderDetailsPageData(true))
			.then(() => this.setState({ isCareOrderDetailsPageDataLoaded: true }));

		if (this.props.config.orderManagement.usesExternalOrders) {
			this.props.dispatch(this.props.orderManagementActions.retrieveSubgroupsForSystem())
			.then(() => this.setState({ isSubgroupsLoaded: true}));
		} else {
			this.setState({ isSubgroupsLoaded: true});
		}

		this.getCareOrderHistory();
	}

render() {
	//TODO: Build filter control/logic
	let openOrders = this.props.orderManagement.careOrders.filter(o => o.statusCode && o.statusCode.toLowerCase() === config.careOrderStatusCodes.open.toLowerCase());
	let { isCareOrderDateRangesLoaded, isBookableSitesLoaded, isCareOrderDetailsPageDataLoaded, isOrderHistoryLoaded, isSubgroupsLoaded } = this.state;
	let isLoaded = isCareOrderDateRangesLoaded && isBookableSitesLoaded && isCareOrderDetailsPageDataLoaded && isOrderHistoryLoaded && isSubgroupsLoaded;
	let enableSave = this.state.selectedDateRangeOption === SAVED_DATE_RANGE || (this.state.selectedDateRangeOption === CUSTOM_DATE_RANGE && this.state.customDateRangeEndIsValid && this.state.customDateRangeStartIsValid);

	return (
		<React.Fragment>
			 <ActiveErrors />
			<SectionHeading label="Create Followup Visit Order" />
			<Alert className="text-center" color={this.state.alertColor} isOpen={this.state.showAlert} toggle={this.dismissAlert}>
				{this.state.alertMessage}
			</Alert>

			{ !isLoaded &&
				<LoadingIndicator />
			}
			{ isLoaded &&
				<div>
					<ErrorBoundary childName="Patient Details">
						<PatientDetailsStrip patientDetails={this.props.activePatient.details} config={this.props.config.patient} />
					</ErrorBoundary>
					<ErrorBoundary childName="Create Order Form">
						<OrderForm
							appointments={this.state.appointments}
							customDateRangeEnd={this.state.customDateRangeEnd}
							customDateRangeEndIsValid={this.state.customDateRangeEndIsValid}
							customDateRangeEndPicker={this.state.customDateRangeEndPicker}
							customDateRangeStart={this.state.customDateRangeStart}
							customDateRangeStartIsValid={this.state.customDateRangeStartIsValid}
							customDateRangeStartPicker={this.state.customDateRangeStartPicker}
							dateRangeList={this.props.orderManagement.dateRangeList}
							departmentList={this.props.orderManagement.bookableSites}
							disableAdditionalOrders={this.state.disableAddAdditionalOrders}
							enableProductInstances={this.props.hasMultipleProductInstances}
							enableSave={enableSave}
							isSavingCreate={this.state.isSavingCreate}
							isSchedulableOrdersModalOpen={this.state.isSchedulableOrdersModalOpen}
							maxRows={this.props.config.orderManagement.maxAppointmentsPerVisit}
							onAddAppointmentClick={this.onAddAppointmentClick}
							onAddRadiologyOrderClick={this.onAddRadiologyOrdersClick}
							onCreateOrderClick={this.onCreateOrderClick}
							onCustomDateRangeChange={this.onCustomDateRangeChange}
							onDatePickerChange={this.onDatePickerChange}
							onDateRangeOptionChange={this.onChange}
							onDropdownChange={this.onChange}
							onRemoveRowClick={this.onRemoveRowClick}
							onRowDropdownChange={this.onRowDropdownChange}
							onSchedulableOrderChange={this.onSchedulableOrderChange}
							onSchedulableOrdersClose={this.onSchedulableOrdersClose}
							onSchedulableOrdersSave={this.onSchedulableOrdersSave}
							optionLists={this.props.orderManagement.rowOptions}
							patientDetails={this.props.activePatient.details}
							productInstances={this.props.consumerProductInstances}
							schedulableOrdersList={this.props.activePatient.schedulableOrders}
							selectedConsumerProductInstance={this.state.selectedConsumerProductInstance}
							selectedDateRange={this.state.selectedDateRange}
							selectedDateRangeOption={this.state.selectedDateRangeOption}
							selectedSchedulableOrdersList={this.state.selectedSchedulableOrders}
							shouldValidate={this.shouldValidate}
							subgroups={this.props.orderManagement.subgroups}
							validateCustomDateRange={this.validateCustomDate}

						/>
					</ErrorBoundary>
					<ErrorBoundary childName="Patient Order History">
						<OrderHistoryTable orders={openOrders} onDetailsClick={this.onDetailsClick} isLoaded={this.state.isOrderHistoryLoaded} showPatientInfo={false} isParentCreateOrder={true} />
					</ErrorBoundary>
					<OrderDetailsModal
						careOrderHistory={this.props.orderManagement.careOrderHistory}
						isEditMode={this.state.enableEditMode}
						isOpen={this.state.orderDetailsModalVisible}
						isSaveDisabled={this.state.isSaveDisabled}
						isSavingEdit={this.state.isSavingEdit}
						onCancel={this.onCancelDetails}
						onConfirm={this.onSaveDetails}
						onEdit={this.onEditClick}
						onStatusListChange={this.onStatusListChange}
						onStatusNotesChange={this.onStatusNotesChange}
						onStatusReasonListChange={this.onStatusReasonListChange}
						order={this.state.selectedOrder}
						orderStatusDisplay={this.state.selectedOrderStatusDisplay}
						patientConfig={this.props.config.patient}
						selectedOrderStatusNotes={this.state.selectedOrderStatusNotes}
						selectedStatusCode={this.state.selectedStatusCode}
						selectedStatusReasonId={this.state.selectedStatusReasonId}
						showStatusReasonList={this.state.showStatusReasonList}
						statusOptionsList={this.props.pageData.careOrderStatuses}
						statusReasonOptionsList={this.props.pageData.careOrderStatusReasons}
						isParentCreateOrder={true}
					/>
				</div>
			}
		</React.Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		activePatient: state.activePatient,
		auth: state.auth,
		config: state.config,
		hasMultipleProductInstances: state.config.consumerProductInstances.filter(pi => pi.id > 0).length > 1,
		orderManagement: state.orderManagement,
		pageData: state.orderManagement.careOrderDetailsPageData,
		consumerProductInstances: state.config.consumerProductInstances,
		token: state.auth.token,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
		orderManagementActions: orderManagementActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderView);
