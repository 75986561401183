/**
 * @format
 */

import * as React from 'react';
import { Label } from 'reactstrap';
import { PhoneNumberInput } from '../input';
import { DropdownSelect } from '../common/standardComponents';
import './preferredContactPhone.css';

export class PreferredContactPhone extends React.Component {
	render() {
		const { phones, selectPreferredContact, inputOtherPhone, showOtherInput, otherPhone } = this.props;

		let optionList = [];

		phones.forEach((phone) => {
			optionList.push({ id: phone.type, name: `${phone.type}: ${phone.number}` });
		});

		optionList.push({ id: 'Other', name: 'Other' });
		let label = 'preferred method for contact';

		return (
			<div className="form-group mt-4">
				<div>
					<Label>Please select the preferred method for contact:</Label>
					<DropdownSelect optionList={optionList} label={label} onChange={selectPreferredContact} />
					{showOtherInput && (
						<PhoneNumberInput
							onChange={inputOtherPhone}
							required={false}
							name={'hello'}
							value={otherPhone}
							placeholder={'Other phone number'}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default PreferredContactPhone;
