import * as actionTypes from './actionTypes';
import config from '../config';
import { APPOINTMENT_CRITERIA } from '../constants/appointmentCriteria';

export function getAppointmentReferenceId(refType, refValue) {
	return {
		type: actionTypes.TOKEN_LINK_APPOINTMENT_REFID_LOOKUP,
		payload: {
			request: {
				method: 'get',
				url: `start/appointmentLookup?refType=${refType}&refValue=${refValue}`
			}
		}
	};
}

export function getAppointmentByReferenceId(referenceId) {
	return {
		type: actionTypes.APPOINTMENT_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `appointment/${referenceId}/details`
			}
		}
	};
}

export function getCancelledAppointmentByReferenceId(referenceId) {
	return {
		type: actionTypes.APPOINTMENT_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `cancelledAppointment/${referenceId}/details`
			}
		}
	};
}

export function getAppointmentRequest(requestId) {
	return {
		type: actionTypes.APPOINTMENT_REQUEST_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `appointment/request/${requestId}/details`
			}
		}
	};
}

export function getPreviousAppointmentByReferenceId(referenceId) {
	return {
		type: actionTypes.PREVIOUS_APPOINTMENT_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `appointment/${referenceId}/details`
			}
		}
	};
}

export function getReservationDetails(fce, patientReferenceId, serviceSiteId, insuranceProvider, payorType) {
	return {
		type: actionTypes.APPOINTMENT_RESERVE_DETAILS,
		payload: {
			request: {
				method: 'post',
				url: `appointment/reservation/details`,
				data: {
					flexCalendarEntryId: fce,
					patientReferenceId: patientReferenceId,
					serviceSiteId: serviceSiteId,
					insuranceProviderId: insuranceProvider,
					payorTypeId: payorType,
				},
			}
		}
	};
}

export function bookAppointment(token, details) {
	return {
		type: actionTypes.APPOINTMENT_BOOK,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/book',
				data: {
					careOrderVisitIdentifier: details.careOrderVisitIdentifier,
					correlationKey: details.correlationKey,
					externalReferralOrderId: details.externalReferralOrderId,
					idSlot: details.idSlot,
					patientReferenceId: details.patientReferenceId,
					idPayorType: details.idPayorType,
					idLanguage: details.idLanguage,
					unmanagedReferringServiceName: details.unmanagedReferringServiceName,
					managedReferringServiceId: details.managedReferringServiceId,
					referringOrganizationProviderId: details.referringOrganizationProviderId,
					languageCode: details.idLanguage === config.notificationLanguages.spanish ? "ES" : "EN", //Legacy hardcodes these, D2c doesn't use them at all.. These codes aren't even in the DB
					hasRequestedWaitlist: details.hasRequestedWaitlist,
					homePhoneCallReminder: details.homePhoneCallReminder,
					homePhoneCallReminderNumber: details.homePhoneCallReminderNumber,
					homePhoneRemindersHoursBefore: details.homePhoneRemindersHoursBefore,
					mobilePhoneCallReminder: details.mobilePhoneCallReminder,
					mobilePhoneCallReminderNumber: details.mobilePhoneCallReminderNumber,
					mobilePhoneCallRemindersHoursBefore: details.mobilePhoneCallRemindersHoursBefore,
					mobilePhoneTextReminder: details.mobilePhoneTextReminder,
					mobilePhoneTextReminderNumber: details.mobilePhoneTextReminderNumber,
					mobilePhoneTextRemindersHoursBefore: details.mobilePhoneTextRemindersHoursBefore,
					emailReminder: details.emailReminder,
					reminderEmail: details.reminderEmail,
					emailConfirmation: details.emailConfirmation,
					confirmationEmail: details.confirmationEmail,
					emailRemindersHoursBefore: details.emailRemindersHoursBefore,
					reasonForAppointment: details.reasonForAppointment,
					idInsuranceState: details.idInsuranceState,
					idInsurance: details.idInsurance,
					clientIdentifier: details.clientIdentifier,
					reservationId: details.reservationId,
					customFieldValues: details.customFieldValues,
					appointmentAttachments: details.appointmentAttachments,
					workboxId: details.workboxId,
					appointmentTypeId: details.appointmentTypeId,
					idServiceSite: details.idServiceSite,
					slotStartDateTime: details.slotStartDateTime,
					slotEndDateTime: details.slotEndDateTime,
					productInstanceConsumerId: details.productInstanceConsumerId,
					decisionSupportSessionId: details.decisionSupportSessionId,
				},
				headers: {
					token: token,
				},
			}
		}
	};
}

export function updateAppointment(details) {
	return {
		type: actionTypes.APPOINTMENT_UPDATE,
		payload: {
			request: {
				method: 'put',
				url: 'appointment/update',
				data: {
					referenceId: details.referenceId,
					reasonForVisit: details.reasonForVisit,
					providerResponse: details.providerResponse,
					doUpdateReasonForVisit: details.doUpdateReasonForVisit,
					doUpdateProviderResponse: details.doUpdateProviderResponse,
				},
			}
		}
	};
}

export function rescheduleAppointment(token, details, previousAppointmentReferenceId, rescheduleMethod) {
	return {
		type: actionTypes.APPOINTMENT_RESCHEDULE,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/reschedule',
				data: {
					careOrderVisitIdentifier: details.careOrderVisitIdentifier,
					correlationKey: details.correlationKey,
					externalReferralOrderId: details.externalReferralOrderId,
					idSlot: details.idSlot,
					patientReferenceId: details.patientReferenceId,
					idPayorType: details.idPayorType,
					idLanguage: details.idLanguage,
					unmanagedReferringServiceName: details.unmanagedReferringServiceName,
					managedReferringServiceId: details.managedReferringServiceId,
					referringOrganizationProviderId: details.referringOrganizationProviderId,
					languageCode: details.idLanguage === config.notificationLanguages.spanish ? "ES" : "EN",
					hasRequestedWaitlist: details.hasRequestedWaitlist,
					homePhoneCallReminder: details.homePhoneCallReminder,
					homePhoneCallReminderNumber: details.homePhoneCallReminderNumber,
					homePhoneRemindersHoursBefore: details.homePhoneRemindersHoursBefore,
					mobilePhoneCallReminder: details.mobilePhoneCallReminder,
					mobilePhoneCallReminderNumber: details.mobilePhoneCallReminderNumber,
					mobilePhoneCallRemindersHoursBefore: details.mobilePhoneCallRemindersHoursBefore,
					mobilePhoneTextReminder: details.mobilePhoneTextReminder,
					mobilePhoneTextReminderNumber: details.mobilePhoneTextReminderNumber,
					mobilePhoneTextRemindersHoursBefore: details.mobilePhoneTextRemindersHoursBefore,
					emailReminder: details.emailReminder,
					reminderEmail: details.reminderEmail,
					emailConfirmation: details.emailConfirmation,
					confirmationEmail: details.confirmationEmail,
					emailRemindersHoursBefore: details.emailRemindersHoursBefore,
					reasonForAppointment: details.reasonForAppointment,
					idInsuranceState: details.idInsuranceState,
					idInsurance: details.idInsurance,
					clientIdentifier: details.clientIdentifier,
					reservationId: details.reservationId,
					customFieldValues: details.customFieldValues,
					appointmentAttachments: details.appointmentAttachments,
					workboxId: details.workboxId,
					appointmentTypeId: details.appointmentTypeId,
					idServiceSite: details.idServiceSite,
					slotStartDateTime: details.slotStartDateTime,
					slotEndDateTime: details.slotEndDateTime,
					productInstanceConsumerId: details.productInstanceConsumerId,
					appointmentRescheduleReferenceId: previousAppointmentReferenceId,
					rescheduleMethod: rescheduleMethod,
					decisionSupportSessionId: details.decisionSupportSessionId,
				},
				headers: {
					token: token,
				},
			}
		}
	};
}

export function reserveAppointment(token, timeSlotInfo, reservationDuration, correlationKey) {
	return {
		type: actionTypes.APPOINTMENT_RESERVE,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/reserve',
				headers: {
					token: token,
				},
				data: {
					correlationKey: correlationKey,
					reservationDuration: reservationDuration,
					timeSlotRequestInfo: { ...timeSlotInfo, serviceSiteId: timeSlotInfo.calendarId }, // TODO: refactor endpoint to use calendarId for consistency
				}
			}
		}
	};
}

export function requestAppointment(token, details) {
	return {
		type: actionTypes.APPOINTMENT_REQUEST,
		payload: {
			request: {
				method: 'post',
				url: 'appointment/request',
				data: {
					patientReferenceId: details.patientReferenceId,
					referralSystemId: details.referralSystemId,
					memberId: details.memberId,
					idUserReferrer: details.idUserReferrer,
					idPgmPayorType: details.idPgmPayorType,
					idPgmSpecialty: details.idPgmSpecialty,
					idLanguage: details.idLanguage,
					reasonForReferral: details.reasonForReferral,
					siteId: details.siteId,
					referringServiceName: details.referringServiceName,
					idInsurance: details.idInsurance,
					serviceName: details.serviceName,
					idServiceSite: details.idServiceSite,
					patientMessage: details.patientMessage,
					uploadedFiles: details.uploadedFiles,
					preferredContactPhone: details.preferredContactPhone,
					preferredContactPhoneType: details.preferredContactPhoneType,
					schedulingInformation: details.schedulingInformation,
					shouldSendConfirmationEmail: details.shouldSendConfirmationEmail,
					alternateConfirmationEmail: details.alternateConfirmationEmail,
					customValues: details.customValues,
					patientNotificationLanguageCode: details.patientNotificationLanguageCode,
					patientConfirmationEmailEnabled: details.patientConfirmationEmailEnabled,
					patientConfirmationEmailDestinationEmail: details.patientConfirmationEmailDestinationEmail,
					patientReminderSmsEnabled: details.patientReminderSmsEnabled,
					patientReminderSmsLeadTimeHours: details.patientReminderSmsLeadTimeHours,
					patientReminderSmsDestinationPhone: details.patientReminderSmsDestinationPhone,
					patientReminderVoiceEnabled: details.patientReminderVoiceEnabled,
					patientReminderVoiceLeadTimeHours: details.patientReminderVoiceLeadTimeHours,
					patientReminderVoiceDestinationPhone: details.patientReminderVoiceDestinationPhone,
					patientReminderEmailEnabled: details.patientReminderEmailEnabled,
					patientReminderEmailLeadTimeHours: details.patientReminderEmailLeadTimeHours,
					patientReminderEmailDestinationEmail: details.patientReminderEmailDestinationEmail,
				},
				headers: {
					token: token,
				},
			}
		}
	}
}

export function cancelAppointment(appointmentId, cancelRequest, token) {
	return {
		type: actionTypes.APPOINTMENT_CANCEL,
		payload: {
			request: {
				method: 'post',
				url: `appointment/{appointmentId}/cancel`,
				headers: {
					token: token,
				},
				data: {
					reasonForCancellation: cancelRequest.reasonForCancellation,
					sendCancellationEmailToPatient: cancelRequest.sendCancellationEmailToPatient,
					email: cancelRequest.email,
					languageCode: cancelRequest.languageCode,
				},
			}
		}
	};
}

export function cancelReservation(token, reservationId) {
	return {
		type: actionTypes.APPOINTMENT_RESERVATION_CANCEL,
		payload: {
			request: {
				method: 'get',
				url: `appointment/reservation/${reservationId}/cancel/`,
				headers: {
					token: token,
				},
				data: {},
			}
		}
	};
}

export function getCustomFields(referralSystemId) {
	return {
		type: actionTypes.CUSTOM_FIELDS,
		payload: {
			request: {
				method: 'get',
				url: `appointment/customfields/${referralSystemId}/`,
			}
		}
	};
}

export function getReferringProviders(systemId) {
	return {
		type: actionTypes.GET_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: `system/${systemId}/referringProviders/`,
			}
		}
	};
}

export function searchReferringProviders(referralSystemId, query) {
	return {
		type: actionTypes.GET_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: `system/${referralSystemId}/referringProviders/${query}`,
			}
		}
	};
}

export function getOrganizationReferringProviders() {
	return {
		type: actionTypes.GET_ORGANIZATION_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: 'organizationReferringProviders',
			}
		}
	};
}

export function searchOrganizationProviders(query) {
	return {
		type: actionTypes.GET_ORGANIZATION_REFERRING_PROVIDERS,
		payload: {
			request: {
				method: 'get',
				url: `organizationReferringProviders/${query}`,
			}
		}
	};
}

export function setAppointmentDetailsFromDecisionSupportOutput(careOrderAppointmentDetails, decisionSupportAppointmentDetails) {
	return function (dispatch) {
		dispatch(setAppointmentDetailsFromDecisionSupportOutputAction(careOrderAppointmentDetails, decisionSupportAppointmentDetails));
		return Promise.resolve();
	}
}

function setAppointmentDetailsFromDecisionSupportOutputAction(careOrderAppointmentDetails, decisionSupportAppointmentDetails) {
	let result = {
		type: actionTypes.SET_APPOINTMENT_DETAILS_FROM_DECISION_SUPPORT_OUTPUT,
		agentInstructions: {
			agentAvailabilityInstructions: decisionSupportAppointmentDetails[APPOINTMENT_CRITERIA.agentAvailabilityInstructions] || '',
			agentReservationInstructions: decisionSupportAppointmentDetails[APPOINTMENT_CRITERIA.agentReservationInstructions] || '',
			agentPostBookingInstructions: decisionSupportAppointmentDetails[APPOINTMENT_CRITERIA.agentPostBookingInstructions] || '',
		},
		symptomDetails: {
			reasonForVisit: decisionSupportAppointmentDetails[APPOINTMENT_CRITERIA.reasonForVisit] || careOrderAppointmentDetails[APPOINTMENT_CRITERIA.reasonForVisit] || '',
		},
	};

	return result;
}