import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

export default function MhdErrorDisplay(props) {
	const childName = props.childName || "Referral Direct";
	return (
		<div className="error-display">
			<h2>
				Oops! {childName} has encountered an error. 
			</h2>
			<Button color="link" onClick={props.patientSearch}>Please click here.</Button>If the issue persists contact EH Support.
		</div>
	);
}

MhdErrorDisplay.propTypes = {
	childName: PropTypes.string,
	error: PropTypes.object.isRequired,
	errorInfo: PropTypes.object.isRequired,
	patientSearch: PropTypes.func.isRequired
};