import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Row, Button } from 'reactstrap';
import * as routes from '../../../routes';
import { LabeledField, AddressDisplay } from "components/common/standardComponents";
import dayjs from "dayjs";

import { genderCodeToDisplayName } from '../../../lib/standardLibrary';
import { defaultBookingDisabledMessage } from '../../../lib/misc';
import { PhoneNumber } from '../../../components/common/phoneNumber';

import '../css/patientDetails.css';

import { startBookingProcess } from "actions/rd2RefactorActions";

export default function PatientDetails({ patient, config }: { patient: any, config: any }) {
    return (
        <div>
            <div className="col-lg-12 patient-details-content">
                <Row className="name-row">
                    <Col lg="auto" style={{paddingLeft: "0px"}}>
                        <PatientName patient={patient} />
                    </Col>
                    <Col>
                        {config.isEditEnabled &&
                            <PatientEditToggle patient={patient} />
                        }
                    </Col>
                    <Col lg="auto" style={{ paddingRight: "0px" }}>
                        <BookAppointmentButton patient={patient} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    {config.memberId.isVisible && 
                        <Col lg="2">
                            <PatientMemberId patient={patient} config={config} />
                        </Col>
                    }
                    {config.groupNumber.isVisible &&
                        <Col lg="2">
                            <PatientGroupNumber patient={patient} config={config} />
                        </Col>
                    }
                    <Col lg="2">
                        <PatientDateOfBirth patient={patient} />
                    </Col>
                    <Col lg="2">
                        <PatientGender patient={patient} config={config} />
                    </Col>
                    <Col lg="2">
                        <PatientHomePhone patient={patient} />
                    </Col>
                    <Col lg="2">
                        <PatientMobilePhone patient={patient} />
                    </Col>
                </Row>
                <Row>
                    <Col xxl="4" xl="6">
                        <PatientAddress patient={patient} />
                    </Col>
                    <Col lg="4">
                        <PatientEmail patient={patient} />
                    </Col>
                </Row>
                {config.notes.isVisible &&
                    <Row>
                        <Col lg="auto">
                            <PatientNotes patient={patient} config={config} />
                        </Col>
                    </Row>
                }
            </div>
        </div>
    )
}

const PatientName = ({ patient }: { patient: any }) => {
    return (
        <h4>
            {patient.details.firstName} {patient.details.lastName}
        </h4>
    )
}

const PatientEditToggle = ({ patient }: { patient: any }) => {
    const dispatch = useDispatch();
    return <h4>
        <a
            href="#Edit"
            onClick={
                (e) => {
                    e.preventDefault();
                    dispatch(routes.patientEdit(patient.details.referenceId))
                }}
            style={{ color: 'black' }}
        >
            <FontAwesomeIcon icon="user-edit" />
        </a>
    </h4>
}

const PatientNotes = ({ patient, config }: { patient: any, config: any }) => {
    return (
        <LabeledField
            label={config.notes.fieldLabel}
            value={patient.details.notes}
            isHidden={!config.notes.isVisible}
        />
    )
}

const PatientMemberId = ({ patient, config }: { patient: any, config: any }) => {
    return (
        <LabeledField
            label={config.memberId?.fieldLabel ?? 'Member Id'}
            value={patient.details.memberId}
            isHidden={!config.memberId.isVisible}
        />
    )
}

const PatientDateOfBirth = ({ patient }: { patient: any }) => {
    return (
        <LabeledField
            label="Date of Birth"
            value={patient.details.dateOfBirth ? dayjs(patient.details.dateOfBirth).format('MM/DD/YYYY') : '-'}
        />
    )
}

const PatientHomePhone = ({ patient }: { patient: any }) => {
    return (
        <LabeledField label="Home Phone" value={<PhoneNumber value={patient.details.homePhone} />} />
    )
}

const PatientAddress = ({ patient }: { patient: any }) => {
    return (
        <AddressDisplay label="Address" address={patient.details} />
    )
}

const PatientGroupNumber = ({ patient, config }: { patient: any, config: any }) => {
    return (
        <LabeledField
            label="Group Number"
            value={patient.details.groupNumber}
            isHidden={!config.groupNumber.isVisible}
        />
    )
}

const PatientGender = ({ patient, config }: { patient: any, config: any }) => {
    return (
        <LabeledField label={config.gender?.fieldLabel ?? 'Gender'} value={genderCodeToDisplayName(patient.details.gender)} />
    )
}

const PatientMobilePhone = ({ patient }: { patient: any }) => {
    return (
        <LabeledField label="Mobile Phone" value={<PhoneNumber value={patient.details.mobilePhone} />} />
    )
}

const PatientEmail = ({ patient }: { patient: any }) => {
    return (
        <LabeledField label="Email" value={patient.details.email} />
    )
}

const BookAppointmentButton = ({ patient }: { patient: any }) => {
    const dispatch = useDispatch();

    const activeCareOrder = useSelector((state: any) => state.careOrder);
    const decisionSupportOutput = useSelector((state: any) => state.decisionSupport);
    const availabilitySearchConfig = useSelector((state: any) => state.config.availabilitySearch);
    const activePatientDetails = useSelector((state: any) => state.activePatient.details);
    const useDecisionSupport = useSelector((state: any) => state.config.decisionSupport.useDecisionSupport);

    return (
        <>
            {!patient.details.isBookingDisabled ? (
                <Button
                    color="primary"
                    onClick={
                        () => {
                            dispatch(startBookingProcess(
                                activeCareOrder,
                                decisionSupportOutput,
                                availabilitySearchConfig,
                                activePatientDetails,
                                useDecisionSupport))
                        }
                    }
                >
                    Book Appointment
                </Button>
            ) : (
                <Alert color="danger">{patient.details.bookingDisabledMessage ?? defaultBookingDisabledMessage}</Alert>
            )}
        </>
    )
}
