import * as types from '../actions/actionTypes';

const initialState = {
	correlationKey: null,
	decisionSupportSessionData: {
		dataPoolFields: null,
		effectiveFlowVersion: null,
		elementSet: null,
		enablePreviousButton: false,
		eventLog: null,
		finishedDueToKickout: false,
		flowId: null,
		flowSessionId: null,
		hasValidationErrors: false,
		hasWarnings: false,
		isFlowAtInputStage: false,
		isFlowAtStart: false,
		isFlowFinished: false,
		kickoutMessage: null,
		results: {
			fields: [],
		},
		supportedFlowInputs: null,
		validationMessages: [],
		validationWarnings: null,
	},
	decisionSupportSessionId: null,
	isDecisionSupportSessionSavedToCareOrder: false,
	decisionSupportSubpoints: null,
	sessionId: null,
	workboxId: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.GET_COMPLETED_DECISION_SUPPORT_SESSION_PAYLOAD_SUCCESS:
			return {
				...state,
				decisionSupportSessionData: action.payload.data,
				decisionSupportSessionId: action.payload.data.flowSessionId,
				decisionSupportSubpoints: mapFlowSessionResponseToSubpoints(action.payload.data)
			};
		case types.WORKFLOW_CORRELATION_KEY_UPDATED:
			return {
				...state,
				correlationKey: action.correlationKey,
				decisionSupportSessionId: null,
				isDecisionSupportSessionSavedToCareOrder: false,
				decisionSupportSubpoints: null,
				workboxId: null,
			};
		case types.APPLIEDPATHWAYS_FETCH_WORKBOX_SUCCESS:
		case types.APPLIEDPATHWAYS_WORKFLOW_NEXT_SUCCESS:
		case types.APPLIEDPATHWAYS_WORKFLOW_PREVIOUS_SUCCESS:
			return {
				...state,
				correlationKey: action.payload.data.state.correlationKey,
				decisionSupportSessionId: action.payload.data.state.decisionSupportSessionId,
				sessionId: action.payload.data.state.sessionId,
				workboxId: action.payload.data.state.workboxId,
			};
		case types.GUIDED_RESPONSE_START_FLOW_SUCCESS:
		case types.GUIDED_RESPONSE_CONTINUE_FLOW_SUCCESS:
			return {
				...state,
				decisionSupportSessionId: action.payload.data.flowSessionId,
				workboxId: null,
			};
		case types.APPOINTMENT_BOOK_SUCCESS:
			return {
				...state,
				correlationKey: null,
				decisionSupportSessionData: null,
				decisionSupportSessionId: null,
				isDecisionSupportSessionSavedToCareOrder: false,
				decisionSupportSubpoints: null,
				workboxId: null,
			};
		case types.CREATE_CARE_ORDER_FROM_DECISION_SUPPORT_OUTPUTS_SUCCESS:
			return {
				...state,
				isDecisionSupportSessionSavedToCareOrder: true,
			}
		default:
			return state;
	}
}

function mapFlowSessionResponseToSubpoints(flowSessionResponse) {
	let results = flowSessionResponse.results.fields;
	let subpoints = [];

	results.forEach(field => {
		let subpoint = { key: field.fieldName.toLowerCase(), value: field.currentValue };
		subpoints.push(subpoint);
	});

	return subpoints;
}