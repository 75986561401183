/**
 * @format
 */

import * as React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import {
	displayFullName,
	displayProviderNameWithProfessionalDesignation,
	valueDisplayBottomMargin,
} from '../../lib/standardLibrary';
import { PhoneNumber } from '../common/phoneNumber';
import './rescheduleBookingDetails.css';

export default function RescheduleBookingDetails(props) {
	const { reservationDetails, config, providerFieldConfig, isRescheduleFlow, previousAppointmentDetails } = props;

	const providerConfig = {
		fax: providerFieldConfig.fax,
		phone: providerFieldConfig.phone,
	};

	let showInsuranceOnBookAppointment = config.showInsuranceOnBookAppointment;
	let providerFullName = displayFullName(reservationDetails.providerFirstName, reservationDetails.providerLastName);
	let providerFullNameProfDesignation = displayProviderNameWithProfessionalDesignation(
		providerFullName,
		reservationDetails.providerProfessionalDesignation,
	);
	let showPreBookInformationForPatient =
		reservationDetails.preBookInformationForPatient && reservationDetails.preBookInformationForPatient !== '';
	let previousAppointmentProviderFullName = displayFullName(
		previousAppointmentDetails.providerFirstName,
		previousAppointmentDetails.providerLastName,
	);
	let previousAppointmentProviderFullNameProfDesignation = displayProviderNameWithProfessionalDesignation(
		previousAppointmentProviderFullName,
		previousAppointmentDetails.providerProfessionalDesignation,
	);
	let isReferringProvider =
		isRescheduleFlow && Boolean(previousAppointmentDetails.managedReferringServiceDisplayName.trim() !== '');

	let showArrivalTime =
		reservationDetails.expectedArrivalTime &&
		reservationDetails.expectedArrivalTime !== reservationDetails.providerAppointmentDateTime;
	let showPreviousArrivalTime =
		previousAppointmentDetails.expectedArrivalTime &&
		previousAppointmentDetails.expectedArrivalTime !== previousAppointmentDetails.providerAppointmentDateTime;

	let showMappedAppointmentType = Boolean(
		previousAppointmentDetails.mappedAppointmentTypeName &&
			previousAppointmentDetails.mappedAppointmentTypeName !== previousAppointmentDetails.appointmentTypeName,
	);

	return (
		<Row>
			{/* Previous Appointment */}
			<Col style={{ margin: '15px 15px 15px 15px' }}>
				<h5 className="mb-3">Original Appointment Details</h5>
				<Row className="mb-2">
					<Col lg="4" className="mb-2">
						<div>
							<p className="fw-bold form-label">WHEN</p>
							{previousAppointmentDetails.providerAppointmentDateTime ? (
								<div> {moment(previousAppointmentDetails.providerAppointmentDateTime).format('dddd, MM/DD/YYYY')}</div>
							) : (
								<div>-</div>
							)}
							{previousAppointmentDetails.patientAppointmentDateTime ? (
								<div>
									{' '}
									{moment(previousAppointmentDetails.patientAppointmentDateTime).format('h:mm a')}
									<span style={{ marginLeft: '5px', marginRight: '5px' }}>
										{previousAppointmentDetails.patientAppointmentTimeZone}
									</span>
									(patient time)
								</div>
							) : (
								<div>-</div>
							)}
							{previousAppointmentDetails.providerAppointmentDateTime ? (
								<div>
									{' '}
									{moment(previousAppointmentDetails.providerAppointmentDateTime).format('h:mm a')}
									<span style={{ marginLeft: '5px', marginRight: '5px' }}>
										{previousAppointmentDetails.providerAppointmentTimeZone}
									</span>
									(provider time)
								</div>
							) : (
								<div>-</div>
							)}
							{showPreviousArrivalTime && (
								<div>
									{' '}
									{moment(previousAppointmentDetails.expectedArrivalTime).format('h:mm a')}
									<span style={{ marginLeft: '5px', marginRight: '5px' }}>
										{previousAppointmentDetails.providerAppointmentTimeZone}
									</span>
									(arrival time)
								</div>
							)}
						</div>
					</Col>
					<Col lg="4" className="mb-2">
						<div>
							<p className="fw-bold form-label">WHERE</p>
							<div>
								{previousAppointmentProviderFullNameProfDesignation &&
								previousAppointmentProviderFullNameProfDesignation.trim() !== ''
									? previousAppointmentProviderFullNameProfDesignation
									: '-'}
							</div>
							<div>
								{previousAppointmentDetails.providerSpecialty &&
								previousAppointmentDetails.providerSpecialty.trim() !== ''
									? previousAppointmentDetails.providerSpecialty
									: '-'}
							</div>
							<div style={{ marginBottom: '10px' }}>
								{previousAppointmentDetails.providerSiteName &&
								previousAppointmentDetails.providerSiteName.trim() !== ''
									? previousAppointmentDetails.providerSiteName
									: '-'}
							</div>
							{!previousAppointmentDetails.suppressPhoneNumber &&
								providerConfig.phone &&
								providerConfig.phone.isVisible && (
									<PhoneNumber
										label={providerConfig.phone.fieldLabel ? providerConfig.phone.fieldLabel : '(O)'}
										value={previousAppointmentDetails.providerPhoneNumber?.trim()}
									/>
								)}
							{providerConfig.fax && providerConfig.fax.isVisible && (
								<PhoneNumber
									label={providerConfig.fax.fieldLabel ? providerConfig.fax.fieldLabel : '(F)'}
									value={previousAppointmentDetails.providerFaxNumber?.trim()}
								/>
							)}
							{!previousAppointmentDetails.suppressAddress && previousAppointmentDetails.providerAddressLine1 ? (
								<div>
									<div style={{ margin: '0px' }}>{previousAppointmentDetails.providerAddressLine1}</div>
									{previousAppointmentDetails.providerAddressLine2 &&
									previousAppointmentDetails.providerAddressLine2.trim() !== '' ? (
										<div>{previousAppointmentDetails.providerAddressLine2}</div>
									) : (
										''
									)}
									<div>
										{previousAppointmentDetails.providerCity}, {previousAppointmentDetails.providerState}{' '}
										{previousAppointmentDetails.providerZip}
									</div>
								</div>
							) : (
								'-'
							)}
						</div>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col lg="4" className="mb-2">
						<div>
							<p className="fw-bold form-label">TYPE</p>
							<div>
								{previousAppointmentDetails.appointmentTypeName &&
								previousAppointmentDetails.appointmentTypeName.trim() !== ''
									? previousAppointmentDetails.appointmentTypeName
									: '-'}
							</div>
						</div>
						{showMappedAppointmentType && (
							<div className="mt-4">
								<p className="fw-bold form-label">MAPPED APPOINTMENT TYPE</p>
								<p style={valueDisplayBottomMargin}>{previousAppointmentDetails.mappedAppointmentTypeName}</p>
							</div>
						)}
					</Col>
					<Col lg="4" className="mb-2">
						{showInsuranceOnBookAppointment ? (
							<div>
								<p className="fw-bold form-label">INSURANCE</p>
								<div>
									{previousAppointmentDetails.payorType && previousAppointmentDetails.payorType.trim() !== ''
										? previousAppointmentDetails.payorType
										: '-'}
								</div>
								<div>
									{previousAppointmentDetails.insuranceCarrierName &&
									previousAppointmentDetails.insuranceCarrierName.trim() !== ''
										? previousAppointmentDetails.insuranceCarrierName
										: ''}
								</div>
							</div>
						) : (
							<div></div>
						)}
					</Col>
				</Row>
				{isReferringProvider && (
					<Row className="mb-2">
						<Col lg="4" className="mb-2">
							<div>
								<p className="fw-bold form-label">REFERRING PROVIDER</p>
								<div>{previousAppointmentDetails.managedReferringServiceDisplayName}</div>
							</div>
						</Col>
					</Row>
				)}
			</Col>

			{isRescheduleFlow && <div className="headerDivider"></div>}

			{/* Current Reservation */}
			<Col style={{ margin: '15px 15px 15px 15px' }}>
				{isRescheduleFlow && <h5 className="mb-3">New Appointment Details</h5>}
				<Row className="mb-2">
					<Col lg="4" className="mb-2">
						<div>
							<p className="fw-bold form-label">WHEN</p>
							{reservationDetails.providerAppointmentDateTime ? (
								<div> {moment(reservationDetails.providerAppointmentDateTime).format('dddd, MM/DD/YYYY')}</div>
							) : (
								<div>-</div>
							)}
							{reservationDetails.patientAppointmentDateTime ? (
								<div>
									{' '}
									{moment(reservationDetails.patientAppointmentDateTime).format('h:mm a')}
									<span style={{ marginLeft: '5px', marginRight: '5px' }}>
										{reservationDetails.patientAppointmentTimeZone}
									</span>
									(patient time)
								</div>
							) : (
								<div>-</div>
							)}
							{reservationDetails.providerAppointmentDateTime ? (
								<div>
									{' '}
									{moment(reservationDetails.providerAppointmentDateTime).format('h:mm a')}
									<span style={{ marginLeft: '5px', marginRight: '5px' }}>
										{reservationDetails.providerAppointmentTimeZone}
									</span>
									(provider time)
								</div>
							) : (
								<div>-</div>
							)}
							{showArrivalTime && (
								<div>
									{' '}
									{moment(reservationDetails.expectedArrivalTime).format('h:mm a')}
									<span style={{ marginLeft: '5px', marginRight: '5px' }}>
										{reservationDetails.providerAppointmentTimeZone}
									</span>
									(arrival time)
								</div>
							)}
						</div>
					</Col>
					<Col lg="4" className="mb-2">
						<div>
							<p className="fw-bold form-label">WHERE</p>
							<div>
								{providerFullNameProfDesignation && providerFullNameProfDesignation.trim() !== ''
									? providerFullNameProfDesignation
									: '-'}
							</div>
							<div>
								{reservationDetails.providerSpecialty && reservationDetails.providerSpecialty.trim() !== ''
									? reservationDetails.providerSpecialty
									: '-'}
							</div>
							<div style={{ marginBottom: '10px' }}>
								{reservationDetails.providerSiteName && reservationDetails.providerSiteName.trim() !== ''
									? reservationDetails.providerSiteName
									: '-'}
							</div>

							{!reservationDetails.suppressPhoneNumber && providerConfig.phone && providerConfig.phone.isVisible && (
								<PhoneNumber
									label={providerConfig.phone.fieldLabel ? providerConfig.phone.fieldLabel : '(O)'}
									value={reservationDetails.providerPhoneNumber?.trim()}
								/>
							)}
							{providerConfig.fax && providerConfig.fax.isVisible && (
								<PhoneNumber
									label={providerConfig.fax.fieldLabel ? providerConfig.fax.fieldLabel : '(F)'}
									value={reservationDetails.providerFaxNumber?.trim()}
								/>
							)}
							{!reservationDetails.suppressAddress && reservationDetails.providerAddressLine1 ? (
								<div>
									<div style={{ margin: '0px' }}>{reservationDetails.providerAddressLine1}</div>
									{reservationDetails.providerAddressLine2 && reservationDetails.providerAddressLine2.trim() !== '' ? (
										<div>{reservationDetails.providerAddressLine2}</div>
									) : (
										''
									)}
									<div>
										{reservationDetails.providerCity}, {reservationDetails.providerState}{' '}
										{reservationDetails.providerZip}
									</div>
								</div>
							) : (
								'-'
							)}
						</div>
					</Col>
					<Col lg="4" className="mb-2">
						{showPreBookInformationForPatient ? (
							<div>
								<p className="fw-bold form-label">ADDITIONAL BOOKING INFORMATION</p>
								<div
									dangerouslySetInnerHTML={{
										__html: reservationDetails.preBookInformationForPatient,
									}}
								/>
							</div>
						) : (
							<div></div>
						)}
					</Col>
				</Row>
				<Row className="mb-2">
					<Col lg="4" className="mb-2">
						<div>
							<p className="fw-bold form-label">TYPE</p>
							<div>
								{reservationDetails.appointmentTypeName && reservationDetails.appointmentTypeName.trim() !== ''
									? reservationDetails.appointmentTypeName
									: '-'}
							</div>
						</div>
					</Col>
					<Col lg="4" className="mb-2">
						{showInsuranceOnBookAppointment ? (
							<div>
								<p className="fw-bold form-label">INSURANCE</p>
								<div>
									{reservationDetails.payorType && reservationDetails.payorType.trim() !== ''
										? reservationDetails.payorType
										: '-'}
								</div>
								<div>
									{reservationDetails.insuranceCarrierName && reservationDetails.insuranceCarrierName.trim() !== ''
										? reservationDetails.insuranceCarrierName
										: ''}
								</div>
							</div>
						) : (
							<div></div>
						)}
					</Col>
				</Row>
				{isRescheduleFlow && isReferringProvider && (
					<Row className="mb-2">
						<Col lg="4" className="mb-2">
							<div>
								<p className="fw-bold form-label">REFERRING PROVIDER</p>
								<div>{previousAppointmentDetails.managedReferringServiceDisplayName}</div>
							</div>
						</Col>
					</Row>
				)}
			</Col>
		</Row>
	);
}
