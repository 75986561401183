import { MouseEventHandler } from "react";
import { Button, FormGroup } from "reactstrap";
import { useAppSelector } from "store"

const SsoLoginButton = (props: { isLoading: boolean, handleSSOLogin: MouseEventHandler<HTMLButtonElement> }) => {
    const externalAuthConfig: any = useAppSelector((state: any) => state.config.externalAuth);

    return (externalAuthConfig.enableSingleSignOn && externalAuthConfig.isAutoLoginEnabled === false) ?
        <>
            <FormGroup style={{ textAlign: "center" }}>
                <span>Or</span>
            </FormGroup>
            <FormGroup>
                <Button
                    className="ssoButton"
                    block
                    onClick={props.handleSSOLogin}
                >
                    <span>SSO Login</span>
                </Button>
            </FormGroup>
        </>
        : null;
}

export default SsoLoginButton;