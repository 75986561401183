import LoadingIndicator from "components/loadingIndicator"
import LoginBackground from "./loginBackground";

const UserViewContainer = (props: { isLoading: boolean, children: any, className: string }) => {
    return (
        <div className={props.className}>
            {props.isLoading
                ? <LoadingIndicator loadingMessage='Fetching Data...' />
                : <>
                    <LoginBackground />
                    {props.children}
                </>
            }
        </div>
    )
}

export default UserViewContainer;