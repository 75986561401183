import { concatFieldId } from './fieldUtils';
export const getError = (errors, fieldId) => errors.find(e => e.fieldId === fieldId);
export const testHasError = (errors, fieldId) => getError(errors, fieldId) ? true : false;

/*
 * errors - the collection of page/validation errors, each error is expected to contain a fieldId property when applicable (concatenated per fieldRef/fieldUtils)
 * fieldValuesMap - the current state of (all) field values, organized by group name then field name
 * allFieldsConfig - the full set of field configs, organized by group name then field name
*/
export const getInitialFieldsState = (fieldsConfig, postBookFieldsConfig, props, useSampleValues = false) => {
	let initialFieldState = {};
	for (let fieldGroupName in fieldsConfig) {
		initialFieldState[fieldGroupName] = {}
		for (let fieldName in fieldsConfig[fieldGroupName]) {
			initialFieldState[fieldGroupName][fieldName] = (useSampleValues)
				? fieldsConfig[fieldGroupName][fieldName].sampleValue
				: fieldsConfig[fieldGroupName][fieldName].defaultValue;
		}
	}
	for (let fieldGroupName in postBookFieldsConfig) {
		initialFieldState[fieldGroupName] = {}
		for (let fieldName in postBookFieldsConfig[fieldGroupName]) {
			initialFieldState[fieldGroupName][fieldName] = (useSampleValues)
				? postBookFieldsConfig[fieldGroupName][fieldName].sampleValue
				: postBookFieldsConfig[fieldGroupName][fieldName].defaultValue;
		}
	}
	return initialFieldState;
}

export const getFieldGroupsInfoFromFieldsConfig = (errors, fieldValuesMap, allFieldsConfig, props, isFocusedLookup) => {
	let fieldGroupsInfo = {}
	for (let fieldGroupName in allFieldsConfig) {
		const fieldGroupConfig = allFieldsConfig[fieldGroupName];
		const fieldGroupInfo = getFieldsInfo(errors, fieldValuesMap, fieldGroupName, fieldGroupConfig, allFieldsConfig, props, isFocusedLookup);
		fieldGroupsInfo[fieldGroupName] = fieldGroupInfo;
	}
	return fieldGroupsInfo;
}

const getFieldsInfo = (errors, fieldValuesMap, fieldGroupName, fieldGroupConfig, allFieldsConfig, props, isFocusedLookup) => {
	let fieldsInfo = {}
	for (let fieldName in fieldGroupConfig) {
		const fieldConfig = fieldGroupConfig[fieldName];
		const dto = mapFieldInfoToInputDto(errors, fieldValuesMap, fieldGroupName, fieldName, fieldConfig, allFieldsConfig, props, isFocusedLookup);
		fieldsInfo[fieldName] = dto;
	}
	return fieldsInfo;
}

export const getPreBookCustomFieldsInfo = (errors, fieldValuesMap, customFieldsConfig, props, isFocusedLookup) => {
	let fieldsInfo = {};
	for (let fieldName in customFieldsConfig) {
		const fieldConfig = customFieldsConfig[fieldName];
		if (fieldConfig.isPostBookAppointment) {
			continue;
		}
		const dto = mapFieldInfoToInputDto(errors, fieldValuesMap, "customFieldsPreBook", fieldName, fieldConfig, customFieldsConfig, props, isFocusedLookup);
		fieldsInfo[fieldName] = dto;
	}
	return fieldsInfo;
}

export const getPostBookCustomFieldsInfo = (errors, fieldValuesMap, customFieldsConfig, props, isFocusedLookup) => {
	let fieldsInfo = {};
	for (let fieldName in customFieldsConfig) {
		const fieldConfig = customFieldsConfig[fieldName];
		if (!fieldConfig.isPostBookAppointment) {
			continue;
		}
		const dto = mapFieldInfoToInputDto(errors, fieldValuesMap, "customFieldsPostBook", fieldName, fieldConfig, customFieldsConfig, props, isFocusedLookup);
		fieldsInfo[fieldName] = dto;
	}
	return fieldsInfo;
}

const mapFieldInfoToInputDto = (errors, fieldValuesMap, fieldGroupName, fieldName, fieldConfig, allFieldsConfig, props, isFocusedLookup) => {
	const fieldId = concatFieldId(fieldGroupName, fieldName);
	const hasError = testHasError(errors, fieldId);
	const error = hasError ? getError(errors, fieldId) : null;
	const isVisible = fieldConfig.visibilityRule ? (fieldConfig.isVisible && fieldConfig.visibilityRule(errors, fieldValuesMap, allFieldsConfig, props)) : fieldConfig.isVisible;
	return {
		...fieldConfig,
		isVisible: isVisible,
		disabled: !fieldConfig.isEditable, // TODO isBooking
		isValid: !hasError, //TODO, or "validator" etc...
		error: error,
		name: fieldId,
		isFocused: isFocusedLookup[fieldId],
		id: fieldId,
		value: fieldValuesMap[fieldGroupName][fieldName],
	}
}

export const getFieldSizeByLabel = (label) => {
	if (!label)
		return null;

	if (label.length <= 25)
		return "md"
	else if (label.length <= 40)
		return "lg"
	else 
		return "xl"
}

const mapDataToFieldConfig_Common = ({ fieldConfig, inputType, maxLength }) => {
	return {
		label: fieldConfig.fieldLabel,
		inputType: inputType,
		isEnabled: fieldConfig.isEnabled,
		isVisible: fieldConfig.isVisible,
		isEditable: fieldConfig.isEditable,
		isRequired: fieldConfig.isRequired,
		maxLength: maxLength,
	}
}

export const mapDataToFieldConfig_Text = ({ fieldConfig, inputType, maxLength }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType, maxLength }),
	};
	return config;
}

export const mapDataToFieldConfig_Date = ({ fieldConfig, inputType, minValue, maxValue }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType }),
		minValue: minValue,
		maxValue: maxValue,
	}
	return config;
}

export const mapDataToFieldConfig_Email = ({ fieldConfig, inputType, maxLength }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType, maxLength }),
	}
	return config;
}

export const mapDataToFieldConfig_Phone = ({ fieldConfig, inputType }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType, maxLength: 10 }),
	}
	return config;
}

export const mapDataToFieldConfig_Zip = ({ fieldConfig, inputType }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType, maxLength: 5 }),
	}
	return config;
}

export const mapDataToFieldConfig_Dropdown = ({ fieldConfig, inputType, }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType, maxLength: 5 }),
	}
	return config;
}

export const mapDataToFieldConfig_Checkbox = ({ fieldConfig, inputType, }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType }),
	}
	return config;
}

export const mapDataToFieldConfig_ButtonOptions = ({ fieldConfig, inputType, isMultiSelect}) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType }),
		isMultiSelect: isMultiSelect,
	}
	return config;
}

export const mapDataToFieldConfig_Year = ({ fieldConfig, inputType }) => {
	let config = {
		...mapDataToFieldConfig_Common({ fieldConfig, inputType }),
	}
	return config;
}