import PrivacyPolicy from './privacyPolicy';
import { showModal } from '../../actions/modalActions';
import { MODAL_TYPE_NOTIFICATION } from '../../constants/modalTypes';
import { useDispatch } from "react-redux"
import TermsOfUse from './termsOfUse';

const PrivacyPolicyAndTermsOfUseLinks = () => {
    const dispatch = useDispatch();

    const showTermsOfUse = (event) => {
        event.preventDefault();
        dispatch(showModal(MODAL_TYPE_NOTIFICATION, {
            title: 'Terms of Use',
            children: <TermsOfUse />,
            bodyClassName: 'scrollable',
            size: 'lg',
        }))
    }

    return (
        <div className="text-center loginFooterSize">
            <PrivacyPolicy /> &amp;{' '}
            <a href="/terms-of-use" onClick={showTermsOfUse}>
                Terms of Use
            </a>
        </div>
    )
}

export default PrivacyPolicyAndTermsOfUseLinks;