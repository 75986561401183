import * as types from '../actions/actionTypes'

const initialState = {
	logoImagePath: '',
	stateList: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.LOAD_LOGIN_PAGE_DATA_SUCCESS:
			return {
				logoImagePath: action.payload.data.logoImagePath
			}					
			
		case types.LOAD_PATIENT_SAVE_PAGE_DATA_SUCCESS:
			return {
				...state,
				stateList: action.payload.data.states
			}
			
		default:
			return state
	}
}