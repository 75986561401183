//@ts-check
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

/**
 *
 * @param {string | null | undefined} html
 * @returns {import('draft-js').EditorState}
 */
export function toEditorState(html) {
	return EditorState.createWithContent(
		ContentState.createFromBlockArray(htmlToDraft(html ?? '').contentBlocks),
	);
}

/**
 *
 * @param {import('draft-js').EditorState} editor
 * @returns {string}
 */
export function toHtmlString(editor) {
	return draftToHtml(convertToRaw(editor.getCurrentContent()));
}

export const emptyEditor = () => toEditorState('');
