import React from 'react';
import PropTypes from 'prop-types';
/*import './earlierAppointmentRequestSection.css';*/

export default function EarlierAppointmentRequestSection(props) {
	const { name, value, requestEarlierAppointmentFieldConfig, onChange } = props;

	return (
		<section id="earlierAppointmentSection">
			<div className="section-row earlier-appointment-section">
				<div className="field-control checkbox xl">
					<input
						checked={value}
						className="form-check-input"
						id="earlierAppointmentInput"
						name={name}
						onChange={onChange}
						type="checkbox"
						value={value}
					/>
					<label id="earlierAppointment" htmlFor="earlierAppointmentInput" className="form-check-label" style={{marginLeft: "5px"}}>
						<div>
							{requestEarlierAppointmentFieldConfig.fieldLabel ?
								(<span>
									<strong>{requestEarlierAppointmentFieldConfig.fieldLabel}</strong>
								</span>) :
								(<span>
									<strong>Request Earlier Appointment</strong>
								</span>)
							}

						</div>
					</label>
				</div>
			</div>
		</section>
	);
};

EarlierAppointmentRequestSection.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.bool,
}