import { fetchPatientHistory } from "actions/rd2RefactorActions";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

const loadPatientAppointmentHistory = async (
    dispatch: any,
    referenceId: any,
    setIsPatientAppointmentHistoryLoading: Function,
    isMounted: () => boolean // Add a parameter to check if the component is still mounted
) => {
    try {
        setIsPatientAppointmentHistoryLoading(true);
        await dispatch(fetchPatientHistory(referenceId));
    } finally {
        if (isMounted()) { // Only call setIsPatientAppointmentHistoryLoading if the component is still mounted
            setIsPatientAppointmentHistoryLoading(false);
        }
    }
};

const useLoadPatientAppointmentHistory = (
    referenceId: string,
    shouldLoad: boolean,
    setIsPatientAppointmentHistoryLoading: Function,
) => {
    const dispatch = useDispatch();
    const mountedRef = useRef(true); // Use a ref to track the mounted status so we don't do
    // something like setIsPatientAppointmentHistoryLoading(false) on an unmounted component
    // aka it was loading in the background and no component cares if its done loading

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        // This function checks if the component is still mounted
        const isMounted = () => mountedRef.current;

        if (!shouldLoad) {
            return;
        }

        loadPatientAppointmentHistory(dispatch, referenceId, setIsPatientAppointmentHistoryLoading, isMounted);

    }, [referenceId, shouldLoad, dispatch, setIsPatientAppointmentHistoryLoading]);
};

export default useLoadPatientAppointmentHistory;